import { Tabs, Spin, notification } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import PageLayout from "../Layout/PageLayout";
import { FormattedMessage, intlShape, injectIntl } from "react-intl";
import {
  fetchConfigNotification,
  fetchUserDetailsById,
  postAlertConfig,
} from "../../utils/apiBucherVaslin";
import EditForm from "./EditForm";
import SubscriptionAlert from "./SubscriptionAlert";
import { isGroupConcessionnaire } from "../../utils/helpers";

const { TabPane } = Tabs;
class Alert extends Component {
  constructor(props) {
    super(props);
    this.state = {
      configNotif: null,
      userInfo: null,
    };
  }

  saveConf = (values) => {
    const { intl } = this.props;
    postAlertConfig(
      values.token,
      values.start,
      values.stop,
      values.isActivated,
      values.lang,
      values.isEmailActivated,
      values.isSmsActivated
    ).then(({ isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
      if (isSuccess) {
        notification.success({
          message: intl.formatMessage({ id: "notification.edit.title" }),
          description: intl.formatMessage(
            { id: "notification.edit.success.description" },
            { name: values.nomPrenom }
          ),
        });

        return true;
      } else {
        notification.error({
          message: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.title" })
            : intl.formatMessage({ id: "common.error" }),
          description: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.message" })
            : errorDetail,
        });
      }
      return null;
    });
  };

  componentDidMount() {
    this.fetchDataAsync();
  }

  // Récupère les données de configuration de notification
  async fetchDataAsync() {
    const {
      item: conf,
      isSuccess,
      isUnauthorized,
      errorMessage,
      errorDetail
    } = await fetchConfigNotification();
    const { connectedUserID, intl } = this.props;
    this.setState({
      configNotif: conf,
    });
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
    }

    fetchUserDetailsById(connectedUserID).then(
      ({ item: userInfo, isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
        if (!isSuccess) {
          notification.error({
            message: isUnauthorized
              ? intl.formatMessage({ id: "error.unauthorized.title" })
              : intl.formatMessage({ id: "common.error" }),
            description: isUnauthorized
              ? intl.formatMessage({ id: "error.unauthorized.message" })
              : errorDetail,
          });
        }
        this.setState({
          userInfo,
        });
      }
    );
  }

  render() {
    const { configNotif, userInfo } = this.state;
    const { history, userRole } = this.props;

    if (!configNotif || !userInfo) {
      return (
        <PageLayout pageKey="alert" history={history}>
          <Spin />
        </PageLayout>
      );
    }

    return (
      <PageLayout pageKey="alert" history={history}>
        <h2>
          <FormattedMessage
            id="notification.edit.title"
            defaultMessage="Set my alerts"
          />
        </h2>

        {!userInfo.mobileNumber || userInfo.mobileNumber.length == 0 ? (
          <span style={{ color: "red", fontSize: "12px" }}>
            <FormattedMessage
              id={`notification.alert.messageInfo`}
              defaultMessage="Please enter your mobile number to access SMS activation or not"
            />
          </span>
        ) : (
          ""
        )}
        <Tabs type="card" defaultActiveKey="1">
          <TabPane
            tab={<FormattedMessage id="notification.menu.alerteMachines" />}
            key="1"
          >
            <EditForm
              configNotif={configNotif}
              user={userInfo}
              save={this.saveConf}
            />
          </TabPane>
          {isGroupConcessionnaire({ userRole }) && (
            <TabPane
              tab={<FormattedMessage id="notification.menu.alerteAbonnement" />}
              key="2"
            >
              <SubscriptionAlert
                user={userInfo}
                isChecked={configNotif.isEmailDealerRequestActivated}
              />
            </TabPane>
          )}
        </Tabs>
      </PageLayout>
    );
  }
}

Alert.propTypes = {
  intl: intlShape.isRequired,
};

const mapStateToProps = ({ signIn: { userID, userRole } }) => ({
  connectedUserID: userID,
  userRole,
});

export default injectIntl(connect(mapStateToProps)(Alert));
