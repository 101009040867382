import { notification } from 'antd'
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import PageLayout from '../Layout/PageLayout';
import CreateForm from './CreateForm';
import { createUserForClient } from '../../utils/apiBucherVaslin';
import history from '../../history';

const save = async (values) => {
        await createUserForClient(values).then(({ isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
                    if (isSuccess) {
            return history.goBack();
        }
        const { intl } = this.props;

        notification.error({
            message: isUnauthorized 
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({ id: 'common.error' }),
            description: isUnauthorized 
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : errorDetail,
        });

        return Promise.reject(Error(errorMessage));
    });
}

const UserCreate = ({ match, clientID, clientName }) => (
    <PageLayout pageKey="newUser">
        <h2>
            <FormattedMessage id="user.create.title" defaultMessage="Create a new user" />
        </h2>
        <CreateForm save={save} match={match} client={{ clientID, clientName }} />
    </PageLayout>
);

const mapStateToProps = ({ signIn: { clientID, clientName } }) => ({
    clientID,
    clientName,
});

UserCreate.propTypes = {
    clientID: PropTypes.string,
    clientName: PropTypes.string,
    match: PropTypes.shape({
        params: PropTypes.shape({
            clientId: PropTypes.string,
            clientName: PropTypes.string,
        }).isRequired,
    }),
    intl: intlShape.isRequired,
};

export default injectIntl(connect(mapStateToProps)(UserCreate));
