import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Pagination, Popover } from 'antd';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faFileContract, faUser, faUsers, faUserPlus, faServer, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Table from '../Table';
import PageLayout from '../Layout/PageLayout';
import { fetchCustomers } from '../../utils/apiBucherVaslin';
import { isGroupAdminClient, isGroupConcessionnaire, isGroupRR, isSuperAdmin } from '../../utils/helpers';

import './List.less';

const tableName = 'customers';

const keyToDbColumn = {
    name: 'NOM_CLIENT',
    city: 'VILLE',
    country: 'PAYS',
    dealer: 'CONCESSIONNAIRE',
    areaManager: 'RR',
    eurekaReference: 'NUMERO_CLIENT_EUREKA',
};

const getColumns = (userRole) => [
    {
        dataIndex: 'nomClient',
        key: 'name',
        sorter: true,
    }, {
        dataIndex: 'ville',
        key: 'city',
        sorter: true,
    }, {
        dataIndex: 'pays',
        key: 'country',
        sorter: true,
    }, {
        dataIndex: 'dealers',
        key: 'dealer',
        sorter: true,
        render: () => (_, { dealers }) =>  (
                dealers && dealers.map((deal) => <Fragment>{deal}< br/></Fragment>)
        )
    }, 
    {
        dataIndex: 'rr',
        key: 'areaManager',
        sorter: true,
    }, {
        dataIndex: 'numeroClientEureka',
        key: 'eurekaReference',
        sorter: true,
    }, {
        key: 'action',
        render: () => (_, { numeroClientEureka, nomClient }) => {
            const enableSpecialActions = isSuperAdmin({ userRole }) || isGroupAdminClient({ userRole });
            const isDealerOrRR = isGroupConcessionnaire({ userRole }) || isGroupRR({ userRole });

            return (
                <span>
                    <Popover content={<FormattedMessage id={`table.${tableName}.clientDetails`} />}>
                        <Link to={`/customers/${numeroClientEureka}/details`}>
                            <Button htmlType="button">
                                <FontAwesomeIcon icon={faUser} />
                            </Button>
                        </Link>
                    </Popover>
                    <Divider type="vertical" />
                    <Popover content={<FormattedMessage id={`table.${tableName}.clientUsers`} />}>
                        <Link to={`/customers/${numeroClientEureka}/users`}>
                            <Button htmlType="button">
                                <FontAwesomeIcon icon={faUsers} />
                            </Button>
                        </Link>
                    </Popover>
                    <Divider type="vertical" />
                    <Popover content={<FormattedMessage id={`table.${tableName}.clientOrders`} />}>
                        <Link to={`/customers/${numeroClientEureka}/orders`}>
                            <Button htmlType="button" disabled={!enableSpecialActions && !isDealerOrRR}>
                                <FontAwesomeIcon icon={faShoppingCart} />
                            </Button>
                        </Link>
                    </Popover>
                    <Divider type="vertical" />
                    <Popover content={<FormattedMessage id={`table.${tableName}.clientOrdersLicense`} />}>
                        <Link to={`/customers/${numeroClientEureka}/ordersLicense`}>
                            <Button htmlType="button" disabled={!enableSpecialActions && !isDealerOrRR}>
                                <FontAwesomeIcon icon={faFileContract} />
                            </Button>
                        </Link>
                    </Popover>
                    <Divider type="vertical" />
                    <Popover content={<FormattedMessage id={`table.${tableName}.machines`} />}>
                        <Link to={`/customers/${numeroClientEureka}/devices`}>
                            <Button htmlType="button" disabled={!enableSpecialActions && !isDealerOrRR}>
                                <FontAwesomeIcon icon={faServer} />
                            </Button>
                        </Link>
                    </Popover>
                    <Divider type="vertical" />
                    <Popover content={<FormattedMessage id={`table.${tableName}.machinesConnected`} />}>
                        <Link to={`/customers/${numeroClientEureka}/connectedDevices`}>
                            <Button htmlType="button" disabled={!enableSpecialActions && !isDealerOrRR}>
                                <FontAwesomeIcon icon={faDatabase} />
                            </Button>
                        </Link>
                    </Popover>
                    <Divider type="vertical" />
                    <Popover content={<FormattedMessage id={`table.${tableName}.createUser`} />}>
                        <Link to={`/newUser/${numeroClientEureka ? numeroClientEureka.trim() : ''}/${nomClient ? nomClient.trim() : ''}`}>
                            <Button htmlType="button" disabled={!enableSpecialActions && !isDealerOrRR}>
                                <FontAwesomeIcon icon={faUserPlus} />
                            </Button>
                        </Link>
                    </Popover>
                </span>
            );
        },
    },
];

const Customers = ({ userRole }) => {
    return <PageLayout pageKey="customers">
        <h2>
            <FormattedMessage
                id={`table.${tableName}.title`}
            />
        </h2>
        <Table
            fetchItems={fetchCustomers}
            keyToDbColumn={keyToDbColumn}
            columns={getColumns(userRole)}
            rowKey="numeroClientEureka"
            tableName={tableName}
        />
        <div className="contact-container">
            <a href="mailto:assistance.sn@buchervaslin.com">
                <FormattedMessage
                    id={`table.${tableName}.contact`}
                />
            </a>
        </div>
    </PageLayout>;
};

Customers.propTypes = {
    userRole: PropTypes.array.isRequired,
};

const mapStateToProps = ({ signIn: { userRole } }) => ({
    userRole
});

export default connect(mapStateToProps)(Customers);
