import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Checkbox, Icon, Input, Modal, Popconfirm, Popover, Slider, Spin } from 'antd';

import LinearGraph from './LinearGraph';
import './LinearGraph.less';

/**
 * Method used when user hover slider point
 * @param {timestamp} timestamp 
 */
function formatter(timestamp) {
    const date = new Date(timestamp);
    return date.toLocaleString();
}

class LinearGraphContainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            alignAxis: false,
            axisGroup: [],
            dateFilter: null,
            displayedMetric: null,
            metric: null,
            newGroupName: null,
            pendingAxisGroup: [],
            loading: null,
            separator: ';',
            showGroupManagement: false,
            stairMode: false,
            step: null,
        };
    }

    async componentDidMount() {
        const { jsonMetrics, xAxis } = this.props;

        // Get groups from localstorage
        await this.readGroupList();

        // Formate metric values into a proper metric object then copy it in another variable and update state
        const metric = await this.formatMetrics(jsonMetrics);
        const displayedMetric = JSON.parse(JSON.stringify(metric));
        const dateFilter = [metric[xAxis][0], metric[xAxis][metric[xAxis].length - 1]];
        await this.setState({dateFilter, displayedMetric, metric});

        // Apply filters
        await this.applyFilters(true);

        // Update state value for displayedMetric
        this.setState({loading: false});
    }

    /**
     * Method used to set a proper time format
     */
    formatTime = (time) => {
        const splitedTime = time.split(':');
        splitedTime.forEach((value, index) => {
            splitedTime[index] = (value.length === 2) ? value : "0" + value;
        });
        return splitedTime[0] + ":" + splitedTime[1] + ":" + splitedTime[2];
    }

    formatDate = (date) => {
        const splitedDate = date.split('-');
        splitedDate.forEach((value, index) => {
            splitedDate[index] = (value.length >= 2) ? value : "0" + value;
        });
        return splitedDate[0] + "-" + splitedDate[1] + "-" + splitedDate[2];
    }


    /**
     * Method triggered when atleast one filter have been changed
     */
    applyFilters = async (calculateStep = false, dateRangeSelection = null) => {
        const { metric, dateFilter } = this.state;
        const displayedMetric = JSON.parse(JSON.stringify(metric));

        // Change value of dateFilter (if defined) + reset displayedMetric value before filter
        await this.setState({ 
            dateFilter: (dateRangeSelection) ? dateRangeSelection : dateFilter, 
            displayedMetric,
        });

        // Filter metric on selected date range
        await this.applyDateFilter();

        // calculate step value
        if (calculateStep) {
            await this.calculateStep();
        }

        // Apply step filter
        await this.applyStepperFilter();
    }


    /**
     * Method used when user change date slider values
     */
    applyDateFilter = async () => {
        const { dateFilter, displayedMetric } = this.state;
        const { xAxis } = this.props;
        const filteredMetric = {};

        if (dateFilter && dateFilter.length === 2) {
            for(var property in displayedMetric) {
                if (displayedMetric[property].length > 0) {
                    // Init filtered property array
                    filteredMetric[property] = [];

                    // Loop trhought property values
                    displayedMetric[property].forEach((propertyValue, index) => {
                        if (displayedMetric[xAxis][index] > dateFilter[0] && displayedMetric[xAxis][index] < dateFilter[1]) {
                            filteredMetric[property].push(propertyValue);
                        }
                    });
                }
            }

            // Update state
            await this.setState({ displayedMetric: filteredMetric });
        } else {
            await this.setState({ displayedMetric: displayedMetric });
        }

        
    }


    /**
     * Method used to calculate step for displayed graph
     * @param metricRows : Array[] => List of metrics
     */
    calculateStep = async () => {
        const { displayedMetric } = this.state
        let step;
        if (displayedMetric.date && displayedMetric.date.length > 0) {
            // Calculate step value using window width and total points
            if (window.innerWidth > displayedMetric.date.length) {
                step = Math.round((window.innerWidth / displayedMetric.date.length) * 2.35);
            } else {
                step = Math.round((displayedMetric.date.length / window.innerWidth) * 2.35);
            }

            step = (step < 1) ? 1 : step; // Set step to 1 if step calculated value was lower than 1
            await this.setState({step});
        }
    }


    /**
     * Method used to apply stepper filter
     */
    applyStepperFilter = async () => {
        const { displayedMetric, step } = this.state;
        const filteredMetric = {};

        for(var property in displayedMetric) {
            if (displayedMetric[property].length > 0) {
                // Init filtered property array
                filteredMetric[property] = [];

                // Loop trhought property values
                displayedMetric[property].forEach((propertyValue, index) => {
                    if (index % step === 0) {
                        filteredMetric[property].push(propertyValue);
                    }
                });
            }
        }

        // Update state
        await this.setState({ displayedMetric: filteredMetric });
    }

    /*
    * Method used to convert an array of metrics into a proper metric object containing all axes data
    */
    formatMetrics = async (metricRows) => {
        // Init formattedMetric object
        const formattedMetric = {};
        formattedMetric.timestamp = [];

        // Init FormattedData object properties
        metricRows.forEach((metric) => {
            // Manage metric name property
            const metricHeader = metric.splice(0,1)[0].toLowerCase();
            formattedMetric[metricHeader] = [];

            // Manage metric property values
            metric.forEach((metricValue, metricValueIndex) => {
                // Check if metric is "date" ou "hour", and proceed specific treatment
                if (metricHeader === "date") {
                    // Add value as string
                    metricValue = metricValue.trim();
                    const dateVal = this.formatDate(metricValue);
                    formattedMetric[metricHeader].push(dateVal);
                } else if (metricHeader === "heure") {
                    // Add value as string
                    metricValue = metricValue.trim();
                    const timeVal = this.formatTime(metricValue);
                    formattedMetric[metricHeader].push(timeVal);

                    // Format date then add his timestamp to the list
                    let date = this.formatDate(metricRows[0][metricValueIndex].trim());
                    date = date.split('-');
                    const formattedDate = date[2] + "-" + date[1] + "-" + date[0] + "T" + timeVal;
                    const timestamp = new Date(formattedDate).getTime();
                    formattedMetric.timestamp.push(timestamp);
                } else {
                    // Add value as int
                    formattedMetric[metricHeader].push(parseInt(metricValue));
                }
            });
        });

        return formattedMetric;
    }

    /**
     * Method called when step is changed
     */
    onChangeStep = async (event) => {
        const { value } = event.target;
        const reg = new RegExp('^[0-9]+$');

        // Check if step field contains only number
        if (!isNaN(value) && reg.test(value) && value > 0) {
            // Update step
            await this.setState({step: value});

            // Refresh the graph preview
            await this.applyFilters();
        }
        
    }

    /**
     * Method called when user (un)check align checkbox
     */
    onChangeAlign = async (event) => {
        await this.setState({alignAxis: event.target.checked});
        await this.applyFilters();
    }

    /**
     * Method called when user (un)check stairMode checkbox
     */
    onChangeStairMode = async (event) => {
        await this.setState({stairMode: event.target.checked});
        await this.applyFilters();
    }

    /**
     * Method called when user decide to close group management modal
     */
    closeGroupModal = () => {
        let pendingAxisGroup = JSON.parse(JSON.stringify(this.state.pendingAxisGroup));

        // Remove empty groups
        pendingAxisGroup = pendingAxisGroup.filter((group) => group.axis && group.axis.length > 0);

        // Cancel update on all groups who are in edit mod
        pendingAxisGroup.forEach((group) => {
            if (group.editMode) {
                group.editMode = false;
            }
        });

        this.setState({ axisGroup: pendingAxisGroup, pendingAxisGroup, showGroupManagement: false});
    }

    /**
     * Method called to read metric groups used in graph
     */
    readGroupList = async () => {
        const { source } = this.props;
        
        // Read stringified value in localstorage
        const saveValue = JSON.parse(localStorage.getItem(`${source}_graph`));

        // Update state
        await this.setState({
            axisGroup: (saveValue && saveValue.groups) ? saveValue.groups : [],
            pendingAxisGroup: (saveValue && saveValue.groups) ? saveValue.groups : [],
        });
    }

    /**
     * Method called to save metric groups used in graph
     */
    saveGroupList = (axisGroup) => {
        const { source } = this.props;
        const saveValue = { groups: axisGroup };
        
        // Save stringified value in localstorage
        localStorage.setItem(`${source}_graph`, JSON.stringify(saveValue));
    }

    /**
     * Method used to know if there are some updates on a axis group
     */
    isPendingGroupUpdates = () => {
        const { pendingAxisGroup } = this.state; 
        let pending = false;

        pendingAxisGroup.forEach((group) => {
            if (group.editMode) {
                pending = true;
                return pending;
            }
        });

        return pending;
    }

    isGroupNameAvailable = (groupName, isEdit = false) => {
        const { axisGroup } = this.state;
        const dupes = axisGroup.filter((group) => group.name.toUpperCase() === groupName.toUpperCase());
        const maxAcceptedOccurence = (isEdit) ? 1 : 0;
        return (dupes && dupes.length > maxAcceptedOccurence) ? false : true;
    }

    /**
     * Method called when user confirm group creation
     */
    addGroup = () => {
        const { newGroupName } = this.state;
        const groupList = JSON.parse(JSON.stringify(this.state.pendingAxisGroup));
        
        // Create new group
        const newGroup = {
            axis: [],
            editMode: true,
            editName: newGroupName,
            editAxis: [],
            name: newGroupName,
        }

        // Add new group to the list
        groupList.push(newGroup);
        this.setState({ axisGroup: groupList, pendingAxisGroup: groupList, newGroupName: null});
        
        // Update storage
        this.saveGroupList(groupList);
    }

    /**
     * Method used to update checked axis for a specific group
     * @param {HtmlEvent} event 
     * @param {string} axeName 
     * @param {number} groupIndex 
     */
    handleCheckedGroupAxe = (event, metricName, groupIndex) => {
        const groupList = JSON.parse(JSON.stringify(this.state.pendingAxisGroup));
        const checked = event.target.checked;

        if (checked) {
            // Push metricName in group
            groupList[groupIndex].editAxis.push(metricName);
        } else {
            // Find the index of the metric inside the group, then remove it
            const indexInAxis = groupList[groupIndex].editAxis.indexOf(metricName);
            groupList[groupIndex].editAxis.splice(indexInAxis, 1);
        }

        // Update state
        this.setState({ pendingAxisGroup: groupList });
    }

    /**
     * Method used to enable/disable edition mode on a group
     * @param {number} index Index of group
     * @param {boolean} bool Boolean to apply on specified group
     */
    changeEditGroupe = async (index, bool) => {
        // Change group editMode value
        const groupList = JSON.parse(JSON.stringify(this.state.pendingAxisGroup));
        groupList[index].editMode = bool;

        // Init editName and editAxis to name value if group became editable
        if (bool) {
            groupList[index].editName = groupList[index].name;
            groupList[index].editAxis = groupList[index].axis;
        }

        // Update state
        await this.setState({ pendingAxisGroup: groupList})
    }

    /**
     * Change displayed edit name on specific axis group
     * @param {string} value New group name to be displayed on field
     * @param {number} index Index of specified group
     */
    changeGroupeName = (value, index) => {
        // Change group editName value
        const groupList = JSON.parse(JSON.stringify(this.state.pendingAxisGroup));
        groupList[index].editName = value;

        // Update state
        this.setState({ pendingAxisGroup: groupList})
    }

    /**
     * 
     * @param {string[]} value List of selected options
     * @param {nulber} index Index of specified group
     */
    changeGroupAxis = (values, index) => {
        // Change group axis value
        const groupList = JSON.parse(JSON.stringify(this.state.pendingAxisGroup));
        groupList[index].editAxis = values;

        // Update state
        this.setState({ pendingAxisGroup: groupList})
    }

    /**
     * Change value of an axis group
     * @param {number} index Index of specified group
     */
    confirmEditGroup = (index) => {
        // Change group values
        const groupList = JSON.parse(JSON.stringify(this.state.pendingAxisGroup));
        groupList[index].name = groupList[index].editName;
        groupList[index].axis = groupList[index].editAxis;
        groupList[index].editMode = false;

        // Update state
        this.setState({ axisGroup: groupList, pendingAxisGroup: groupList});

        // Update storage
        this.saveGroupList(groupList);
    }

    /**
     * Method used to delete a group
     * @param {Number} pendingGroupIndex index of the group we want to delete
     */
    deleteGroupe = (pendingGroupIndex) => {
        // Remove group of the pending group list
        const pendingGroupList = JSON.parse(JSON.stringify(this.state.pendingAxisGroup));
        pendingGroupList.splice(pendingGroupIndex, 1);

        // Remove group of the group list
        const groupList = JSON.parse(JSON.stringify(this.state.pendingAxisGroup));
        const groupIndex = groupList.findIndex((group) => group.name === this.state.pendingAxisGroup[pendingGroupIndex].name);
        if (groupIndex > -1) {
            // Remove the group if we can find it
            groupList.splice(groupIndex, 1);
        }

        // Update state
        this.setState({ axisGroup: groupList, pendingAxisGroup: pendingGroupList});

        // Update storage
        this.saveGroupList(groupList);
    }

    render() {
        // Read props and state values
        const { excludedMetrics, intl, xAxis } = this.props;
        const { alignAxis, axisGroup, dateFilter, displayedMetric, metric, newGroupName, 
            pendingAxisGroup, loading, showGroupManagement, stairMode, step } = this.state;

        // Get min/max graph date
        const metricSize = (metric) ? metric[xAxis].length : null;
        const maxGraphDate = (metric) ? metric[xAxis][metricSize - 1] : null;
        const minGraphDate = (metric) ? metric[xAxis][0] : null;

        // Check if there are some unsaved group updates
        const pendingGroupUdpates = this.isPendingGroupUpdates();
        
        return(
            <div>
                <div className="graph-bloc">
                        {/* Graph header */}
                        <div className="graphHeader">
                            {/* Step Field */}
                            <div>
                                <label htmlFor="step">
                                    <FormattedMessage id="metricPreview.step" />
                                    :
                                </label>
                                <Input 
                                    id="step"
                                    onChange={(e) => {this.onChangeStep(e)}}
                                    type="number"
                                    value={step} 
                                />
                            </div>

                            {/* Range selection */}
                            <div>
                                {   (minGraphDate && maxGraphDate) 
                                    && (dateFilter && dateFilter.length === 2) 
                                    && (
                                    <Fragment>
                                        <label htmlFor="rangeSlider">
                                            <FormattedMessage id="metricPreview.dateSlider" />
                                            :
                                            { dateFilter && dateFilter.length === 2 && (
                                                <span className="date-range-filter">
                                                    {
                                                        new Date(dateFilter[0]).toLocaleTimeString() + " - " 
                                                        + new Date(dateFilter[1]).toLocaleTimeString()
                                                    }
                                                </span>
                                            )}
                                        </label>
                                        <Slider
                                            defaultValue={[minGraphDate, maxGraphDate]}
                                            id="rangeSlider"
                                            max={maxGraphDate}
                                            min={minGraphDate}
                                            onAfterChange={(value) => {this.applyFilters(false, value)}}
                                            range
                                            tipFormatter={formatter}
                                        />
                                    </Fragment>
                                )}
                            </div>

                            {/* Align on 0 */}
                            <div>
                                <Checkbox
                                    checked={alignAxis}
                                    onChange={(event) => { this.onChangeAlign(event) }}
                                >
                                    <FormattedMessage
                                        id="metricPreview.alignAxis"
                                    />
                                </Checkbox>
                            </div>

                            {/* Acivate / Unactivate stair mode */}
                            <div>
                                <Checkbox
                                    checked={stairMode}
                                    onChange={(event) => { this.onChangeStairMode(event) }}
                                >
                                    <FormattedMessage
                                        id="metricPreview.stairMode"
                                    />
                                </Checkbox>
                            </div>

                            {/* Manage axe group */}
                            <Button onClick={() => { this.setState({showGroupManagement: true}) }}>
                                <FormattedMessage id="metricPreview.groupModal.open" />                  
                            </Button>
                        </div>

                        {/* Group management modal */}
                        <Modal
                            closable={false}
                            title={(
                                <FormattedMessage id="metricPreview.groupModal.title" />
                            )}
                            visible={showGroupManagement}
                            width="60vw"
                            footer={[
                                <Fragment key="footer-fragment">
                                    {pendingGroupUdpates ? (
                                        // If therre are some kind of updates, display confirmation
                                        <Popconfirm
                                            title={<FormattedHTMLMessage id="axisGroupModal.close.warning" />}
                                            onConfirm={() => this.closeGroupModal()}
                                            okText={<FormattedMessage id="common.yes" />}
                                            cancelText={<FormattedMessage id="common.no" />}
                                            key="popover"
                                        >
                                            <Button key="cancel-popover" type="default">
                                                <FormattedMessage id="common.modal.close" />
                                            </Button>
                                        </Popconfirm>
                                    ) : (
                                        // If there are no pending updates, display close button
                                        <Button key="cancel" type="default" onClick={this.closeGroupModal}>
                                            <FormattedMessage id="common.modal.close" />
                                        </Button>
                                    )}
                                </Fragment>
                                
                            ]}
                        >
                            <div className="axis-group-modal">
                                <Input
                                    placeholder={intl.formatMessage({id: 'axisGroupModal.placeholder.group'} )}
                                    value={newGroupName}
                                    onChange={(e) => this.setState({newGroupName: e.target.value})} 
                                />
                                <Button 
                                    disabled={!newGroupName || newGroupName.length === 0 || !this.isGroupNameAvailable(newGroupName)}
                                    onClick={() => { this.addGroup() }}
                                >
                                    <FormattedMessage id="axisGroupModal.add" />
                                </Button>

                                {/* Manage group display */}
                                {pendingAxisGroup && pendingAxisGroup.length > 0 && (
                                    <div className="axis-group-modal-container">
                                        {pendingAxisGroup.map((group, groupIndex) => (
                                            <div 
                                                className="axis-group-modal-group"
                                                key={groupIndex}
                                            >
                                                {/* Group Header */}
                                                <div className="axis-group-modal-group-header">
                                                    {/* Header name */}
                                                    <div 
                                                        className="axis-group-modal-group-title"
                                                        style={group.editMode  ? {textAlign: 'left'} : {}}
                                                    >
                                                        {group.editMode ? (
                                                            <Input
                                                                placeholder={intl.formatMessage({id: 'axisGroupModal.placeholder.group'} )}
                                                                value={group.editName}
                                                                onChange={(e) => {this.changeGroupeName(e.target.value, groupIndex)}} 
                                                            />
                                                        ) : (
                                                            <h3>{group.name}</h3>
                                                        )}
                                                        
                                                    </div>

                                                    {/* Header actions */}
                                                    <div className="axis-group-modal-group-actions">
                                                        {group.editMode ? (
                                                            <Fragment>
                                                                <Fragment>
                                                                    {/* Confirm edit button */}
                                                                    <Popover content={<FormattedMessage id="axisGroupModal.confirm" />}>
                                                                        <Button 
                                                                            className="axis-group-modal-group-edit"
                                                                            disabled={(
                                                                                !group.editName
                                                                                || group.editName.length === 0
                                                                                || !this.isGroupNameAvailable(group.editName, true)
                                                                            )}
                                                                            onClick={() => {this.confirmEditGroup(groupIndex)}}
                                                                        >
                                                                            <Icon type="check" />
                                                                        </Button> 
                                                                    </Popover>
                                                                </Fragment>
                                                                
                                                                <Fragment>
                                                                    {/* Cancel edit button */}
                                                                    <Popover content={<FormattedMessage id="axisGroupModal.cancel" />}>
                                                                        <Button 
                                                                            className="axis-group-modal-group-edit"
                                                                            onClick={() => {this.changeEditGroupe(groupIndex, false)}}
                                                                        >
                                                                            <Icon type="close-circle" />
                                                                        </Button>
                                                                    </Popover>
                                                                </Fragment>
                                                            </Fragment>
                                                        ) : (
                                                            <Fragment>
                                                                {/* Edit button */}
                                                                <Popover content={<FormattedMessage id="axisGroupModal.edit" />}>
                                                                    <Button 
                                                                        className="axis-group-modal-group-edit"
                                                                        onClick={() => {this.changeEditGroupe(groupIndex, true)}}
                                                                    >
                                                                        <Icon type="form" />
                                                                    </Button>
                                                                </Popover>
                                                            </Fragment>
                                                        )}

                                                        {/* Delete button */}
                                                        <Popover content={<FormattedMessage id="axisGroupModal.delete" />}>
                                                            <Button 
                                                                className="axis-group-modal-group-delete"
                                                                onClick={() => {this.deleteGroupe(groupIndex)}}
                                                            >
                                                                <Icon type="delete" />
                                                            </Button>
                                                        </Popover>
                                                    </div>
                                                </div>

                                                {/* Group Body */}
                                                <div className="axis-group-modal-group-body">
                                                    { group.editMode ? (
                                                        <div>
                                                            { displayedMetric && Object.keys(displayedMetric).length > 0 && (
                                                                <ul style={{paddingLeft: "1em", marginTop: '0.5em'}}> 
                                                                    {Object.keys(displayedMetric).map((axe, axeIndex) => (
                                                                        <Fragment key={"axe_" + axeIndex}>
                                                                            {(!excludedMetrics.includes(axe)) && (
                                                                                <li style={{listStyle: "none"}}>
                                                                                    <Checkbox
                                                                                        checked={group.editAxis.includes(axe)}
                                                                                        onChange={(e) => { this.handleCheckedGroupAxe(e, axe, groupIndex) }}
                                                                                    >
                                                                                        <span>
                                                                                            { axe }
                                                                                        </span>
                                                                                    </Checkbox>
                                                                                </li>
                                                                            )}
                                                                        </Fragment>
                                                                    ))}
                                                                </ul>
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <ul>
                                                            {group.axis.map((metric) => (
                                                                <li key={metric}>
                                                                    <span>{metric}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    )}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </Modal>

                        {/* Graph display */}
                        <Fragment>
                            {/* Display loader while data are loading, or if data conversion failed */}
                            {(loading || !displayedMetric || !displayedMetric.date) ? (
                                // Display spinner while component is loading
                                <Spin size="large" />
                            ) : (
                                <LinearGraph 
                                    alignAxis={alignAxis}
                                    axisGroup={axisGroup}
                                    excludedMetrics={excludedMetrics}
                                    metric={displayedMetric}
                                    stairMode={stairMode}
                                    xAxis={xAxis}
                                />
                            )}
                        </Fragment>
                    </div>
            </div>
        )
    } 
}

LinearGraphContainer.propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    excludedMetrics: PropTypes.array.isRequired,
    jsonMetrics: PropTypes.array.isRequired,
    source: PropTypes.string.isRequired,
    xAxis: PropTypes.string.isRequired,
}

const mapStateToProps = ({
    app: {
        locale,
    }
}) => ({ locale });

export default connect(mapStateToProps)(injectIntl(LinearGraphContainer));
