import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Checkbox, Form, Modal, Icon, Popover, Select, Spin, Switch } from 'antd';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { linkLicense } from '../../utils/apiBucherVaslin';
import { isSuperAdmin, isGroupAdminClient } from '../../utils/helpers';
import RequiredFieldLegend from '../RequiredFieldLegend';

import './SwapLicenseOwnership.less';

const FormItem = Form.Item;
const { Option } = Select;

class SwapLicenseOwnership extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            wantsAllMachines: false,
            wantsAllUsers: false,
        };

        this.renderMachinesField = this.renderMachinesField.bind(this);
        this.renderUsersField = this.renderUsersField.bind(this);
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleChangeUsers = (checked) => {
        this.setState({ wantsAllUsers: checked });
    };

    handleChangeMachines = (checked) => {
        this.setState({ wantsAllMachines: checked });
    };

    handleOk = () => {
        const { refresh, intl, form, guid } = this.props;
        const { wantsAllMachines, wantsAllUsers } = this.state;
        this.setState({
            confirmLoading: true,
        });

        form.validateFields((err, values) => {
            if (err) {
                this.setState({
                    confirmLoading: false,
                });

                return;
            }
            linkLicense({
                guid,
                idUser: form.getFieldValue(wantsAllUsers ? 'idAllUser' : 'idMyUser'),
                idDevice: form.getFieldValue(wantsAllMachines ? 'idAllDevice' : 'idMyDevice'),
            }).then(({ isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
                this.setState({
                    confirmLoading: false,
                    visible: false,
                });
                refresh();
                isSuccess
                    ? Modal.success({
                        title: intl.formatMessage({
                            id: 'table.licenses.licenseAffectationSuccess',
                        }),
                    })
                    : Modal.error({
                        title: isUnauthorized
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({
                            id: 'table.licenses.licenseAffectationFail',
                        }),
                        content: isUnauthorized
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail
                    });

                this.setState({
                    confirmLoading: false,
                });
            });
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            confirmLoading: false,
        });
    };

    renderMachinesField() {
        const {
            myMachines,
            allMachines,
            userRole,
            intl,
            form: { getFieldDecorator },
        } = this.props;

        const { wantsAllMachines } = this.state;

        const filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

        return (
            <Fragment>
                {isSuperAdmin({ userRole }) && (
                    <Fragment>
                        <Switch
                            className="switch-all"
                            defaultChecked={false}
                            onChange={this.handleChangeMachines}
                        />
                        <FormattedMessage
                            id="licenses.switchDevices.label"
                            defaultMessage="Show all devices"
                        />
                    </Fragment>
                )}
                <FormItem
                    label={intl.formatMessage({
                        id: wantsAllMachines ? 'table.licenses.allDevices' : 'table.licenses.devices',
                    })}
                >
                    {isGroupAdminClient({ userRole }) && wantsAllMachines
                        ? getFieldDecorator('idAllDevice', {
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'table.licenses.requiredDevice',
                                    }),
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                placeholder={intl.formatMessage({
                                    id: 'table.licenses.devicePlaceholder',
                                })}
                                style={{ width: '100%' }}
                            >
                                {allMachines.map(({ id, name, client: { nomClient } }) => (
                                    <Option key={id}>{`${nomClient} - ${name}`}</Option>
                                ))}
                            </Select>,
                        )
                        : getFieldDecorator('idMyDevice', {
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'table.licenses.requiredDevice',
                                    }),
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                placeholder={intl.formatMessage({
                                    id: 'table.licenses.devicePlaceholder',
                                })}
                                style={{ width: '100%' }}
                            >
                                {myMachines.map(({ id, name }) => (
                                    <Option key={id}>{name}</Option>
                                ))}
                            </Select>,
                        )}
                </FormItem>
            </Fragment>
        );
    }

    renderUsersField(isConcessionnaire) {
        const {
            allUsers,
            concessionaireUsers,
            form: { getFieldDecorator },
            intl,
            myUsers,
            userRole,
        } = this.props;

        const { wantsAllUsers } = this.state;

        const filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

        let labelId = 'table.licenses.users';
        if (isConcessionnaire) {
            labelId = 'table.licenses.concessionnairesUsers';
        }
        if (wantsAllUsers) {
            labelId = 'table.licenses.allUsers';
        }

        return (
            <Fragment>
                {isSuperAdmin({ userRole }) ? (
                    <Fragment>
                        <Switch
                            className="switch-all"
                            defaultChecked={false}
                            onChange={this.handleChangeUsers}
                        />
                        <FormattedMessage id="licenses.switchUsers.label" defaultMessage="Show all users" />
                    </Fragment>
                ) : null}
                <FormItem
                    label={intl.formatMessage({
                        id: labelId,
                    })}
                >
                    {(isGroupAdminClient({ userRole }) || isConcessionnaire) && wantsAllUsers
                        ? getFieldDecorator('idAllUser', {
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'table.licenses.requiredUser',
                                    }),
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                placeholder={intl.formatMessage({
                                    id: 'table.licenses.userPlaceholder',
                                })}
                                style={{ width: '100%' }}
                            >
                                {allUsers && allUsers.map(({ idUser, nomPrenom, client: { nomClient } }) => (
                                    <Option key={idUser}>{`${nomClient} - ${nomPrenom}`}</Option>
                                ))}
                            </Select>,
                        )
                        : getFieldDecorator('idMyUser', {
                            rules: [
                                {
                                    required: true,
                                    message: intl.formatMessage({
                                        id: 'table.licenses.requiredUser',
                                    }),
                                },
                            ],
                        })(
                            <Select
                                showSearch
                                optionFilterProp="children"
                                filterOption={filterOption}
                                placeholder={intl.formatMessage({
                                    id: 'table.licenses.userPlaceholder',
                                })}
                                style={{ width: '100%' }}
                            >
                                {isConcessionnaire ? (
                                    concessionaireUsers && concessionaireUsers.map(({ id, userName }) => (
                                        <Option key={id}>{userName}</Option>
                                    ))
                                ) : (
                                    myUsers && myUsers.map(({ idUser, nomPrenom }) => (
                                        <Option key={idUser}>{nomPrenom}</Option>
                                    ))
                                )}
                            </Select>,
                        )}
                </FormItem>
            </Fragment>
        );
    }
    render() {
        const { visible, confirmLoading } = this.state;
        const { intl, isLicenseChild, isMultiUsers, myMachines, myUsers, profil, form: { getFieldDecorator } } = this.props;
        const escapeProfil = (profil === 'Connexion'|| profil === 'VNC') ? true : false;
        
        return (
            <span>
                <Popover content={<FormattedMessage id="table.licenses.ownershipSwitch" />}>
                    <Button onClick={this.showModal}>
                        <Icon type="retweet" />
                    </Button>
                </Popover>
                <Modal
                    title={(
                        <FormattedMessage
                            id="table.licenses.ownershipSwitch"
                            defaultMessage="Change license ownership"
                        />
                    )}
                    visible={visible}
                    confirmLoading={confirmLoading}
                    okText={<FormattedMessage id="common.validate" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    {myMachines && myUsers ? (
                        <Form layout="vertical">
                            {isMultiUsers && !isLicenseChild && this.renderMachinesField()}
                            {!isMultiUsers && !escapeProfil && this.renderUsersField(false)}
                            {isMultiUsers && isLicenseChild && this.renderUsersField(true)}
                            {escapeProfil && this.renderMachinesField()}
                            <RequiredFieldLegend />
                            <FormItem>
                                {
                                    getFieldDecorator('terms', {
                                        valuePropName: 'checked',
                                        initialValue: false,
                                        rules: [{
                                            required: true,
                                            transform: (value) => value || undefined,
                                            type: 'boolean',
                                            message: intl.formatMessage({
                                                id: 'cgu.required',
                                            }),
                                        }],
                                    })(
                                        <Checkbox>
                                            <u>
                                                <Link to="/cgu">
                                                    <FormattedHTMLMessage id="cgu.full" />
                                                </Link>
                                            </u>
                                        </Checkbox>,
                                    )
                                }
                            </FormItem>
                        </Form>
                    ) : (
                        <div className="spinner-container">
                            <Spin />
                        </div>
                    )}
                </Modal>
            </span>
        );
    }
}

SwapLicenseOwnership.propTypes = {
    refresh: PropTypes.func,
    intl: intlShape.isRequired,
    guid: PropTypes.string.isRequired,
    form: PropTypes.shape({
        validateFieldsAndScroll: PropTypes.func.isRequired,
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    myMachines: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.bool]),
    allMachines: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.object), PropTypes.bool]),
    myUsers: PropTypes.arrayOf(PropTypes.object),
    allUsers: PropTypes.arrayOf(PropTypes.object),
    concessionaireUsers: PropTypes.arrayOf(PropTypes.object),
    isLicenseChild: PropTypes.bool.isRequired,
    isMultiUsers: PropTypes.bool.isRequired,
    userRole: PropTypes.array.isRequired,
    profil: PropTypes.string.isRequired,
};

const mapStateToProps = ({ signIn: { userRole } }) => ({ userRole });

export default Form.create()(connect(mapStateToProps)(injectIntl(SwapLicenseOwnership)));
