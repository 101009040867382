import { notification, Spin, Tabs} from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import PageLayout from '../Layout/PageLayout';
import EditForm from './EditForm';
import  UserRulesForm  from './UserRulesForm';
import { setLocale } from '../../actions/app';
import {
    editUserForClient,
    fetchUserDetailsById,
} from '../../utils/apiBucherVaslin';
import { isGroupAdminClient, isSuperAdmin } from '../../utils/helpers';


const { TabPane } = Tabs;

class UserEdit extends Component {
    state = {
        userInfo: null,
        loadUser: false,
    };

    componentDidMount() {
        const { match: { params: { id } }, userID, intl } = this.props;

        fetchUserDetailsById(id || userID).then(({ item: userInfo, isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
            if (!isSuccess){
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail,
                });
            }
            this.setState({
                userInfo,
                loadUser: true,
            });
        });
    }

    saveUser = (values) => {
        const { handleChangeLocale, intl } = this.props;
        const { userInfo } = this.state;
        values.mobileNumber = values.mobileNumber ? values.mobileNumber : '';
        values.phoneNumber = values.phoneNumber ? values.phoneNumber : '';

        editUserForClient({
            ...userInfo,
            ...values,
        }).then(({
            isSuccess,
            isUnauthorized,
            errorMessage,
            errorDetail
        }) => {
            if (isSuccess) {
                notification.success({
                    message: intl.formatMessage({ id: 'user.edit.notification.title' }),
                    description: intl.formatMessage({ id: 'user.edit.notification.description' }, { name: values.nomPrenom }),
                });

                handleChangeLocale(values.Lang);

                return true;
            }

            notification.error({
                message: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : errorDetail,
            });

            return null;
        });
    };

    render() {
        const { userInfo, loadUser } = this.state;
        const { intl, pageKey, history } = this.props;

        if (!userInfo && !loadUser) {
            return (
                <PageLayout pageKey={pageKey || 'users'} history={history}>
                    <Spin />
                </PageLayout>
            );
        }
        else if (!userInfo && loadUser) {
            // Cas erreur chargement données
            return (
                <PageLayout pageKey={pageKey || 'users'} history={history}>
                </PageLayout>
            );
        }

        let userRightId = 'user.role.standard';
        if (isSuperAdmin({ userRole: userInfo.role })) {
            userRightId = 'user.role.superadmin';
        } else if (isGroupAdminClient({ userRole: userInfo.role })) {
            userRightId = 'user.role.admin';
        }
        const userRight = intl.formatMessage({ id: userRightId });

        return (
            <PageLayout pageKey={pageKey || 'users'} history={history}>
                <h2>
                    <FormattedMessage
                        id="user.edit.title"
                        defaultMessage="Edit a user"
                        values={{
                            userRight,
                        }}
                    />
                </h2>

                <Tabs type="card" defaultActiveKey="1">
                    {/* User info  */}
                    <TabPane tab={<FormattedMessage id="client.edit.tab.infos" />} key="1">                   
                            <EditForm
                                user={userInfo}
                                save={this.saveUser}
                            />
                    </TabPane>
                    {/* User rules */}
                    <TabPane tab={<FormattedMessage id="user.edit.tab.role" />} key="2">
                            <UserRulesForm idUser={userInfo.idUser}/>
                    </TabPane>
                </Tabs>
                
            </PageLayout>
        );
    }
}

UserEdit.propTypes = {
    handleChangeLocale: PropTypes.func.isRequired,
    pageKey: PropTypes.string,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string,
        }).isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    userID: PropTypes.string,
};

const mapStateToProps = ({ signIn: { userID } }) => ({ userID });

const mapDispatchToProps = {
    handleChangeLocale: setLocale,
};

export default injectIntl(connect(mapStateToProps, mapDispatchToProps, null, { pure: false })(UserEdit));
