import PropTypes from 'prop-types';
import React, { Fragment, Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { notification, Spin, Table, Divider, Input, Button } from 'antd';
import { connect } from 'react-redux';
import { isNil } from 'lodash';
import PageLayout from '../Layout/PageLayout';
import moment from 'moment';
import { fetchDevices } from '../../utils/apiBucherVaslin';
import { alphabetically, isGroupAdminClient, isSuperAdmin, isGroupConcessionnaire, compareDate, isInfoCompleted } from '../../utils/helpers';
import GiveMachineNoConnected from '../GiveMachineNoConnected';
import ActivateGuarantee from './ActivateGuarantee';

const tableName = 'devices';
const mongoDateFormat = 'YYYYMMDD HH:mm:ss';
const renderedDate = 'DD/MM/YY';
const renderDate = (date) => (date ? <span>{moment(date).format(renderedDate)}</span> : '-');

class Devices extends Component {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            devices: [],
            canAccess: false,
        };
    }

    async componentDidMount() {
        /* check connexion and information */ 
        const { userID, intl } = this.props;
        await isInfoCompleted(intl, userID);
          
        this.fetchDataAsync();
    }

    async fetchDataAsync() {
        this.setState({ loading: false, canAccess: false });
        const { intl, match: { params: { eureka }} } = this.props;

        let { isSuccess, items: devices, isUnauthorized, errorMessage, errorDetail } = await fetchDevices(eureka)();

        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            this.setState({ canAccess: false, loading: true });
            return;
        }
        this.setState({ devices, canAccess: true, loading: true });
    }

    getColumns = () => {
        return [
            {
                dataIndex: 'libelleFamilleCommerciale',
                key: 'libelleFamilleCommerciale',
                sorter: (a, b) => alphabetically(a.libelleFamilleCommerciale, b.libelleFamilleCommerciale),
                filterDropdown: this.filterDropDown,
                onFilter: (value, { libelleFamilleCommerciale }) => this.onFilter(value, libelleFamilleCommerciale),
            }, {
                dataIndex: 'libelleGamme',
                key: 'libelleGamme',
                sorter: (a, b) => alphabetically(a.libelleGamme, b.libelleGamme),
                filterDropdown: this.filterDropDown,
                onFilter: (value, { libelleGamme }) => this.onFilter(value, libelleGamme),
            }, {
                dataIndex: 'numeroLot',
                key: 'numeroLot',
                sorter: (a, b) => alphabetically(a.numeroLot, b.numeroLot),
                filterDropdown: this.filterDropDown,
                onFilter: (value, { numeroLot }) => this.onFilter(value, numeroLot),
            }, {
                dataIndex: 'codeArticle',
                key: 'codeArticle',
                sorter: (a, b) => alphabetically(a.codeArticle, b.codeArticle),
                filterDropdown: this.filterDropDown,
                onFilter: (value, { codeArticle }) => this.onFilter(value, codeArticle),
            }
            , {
                dataIndex: 'lotValidationDate',
                key: 'lotValidationDate',
                sorter: (a, b) => compareDate(a.lotValidationDate, b.lotValidationDate, mongoDateFormat),
                render: renderDate
            }
        ];
    };

    getActions = (userRole, clientID) => [
        {
            key: 'action',
            render: (_, { name: machineName, active, hasLicenceConnexion, serialIot: serial, codeConcessionaire, lotGuaranteeExist, lotGuaranteeId, lotGuaranteeOnWaiting, lotValidationDate, ...props }) => (
                <span>
                    {/* Bouton transfert machine */}
                    {
                        <Fragment>
                            <GiveMachineNoConnected 
                                {...props}
                                lotGuaranteeExist={lotGuaranteeExist}
                                serial={serial}
                                refresh={() => this.fetchDataAsync()}
                                disable= {(isGroupAdminClient({ userRole }) || (isGroupConcessionnaire({ userRole}) && clientID == codeConcessionaire)) ? false : true}
                            />
                            <Divider type="vertical" />
                        </Fragment>
                    } 
                    {lotValidationDate ?  
                    /* Bouton permettant de modifier la garantie */
                    (isGroupAdminClient({ userRole }) || (isGroupConcessionnaire({ userRole}) && clientID == codeConcessionaire))
                    && ( 
                        <Fragment>
                            <ActivateGuarantee 
                                {...props}
                                askActivate={isGroupConcessionnaire({ userRole })} 
                                isUpdate={true}
                                isConnectedDevice={false}
                                lotGuaranteeId={lotGuaranteeId}
                                refresh={() => this.fetchDataAsync()}
                                serial={serial}
                                disable = {lotValidationDate ? false : true}
                                warranty={true}
                                guaranteeIsValidate = {true} 
                                authorizeTransfert = {true}
                            />
                            <Divider type="vertical" />
                        </Fragment> 
                    )
                    :
                    /* Bouton permettant de valider la garantie */
                    (isGroupAdminClient({ userRole }) || (isGroupConcessionnaire({ userRole}) && clientID == codeConcessionaire))
                    && ( 
                        <Fragment>
                            <ActivateGuarantee 
                                {...props}
                                askActivate={isGroupConcessionnaire({ userRole }) } 
                                isConnectedDevice={false}
                                lotGuaranteeId={lotGuaranteeId}
                                refresh={() => this.fetchDataAsync()}
                                serial={serial}
                                disable = {!lotGuaranteeOnWaiting  && lotGuaranteeId === 0 ? false : true}
                                warranty={true}
                                guaranteeIsValidate =  {isGroupConcessionnaire({ userRole }) && !isSuperAdmin({ userRole }) ? false : true } 
                                authorizeTransfert = {true}
                            />
                            <Divider type="vertical" />
                        </Fragment>
                    )
                    }
                </span>
            ),
        }
    ];

    onFilter = (value, obj) => {
        return obj && obj.toLowerCase().includes(value.toLowerCase());
    };

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    render() {
        const { match: { params: { eureka } }, clientID, userRole } = this.props;
        const { canAccess, devices, loading } = this.state;

        if (!loading){
            return (
                <PageLayout pageKey={isNil(eureka) ? 'myDevices' : 'customers'}>
                    <h2>
                        {eureka ? (
                            <FormattedMessage
                                id={`table.${tableName}.listTitle`}
                            />
                        ) : (
                            <FormattedMessage
                                id={`table.${tableName}.title`}
                            />
                        )}

                    </h2>
                    <Spin size="small" />
                </PageLayout>
            );
        }

        if (!canAccess){
            return (
                <PageLayout pageKey={isNil(eureka) ? 'myDevices' : 'customers'}>
                    <h2>
                        {eureka ? (
                            <FormattedMessage
                                id={`table.${tableName}.listTitle`}
                            />
                        ) : (
                            <FormattedMessage
                                id={`table.${tableName}.title`}
                            />
                        )}

                    </h2>
                    <h3 style={{color: 'red'}}>{<FormattedMessage id="error.unauthorized.title" />}</h3>
                </PageLayout>
            );
        }

        const actions = [...this.getActions(userRole, clientID, eureka)];
        const columns = [...this.getColumns(), ...actions];
        const translatedColumns = columns.map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        return (
            <PageLayout pageKey={isNil(eureka) ? 'myDevices' : 'customers'}>
                <h2>
                    {eureka ? (
                        <FormattedMessage
                            id={`table.${tableName}.listTitle`}
                        />
                    ) : (
                        <FormattedMessage
                            id={`table.${tableName}.title`}
                        />
                    )}

                </h2>
                <Table
                    className="custom-table"
                    dataSource={devices}
                    columns={translatedColumns}
                    loading={!loading}
                    rowKey="numeroLot"
                    tableName={tableName}
                />
            </PageLayout>
        );
    }
}

Devices.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            eureka: PropTypes.string,
        }).isRequired,
    }).isRequired,
    clientID: PropTypes.string.isRequired,
    userRole: PropTypes.array.isRequired,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ signIn: { clientID, userID, userRole } }) => ({
    clientID,
    userRole,
    userID
});

export default injectIntl(connect(mapStateToProps)(Devices));
