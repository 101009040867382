import React, { Fragment } from "react";
import { Switch, notification } from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import { setUserAlerts } from "../../utils/apiBucherVaslin";

const SubscriptionAlert = ({ user, intl, isChecked }) => {
  const onChangeSwitch = ({ check }) => {
    setUserAlerts({
      isEmailDealerRequestAlertActivated: check,
      idUser: user.idUser,
    }).then(({ isSuccess, isUnauthorized }) => {
      if (isSuccess) {
        notification.success({
          message: intl.formatMessage({
            id: "notification.edit.title",
          }),
          description: intl.formatMessage({
            id: "notification.edit.success.description",
          }),
        });
        return true;
      } else {
        notification.error({
          message: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.title" })
            : intl.formatMessage({ id: "common.error" }),
        });
      }
    });
  };
  return (
    <Fragment>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span style={{ marginRight: "5px" }}>
          <FormattedMessage id="notification.networkRequests.alerte" />:
        </span>
        <Switch
          defaultChecked={isChecked}
          onChange={(checked) =>
            onChangeSwitch(
              { check: checked },
              "IsEmailDealerRequestAlertActivated"
            )
          }
        ></Switch>
      </div>
    </Fragment>
  );
};

export default injectIntl(SubscriptionAlert);
