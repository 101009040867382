import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PageLayout from '../Layout/PageLayout';
import { Button, Input, InputNumber, notification, Popover, Spin, Table, DatePicker } from 'antd';
import {
    getHistoricProcess
} from '../../utils/apiBucherVaslin';
import { alphabetically, compareDate } from '../../utils/helpers';
import moment from 'moment';

const tableName = 'histoProcess';

const mongoDateFormat = 'YYYYMMDD HH:mm:ss';
const dateOnlyFormat = 'DD/MM/YY';

const renderDate = (date) => (date ? (
    <Popover content={moment(date, mongoDateFormat).format('DD/MM/YY HH:mm:ss')}>
        <span>{moment(date, mongoDateFormat).format('DD/MM/YY HH:mm:ss')}</span>
    </Popover>
) : (
    '-'
));

const renderOnlyDate = (date) => (date ? (
    <Popover content={moment(date, mongoDateFormat).format('DD/MM/YY')}>
        <span>{moment(date, mongoDateFormat).format('DD/MM/YY')}</span>
    </Popover>
) : (
    '-'
));

class ListProcess extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadData: false,
            numberLine: 20,
            histoProcess: [],
            nameDevice: null,
        };
    }

    async componentDidMount(){
        const { numberLine } = this.state;
        this.fetchDataAsync(numberLine);
    }

    async fetchDataAsync(numberLine){
        const {
            match: {
                params: { serial },
            },
            location: {
                state: { nameDevice },
            },
            intl,
        } = this.props;
        this.setState({ loadData: false, nameDevice });

        const { items: histoProcess, isSuccess, isUnauthorized, errorMessage, errorDetail} = await getHistoricProcess()({ serial, numberLine });

        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }

        this.setState({ loadData: true, histoProcess });
    }

    onFilter = (value, obj) => {
        if (obj === true || obj === false) {
            return obj == value;
        }
        // Cas des dates : on convertit l'objet avec le même format d'affichage (pour que le match corresponde)
        if (obj && obj.includes('T')){
            obj = moment(obj, mongoDateFormat).format('DD/MM/YY'); 
        }
        return obj && obj.toLowerCase().includes(value.toLowerCase()); 
    };

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    getColumns() {
        return [{
            dataIndex: 'dateAdded',
            key: 'dateAdded',
            sorter: (a, b) => compareDate(a.dateAdded, b.dateAdded, mongoDateFormat),
            render: renderDate,
            filterDropdown: this.filterDateDropDown,
            onFilter: (value, record) => this.onFilter(value, record.dateAdded),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'dateCycle',
            key: 'cycle',
            sorter: (a, b) => compareDate(a.dateCycle, b.dateCycle, mongoDateFormat),
            render: renderDate,
            filterDropdown: this.filterDateDropDown,
            onFilter: (value, record) => this.onFilter(value, record.dateCycle),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'libelleCorrespondance',
            key: 'message',
            sorter: (a, b) => alphabetically(a.libelleCorrespondance, b.libelleCorrespondance),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.libelleCorrespondance),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }];
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    filterDateDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <DatePicker
                format={dateOnlyFormat}
                onChange={(date, dateString) => setSelectedKeys(dateString ? [dateString] : [])}
                onOk={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    render(){
        const { loadData, histoProcess, nameDevice, numberLine } = this.state;
        const { history } = this.props;

        if (!loadData){
            return (
                <PageLayout pageKey="histoProcess" history={history}>
                    <h2>{<FormattedMessage id="cycles.process.title" values={{name: nameDevice}} />}</h2>
                    <Spin size="small" />
                </PageLayout>
            );
        }

        const translatedColumns = this.getColumns().map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        return (
            <PageLayout pageKey="histoProcess" history={history}>
                <h2>{<FormattedMessage id="cycles.process.title" values={{name: nameDevice}} />}</h2>
                {histoProcess && histoProcess.length > 0 && (
                    <Fragment>
                        <FormattedMessage id="cycles.process.numberLine" />
                        <InputNumber
                            defaultValue={numberLine}
                            className="input-number"
                            size="medium"
                            onChange={ value => this.setState({ numberLine: value })}
                        />
                        <Button htmlType="button" onClick={ () => this.fetchDataAsync(numberLine)}>
                            <FormattedMessage id="cycles.process.search.confirm" />
                        </Button>
                    </Fragment>
                )}
                <Table 
                    className="custom-table"
                    dataSource={histoProcess}
                    columns={translatedColumns}
                    loading={!loadData}
                    rowKey={record => record.idHistory + '_' + record.libelleCorrespondance}
                    tableName={tableName}
                />
            </PageLayout>
        );
    }
}

ListProcess.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            serial: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            nameDevice: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
}

const mapStateToProps = ({}) => ({

});

export default injectIntl(connect(mapStateToProps)(ListProcess));