export const convertLocaleToBVLocale = (locale) => {
    // Change code for español
    if ('es' === locale) {
        return 'sp';
    }

    return locale;
};

export const formatLocaleToNavigator = (locale) => {
    // Change code for español
    if ('sp' === locale) {
        return 'es';
    }

    return locale;
};
