import React from 'react';
import { Button, Input, notification, Table } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import PageLayout from './Layout/PageLayout';
import { alphabetically } from '../utils/helpers';
import { fetchDirectory } from '../utils/apiBucherVaslin';

const tableName = 'directory';

class Directory extends React.Component {
    state = {
        items: [],
        loading: false,
    };

    async componentDidMount() {
        this.setState({
            items: [],
            loading: true,
        });

        const { intl } = this.props;
        const { items, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchDirectory();
        this.setState({
            items,
            loading: false,
        });
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
    }

    onFilter = (value, obj) => obj && obj.toLowerCase().includes(value.toLowerCase());

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    getColumns() {
        return [{
            dataIndex: 'deviceType',
            key: 'deviceType',
            sorter: (a, b) => alphabetically(a.deviceType, b.deviceType),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.deviceType),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }, {
            dataIndex: 'adresseOpcua',
            key: 'adresseOpcua',
            sorter: (a, b) => alphabetically(a.adresseOpcua, b.adresseOpcua),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.adresseOpcua),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }, {
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => alphabetically(a.type, b.type),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.type),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }, {
            dataIndex: 'commentaire',
            key: 'commentaire',
            sorter: (a, b) => alphabetically(a.commentaire, b.commentaire),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.commentaire),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }, {
            dataIndex: 'softVersion',
            key: 'softVersion',
            sorter: (a, b) => alphabetically(a.softVersion, b.softVersion),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.softVersion),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }, {
            dataIndex: 'profilEveryone',
            key: 'profilEveryone',
            sorter: (a, b) => alphabetically(a.profilEveryone, b.profilEveryone),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.profilEveryone),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }, {
            dataIndex: 'profilSupervisions',
            key: 'profilSupervisions',
            sorter: (a, b) => alphabetically(a.profilSupervisions, b.profilSupervisions),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.profilSupervisions),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }];
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    render() {
        const { items, loading } = this.state;
        const { history } = this.props;

        const translatedColumns = this.getColumns().map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        return (
            <PageLayout pageKey="directory" history={history}>
                <Table
                    className="custom-table"
                    dataSource={items}
                    columns={translatedColumns}
                    loading={loading}
                    rowKey="id"
                    tableName={tableName}
                    scroll={{ x: true }}
                />
            </PageLayout>
        );
    }
}

Directory.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Directory);
