import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { isNil } from 'lodash';
import PageLayout from '../Layout/PageLayout';
import EditForm from './EditForm';
import {
    fetchCustomerDetailsById,
    fetchCurrentCustomerDetails,
    updateClient,
} from '../../utils/apiBucherVaslin';
import { Alert, Divider, notification, Tabs } from 'antd';
import { connect } from 'react-redux';
import CustomerSettings from './Settings';
import ClientAddress from './ClientAddress';
import { isGroupAdminClient, isGroupEBV, isSuperAdmin, isInfoCompleted } from '../../utils/helpers';

const { TabPane } = Tabs;

class ClientEdit extends Component {
    state = {
        clientInfo: null,
        spinUpdateClient: false
    };

    async componentDidMount() {
        const { match: { params: { eureka } }, intl, userID } = this.props;
        /* check connexion and information */ 
        await isInfoCompleted(intl, userID);
        
        if (!isNil(eureka)) {
            fetchCustomerDetailsById(eureka).then(({ item: clientInfo, isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
                if (!isSuccess){
                    notification.error({
                        message: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorDetail,
                    });
                }

                this.setState({
                    clientInfo,
                });
            });
        } else {
            fetchCurrentCustomerDetails().then(({ clientInfo }) => {
                this.setState({
                    clientInfo,
                });
            });
        }
    }

    saveClient = (values) => {
        this.setState({spinUpdateClient:true})
        const { clientInfo } = this.state;
        const { intl } = this.props;

        const formatedValues = { ...values.client };
        if (values.fichiers && values.fichiers.fileList) {
            formatedValues.fichiers = values.fichiers.fileList.map((f) => f.originFileObj);
            if (1 === formatedValues.fichiers.length) {
                formatedValues.fichiers = formatedValues.fichiers.shift();
            }
        } else {
            formatedValues.fichiers = [];
        }

        updateClient({
            ...clientInfo,
            ...formatedValues,
        }).then(({
            isSuccess,
            isUnauthorized,
            errorMessage,
            errorDetail
        }) => {
            this.setState({spinUpdateClient:false})
            if (isSuccess) {
                notification.success({
                    message: intl.formatMessage({ id: 'client.edit.notification.title' }),
                    description: intl.formatMessage({ id: 'client.edit.notification.description' }),
                });
                return;
            }

            notification.error({
                message: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : errorDetail,
            });
        });
    };

    render() {
        const { clientInfo, spinUpdateClient } = this.state;
        const { match: { params: { eureka } }, history, userRole} = this.props;

        return (
            <PageLayout pageKey={isNil(eureka) ? 'myInformation' : 'customers'} history={history}>
                <h2>
                    <FormattedMessage
                        id="client.edit.title"
                        defaultMessage="Edit a client"
                    />
                </h2>
               
                {(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole })) ? 
                (isGroupEBV({userRole}) ? 
                    <Fragment>
                        <Alert message={<FormattedMessage id="client.address.ebvInfo" />} type="info" />
                        <Divider></Divider>
                    </Fragment>
                 : 
                    <Fragment>
                        <Alert message={<FormattedMessage id="client.address.userInfo" />} type="info" />
                        <Divider></Divider>
                    </Fragment>
                ) : ''}

                <Tabs type="card" defaultActiveKey="1">
                    {/* Personal infos tab */}
                    <TabPane tab={<FormattedMessage id="client.edit.tab.infos" />} key="1">
                        { null === clientInfo ? null : (
                            <EditForm
                                initialValues={clientInfo}
                                save={this.saveClient}
                                spinUpdateClient = {spinUpdateClient}
                            />
                        )}
                    </TabPane>

                    {/* Principal address tab */}
                    <TabPane tab={<FormattedMessage id="client.edit.tab.address.principal" />} key="2">
                        { null === clientInfo ? null : (
                            <ClientAddress client={clientInfo}/>
                        )}
                    </TabPane>

                    {/* User settings tab */}
                    <TabPane tab={<FormattedMessage id="client.edit.tab.settings" />} key="3">
                        <Divider>
                            <FormattedMessage
                                id="customerDetails.line.settingsTitle"
                                defaultMessage="Account settings"
                            />
                        </Divider>
                        <CustomerSettings
                            initialValues={clientInfo}
                        />
                    </TabPane>
                </Tabs>
                
            </PageLayout>
        );
    }
}

ClientEdit.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            eureka: PropTypes.string,
        }).isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({signIn: { userRole, userID },
}) => ({ userRole, userID });

export default injectIntl(connect(mapStateToProps, { pure: false })(ClientEdit));
