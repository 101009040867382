import { faKey } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, notification, Popconfirm, Popover, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { generateUserPassword } from '../utils/apiBucherVaslin';

class PasswordGenerate extends Component {
    state = {
        loading: false,
    };

    async askNewPassword() {
        const { intl, name, email } = this.props;

        this.setState({ loading: true });
        const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await generateUserPassword(email);
        this.setState({ loading: false });
        if (isSuccess) {
            notification.success({
                message: intl.formatMessage({ id: 'table.users.generatePassword.title' }),
                description: intl.formatMessage({ id: 'table.users.generatePassword.description' }, { name }),
                duration: 0,
            });

            return true;
        }

        notification.error({
            message: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.title' })
            : intl.formatMessage({ id: 'common.error' }),
            description: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.message' })
            : errorDetail,
        });

        return null;
    }

    render() {
        const { loading } = this.state;

        if (loading) {
            return (
                <Button htmlType="button">
                    <Spin size="small" />
                </Button>
            );
        }

        return (
            <Popover content={<FormattedMessage id="table.users.generatePassword" />}>
                <Popconfirm
                    title={<FormattedMessage id="table.users.generatePassword" />}
                    onConfirm={() => this.askNewPassword()}
                    okText={<FormattedMessage id="common.yes" />}
                    cancelText={<FormattedMessage id="common.no" />}
                >
                    <Button htmlType="button">
                        <FontAwesomeIcon icon={faKey} />
                    </Button>
                </Popconfirm>
            </Popover>
        );
    }
}

PasswordGenerate.propTypes = {
    intl: intlShape.isRequired,
    name: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
};

export default injectIntl(PasswordGenerate);
