import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button, Divider, Modal, Popover } from 'antd';
import { fetchFilesOrder } from '../../utils/apiBucherVaslin';


const ButtonSpin = ({ isSpinning, onClick }) => {

    return (
        <Popover content={<FormattedMessage id="table.order.download" />}>
        <Button onClick={onClick}>
            {isSpinning ?       
        <FontAwesomeIcon icon={faSpinner} spin /> :
        <FontAwesomeIcon icon={faDownload}  /> }
        </Button>
        </Popover>
    );
  };
  
class DownloadFilesOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visible: false,
            arcFiles: [],
            blFiles: [],
            billFiles: [],
            isSpinning: false
        };
    }
    
    fetchFiles = async () => {

        this.setState({ isSpinning: true });
        
        const { intl, id } = this.props;
        const { files, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchFilesOrder(id);
        let arcFilesTemps = [];
        let blFilesTemps = [];
        let billFilesTemps = [];
               
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            this.setState({ isSpinning: false });
        }
        
    Object.keys(files).forEach(function(filesTemps) {
        if(files[filesTemps].anonymousFileName.startsWith('FAC'))
            billFilesTemps.push(files[filesTemps])
        if (files[filesTemps].anonymousFileName.startsWith('BUL'))  
            blFilesTemps.push(files[filesTemps])
        if (files[filesTemps].anonymousFileName.startsWith('ARC')) 
            arcFilesTemps.push(files[filesTemps])  
    });
        this.setState({ arcFiles: arcFilesTemps ,blFiles: blFilesTemps, billFiles: billFilesTemps , visible: true, isSpinning: false });
    }

    onCancel() {
        this.setState({ visible: false });
    }

    render() {
        const { visible, arcFiles, blFiles, billFiles } = this.state;
        const { id } = this.props;
        return (
            <Fragment>
                <Modal
                    title={<FormattedMessage id="table.order.download.title" />}
                    visible={visible}
                    onCancel={() => this.onCancel()}
                    footer={[
                        <Button type="danger" key={id} onClick={() => this.onCancel()}>
                          {<FormattedMessage id="common.cancel" />}
                        </Button>
                      ]}
                    width="40%"
                > 
                <Divider><FormattedMessage id="table.order.download.arc" /></Divider>
                    {arcFiles.length > 0 ? arcFiles.map((arc) => (
                        <Fragment>
                            <a href={arc.urlFile}>{arc.anonymousFileName}</a>
                            <br />  
                        </Fragment>
                    )): <FormattedMessage id="table.order.download.noArc" />}
                <Divider><FormattedMessage id="table.order.download.bl" /></Divider>
                    {blFiles.length > 0 ? blFiles.map((bl) => (
                        <Fragment>
                            <a href={bl.urlFile}>{bl.anonymousFileName}</a>
                            <br />  
                        </Fragment>
                    )): <FormattedMessage id="table.order.download.noBl" /> }
                <Divider><FormattedMessage id="table.order.download.bill" /></Divider>
                    {billFiles.length > 0 ? billFiles.map((bill) => (
                        <Fragment>
                            <a href={bill.urlFile}>{bill.anonymousFileName}</a>
                            <br />                 
                        </Fragment>
                    )): <FormattedMessage id="table.order.download.noBill" />}
                </Modal>
                <ButtonSpin isSpinning={this.state.isSpinning} onClick={this.fetchFiles} />
            </Fragment>
        );
    }
}

DownloadFilesOrder.propTypes = {
    intl: intlShape.isRequired,
    id: PropTypes.string.isRequired,
};

export default injectIntl(DownloadFilesOrder);
