import { addLocaleData } from 'react-intl';
import frSupport from 'react-intl/locale-data/fr';
import enSupport from 'react-intl/locale-data/en';
import esSupport from 'react-intl/locale-data/es';
import deSupport from 'react-intl/locale-data/de';
import itSupport from 'react-intl/locale-data/it';
import fr from './fr.json';
import es from './es.json';
import de from './de.json';
import it from './it.json';
import en from './en.json';

addLocaleData([...frSupport, ...enSupport, ...esSupport, ...deSupport, ...itSupport]);

// The API doesn't comply with ISO 639-1 so I have to make this dirty little thing ¯\_(ツ)_/¯
export const locales = {
    fr: 'fr',
    es: 'es',
    de: 'de',
    it: 'it',
    en: 'en',
};

export default { fr, en, es, de, it };
