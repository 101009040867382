import { Button, Divider, Form, notification, Radio, Select, Spin } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { fetchCustomers, checkEmailExistOnUser, editUserForClient, createUserForClient} from '../../utils/apiBucherVaslin';
import { formItemLayout, tailFormItemLayout } from '../../utils/formItemLayout';
import RequiredFieldLegend from '../RequiredFieldLegend';
import UserFormItems from './FormItems';
import { isGroupAdminClient, isSuperAdmin } from '../../utils/helpers';
import { CLIENT_ROLE_ADMIN, CLIENT_ROLE_CLIENT } from '../../utils/constants';

const { Item: FormItem } = Form;
const { Option } = Select;

class CreateForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customers: [],
            isFetching: false,
            isSubmiting: false,
            clientRole: CLIENT_ROLE_CLIENT,
        };
    }

    componentDidMount() {
        const { clientId, clientName } = this.getCustomerFromProps();
        if (clientId && clientName) {
            this.setState({
                customers: [{ numeroClientEureka: clientId, nomClient: clientName }],
                isSubmiting: false,
            });
        }
    }

    getCustomerFromProps() {
        const {
            client: {
                clientID: id, clientName: name,
            },
            match: {
                params: { clientId: matchClientId, clientName: matchClientName },
            },
        } = this.props;
        
        return {
            clientId: (matchClientId === undefined ? id : matchClientId),
            clientName: (matchClientName === undefined ? name : matchClientName),
        };
    }

    handleSubmit = async (e) => {
        e.preventDefault();

        const {
            form: { validateFieldsAndScroll },
            intl,
            save,
        } = this.props;
        this.setState({ isSubmiting: true });
        validateFieldsAndScroll(async (err, values) => {
            if (!err) {

                const { item, isSuccess }  = await checkEmailExistOnUser(values.user.email, undefined);
                if (item == 1) {
                // Appel du save appelant lui-même la route pour ajouter l'utilisateur
                save({ ...values.user, idClient: values.idClient.key })
                    .catch((error) => {
                        notification.error({
                            message: intl.formatMessage({ id: 'common.error' }),
                            description: 'Failed : DuplicateUserName' === error.message
                                ? intl.formatMessage({ id: 'user.create.error.duplicate' })
                                : error.message,
                        });
                    })
                    .finally(() => {
                        this.setState({ isSubmiting: false });
                    });
                }   
                if (item == 3) {
                    //modfication du 22/12/2022 remplacement du editUserForClient par createUserForClient ticket R-006434
                    //item 3 : Le mail existe déjà et l'utilisateur est désactivé
                    createUserForClient({
                            ...values.user,
                        }).then(({
                            isSuccess,
                            isUnauthorized,
                            errorMessage,
                            errorDetail,
                        }) => {
                            if (isSuccess) {
                                notification.success({
                                    message: intl.formatMessage({ id: 'user.edit.notification.title' }),
                                    description: intl.formatMessage({ id: 'user.edit.notification.description' }, { name: values.nomPrenom }),
                                });
                            } else {        
                                notification.error({
                                    message: isUnauthorized
                                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                                    : intl.formatMessage({ id: 'common.error' }),
                                    description: isUnauthorized
                                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                                    : errorDetail,
                                });
                            }
                        });
                    }
                    this.setState({ isSubmiting: false });
            } else {
                var firstError = err.user[Object.keys(err.user)[0]];
                var firstErrorId = firstError[Object.keys(firstError)[0]][0].field;
                document.getElementById(firstErrorId).focus({ preventScroll: true, focusVisible: true });
                var test = document.getElementById(firstErrorId).getBoundingClientRect().top - (document.body.getBoundingClientRect().top + 80);
                window.scrollTo( window.scrollX , test );
                this.setState({ isSubmiting: false });
            }
        });
    };

    onSearch = async (value) => {
        const { intl } = this.props;
        if (value) {
            this.setState({
                isFetching: true,
            });
            const {items: customers, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchCustomers({ nom_client: value });
            
            if (!isSuccess){
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail,
                });
            }

            this.setState({
                customers: isSuccess ? customers : [],
                isFetching: false,
            });

            return;
        }
        this.setState({
            isFetching: false,
        });
    };

    render() {
        const { form, userRole } = this.props;
        const { getFieldDecorator } = form;
        const { isFetching, customers, isSubmiting } = this.state;
        const { clientId, clientName } = this.getCustomerFromProps();
        return (
            <Form onSubmit={this.handleSubmit}>
                <Divider>
                    <FormattedMessage id="user.create.user" defaultMessage="User" />
                </Divider>
                <UserFormItems form={form} userPrefix="user."/>
                <FormItem {...formItemLayout} label={<FormattedMessage id="user.form.client" />}>
                    {getFieldDecorator('idClient', {
                        initialValue: clientId && clientName ? { key: clientId, label: clientName } : undefined,
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="user.form.clientRequired" />,
                            },
                        ],
                    })(
                        <Select
                            notFoundContent={isFetching ? <Spin size="small" /> : null}
                            labelInValue
                            showSearch
                            onSearch={debounce(this.onSearch, 600)}
                            filterOption={false}
                        >
                            {customers && customers.map((customer) => (
                                <Option key={customer.numeroClientEureka}>{customer.nomClient}</Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>
                {/* Currently login can change roles for new user */}
                {(isGroupAdminClient({ userRole }) || isSuperAdmin({ userRole })) && (
                    <Fragment>
                        <Divider>
                            <FormattedMessage id="user.form.dividerRole" defaultMessage="Roles settings" />
                        </Divider>
                        <Form.Item {...formItemLayout} 
                            label={<FormattedMessage id="user.form.role" 
                            defaultMessage="Administrator" />}
                        >
                            {getFieldDecorator('clientRole', {
                                rules: [
                                    {
                                        required: true,
                                        message: <FormattedMessage id="user.form.role.required" defaultMessage="Please choose the role of the user" />
                                    }
                                ]
                            })(
                                <Radio.Group onChange={({ target: { value } }) => {
                                    this.setState({
                                        clientRole: value,
                                    });
                                }}>
                                    <Radio value={CLIENT_ROLE_ADMIN}>
                                    <FormattedMessage id="common.yes" defaultMessage="Yes" />
                                    </Radio>
                                    <Radio value={CLIENT_ROLE_CLIENT}>
                                    <FormattedMessage id="common.no" defaultMessage="No" />
                                    </Radio>
                                </Radio.Group>
                            )}
                        </Form.Item>
                    </Fragment>
                )}
                <FormItem {...tailFormItemLayout}>
                    <RequiredFieldLegend />
                </FormItem>
                <Divider />
                <FormItem {...tailFormItemLayout}>
                    {isSubmiting ? (
                        <Spin />
                    ) : (
                        <Button type="primary" htmlType="submit">
                            <FormattedMessage id="user.create.button" defaultMessage="Create user" />
                        </Button>
                    )}
                </FormItem>
            </Form>
        );
    }
}

CreateForm.propTypes = {
    form: PropTypes.shape({
        validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    client: PropTypes.shape({
        clientId: PropTypes.string,
        clientName: PropTypes.string,
    }),
    intl: intlShape.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            clientId: PropTypes.string,
            clientName: PropTypes.string,
        }).isRequired,
    }),
    save: PropTypes.func.isRequired,
};

const mapStateToProps = ({ signIn: { userID } }) => ({
    connectedUserID: userID,
});

export default injectIntl(Form.create()(connect(mapStateToProps)(CreateForm)));
