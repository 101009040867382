import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Popover, Button, Icon, Modal, Switch } from 'antd';
import { fetchCustomers, transferDeviceNoConnected } from '../utils/apiBucherVaslin';
import Table from './Table';
import { connect } from 'react-redux';
import { isGroupConcessionnaire } from '../utils/helpers';

const tableName = 'customers-light';

const SwapButton = ({ numeroClientEureka, machineProps, handleOk, commissioning }) => {
    let isSuccessfullyTransferred;

    const handleClick = () => {
        transferDeviceNoConnected(machineProps.codeArticle, machineProps.numeroLot,numeroClientEureka, commissioning)
        .then(({ isSuccess, isUnauthorized }) => {
            isSuccess ? (
                isSuccessfullyTransferred = true
            ) : (
                isSuccessfullyTransferred = false
            );
            handleOk(isSuccessfullyTransferred, isUnauthorized);
        });
    };

    return (
        <span>
            <Popover content={<FormattedMessage id="table.devices.customerSwitch" />}>
                <Button htmlType="button" onClick={handleClick}>
                    <Icon type="swap" />
                </Button>
            </Popover>
        </span>
    );
};

SwapButton.propTypes = {
    numeroClientEureka: PropTypes.string,
    machineProps: PropTypes.shape({}),
    handleOk: PropTypes.func,
    commissioning: PropTypes.bool,
};

const columns = (machineProps, handleOk, commissioning) => (
    [
        {
            dataIndex: 'nomClient',
            key: 'name',
            sorter: true,
            render:() => (_, props) =>{
                return <Fragment><span>{props.nomClient} ({props.ville})</span><br/><span style={{fontSize:"90%", color:'#d65c10'}}><i>{props.numeroClientEureka}</i></span></Fragment>
            },
        },
        {
            key: 'action',
            render: () => (_, props) => (
                    <SwapButton {...props} machineProps={machineProps} handleOk={handleOk} commissioning={commissioning} />
                ),
        },
    ]
);

const keyToDbColumn = {
    name: 'NOM_CLIENT',
};

class GiveMachineNoConnected extends Component {
    state = {
        visible: false,
        commissioning: (this.isDiplayGuarantee()) ? true : false,
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    handleOk = (isSuccessfullyTransferred, isUnauthorized) => {
        const { refresh, intl } = this.props;
        this.setState({
            visible: false,
        });
        refresh();
        isSuccessfullyTransferred ? (
            Modal.success({
                title: intl.formatMessage({
                    id: 'table.devices.deviceTransferSuccess',
                }),
            })
        ) : (
            Modal.error({
                title: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({
                    id: 'table.devices.deviceTransferFail',
                }),
                content: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : null,
            })
        )
    };

    // Method used to know if connected user have dealer rights and if device dealer is the connected user
    isDiplayGuarantee() {
        const { clientID, codeConcessionnaire, userRole, lotGuaranteeExist } = this.props;

        // Check if connected user has corresponding rigths and is dealler of the device
        if (clientID !== codeConcessionnaire || !isGroupConcessionnaire({ userRole })) {
            return false;
        }

        // Check if guarantee is available
        if ( lotGuaranteeExist ) {
            return false
        }

        return true;
    }

    render() {
        const { visible, commissioning } = this.state;
        const { disable } = this.props;

        return (
            <span>
                <Popover content={<FormattedMessage id="table.devices.customerSwitch" />}>
                    <Button htmlType="button" onClick={this.showModal} disabled={disable}>
                        <Icon type="retweet" />
                    </Button>
                </Popover>
                <Modal
                    width={700}
                    title={(
                        <FormattedMessage
                            id="table.devices.customerSelect"
                            defaultMessage="Select a user"
                        />
                    )}
                    visible={visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="cancel" type="default" onClick={this.handleCancel} >
                            Cancel
                        </Button>
                    ]}
                >
                    <div>
                        {(this.isDiplayGuarantee()) ? (
                            <span>
                                <Switch
                                    defaultChecked={commissioning}
                                    onChange={value => { this.setState({commissioning: value}); }}
                                    className="alert-switch"
                                />
                                <FormattedMessage
                                    id="table.devices.declare.guarantee"
                                    defaultMessage="Declare the guarantee of the machine"
                                />
                            </span>
                        ) : ''}
                    </div>
                    <Table
                        fetchItems={fetchCustomers}
                        keyToDbColumn={keyToDbColumn}
                        columns={columns(this.props, this.handleOk, commissioning)}
                        rowKey="numeroClientEureka"
                        tableName={tableName}
                    />
                </Modal>
            </span>
        );
    }
}

GiveMachineNoConnected.propTypes = {
    refresh: PropTypes.func,
    intl: intlShape.isRequired,
    disable: PropTypes.bool.isRequired
};

const mapStateToProps = ({ signIn: { clientID, rights, userRole } }) => ({
    clientID,
    rights,
    userRole,
});

export default injectIntl(connect(mapStateToProps)(GiveMachineNoConnected));