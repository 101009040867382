import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDisplayName } from 'recompose';

const mapStateToProps = ({
    signIn: {
        loggedIn,
    },
}) => ({ loggedIn });

export default (ComposedComponent) => {
    class NotAuthentication extends Component {
        static propTypes = {
            loggedIn: PropTypes.bool.isRequired,
            history: PropTypes.shape({
                push: PropTypes.func.isRequired,
            }).isRequired,
        };

        componentDidMount() {
            this.checkLoggedIn();
        }

        componentDidUpdate() {
            this.checkLoggedIn();
        }

        checkLoggedIn() {
            const { loggedIn, history: { push } } = this.props;

            if (loggedIn) {
                push('/');
            }
        }

        render() {
            const { loggedIn } = this.props;
            if (loggedIn) {
                return null;
            }

            return <ComposedComponent {...this.props} />;
        }
    }

    NotAuthentication.displayName = getDisplayName(ComposedComponent);

    return connect(mapStateToProps)(NotAuthentication);
};
