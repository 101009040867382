import { PARAM_SET } from '../actions/params';

const paramsReducer = (state = {}, { type, param, data }) => {
    switch (type) {
        case PARAM_SET:
            return {
                ...state,
                [param]: data,
            };
        default:
            return state;
    }
};

export default paramsReducer;
