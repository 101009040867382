import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Checkbox, Modal, Popover, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { submitOrder } from '../../utils/apiBucherVaslin';
import { Link } from 'react-router-dom';

class SubmitOrderLicense extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmationCheckbox: false,
            visible: false,
        };
    }

    // Method triggered on ok
    async handleOk() {
        const { intl, id, refresh } = this.props;
        const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await submitOrder(id);

        if (isSuccess) {
            message.success(
                intl.formatMessage(
                    {
                        id: 'table.ordersLicenses.submit.success'
                    },
                    { id }
                ),
                5
            );

            refresh();

            return;
        }
        if (!isSuccess) {
            message.error(
                    errorDetail,5
            )
            refresh();
            this.setState({ visible: false });

            return;
        }
        Modal.error({
            title: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.title' })
            : intl.formatMessage({
                id: 'table.order.submit.fail',
            }),
            content: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.message' })
            : errorDetail,
        });

        this.setState({ visible: false });
    }

    // Method triggered on cancel
    handleCancel() {
        this.setState({ visible: false });
    }

    // Display transfert confirmation modal
    showSubmitModal() {
        this.setState({ visible: true });
    }

    // Update validation checkbox value
    async changeValidationCheck() {
        const { confirmationCheckbox } = this.state;
        await this.setState({confirmationCheckbox: !confirmationCheckbox})
    }

    // Method used to get informations about General Terms of Sales
    getGTS() {
        const { intl, locale } = this.props;
        const gts = { 
            label: intl.formatMessage({ id: 'gts.title' }),
            link: ""
        };

        switch(locale) {
            case "fr":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_french.pdf"
                break;
            case "en":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_english.pdf"
                break;
            case "de":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_german.pdf"
                break;
            case "es":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_spanish.pdf"
                break;
            case "it":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_italian.pdf"
                break;
            default :
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_english.pdf"
                break;
        }

        return gts;
    }

    render() {
        const { confirmationCheckbox, visible } = this.state;
        const { intl, id } = this.props;

        const gts = (visible) ? this.getGTS() : null;

        return (
            <Fragment>
                <Popover content={<FormattedMessage id="table.order.submit" />}>
                    <Button htmlType="button" onClick={() => this.showSubmitModal()}>
                        <FontAwesomeIcon icon="paper-plane" />
                    </Button>
                </Popover>

                <Modal
                    visible={visible}
                    title={intl.formatMessage(
                                { id: 'table.order.submit.title'},
                                { id }
                    )}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    footer={[
                        <Button key="back" onClick={() => this.handleCancel()}>
                            <FormattedMessage id="common.no" />
                        </Button>,
                        <Button key="submit" type="danger" disabled={!confirmationCheckbox} onClick={() => this.handleOk()}>
                            <FormattedMessage id="common.yes" />
                        </Button>
                    ]}
                >
                    {/* Checkbox de confirmation de lecture */}
                    <span>
                        <Checkbox 
                            checked={confirmationCheckbox} 
                            onChange={ () => this.changeValidationCheck() }
                            style={{marginRight: "1em"}}
                        />
                        <FormattedMessage id="order.modal.transfert.confirm" />
                    </span>

                    {/* List of informations link */}
                    <ul style={{marginTop: "1em"}}>
                        <li>
                            {/* Link to CGU */}
                            <Link to="/cgu" target="_blank">
                                <FormattedMessage id="cgu.title" />
                            </Link>
                        </li>

                        {/* Link to General Terms of Sales */}
                        {gts && (
                            <li>
                                <a target="_blank" href={gts.link}>{gts.label}</a>
                            </li>
                        )}
                    </ul>
                    
                </Modal>
            </Fragment>
        )
    }
}

SubmitOrderLicense.propTypes = {
    intl: intlShape.isRequired,
    id: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired,
};

const mapStateToProps = ({
    app: {
        locale,
    }
}) => ({ locale });

export default injectIntl(connect(mapStateToProps, {})(SubmitOrderLicense));