import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { flatMap } from 'lodash/fp';
import { connect } from 'react-redux';
import { Cascader, Form, Input, Icon, Select, Button, Modal, notification, Popover } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropzone from 'react-dropzone';
import PageLayout from '../Layout/PageLayout';
import RequiredFieldLegend from '../RequiredFieldLegend';
import { 
    contactSupport,  
    fetchCustomers, 
    fetchDevices,
    fetchAllCriticites,
    loadContactSubject,
} from '../../utils/apiBucherVaslin';
import history from '../../history';
import { isGroupConcessionnaire, isInfoCompleted } from '../../utils/helpers';
import ContactWarranty from './ContactWarranty';
import ContactWhitoutWarranty from './ContactWhitoutWarranty';
import PhoneInput from 'react-phone-number-input';
import RecaptchaItem from '../Captcha';
import flags from 'react-phone-number-input/flags'

import './Contact.less';

const { Item: FormItem } = Form;
const { TextArea } = Input;
const { Option } = Select;
const fileSize = "5"; // File size in MB
const limitTextAreaMessage = 1500;

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            allSelectedSubjects: [],
            criticites: [],
            dealerClients: [],
            defaultValueCriticites: "",
            disableForm: false,
            machines: [],
            loadingClient: false,
            loadingDevice: false,
            files: [],
            bonN1: [],
            bonN2: [],
            isLoading: false,
            selectedSubject: null,
            subjectList: [],
            defaultSelectedValue: null,
            articles: {},
            selectedClientConcessionnaire: null,
            mainAdresseId : null,
            countTextArea: 0,
            phone: ""
        };
    }

    

    async componentDidMount() {
        const { loggedIn, signIn,  intl, userID} = this.props;
        /* check connexion and information */ 
        await isInfoCompleted(intl, userID);
        // Get the subject list available for user
        await this.getSubjectList();

        if (loggedIn) {

            await this.getCriticites();
            // If user is dealer, get the list of his client and devices
            if (isGroupConcessionnaire({ userRole: signIn.userRole })) {
                // Get Clients
                await this.setState({loadingClient: true});
                const { items: clients, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchCustomers();
            
                if (!isSuccess){
                    notification.error({
                        message: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorDetail,
                    });
                }
                const dealerClients = clients.filter((concessionaireUser) => concessionaireUser.guIdClient != null); 
                this.setState({
                    dealerClients,
                    loadingClient: false
                });
                return;
            } else { 
                this.setState({selectedClientConcessionnaire: signIn.clientEurekaNumber})
                // Get the list devices linked to connected user
                await this.getDevices(signIn.clientEurekaNumber, null);
            }
        }
    }
    componentDidUpdate(prevProps) {
        const { match: { params: { id, type } } } = this.props;
        if (id != prevProps.match.params.id || type != prevProps.match.params.type) {
            this.setState({defaultSelectedValue : null, selectedSubject: null});
            this.getSubjectList();
        }
    }
    // Get the list of all criticites
    getCriticites = async () => {
        fetchAllCriticites()
            .then(({result: criticites, isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
                if (!isSuccess){
                    notification.error({
                        message: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorDetail,
                    });
                }
                const defaultValueCriticites = criticites[0].id;
                this.setState({
                    criticites, defaultValueCriticites
                });
            });
    }

    onSearchDevice = (value) => {
        const { selectedClientConcessionnaire} = this.state;
        this.getDevices(selectedClientConcessionnaire, value);
    }
    defineMainAdresseId = (value) => {
        this.setState({mainAdresseId: value});
    }
    // Get the list of all devices
    getDevices = async (eureka, value) => {
        const { intl } = this.props;
        const showDevicesConnected = true;
        const toDisplay = 50;
        fetchDevices(eureka, showDevicesConnected, toDisplay, value)()
            .then(({items: machines, isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
                if (!isSuccess){
                    notification.error({
                        message: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorDetail,
                    });
                }
                this.setState({
                    machines: isSuccess ? machines : [],
                });
            });
    }

    // Method used to get the suject list values
    getSubjectList = async() => {        
        const { match: {  params: { id, type } } , intl, locale, loggedIn } = this.props;
        // Get subject list
        const { subjects, isSuccess, isUnauthorized, errorMessage, errorDetail } = await loadContactSubject(locale, loggedIn);
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
        }
        let subjectsFiltred = [];
        let defaultSelectedValue = null;
        let selectedSubject = null;
        // Si menu equipement ou piéce détaché séléctionné (id 12 = piéce détaché, 17 = équipement )
        if(id) {    
            subjectsFiltred = subjects.filter((subject) => subject.typeFormulaire != "Standard");
            let subSubjectTemp = subjectsFiltred;
            let subjectsFiltredId = subjectsFiltred[0].subSubjects.filter((subject) => subject.subjectId == id);
            subSubjectTemp[0].subSubjects = subjectsFiltredId; 
            subjectsFiltred = subSubjectTemp;
                if (type) {
                    let subjectsFiltredType = subjectsFiltredId[0].subSubjects.filter((subject) => subject.subjectId == type);
                    subjectsFiltred[0].subSubjects[0].subSubjects = subjectsFiltredType;  
                    selectedSubject = subjectsFiltred[0].subSubjects[0].subSubjects[0];
                    defaultSelectedValue = subjectsFiltred[0].subject + " / " + subjectsFiltred[0].subSubjects[0].subject + " / " + subjectsFiltred[0].subSubjects[0].subSubjects[0].subject;
                }
            } else {    
                subjectsFiltred = subjects;
            }
            //console.log(subjectsFiltred);
        await this.setState({subjectList: subjectsFiltred, defaultSelectedValue, selectedSubject});
    }

    /**
     * Method used when user clic on submit button
     */
    submitContactForm = (datas) => {
        const { intl, loggedIn } = this.props;
        // Update message field
        if (!loggedIn) {
            // Set formatedMessage values
            let formatedMessage = datas.message + "<br /><br />";
            formatedMessage += intl.formatMessage({id:"contact.form.name"}) + " : " + datas.name +"<br /><br />";
            formatedMessage += intl.formatMessage({id:"contact.form.phoneNumber"}) + " : " + datas.phoneNumber +"<br /><br />";
            formatedMessage += intl.formatMessage({id:"contact.form.companyName"}) + " : " + datas.companyName +"<br /><br />";
            formatedMessage += intl.formatMessage({id:"contact.form.country"}) + " : " + datas.country +"<br /><br />";
            formatedMessage += intl.formatMessage({id:"contact.form.postalCode"}) + " : " + datas.postalCode +"<br /><br />";
           
            // Update data message value
            datas.message = formatedMessage;
        }

        contactSupport(datas)
            .then(({ isSuccess }) => {
                if (isSuccess) {
                    Modal.success({
                        title: intl.formatMessage({
                            id: 'contact.modal.success',
                        }),
                        onOk() {
                            history.goBack();
                        },
                    });

                    return;
                }
                Modal.error({
                    title: intl.formatMessage({
                        id: 'contact.modal.fail',
                    }),
                    onOk() {
                        history.goBack();
                    },
                });
            });
    }

    /**
     * Method used when user clic on submit button and when the subject is warranty request
     */
    submitWarrantyContactForm = (datas) => {
        const { intl } = this.props;
        /*
        * Format message
        */ 
        let formatedMessage = datas.message + "<br /><br />";
        formatedMessage += intl.formatMessage({id:"contact.form.requestType.title"}) + " : " + datas.typeFormulaire +"<br /><br />";
        
        // Manage Client field display
        if (datas.selectedClient.length > 0 && datas.selectedClient[0].nomClient) {
            formatedMessage += intl.formatMessage({id:"contact.form.client"}) + " : " + datas.selectedClient[0].nomClient.trim() +"<br /><br />";
        }

        // If device hasn't been found, display specific field values
        if (datas.deviceNotFound) {
            formatedMessage += "<h5>" + intl.formatMessage({id:"contact.form.device.title"}) + "</h5>";
            formatedMessage += intl.formatMessage({id:"contact.form.device.name"}) + " : " + datas.deviceName +"<br />";
            formatedMessage += intl.formatMessage({id:"contact.form.device.commissioningDate"}) + " : " + datas.deviceCommissioningDate +"<br />";
            formatedMessage += intl.formatMessage({id:"contact.form.device.number"}) + " : " + datas.deviceNumber +"<br />";
            formatedMessage += intl.formatMessage({id:"contact.form.device.serial"}) + " : " + datas.deviceSerial +"<br /><br />";
        } else if(datas.machine) {
            formatedMessage += intl.formatMessage({id:"contact.form.device.title"}) + " : " + datas.machine + "<br /><br />";
        }

        // Manage Device Part table
        if ( datas.warrantyState && datas.warrantyState.warrantyPieces && datas.warrantyState.warrantyPieces.length > 0) {
            formatedMessage += '<table style="border-collapse: collapse;">';
            // table header
            formatedMessage += '<thead><tr>';
            formatedMessage += '<th style="color: #DC0023;border: 1px solid #e8e8e8; padding: 3px 8px">' + intl.formatMessage({id:"table.contact.warranty.reference"}) + "</th>";
            formatedMessage += '<th style="color: #DC0023;border: 1px solid #e8e8e8; padding: 3px 8px">' + intl.formatMessage({id:"table.contact.warranty.label"}) + "</th>";
            formatedMessage += '<th style="color: #DC0023;border: 1px solid #e8e8e8; padding: 3px 8px">' + intl.formatMessage({id:"table.contact.warranty.billNumber"}) + "</th>";
            formatedMessage += '<th style="color: #DC0023;border: 1px solid #e8e8e8; padding: 3px 8px">' + intl.formatMessage({id:"table.contact.warranty.quantity"}) + "</th>";
            formatedMessage += "</tr></thead><tbody>";
            
            // table body
            datas.warrantyState.warrantyPieces.forEach((piece) => {
                formatedMessage += '<tr><td style="border: 1px solid #e8e8e8; padding: 3px 8px">'+ piece.reference + "</td>";
                formatedMessage += '<td style="border: 1px solid #e8e8e8; padding: 3px 8px">'+ piece.label + "</td>";
                formatedMessage += '<td style="border: 1px solid #e8e8e8; padding: 3px 8px">'+ piece.billNumber + "</td>";
                formatedMessage += '<td style="border: 1px solid #e8e8e8; padding: 3px 8px">'+ piece.quantity + "</td></tr>";
            });
            formatedMessage += "</tbody></table><br /><br />";
        }

        // Manage delivery date
        if (datas.deliveryDate) {
            formatedMessage += intl.formatMessage({id:"contact.form.deliveryDate"}) + " : " + datas.deliveryDate.format("L") +"<br /><br />";
        }

        // Update data message value
        datas.message = formatedMessage;

        // Send api request
        contactSupport(datas)
            .then(({ isSuccess }) => {
                if (isSuccess) {
                    Modal.success({
                        title: intl.formatMessage({
                            id: 'contact.modal.success',
                        }),
                        onOk() {
                            history.goBack();
                        },
                    });

                    return;
                }
                Modal.error({
                    title: intl.formatMessage({
                        id: 'contact.modal.fail',
                    }),
                    onOk() {
                        history.goBack();
                    },
                });
            });
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { form: { validateFieldsAndScroll }, intl } = this.props;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                const {
                    clientNumber,
                    companyName,
                    country,
                    deviceNotFound ,
                    description,
                    deviceCommissioningDate,
                    deliveryDate,
                    deviceName,
                    deviceNumber,
                    deviceSerial,
                    message,
                    name,
                    phoneNumber,
                    postalCode,
                    numeroClientEureka,
                    clefUniciteMachine,
                    criticiteId,
                    isProductionDependante,
                    isVendange,
                    dateDefaillance, 
                    dateExpeditionSouhaitee,
                    typePriseEnCharge,
                    referenceClient,
                    commentaireERP,
                    numeroClientLivraisonEureka,
                    adresseDynamicsGuid,  
                } = values;
                const {
                    dealerClients,
                    files, 
                    bonN1,
                    bonN2,              
                    selectedSubject,
                    articles 
                } = this.state;

                const { loggedIn, signIn } = this.props;

                // Manage email
                let email;
                if (loggedIn) {
                    email = signIn.email
                } else {
                    email = values.email
                }
                // Get client object referring to selected Client (warranty form)
                const selectedClient = dealerClients.filter((client) => client.numeroClientEureka === clientNumber);

                let formattedSubject = selectedSubject.mailSubject;
                
                // Manage API call depending of request type
                if ( selectedSubject.typeFormulaire === "Garantie" ) {
                    this.submitWarrantyContactForm({
                        dealerID: signIn.clientEurekaNumber,
                        deviceNotFound,
                        deviceCommissioningDate,
                        deliveryDate,
                        deviceName,
                        deviceNumber,
                        deviceSerial,
                        email: email,
                        fichiers: files,
                        garantie: true,
                        loggedIn,
                        message,
                        //requestType,
                        selectedClient,
                        sujet: formattedSubject,
                        typeFormulaire: selectedSubject.typeFormulaire,
                        numeroClientEureka : numeroClientEureka ? numeroClientEureka : signIn.clientEurekaNumber, 
                        clefUniciteMachine,
                        dateDefaillance : moment(dateDefaillance).format("YYYY-MM-DD"), 
                        dateExpeditionSouhaitee :  moment(dateExpeditionSouhaitee).format("YYYY-MM-DD"),
                        typePriseEnCharge,
                        bonNum1 : bonN1,
                        bonNum2 : bonN2,
                        referenceClient,
                        commentaireERP,
                        numeroClientLivraisonEureka,
                        adresseDynamicsGuid :adresseDynamicsGuid ?  adresseDynamicsGuid : this.state.mainAdresseId,
                        articles,
                        description : message
                    });
                } else {
                    this.submitContactForm({
                        companyName,
                        country,
                        dealerID: signIn.clientEurekaNumber,
                        email: email,
                        fichiers: files,
                        garantie: false,
                        loggedIn,
                        message,
                        name,
                        phoneNumber,
                        postalCode,
                        sujet: formattedSubject,
                        typeFormulaire: selectedSubject.typeFormulaire,
                        numeroClientEureka, 
                        clefUniciteMachine,
                        criticiteId,
                        isProductionDependante,
                        isVendange,
                        description: message
                    });
                }
            }
            else{
                this.setState({ isLoading: false });
            }
        });
    };

    handleDropzoneDrop = (acceptedFile) => {
        const files = this.state.files;
        const allFiles = files.concat(acceptedFile);
        this.setState({ files: allFiles });
    };

    // Method used to catch subject field selection change
    handleChangeSubject = async (value) => {
        const {subjectList} = this.state;
        const selectedSubjects = [];
        let sub;

        // Get the list of all selected subject element
        if (value && value.length > 0) {
            let i = 0;
            while (i < value.length) {
                if (i === 0) {
                    sub = subjectList.find((sub) => sub.subjectId === value[i]);
                } else {
                    sub = selectedSubjects[i-1].subSubjects.find((sub) => sub.subjectId === value[i]);
                }
                
                selectedSubjects.push(sub);
                i ++;
            }
        }

        const lastLevelSelectedSubject = (selectedSubjects.length > 0) ? selectedSubjects[selectedSubjects.length - 1] : null;

        await this.setState({
            allSelectedSubjects: selectedSubjects, 
            selectedSubject: lastLevelSelectedSubject
        });
    }

    handleRedirectRegister = (content) => {
        // destroy modal
        content.destroy();

        // Manage navigation
        const { history } = this.props;
        history.push("/newCustomer");
    }

    removeFile = (event, index) => {
        event.stopPropagation();
        const copyFiles = this.state.files;
        copyFiles.splice(index, 1);
        this.setState({ files: copyFiles });
    };

    enterLoading = () => {
        this.setState({
            isLoading: true,
        });
    }

    /**
     * Method used to rest field values
     * @param fields => Array: Array of fields decorators to reset
     * @param value => Any : Value to set to the target field
     */
    clearFormFields = (fields, value=null) => {
        const { form } = this.props;
        // Loop throught all defined fields
        fields.forEach((field) => {
            // Set fields value to null
            form.setFieldsValue({
                [field.valueOf()]: value
            });
        });
    }

    /*
    * Method used to update warranty state from child components
    */
    updateWarrantyState = (state) => {
        this.setState({articles: state});
    }
    updateBonN1 = (bonN1) => {
        this.setState({bonN1});
    }
    updateBonN2 = (bonN2) => {
        this.setState({bonN2});
    }
    /**
     * Method used to display a reject file modal
     */
    showRejectFileModal = () => {
        const { intl } = this.props;

        notification.error({
            duration: 10,
            message: <span dangerouslySetInnerHTML={{__html: intl.formatHTMLMessage(
                { id: 'contact.form.file.sizeLimit.error'},
                { fileSize: fileSize}
            )}}></span>,
        });
    }

    onChangeClients = (clientEureka) => {
        this.setState({selectedClientConcessionnaire: clientEureka});
        this.getDevices(clientEureka, null);
    }
    disableForm = (value) => {
        const disableForm = value ? false : true;
        this.setState({disableForm})
    }

    onChangeMessage(value) {
        const countTextArea = value ? value.target.value.length : 0;
        this.setState({countTextArea});
    }

    render() {
        const { match: {  params: { id, type } } , form: { getFieldDecorator }, loggedIn, history, form } = this.props;
        const { articles, bonN1, bonN2, criticites, dealerClients, defaultValueCriticites, disableForm, files, isLoading, loadingClient, loadingDevice, 
            machines, selectedSubject, subjectList, selectedClientConcessionnaire, defaultSelectedValue, phone } = this.state;
            // console.log(subjectList);
        return (
            <PageLayout pageKey={"contact"+ (id? id : "") + (type? type : "")} history={history}>
                <h2>
                    <FormattedMessage
                        id="contact.form.title"
                    />
                </h2>
                <Form className="contact-form" onSubmit={this.handleSubmit}>
                    {/* Subject select field */}
                    <div className="subjectContact">
                    <FormItem
                        label={(
                            <Fragment>
                                <FormattedMessage
                                    id="contact.form.subject"
                                />

                                <Popover content={<FormattedMessage id="contact.form.otherSubject" />}>
                                    <Icon 
                                        type="info-circle" 
                                        style={{verticalAlign: "unset", margin: "0 0.5em", color: 'orange' }}
                                    />
                                </Popover>
                            </Fragment>
                        )}
                    >
                        {defaultSelectedValue ? 
                            getFieldDecorator('subject', {         
                            initialValue: defaultSelectedValue,
                            rules: [{
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="contact.form.subjectRequired"
                                    />
                                ),
                            }],
                            
                        })(
                            <Input disabled/>
                        ) :
                        getFieldDecorator('subject', {        
                            rules: [{
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="contact.form.subjectRequired"
                                    />
                                ),
                            }],
                            
                        })(
                            <Cascader 
                            fieldNames={{ children: "subSubjects", label: "subject", value: "subjectId"}} 
                            onChange={(value) => this.handleChangeSubject(value)}
                            options={subjectList}
                            placeholder=""
                            />
                        )}
                    </FormItem>
                    </div>
                    {/* Check Subject type */}
                    {loggedIn ? (
                    (selectedSubject && selectedSubject.typeFormulaire === "Garantie") ? (
                        // Fragment when Warrenty subject have been selected
                        <ContactWarranty
                            clients={dealerClients}  
                            form={form}
                            loadingClient={loadingClient}
                            loadingDevice={loadingDevice}
                            machines={machines}
                            bonN1={bonN1}
                            bonN2={bonN2}
                            selectedClientConcessionnaire= {selectedClientConcessionnaire}
                            updateWarrantyState={(state) => this.updateWarrantyState(state)}
                            updateBonN1={(bonN1) => this.updateBonN1(bonN1)}
                            updateBonN2={(bonN2) => this.updateBonN2(bonN2)}
                            onChangeClients={(value) => this.onChangeClients(value)}
                            clearFormFields={(fields, value) => this.clearFormFields(fields, value)}
                            disableForm={(value) => this.disableForm(value)}
                            onSearchDevice={(value) => this.onSearchDevice(value)}
                            defineMainAdresseId = {(value) => this.defineMainAdresseId(value)}
                        />
                    ) :
                    (selectedSubject && selectedSubject.typeFormulaire === "Réclamation") ? (
                        // Fragment when subject have been selected
                        <ContactWhitoutWarranty
                            clients={dealerClients}
                            criticites={criticites}
                            defaultValueCriticites={defaultValueCriticites}
                            selectedClientConcessionnaire= {selectedClientConcessionnaire}
                            machines={machines}
                            form={form}
                            loadingClient={loadingClient}
                            loadingDevice={loadingDevice}
                            onChangeClients={(value) => this.onChangeClients(value)}
                            clearFormFields={(fields, value) => this.clearFormFields(fields, value)}
                        /> 
                        // If no subject slected, but still loggedIn
                    ) : ""
                        // Fragement when log out
                    ) : 
                    (     
                            <Fragment>
                            {/* Name field */}
                            <FormItem
                                label={(
                                    <FormattedMessage
                                        id="contact.form.name"
                                    />
                                )}
                            >
                                {getFieldDecorator('name', {
                                    rules: [{
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="contact.form.nameRequired"
                                            />)
                                    }]             
                                })(
                                    <Input />
                                )}
                            </FormItem>

                            {/* Email fields */}
                            <FormItem
                                label={(
                                    <FormattedMessage
                                        id="contact.form.email"
                                    />
                                )}
                            >
                                {getFieldDecorator('email', {
                                    rules: [{
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="contact.form.emailRequired"
                                            />
                                        )
                                    },{
                                        type: 'email',
                                        message: (<FormattedMessage
                                            id="contact.form.emailValid"
                                            defaultMessage="Please enter a valid e-mail"
                                        />)
                                    }]
                                })(
                                    <Input />
                                )}
                            </FormItem>

                            {/* Phone number field */}
                            <FormItem
                                label={(
                                    <FormattedMessage
                                        id="contact.form.phoneNumber"
                                    />
                                )}
                            >
                                {getFieldDecorator('phoneNumber', {
                                    rules: [],
                                })(
                                    <PhoneInput
                                        value={this.state.phone2}
                                        onChange={(phone2) => {this.setState({phone2: phone2})}}
                                        flags={flags}
                                        international={true}
                                        limitMaxLength={true}
                                    />
                                )}
                            </FormItem>

                            {/* Company name field */}
                            <FormItem
                                label={(
                                    <FormattedMessage
                                        id="contact.form.companyName"
                                    />
                                )}
                            >
                                {getFieldDecorator('companyName', {
                                    rules: [{
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="client.form.companyRequired" 
                                        />)
                                    }]    
                                })(
                                    <Input />
                                )}
                            </FormItem>

                            {/* Country field */}
                            <FormItem
                                label={(
                                    <FormattedMessage
                                        id="contact.form.country"
                                    />
                                )}
                            >
                                {getFieldDecorator('country', {
                                    rules: [{
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="client.form.countryRequired" 
                                        />)
                                    }]   
                                })(
                                    <Input />
                                )}
                            </FormItem>

                            {/* Postal Code field */}
                            <FormItem
                                label={(
                                    <FormattedMessage
                                        id="contact.form.postalCode"
                                    />
                                )}
                            >
                                {getFieldDecorator('postalCode', {
                                    rules: [{
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="client.form.zipCodeRequired"
                                        />)
                                    }]  
                                })(
                                    <Input />
                                )}
                            </FormItem> 
                        </Fragment>
                    )} 

                    {/* description message */}

                        <FormItem
                            label={(
                                <Fragment>
                                    <FormattedMessage id="contact.form.message"/>
                                    <Popover content={<FormattedMessage id="contact.form.descriptionInfo" />} placement="topLeft" >
                                        <Icon 
                                            type="info-circle" 
                                            style={{verticalAlign: "unset", margin: "0 0.5em", color: 'orange' }}
                                        />
                                        {this.state.countTextArea}/{limitTextAreaMessage}
                                    </Popover>
                                </Fragment>
                            )}>
                            {getFieldDecorator('message', {
                                rules: [{
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="contact.form.messageRequired"
                                        />
                                    )
                                }]
                            })(
                                <TextArea autoSize={{ minRows: 3 }} maxLength={limitTextAreaMessage} onChange={(value) => this.onChangeMessage(value)} />,
                            )}
                        </FormItem>
                

                        {/* File field */}
                        <FormItem className="file-upload-form-item"
                            label={(
                                    <Fragment>
                                        <FormattedMessage id="contact.form.files"/>  
                                        <Popover content={<FormattedMessage id="contact.form.file.sizeLimit.info" values={{fileSize: fileSize}}/>} 
                                                placement="topLeft" >
                                        <Icon 
                                            type="info-circle" 
                                            style={{verticalAlign: "unset", margin: "0 0.5em", color: 'steelblue' }}
                                        />
                                        </Popover> 
                                    </Fragment>              
                                )}>
                            {getFieldDecorator('files')(
                                <Dropzone
                                    className="dropzone"
                                    name="files"
                                    multiple={true}
                                    maxSize={fileSize * 1000000}
                                    onDrop={this.handleDropzoneDrop}
                                    onDropRejected={this.showRejectFileModal}
                                >
                                    <Fragment>
                                        { (files && files.length > 0) ? (
                                            <div className="file-container">
                                                { files && files.map((fileElement, fileIndex) => (
                                                    <button
                                                        type="button"
                                                        key={fileElement.name}
                                                        className="file-name-container"
                                                        onClick={(e) => this.removeFile(e,fileIndex)}
                                                    >
                                                        <span className="file-name">{fileElement.name}</span>
                                                        <span className="file-delete">
                                                            <FontAwesomeIcon icon="times-circle" />
                                                        </span>
                                                    </button>
                                                ))}
                                            </div>
                                        ) : null}

                                        <div className="dropzone-content">
                                            <span className="upload-icon">
                                                <FontAwesomeIcon icon="file-upload" />
                                            </span>
                                        <FormattedMessage id="contact.form.fileUpload" />
                                    </div>
                                    </Fragment>
                                </Dropzone>
                            )}
                            
                        </FormItem>
                    {loggedIn ? null : (
                        <FormItem>
                            <RecaptchaItem form={form} />
                        </FormItem>
                    )}

                    {/* Legend */}
                    <RequiredFieldLegend />
                    
                    {/* Confirm button */}
                    {!disableForm &&
                    <FormItem>
                        <Button
                            type="primary"
                            htmlType="submit"
                            onClick={this.enterLoading}
                            loading={isLoading}
                            disabled= {selectedSubject && selectedSubject.typeFormulaire === "Garantie"  &&  Object.keys(articles).length == 0 ? true : false}
                        >
                            <FormattedMessage
                                id="contact.form.send"
                                defaultMessage="Create user"
                            />
                        </Button>
                    </FormItem>
                }
                </Form>
            </PageLayout>
        );
    }
}

Contact.propTypes = {
    form: PropTypes.shape({
        validateFieldsAndScroll: PropTypes.func.isRequired,
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    loggedIn: PropTypes.bool.isRequired,
    userRole: PropTypes.array
};

const mapStateToProps = ({ 
        app: {
            locale,
        },
        signIn: { loggedIn, userRole, userID },
        signIn
    }) => ({ 
        locale, loggedIn, signIn, userRole, userID
    }
);

export default injectIntl(Form.create()(connect(mapStateToProps)(Contact)));
