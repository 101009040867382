import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { locales } from '../translations';

import './HtmlIncluder.less';

class HtmlIncluder extends Component {
    render() {
        const { folder, fileName, locale, onLoad, isModalContent } = this.props;
        // If langage doesn't exist on translate we put 'en' by default
        const localeExist = locales.hasOwnProperty(locale);
        const source = [folder, fileName, `${fileName}_${(localeExist ? locale : 'en')}.html`].filter(Boolean).join('/');

        return (
            <div className="html-includer">
                <iframe
                    title="HtmlIncluder"
                    onLoad={() => onLoad && onLoad(this.instance)}
                    ref={(instance) => { this.instance = instance; }}
                    src={`/templates/pages/${source}`}
                    style={{ flex: '1 1 auto' }}
                    className={(isModalContent) ? 'modal-content-frame' : ''}
                />
            </div>
        );
    }
}

HtmlIncluder.propTypes = {
    onLoad: PropTypes.func,
    folder: PropTypes.string,
    fileName: PropTypes.string.isRequired,
    isModalContent: PropTypes.bool,
    locale: PropTypes.string.isRequired,
};

const mapStateToProps = ({ app: { locale } }) => ({ locale });

export default connect(mapStateToProps)(HtmlIncluder);
