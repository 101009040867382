import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Icon, Spin, notification, Popover, Popconfirm } from 'antd';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { triggerMachineOrderRestart } from '../utils/apiBucherVaslin';
import { faHdd, faUndo, faSync } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './RestartMachine.less';

class RestartMachine extends Component {
    state = {
        loading: false,
        visible: false,
    };

    timer = null;

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleRestart = async(value, inRequest, inVariable="TypeRestart", metric="ts") => {
        const { intl, serial } = this.props;
        let isTimeOut = false;
        this.setState({
            loading: true,
        });

        // Handle timeout of the request
        this.timer = setTimeout(() => {
            // The api takes more than 10sec to answer, we concider that the resquest have correctly 
            // been send to the device and return a success notification
            notification.success({
                message: intl.formatMessage({
                    id: 'table.devices.restartModal.success',
                }),
            });

            // Close the modal
            isTimeOut = true;
            this.setState({ loading: false, visible: false });
        }, 10000);

        // Trigger api request
        const { isSuccess, ordreResult, isUnauthorized } = await triggerMachineOrderRestart(serial, value, inRequest, inVariable, metric);
        
        // If the request ends up before timeout
        // And if timer is set (prevent isues when user leave modal and came back while api call is still running)
        if (!isTimeOut && this.timer !== null) {
            // Clear timeout
            clearTimeout(this.timer);

            if(isSuccess) {
                const ordreResultFail = ordreResult.filter((order) => order.StatusType === "error");            
                // If atleast one order fail
                if(ordreResultFail && ordreResultFail.length > 0) {
                    // Display an error notification
                    notification.error({
                        message: intl.formatMessage({ id: 'common.error' }),
                        description: ordreResultFail[0].StatusMessage
                    });
                }
            } else {
                // If request failed because we arn't authaurised to send this request
                if (isUnauthorized) {
                    notification.error({
                        message: intl.formatMessage({ id: 'error.unauthorized.title' }),
                        description: intl.formatMessage({ id: 'error.unauthorized.message' })
                    });
                } else {
                    // If the api respond in less than 10 secondes, it means that a problem occurred
                    notification.error({
                        message: intl.formatMessage({ id: 'common.error' })
                    });
                }
            };

            this.setState({ loading: false, visible: false });
        }
    };

    handleCancel = () => {
        // Réinitiate timer when modal close
        clearTimeout(this.timer);
        this.timer = null;

        // Réinitiate state whenether the modal is closed
        this.setState({
            loading: false,
            visible: false,
        });
    };

    render() {
        const { visible, loading } = this.state;
        const { active } = this.props;
        const {hasLicenceConnexionValide} = this.props;

        return (
            <span>
                <Popover 
                    content={<FormattedMessage 
                        id={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:(active) ? "table.devices.restart" : "table.devices.unactivated.disabled"} 
                    />}
                >
                    <Button onClick={this.showModal} disabled={!active || !hasLicenceConnexionValide}>
                        <FontAwesomeIcon icon={faUndo} />
                    </Button>
                </Popover>
                <Modal
                    title={(
                        <FormattedMessage
                            id="table.devices.restartModal.title"
                            defaultMessage="Restart actions"
                        />
                    )}
                    visible={visible}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <div>
                        <p>
                            <FormattedHTMLMessage id="table.devices.restartModal.description" />  
                        </p>
                        
                        <div className="action-container">
                            {/* Display loader */}
                            {loading && (
                                <Spin size="large" />
                            )}

                            {/* Warm restart button */}
                            <Popover content={<FormattedMessage id="table.devices.restartModal.warmRestart" />}>
                                <Popconfirm
                                    title={<FormattedMessage id="table.devices.restartModal.confirm" />}
                                    onConfirm={() => this.handleRestart('1','2','TypeRestart','ts')}
                                    okText={<FormattedMessage id="common.yes" />}
                                    cancelText={<FormattedMessage id="common.no" />}
                                >
                                    <Button disabled={loading} >
                                        <FontAwesomeIcon icon={faUndo} />
                                        <FormattedMessage id="table.devices.restartModal.warmRestart" /> 
                                    </Button>
                                </Popconfirm>
                            </Popover>

                            {/* Cold restart button */}
                            <Popover content={<FormattedMessage id="table.devices.restartModal.coldRestart" />}>
                                <Popconfirm
                                    title={<FormattedMessage id="table.devices.restartModal.confirm" />}
                                    onConfirm={() => this.handleRestart('2','2','TypeRestart','ts')}
                                    okText={<FormattedMessage id="common.yes" />}
                                    cancelText={<FormattedMessage id="common.no" />}
                                >
                                    <Button disabled={loading} >
                                        <FontAwesomeIcon icon={faSync}/>
                                        <FormattedMessage id="table.devices.restartModal.coldRestart" /> 
                                    </Button>
                                </Popconfirm>
                            </Popover>

                            {/* Router restart button */}
                            <Popover content={<FormattedMessage id="table.devices.restartModal.routerRestart" />}>
                                <Popconfirm
                                    title={<FormattedMessage id="table.devices.restartModal.confirm" />}
                                    onConfirm={() => this.handleRestart('1','3','BP_RebootSM_D','ts')}
                                    okText={<FormattedMessage id="common.yes" />}
                                    cancelText={<FormattedMessage id="common.no" />}
                                >
                                    <Button disabled={loading} >
                                        <FontAwesomeIcon icon={faHdd}/>
                                        <FormattedMessage id="table.devices.restartModal.routerRestart" /> 
                                    </Button>
                                </Popconfirm>
                            </Popover>
                        </div>
                    </div>
                </Modal>
            </span>
        );
    }
}

RestartMachine.propTypes = {
    active: PropTypes.bool.isRequired,
    refresh: PropTypes.func,
    intl: intlShape.isRequired,
};

export default injectIntl(RestartMachine);
