import React from 'react';

import PageLayout from './Layout/PageLayout';
import HtmlIncluder from './HtmlIncluder';

const CGU = () => (
    <PageLayout pageKey="cgu" flex>
        <HtmlIncluder fileName="cgu" />
    </PageLayout>
);

export default CGU;
