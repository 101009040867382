import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faWineGlassAlt,
    faUserAlt,
    faUserEdit,
    faTrashAlt,
    faBan,
    faEnvelope,
    faFileUpload,
    faTimesCircle,
    faFileContract,
    faQuestionCircle,
    faPaperPlane,
    faPenAlt,
    faSave,
    faTimes,
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faWineGlassAlt,
    faUserAlt,
    faUserEdit,
    faTrashAlt,
    faBan,
    faEnvelope,
    faFileUpload,
    faTimesCircle,
    faFileContract,
    faQuestionCircle,
    faPaperPlane,
    faPenAlt,
    faSave,
    faTimes,
);
