import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import HtmlIncluder from '../HtmlIncluder';
import PageLayout from '../Layout/PageLayout';

class ETraining extends Component {

    render() {
        return (
            <PageLayout pageKey="eTraining" flex>
                <HtmlIncluder
                    fileName="eTraining"
                    folder="training"
                />
            </PageLayout>
        );
    }
}

ETraining.propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string,
};

const mapStateToProps = ({
    app: {
        locale,
    },
}) => ({ locale });

export default connect(mapStateToProps)(injectIntl(ETraining));
