import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { Button, Checkbox, DatePicker, Input, Icon, Modal, notification, Table } from 'antd';
import { getHistoricDefaultSynthesis } from '../../utils/apiBucherVaslin';
import { alphabetically, compareNumber } from '../../utils/helpers';
import moment from 'moment';
import { Radar } from 'react-chartjs-2';
import './ListDefaultSynthese.less';

const tableName = 'histoDefaultSynthesis';
const today = new Date(); 
const { RangePicker } = DatePicker;

class ListDefaultSynthese extends Component {
    constructor(props){
        super(props);
        this.state = {
            dateStart: new Date(new Date().setFullYear(today.getFullYear() - 1)),
            dateEnd: today,
            displayedSynthesisAxes: [],
            loadData: false,
            nameDevice: null,
            radarGraph: {
                labels: [],
                datasets: []
            },
            showGraphModal : false,
            synthesisDefault: [],
        };
    }

    async componentDidMount() {
        this.fetchDataAsync();
    }

    async fetchDataAsync() {
        const {
            match: {
                params: { serial },
            },
            location: {
                state: { nameDevice },
            },
            locale: lang,
            intl,
        } = this.props;
        const { dateStart, dateEnd } = this.state;
        this.setState({ loadData: false, nameDevice });

        const { items: synthesisDefault, isSuccess, isUnauthorized, errorMessage, errorDetail} = await getHistoricDefaultSynthesis()({ 
            dateStart,
            dateEnd,
            lang,
            serial,
        });
        
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }

        // set all default visibility to true by default (for radar graph)
        synthesisDefault.forEach((defaultValue) => {
            defaultValue.visible = true;
        });

        // Update synthesis default
        await this.setState({ loadData: true, synthesisDefault});

        // Init displayed axis
        await this.initDisplayedAxes();

        // Update Graph value
        await this.updateGraphDataset();
    }

    onFilter = (value, obj) => {
        if (obj === true || obj === false) {
            return obj == value;
        }
        return obj && obj.toLowerCase().includes(value.toLowerCase()); 
    };

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    getColumns() {
        return [{
            dataIndex: 'value',
            key: 'value',
            sorter: (a, b) => alphabetically(a.value, b.value),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.value),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'message',
            key: 'message',
            sorter: (a, b) => alphabetically(a.message, b.message),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.message),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            key: 'occurrence',
            children: [
                {
                    dataIndex: 'numberOccurrenceForLastData',
                    key: 'lastData',
                    title: <FormattedMessage id={`table.${tableName}.lastData`} />,
                    sorter: (a, b) => compareNumber(a.numberOccurrenceForLastData, b.numberOccurrenceForLastData),
                },{
                    dataIndex: 'numberOccurrenceForFiveLastData',
                    key: 'fiveLastData',
                    title: <FormattedMessage id={`table.${tableName}.fiveLastData`} />,
                    sorter: (a, b) => compareNumber(a.numberOccurrenceForFiveLastData, b.numberOccurrenceForFiveLastData),
                },{
                    dataIndex: 'numberOccurrenceForTenLastData',
                    key: 'tenLastData',
                    title: <FormattedMessage id={`table.${tableName}.tenLastData`} />,
                    sorter: (a, b) => compareNumber(a.numberOccurrenceForTenLastData, b.numberOccurrenceForTenLastData),
                },{
                    dataIndex: 'numberOccurrenceForFiftyLastData',
                    key: 'fiftyLastData',
                    title: <FormattedMessage id={`table.${tableName}.fiftyLastData`} />,
                    sorter: (a, b) => compareNumber(a.numberOccurrenceForFiftyLastData, b.numberOccurrenceForFiftyLastData),
                },{
                    dataIndex: 'numberOccurrenceForHundredLastData',
                    key: 'hundredLastData',
                    title: <FormattedMessage id={`table.${tableName}.hundredLastData`} />,
                    sorter: (a, b) => compareNumber(a.numberOccurrenceForHundredLastData, b.numberOccurrenceForHundredLastData),
                },{
                    dataIndex: 'numberOccurrenceForFiveHundredLastData',
                    key: 'fiveHundredLastData',
                    title: <FormattedMessage id={`table.${tableName}.fiveHundredLastData`} />,
                    sorter: (a, b) => compareNumber(a.numberOccurrenceForFiveHundredLastData, b.numberOccurrenceForFiveHundredLastData),
                },{
                    dataIndex: 'numberOccurenceForAllData',
                    key: 'allData',
                    title: <FormattedMessage id={`table.${tableName}.allData`} />,
                    sorter: (a, b) => compareNumber(a.numberOccurenceForAllData, b.numberOccurenceForAllData),
                }
            ]
        }];
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    onDateChange = async (value) => {
        await this.setState({
            dateStart: (value[0]) ? value[0].format("YYYY-MM-DD") : null,
            dateEnd: (value[1]) ? value[1].format("YYYY-MM-DD") : null, 
        });

        this.fetchDataAsync();
    }

    /**
     * Method used to init displayed axes
     */
    initDisplayedAxes = async () => {
        const { synthesisDefault } = this.state;
        const axes = [];

        // Loop throught default list to initiate axeFilter
        synthesisDefault.forEach((defaultValue) => {
            axes.push({ label: defaultValue.message, checked: true});
        });

        this.setState({ displayedSynthesisAxes: axes});
    }

    /**
     * Method used to update graphDataset
     */
    updateGraphDataset = async () => {
        const { synthesisDefault } = this.state;
        const axeLabels = [];

        // Filter default to display only "visible" axis
        const filteredDefault = synthesisDefault.filter((defaultValue) => defaultValue.visible === true );

        // Init datasets
        const datasets = [{
            label: '1',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            data: [],
        },
        {
            label: '5',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            data: [],
        },
        {
            label: '10',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            data: [],
        },
        {
            label: '50',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            data: [],
        },
        {
            label: '100',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            data: [],
        },
        {
            label: '500',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            data: [],
        },{
            label: 'all',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            data: [],
        }];

        // Loop throught synthesis values
        filteredDefault.forEach((defaultValue, indexDefault) => {
            axeLabels.push(defaultValue.message);
            datasets[0].data[indexDefault] = defaultValue.numberOccurrenceForLastData;
            datasets[1].data[indexDefault] = defaultValue.numberOccurrenceForFiveLastData;
            datasets[2].data[indexDefault] = defaultValue.numberOccurrenceForTenLastData;
            datasets[3].data[indexDefault] = defaultValue.numberOccurrenceForFiftyLastData;
            datasets[4].data[indexDefault] = defaultValue.numberOccurrenceForHundredLastData;
            datasets[5].data[indexDefault] = defaultValue.numberOccurrenceForFiveHundredLastData;
            datasets[6].data[indexDefault] = defaultValue.numberOccurenceForAllData;
        });

        const graphConfig = {
            labels: axeLabels,
            datasets
        };
        this.setState({ radarGraph: graphConfig});
    }

    /**
     * Method called when axis is checked
     */
    handleChecked = async (event, axisIndex) => {
        // Change state value for (un)checked axis
        const { displayedSynthesisAxes, synthesisDefault } = this.state;
        displayedSynthesisAxes[axisIndex].checked = event.target.checked

        // Change graph axis visiblity according to the action
       const dataIndex = synthesisDefault.findIndex((element) => element.message === displayedSynthesisAxes[axisIndex].label);
       if(dataIndex > -1) {
           synthesisDefault[dataIndex].visible = event.target.checked
       }

        // Update state
        await this.setState({displayedSynthesisAxes, synthesisDefault});

        // Update graph dataset
        this.updateGraphDataset();
    }

    render () {
        const { dateEnd, dateStart, displayedSynthesisAxes, loadData, radarGraph, showGraphModal, synthesisDefault } = this.state;
        const { locale, intl } = this.props

        const translatedColumns = this.getColumns().map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        const dateFormat = 'YYYY-MM-DD';

        const tableLoading = {
            spinning: !loadData,
            indicator: <span style={{width: "100%", left: "0%"}}>
                <Icon type="loading" style={{marginRight:"1em"}}/>
                <FormattedMessage id="table.histoDefaultSynthesis.loaderText" />
            </span>,
        }

        return (
            <div className="default-synthesis">
                {synthesisDefault && synthesisDefault.length > 0 && (
                    <Fragment>                        
                        <RangePicker 
                            defaultValue={ (dateStart && dateEnd) ? [
                                moment(dateStart, dateFormat), 
                                moment(dateEnd, dateFormat)
                            ] : null}
                            disabled={!loadData}
                            locale={locale}
                            onChange={(value) => { this.onDateChange(value)}}
                            placeholder={[
                                intl.formatMessage({id:'table.histoDefaultSynthesis.from'}),
                                intl.formatMessage({id:'table.histoDefaultSynthesis.to'}),
                            ]}
                            separator="-"
                            style={{marginBottom: "1em"}}
                        />

                        {/* Button for displaying synthesis graph */}
                        {( loadData && synthesisDefault.length > 2) && (
                            <Button 
                                onClick={() => { this.setState({showGraphModal: true}) }}
                                style={{marginLeft: "0.5em"}}
                            >
                                <FormattedMessage id="cycles.synthesis.graph.show" />
                            </Button>
                        )}
                    </Fragment>
                )}
                <Table 
                    className="custom-table"
                    dataSource={synthesisDefault}
                    columns={translatedColumns}
                    loading={tableLoading}
                    // rowKey="dateAdded"
                    rowKey={record => record.dateAdded + '_' + record.value}
                    tableName={tableName}
                />

                {/* Synthesis graph modal */}
                <Modal
                    title={<FormattedMessage id="cycles.synthesis.graph.title" />}
                    visible={showGraphModal}
                    onCancel={() => this.setState({ showGraphModal: false })}
                    bodyStyle={{
                        overflowY: 'hidden',
                        minHeight: '350px',
                        position: 'relative',
                    }}
                    footer={null}
                    width="80%"
                >
                    <div className="synthesis-radar-container">
                        {/* Radar filter */}
                        <div className="radar-axe-selection">
                            { displayedSynthesisAxes && displayedSynthesisAxes.length > 0 && (
                                <ul>
                                    {displayedSynthesisAxes.map(( axis, axisIndex ) => (
                                        <li key={"axis_" + axisIndex}>
                                            <Fragment>
                                                <Checkbox
                                                    checked={axis.checked}
                                                    onChange={(e) => { this.handleChecked(e, axisIndex) }}
                                                >
                                                    { axis.label }
                                                </Checkbox>
                                            </Fragment>
                                        </li>
                                    ))}
                                </ul>
                            ) }
                        </div>

                        {/* Radar display */}
                        <div className="radar-container">
                            <Radar 
                                data={radarGraph} 
                                height={150}
                                legend={{display: false}}
                            />
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

ListDefaultSynthese.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            serial: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            nameDevice: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    locale: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
}

const mapStateToProps = ({ app: { locale } }) => ({
    locale,
});

export default injectIntl(connect(mapStateToProps)(ListDefaultSynthese));