import React, { Component, Fragment } from "react";
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PropTypes from 'prop-types';
import { Button, Icon, Popover, Modal, notification, Select, Spin } from 'antd'
import { generateLicense,
    getUsersForConcessionnaireClient, fetchLicensesForClient, linkLicense } from '../../utils/apiBucherVaslin';

const { Option } = Select;

// Génère une modal où le client peut donner accès à son concessionnaire à ses machines
class ModalGenerateForDealer extends Component {
    constructor(props){
        super(props);
        this.state = {
            modalVisibility: false,
            buttonOpeningModalDisabled: false,
            confirmLoading: false,
            haveLicenseValid: false,
            canGenerate: false,
            loadingData: false,
            concessionaireUsers: [],
            usersToAffect: [],
            licenseParent: {},
        };
    }

    componentDidMount() {
        const { canGenerate } = this.props;
        this.setState({ buttonOpeningModalDisabled: !canGenerate });
    }

    generateModal = async () => {
        const { canGenerate } = this.props;
        this.setState({ loadingData: false });

        if (!canGenerate) {
            this.setState({ loadingData: true });
            return;
        }

        this.setState({
            modalVisibility: true,
        });
        
        const { eureka, clientID, intl, family, serial } = this.props;

        // On récupère les licences valides de l'utilisateur (et pouvant être affectées)
        const { items: licensesForClient
            , isSuccess: isSuccessLicForCli
            , isUnauthorized: isUnauthorizedLicForCli
            , errorDetail: errorMessageLicForCli } = await fetchLicensesForClient(eureka || clientID)({isExpire: false, isConnexion: false});
        
        if (!isSuccessLicForCli){
            notification.error({
                message: isUnauthorizedLicForCli 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedLicForCli 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageLicForCli,
            });
        }
        // On récupère la première licence parente et multi-user et de la même famille que la machine
        const license = licensesForClient && licensesForClient.length != 0 ? 
            licensesForClient.find((l) => l.isLicenseChild === false && l.isMultiUsers === true
            && l.famille === family && l.device.serial == serial) : []
        
        if (!license || license.length == 0) {
            this.setState({ loadingData: true });
            return;
        }
        this.setState({ haveLicenseValid: true, licenseParent: license });

        // On récupère les utilisateurs du concessionnaire
        const { items: concessionaireUsers, isSuccess: isSuccessUserConcCli
            , isUnauthorized: isUnauthorizedUserConcCli, errorDetail: errorMessageUserConcCli }
        = await getUsersForConcessionnaireClient();

        if (!isSuccessUserConcCli){
            notification.error({
                message: isUnauthorizedUserConcCli 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedUserConcCli 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageUserConcCli,
            });
            this.setState({ loadingData: true });
            return;
        }
        this.setState({ concessionaireUsers });
        this.setState({ loadingData: true });
    }

    // On génère la licence fille de la licence parente et on affecte chacun des utilisateurs sélectionnés à cette nouvelle licence
    generate = async () => {
        const { usersToAffect, licenseParent: { famille, guid } } = this.state;
        const { intl } = this.props;

        // On génère la licence fille
        const {item, isSuccess, isUnauthorized, errorMessage, guid: newGuid, errorDetail } = await generateLicense()({ parentGuid: guid, family: famille});
        
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            return;
        }
        
        let isSuccessAffectedAll = true;
        let isUnauthorizedAffectedAll = false;
        let errorMessageAffectedAll = null;
        // On affecte les utilisateurs à la licence fille (à ce stade il y en a forcément)
        for (var i = 0; i < usersToAffect.length; i++){
            const { isSuccess: isSuccessAffected, isUnauthorized: isUnauthorizedAffected,
                errorDetail: errorMessageAffected } = await linkLicense({ guid: newGuid, idUser: usersToAffect[i] });
            if (!isSuccessAffected){
                isSuccessAffectedAll = isSuccessAffected;
                isUnauthorizedAffectedAll = isUnauthorizedAffected;
                errorMessageAffectedAll = errorMessageAffected;
                break;
            }
        }
        if (!isSuccessAffectedAll){
            notification.error({
                message: isUnauthorizedAffectedAll 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedAffectedAll 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageAffectedAll,
            });
            return;
        }
        notification.success({
            message: intl.formatMessage({ id: 'device.dealer.license.affected.title' }),
            description: intl.formatMessage({ id: 'device.dealer.license.affected.description'}, { guid: newGuid }),
        });
        notification.info({
            message: intl.formatMessage({ id: 'device.dealer.license.affected.confirmationDelete' }),
            duration: 15
        });
        this.handleCancel();
    };

    // A la sélection/déselection d'utilisateurs on les ajoute à la liste des utilisateurs à affecter et on autorise 
    // à cliquer sur le bouton ok s'il y a au moins un utilisateur de sélectionné
    handleChangeUser = async (value) => {
        if (value === undefined || value == null || value.length == 0){
            this.setState({ canGenerate: false });
        }
        else {
            this.setState({ canGenerate: true });
        }
        this.setState({ usersToAffect: value });
    }

    handleCancel = () => {
        this.setState({
            modalVisibility: false,
            buttonOpeningModalDisabled: false,
            confirmLoading: false,
            haveLicenseValid: false,
            canGenerate: false,
            loadingData: false,
            concessionaireUsers: [],
            usersToAffect: [],
            licenseParent: {},
        });
    };

    render() {
        const { buttonOpeningModalDisabled, canGenerate, concessionaireUsers,
            confirmLoading, haveLicenseValid, loadingData, modalVisibility } = this.state;
        
        const { intl } = this.props;

        const filterOption = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

        if (!loadingData){
            return (
                <Fragment>
                <Popover content={<FormattedMessage id="device.dealer.access" />}>
                    <Button disabled={buttonOpeningModalDisabled} htmlType="button" onClick={this.generateModal}>
                        <Icon type="select" />
                    </Button>
                </Popover>
                <Modal
                    title={<FormattedMessage id="device.dealer.license.affected" />}
                    onOk={this.generate}
                    okButtonProps={{disabled: true}}
                    visible={modalVisibility}
                    onCancel={this.handleCancel}
                    confirmLoading={confirmLoading}
                >
                    <Spin size="small" />
                </Modal>
            </Fragment>
            )
        }

        return (
            <Fragment>
                <Popover content={<FormattedMessage id="device.dealer.access" />}>
                    <Button disabled={buttonOpeningModalDisabled} htmlType="button" onClick={this.generateModal}>
                        <Icon type="select" />
                    </Button>
                </Popover>
                <Modal
                    title={<FormattedMessage id="device.dealer.license.affected" />}
                    onOk={this.generate}
                    okButtonProps={{disabled: !canGenerate}}
                    visible={modalVisibility}
                    onCancel={this.handleCancel}
                    confirmLoading={confirmLoading}
                >
                    {!haveLicenseValid ? (
                        <FormattedMessage id="device.dealer.noLicence" />
                    ) : (
                        <Fragment>
                            <FormattedMessage id="device.dealer.license.affected.content" />
                            <Select
                                showSearch
                                mode="multiple"
                                optionFilterProp="children"
                                filterOption={filterOption}
                                placeholder={intl.formatMessage({
                                    id: 'table.licenses.userPlaceholder',
                                })}
                                style={{ width: '100%', marginTop: '5px' }}
                                onChange={this.handleChangeUser}
                            >
                                {concessionaireUsers.map(({ id, userName }) => (
                                    <Option key={id}>{userName}</Option>
                                ))}
                            </Select>
                        </Fragment>
                    )}
                </Modal>
            </Fragment>
        );
    }
}

ModalGenerateForDealer.propTypes = {
    intl: intlShape.isRequired,
    userRole: PropTypes.array.isRequired,
    clientID: PropTypes.string,
    serial: PropTypes.string.isRequired,
    eureka: PropTypes.string,
    family: PropTypes.string.isRequired,
    canGenerate: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ signIn: { userID, userRole, clientID } }) => ({
    userID,
    userRole,
    clientID,
});

export default connect(mapStateToProps)(injectIntl(ModalGenerateForDealer));