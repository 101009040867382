import React, { useEffect } from "react";
import { getListEchangesReseau } from "../../utils/apiBucherVaslin";
import { Table, notification } from "antd";
import moment from "moment";
import { injectIntl } from "react-intl";
import "./NetworkRequests.less";

const renderDate = (date) =>
  date ? <span>{moment(date).format("DD/MM/YY")}</span> : "-";

const getColumns = (intl) => {
  let networkRequestsColumns = [
    {
      dataIndex: "codeArticle",
      key: "codeArticle",
      title: intl.formatMessage({
        id: "networkRequests.table.codeArticle",
      }),
    },
    {
      dataIndex: "libelle",
      key: "libelle",
      title: intl.formatMessage({
        id: "networkRequests.table.libelle",
      }),
      width: "5%",
    },
    {
      dataIndex: "qty",
      key: "qty",
      title: intl.formatMessage({
        id: "networkRequests.table.quantite",
      }),
    },
    {
      dataIndex: "dateBesoin",
      key: "dateBesoin",
      title: intl.formatMessage({
        id: "networkRequests.table.dateBesoin",
      }),
      render: renderDate,
    },
    {
      dataIndex: "criticiteLibelle",
      key: "criticiteLibelle",
      title: intl.formatMessage({ id: "networkRequests.table.criticite" }),
    },
    {
      dataIndex: "occasionLibelle",
      key: "occasionLibelle",
      title: intl.formatMessage({ id: "networkRequests.modal.occasion" }),
    },
  ];
  return networkRequestsColumns;
};
const openNotification = (intl, listEchangesReseau) => {
  const key = `open${Date.now()}`;
  notification.open({
    message: intl.formatMessage({
      id: "networkRequests.menu.networkRequests",
    }),
    description: (
      <a href={"/networkRequests"}>
        <Table
          className="custom-table"
          dataSource={listEchangesReseau}
          columns={getColumns(intl)}
          rowKey={(record) => record.id}
          pagination={false}
        />
      </a>
    ),
    key,
    duration: 15,
    className: "infoNetworkRequestNotif",
  });
};
const InfoRequestNetwork = ({ intl }) => {
  useEffect(() => {
    getListEchangesReseau("DemandesDuReseau", 10).then(
      ({ listEchangesReseau }) => {
        listEchangesReseau &&
          listEchangesReseau.length != 0 &&
          openNotification(intl, listEchangesReseau);
      }
    );
  }, []);
  return null;
};

export default injectIntl(InfoRequestNetwork);
