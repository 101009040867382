import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Alert, Button, Col, Divider, Form, Input, message, notification, Row, Table, Upload } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { alphabetically, isOrderEditable, isGroupEBV, isSuperAdmin, isGroupDev, isInfoCompleted } from '../../utils/helpers';
import PageLayout from '../Layout/PageLayout';
import { addLineToOrder, importCommandArcticles, fetchOrder, fetchOrderStatus, fetchUsers, getAddressById, updateLineInOrder } from '../../utils/apiBucherVaslin';
import AddArticles from './AddArticles';
import SearchArticles from './SearchArticles';
import ArticleComponents from '../ArticleComponents';
import DeleteArticle from './DeleteArticle';
import { EditableCell, EditableContext, EditableFormRow } from '../EditableCell';
import  FormattedCurrency  from '../utils/FormattedCurrency';
import { CURRENCY_EURO } from '../../utils/constants';
import OrderStepper from './OrderStepper';
import './Orders.less'
import OrderStatusStepper from './OrderStatusStepper';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import UpdateOrder from './UpdateOrder';

const { TextArea } = Input;

/* eslint-disable */
class Order extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientEurekaNumber: null,
            loading: false,
            order: null,
            orderStatus: null,
            lines: [],
            editingKey: '',
            currency: CURRENCY_EURO,
            statusList: [],
            friezeStatus: [],
            visible: false,
            confirmLoading: false,
            deliveryDeadlinesList: [], 
            prixTotal: null,
            addressShow: null
        };
    }

    async componentDidMount() {
        /* check connexion and information */ 
        const { intl, userID } = this.props;
        await isInfoCompleted(intl, userID);

        await this.initStatusList();
        await this.fetchDataAsync();
        await this.fetchAddress();
    }
    reloadOrder = async () => {
        // Load orders
        await this.fetchDataAsync();
        // Reload Address
        await this.fetchAddress();
    }

    async addArticles(articles) {
        const { order, lines } = this.state;
        const { intl } = this.props;
        const QUANTITY = 1;

        const addedLines = [];
        for (let i = 0; i < articles.length; i++) {
            const existingArticle = lines.find(article => article.codeArticle === articles[i].codeArticle);
            // if (existingArticle) {
            //     const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await updateLineInOrder(order.numCommande, existingArticle, existingArticle.quantite, existingArticle.commentaire);
            //     if (!isSuccess){
            //         notification.error({
            //             message: isUnauthorized 
            //                 ? intl.formatMessage({ id: 'error.unauthorized.title' })
            //                 : intl.formatMessage({ id: 'common.error' }),
            //             description: isUnauthorized 
            //                 ? intl.formatMessage({ id: 'error.unauthorized.message' })
            //                 : errorDetail,
            //         });
            //     } else {
            //         // Gestion de la quantité et du prix total de la ligne
            //         existingArticle.quantite += QUANTITY;
            //         existingArticle.prixTotal += parseInt(existingArticle.prix);
            //     }
            // } else {
                if(articles[i].codeArticle){
                    const { item, isSuccess, isUnauthorized, errorMessage, errorDetail } = await addLineToOrder(order.numCommande, articles[i], QUANTITY);
                    if (isSuccess) {
                        // Gestion du prix total de la ligne
                        item.prixTotal = parseInt(item.prix);
                        addedLines.push(item);
                    }
                    else {
                        notification.error({
                            message: isUnauthorized 
                                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                                : intl.formatMessage({ id: 'common.error' }),
                            description: isUnauthorized 
                                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                                : errorDetail,
                        });
                    }
                }
            }
        // }

        this.fetchDataAsync();
    }

    initStatusList = async () => {
        const { intl, locale } = this.props;

        // Charge la liste des status
        const { status, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchOrderStatus(locale.toUpperCase());
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            return;
        }

        // Récupère la liste des statuts à afficher dans la frise
        const friezeStatus = status.filter((elem) => elem.isVisible);

        // Mise à jour du state
        await this.setState({friezeStatus, statusList: status});
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    isEditing = record => record.id === this.state.editingKey;

    cancel() {
      this.setState({ editingKey: '' });
    }

    edit(key) {
      this.setState({ editingKey: key });
    }

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    onFilter = (value, obj) => obj && obj.toLowerCase().includes(value.toLowerCase());

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    async fetchDataAsync() {
        this.setState({ loading: true });
        const {
            match: {
                params: { id },
            },
            clientID,
            intl,
        } = this.props;

        const { items, isSuccess: isSuccessOrder, isUnauthorized, errorMessage, errorDetail } = await fetchOrder(id)();
        
        const {items: users, isSuccess: isSuccessUsers
            , isUnauthorized: isUnauthorizedUsers
            , errorDetail: errorMessageUsers } = await fetchUsers({}, clientID);

        if (!isSuccessUsers){
            notification.error({
                message: isUnauthorizedUsers 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedUsers 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageUsers,
            });
        }

        // Ajout du prix total de la ligne
        items && items.lignes && items.lignes.forEach((ligne) => {
            ligne.prixTotal = (ligne.quantite * ligne.prix).toFixed(2);
        })

        // TODO => A retirer quand le statut de la commande remontera correctement pour les statuts
        // "Brouillon" et "En préparation"
        if(!items.commande.statutCommand) {
            items.commande.statutCommand = (!items.commande.dateSoumission) ? "Draft" : "In preparation";
        }

        if (isSuccessOrder){
            this.setState({ order: items.commande, orderStatus: items.statut, prixTotal: items.commande.montant});
        }
        if (!isSuccessOrder){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }

        if (!isSuccessUsers || !users || 0 === users.length) {
            this.setState({ lines: [], loading: false })
            return;
        }

        const clientEurekaNumber = users[0].client.numeroClientEureka;
        this.setState({ lines: items.lignes, loading: false, clientEurekaNumber, currency: items.commande.codeDevise });
    }
  
    async fetchAddress() {
        const {order} = this.state;
        const {intl} = this.props;
        const {addresses, isSuccess, isUnauthorized, errorMessage, errorDetail } = await getAddressById(order.dynamicsAdresseGuid);
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
        }
        this.setState({ addressShow: addresses[0] }); 
    }
    getColumns () {
        const { currency } = this.state;
        const { userRole } = this.props;
        
        let libelle = {
            dataIndex: 'libelle',
            key: 'libelle',
            sorter: false,
            title: <FormattedMessage id={`table.order.label`} />,
            sorter: (a, b) => alphabetically(a.libelle, b.libelle),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.libelle),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
        };
        let codeArticle = {
            dataIndex: 'codeArticle',
            key: 'codeArticle',
            sorter: false,
            title: <FormattedMessage id={`table.order.codeArticle`} />,
            sorter: (a, b) => alphabetically(a.codeArticle, b.codeArticle),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.codeArticle),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
        };
        let gamme = {
            dataIndex: 'gamme',
            key: 'gamme',
            sorter: false,
            title: <FormattedMessage id={`table.order.gamme`} />,
            sorter: (a, b) => alphabetically(a.gamme, b.gamme),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.gamme),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
        };
        let famille = {
            dataIndex: 'famille',
            key: 'famille',
            sorter: false,
            title: <FormattedMessage id={`table.order.famille`} />,
            sorter: (a, b) => alphabetically(a.famille, b.famille),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.famille),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
        };
        let familleCommerciale = {
            dataIndex: 'familleCommerciale',
            key: 'familleCommerciale',
            sorter: false,
            title: <FormattedMessage id={`table.order.familleCommerciale`} />,
            sorter: (a, b) => alphabetically(a.familleCommerciale, b.familleCommerciale),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.familleCommerciale),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
        };
        let prixPublic = {
            dataIndex: 'prixPublic',
            key: 'prixPublic',
            sorter: false,
            title: <FormattedMessage id={`table.articles.prixPublic`} />,
            sorter: (a, b) => a.prixPublic > b.prixPublic,
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => value === record.prixPublic,
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            render: (value) => <FormattedCurrency amount={parseFloat(value)} currency={currency} />
        };
        let prix = {
            dataIndex: 'prix',
            key: 'prix',
            sorter: false,
            title: <FormattedMessage id={`table.order.prixUnitaire`} />,
            sorter: (a, b) => a.prix > b.prix,
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => value === record.prix,
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            render: (value) => <FormattedCurrency amount={parseFloat(value)} currency={currency} />
        };  
        let quantite = {
            dataIndex: 'quantite',
            inputType:'number',
            key: 'quantite',
            sorter: false,
            title: <FormattedMessage id={`table.order.quantite`} />,
            sorter: (a, b) => a.quantite > b.quantite,
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => parseInt(value) === parseInt(record.quantite),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            editable: true,
        }; 
          let prixTotal = {
            dataIndex: 'prixTotal',
            key: 'prixTotal',
            sorter: false,
            title: <FormattedMessage id={`table.order.prixTotal`} />,
            sorter: (a, b) => a.prixTotal > b.prixTotal,
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => value === record.prixTotal,
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            render: (value) => <FormattedCurrency amount={parseFloat(value)} currency={currency} />
        };  
        let status = {
            key: 'status',
            title: <FormattedMessage id={`table.order.status`} />,
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, this.getStatus(record)),
            sorter: (a, b) => alphabetically(this.getStatus(a), this.getStatus(b)),
            render: (_, line) => { return this.getStatus(line) },
        };    
        let commentaire = {
            dataIndex: 'commentaire',
            inputType:'string',
            key: 'commentaire',
            title: <FormattedMessage id={`table.order.comment`} />, 
            editable: true
        };   
        let columns = [];
        (isGroupEBV({ userRole }) || isSuperAdmin({ userRole })) ? 
        columns = [libelle,codeArticle,gamme,famille,prix,prixPublic,quantite,prixTotal,status,commentaire] :
        columns = [libelle,codeArticle,gamme,famille,prix,quantite,prixTotal,status,commentaire];

        if (this.isEditable()) {
            columns.push(
            {
                key: 'action',
                title: 'Action',
                render: (_, record) => {
                    const { id, libelle, numCommande } = record;

                    if (this.isEditing(record)) {
                        return (
                            <span>
                                <EditableContext.Consumer>
                                    {form => (
                                        <Button
                                            htmlType="button"
                                            type="primary"
                                            onClick={() => this.save(form, record.id)}
                                        >
                                            <FontAwesomeIcon icon="save" />
                                        </Button>
                                    )}
                                </EditableContext.Consumer>
                                <Divider type="vertical" />
                                <Button
                                    htmlType="button"
                                    type="primary"
                                    onClick={() => this.cancel(record.id)}
                                >
                                    <FontAwesomeIcon icon="ban" />
                                </Button>
                            </span>
                        );
                    }

                    return (
                        <Fragment>
                            <Button
                                htmlType="button"
                                type="danger"
                                onClick={() => this.edit(record.id)}
                            >
                                <FontAwesomeIcon icon="pen-alt" />
                            </Button>
                            <Divider type="vertical" />
                            <DeleteArticle id={id} libelle={libelle} numCommande={numCommande} refresh={() => this.fetchDataAsync()} />
                            <Divider type="vertical" />
                            <Link to={`/partsUseCases/${record.codeArticle}`} target="_blank">
                                <Button>
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </Link>  
                        </Fragment>
                    );
                },
            });

            // Configurate editables columns
            columns = columns.map((col) => {
                if (!col.editable) {
                    return col;
                }

                const c = {
                    ...col,
                    onCell: record => ({
                      record,
                      inputType: col.inputType,
                      dataIndex: col.dataIndex,
                      title: col.title,
                      editing: this.isEditing(record),
                    }),
                };

                return c;
            });
        }

        return columns;
    }
    
    diff(num1, num2) {
        const {prixTotal} = this.state;
        if (num1 > num2) {
            const newMontant = prixTotal+ (num1 - num2);
            this.setState({prixTotal: newMontant});
        } else {
            const newMontant = prixTotal - (num2 - num1);
            this.setState({prixTotal: newMontant});
        }
    }

    save(form, key) {
        form.validateFields(async (error, row) => {
          if (error) {
            return;
          }

          const { order } = this.state;
          const { intl } = this.props;
          const newLines = [...this.state.lines];
          const index = newLines.findIndex(item => key === item.id);
          if (index === -1) {
            return;
          }

          if (row.quantite <= 0) {
            row.quantite = 1;
          }

          const item = newLines[index];
          const newItem = { ...item, ...row };
          // Update total price;
          newItem.prixTotal = parseInt(newItem.quantite) * parseInt(newItem.prix);
          newLines.splice(index, 1, newItem);

          this.diff(newItem.prixTotal, item.prixTotal);
          this.setState({ lines: newLines, editingKey: '' });
          const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await updateLineInOrder(order.numCommande, newItem, newItem.quantite, newItem.commentaire);
          if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
          }
        })
    }

    isEditable() {
        return this.state.order && isOrderEditable(this.state.order);
    }

    // Return status id for translation 
    getStatus = (line) => {
        const { statusList } = this.state;
        const lineStatut = statusList.find((status) => status.statut === line.statut);
        return (lineStatut) ? lineStatut.statutTraduit : null;
    }

    // Method used to import csv order article into currently displayed order
    importCommandArcticles = async (csvFiles) => {
        const {
            match: {
                params: { id },
            },
            intl,
        } = this.props;

        // Start loader
        await this.setState({ loading: true });

        // Import command
        const { isSuccess, resultatsImportFails, isUnauthorized, errorMessage, errorDetail }  = await importCommandArcticles(id, csvFiles.file);
        if (!isSuccess) {
            if (resultatsImportFails[0] ?  true : false)  {
                message.error(resultatsImportFails[0].raison,7)
            } else {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
                duration: 7
            });
        }
            await this.setState({ loading: false });
            return;
        }
        message.success(
            intl.formatMessage(
                {
                    id: 'order.detail.importArticles.success'
                },
                { id }
            ),
            7
        );
        for (var i = 0; i < resultatsImportFails.length; i++) {
            message.warning(
                resultatsImportFails[i].codeArticle + " : " + resultatsImportFails[i].raison            
                ),
            10
        } 
        // Reload articles
        await this.fetchDataAsync();

        // Stop the loader
        await this.setState({ loading: false });
    }
    handleOk = async() =>{
        this.setState({
            modalInfoToCompleted: false
        });
    }
    
    render() {
        const {
            match: {
                params: { id }
            },
            history,
            userRole
        } = this.props;
        const { clientEurekaNumber, currency, friezeStatus, lines, loading, order, prixTotal, addressShow  } = this.state;

        // Allow orders with articles of the same family
        let familyCode = null;
        if (lines.length > 0) {
            familyCode = lines[0].famille;
        }

        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell
            }
        };

        return (         
            <PageLayout pageKey="order" history={history}>
                <Row type="flex" justify="space-between">
                    <Col sm={24} md={4} lg={12}>
                        <h2>
                            <FormattedMessage
                                id="order.title"
                                values={{
                                    id,
                                }}
                            />
                        </h2>
                    </Col>
                </Row>
                { order && (
                    <Fragment>
                        {/* Include creation order stepper when order is edditable*/}
                        { this.isEditable() ? (
                            <OrderStepper stepIndex={(lines && lines.length > 0) ? 3 : 1} />
                        ) : (
                            // Add Order status stepper
                            <Fragment>
                                {order && (
                                    <OrderStatusStepper order={order} statusList={friezeStatus} />
                                )}
                            </Fragment>
                        )}
                    </Fragment>
                )}
                {/* Update Button */}
                <div style={{display:"flex"}}>
                {order && this.isEditable() && ( 
                    <UpdateOrder  refresh={this.reloadOrder} order={order}> </UpdateOrder> 
                )}
                {this.isEditable() && (prixTotal <= order.montantMinimum) && (
                    <Fragment >  
                        <Alert message={<FormattedMessage id="order.detail.currentTotalAmount.warning" />} type="warning" showIcon closable 
                               style={{width: "30%", backgroundColor:"#f7cb3b",  margin: "0 auto" }}/>
                        < br/>
                    </Fragment>
                )}
                </div>
                {/* Order detail bloc */}
                {order && (
                    <div className="order-details">
                        {/* Left side bloc */}
                        <div className="order-details-bloc">
                            {/* Client Ref */}
                            <div>
                                <FormattedMessage id="order.detail.clientRef"></FormattedMessage>
                                {order.numCommandCustomer}
                            </div>

                            {/* Created By */}
                            <div>
                                <FormattedMessage id="order.detail.createdBy"></FormattedMessage>
                                {(order.userCreated.nomPrenom) ? (
                                    <Fragment>{order.userCreated.nomPrenom}</Fragment>
                                ) : (
                                    <i>Bucher Vaslin</i>
                                )}
                                
                            </div>

                            {/* Delivery Address */}
                            <div>
                                <FormattedMessage id="order.detail.deliveryAddress"></FormattedMessage>
                                 {(addressShow) ? (<Fragment>{addressShow.line1} 
                                                  {addressShow.line2 ? ", " + addressShow.line2 + " " : ' '}
                                                  {addressShow.line3 ? ", " + addressShow.line3 + " " : '' } 
                                                  ({addressShow.postalCode} {addressShow.city})
                                                </Fragment>
                                 ): ""}  
                            </div>
                        </div>

                        {/* Middle-left bloc */}
                        <div className="order-details-bloc">

                            {/* Expected Delivery Date */}
                            <div>
                                <FormattedMessage id="order.detail.expectedDeliveryDate"></FormattedMessage>
                                {order.dateLivraison ? moment(order.dateLivraison).format('DD/MM/YYYY') : ''}
                            </div>
                             {/* Created Date */}
                            <div>
                                <FormattedMessage id="order.detail.createdDate"></FormattedMessage> 
                                {order.dateCreation ? moment(order.dateCreation).format('DD/MM/YYYY') : ''}
                            </div>
                            {/* Submission Date */}
                            <div>
                                <FormattedMessage id="order.detail.submissionDate"></FormattedMessage>
                                {order.dateSoumission ? moment(order.dateSoumission).format('DD/MM/YYYY') : ''}
                            </div>
                            {/* Validation Date */}
                            <div>
                                <FormattedMessage id="order.detail.validationDate"></FormattedMessage>
                                {order.dateValidation ? moment(order.dateValidation).format('DD/MM/YYYY') : ''}
                            </div>
                        </div>
                        {/* Middle-right  bloc */}
                        <div className="order-details-bloc">

                            {/* Delivery Fee */}
                            <div>
                                <FormattedMessage id="order.detail.deliveryFee"></FormattedMessage>
                                {(order && order.amountShippingCosts) && (
                                    <FormattedCurrency amount={order.amountShippingCosts} currency={currency} />
                                )}
                            </div>
                            
                            {/* Order amount min */}
                            <div>
                                <FormattedMessage id="order.detail.orderAmountMin"></FormattedMessage>
                                {(order && order.montantMinimum) && (
                                    <FormattedCurrency amount={order.montantMinimum} currency={currency} />
                                )}
                               
                            </div>

                            {/*current total amount */}
                            <div>
                                <FormattedMessage id="order.detail.currentTotalAmount"></FormattedMessage>
                                {(order && prixTotal) && (
                                    <FormattedCurrency amount={prixTotal} currency={currency} />
                                )}
                            </div>
                            {/* accepter les livraisons partielles */}
                            <div>
                                <FormattedMessage id="order.detail.reliquat" />
                                {order.reliquat ? ((order.reliquat = true) ? <FormattedMessage id="common.yes" /> : <FormattedMessage id="common.no" />)
                                                : <FormattedMessage id="common.no" />}
                            </div>
                        </div>

                        {/* Right bloc */}
                        <div className="order-details-bloc">
                            {/* Order Comment */}
                            <label htmlFor="orderComment">
                                <FormattedMessage id="order.detail.comment" />
                            </label>
                            <TextArea autoSize disabled id="orderComment" value={order.commentaire} ></TextArea >
                        </div>
                    </div>
                )}            

                {/* Array of order parts */}
                <Table
                    className="custom-table"
                    components={components}
                    dataSource={lines}
                    columns={this.getColumns()}
                    expandedRowRender={ArticleComponents}
                    rowClassName={(article) => (article.composants && article.composants.length ? 'editable-row' : 'editable-row no-expand')}
                    rowKey="id"
                    loading={loading}
                    //scroll={{ x: true }} // non compatible avec expandedRowRender
                />
                { clientEurekaNumber && this.isEditable() ? ( 
                        <Fragment>
                            {/* Add article button */}                  
                            <Fragment>
                            {/* { isGroupDev({ userRole }) && ( */}
                                <AddArticles
                                    client={clientEurekaNumber}
                                    familyCode={familyCode}
                                    numCommande={order.numCommande}
                                    // allowFamilySwitch={!familyCode}
                                    onSubmit={(articles) => this.addArticles(articles)}
                                />
                            {/* )} */}
                                <Divider type="vertical" />
                                <SearchArticles
                                    numCommande={order.numCommande}
                                    lines={lines} 
                                    onSubmit={() => this.fetchDataAsync()}
                                /> 
                            </Fragment>
                            {/* Add article from csv file button */}
                            <Divider type="vertical" />
                            <Upload 
                                type="file"
                                id="file"
                                accept=".csv"
                                customRequest={(e) => {this.importCommandArcticles(e)}}
                                showUploadList={false} // Masque la liste des fichiers importés
                            >
                                <Button>
                                    <FontAwesomeIcon icon={faFileImport} style={{marginRight: "0.5em"}}/>
                                    <FormattedMessage id="order.detail.importArticles"></FormattedMessage>
                                </Button>
                            </Upload>
                        </Fragment>
                    ) : ''
                }
            </PageLayout>
        );
    }
}

Order.propTypes = {
    intl: intlShape.isRequired,
    form: PropTypes.shape({
        getFieldsValue: PropTypes.func,
        getFieldDecorator: PropTypes.func,
    }),
};

const mapStateToProps = ({ app: { locale }, signIn: { userID, userRole, clientID } }) => ({
    userID,
    clientID,
    locale,
    userRole
});

export default connect(mapStateToProps)(injectIntl(Form.create()(Order)));
