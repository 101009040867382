import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Table, Input, Button, notification } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { setQuery } from '../actions/tables';

import './Table.less';

const itemsPerPage = 10;
const sortDirection = {
    ascend: 'DESC',
    descend: 'ASC',
};

class CustomTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            loading: false,
            total: 0,
        };
        this.refresh = this.updateList.bind(this);
        this.searchInput = null;
    }

    componentDidMount() {
        this.updateList();
    }

    componentDidUpdate({ query: prevQuery }) {
        const { query } = this.props;
        if (query !== prevQuery) {
            this.updateList();
        }
    }

    onFilterDropdownVisibleChange(visible) {
        if (visible) {
            setTimeout(() => this.searchInput.select());
        }
    }

    handleTableChange = ({ current }, filters, { columnKey, order }) => {
        const { updateQuery } = this.props;

        const updatedState = {
            current,
            sortInfo: {
                columnKey,
                order,
            },
            filters,
        };
        updateQuery(updatedState);
    }

    renderFilter = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                placeholder="Search"
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={confirm}
                ref={(node) => { this.searchInput = node; }}
            />
            <Button type="primary" onClick={confirm}>
                Search
            </Button>
            <Button onClick={clearFilters}>Reset</Button>
        </div>
    )

    updateList() {
        this.setState({ loading: true });
        const {
            fetchItems,
            keyToDbColumn,
            query: {
                current,
                sortInfo: { columnKey, order },
                filters,
            },
            mandatoryFilter,
            intl,
        } = this.props;
        const formattedFilters = {};
        Object.entries(filters).forEach(([key, [value]]) => {
            if (value) {
                formattedFilters[keyToDbColumn[key]] = value;
            }
        });
        const body = {
            ToDisplay: itemsPerPage,
            ToSkip: itemsPerPage * (current - 1),
            ...formattedFilters,
        };
        if (columnKey && order) {
            body.SortDirection = sortDirection[order];
            body.SortField = keyToDbColumn[columnKey];
        }
        fetchItems(body, mandatoryFilter).then(({ items, total, isSuccess, isUnauthorized, errorMessage, errorDetail }) => {

            if (!isSuccess){
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail,
                });
            }

            this.setState({
                items,
                loading: false,
                total,
            });
        });
    }

    render() {
        const { items, loading, total } = this.state;
        const {
            columns,
            loading: externalLoading,
            rowKey,
            tableName,
            query: { current, sortInfo, filters },
        } = this.props;
        const translatedColumns = columns.map(({ key, title, sorter, render, ...others }) => ({
            ...others,
            render: render ? render(this.refresh) : undefined,
            sorter,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
            sortOrder: key === sortInfo.columnKey && sortInfo.order,
            filterDropdown: sorter ? (props) => this.renderFilter(props) : null,
            filteredValue: filters[key],
            onFilterDropdownVisibleChange: (visible) => this.onFilterDropdownVisibleChange(visible),
        }));

        // TODO : waiting for backend to manage all filter list
        // let filteredItems = items;
        // Object.keys(filters).forEach((key) => {
        //     const column = translatedColumns.find((c) => c.dataIndex === key);
        //     if (column) {
        //         const value = column.filteredValue[0];
        //         filteredItems = filteredItems.filter((item) => column.onFilter(value, item));
        //     }
        // });

        return (
            <Table
                className="custom-table"
                dataSource={items}
                columns={translatedColumns}
                rowKey={rowKey}
                pagination={{
                    current,
                    total,
                    pageSize: itemsPerPage,
                }}
                onChange={this.handleTableChange}
                loading={loading || externalLoading}
                scroll={{ x: true }}
            />
        );
    }
}

CustomTable.propTypes = {
    fetchItems: PropTypes.func.isRequired,
    keyToDbColumn: PropTypes.shape({}).isRequired,
    columns: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.string.isRequired,
            width: PropTypes.number,
            fixed: PropTypes.string,
        }),
    ).isRequired,
    loading: PropTypes.bool,
    rowKey: PropTypes.string,
    tableName: PropTypes.string.isRequired,
    query: PropTypes.shape({
        filters: PropTypes.shape({}).isRequired,
        sortInfo: PropTypes.shape({}).isRequired,
        current: PropTypes.number.isRequired,
    }).isRequired,
    updateQuery: PropTypes.func.isRequired,
    mandatoryFilter: PropTypes.string,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ tables }, { tableName }) => ({
    query: tables[tableName] || {
        filters: {},
        sortInfo: {},
        current: 1,
    },
});

const mapDispatchToProps = (dispatch, { tableName }) => ({
    updateQuery: (query) => dispatch(setQuery(tableName, query)),
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomTable));
