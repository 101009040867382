import React, { Component, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape  } from 'react-intl';
import PropTypes from 'prop-types';
import { extensionToType } from '../../utils/helpers';
import './Catalog.less';
import { Fragment } from 'react';
import { Button, Icon, Pagination } from 'antd';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { fetchPDF } from '../../utils/apiBucherVaslin';


const BLOB_URL_TECHDOC = process.env.BLOB_URL_TECHDOC;
// const [numPages, setNumPages] = useState<number>(0);
class Doc extends Component { 
    
    constructor(props) {
        super(props);
        this.state = {
            file: '',
            fileName : '',
            extension: '',
            numPages : null,
            pageNumber:1,
            scale : 1.0,
        };

    }

    componentDidMount() {
        
    }  
    async componentDidUpdate(prevProps) {
        const {src} = this.props;
        console.log(src);
        console.log(prevProps.src)
        
        if(src !== prevProps.src){
            this.setState({pageNumber : 1});
            this.setState({scale : 1.0});
            console.log("ça passe");
            await this.setState({fileName: src});
            console.log(this.state.fileName);
            console.log(this.props);
            console.log(this.state);
            const{fileName}= this.state;
            console.log(fileName);
            await fetchPDF(src).then((url)=>{
                this.setState({file : url});
            });
            await this.setState({extension : fileName.split('.').pop()});
            console.log(this.state);
        }
    }  
    
    onDocumentLoadSuccess = ({ numPages : pages }) => {
        console.log(pages);
        if(pages){
            // this.setState({pageNumber : 1});
            this.setState({numPages:pages});
        }
    };

    scaleIn = () =>{this.setState({scale: (this.state.scale - 0.1)})};
    scaleOut = () =>{this.setState({scale: (this.state.scale + 0.1)})};

    checkExtension = () => {
        const {file, extension, pageNumber, numPages, scale} = this.state;
        console.log("check",numPages);
        console.log("check",pageNumber);
        // const fileType = src.split('.').pop();
        // const linkDecoder = BLOB_URL_TECHDOC + "/" +  escape(src);
        // console.log(file, extension);

        switch (extensionToType(extension.toLowerCase())) {
            case 'png':
                console.log('png');
                return (
                    <span className="image-wrapper">
                        <img src={file} alt="" />
                    </span>
                );
            case 'pdf': 
                console.log('pdf', numPages);
                console.log('pdf', pageNumber);
                return(   
                    <Fragment>
                        <Document file={file} onLoadSuccess={this.onDocumentLoadSuccess}>
                            <Page pageNumber={pageNumber} scale={scale} renderAnnotationLayer={false} renderTextLayer={false}/>
                        </Document>
                        <br/>
                        <Pagination simple onChange={(page)=>{this.setState({pageNumber: page})}} current={pageNumber} defaultCurrent={1} total={numPages} pageSize={1} />
                        <br/>
                        <Button.Group>
                            <Button onClick={this.scaleOut}>
                                <Icon type="zoom-in"  style={{backgroundColor:'white'}} />
                            </Button>
                            <Button onClick={this.scaleIn}>
                                <Icon type="zoom-out"  style={{backgroundColor:'white'}}/>
                            </Button>
                        </Button.Group>                 
                    </Fragment>
                
                )
            default:
            return ( 
                    <Fragment>
                        <FormattedMessage id="documents.invalidfile" />
                        <a href={file} target="_blank" download>
                            <Button htmlType="button" style={{ marginRight: 8 }}>
                                <Icon type="download" />
                                <FormattedMessage id="documents.download" />
                            </Button>
                        </a>
                    </Fragment>
                )
            }
    }
    render = () =>  {
        const {src} = this.props;
        if (!src) {
            return (
            <Fragment>
                <FormattedMessage id="documents.selectfile" />
            </Fragment>
            )
        } else {
            return this.checkExtension()
        }
    }
}

Doc.propTypes = {
    src: PropTypes.string,
    intl: intlShape.isRequired,
    codeDocument:PropTypes.string,
    codeParent:PropTypes.string,
    affichePDR:PropTypes.bool,
    niveau:PropTypes.number
};
const mapStateToProps = ({ app: { locale }, signIn: {  userRole } }) => ({
    locale,
    userRole
});

export default connect(mapStateToProps)(injectIntl(Doc));
