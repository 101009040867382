import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, Input, notification, Popover, Select, Table } from 'antd';
import { Link } from 'react-router-dom';
import { faCopy, faEdit, faSearch, faFileExport} from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import PageLayout from '../Layout/PageLayout';
import {
    alphabetically,
    compareDate,
    compareNumber,
    isGroupAdminClient,
    isOrderEditable,
    isGroupEBV,
    isInfoCompleted
} from '../../utils/helpers';
import {
    duplicateOrder,
    exportCommand,
    fetchOrderStatus, 
    fetchOrdersForClient, 
    fetchCurrentCustomerDetails, 
    fetchCustomerDetailsById, 
    fetchYearsCreatedOrders } from '../../utils/apiBucherVaslin';
import CreateOrder from './CreateOrder';
import DeleteOrder from './DeleteOrder';
import SubmitOrder from './SubmitOrder';
import DownloadFilesOrder from './DownloadFilesOrder';
import TrackingOrder from './TrackingOrder';
import FormattedCurrency  from '../utils/FormattedCurrency';


const { Option } = Select;
const tableName = 'orders';

const mongoDateFormat = 'YYYY-MM-DD HH:mm:ss';
const acctualYears = new Date().getFullYear();
const renderDate = (date) => (date ? <span>{moment(date).format('DD/MM/YY')}</span> : '-');

/* eslint-disable */
class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            displayedOrders: [],
            loading: false,
            orders: [],
            statusList: [],
            statusFilter: [],
            clientInfo: null,
            yearsCreatedOrder: [],
            yearSelected: acctualYears
        };
    }

    componentDidMount = async () => {
        /* check connexion and information */ 
        const { match: { params: { eureka } }, intl, userID  } = this.props;
        await isInfoCompleted(intl, userID);
        
        // Init status list
        await this.initStatusList();

        //Init ddl years
        await this.initCreatingOrderYers();

        // Load and filter orders
        await this.reloadOrders();

        if (!isNil(eureka)) {
            fetchCustomerDetailsById(eureka).then(({ item: clientInfo, isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
                if (!isSuccess){
                    notification.error({
                        message: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorDetail,
                    });
                }
               
                this.setState({
                    clientInfo,
                });
            });
        } else {
            fetchCurrentCustomerDetails().then(({ clientInfo }) => {
               
                this.setState({
                    clientInfo,
                });
            });
        }
    };

    /*
    *   Load and filter (on status) the list of displayed orders
    */
    reloadOrders = async () => {
        // Load orders
        await this.fetchDataAsync();

        // Update statusCount
        await this.updateStatusCount();

        // Apply filter on orders array
        await this.applyStatusFilters();
    }

    initCreatingOrderYers = async () => {
        const { clientID, intl, match: { params: { eureka } } } = this.props;
        const { items, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchYearsCreatedOrders(eureka || clientID)();
        if (!isSuccess)  {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
      await this.setState({ yearsCreatedOrder: items });
    };

    fetchDataAsync = async () => {
        await this.setState({ loading: true });
        const { clientID, intl, match: { params: { eureka } } } = this.props;
        const {yearSelected } = this.state;
        const { items, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchOrdersForClient(eureka || clientID, yearSelected)();
        if (isSuccess) {
            items.sort(function(a, b){
                return new Date(b.dateCreation) - new Date(a.dateCreation);
            });
        }
        else {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }

        await this.setState({ orders: items, loading: false });
    };

    handleDuplicate = async (numCommande) => {
        const { intl } = this.props;
        const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await duplicateOrder(numCommande);
        if (isSuccess) {
            notification.success({
                message: intl.formatMessage({
                    id: 'order.duplicate.success',
                }),
            });
            await this.reloadOrders();
        }
        else {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
    };

    initStatusList = async () => {
        const { intl, locale } = this.props;

        // Charge la liste des status
        const { status, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchOrderStatus(locale.toUpperCase());
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            return;
        }

        // Get the pre selected status filter list
        const preselectedItems = [];
        status.forEach((element) => {
            if(element.preselection) {
                preselectedItems.push(element.statutTraduit);
            }
        })

        await this.setState({statusFilter: preselectedItems, statusList: status});
    }

    // Method triggered for command export
    exportCommand  = async (commandNumber) => {
        const { intl } = this.props;

        // Export command data to csv 
        const { result, isUnauthorized, errorMessage, errorDetail } = await exportCommand(commandNumber);
        if (!result) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            return;
        }

        // If export succeed, create link to the csv file and open it
        const csvContent = "data:text/csv;charset=utf-8," + result;
        const encodedUri = encodeURI(csvContent);
        const documentName = intl.formatMessage({id: "table.order.export.fileName"}, {commandNumber});
        const link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", documentName);
        document.body.appendChild(link);
        link.click();
    }

    getColumns () {
        const { intl, userRole, clientID } = this.props;

        return [
            {
                dataIndex: '',
                key: 'numOrder',
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.numCommande + ";" + record.numCommandeOrigine),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.numCommande, b.numCommande),
                render: (text, row, index) => {
                    if(row.numCommande == row.numCommandeOrigine) {
                       return <span>{row.numCommande} </span>
                    } 
                    return <Fragment><span>{row.numCommande} </span>  <br/><span style={{fontSize:"75%", color:'#d65c10'}}><i> {row.numCommandeOrigine}</i></span></Fragment>
                },
            },
            {
                dataIndex: 'numCommandCustomer',
                key: 'numEureka',
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.numCommandCustomer),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.numCommandCustomer, b.numCommandCustomer)
            },
            {
                dataIndex: '',
                key: 'createdBy',
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.userCreated.nomPrenom),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.userCreated.nomPrenom, b.userCreated.nomPrenom),
                render : (record, clientInfo) => {
                    if (record.numEureka !== clientID){

                        // console.log(clientInfo.numEureka);
                        // console.log(record.numEureka);
                        // console.log(this.props.clientID);
                        // console.log("------------------");
                        // console.log(record.nomClient +' / ' + clientInfo.nomClient + ' : ' + record.userCreated.nomPrenom);
                        return <Fragment><span>{record.userCreated.nomPrenom }</span> <br/> <span style={{fontSize:"85%", fontStyle: 'italic', color:'#d65c10'}}>{record.nomClient}</span> </Fragment>
                        
                    }else{
                        // console.log(clientInfo.numEureka);
                        // console.log(record.numEureka);
                        // console.log(this.props.clientID);
                        // console.log("------------------");
                        // console.log(record.nomClient +' / ' + clientInfo.nomClient);
                        return <span>{record.userCreated.nomPrenom }</span>
                    }
                },
            },
            {
                dataIndex: 'montant',
                key: 'amount',
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.montant.toString()),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => compareNumber(a.montant, b.montant),
                render: (text, row, index) => { 
                    return <FormattedCurrency amount={row.montant} currency={row.codeDevise} />
                }
            },
            {
                key: 'status',
                filterDropdown: this.filterStatusDropDown,
                sorter: (a, b) => alphabetically(this.getStatus(a), this.getStatus(b)),
                render: (_, order) => { return this.getStatus(order)}
            },
            {
                dataIndex: 'dateCreation',
                key: 'dateCreation',
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, moment(record.dateCreation, mongoDateFormat).format('DD/MM/YYYY')),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => compareDate(a.dateCreation, b.dateCreation, mongoDateFormat),
                render: renderDate
            },
            {
                dataIndex: 'dateSoumission',
                key: 'dateSubmitted',
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, moment(record.dateSoumission, mongoDateFormat).format('DD/MM/YYYY')),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => compareDate(a.dateSoumission, b.dateSoumission, mongoDateFormat),
                render: renderDate
            },
            {
                key: 'action',
                render: (_, { numCommande, statutCommand, nbArticles, haveTrackingLinks, montantMinimum, montant }) => (
                    <span>
                        {isOrderEditable({ statutCommand }) ? (
                            <Popover content={<FormattedMessage id={`table.${tableName}.edit`} />}>
                                <Link to={`/orders/${numCommande}`}>
                                    <Button>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </Link>
                            </Popover>) : (
                            <Popover content={<FormattedMessage id={`table.${tableName}.details`} />}>
                                <Link to={`/orders/${numCommande}`}>
                                    <Button>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </Link>
                            </Popover>)
                        }
                        <Divider type="vertical" />
                        {isGroupEBV({userRole}) && (
                        <Popover content={<FormattedMessage id={`table.${tableName}.duplicate`} />}>
                            <Button>
                                <FontAwesomeIcon icon={faCopy} onClick={() => this.handleDuplicate(numCommande)} />
                            </Button>
                        </Popover>
                        )}
                        {/* Button submit show when no validate (or date not passed) and user is superAdmin or AdminClient
                            If user haven't these roles we show a button deactivate
                        */}
                        {isOrderEditable({ statutCommand }) && (
                            ((isGroupEBV({userRole}) || isGroupAdminClient({userRole})) ? (
                                nbArticles ?
                                    (
                                        <Fragment>
                                        <Divider type="vertical" />
                                        <SubmitOrder id={numCommande} refresh={this.reloadOrders} isMontantMini={montantMinimum > montant ? true : false} />
                                        </Fragment>
                                    ) :  
                                        <Fragment>
                                        <Divider type="vertical" />
                                            <Popover content={<FormattedMessage id="table.order.submit.noArticle" />}>
                                                <Button disabled>
                                                    <FontAwesomeIcon icon="paper-plane" />
                                                </Button>
                                            </Popover>
                                        </Fragment>
                            ) : 
                                <Fragment>
                                <Divider type="vertical" />
                                    <Popover content={<FormattedMessage id="table.order.submit.deactivate" />}>
                                        <Button disabled>
                                            <FontAwesomeIcon icon="paper-plane" />
                                        </Button>
                                    </Popover>
                                </Fragment>
                                )
                        )}
                        {/* Export command button */}
                        <Fragment>
                            <Divider type="vertical" />
                        {isGroupEBV({userRole}) && (
                            <Popover content={<FormattedMessage id="table.order.export" />}>
                                <Button htmlType="button" onClick={() => this.exportCommand(numCommande)}>
                                    <FontAwesomeIcon icon={faFileExport} />
                                </Button>
                            </Popover>
                        )}
                        </Fragment>
                        {/* Tracking order button */}
                        {!isOrderEditable({ statutCommand }) && (
                        <Fragment>
                            <Divider type="vertical" />
                            <TrackingOrder id={numCommande} haveTrackingLinks={haveTrackingLinks} />
                        </Fragment>
                        )}
                        {/** Can't delete submitted order */}
                        {isOrderEditable({ statutCommand }) && (
                            <Fragment>
                                <Divider type="vertical" />
                                <DeleteOrder id={numCommande} refresh={this.reloadOrders} />
                            </Fragment>
                        )}
                        {/* Download command button */}
                        {!isOrderEditable({ statutCommand }) && (
                            <Fragment>
                                <Divider type="vertical" />
                                <DownloadFilesOrder id={numCommande} />
                            </Fragment>
                        )}
                    </span>
                ),
            },
        ];
    };

    // Return status id for translation
    getStatus = (order) => {
        const { statusList } = this.state;
        const orderStatut = statusList.find((status) => status.statut === order.statutCommand);
        return (orderStatut) ? orderStatut.statutTraduit : null;
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                <FormattedMessage id="common.search" />
            </Button>
            <Button onClick={this.handleReset(clearFilters)}><FormattedMessage id="common.cancel" /></Button>
        </div>
    );

    onFilter = (value, obj) => obj && obj.toLowerCase().includes(value.toLowerCase());

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    // Method used to update statusListFilter
    changeFilterStatus = async (statusList) => {
        // Update filter value
        await this.setState({ statusFilter: statusList});

        // Trigger filter on order list
        await this.applyStatusFilters();
    }

    changeYearsOrder =  async (year) => {
        // Update selected years
        await this.setState({ yearSelected: year});

        await this.reloadOrders();
    }

    // Method used to filter based on selected status
    applyStatusFilters = async () => {
        const { orders, statusFilter} = this.state;
        const visibleOrders = (statusFilter && statusFilter.length > 0) ? [] : orders;

        // Filter orders according to filter status list
        if (statusFilter && statusFilter.length > 0) {
            orders.forEach((order) => {
                // Get status key to check if status filter includes that key
                const orderStatus = this.getStatus(order);
                if (statusFilter.includes(orderStatus)) {
                    visibleOrders.push(order);
                }
            });
        }

        // Update state according to filter results
        await this.setState({displayedOrder: visibleOrders});
    }

    // Method used to update status count
    updateStatusCount = async () => {
        const { orders, statusList } = this.state;
        const updatedStatusList = [];

        // Loop throught initial status list
        statusList.forEach((status) => {
            let orderCount = 0;

            // Loop throught order to see whose who match current status
            if(orders && orders.length > 0) {
                orders.forEach((order) => {
                    const statusKey = this.getStatus(order);

                    // Check is status fit
                    if (statusKey === status.statutTraduit) {
                        orderCount ++;
                    }
                });
            }

            // Update status count
            status.orderCount = orderCount;
            updatedStatusList.push(status);
        });

        // Update status list in state
        await this.setState({statusList: updatedStatusList});
    }

    render() {
        const { match: { params: { eureka } }, history , userRole} = this.props;
        const { displayedOrder, loading, statusFilter, clientInfo, yearsCreatedOrder, yearSelected } = this.state;

        const columns = this.getColumns();
        const translatedColumns = columns.map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        return (
            <PageLayout pageKey={isNil(eureka) ? 'orders' : 'customers'} history={history}>
                <h2>
                    <FormattedMessage id={`table.${tableName}.${eureka ? 'listTitle' : 'title'}`} />
                </h2>

                {/* Status filter */}
                <div style={{marginBottom:'1em'}}>
                    <FormattedMessage id='table.orders.statusFilter.label' /> : 
                    <Select
                        allowClear={true}
                        mode="multiple"
                        onChange={(value) => { this.changeFilterStatus(value)}}
                        style={{minWidth:'300px', maxWidth:'calc(100% - 200px)', margin:'1em'}}
                        value={statusFilter}
                    >
                        { this.state.statusList.map((status) => (
                            <Option key={status.id} value={status.statutTraduit}>
                                {status.statutTraduit} ({status.orderCount})
                            </Option>
                        ))}
                    </Select>
                    <FormattedMessage id='table.orders.yearCreatedOrder.label' /> : 
                    <Select
                        value={yearSelected}
                        style={{width :'80px', marginLeft:'1em'}}
                        onChange={(value) => { this.changeYearsOrder(value)}}
                    >
                        {yearsCreatedOrder.map((years) => (
                            <Option key={years}>
                                {years} 
                            </Option>
                        ))}
                    </Select>
                </div>

                <Table
                    className="custom-table"
                    dataSource={displayedOrder}
                    columns={translatedColumns}
                    rowKey="numCommande"
                    loading={loading}
                />
                <CreateOrder refresh={this.reloadOrders} client={clientInfo} />
            </PageLayout>
        );
    }
}

Orders.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            eureka: PropTypes.string,
        }).isRequired,
    }).isRequired,
    clientId: PropTypes.string,
    intl: intlShape.isRequired,
    userRole: PropTypes.array.isRequired,
};

const mapStateToProps = ({ app: { locale }, signIn: { clientID, userID, userRole } }) => ({
    clientID,
    userID,
    locale,
    userRole
});

export default injectIntl(connect(mapStateToProps)(Orders));
