import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import moment from "moment";
import {
  Button,
  Modal,
  Divider,
  Card,
  Form,
  Input,
  DatePicker,
  Select,
  Spin,
  notification,
} from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEdit,
  faFileImport,
  faCheck,
  faAward,
} from "@fortawesome/free-solid-svg-icons";
import {
  fetchIncidentById,
  fetchOrderClientAndType,
  fetchBillOrder,
  fetchArticleBill,
  updateIncident,
  getAddressCustomerById,
} from "../../utils/apiBucherVaslin";
import AddAnnotation from "./AddAnnotation";
import IncidentBon from "./IncidentBon";
import BonDeRetour from "./BonDeRetour";
import SearchArticles from "../Orders/SearchArticles";
import "./IncidentInfo.less";

const { Item: FormItem } = Form;
const { TextArea } = Input;
const { Option } = Select;
//TODO: Lors de l'implementation de la modification (formItem) passer d'un format class a un format Hooks
class IncidentInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDescriptionModal: false,
      showDescriptionModalName: null,
      showDescriptionModalValue: null,
      incidentInfoAnnotation: null,
      incidentInfo: null,
      isEditable: false,
      commandList: [],
      billList: [],
      articleListe: [],
      articleSelected: null,
      loadingIncident: true,
      bonN1File: null,
      bonN1FileName: "",
      bonN1File: null,
      bonN2FileName: "",
      mainAddressCustomer: null,
    };
  }
  /**
   * Method used to hide desciption modal
   */
  hideIncidentDescription = async () => {
    this.setState({
      showDescriptionModal: false,
      showDescriptionModalName: null,
      showDescriptionModalValue: null,
      commandList: [],
      billList: [],
      isEditable: false,
    });
  };
  // Display transfert confirmation modal
  showSubmitModal = async () => {
    const {
      form: { validateFieldsAndScroll },
      incident,
    } = this.props;
    const { articleSelected } = this.state;
    validateFieldsAndScroll(async (err, values) => {
      if (incident.typePriseEnCharge == "100000001") {
        values.article.codeArticle = articleSelected;
      }
      if (!err) {
        const { isSuccess, isUnauthorized, errorMessage, errorDetail } =
          await updateIncident(values, incident.incidentId);
        if (!isSuccess) {
          notification.error({
            message: isUnauthorized
              ? intl.formatMessage({ id: "error.unauthorized.title" })
              : intl.formatMessage({ id: "common.error" }),
            description: isUnauthorized
              ? intl.formatMessage({ id: "error.unauthorized.message" })
              : errorDetail,
          });
          return;
        }
      }
    });
    this.hideIncidentDescription();
  };
  /**
   * Method used to get more incident information
   */
  getIncidentById = async (incidentId) => {
    this.setState({
      incidentInfoAnnotation: null,
      incidentInfo: null,
      loadingIncident: true,
    });
    const { incidentInfo, isSuccess, isUnauthorized, errorMessage, errorDetail } =
      await fetchIncidentById(incidentId);
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
      return;
    }
    if (incidentInfo.bonn1File) {
      this.setState({
        bonN1File: incidentInfo.bonn1File,
        bonN1FileName: incidentInfo.bonn1File.fileName,
      });
    }
    if (incidentInfo.bonn2File) {
      this.setState({
        bonN2File: incidentInfo.bonn2File,
        bonN2FileName: incidentInfo.bonn2File.fileName,
      });
    }

    if (incidentInfo.annotationsIncident) {
      incidentInfo.annotationsIncident.forEach((incident) => {
        incident.imagesBase64.forEach((imgBase64, i) => {
          incident.noteText = this.addStr(
            incident.noteText,
            this.getPosition(incident.noteText, "<img loading", i + 1),
            " <a download='img.png' href=data:image/png;base64," +
              imgBase64 +
              ">"
          );
          incident.noteText = this.addStr(
            incident.noteText,
            this.getPosition(incident.noteText, '"></div>', i + 1) + 2,
            " </a>"
          );
        });
      });
    }
    this.loadAdresseCustomer(incidentInfo.customerId);
    const articleSelected = incidentInfo.article
      ? incidentInfo.article.productnumber
      : null;
    this.setState({
      incidentInfoAnnotation: incidentInfo.annotationsIncident,
      incidentInfo,
      loadingIncident: false,
      articleSelected,
    });
  };
  /**
   * Method used to load adresse customer connected
   */
  loadAdresseCustomer = async (customerId) => {
    const { addresses, isSuccess, isUnauthorized, errorMessage, errorDetail } =
      await getAddressCustomerById(customerId);
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
      return;
    }
    const mainAddressCustomer = addresses.filter(
      (address) => address.addressTypeCode === 3
    );

    this.setState({ mainAddressCustomer });
  };
  // Method used to get the command client
  getCommandeClient = async () => {
    const { clientEurekaNumber } = this.props;
    const selectedType = ["CO","RE"]; // 20231212 changement pour ["CO","RE"] // Toujours RE dans le cas d'une garantie
    //Get command list by client
    const { commandList, isSuccess, isUnauthorized, errorMessage, errorDetail } =
      await fetchOrderClientAndType(clientEurekaNumber, selectedType);
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
    }
    this.setState({ commandList, billList: [] });
  };

  getPosition(string, subString, index) {
    return string.split(subString, index).join(subString).length;
  }

  addStr(str, index, stringToAdd) {
    return (
      str.substring(0, index) + stringToAdd + str.substring(index, str.length)
    );
  }

  cleanBillOrder = (value) => {
    const {
      form: { setFieldsValue },
    } = this.props;
    setFieldsValue({ ["factureOrigine".valueOf()]: null });
    setFieldsValue({ ["article.codeArticle".valueOf()]: null });
    this.getBillOrder(value);
  };

  getBillOrder = async (value) => {
    //Get order list by client
    const { intl } = this.props;
    const { billList, isSuccess, isUnauthorized, errorMessage, errorDetail } =
      await fetchBillOrder(value);
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
    }
    this.setState({ billList, articleListe: [] });
  };

  cleanArticleBill = (value) => {
    const {
      form: { setFieldsValue },
    } = this.props;
    setFieldsValue({ ["article.codeArticle".valueOf()]: null });
    this.getArticleBill(value);
  };
  // Method used to get bill articles
  getArticleBill = async (value) => {
    const { intl } = this.props;
    if (value) {
      const { articleListe, isSuccess, isUnauthorized, errorMessage, errorDetail } =
        await fetchArticleBill(value);
      if (!isSuccess) {
        notification.error({
          message: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.title" })
            : intl.formatMessage({ id: "common.error" }),
          description: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.message" })
            : errorDetail,
        });
      }
      this.setState({ articleListe });
    }
  };

  downloadFileBase64 = (infoAnnotation) => {
    const linkSource = `data:${infoAnnotation.mimeType};base64,${infoAnnotation.documentBody}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = infoAnnotation.fileName;
    downloadLink.click();
  };
  /**
   * Method used to display desciption modal
   */
  showIncidentDescription = (incident) => {
    this.getIncidentById(incident.incidentId);
    if (incident.demandeGarantie) {
      this.getCommandeClient();
      this.getBillOrder(incident.commandeOrigine);
      this.getArticleBill(incident.factureOrigine);
    }
    //  Replace all \n with escape tag (replace by < /br> if it's able to put html on textArea)
    var description = incident.description
      ? incident.description.replaceAll("\n", " ")
      : "";
    // Update State
    this.setState({
      showDescriptionModal: true,
      showDescriptionModalName: incident.ticketNumber,
      showDescriptionModalValue: description,
    });
  };
  getDateAnnotation(nom, subject, date) {
    return (
      <Fragment>
        {nom + " - " + subject}
        <span style={{ fontSize: "10px", float: "right" }}>
          {moment(date).format("DD/MM/YYYY HH:mm")}
        </span>
      </Fragment>
    );
  }
  updateStateBon = (value, bonN) => {
    if (bonN == "bonN1File" && value) {
      this.setState({ bonN1File: value[0], bonN1FileName: value[0].name });
    }
    if (bonN == "bonN2File" && value) {
      this.setState({ bonN2File: value[0], bonN2FileName: value[0].name });
    }
  };

  render() {
    const {
      articleSelected,
      articleListe,
      billList,
      bonN1File,
      bonN2File,
      bonN1FileName,
      bonN2FileName,
      commandList,
      showDescriptionModal,
      showDescriptionModalName,
      showDescriptionModalValue,
      incidentInfoAnnotation,
      isEditable,
      incidentInfo,
      loadingIncident,
      mainAddressCustomer,
    } = this.state;
    const {
      intl,
      incident,
      form: { getFieldDecorator },
      clientName,
    } = this.props;
    return (
      <Fragment>
        <Button
          onClick={() => {
            this.showIncidentDescription(incident);
          }}
        >
          <FontAwesomeIcon icon={faEye} />
        </Button>

        <Modal
          title={
            incident.demandeGarantie ? (
              <Fragment>
                <FormattedMessage
                  id="table.incidents.descriptionModalGarantie.title"
                  values={{ incidentName: showDescriptionModalName }}
                />
                &nbsp;-&nbsp;{incident.etatPriseEnChargeLabel}&nbsp;-&nbsp;
                <FontAwesomeIcon
                  icon={faAward}
                  style={{ marginLeft: "0.5em", color: "green" }}
                />
                {incident.isEditableForGarantie == true ? (
                  <Fragment>
                    <span style={{ marginLeft: "15px" }}>
                      {isEditable ? (
                        <Button onClick={() => this.showSubmitModal()}>
                          <FontAwesomeIcon
                            icon={faCheck}
                            style={{ marginRight: "0.5em" }}
                          />
                          <FormattedMessage id="common.validate" />
                        </Button>
                      ) : (
                        <Button
                          onClick={() => this.setState({ isEditable: true })}
                        >
                          <FontAwesomeIcon
                            icon={faEdit}
                            style={{ marginRight: "0.5em" }}
                          />
                          <FormattedMessage id="common.editer" />
                        </Button>
                      )}
                    </span>
                  </Fragment>
                ) : (
                  ""
                )}
                {incident.etatPriseEnCharge == "100000005" ? (
                  <span style={{ marginLeft: "15px" }}>
                    <BonDeRetour
                      clientName={clientName}
                      incident={incidentInfo}
                      customerAddresse={mainAddressCustomer}
                    />
                  </span>
                ) : (
                  ""
                )}
              </Fragment>
            ) : (
              <Fragment>
                <FormattedMessage
                  id="table.incidents.descriptionModalIncident.title"
                  values={{ incidentName: showDescriptionModalName }}
                />
                &nbsp;-&nbsp;
                {incident.statusCodeLabel ? incident.statusCodeLabel : ""}
                &nbsp;-&nbsp;
              </Fragment>
            )
          }
          footer={null}
          visible={showDescriptionModal}
          onCancel={this.hideIncidentDescription}
          width="80%"
        >
          <div className="incident-container">
            <div className="infoIncident-container">
              <div>
                <FormItem
                  label={
                    <b>
                      <FormattedMessage id="incident.modal.formInfo.techContact" />
                    </b>
                  }
                >
                  {getFieldDecorator("techContact")(
                    <span>
                      {incident.techContact ? incident.techContact : ""}
                    </span>
                  )}
                </FormItem>
                <FormItem
                  label={
                    <b>
                      <FormattedMessage id="incident.modal.formInfo.categorie" />
                    </b>
                  }
                >
                  {getFieldDecorator("categorie")(
                    <span>
                      {incident.categorie ? incident.categorie.new_name : ""}
                    </span>
                  )}
                </FormItem>
                <FormItem
                  label={
                    <b>
                      <FormattedMessage id="incident.modal.formInfo.incidentType" />
                    </b>
                  }
                >
                  {getFieldDecorator("incidentType")(
                    <span>
                      {incident.incidentType ? incident.incidentType : ""}
                    </span>
                  )}
                </FormItem>
              </div>
              {incident.demandeGarantie && (
                <div>
                  <FormItem
                    label={
                      <b>
                        <FormattedMessage id="contact.form.dateDefaillance" />
                      </b>
                    }
                  >
                    {getFieldDecorator("dateDefaillance", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="contact.form.dateDefaillanceRequired" />
                          ),
                        },
                      ],
                      initialValue: incident.dateDefaillance
                        ? moment(incident.dateDefaillance)
                        : "",
                    })(
                      <DatePicker
                        disabled={isEditable ? false : true}
                        disabledDate={(d) => !d || d.isAfter(new Date())}
                        format="DD/MM/YY"
                      />
                    )}
                  </FormItem>

                  <IncidentBon
                    incidentId={incident.incidentId}
                    isEditable={isEditable}
                    bonN1File={bonN1File}
                    bonN1FileName={bonN1FileName}
                    bonN2File={bonN2File}
                    bonN2FileName={bonN2FileName}
                    typePriseEnCharge={incident.typePriseEnCharge}
                    downloadFileBase64={(bon) => this.downloadFileBase64(bon)}
                    updateStateBon={(value, bonN) =>
                      this.updateStateBon(value, bonN)
                    }
                  ></IncidentBon>

                  {incident.typePriseEnCharge == "100000000" && (
                    <Fragment>
                      <FormItem
                        label={
                          <b>
                            <FormattedMessage id="incident.modal.formInfo.commandeOrigine" />
                          </b>
                        }
                      >
                        {getFieldDecorator("commandeOrigine", {
                          initialValue: incident.commandeOrigine
                            ? incident.commandeOrigine
                            : "",
                        })(
                          <Select
                            disabled={isEditable ? false : true}
                            style={{ width: "200px" }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onSelect={(value) => this.cleanBillOrder(value)}
                            showSearch={true}
                          >
                            <Option value={null}>
                              {intl.formatMessage({
                                id: "incident.commandeOrigine.toutes",
                              })}
                            </Option>
                            {commandList &&
                              commandList.map((commandeOrigine) => (
                                <Option key={commandeOrigine.numCommande}>
                                  {commandeOrigine.numCommande}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </FormItem>
                      <FormItem
                        label={
                          <b>
                            <FormattedMessage id="incident.modal.formInfo.factureOrigine" />
                          </b>
                        }
                      >
                        {getFieldDecorator("factureOrigine", {
                          rules: [
                            {
                              required: true,
                            },
                          ],
                          initialValue: incident.factureOrigine
                            ? incident.factureOrigine
                            : "",
                        })(
                          <Select
                            disabled={isEditable ? false : true}
                            style={{ width: "200px" }}
                            filterOption={(input, option) =>
                              option.props.children
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                            onSelect={(value) => this.cleanArticleBill(value)}
                            showSearch={true}
                          >
                            {billList &&
                              billList.map((bill) => (
                                <Option key={bill.numBilling}>
                                  {bill.numBilling}
                                </Option>
                              ))}
                          </Select>
                        )}
                      </FormItem>
                    </Fragment>
                  )}
                  <FormItem
                    label={
                      <b>
                        <FormattedMessage id="incident.modal.formInfo.seriePiece" />
                      </b>
                    }
                  >
                    {getFieldDecorator("seriePiece", {
                      initialValue: incident.seriePiece
                        ? incident.seriePiece
                        : "",
                    })(
                      <Input
                        style={{ width: "200px" }}
                        disabled={isEditable ? false : true}
                      />
                    )}
                  </FormItem>
                  {incident.clientLivre && (
                    <FormItem
                      label={
                        <b>
                          <FormattedMessage id="incident.modal.formInfo.clientLivre" />
                        </b>
                      }
                    >
                      {getFieldDecorator("clientLivre")(
                        <span>{incident.clientLivre}</span>
                      )}
                    </FormItem>
                  )}
                  {incident.typePriseEnCharge == "100000000" ? (
                    <FormItem
                      label={
                        <b>
                          <FormattedMessage id="incident.modal.formInfo.article" />
                        </b>
                      }
                    >
                      {getFieldDecorator("article.codeArticle", {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                        initialValue: incidentInfo
                          ? incidentInfo.article
                            ? incidentInfo.article.productnumber
                            : ""
                          : "",
                      })(
                        <Select
                          disabled={isEditable ? false : true}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .includes(input.toLowerCase())
                          }
                          showSearch={true}
                        >
                          {articleListe &&
                            articleListe.map((article) => (
                              <Option key={article.codeArticle}>
                                {article.codeArticle}
                              </Option>
                            ))}
                        </Select>
                      )}
                    </FormItem>
                  ) : (
                    <FormItem
                      label={
                        <b>
                          <FormattedMessage id="incident.modal.formInfo.article" />
                        </b>
                      }
                    >
                      {getFieldDecorator("article.codeArticle", {
                        rules: [
                          {
                            required: true,
                          },
                        ],
                        initialValue: articleSelected,
                      })(
                        isEditable ? (
                          <a>
                            <SearchArticles
                              updateIncident={isEditable}
                              pieceSelected={articleSelected}
                              onSubmitWarrantyPiece={(
                                codeArticle,
                                prix,
                                libelle
                              ) =>
                                this.setState({ articleSelected: codeArticle })
                              }
                            />
                          </a>
                        ) : (
                          <Input disabled={true} />
                        )
                      )}
                    </FormItem>
                  )}
                  {incident.documentErp && (
                    <FormItem
                      label={
                        <b>
                          <FormattedMessage id="incident.modal.formInfo.documentErp" />
                        </b>
                      }
                    >
                      {getFieldDecorator("documentErp")(
                        <span>
                          {incident.documentErp ? incident.documentErp : ""}
                        </span>
                      )}
                    </FormItem>
                  )}
                </div>
              )}
              <FormItem
                label={
                  <b>
                    <FormattedMessage id="incident.modal.formInfo.description" />
                  </b>
                }
              >
                {getFieldDecorator("description", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="contact.form.messageRequired" />
                      ),
                    },
                  ],
                  initialValue: showDescriptionModalValue,
                })(
                  <TextArea
                    autoSize={{ minRows: 4 }}
                    disabled={isEditable ? false : true}
                  ></TextArea>
                )}
              </FormItem>
            </div>
            <div className="annotation-container">
              {incident.isEditableForIncident && (
                <AddAnnotation
                  disabledButton={loadingIncident}
                  incidentId={incident.incidentId}
                  reloadAnnotation={(incidentId) =>
                    this.getIncidentById(incidentId)
                  }
                />
              )}
              {loadingIncident ? (
                <Spin style={{ display: "block" }} size="small" />
              ) : incidentInfoAnnotation &&
                incidentInfoAnnotation.length > 0 ? (
                <Fragment>
                  {incidentInfoAnnotation.map(
                    (infoAnnotation, annotationid) => (
                      <Card
                        key={annotationid}
                        size="small"
                        title={this.getDateAnnotation(
                          infoAnnotation.fullNameCreatedBy,
                          infoAnnotation.subject,
                          infoAnnotation.createdOn
                        )}
                        style={
                          infoAnnotation.isCreatedByBV
                            ? {
                                marginBottom: "2px",
                                backgroundColor: "rgba(5, 227, 235, 0.03)",
                                borderColor: "rgba(5, 227, 235)",
                                border: "thin solid 5px",
                              }
                            : {
                                marginBottom: "2px",
                                backgroundColor: "rgba(220, 0, 35, 0.03)",
                                borderColor: "rgba(220, 0, 35)",
                                border: "thin solid 5px",
                              }
                        }
                      >
                        {infoAnnotation.noteText && (
                          <Fragment>
                            <span
                              dangerouslySetInnerHTML={{
                                __html: infoAnnotation.noteText,
                              }}
                            ></span>
                            <br></br>
                          </Fragment>
                        )}
                        {infoAnnotation.fileName && (
                          <Fragment>
                            <FontAwesomeIcon
                              icon={faFileImport}
                              style={{
                                marginRight: "0.5em",
                                cursor: "pointer",
                              }}
                            />
                            <span
                              onClick={() =>
                                this.downloadFileBase64(infoAnnotation)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {infoAnnotation.fileName}
                            </span>
                          </Fragment>
                        )}
                      </Card>
                    )
                  )}
                  <Divider type="vertical" style={{ height: "100%" }} />
                </Fragment>
              ) : (
                <Card style={{ marginBottom: "2px" }}>
                  <FormattedMessage id="incident.modal.formInfo.noAnnotation" />
                </Card>
              )}
            </div>
          </div>
        </Modal>
      </Fragment>
    );
  }
}

IncidentInfo.propTypes = {
  incident: PropTypes.object.isRequired,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }),
  intl: intlShape.isRequired,
};
const mapStateToProps = ({ signIn: { clientEurekaNumber, clientName } }) => ({
  clientEurekaNumber,
  clientName,
});

export default connect(mapStateToProps)(
  injectIntl(Form.create()(IncidentInfo))
);
