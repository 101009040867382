import React, { Fragment, useState } from "react";
import PageLayout from "../Layout/PageLayout";
import { Tabs } from "antd";
import moment from "moment";
import { FormattedMessage } from "react-intl";
import { alphabetically, compareDate } from "../../utils/helpers";
import {
  RenderCommentaire,
  FilterDropDown,
  OnFilterDropdownVisibleChange,
  OnFilter,
} from "./NetworkRequestsActions";
import NetworkRequests from "./NetworkRequests";
import NetworkRequestsAnswered from "./NetworkRequestsAnswered";
import MyNetworkRequests from "./MyNetworkRequests";
import CreateNetworkRequests from "./CreateNetworkRequests";

const { TabPane } = Tabs;
const mongoDateFormat = "YYYY-MM-DD HH:mm:ss";
const renderDate = (date) =>
  date ? <span>{moment(date).format("DD/MM/YY")}</span> : "-";

let networkRequestsColumns = [
  {
    dataIndex: "codeArticle",
    key: "codeArticle",
    filterDropdown: FilterDropDown,
    onFilter: (value, record) => OnFilter(value, record.codeArticle.toString()),
    onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
    sorter: (a, b) => alphabetically(a.codeArticle, b.codeArticle),
    title: <FormattedMessage id={`networkRequests.table.codeArticle`} />,
  },
  {
    dataIndex: "libelle",
    key: "libelle",
    filterDropdown: FilterDropDown,
    onFilter: (value, record) => OnFilter(value, record.libelle.toString()),
    onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
    sorter: (a, b) => alphabetically(a.libelle, b.libelle),
    title: <FormattedMessage id={`networkRequests.table.libelle`} />,
  },
  {
    dataIndex: "commentaire",
    key: "commentaire",
    title: <FormattedMessage id={`networkRequests.table.commentaire`} />,
    render: (_, record) =>
      record.commentaire && record.commentaire.length > 30 ? (
        <Fragment>
          <RenderCommentaire commentaire={record.commentaire} />
        </Fragment>
      ) : (
        record.commentaire
      ),
  },
  {
    dataIndex: "qty",
    key: "qty",
    filterDropdown: FilterDropDown,
    onFilter: (value, record) => OnFilter(value, record.qty.toString()),
    onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
    sorter: (a, b) => a.qty > b.qty,
    title: <FormattedMessage id={`networkRequests.table.quantite`} />,
  },
  {
    dataIndex: "dateBesoin",
    key: "dateBesoin",
    filterDropdown: FilterDropDown,
    onFilter: (value, record) =>
      OnFilter(
        value,
        moment(record.dateBesoin, mongoDateFormat).format("DD/MM/YYYY")
      ),
    onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
    sorter: (a, b) => compareDate(a.dateBesoin, b.dateBesoin, mongoDateFormat),
    title: <FormattedMessage id={`networkRequests.table.dateBesoin`} />,
    render: renderDate,
  },
  {
    dataIndex: "criticiteLibelle",
    key: "criticiteLibelle",
    filterDropdown: FilterDropDown,
    onFilter: (value, record) =>
      OnFilter(value, record.criticiteLibelle.toString()),
    onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
    sorter: (a, b) => alphabetically(a.criticiteLibelle, b.criticiteLibelle),
    title: <FormattedMessage id={`networkRequests.table.criticite`} />,
  },
  {
    dataIndex: "occasionLibelle",
    key: "occasionLibelle",
    filterDropdown: FilterDropDown,
    onFilter: (value, record) =>
      OnFilter(value, record.occasionLibelle.toString()),
    onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
    sorter: (a, b) => alphabetically(a.occasionLibelle, b.occasionLibelle),
    title: <FormattedMessage id={`networkRequests.modal.occasion`} />,
  },
];
const NetworkRequestsMenu = () => {
  const [refresh, setRefresh] = useState(false);
  const isRefreshing = () => {
    setRefresh(!refresh);
  };
  return (
    <PageLayout pageKey="networkRequests" history={history}>
      <h2>
        <FormattedMessage id="menu.eMarket.networkRequests" />
      </h2>
      <CreateNetworkRequests refresh={() => isRefreshing()} />
      <Tabs type="card" defaultActiveKey="1">
        <TabPane
          tab={<FormattedMessage id="networkRequests.menu.networkRequests" />}
          key="1"
        >
          <NetworkRequests
            columns={networkRequestsColumns}
            isRefreshing={refresh}
            refresh={() => isRefreshing()}
          />
        </TabPane>
        <TabPane
          tab={
            <FormattedMessage id="networkRequests.menu.networkRequestsAnswered" />
          }
          key="2"
        >
          <NetworkRequestsAnswered
            columns={networkRequestsColumns}
            isRefreshing={refresh}
            refresh={() => isRefreshing()}
          />
        </TabPane>
        <TabPane
          tab={<FormattedMessage id="networkRequests.menu.myNetworkRequests" />}
          key="3"
        >
          <MyNetworkRequests
            columns={networkRequestsColumns}
            isRefreshing={refresh}
            refresh={() => isRefreshing()}
          />
        </TabPane>
      </Tabs>
    </PageLayout>
  );
};

export default NetworkRequestsMenu;
