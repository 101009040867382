import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Form, Input, Select, Switch, notification } from 'antd';
import { locales } from '../../translations';
import { formItemLayout } from '../../utils/formItemLayout';
import { REGEX_NAME , REGEX_ONLY_CARACTERE_LATIN } from '../../utils/constants';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import './FormItem.less';
import {
    checkEmailExistOnUser,
    fetchInfoDropDownUser
} from '../../utils/apiBucherVaslin';
import {
    isInfoCompleted
} from '../../utils/helpers';

const { Item: FormItem } = Form;
const { Option } = Select;

class UserFormItems extends Component {
    constructor(props){
        super(props);
        this.state = {
            showPublicDevices: true,
            loading: false,
            dupeState: {
                userMail: 0,
            },
            fonction :[],
            service: [],
            typeInterlocuteur: [],
            civilite: []
        };
    }

    async componentDidMount() {
        const { user: { showPublicDevices }, intl, userID} = this.props;
        /* check connexion and information */ 
        if(userID) {
            await isInfoCompleted(intl, userID);
        }
        await this.loadInfoDropDownUser();
        this.setState({showPublicDevices: showPublicDevices !== undefined ? showPublicDevices : true, loading: true});
    }

    /**
     * Method used to get dropdown information by user langage 
     */
    loadInfoDropDownUser = async () => {
        const { intl, locale } = this.props;
        const { infoDropDown, isSuccess, isUnauthorized, errorMessage, errorDetail }  = await fetchInfoDropDownUser(locale);
        var civilite;
        var fonction;
        var typeInterlocuteur;
        var service;
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
        const civilite = infoDropDown.filter((info) => info.nameField === "new_civilite");      
        const fonction = infoDropDown.filter((info) => info.nameField === "new_fonction");       
        const typeInterlocuteur2 = infoDropDown.filter((info) => info.nameField === "tech_interlocuteurtypecode");       
        const typeInterlocuteur = typeInterlocuteur2.concat({libelleField : intl.formatMessage({ id: 'user.form.notypeInterlocuteur' }) , nameField : '', valueField : null}); 
        const service = infoDropDown.filter((info) => info.nameField === "new_service");  
       await this.setState({civilite, fonction, typeInterlocuteur, service});
    }
    /**
     * Method called on blur for the fields who needs to be unique
     * @param {string} field Field decorator used to now on which field we want to check dupe
     */
    checkDupeUser = async (field) => {
        const { form, user } = this.props;

        // Copy dupeState to update it later
        const dupeState = JSON.parse(JSON.stringify(this.state.dupeState));
        const fieldValue = form.getFieldValue(field);

        // Check if field display an error and if field display a dupe error
        const errors = form.getFieldError(field);
        const dupeErrors = (errors) ? errors.filter((error) => !error.props) : null;
        // Check for dupe only if another error isn't displayed on this fields
        if (!errors || !dupeErrors || errors.length - dupeErrors.length === 0) {
            switch (field) {
                case "user.email":
                    if(fieldValue != user.email ) {
                        const { item: itemEmail } = await checkEmailExistOnUser(fieldValue);
                        dupeState.userMail = itemEmail;
                        await this.setState({dupeState});
                    }
                    break;
            }
        }        
    }

    /**
     * Method used to check for email duplication
     */
    checkEmailValidationUser = async (_) => {
        console.log('checkEmailValidationUser');
        const { intl, userPrefix } = this.props;
        // Trigger dupe check
        await this.checkDupeUser(`${userPrefix}email`);
        // Get dupeState
        const { dupeState } = this.state;
        const message = '';
        if (dupeState.userMail === 2) {
            // Reject if user email is a duplicate and actif
            message = intl.formatMessage({id:"user.form.emailDuplicate"});
            return Promise.reject(message);
        } else {
            return Promise.resolve();
        }
    }

    render() {
        const {
            form: { getFieldDecorator },
            intl,
            locale,
            userPrefix,
            user
        } = this.props;
        const { showPublicDevices, loading, civilite, fonction, service, typeInterlocuteur } = this.state;
        return <Fragment>
        {/*  CIVILITE  */}
            <FormItem
            {...formItemLayout}
                label={(
                    <FormattedMessage
                        id="user.form.civilite"
                        defaultMessage="Civility"
                    />
                )}
            >
                {getFieldDecorator(`${userPrefix}Civilite`, {
                    rules: [{
                        required: true,
                        message: (
                            <FormattedMessage
                                id="user.form.civiliteRequired"
                                defaultMessage="Please select a title"
                            />
                        ),
                    }],  
                    initialValue: user.civilite && user.civilite.toString()
                })(
                    <Select>
                        {civilite.map(({libelleField, valueField}) => (
                            <Option
                                key={valueField}
                                value={valueField}
                            >
                            {libelleField}
                            </Option>
                        ))}
                    </Select>,
                )}
            </FormItem>
        {/* NOM  */}
            <FormItem
                {...formItemLayout}
                label={(
                    <FormattedMessage
                        id="contact.form.name"
                        defaultMessage="Lastname"
                    />
                )}
            >
                {getFieldDecorator(`${userPrefix}nom`, {
                    rules: [
                        {
                            required: true,
                            message: (<FormattedMessage
                                id="contact.form.nameRequired"
                                defaultMessage="Please enter your lastname"
                            />),
                        },
                        {  
                            min: 2, 
                            message: intl.formatMessage({id: 'minCaractere'},{min : 2})
                        },  
                        {
                            pattern: new RegExp(REGEX_NAME),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaracteresName"
                                defaultMessage="Characters are invalid."
                            />),
                        }
                    ],
                    initialValue: user.nom
                })(
                    <Input />
                )}
            </FormItem>
            {/* PRENOM  */}
            <FormItem            
                {...formItemLayout}
                label={(
                    <FormattedMessage
                        id="user.form.firstname"
                        defaultMessage="Firstname"
                    />
                )}
            >
                {getFieldDecorator(`${userPrefix}prenom`, {
                    rules: [
                        {
                            required: true,
                            message: (<FormattedMessage
                                id="user.form.firstnameRequired"
                                defaultMessage="Please enter your firstname"
                            />),
                        },
                        {   
                            min: 2,
                            message: intl.formatMessage({id: 'minCaractere'},{min : 2})
                        },  
                        {
                            pattern: new RegExp(REGEX_NAME),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaracteresName"
                                defaultMessage="Characters are invalid."
                            />)
                        }
                    ],
                    initialValue: user.prenom
                })(
                    <Input />
                )}
            </FormItem>
            {/* SERVICE  */}
            <FormItem
                {...formItemLayout}
                label={(
                    <FormattedMessage
                        id="user.form.service"
                        defaultMessage="Service"
                    />
                )}
            >
                {getFieldDecorator(`${userPrefix}Service`, {
                    rules: [{
                        required: true,
                        message: (
                            <FormattedMessage
                                id="user.form.serviceRequired"
                                defaultMessage="Please choose the user's service"
                            />
                        )
                    }],
                    initialValue: user.service && user.service.toString()
                })(
                    <Select>
                        {service.map(({libelleField, valueField}) => (
                            <Option
                                key={valueField}
                                value={valueField}
                            >
                            {libelleField}
                            </Option>
                        ))}
                    </Select>
                )}
            </FormItem>
            {/* FONCTION  */}
            <FormItem
                {...formItemLayout}
                label={(
                    <FormattedMessage
                        id="user.form.fonction"
                        defaultMessage="Function"
                    />
                )}
            >
                {getFieldDecorator(`${userPrefix}Fonction`, {               
                    initialValue: user.fonction && user.fonction.toString()
                })(
                    <Select>
                        {fonction.map(({libelleField, valueField}) => (
                            <Option
                                key={valueField}
                                value={valueField}
                            >
                               {libelleField}
                            </Option>
                        ))}
                    </Select>
                )}
            </FormItem>
            {/*  Type Interlocuteur  */}
            <FormItem
                {...formItemLayout}
               label={(
                    <FormattedMessage
                        id="user.form.typeInterlocuteur"
                        defaultMessage="Referent"
                    />
                )}
                style={{marginBottom: 0}}
            >
                {getFieldDecorator(`${userPrefix}TypeInterlocuteur`, {
                    initialValue: user.interlocuteurTypeCode      
                })(
                    <Fragment>
                    <Select>
                        {typeInterlocuteur.map(({libelleField, valueField}) => (
                            <Option
                                key={valueField}
                                value={valueField}
                            >
                                {libelleField}
                            </Option>
                        ))}
                    </Select>
                    <span style={{ fontSize: '12px' , marginBottom: 0}}> <FormattedMessage id="user.form.typeInterlocuteurInfo" defaultMessage="Characters are invalid."/></span>
                    </Fragment>
                )}
            </FormItem>
            {/*  EMAIL  */}
            <FormItem
                {...formItemLayout}
                label={( 
                    <FormattedMessage
                    id="user.form.email"
                    defaultMessage="Email"
                />
                )}
            >
                {getFieldDecorator(`${userPrefix}email`, {
                    validate: [{
                        trigger: 'onChange',
                        rules: [
                            {
                                required: true,
                                message: (<FormattedMessage
                                    id="user.form.emailRequired"
                                    defaultMessage="Please enter your e-mail"
                                />),
                            },
                            {
                                type: 'email',
                                message: (<FormattedMessage
                                    id="user.form.emailValid"
                                    defaultMessage="Please enter a valid e-mail"
                                />),
                            },
                            {
                                pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                message: (<FormattedMessage
                                    id="form.validator.invalidCaracteres"
                                    defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                />),
                            }
                        ]},
                        {
                            trigger: 'onBlur',
                            rules: [
                                {
                                    required: true,
                                    message: (<FormattedMessage
                                        id="user.form.emailRequired"
                                        defaultMessage="Please enter your e-mail"
                                    />),
                                },
                                {
                                    type: 'email',
                                    message: (<FormattedMessage
                                        id="user.form.emailValid"
                                        defaultMessage="Please enter a valid e-mail"
                                    />),
                                },
                                {
                                    pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                    message: (<FormattedMessage
                                        id="form.validator.invalidCaracteres"
                                        defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                    />),
                                },
                                {
                                    validator: this.checkEmailValidationUser
                                }
                            ]
                        }
                    ],
                    initialValue: user.email,
                })(
                    <Input />,
                )}
            </FormItem>
            {/*  PORTABLE  */}
            <FormItem
                {...formItemLayout}
                label={(
                    <FormattedMessage
                    id="user.form.mobilePhone"
                    defaultMessage="Cellphone"
                />
                )}
            >
                {getFieldDecorator(`${userPrefix}mobileNumber`, {
                    rules: [{
                        message: (<FormattedMessage
                            id="user.form.mobilePhoneRequired"
                            defaultMessage="Please enter your mobile phone number"
                        />),
                    }],
                    initialValue: user.mobileNumber,
                })(
                    <PhoneInput
                        onChange={ phone => {user.mobileNumber=phone;} }
                        international={true}
                        //country={'FR'}
                        displayInitialValueAsLocalNumber
                        limitMaxLength={true}
                    />,
                )}
            </FormItem>
             {/*  TELEPHONE  */}
            <FormItem
                {...formItemLayout}
                label={( <FormattedMessage
                    id="user.form.phoneNumber"
                    defaultMessage="Phone Number"
                    />
                )}
            >
                {getFieldDecorator(`${userPrefix}phoneNumber`, {
                    rules: [{
                        message: (<FormattedMessage
                            id="user.form.phoneNumberRequired"
                            defaultMessage="Please enter your phone number"
                        />),
                    }],
                    initialValue: user.phoneNumber,
                })(
                    <PhoneInput
                        onChange={ phone => {user.phoneNumber=phone;} }
                        international={true}
                        //country={'FR'}
                        displayInitialValueAsLocalNumber
                        limitMaxLength={true}
                    />,
                )}
            </FormItem>
            {/*  LANGUAGE  */}
            <FormItem
                {...formItemLayout}
                label={(
                    <FormattedMessage
                        id="client.form.language"
                        defaultMessage="Language"
                    />
                )}
            >
                {getFieldDecorator(`${userPrefix}Lang`, {
                    rules: [{
                        required: true,
                        message: (
                            <FormattedMessage
                                id="client.form.languageRequired"
                                defaultMessage="Please enter the language"
                            />
                        ),
                    }],
                    initialValue: (user.lang && user.lang.toLowerCase()) || locale,
                })(
                    <Select>
                        {Object.keys(locales).map((key) => (
                            <Option
                                key={key}
                                value={locales[key]}
                            >
                                <FormattedMessage
                                    id={`menu.lang.${key}`}
                                    defaultMessage={key}
                                />
                            </Option>
                        ))}
                    </Select>,
                )}
            </FormItem>
            <FormItem>
                <div className="alert-switches">
                    {getFieldDecorator(`${userPrefix}ShowPublicDevices`, {
                        rules: [],
                        initialValue: showPublicDevices,
                    })(
                        <Fragment>
                            {loading && (
                                <Switch
                                defaultChecked={showPublicDevices}
                                onChange={value => { this.setState({showPublicDevices: value}); }}
                                className="alert-switch"
                            />
                            )}
                            <FormattedMessage
                                id="user.form.showPublicDevices"
                                defaultMessage="Show demo machines in Winect"
                            />
                        </Fragment>
                    )}
                </div>
            </FormItem>
        </Fragment>;
    }
}

UserFormItems.defaultProps = {
    userPrefix: '',
    user: {},
};

UserFormItems.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    userPrefix: PropTypes.string,
    user: PropTypes.shape({}),
};

const mapStateToProps = ({ app: { locale }, signIn: { userID } }) => ({ locale, userID });

export default connect(mapStateToProps)(injectIntl(UserFormItems));
