import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Media } from 'react-breakpoints';

import DesktopLayout from './DesktopLayout';
import MobileLayout from './MobileLayout';

import { logout } from '../../actions/signIn';
import { setLocale } from '../../actions/app';

class PageLayout extends Component {
    constructor(props){
        super(props);
        this.state = {

        };
    }

    render () {
        const { children, currentLocale, flex, handleChangeLocale, handleLogout, loggedIn, pageKey, history, includeMargin } = this.props;

        return (
            <Media>
                {
                    ({ breakpoints, currentBreakpoint }) => {
                        let withPadding = false;
                        React.Children.forEach(children, (child) => {
                            if (child && child.type) {
                                withPadding = withPadding || !(child.type.displayName && child.type.displayName.includes('HtmlIncluder'));
                            }
                        });

                        if (breakpoints[currentBreakpoint] > breakpoints.tabletLandscape) {
                            return (
                                <Fragment>
                                    <DesktopLayout
                                        pageKey={pageKey}
                                        loggedIn={loggedIn}
                                        handleLogout={handleLogout}
                                        handleChangeLocale={handleChangeLocale}
                                        currentLocale={currentLocale}
                                        flex={flex}
                                        withPadding={withPadding}
                                        history={history}
                                        includeMargin={includeMargin}
                                    >
                                        {children}
                                    </DesktopLayout>
                                </Fragment>
                                
                            );
                        }

                        return (
                            <MobileLayout
                                pageKey={pageKey}
                                loggedIn={loggedIn}
                                handleLogout={handleLogout}
                                handleChangeLocale={handleChangeLocale}
                                currentLocale={currentLocale}
                                flex={flex}
                                withPadding={withPadding}
                            >
                                {children}
                            </MobileLayout>
                        );
                    }
                }
            </Media>
        );
    }
}

PageLayout.propTypes = {
    pageKey: PropTypes.string.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    children: PropTypes.node,
    handleLogout: PropTypes.func,
    handleChangeLocale: PropTypes.func,
    currentLocale: PropTypes.string,
    flex: PropTypes.bool,
    includeMargin: PropTypes.bool,
    history: PropTypes.shape({}),
};

const mapStateToProps = ({
    signIn: {
        loggedIn,
    },
    app: {
        locale: currentLocale,
    },
}) => ({ loggedIn, currentLocale });

export default connect(mapStateToProps, { handleLogout: logout, handleChangeLocale: setLocale })(PageLayout);
