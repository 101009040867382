import React, { useState, Fragment, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { intlShape, injectIntl } from 'react-intl';
import { Switch, notification } from 'antd';

import { setUserRole, getListRolesByUser } from '../../utils/apiBucherVaslin';

const UserRulesForm = ({idUser, intl}) => {
    const [roles, setRoles] = useState([]);
    const dispatch = useDispatch();
    const signIn = useSelector(state => state.signIn);

    useEffect(() => {
        getListRolesByUser(idUser).then(({ userInfo: userRoles, isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
            if (!isSuccess){
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail,
                });
            }
            setRoles(userRoles);
        });      
    }, []);

    const SwitchRoles = ({ role }) => { 
        return (
            <Fragment>
                <div>
                    <Switch
                        defaultChecked={role.havePermission} 
                        onChange={checked => handleRoleChange(checked, role.id)}
                        className="alert-switch"
                    />
                    {role.description}
                </div>
            </Fragment>
      )
    }
   
    const handleRoleChange = async(checked, id) => {
        const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await setUserRole(idUser, checked, id);
        if (!isSuccess) {    
            notification.error({
                message: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : errorDetail,
            });
        }
    };

    return (
        <div style={{display: 'grid', justifyContent: 'center'}}>
            {roles.map(role => (
                <SwitchRoles key={role.id} role={role}/>
            ))}
        </div>
            /* {isGroupAdminClient({userRole : signIn.userRole}) && signIn.userID !== idUser && (    )} */
    );
}

UserRulesForm.propTypes = {
    idUser: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
};

export default injectIntl(UserRulesForm);