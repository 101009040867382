import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form, Button, Divider, Modal, Popconfirm, Popover, Icon } from 'antd';
import { NavLink } from 'react-router-dom';

import RequiredFieldLegend from '../RequiredFieldLegend';
import UserFormItems from './FormItems';

import { tailFormItemLayout } from '../../utils/formItemLayout';

import './EditForm.less';

const { Item: FormItem } = Form;

class EditForm extends Component {
    handleSubmit = () => {
        const {
            form: { validateFieldsAndScroll },
            save,
        } = this.props;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                save(values);
            }
        });
    }

    render() {
        const { user, form, connectedUserID } = this.props;

        return (
            <Form>
                <UserFormItems form={form} user={user}/>
                <FormItem {...tailFormItemLayout}>
                    <RequiredFieldLegend />
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                    <Popover content={<FormattedMessage id="user.edit.button" />}>
                        <Popconfirm
                            title={<FormattedMessage id="user.edit.confirm" />}
                            onConfirm={() => this.handleSubmit()}
                            okText={<FormattedMessage id="common.yes" />}
                            cancelText={<FormattedMessage id="common.no" />}
                        >
                            <Button type="primary" htmlType="submit">
                                <FormattedMessage id="user.edit.button" defaultMessage="Update user" />
                            </Button>
                        </Popconfirm>
                    </Popover>
                </FormItem>
                <Divider />
                {/* Seul l'utilisateur peut modifier ses propres alertes (ne pas déranger fait 
                    uniquement pour l'user connecté côté api) */}
                {(connectedUserID === user.userID || user.userID == null) && (
                    <div className="alert-switches">
                    <Icon type="info-circle" style={{marginRight: "5px"}} />
                    <FormattedMessage id="notification.linkText" defaultMessage="The setting of alerts is now done" />
                    <NavLink to="/alert" className="edit-link" style={{marginLeft: "5px"}}>
                        <FormattedMessage id="notification.linkText.here" defaultMessage="here" />
                    </NavLink>
                    </div>
                )}
            </Form>
        );
    }
}

EditForm.propTypes = {
    userRole: PropTypes.array.isRequired,
    connectedUserID: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    user: PropTypes.shape({}),
    save: PropTypes.func.isRequired,
};

const mapStateToProps = ({ signIn: { userRole, userID } }) => ({
    userRole,
    connectedUserID: userID,
});

export default injectIntl(Form.create()(connect(mapStateToProps)(EditForm)));
