import React from 'react';

import PageLayout from './Layout/PageLayout';
import HtmlIncluder from './HtmlIncluder';

const ServicesCatalogue = () => (
    <PageLayout pageKey="servicesCatalogue" flex>
        <HtmlIncluder fileName="servicesCatalogue" />
    </PageLayout>
);

export default ServicesCatalogue;
