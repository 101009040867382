import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Icon, Button, Modal, notification, Form, Input, Popover } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import { updateDevice } from '../utils/apiBucherVaslin';
import RequiredFieldLegend from './RequiredFieldLegend';

const { Item: FormItem } = Form;

class ChangeName extends Component {
    state = {
        ModalText: <FormattedMessage
            id="table.devices.deviceNewName"
            defaultMessage="Enter machine's new name"
        />,
        visible: false,
        confirmLoading: false,
    };

    showModal = () => {
        this.setState({
            visible: true,
            ModalText: <FormattedMessage
                id="table.devices.deviceNewName"
                defaultMessage="Enter machine's new name"
            />,
        });
    }

    handleOk = (_, value, callback) => {
        const { form, refresh, name, intl, ...props } = this.props;
        this.setState({
            confirmLoading: true,
        });
        updateDevice({
            ...props,
            name: form.getFieldValue('newName'),
        })
            .then(({ isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
                if (isSuccess) {
                    this.setState({
                        ModalText: <FormattedMessage
                            id="table.devices.deviceUpdateName"
                            defaultMessage="Machine's name successfully updated"
                        />,
                        confirmLoading: false,
                        visible: false,
                    });
                    refresh();
                }
                else {
                    this.setState({
                        ModalText: errorDetail,
                        confirmLoading: false,
                    });
                    notification.error({
                        message: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorDetail,
                    });
                }
            });
    }

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    }

    render() {
        const { visible, confirmLoading, ModalText } = this.state;
        const {
            form: { getFieldDecorator, getFieldsValue },
        } = this.props;

        return (
            <span>
                <Popover content={<FormattedMessage id="table.devices.editDeviceName" />}>
                    <Button onClick={this.showModal}>
                        <Icon type="form" />
                    </Button>
                </Popover>
                <Modal
                    title={<FormattedMessage id="table.devices.deviceModifyName" />}
                    visible={visible}
                    onOk={this.handleOk}
                    confirmLoading={confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <Form>
                        <FormItem
                            label={ModalText}
                        >
                            {getFieldDecorator('newName', {
                                rules: [{
                                    required: true,
                                    message: <FormattedMessage id="table.devices.deviceChooseName" />,
                                }],
                            })(
                                <Input
                                    type="text"
                                    onPressEnter={this.handleOk}
                                />,
                            )}
                        </FormItem>
                        <RequiredFieldLegend />
                    </Form>
                </Modal>
            </span>
        );
    }
}

ChangeName.propTypes = {
    form: PropTypes.shape({
        getFieldsValue: PropTypes.func,
        getFieldDecorator: PropTypes.func,
    }),
    refresh: PropTypes.func,
    name: PropTypes.string,
    intl: intlShape.isRequired,
};

export default injectIntl(Form.create()(ChangeName));
