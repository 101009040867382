import React, { Component } from 'react';
import PageLayout from '../Layout/PageLayout';
import FAQContent from './FAQContent';
import './FAQ.less';

class FAQ extends Component {
    constructor(props){
        super(props);
        this.state = {};
    }

    render() {
        return(
            <PageLayout pageKey="faq" flex>
                <FAQContent fromPortal={true}/>
            </PageLayout>
        )
    } 
}

export default FAQ;
