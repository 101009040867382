import { fetchCountries } from '../utils/apiBucherVaslin';

export const PARAM_SET = 'PARAM_SET';

export const loadCountries = () => async (dispatch) => {
    const data = await fetchCountries();

    dispatch({
        type: PARAM_SET,
        param: 'countries',
        data,
    });
};
