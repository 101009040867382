import React, { Component } from 'react';
import PageLayout from '../Layout/PageLayout';
import { fetchFlashInfos, readFlashInfos, unreadFlashInfos } from '../../utils/apiBucherVaslin';
import { isInfoCompleted } from '../../utils/helpers';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import moment from 'moment';
import { Icon, notification, Popover, Spin  } from 'antd';
import './FlashInfo.less';

const renderDate = (date) => (date ? <span>{moment(date).format('DD/MM/YY')}</span> : '-');

class FlashInfo extends Component {
    constructor(props){
        super(props);
        this.state = {
            flashInfos: [],
            loading: true,
        };
    }

    async componentDidMount() {
        const { userID, intl} = this.props;
        /* check connexion and information */ 
        await isInfoCompleted(intl, userID);
        this.loadFlashInfos();
    }

    // Method used to load flash infos list list
    loadFlashInfos = async() => {
        const { intl, locale } = this.props;
        
        this.setState({ loading:true })
        const { result, isSuccess, isUnauthorized, errorMessage, errorDetail} = await fetchFlashInfos(locale);
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            this.setState({ loading: false });
            return;
        }

        // Update State values
        this.setState({ flashInfos: result, loading: false });
    }

    // Method used to set a flash info to "read"
    markAsRead = async (flash) => {
        const { flashInfos } = this.state;
        const { intl } = this.props;
        const { result, isSuccess, isUnauthorized, errorMessage, errorDetail} = await readFlashInfos(flash.salesLiteratureId);
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            this.setState({ loading: false });
            return;
        }
     const infoIndex = flashInfos.findIndex((info) => info.salesLiteratureId === flash.salesLiteratureId);
        flashInfos[infoIndex].isRead = true;
        this.setState({flashInfos: flashInfos});
    }

        // Method used to set a flash info to "read"
        markAsUnread = async (flash) => {
            const { flashInfos } = this.state;
            const { intl } = this.props;
            const { result, isSuccess, isUnauthorized, errorMessage, errorDetail} = await unreadFlashInfos(flash.salesLiteratureId);
            if (!isSuccess) {
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail
                });
                this.setState({ loading: false });
                return;
            }
         const infoIndex = flashInfos.findIndex((info) => info.salesLiteratureId === flash.salesLiteratureId);
            flashInfos[infoIndex].isRead = false;
            this.setState({flashInfos: flashInfos});
        }

    render() {
        const { flashInfos, loading } = this.state;
        const { history, intl } = this.props;

        return(
            <PageLayout pageKey="flashInfo" history={history} withPadding={true}>
                <h2>
                    {intl.formatMessage({ id: 'flashInfo.title' })}
                </h2>

                {/* Loader */}
                {loading ? (
                    <Spin size="large" />
                ) : (
                    <div className="flash-info-container">
                        {/* Loop throught flash infos */}
                        { flashInfos.map((info, indexInfos) => (
                            <div className={(info.isRead) ? "flash-info flash-info-read" : "flash-info flash-info-unread"} key={indexInfos}>
                                <div className="flash-info-title">
                                    {/* New Tag */}
                                    {(!info.isRead) && (
                                        <div className="flash-info-title-new">New</div>
                                    )}
                                    
                                    {/* Title value */}
                                    <div className="flash-info-title-value">
                                        {info.title}
                                    </div>

                                    {/* Mark as read */}
                                    {(!info.isRead) && (
                                        <Popover content={<FormattedMessage id="flashInfo.marskAsRead" />}>
                                            <Icon type="eye" className="read" onClick={() => this.markAsRead(info)} />
                                        </Popover>
                                    )}
                                    {/* Mark as unread */}
                                    {(info.isRead) && (
                                        <Popover content={<FormattedMessage id="flashInfo.marskAsUnread" />}>
                                            <Icon type="eye-invisible" className="unread" onClick={() => this.markAsUnread(info)} />
                                        </Popover>
                                    )}
                                </div>
                                <div className="flash-info-description">
                                    <div className="release-date">Paru le : {renderDate(info.dateParution)}</div>
                                    {info.description}
                                </div>
                            </div>
                        ))}

                    </div>
                )}
            </PageLayout>
        )
    } 
}

FlashInfo.propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string
}

const mapStateToProps = ({
    app: {
        locale,
    },
    signIn: {
        userID
    }
}) => ({ locale, userID });

export default connect(mapStateToProps)(injectIntl(FlashInfo));
