import _ from 'lodash';
import { flatten } from './flatten';
import { compareNumber, alphabetically } from './helpers';

// Filtre un tableau d'objets en fonction d'un tableau de valeurs à filtrer
export function multiPropsFilter(products, filters) {
    const filterKeys = Object.keys(filters);
    return products.filter(product => {
        var productFlat = flatten({ product });
        return filterKeys.every(key => {
            let data;
            if (!filters[key].length) return true;
            if (!_.isObject(filters[key][0]) && filters[key][0] === true){
                data = "1";
            }
            else if (!_.isObject(filters[key][0]) && filters[key][0] === false){
                data = "0";
            }
            else {
                data = filters[key][0].toLowerCase();
            }
            return productFlat['product.'+key].toLowerCase().includes(data);
        });
    });
};

// Tri un tableau d'objets en fonction de la colonne passée en paramètre et de l'ordre
export function sorterArrayObject(products, columnName, orderIsAsc) {
    // Nécessaire sinon l'objet en state sera modifié en même temps
    const productsTemp = Object.assign([], products);
    const productsSorted = productsTemp && productsTemp.sort((a, b) => {
        var productFlatA = flatten({ a });
        var productFlatB = flatten({ b });

        let dataA = productFlatA['a.'+columnName];
        let dataB = productFlatB['b.'+columnName];

        if (_.isNumber(dataA) && _.isNumber(dataB)){
            return orderIsAsc ? compareNumber(dataA, dataB) : compareNumber(dataB, dataA);
        }
        else if (_.isBoolean(dataA) && _.isBoolean(dataB)){
            return orderIsAsc ? compareNumber(dataB, dataA) : compareNumber(dataA, dataB);
        }

        return orderIsAsc ? alphabetically(dataA, dataB) : alphabetically(dataB, dataA);
    });

    return productsSorted;
}