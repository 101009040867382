import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { Checkbox } from 'antd';
import { getDatasetColor } from '../../../utils/graphs';
import { Line } from 'react-chartjs-2';
import './LinearGraph.less';

class LinearGraph extends Component {
    constructor(props){
        super(props);
        this.state = {
            metrics: [],
            groups: [],
            hideGraph: true,
            data: {
                datasets: []
            },
            options: {}
        };
    }


    async componentDidMount() {
        let valMetrics = [];

        // Format all metrics properties
        const formatedMetrics = this.formatMetrics(true);
        valMetrics = valMetrics.concat(formatedMetrics);

        // Update state
        this.updateState(valMetrics);
    }

    async componentDidUpdate() {
        const { metrics } = this.state;
        const { axisGroup } = this.props;
        let metricList = [];

        // Configure groups
        const groupList = this.formatGroups();
        metricList = metricList.concat(groupList);

        // Update State metrics
        const formMetrics = this.formatMetrics();
        metricList = metricList.concat(formMetrics);

        // Prevent infinit loop on component thanks to setState method
        const firstMetric = metrics.findIndex(met => met.datas && met.datas.length > 0);
        const firstMetricList = metricList.findIndex(met => met.datas && met.datas.length > 0);

        if (metrics.length > 0 && metricList.length > 0) {
            // Check that metric length remains the same
            if (metrics.length === metricList.length) {
                if (metrics[firstMetric].datas.length !== metricList[firstMetricList].datas.length) {
                    // Handle filter change on datas
                    this.updateState(metricList, axisGroup);
                    return;
                } else {
                    metrics.forEach((metricVal, metricIndex) => {
                        if (metricVal.label !== metricList[metricIndex].label) {
                            // Handle group rename
                            this.updateState(metricList, axisGroup);
                            return;
                        } else if (metricVal.axis && metricList[metricIndex].axis) {
                            const listAxis = metricList[metricIndex].axis;

                            if (metricVal.axis.length !== listAxis.length) {
                                // Handle groups axis length change
                                this.updateState(metricList, axisGroup);
                            } else {
                                metricVal.axis.forEach((axe, axeIndex) => {
                                    if(!listAxis[axeIndex] || listAxis[axeIndex].label !== axe.label) {
                                        // Handle groups axis change
                                        this.updateState(metricList, axisGroup);
                                    }
                                });
                            }
                        }
                    });
                }
            } else {
                // Handle group creation / delete
                this.updateState(metricList, axisGroup);
                return;
            }
        }
    }

    /**
     * Method used to update state after using didMount or didUpdate method
     */
    updateState = async(metricList, axisGroup) => {
        // Update metric
        await this.setState({ groups: axisGroup, metrics: metricList });

        // Update axis options
        await this.updateOptions();

        // Update graphDataset
        await this.updateGraphDataset();
    }

    /**
     * Method called in componentDidUpdate for formating group
     */
    formatGroups = () => {
        // Get checked metric list
        const { axisGroup, metric } = this.props;
        const { metrics } = this.state;
        const groups = [];

        if (axisGroup && axisGroup.length > 0) {
            axisGroup.forEach((group) => {
                // Get the existing occurence of group
                const copyGroup = metrics.find((met) => met.label === group.name);

                // Create a group
                const formatedGroup = {
                    axis: [],
                    checked: (copyGroup && copyGroup.checked) ? true: false,
                    label: group.name,
                };

                // Add group axis
                group.axis.forEach((axe) => {
                    // Get checked value (default false)
                    let isCheckedMetric = false
                    if (copyGroup) {
                        const currentAxis = copyGroup.axis.find((elem) => elem.label === axe);
                        isCheckedMetric = (currentAxis && currentAxis.checked) ? true : false;
                    }

                    const formatedMetric = {
                        label: axe,
                        datas: metric[axe],
                        checked: isCheckedMetric
                    };

                    // If metric was checked, we check the group too
                    if (formatedMetric.checked) {
                        formatedGroup.checked = true;
                    }

                    // Add metric to the group
                    formatedGroup.axis.push(formatedMetric);
                });

                // Push group to the list of groups
                groups.push(formatedGroup);
            });
        }

        return groups;
    }

    formatMetrics = (isInit = false) => {
        // Get checked metric list
        let metrics = JSON.parse(JSON.stringify(this.state.metrics));
        const { excludedMetrics, metric } = this.props;
        const checkedMetrics = metrics.filter((met) => met.checked);
        const updatedMetrics = []

        for(var property in metric) {
            if (metric[property].length > 0) {
                // Add only X axis metrics (and ignore Y axis metrics)
                if (!excludedMetrics.includes(property)) {
                    // Check if property is currently checked (to check it back)
                    const isCheckedMetric = checkedMetrics.find((met) => met.label === property);
                    const formatedMetric = {
                        label: property,
                        datas: metric[property],
                        checked: false
                    };

                    // Manage metric checked value
                    if (isInit && updatedMetrics.length < 2) {
                        formatedMetric.checked = true;
                    } else if (!isInit && (isCheckedMetric || isCheckedMetric === 0)) {
                        formatedMetric.checked = true;
                    }

                    // Push formated metric
                    updatedMetrics.push(formatedMetric)
                }
            }
        }
        return updatedMetrics;
    }

    /**
     * Method called when axes is checked
     */
    handleChecked = async (event, axesIndex, subMetricIndex = null) => {
        const { metrics } = this.state;

        if (subMetricIndex || subMetricIndex === 0) {
            // Manage checked for an axe who bellongs to a group
            metrics[axesIndex].axis[subMetricIndex].checked = event.target.checked;

            // Manage group checked value
            let groupChecked = false;
            metrics[axesIndex].axis.forEach((axis) => {
                if (axis.checked) {
                    groupChecked = true;
                }
            });

            // Edit group checked value to true if atleast one metric is checked
            metrics[axesIndex].checked = groupChecked;
        } else {
            // Manage checked for an axe who don't bellongs to a group
            metrics[axesIndex].checked = event.target.checked;
        }

        // Update state
        await this.setState({ metrics });

        // Update graph dataset
        await this.updateGraphDataset();
    }

    /**
     * Method used to update graphDataset
     */
    updateGraphDataset = async () => {
        const { data, options } = this.state;
        let metrics = JSON.parse(JSON.stringify(this.state.metrics));

        // Hide graph + reset dataset values (to allow us to recalculate point positions)
        await this.setState({data: { datasets: [] }, hideGraph: true});

        // Reset axis options
        await this.updateOptions();

        // Init dataset
        let axisIndex = 0;
        let colorIndex = 0;
        const datasets = [];
        const yAxes = [];

        // Configure datasets and vertical axes
        metrics.forEach((metric, metricIndex) => {
            // Check if metric is refering to a group
            if (metric.axis && metric.axis.length > 0) {
                // Check if group needs to be displayed
                if (metric.checked) {
                    // Create a boolean not to add multiple time the same option
                    let isOptionCreated = false;

                    // Loop throught group axis to configurate visible ones
                    metric.axis.forEach((subAxis, subAxisIndex) => {
                        if (subAxis.checked) {
                            // Get axe configuration
                            const config = this.configurateVisibleAxes(subAxis, axisIndex, colorIndex, !isOptionCreated, metrics[metricIndex]);
            
                            // Update metric
                            metrics[metricIndex].axis[subAxisIndex] = config.metric;
            
                            // Update datasets
                            datasets.push(config.dataset);
            
                            // Udapte options if it haven't been added yet
                            if (!isOptionCreated) {
                                isOptionCreated = true;
                                yAxes.push(config.option);
                            }
                        } else {
                            // Reset metric color
                            metrics[metricIndex].axis[subAxisIndex].color = {r: 115, g:115, b:115, a:1};
                        }

                        // Increment color index
                        colorIndex ++;
                    });

                    // Increment axis
                    axisIndex ++;
                } else {
                    // If group isn't checked, reset label color + increase colorIndex
                    // Loop throught group axis to configurate visible ones
                    metric.axis.forEach((subAxis, subAxisIndex) => {
                        metrics[metricIndex].axis[subAxisIndex].color = {r: 115, g:115, b:115, a:1};
                        colorIndex ++;
                    });
                }
            } else {
                // If metric isn't refering to a group
                if (metric.checked) {
                    // Get axe configuration
                    const config = this.configurateVisibleAxes(metric, axisIndex, colorIndex, true);
    
                    // Update metric
                    metrics[metricIndex] = config.metric;
    
                    // Update datasets
                    datasets.push(config.dataset);
    
                    // Udapte options
                    yAxes.push(config.option);
    
                    // Increment axis index
                    axisIndex ++;
                } else {
                    // Reset metric color
                    metrics[metricIndex].color = {r: 115, g:115, b:115, a:1};
                }

                // Increment color index
                colorIndex ++;
            }
        });

        // Updates state param copy
        data.datasets = datasets;
        options.scales.yAxes = yAxes;

        // Update state
        await this.setState({data, hideGraph: false, metrics, options});
    }


    /**
     * 
     * @param {object} metric Metric value
     * @param {number} axisIndex index of visible index used for axis reference
     * @param {number} colorIndex index of color to be used
     * @param {boolean} manageOption Boolean used to determine if we need to manage option config
     * @param {object} group Group on the one the axis bellong
     */
    configurateVisibleAxes = (metric, axisIndex, colorIndex, manageOption = true, group = null) => {
        const { alignAxis, stairMode } = this.props;
        
        //  Get metric color
        const metricColor = getDatasetColor(colorIndex);
        const rgbaColor = `rgba(${metricColor.r}, ${metricColor.g}, ${metricColor.b}, 1)`;
        const defaultColor = `rgba(115, 115, 115, 1)`;
        metric.color = metricColor;

        // Manage metric dataset
        const dataset = {
            label: metric.label,
            type:'line',
            data: metric.datas,
            fill: false,
            backgroundColor: `rgba(${metricColor.r}, ${metricColor.g}, ${metricColor.b}, ${metricColor.a})`,
            borderColor: rgbaColor,
            pointBackgroundColor: rgbaColor,
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: rgbaColor,
            steppedLine: stairMode,
            yAxisID: `y-axis-${axisIndex + 1}`
        };

        // Manage metric options
        let option = {};
        if (manageOption) {
            option = {
                type: 'linear',
                display: true,
                position: 'right',
                id: `y-axis-${axisIndex + 1}`,
                gridLines: {
                    display: true,
                    drawTicks: true,
                    drawOnChartArea: false,
                    color: (group) ? defaultColor : rgbaColor
                },
                labels: {
                    show: true
                },
                scaleLabel: {
                    display: true,
                    labelString: (group && group.label) ? group.label : metric.label,
                    fontColor: (group) ? defaultColor : rgbaColor
                },
                ticks: {
                    fontColor: (group) ? defaultColor : rgbaColor,
                }
            };

            // Edit axis label if options refer to a group axis
            if (group) {
                // Initiate groupe label
                let groupLabel = [];

                // Loop throught group metrics
                group.axis.forEach((subMetric) => {
                    groupLabel.push(subMetric.label);
                });

                // Cut string, if there are more than 4 axis in group
                if (groupLabel.length > 4) {
                    groupLabel.splice(4, groupLabel.length - 4);
                    groupLabel.push("...");
                }

                // Update option value
                option.scaleLabel.labelString += " (" + groupLabel.join(', ') +")";
            }
        }

        // If alignAxis option is checked
        if (alignAxis && manageOption) {
            if (group) {
                // If align axis has to be calculated for a group
                // Init min/max
                let min = Math.min.apply(Math, group.axis[0].datas);
                let max = Math.max.apply(Math, group.axis[0].datas);

                // Loop throught sub metrics to get real min/max of the group
                group.axis.forEach((subMetric) => {
                    // Get sub metric min/max
                    const subMin = Math.min.apply(Math, subMetric.datas);
                    const subMax = Math.max.apply(Math, subMetric.datas);

                    // Update group min/max if needed
                    min = (subMin < min) ? subMin : min;
                    max = (subMax > max) ? subMax : max;
                });

                // Calculate absolute value
                const absolute = (Math.abs(min) > Math.abs(max)) ? Math.abs(min) : Math.abs(max);

                // Apply mix/max to ticks
                option.ticks.suggestedMax = absolute;
                option.ticks.suggestedMin = - absolute;
            } else {
                // If align axis has to be calculated for simple axis
                // Calculate min/max value
                const min = Math.min.apply(Math, metric.datas);
                const max = Math.max.apply(Math, metric.datas);
                const absolute = (Math.abs(min) > Math.abs(max)) ? Math.abs(min) : Math.abs(max);
                
                // Apply mix/max to ticks
                option.ticks.suggestedMax = absolute;
                option.ticks.suggestedMin = - absolute;
            }
        }

        return {
            dataset,
            metric,
            option,
        }
       
    }

    /**
     * Method used to update axis options after triggering filter
     */
    updateOptions = async() => {
        const { xAxis } = this.props;
        const timestampValues = this.props.metric.timestamp

        const options = {
            responsive: true,
            tooltips: {
                mode: 'index',
                axis: 'x',
                callbacks: {
                    title: function(tooltipItem) {
                        const date = new Date(timestampValues[tooltipItem[0].index]).toLocaleString();
                        return date;
                    },
                }
            },
            labels: this.props.metric.heure,
            elements: {
                line: {
                    fill: false
                }
            },
            scales: {
                xAxes: [
                    {
                        type: 'time',
                        time: {
                            unit: 'minute',
                            displayFormats: {
                                'minute': 'HH:mm:ss'
                            },
                            tooltipFormat: 'HH:mm:ss',
                        },
                        display: true,
                        gridLines: {
                            display: true,
                            drawTicks: true,
                            drawOnChartArea: false
                        },
                        labels: this.props.metric[xAxis],
                        scaleLabel: {
                            display: true,
                            labelString: "Date"
                        },
                        ticks : {
                            maxTicksLimit: 11
                        }
                    }
                ],
                yAxes: []
            }
        };

        this.setState({options});
    }


    render() {
        const { data, hideGraph, options, metrics } = this.state;

        return(
            <div className="preview-graph-container">
                {/* Graph filter */}
                <div className="preview-graph-selection">
                    { metrics && metrics.length > 0 && (
                        <ul>
                            {metrics.map(( axes, axesIndex ) => (
                                <li key={"axes_" + axesIndex}>
                                    {/* If current axe element is a group */}
                                    <Fragment>
                                        <Checkbox
                                            checked={axes.checked}
                                            onChange={(e) => { this.handleChecked(e, axesIndex) }}
                                        >
                                            { axes.color ? (
                                                <span style={{color: `rgba(${axes.color.r}, ${axes.color.g}, ${axes.color.b}, 1)`}}>
                                                    { axes.label }
                                                </span>
                                            ) : (
                                                <span>
                                                    { axes.label }
                                                </span>
                                            )}
                                        </Checkbox>

                                        { axes.axis && axes.axis.length > 0 && (
                                            <ul className="preview-graph-selection-sub-axis-list">
                                                {axes.axis.map(( subAxis, subAxisIndex ) => (
                                                    <li key={"axes_" + axesIndex + "_" + subAxisIndex}>
                                                        <Fragment>
                                                            <Checkbox
                                                                checked={subAxis.checked}
                                                                onChange={(e) => { this.handleChecked(e, axesIndex, subAxisIndex) }}
                                                            >
                                                                { subAxis.color ? (
                                                                    <span style={{color: `rgba(${subAxis.color.r}, ${subAxis.color.g}, ${subAxis.color.b}, 1)`}}>
                                                                        { subAxis.label }
                                                                    </span>
                                                                ) : (
                                                                    <span>
                                                                        { subAxis.label }
                                                                    </span>
                                                                )}
                                                            </Checkbox>
                                                        </Fragment>
                                                    </li>
                                                ))}
                                            </ul>
                                        )}
                                    </Fragment>
                                </li>
                            ))}
                        </ul>
                    ) }
                </div>

                {/* Graph display */}
                <div className="preview-graph-content">
                    {!hideGraph && (
                        <Line
                            data={data}
                            options={options}
                            height={100}
                        />
                    )}
                </div>
            </div>
        )
    } 
}

LinearGraph.propTypes = {
    alignAxis: PropTypes.bool.isRequired,
    axisGroup: PropTypes.array,
    excludedMetrics: PropTypes.array.isRequired,
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    metric: PropTypes.object.isRequired,
    stairMode: PropTypes.bool.isRequired,
    xAxis: PropTypes.string.isRequired,
}

const mapStateToProps = ({
    app: {
        locale,
    }
}) => ({ locale });

export default connect(mapStateToProps)(injectIntl(LinearGraph));
