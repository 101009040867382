import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import PageLayout from '../Layout/PageLayout';
import { Badge, Button, Descriptions, Divider, Icon, Input, notification, Spin, Table, Tabs, Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { fetchAllDevices,
    fetchConnectedDevices,
    fetchLicensesForDevice,
    fetchUsers,
    getUsersForConcessionnaireClient
} from '../../utils/apiBucherVaslin';
import { alphabetically, getDateGmt, isSuperAdmin, isGroupDev, isGroupAdminClient } from '../../utils/helpers';
import DeleteLicense from '../Licenses/DeleteLicense';
import UpdateLicense from '../Licenses/UpdateLicense';
import ModalGenerate from '../Licenses/ModalGenerate';
import SwapLicenseOwnership from '../Licenses/SwapLicenseOwnership';
import moment from 'moment';

const { TabPane } = Tabs;
const tableName = 'lisensesDevice';
const mongoDateFormat = 'YYYYMMDD HH:mm:ss';

const renderDate = (date) => (date ? (
    <span>{moment(date, mongoDateFormat).format('DD/MM/YY HH:mm:ss')}</span>
) : (
    '-'
));

class Infos extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadDeviceData: false,
            loadLicenceData: false,
            serial: null,
            nameDevice: null,
            active: false,
            historySaveEnabled: false,
            serialMachine: null,
            type: null,
            id: 0,
            lisenses: [],
            dateLastDataFeedBack: null,
            dateLastDataFeedBackGmt: null,
            dateWarrantyActivation: null,
            allMachines: null,
            allUsers: null,
            concessionaireUsers: null,
            myMachines: null,
            myUsers: null,
        };
    }

    async componentDidMount(){
        const {
            match: {
                params: { serial },
            },
            location: {
                state: { active, dateLastDataFeedBack, deviceGuaranteeValidate, historySaveEnabled, id, nameDevice, serialMachine, type },
            },
        } = this.props;

        // Init datas to the state the displayed device had when user clicked on "info" button 
        // (not to display blank tab if loadDeviceFailed)
        this.setState({ serial, nameDevice, active, historySaveEnabled, serialMachine, type, id, 
            dateLastDataFeedBack: dateLastDataFeedBack !== undefined ? dateLastDataFeedBack : null 
        });

        // On transforme la date de dernière remontée de données en date Gmt
        const dateLastDataFeedBackGmt = getDateGmt(dateLastDataFeedBack);
        this.setState({ 
            dateLastDataFeedBackGmt: dateLastDataFeedBackGmt !== undefined ? dateLastDataFeedBackGmt : null 
        });

        // On transforme la date d'activation de garantie
        if (deviceGuaranteeValidate && deviceGuaranteeValidate.validateGuarantee 
            && new Date(deviceGuaranteeValidate.validateGuarantee).getTime() > 0 
        ) {
            const dateWarrantyGmt = getDateGmt(deviceGuaranteeValidate.validateGuarantee);
            this.setState({ dateWarrantyActivation: dateWarrantyGmt !== undefined ? dateWarrantyGmt : null });
        }

        // Load device values (for Device and State tabs)
        await this.loadDeviceInfo();

        // Load licence values (for licence tab)
        this.fetchDataAsync();
    }

    // Méthode en charge de gérer le chargement des données
    fetchDataAsync = async () => {
        const { userRole } = this.props;
        this.setState({ loadLicenceData: false });
        
        // Charge les informations des licences 
        await this.getLicenceValues();

        // Si l'utilisateur est admin, charge les informations nécessaires aux actions sur les licences
        if (isGroupAdminClient({ userRole })) {
            await this.initSwapLicenseOwnershipModal();
        } else {
            // Stop le chargement des données et modifie le state
            this.setState({ loadLicenceData: true });
        }
    }

    callbackTab(key) {
    
    }

    // Method used to load data for Device and State tab
    loadDeviceInfo = async() => {
        // Start data loading
        this.setState({ loadDeviceData: false });

        // Get device datas
        await this.getDeviceEureka();

        // Stop data loading
        this.setState({ loadDeviceData: true });
    }

    // Récupère la liste des licences
    getLicenceValues = async () => {
        const { id } = this.state;
        const { items: lisenses, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchLicensesForDevice(id)();
        
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
        // On calcule la date d'expiration
        const dataSource = lisenses && lisenses.map((license) => {
            const date = moment(license.dateCreation);

            const delta = parseInt(license.duree, 10);

            if (1 === Math.sign(delta)) {
                date.add(Math.abs(delta), 'month');
            } else {
                date.subtract(Math.abs(delta), 'month');
            }

            return { ...license, expirationDate: date.format('DD/MM/YYYY') };
        });
        this.setState({ lisenses: dataSource });
    }

    // Method used to get the list of connected devices
    // Return an array of devices
    getDeviceEureka = async () => {
        const { items: myMachines, isSuccess: isSuccessConnectDevice
            , isUnauthorized: isUnauthorizedConnectDevice
            , errorDetail: errorMessageConnectDevice } = await fetchConnectedDevices()();

        if (!isSuccessConnectDevice){
            notification.error({
                message: isUnauthorizedConnectDevice 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedConnectDevice 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageConnectDevice,
            });
        }

        // Update state
        this.setState({ myMachines: myMachines });

        // Update Device / State tab
        await this.updateDeviceData(myMachines);

        return myMachines;
    }

    // Method used to refresh displayed device value
    async updateDeviceData(myMachines) {    

        // On récupère puis transforme la date de dernière remontée de données en date Gmt
        const { id } = this.state;
        const displayedDevice = myMachines.find((device) => device.id === id);
        
        if (displayedDevice) {
            let dateWarrantyGmt = null;
            const dateLastDataFeedBackGmt = getDateGmt(displayedDevice.dateLastDataFeedBack);

            // Get Warranty activation date
            if (displayedDevice.deviceGuaranteeValidate && displayedDevice.deviceGuaranteeValidate.validateGuarantee 
                && new Date(displayedDevice.deviceGuaranteeValidate.validateGuarantee).getTime() > 0 
            ) {
                dateWarrantyGmt = getDateGmt(displayedDevice.deviceGuaranteeValidate.validateGuarantee);
            }

            await this.setState({ 
                active: displayedDevice.active,
                dateLastDataFeedBackGmt: (dateLastDataFeedBackGmt !== undefined) ? dateLastDataFeedBackGmt : null,
                dateWarrantyActivation: dateWarrantyGmt,
                historySaveEnabled: displayedDevice.historySaveEnabled,
                nameDevice: displayedDevice.name,
                serial: displayedDevice.serial,
                serialMachine: displayedDevice.serialMachine,
            });
        }
    }

    // Method used to get all devices data
    // Return an array of devices
    getAllDeviceList = async () => {
        const { items: allMachines, isSuccess: isSuccessAllDevice
            , isUnauthorized: isUnauthorizedAllDevice
            , errorDetail: errorMessageAllDevice } = await fetchAllDevices();

        if (!isSuccessAllDevice){
            notification.error({
                message: isUnauthorizedAllDevice 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedAllDevice 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageAllDevice,
            });
        }

        return {allMachines, isSuccessAllDevice};
    }

    // Method used to get user list
    // Return an array of user
    getUsers = async (clientID = null) => {
        let users, isSuccessUsers, isUnauthorizedUsers, errorMessageUsers;

        // Get user using clientID if defined
        if (clientID) {            
            const response = await fetchUsers({}, clientID);
            users = response.items;
            isSuccessUsers = response.isSuccess;
            isUnauthorizedUsers = response.isUnauthorized;
            errorMessageUsers = response.errorDetail;
        } else {
            const response = await fetchUsers();
            users = response.items;
            isSuccessUsers = response.isSuccess;
            isUnauthorizedUsers = response.isUnauthorized;
            errorMessageUsers = response.errorDetail;
        }

        // If API call fail
        if (!isSuccessUsers){
            notification.error({
                message: isUnauthorizedMyUsers 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedMyUsers 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageMyUsers,
            });
        }
        
        return {users, isSuccessUsers};
    }

    // Method used to get dealer's user
    // Return an array of user
    getDealerUsers = async () => {
        const { items: concessionaireUsers, isSuccess: isSuccessUserConcCli
            , isUnauthorized: isUnauthorizedUserConcCli, errorDetail: errorMessageUserConcCli }
        = await getUsersForConcessionnaireClient();

        if (!isSuccessUserConcCli){
            notification.error({
                message: isUnauthorizedUserConcCli 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedUserConcCli 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageUserConcCli,
            });
            return;
        }

        this.setState({ concessionaireUsers: concessionaireUsers });
        return concessionaireUsers;
    }

    // Method used to trigger data loading for licences actions
    // Called when user role is Admin
    initSwapLicenseOwnershipModal = async () => {
        const { clientID, intl } = this.props;

        // Start loading
        this.setState({ loadLicenceData: false });

        // Récupère la liste de toute les machines
        const { allMachines, isSuccessAllDevice } = await this.getAllDeviceList();

        // Order all devices list
        const orderAllMachines = isSuccessAllDevice && allMachines ? allMachines.sort(
            ({ client: { nomClient: nomClientA } }, { client: { nomClient: nomClientB } }) => {
                if (nomClientA < nomClientB) {
                    return -1;
                }

                if (nomClientA > nomClientB) {
                    return 1;
                }

                return 0;
            },
        ) : [];
        this.setState({ allMachines: orderAllMachines });

        // Get my users
        const { users: myUsers } = await this.getUsers(clientID);
        this.setState({ myUsers: myUsers });
        // Get dealer's users
        await this.getDealerUsers();

        // Stop loading
        this.setState({ loadLicenceData: true });
        
        // Get all users
        const { users: allUsers, isSuccessUsers: isSuccessAllUser } = await this.getUsers();

        // Order all user list
        const orderAllUsers = isSuccessAllUser && allUsers ? allUsers.sort(
            ({ client: { nomClient: nomClientA } }, { client: { nomClient: nomClientB } }) => {
                if (nomClientA < nomClientB) {
                    return -1;
                }
                if (nomClientA > nomClientB) {
                    return 1;
                }

                return 0;
            },
        ) : [];
        this.setState({ allUsers: orderAllUsers });
    };

    onFilter = (value, obj) => {
        if (obj === true || obj === false) {
            return obj == value;
        }
        return obj && obj.toLowerCase().includes(value.toLowerCase()); 
    };

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    getActions = (userRole, allMachines, allUsers, concessionaireUsers, myMachines, myUsers) => [
        {
            key: 'actions',
            render: (_, { guid, isLicenseChild, isMultiUsers, duree, famille, idProfil, profil, ...props }) => (
                <span>
                    {(isGroupAdminClient({ userRole }) || isSuperAdmin({ userRole })) && (
                        <SwapLicenseOwnership
                            {...props}
                            guid={guid}
                            myMachines={myMachines}
                            allMachines={allMachines}
                            myUsers={myUsers}
                            allUsers={allUsers}
                            concessionaireUsers={concessionaireUsers}
                            refresh={this.fetchDataAsync}
                            isLicenseChild={isLicenseChild}
                            isMultiUsers={isMultiUsers}
                            profil={profil}
                        />
                    )}
                    {!isLicenseChild && isMultiUsers && (
                        <Fragment>
                            <Divider type="vertical" />
                            <ModalGenerate refresh={this.fetchDataAsync} parent={{ guid, duree, famille, idProfil }} />
                        </Fragment>
                    )}
                    {!isLicenseChild && isSuperAdmin({ userRole }) && (
                        <Fragment>
                            <Divider type="vertical" />
                            <UpdateLicense guid={guid} refresh={this.fetchDataAsync} />
                        </Fragment>
                    )}
                    {/* A superAdmin can delete all licenses
                        A clientAdmin can delete only licenseChild
                        La confirmation est pour toutes les licences mères
                    */}
                    {isSuperAdmin({ userRole }) && (
                        <Fragment>
                            <Divider type="vertical" />
                            <DeleteLicense refresh={this.fetchDataAsync} guid={guid} isLicenseChild={isLicenseChild} />
                        </Fragment>
                    )}
                    {(isGroupAdminClient({ userRole }) && !isSuperAdmin({ userRole }) && isLicenseChild) && (
                        <Fragment>
                            <Divider type="vertical" />
                            <DeleteLicense refresh={this.fetchDataAsync} guid={guid} isLicenseChild={isLicenseChild} />
                        </Fragment>
                    )}
                </span>
            ),
        },
    ];

    getColumns() {
        return [{
            dataIndex: 'guid',
            key: 'guid',
            sorter: (a, b) => alphabetically(a.guid, b.guid),
            render: (text) => (
                <Tooltip title={text}>
                    <span>{`${text.substring(0, 10)}...`}</span>
                </Tooltip>
            ),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.guid),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'profil',
            key: 'profil',
            sorter: (a, b) => alphabetically(a.profil, b.profil),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.profil),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => alphabetically(a.type, b.type),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.type),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'expirationDate',
            key: 'expiration',
            sorter: (a, b) => compareDate(a.expirationDate, b.expirationDate),
            filterDropdown: this.filterDropDown,
            onFilter: (value, { expirationDate }) => this.onFilter(value, expirationDate),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            render: (expirationDate) => {
                const momentDate = moment(expirationDate, 'DD/MM/YYYY');
                const inside = <span>{momentDate.format('DD/MM/YY')}</span>;

                return moment().diff(momentDate, 'days') >= 0 ? <Badge dot>{inside}</Badge> : inside;
            },
        },{
            dataIndex: 'user.nomPrenom',
            key: 'userName',
            sorter: (a, b) => alphabetically(a.user.nomPrenom, b.user.nomPrenom),
            filterDropdown: this.filterDropDown,
            onFilter: (value, { user: { nomPrenom } }) => this.onFilter(value, nomPrenom),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }];
    }

    render() {
        const { history } = this.props;
        const { loadLicenceData } = this.state;

        const { active, dateLastDataFeedBackGmt, dateWarrantyActivation, historySaveEnabled, loadDeviceData, nameDevice, serial, serialMachine, type, lisenses,
            allMachines, allUsers, concessionaireUsers, myMachines, myUsers } = this.state;
        const { userRole } = this.props;

        const actions = this.getActions(userRole, allMachines, allUsers, concessionaireUsers, myMachines, myUsers);

        const columns = isGroupAdminClient({ userRole })
            ? [...this.getColumns(), ...actions]
            : this.getColumns();

        const translatedColumns = columns.map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        return (
            <PageLayout pageKey="deviceInfos" history={history}>
                <h2>{<FormattedMessage id="device.info" />}</h2>

                <Tabs onChange={this.callbackTab} type="card">
                    {/* Device Tab */}
                    <TabPane tab={<FormattedMessage id="device.info.tab.device.title" />} key="1">
                        {(loadDeviceData) ? (
                            <div style={{paddingLeft: "0.2em"}} >
                                <Descriptions>
                                    <Descriptions.Item span={3} label={<FormattedMessage id="device.info.tab.device.name" />}>{nameDevice}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={<FormattedMessage id="device.info.tab.device.serial" />}>{serial}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={<FormattedMessage id="device.info.tab.device.serialMachine" />}>{serialMachine}</Descriptions.Item>
                                    <Descriptions.Item span={3} label={<FormattedMessage id="device.info.tab.device.type" />}>{type}</Descriptions.Item>
                                </Descriptions>
                            </div>
                        ) : (
                            <span style={{paddingLeft: "0.2em"}} ><Spin size="small" /></span>
                        )}
                    </TabPane>

                    {/* State Tab */}
                    <TabPane tab={<FormattedMessage id="device.info.tab.state.title" />} key="2">
                        {(loadDeviceData) ? (
                            <div style={{paddingLeft: "0.2em"}}>
                                <button className="ant-btn ant-btn-primary"
                                        style={{marginBottom: '0.5em'}}
                                        onClick={() => this.loadDeviceInfo()}
                                >
                                    <FontAwesomeIcon icon={faSyncAlt} />
                                </button>
                                <Descriptions>
                                    <Descriptions.Item span={3} label={<FormattedMessage id="device.info.tab.state.active" />}>
                                        { (active) ? 
                                            <FontAwesomeIcon icon={faPowerOff} style={{ color: 'yellowgreen'}} />
                                         :
                                            <Icon type="close" theme="outlined" style={{ color: 'red'}} />
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={<FormattedMessage id="device.info.tab.state.historyActive" />}>
                                        { (historySaveEnabled) ? 
                                            <Icon type="check" theme="outlined" style={{ color: 'yellowgreen'}} />
                                         :
                                            <Icon type="close" theme="outlined" style={{ color: 'red'}} />
                                        }
                                    </Descriptions.Item>
                                    <Descriptions.Item span={3} label={<FormattedMessage id="device.info.tab.state.dateDataFeedback" />}>{renderDate(dateLastDataFeedBackGmt)}</Descriptions.Item>
                                    {(dateWarrantyActivation) && (
                                        <Descriptions.Item span={3} label={<FormattedMessage id="device.info.tab.state.dateWarrantyActivation" />}>{renderDate(dateWarrantyActivation)}</Descriptions.Item>
                                    )}
                                </Descriptions>
                            </div>
                        ) : (
                            <span style={{paddingLeft: "0.2em"}} ><Spin size="small" /></span>
                        )}
                    </TabPane>
                    
                    {/* Licence Tab */}
                    <TabPane tab={<FormattedMessage id="device.info.tab.license.title" />} key="3">
                        <Table
                            className="custom-table"
                            dataSource={lisenses}
                            columns={translatedColumns}
                            loading={!loadLicenceData}
                            rowKey="guid"
                            tableName={tableName}
                        />
                    </TabPane>
                </Tabs>
            </PageLayout>
        );
    }
}

Infos.propTypes = {
    clientID: PropTypes.string.isRequired,
    userRole: PropTypes.array,
    intl: intlShape.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            serial: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            nameDevice: PropTypes.string.isRequired,
            serialMachine: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired,
            active: PropTypes.bool.isRequired,
            historySaveEnabled: PropTypes.bool.isRequired,
            id: PropTypes.number.isRequired,
            dateLastDataFeedBack: PropTypes.string,
        }).isRequired,
    }).isRequired,
}

const mapStateToProps = ({ signIn: { clientID, userRole } }) => ({
    clientID,
    userRole,
});

export default injectIntl(connect(mapStateToProps)(Infos));