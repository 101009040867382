import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDolly, faTruck } from '@fortawesome/free-solid-svg-icons';
import { Button, Modal, Popover, List } from 'antd';
import { getTrackingLinks } from '../../utils/apiBucherVaslin';
  
class TrackingOrder extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            trackingLinks: []
        };
    }
    
    openTrackingModal = async () => {

        this.setState({ visible: true });
        const { intl, id } = this.props;
        const { trackingLinks, isSuccess, isUnauthorized, errorMessage, errorDetail } = await getTrackingLinks(id);
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }    
        this.setState({trackingLinks});  
    }

    onCancel() {
        this.setState({ visible: false, trackingLinks: [] });
    }

    render() {
        const { visible, trackingLinks } = this.state;
        const { id, haveTrackingLinks } = this.props;
        return (
            <Fragment>
                <Modal
                    title={<FormattedMessage id="table.order.trackingOrder.title" />}
                    visible={visible}
                    onCancel={() => this.onCancel()}
                    footer={null}
                    width="40%"
                >
                <Fragment>    
                <List
                    bordered
                    dataSource={trackingLinks}
                    renderItem={item => (
                        <List.Item  style= {{justifyContent: "center"}}>
                          <FontAwesomeIcon icon={faTruck} style={{marginRight: "0.5em"}} /><a href={item.lien} target='_blank'>{item.libelleLien}</a>
                        </List.Item>
                    )}                 
                />
                </Fragment> 
                </Modal>
                <Popover content={haveTrackingLinks? <FormattedMessage id="table.order.trackingOrderTrue" /> : <FormattedMessage id="table.order.trackingOrderFalse" />}>
                    <Button disabled={!haveTrackingLinks} onClick={this.openTrackingModal}>  <FontAwesomeIcon icon={faDolly} /> </Button>
                </Popover>
            </Fragment>
        );
    }
}

TrackingOrder.propTypes = {
    intl: intlShape.isRequired,
    id: PropTypes.string.isRequired,
    haveTrackingLinks: PropTypes.bool.isRequired
};

export default injectIntl(TrackingOrder);
