import PropTypes from 'prop-types';
import React from 'react';

import './ArticleComponents.less';

const ArticleComponents = ({ composants }) => (composants && composants.length ? (
    <p className="components">
        <ul>
            {composants.map(({ codeArticle, libelle }) => (
                <li>
                    <b>{parseInt(codeArticle, 10)}</b>
                    {libelle}
                </li>
            ))}
        </ul>
    </p>) : null);

ArticleComponents.propTypes = {
    composants: PropTypes.arrayOf(PropTypes.array),
};

export default ArticleComponents;
