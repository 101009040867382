import React from 'react';
import PropTypes from 'prop-types';
import { Menu } from 'antd';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './FooterMenu.less';

const FooterMenu = ({ pageKey }) => (
    <Menu
        className="footer-menu"
        selectedKeys={[pageKey]}
        mode="horizontal"
    >
        <Menu.Item key="contact">
            <NavLink to="/contact">
                <span className="icon-container">
                    <FontAwesomeIcon icon="envelope" />
                </span>
                <FormattedMessage id="menu.footer.contact" />
            </NavLink>
        </Menu.Item>
        <Menu.Item key="cgu">
            <NavLink to="/cgu">
                <span className="icon-container">
                    <FontAwesomeIcon icon="file-contract" />
                </span>
                <FormattedMessage id="menu.footer.cgu" />
            </NavLink>
        </Menu.Item>
        <Menu.Item key="faq">
            <NavLink to="/faq">
                <span className="icon-container">
                    <FontAwesomeIcon icon="question-circle" />
                </span>
                <FormattedMessage id="menu.footer.faq" />
            </NavLink>
        </Menu.Item>
    </Menu>
);

FooterMenu.propTypes = {
    pageKey: PropTypes.string.isRequired,
};

export default FooterMenu;
