import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import PageLayout from '../Layout/PageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { getAllDocsByCodeArticle } from '../../utils/apiBucherVaslin';
import { isInfoCompleted, alphabetically } from '../../utils/helpers';
import PropTypes from 'prop-types';
import { Button, Divider, Form, notification, Input, Table  } from 'antd';
import ShowDoc from './ShowDoc';

const { Search } = Input;
const { Item: FormItem } = Form;

class FlashInfo extends Component {
    constructor(props){
        super(props);
        this.state = {
            docs: [],
            codeArticle : '',
            article: '',
            loading: false,
            idPreSelected: null
        };
    }

    async componentDidMount() {
        /* check connexion and information */ 
        
        const {  
            match: {
                params: { id },
            },
            intl, userID  } = this.props;
        if(id) {
            this.setState({ idPreSelected: id });
            this.getDocsByCodeArticle(id)
        }
            
        await isInfoCompleted(intl, userID);
    }

    // Method used to load flash infos list list
    getDocsByCodeArticle = async(value) => {  

        await this.setState({loading: true});   
        const { docs, isSuccess, isUnauthorized, errorMessage, errorDetail} = await getAllDocsByCodeArticle(value);//"61002083"
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            return;
        }
        const article = docs[0]? docs[0].article : '';
        const codeArticle = docs[0]? docs[0].codeArticle : '';
        this.setState({ docs, article, codeArticle, loading: false });
    }
    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    onFilter = (value, obj) => obj && obj.toLowerCase().includes(value.toLowerCase());

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };
    
    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    getColumns () {
        const { intl } = this.props;

        return [
            {
                title: intl.formatMessage({id:'partsUseCases.table.typeDocumentation'}),
                dataIndex: 'typeLibelle',
                sorter: (a, b) => alphabetically(a.typeLibelle, b.typeLibelle),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.typeLibelle),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
            },
            {
                title: intl.formatMessage({id:'partsUseCases.table.modele'}),
                dataIndex: 'modele',
                sorter: (a, b) => alphabetically(a.modele, b.modele),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.modele),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
            },
            {
                title: intl.formatMessage({id:'partsUseCases.table.codeDocumentation'}),
                dataIndex: 'codeDocumentation',
                sorter: (a, b) => alphabetically(a.codeDocumentation, b.codeDocumentation),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.codeDocumentation),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
            },
            {
                title: intl.formatMessage({id:'partsUseCases.table.nomDocumentation'}),
                dataIndex: 'nomDocumentation',
                sorter: (a, b) => alphabetically(a.nomDocumentation, b.nomDocumentation),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.nomDocumentation),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
            },
            {                
                title: intl.formatMessage({id:'partsUseCases.table.version'}),
                dataIndex: 'version',
                sorter: (a, b) => alphabetically(a.version, b.version),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.version),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
            },
            {                
                title: intl.formatMessage({id:'partsUseCases.table.versionPdr'}),
                dataIndex: 'versionPdr',
                sorter: (a, b) => alphabetically(a.versionPdr, b.versionPdr),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.versionPdr),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange
            },
            {
                key: 'action',
                render: (_, row) => (
                    <Fragment>
                        <span>
                            <ShowDoc nomFichier={row.nomFichier} />              
                            <Divider type="vertical" />
                            <a href={row.blobUrlFichier} target="_blank" download>
                            <Button htmlType="button" style={{ marginRight: 8 }}>
                                <FontAwesomeIcon icon={faDownload} />
                            </Button>
                            </a>
                        </span>
                    </Fragment>
                )
            }
        ];
    };

    render() {
        const { history, intl, form: { getFieldDecorator }  } = this.props;
        const { loading, docs, article, codeArticle, idPreSelected } = this.state;

        const columns = this.getColumns();

        return(
            <PageLayout pageKey="partsUseCases" history={history} withPadding={true}>
                <h2>
                    {intl.formatMessage({ id: "menu.support.partsUseCases" })}
                    {article.length > 0 ? " - " + article + " (" + codeArticle +")" : ''}
                </h2>
                {/* Status filter */}
                <div style={{marginBottom:'1em'}}>
                    <FormattedMessage id='partsUseCases.searchLabel' /> : 
                    <FormItem>
                        {getFieldDecorator('searchRefArticle', {
                            rules: [
                                { required: true, message: <FormattedMessage id="order.search.searchRequired" />  }
                            ],
                            initialValue: idPreSelected        
                        })
                        (
                        <Search
                            loading={loading}
                            onSearch={this.getDocsByCodeArticle}
                            placeholder={intl.formatMessage({id: 'partsUseCases.search.placeholder'} )}
                            style={{ width:"30%" }}
                        />
                        )}
                    </FormItem>
                </div>

                <Table
                    className="custom-table"
                    dataSource={docs}
                    columns={columns}
                    loading={loading}
                />
            </PageLayout>
        )
    } 
}

FlashInfo.propTypes = {
    intl: intlShape.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired
    }).isRequired,
    locale: PropTypes.string
}

const mapStateToProps = ({
    app: {
        locale,
    },
    signIn: {
        userID
    }
}) => ({ locale, userID });

export default connect(mapStateToProps)(injectIntl(Form.create()(FlashInfo)));
