import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Icon, Modal, Popconfirm, Popover, message, Spin, Switch, Checkbox } from 'antd';
import { activateDevice } from '../../utils/apiBucherVaslin';
import HtmlIncluder from '../HtmlIncluder';
import { Link } from 'react-router-dom';

// Total step number
const maxStep = 2;

class ActivateDevice extends Component {
    state = {
        loading: false,
        visible: false,
        dealerCheckCGU: false,
        dealerCheckWarranty: false,
        step: 1,
        confirmationCheckbox : false,
    };

    async activate() {
        this.setState({ loading: true });
        const { intl, clientID, name, serial, refresh, askActivate } = this.props;
        this.hideModal();
        const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await activateDevice(serial, clientID);

        this.setState({ loading: false });
        if (isSuccess) {
            message.success(
                intl.formatMessage(
                    {
                        id: askActivate ? 'device.activation.dealer.submitted' : 'table.devices.activationModal.success',
                    },
                    { name },
                ),
                5,
            );

            refresh();

            return;
        }

        Modal.error({
            title: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.title' })
            : intl.formatMessage({
                id: 'table.devices.activationModal.fail',
            }),
            content: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.message' })
            : errorDetail,
        });
    }

    /**
     * Method used when user confirm modal
     */
    confirm() {
        const { askActivate, warranty } = this.props;
        const { step } = this.state;

        // If user isn't dealer for this device
        if (!askActivate) {
            this.activate();
        } else {
            // If User is the dealer for this device
            if((step === 1 && !warranty ) || (step === maxStep )) {
                // User have validate all step, he's now able to proceed device activation
                this.activate();
            } else {
                // User havn't confirm all steps
                this.setState({ step: step + 1});
            }
        }   
    }

    /**
     * Method used to return corresponding label for "Ok" button
     */
    getOkButtonLabel() {
        const { askActivate, warranty } = this.props;
        const { step } = this.state;


        if (!warranty || step === maxStep) {
            // If all displayed step have been completed
            return (askActivate) ? "cgu.accept.dealer" : "cgu.accept";
        } else {
            // Else, display next step
            return "activateDevice.nextStep";
        }
    }

    hideModal() {
        this.setState({ visible: false });
    }

    /**
     * Method called when user confirm device activation
     */
    displayActivationModal() {
        const { intl, isNumeroEureka, ownerId } = this.props;
        if (!isNumeroEureka || !ownerId || ownerId === "NewCli") {
             // If device owner client haven't been validated yet => Show Info modal
             //console.log('modal de confirmation');
            Modal.info({
                content: (
                    <div>
                        { intl.formatMessage({ id: 'table.devices.activationInfoModal.description' }) }
                    </div>
                ),
                onOk() {},
            });
        } else {
            //console.log('modal de confirmation');
            // If client have been validated => Show confirmation modal
            this.setState({ visible: true })
        } 
    }

    // Update validation checkbox value
    async changeValidationCheck() {
        const { confirmationCheckbox } = this.state;
        await this.setState({confirmationCheckbox: !confirmationCheckbox})
    }

    // Method used to get informations about General Terms of Sales
    getGTS() {
        const { intl, locale } = this.props;
        const gts = { 
            label: intl.formatMessage({ id: 'gts.title' }),
            link: ""
        };

        switch(locale) {
            case "fr":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_french.pdf"
                break;
            case "en":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_english.pdf"
                break;
            case "de":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_german.pdf"
                break;
            case "es":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_spanish.pdf"
                break;
            case "it":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_italian.pdf"
                break;
            default :
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_english.pdf"
                break;
        }

        return gts;
    }

    render() {
        const { name, askActivate, authorizeTransfert } = this.props;
        const { loading, visible, dealerCheckCGU, dealerCheckWarranty, step, confirmationCheckbox} = this.state;
        const gts = (visible) ? this.getGTS() : null;
        //console.log(step);
        return (
            <Popover content={!authorizeTransfert ? <FormattedMessage id="table.devices.impossible" /> : <FormattedMessage id="table.devices.activate" />}>
                <Popconfirm
                    title={<FormattedMessage id="table.devices.activationModal.title" values={{ name }} />}
                    okText={<FormattedMessage id="common.yes" />}
                    cancelText={<FormattedMessage id="common.no" />}
                    onConfirm={() => this.displayActivationModal()}
                >
                    {loading ? (
                        <Button htmlType="button">
                            <Spin size="small" />
                        </Button>
                    ) : (
                        <Button htmlType="button" disabled={!authorizeTransfert}>
                            <Icon type="caret-right" />
                        </Button>
                    )}
                </Popconfirm>
                <Modal
                    title={<FormattedMessage id={(step === 1) ? "cgu.title" : "table.devices.guarantee"} />}
                    visible={visible}
                    okText={<FormattedMessage id={this.getOkButtonLabel()} />}
                    cancelText={<FormattedMessage id="cgu.decline" />}
                    onOk={() => this.confirm()}
                    okButtonProps={{ 
                        disabled: !askActivate ? false : ((step === 1 && (!dealerCheckCGU || !confirmationCheckbox)) || (!dealerCheckWarranty && step === 2))
                    }}
                    onCancel={() => this.hideModal()}
                    bodyStyle={{
                        overflowY: 'hidden',
                        minHeight: '350px',
                        position: 'relative',
                        display: askActivate ? 'block' : 'flex',
                    }}
                    width="70%"
                >
                    

                    {/* Step 1 */}
                    {step === 1 && (
                        <Fragment>
                            {askActivate && (
                                <Fragment>
                                    <Switch
                                        checked={dealerCheckCGU}
                                        onChange={(value) => {this.setState({dealerCheckCGU: value})}}
                                        className="alert-switch"
                                    />
                                    <FormattedMessage
                                        id="device.activation.dealer.askOwnerCGU"
                                        defaultMessage="Confirm that the machine belongs to you and that you will use it to make demos or provide services"
                                    />
                                </Fragment>
                            )}
                        
                        <br></br>
                        <span>
                            <Checkbox 
                                checked={confirmationCheckbox} 
                                onChange={ () => this.changeValidationCheck() }
                                style={{marginRight: "1em", marginLeft: "1em", marginTop: "1em"}}
                            />
                            <FormattedMessage id="device.modal.confirm.terms.of.sales" />
                        </span>

                        {/* List of informations link */}
                        <ul style={{marginTop: "1em"}}>
                            {/* Link to General Terms of Sales */}
                            {gts && (
                                <li>
                                    <a target="_blank" href={gts.link}>{gts.label}</a>
                                </li>
                            )}
                        </ul>
                        
                            
                            {(!askActivate || (dealerCheckCGU && confirmationCheckbox)) && (
                                <HtmlIncluder 
                                    fileName="cgu"
                                    isModalContent={true}
                                    onLoad={(instance) => {
                                        if (instance) {
                                            instance.style.height = 'calc( 90vh - 100px - 56px - 50px - 42px - 53px - 47px )';
                                        }
                                    }}
                                />
                            )}
                        </Fragment>
                    )}

                    {/* Step 2 */}
                    {step === 2 && (
                        <Fragment>
                            <Fragment>
                                <Switch
                                    checked={dealerCheckWarranty}
                                    onChange={(value) => {this.setState({dealerCheckWarranty: value})}}
                                    className="alert-switch"
                                />
                                <FormattedMessage
                                    id="device.activation.dealer.askOwnerWarranty"
                                />
                            </Fragment>

                            <HtmlIncluder 
                                fileName="warranty"
                                isModalContent={true}
                            />

                            <Fragment>
                                <Switch
                                    checked={dealerCheckWarranty}
                                    onChange={(value) => {this.setState({dealerCheckWarranty: value})}}
                                    className="alert-switch"
                                />
                                <FormattedMessage
                                    id="device.activation.dealer.askOwnerWarranty"
                                />
                            </Fragment>
                                           
                        </Fragment>
                    )}
                </Modal>
            </Popover>
        );
    }
}

ActivateDevice.propTypes = {
    askActivate: PropTypes.bool.isRequired, // Si l'utilisateur est le concessionnaire de la machine alors la valeur est à true
    clientID: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    isNumeroEureka: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    ownerID: PropTypes.string,
    refresh: PropTypes.func.isRequired,
    serial: PropTypes.string.isRequired,
    warranty: PropTypes.bool.isRequired,
    authorizeTransfert : PropTypes.bool.isRequired,
};

export default injectIntl(ActivateDevice);
