import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Layout, Button, Icon } from 'antd';
import { NavLink } from 'react-router-dom';

import MainMenu from './MainMenu';
import FooterMenu from './FooterMenu';

import './Layout.less';

const { Header, Content, Footer, Sider } = Layout;

class MobileLayout extends Component {
    constructor(props) {
        super(props);

        this.state = {
            siderCollapsed: true,
        };
    }

    handleSiderCollapse = () => {
        const { siderCollapsed } = this.state;

        this.setState({
            siderCollapsed: !siderCollapsed,
        });
    };

    render() {
        const { flex, pageKey, loggedIn, children, handleLogout, handleChangeLocale, currentLocale, withPadding } = this.props;
        const { siderCollapsed } = this.state;

        return (
            <Layout className="main-container">
                <Sider
                    className="sider"
                    theme="light"
                    width={250}
                    collapsible
                    collapsedWidth={0}
                    collapsed={siderCollapsed}
                    trigger={null}
                > 
                    <MainMenu
                        currentLocale={currentLocale}
                        handleChangeLocale={handleChangeLocale}
                        handleLogout={handleLogout}
                        pageKey={pageKey}
                        loggedIn={loggedIn}
                        mode="mobile"
                    />
                </Sider>
                <Layout>
                    <Header className={(siderCollapsed) ? "header header-mobile" : "header header-mobile header-mobile-open"}>
                        <NavLink to="/">
                            <div className="logo-container-mobile">
                                <img
                                    className="logo"
                                    src="/img/logo.png"
                                    alt="logo"
                                />
                            </div>
                        </NavLink>

                        <div className="sider-button-container">
                            <Button
                                htmlType="button"
                                type="default"
                                onClick={this.handleSiderCollapse}
                            >
                                <Icon type={siderCollapsed ? 'menu-unfold' : 'menu-fold'} />
                            </Button>
                        </div>
                    </Header>
                    <Content className={`content ${flex ? 'content-flex' : ''} ${withPadding ? 'content-padding' : ''}`}>
                        {children}
                    </Content>
                    <Footer>
                        <FooterMenu pageKey={pageKey} />
                    </Footer>
                </Layout>
            </Layout>
        );
    }
}

MobileLayout.propTypes = {
    pageKey: PropTypes.string.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    children: PropTypes.node,
    handleLogout: PropTypes.func,
    handleChangeLocale: PropTypes.func,
    currentLocale: PropTypes.string,
    flex: PropTypes.bool,
    withPadding: PropTypes.bool.isRequired,
};

export default MobileLayout;
