import React from 'react';

import PageLayout from './Layout/PageLayout';
import HtmlIncluder from './HtmlIncluder';

const WinectGuide = () => (
    <PageLayout pageKey="winectGuide" flex>
        <HtmlIncluder fileName="winectGuide" />
    </PageLayout>
);

export default WinectGuide;
