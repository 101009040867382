import React, { Component } from "react";
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Avatar } from "antd";

const BLOB_URL = process.env.BLOB_URL;

class ProgressIcon extends Component {
    constructor(props){
        super(props);
        this.state = {
        };
    }

    componentDidMount() {
    }

    displayIcon = (fileName) => {
        if (fileName) {
            // Adress of the image
            const imgSrc = `${BLOB_URL}/${fileName}`;
    
            return <Avatar size="small" src={imgSrc} />
        }
    
        return null;
    };

    render(){
        const { fileName } = this.props;
        
        return this.displayIcon(fileName);
    }
}

ProgressIcon.propTypes = {
    clientID: PropTypes.string.isRequired,
    userRole: PropTypes.array,
    intl: intlShape.isRequired,
    fileName: PropTypes.string.isRequired,
    key: PropTypes.number,
}

const mapStateToProps = ({ signIn: { clientID, userRole } }) => ({
    clientID,
    userRole,
});

export default injectIntl(connect(mapStateToProps)(ProgressIcon));