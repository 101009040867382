import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Modal, notification, Select } from 'antd';
import { fetchArticlesFamilies } from '../../utils/apiBucherVaslin';
import Articles from '../Articles';
import OrderStepper from '../Orders/OrderStepper';
import '../Orders/AddArticles.less';

class AddArticlesLicense extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedArticles: [],
            family: null,
            families: [],
            visible: false,
        };
    }

    async componentDidMount() {
        // await this.fetchFamilies();
    }

    onArticlesSelected(selectedArticles) {
        this.setState({ selectedArticles });
    }

    onCancel() {
        this.setState({ visible: false });
    }

    // onFamilyChanged(value) {
    //     this.setState({ family: this.findFamily(value) });
    // }

    onOk() {
        const { onSubmit } = this.props;
        const { selectedArticles } = this.state;

        onSubmit(selectedArticles);
        this.setState({ visible: false });
    }

    // findFamily(value) {
    //     const { families } = this.state;

    //     return families.find((f) => f.codE_FAMILLE === value || f.libellE_FAMILLE === value);
    // }

    // async fetchFamilies() {
    //     const { intl } = this.props;
    //     const { items, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchArticlesFamilies();
        
    //     if (!isSuccess){
    //         notification.error({
    //             message: isUnauthorized 
    //                 ? intl.formatMessage({ id: 'error.unauthorized.title' })
    //                 : intl.formatMessage({ id: 'common.error' }),
    //             description: isUnauthorized 
    //                 ? intl.formatMessage({ id: 'error.unauthorized.message' })
    //                 : errorDetail,
    //         });
    //     }
    //     this.setState({ families: items });
    // }

    render() {
        const { visible } = this.state;
        const { client } = this.props;
        return (
            <Fragment>
                <Modal
                    title={<FormattedMessage id="orderLicense.modal.title" />}
                    visible={visible}
                    onCancel={() => this.onCancel()}
                    onOk={() => this.onOk()}
                    okText={<FormattedMessage id="order.modal.button" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                    width="80%"
                >
                    
                    <div className="category-filter">
                        {/* Include order stepper */}
                        <OrderStepper stepIndex={2} />
                    </div>
                    <Articles
                        familyCode="SAW"
                        client={client}
                        allowSelection
                        showFamily={true}
                        onArticlesSelected={(selectedArticles) => this.onArticlesSelected(selectedArticles)}
                    />
                </Modal>
                <Button onClick={() => this.setState({ visible: true })}>
                    <FormattedMessage id="order.add" />
                </Button>
            </Fragment>
        );
    }
}

AddArticlesLicense.propTypes = {
    client: PropTypes.string.isRequired,
    onSubmit: PropTypes.func,
    intl: intlShape.isRequired,
};

export default injectIntl(AddArticlesLicense);
