import React, { Component} from 'react';
import { Button,  Icon, Popover } from 'antd';
import { FormattedMessage, injectIntl } from 'react-intl';
import { CheckCanShowGTAAndReturnIdAgent, Gta } from '../utils/apiBucherVaslin';
import { isNull, isUndefined } from 'lodash';



class MachineHandling extends Component {
        
        state = {urlMachineHandling:'',
        urlAbs: true,
        message:<FormattedMessage id="table.devices.no.login.license" />,
        };
        
    async componentDidMount(){
  
    //    if(this.props.hasLicenceConnexion && this.props.hasLicenceConnexionValide){
  
       if(this.props.grantAccess){

            new Promise((resolve) => {
                resolve(CheckCanShowGTAAndReturnIdAgent(this.props.idDevice));
            }).then((valIdAgent) =>{
                this.setState({message:valIdAgent.item.data.raison})
                if(!isUndefined(valIdAgent.item.data.idAgent) && !isNull(valIdAgent.item.data.idAgent)){
                //    console.log(valIdAgent.item.data.idAgent);
                   new Promise((resolve) => {
                      resolve(Gta(valIdAgent.item.data.idAgent));
                }).then((valUrl) =>{
                    // console.log(valUrl.item.GTA.url)
                    if(!isUndefined(valUrl.item.GTA.url) && !isNull(valUrl.item.GTA.url)){
                        this.setState({urlMachineHandling : valUrl.item.GTA.url})
                        this.setState({urlAbs: false})
                    }
                })
                }
            });
            
        }
    }

handleClick = () =>{
    // const {hasLicenceConnexion, hasLicenceConnexionValide} = this.props;
    const {grantAccess} = this.props;
    // if(hasLicenceConnexion && hasLicenceConnexionValide && !this.state.urlAbs ) {
    if(grantAccess && !this.state.urlAbs ) {
        
        window.open(this.state.urlMachineHandling, '_blank');
    } 
};


    render() {
        const {grantAccess} = this.props;
        // console.log((grantAccess && !this.state.urlAbs) ? this.props.idDevice : "");
        return (
            <span>
                {/* <Popover content= {(hasLicenceConnexion && hasLicenceConnexionValide && !this.state.urlAbs) ? <FormattedMessage id="table.devices.machine.handling" /> : <FormattedMessage id="table.devices.no.login.license" />} */}
                {/* <Popover content= {(grantAccess && !this.state.urlAbs) ? <FormattedMessage id="table.devices.machine.handling" /> : <FormattedMessage id="table.devices.no.login.license" />} */}
                <Popover content= {(grantAccess && !this.state.urlAbs) ? <FormattedMessage id="table.devices.machine.handling" /> : this.state.message}
                >
                    {/* <Button disabled = {(hasLicenceConnexion && hasLicenceConnexionValide && !this.state.urlAbs) ? false : true} onClick={this.handleClick}>
                        {(hasLicenceConnexion && hasLicenceConnexionValide && !this.state.urlAbs) ?
                            <Icon type="eye" theme = "outlined" />
                       :
                            <Icon type="eye-invisible" theme = "outlined" />
                        }
                    </Button> */}
                    <Button disabled = {(grantAccess && !this.state.urlAbs) ? false : true} onClick={this.handleClick}>
                        {(grantAccess && !this.state.urlAbs) ?
                            <Icon type="eye" theme = "outlined" />
                       :
                            <Icon type="eye-invisible" theme = "outlined" />
                        }
                    </Button>
                </Popover>
            </span>
        );

    }

}

export default injectIntl(MachineHandling);