import { faFileSignature } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, InputNumber, message, Modal, notification, Popover, Select } from 'antd';
import { filter, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import {
    fetchAllLicenseFamilies, fetchLicensesTypes, fetchProfils, generateLicense,
} from '../../utils/apiBucherVaslin';
import { isSuperAdmin } from '../../utils/helpers';
import SelectCustomer from '../SelectCustomer';

export const inputStyle = {
    width: '100%',
    marginLeft: 0,
};

const { Option } = Select;

class ModalGenerate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            expiration: null,
            confirmLoading: false,
            customer: null,
            family: null,
            familyName: null,
            families: [],
            type: null,
            licensesTypes: [],
        };
    }

    generateModal = async () => {
        this.setState({
            visible: true,
        });
        const { intl } = this.props;

        const { items: families, isSuccess: isSuccessAllLicFam
            , isUnauthorized: isUnauthorizedAllLicFam
            , errorDetail: errorMessageAllLicFam } = await fetchAllLicenseFamilies();
        const { item: typesLicense, isSuccess: isSuccessLicensesTypes, isUnauthorized, errorMessage, errorDetail } = await fetchLicensesTypes();

        this.setState({
            families,
            licensesTypes: typesLicense,
        });
        if (!isSuccessAllLicFam){
            notification.error({
                message: isUnauthorizedAllLicFam 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedAllLicFam 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageAllLicFam,
            });
        }
        if (!isSuccessLicensesTypes){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
    };

    changeFamily = (value) => {
        const { families } = this.state;
        const familyValue = (families[value].isDemoKit) ? null : families[value].familyName;

        this.setState({
            family: familyValue,
            familyName: families[value].familyName
        });
    };

    changeExpiration = (value) => {
        this.setState({
            expiration: value,
        });
    };

    changeProfile = (type) => {
        this.setState({
            type,
        });
    };

    changeCustomer = (key) => {
        this.setState({
            customer: key,
        });
    };

    generateFromParent = async () => {
        const { clientID, parent: { guid, duree, famille, idProfil } } = this.props;
        const { customer } = this.state;

        this.setState({
            confirmLoading: true,
        });

        const response = await generateLicense(customer || clientID)({ duree, family: famille, profil: idProfil, parentGuid: guid });
        this.handleResponse(response);
    };

    handleResponse = (response) => {
        const { isSuccess, item: responseMsg, isUnauthorized } = response;
        const { intl, refresh } = this.props;

        this.hideModal();

        if (isSuccess) {
            refresh();
            message.success(responseMsg, 5);

            return;
        }

        Modal.error({
            title: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.title' })
            : intl.formatMessage({
                id: 'licenses.generate.error',
            }),
            content: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.message' })
            : intl.formatMessage({
                id: 'licenses.generate.errorMessage',
            }),
        });
    };

    generate = async () => {
        const { clientID, intl, parent } = this.props;
        const { expiration, customer, family, type } = this.state;

        this.setState({
            confirmLoading: true,
        });

        const { items: profils, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchProfils();
        let profil = null;
        if (isSuccess){
            profil = profils.find((p) => p.libelle === type);
            profil = profil ? profil.id : null;
        }
        else {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            return;
        }

        const response = await generateLicense(customer || clientID)({ duree: expiration, family, profil, parentGuid: parent, type});
        this.handleResponse(response);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    hideModal() {
        this.setState({
            visible: false,
            confirmLoading: false,
        });
    }

    render() {
        const { userRole, clientID, clientName, parent } = this.props;
        const { visible, confirmLoading, familyName, families, licensesTypes } = this.state;

        let availableLicences = filter(licensesTypes, { famille: familyName });
        availableLicences = uniqBy(availableLicences, 'type');

        return (
            <Fragment>
                {parent ? (
                    <Popover content={<FormattedMessage id="licenses.generate.new" />}>
                        <Button htmlType="button" onClick={this.generateFromParent}>
                            <FontAwesomeIcon icon={faFileSignature} />
                        </Button>
                    </Popover>
                ) : (
                    <Fragment>
                        <Divider style={{width:"auto", minWidth:"auto"}}/>
                        <Button className="generate-button" onClick={this.generateModal}>
                            Nouvelle licence
                        </Button>
                    </Fragment>
                )}
                <Modal
                    title={<FormattedMessage id="licenses.generate.modal" />}
                    onOk={this.generate}
                    visible={visible}
                    onCancel={this.handleCancel}
                    confirmLoading={confirmLoading}
                >
                    {isSuperAdmin({ userRole }) && (
                        <SelectCustomer
                            onChange={this.changeCustomer}
                            client={{ clientID, clientName }}
                        />
                    )}
                    <FormattedMessage id="licenses.generate.type.device" />
                    <Select
                        showSearch
                        optionFilterProp="children"
                        style={inputStyle}
                        onChange={this.changeFamily}
                    >
                        {families.map((value, index) => (
                            <Option 
                                key={index} 
                            >
                                {value.familyName}
                            </Option>
                        ))}
                    </Select>
                    <FormattedMessage id="licenses.generate.expiration" />
                    <InputNumber
                        className="input-number"
                        size="medium"
                        onChange={this.changeExpiration}
                        style={inputStyle}
                    />
                    <FormattedMessage id="licenses.generate.type" />
                    <Select
                        showSearch
                        optionFilterProp="children"
                        style={inputStyle}
                        onChange={this.changeProfile}
                    >
                        {availableLicences && availableLicences.map(({ type }) => (
                            <Option key={type}>{type}</Option>
                        ))}
                    </Select>
                </Modal>
            </Fragment>
        );
    }
}

ModalGenerate.propTypes = {
    userRole: PropTypes.array.isRequired,
    clientID: PropTypes.string,
    clientName: PropTypes.string,
    parent: PropTypes.shape({
        guid: PropTypes.string.isRequired,
        duree: PropTypes.number.isRequired,
        famille: PropTypes.string.isRequired,
        idProfil: PropTypes.number.isRequired,
    }),
    intl: intlShape.isRequired,
    refresh: PropTypes.func.isRequired,
};

const mapStateToProps = ({ signIn: { userID, userRole, clientID, clientName } }) => ({
    userID,
    userRole,
    clientID,
    clientName,
});

export default connect(mapStateToProps)(injectIntl(ModalGenerate));
