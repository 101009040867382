import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Icon, Modal, Popover, message } from 'antd';
import { deleteAddress } from '../../utils/apiBucherVaslin';
import { isGroupAdminClient, isGroupEBV } from '../../utils/helpers';


const { confirm } = Modal;

const showDeleteConfirm = (intl, id, nom, refresh) => {
    confirm({
        title: intl.formatMessage(
            {
                id: 'client.addressModal.deleteModal.title'
            },
            { nom },
        ),
        okText: intl.formatMessage({
            id: 'common.yes',
        }),
        cancelText: intl.formatMessage({
            id: 'common.no',
        }),
        okType: 'danger',
        async onOk() {
            const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await deleteAddress(id);

            if (isSuccess) {
                message.success(
                    intl.formatMessage(
                        {
                            id: 'client.addressModal.deleteModal.title',
                        },
                        { nom },
                    ),
                    5,
                );
                refresh();
                return;
            }
            Modal.error({
                title: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({
                    id: 'table.orders.fail',
                }),
                content: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : errorDetail,
            });
        },
    });
};

const DeleteAddress = ({ intl, id, nom, userRole, refresh }) => (
    <Popover content={<FormattedMessage id="client.addressModal.delete.title" />}>
        <Button disabled={!(isGroupAdminClient({userRole}) || isGroupEBV({userRole}))} style={{float:'right', marginRight:'1px', color:'red'}} onClick={() => showDeleteConfirm(intl, id, nom, refresh)}><Icon type="delete" />
        </Button> 
    </Popover>
);

DeleteAddress.propTypes = {
    intl: intlShape.isRequired,
    id: PropTypes.string.isRequired,
    nom: PropTypes.string.isRequired,
    userRole: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired
};

export default injectIntl(DeleteAddress);
