import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Modal, Pagination, Icon} from 'antd';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { fetchPDF } from '../../utils/apiBucherVaslin';
import './ShowDoc.less';


const BLOB_URL_TECHDOC = process.env.BLOB_URL_TECHDOC;

class ShowDoc extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            file : null,
            fileName : this.props.nomFichier,
            numPages : null,
            pageNumber:1,
            scale : 1.0,
        };
    }
    async componentDidMount() {
        this.setState({pageNumber : 1});
        this.setState({scale : 1.0});
        await fetchPDF(this.state.fileName).then((url)=>{
            this.setState({file : url});
        });

    }

    onCancel() {
        this.setState({ visible: false });
    }   
    onDocumentLoadSuccess = ({ numPages : pages }) => {
        console.log(pages);
        if(pages){
            // this.setState({pageNumber : 1});
            this.setState({numPages:pages});
        }
    };

    scaleIn = () =>{this.setState({scale: (this.state.scale - 0.1)})};
    scaleOut = () =>{this.setState({scale: (this.state.scale + 0.1)})};

    render() {
        const { visible, file, fileName, pageNumber, numPages, scale } = this.state;
        return (
        <Fragment>
          <Modal
            title={<FormattedMessage id="partsUseCase.modal.title" values={{nameDoc: fileName}} />}
            visible={visible}
            onCancel={() => this.onCancel()}
            okButtonProps={{ style: { display: 'none' } }}
            cancelText={<FormattedMessage id="common.cancel" />}
            width="60%"
            bodyStyle={{height: "80vh", display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", backgroundColor:"WhiteSmoke",}}
            centered = {true}
            > 
            {/* <iframe id='FrameId' style={{width:'100%', height:'100%'}} src={ "https://docs.google.com/gview?url=" +  BLOB_URL_TECHDOC + "/" +  encodeURI(nomFichier) + "&embedded=true"}></iframe>  */}
                {/* <Document file={file}>
                    <Page pageNumber={1}/>
                </Document> */}
                 <Fragment>
                        <Document file={file} onLoadSuccess={this.onDocumentLoadSuccess} className='docOverflow' >
                            <Page pageNumber={pageNumber} scale={scale} renderAnnotationLayer={false} renderTextLayer={false} />
                        </Document>
                        <br/>
                        <Pagination simple onChange={(page)=>{this.setState({pageNumber: page})}} current={pageNumber} defaultCurrent={1} total={numPages} pageSize={1} />
                        <br/>
                        <Button.Group>
                            <Button onClick={this.scaleOut}>
                                <Icon type="zoom-in"  style={{backgroundColor:'white'}} />
                            </Button>
                            <Button onClick={this.scaleIn}>
                                <Icon type="zoom-out"  style={{backgroundColor:'white'}}/>
                            </Button>
                        </Button.Group>                 
                    </Fragment>
      
        </Modal>
                <Button onClick={() => {this.setState({ visible: true })}}>
                    <FontAwesomeIcon icon={faSearch}/>
                </Button> 
        </Fragment>
        );
    }
}

ShowDoc.propTypes = {
    intl: intlShape.isRequired,
    nomFichier: PropTypes.string.isRequired,
};

export default (injectIntl(ShowDoc));
