import React, { useState, useEffect, Fragment } from "react";
import { Table } from "antd";
import { FormattedMessage } from "react-intl";
import { getListEchangesReseau } from "../../utils/apiBucherVaslin";
import {
  AnnulerLaDemande,
  CloturerCommeObtenu,
  CloturerCommePerdu,
  RemiseEnCircuit,
  SoumettreLaDemande,
  InformationDemandeur,
  FilterDropDown,
  OnFilter,
  OnFilterDropdownVisibleChange,
} from "./NetworkRequestsActions";
import { alphabetically } from "../../utils/helpers";

const getColumns = (columns, refresh) => {
  let columnsWhitAction = [
    ...columns,
    {
      dataIndex: "demandeurNomClient",
      key: "demandeurNomClient",
      filterDropdown: FilterDropDown,
      onFilter: (value, record) =>
        OnFilter(value, record.demandeurNomClient.toString()),
      onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
      sorter: (a, b) =>
        alphabetically(a.demandeurNomClient, b.demandeurNomClient),
      title: <FormattedMessage id={`networkRequests.table.prisPar`} />,
      render: (_, record) =>
        record.priseEnChargeNomClient && (
          <InformationDemandeur
            idUser={record.userIdPriseEnCharge}
            nomClient={record.priseEnChargeNomClient}
          />
        ),
    },
    {
      dataIndex: "actions",
      key: "actions",
      title: <FormattedMessage id={`table.users.action`} />,
      render: (_, record) => (
        <Fragment>
          {record.statut == 0 && (
            <SoumettreLaDemande id={record.id} refresh={refresh} />
          )}
          <CloturerCommeObtenu id={record.id} refresh={refresh} />
          <CloturerCommePerdu id={record.id} refresh={refresh} />
          {record.statut == 3 && (
            <RemiseEnCircuit id={record.id} refresh={refresh} />
          )}
          <AnnulerLaDemande id={record.id} refresh={refresh} />
        </Fragment>
      ),
    },
  ];
  return columnsWhitAction;
};
const MyNetworkRequests = ({ columns, isRefreshing, refresh }) => {
  const [loadMyNetworkRequests, setLoadMyNetworkRequests] = useState(true);
  const [myNetworkRequestsData, setMyNetworkRequestsData] = useState([]);
  useEffect(() => {
    getListEchangesReseau("MesDemandes").then(({ listEchangesReseau }) => {
      setMyNetworkRequestsData(listEchangesReseau);
      setLoadMyNetworkRequests(false);
    });
  }, [isRefreshing]);
  return (
    <Table
      className="custom-table"
      dataSource={myNetworkRequestsData}
      columns={getColumns(columns, refresh)}
      rowKey={(record) => record.id}
      loading={loadMyNetworkRequests}
    />
  );
};

export default MyNetworkRequests;
