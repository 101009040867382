import PropTypes from 'prop-types';
import React from 'react';

import PageLayout from '../Layout/PageLayout';
import HtmlIncluder from '../HtmlIncluder';

const Info = ({ match: { params: { name } } }) => (
    <PageLayout pageKey="infos">
        <HtmlIncluder folder="infos" fileName={name} />
    </PageLayout>
);

Info.propTypes = {
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            name: PropTypes.string,
        }),
    }).isRequired,
};

export default Info;
