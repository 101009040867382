import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PageLayout from '../Layout/PageLayout';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin } from 'antd';

import LinearGraphContainer from '../Graph/LinearGraph/LinearGraphContainer';
import './MetricPreview.less';

class MetricPreview extends Component {
    constructor(props){
        super(props);
        this.state = {
            file: null,
            jsonMetrics: null,
            loading: null,
            separator: ';',
        };
    }

    
    /*
    * Manage selected file actions
    * After reading file content, trigger graph visualisation process
    */
    handleFileDrop = async (file) => {
        if (file) {
            // Set loading
            await this.setState({file: null, loading: true});

            // Read File content
            const reader = new FileReader();

            // Init method triggered after file reading
            reader.onloadend = async () => {
                // Call convert method to generated metrics
                const jsonMetrics = await this.convertCSVToJSON(reader.result);

                // Update state value for displayedMetric
                this.setState({loading: false, jsonMetrics});
            }

            // Trigger file reading
            reader.readAsText(file);

            // Update state with file value (display file name)
            this.setState({file});
        }
    };


    // Method used to convert CSV file content to JSON object containing array of metrics
    convertCSVToJSON = async (fileContent) => {
        // Split fileContent in rows array
        const metrics = [];
        const rows = fileContent.split('\n');
        const { separator } = this.state;
        const fakeEscape = String.fromCharCode(0);
        
        // Loop throught each rows
        rows.forEach((row, rowIndex) => {
            const cells = row.split(separator);

            // Loop throught each cells
            cells.forEach((cell, cellIndex) => {
                if (rowIndex === 0) {
                    // Replace all fake escape with real one
                    cell = cell.replaceAll(fakeEscape, " ");

                    // Init metric array if header cell isn't empty
                    if (cell.trim() !== "") {
                        metrics[cellIndex] = [cell];
                    }
                } else {
                    // Check if the metric exists
                    if (metrics[cellIndex]) {
                        // Replace all fake escape with real one
                        cell = cell.replaceAll(fakeEscape, " ");

                        // Insert value in corresponding metric
                        metrics[cellIndex].push(cell);
                    }
                }
            });
        });

        return metrics;
    }

    render() {
        // Read props and state values
        const { intl, history } = this.props;
        const { file, jsonMetrics, loading } = this.state;
        
        return(
            <PageLayout pageKey="metricPreview" history={history}>
                <h1>
                    {intl.formatMessage({ id: 'metricPreview.title' })}
                </h1>

                {/* Dropzone */}
                <div className="preview-dropzone">
                    <input 
                        type="file"
                        className="input-file"
                        id="file"
                        accept=".csv"
                        onChange={(e) => {this.handleFileDrop(e.target.files[0])}}
                    />
                    <label htmlFor="file">
                        <div className="file-container">
                            { file && file.name && (
                                <button
                                    type="button"
                                    className="file-name-container"
                                >
                                    <span className="file-name">{file.name}</span>
                                </button>
                            )}
                        </div>

                        <span className="upload-icon">
                            <FontAwesomeIcon icon="file-upload" />
                            <FormattedMessage id="metricPreview.fileUpload" />
                        </span>     
                    </label>
                </div>

                {/* Graph preview (if a .csv file is selected) */}
                { file && jsonMetrics && (
                    <Fragment>
                        {loading ? (
                            <Spin size="large" style={{margin: "1em 0.5em"}}/>
                        ) : (
                            <LinearGraphContainer 
                                excludedMetrics={['date', 'heure', 'timestamp']}
                                jsonMetrics={jsonMetrics}
                                source={'preview'}
                                xAxis={'timestamp'}
                            />
                        )}
                    </Fragment>
                    
                )}
            </PageLayout>
        )
    } 
}

MetricPreview.propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string,
}

const mapStateToProps = ({
    app: {
        locale,
    }
}) => ({ locale });

export default connect(mapStateToProps)(injectIntl(MetricPreview));
