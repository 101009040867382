import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Button,
  DatePicker,
  Empty,
  Form,
  Icon,
  Input,
  Modal,
  notification,
  Radio,
  Select,
  Spin,
} from "antd";
import Dropzone from "react-dropzone";
import RequiredFieldLegend from "../RequiredFieldLegend";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { injectIntl, intlShape, FormattedMessage } from "react-intl";
import WarrantyPieces from "./WarrantyPieces";
import HtmlIncluder from "../HtmlIncluder";
import {
  fetchCustomers,
  fetchOrderClientAndType,
  getAddressCustomerById,
} from "../../utils/apiBucherVaslin";
import { isGroupConcessionnaire } from "../../utils/helpers";
import { REGEX_ORDER_REF } from "../../utils/constants";
import { debounce } from "lodash";

const { Item: FormItem } = Form;
const { Option } = Select;
const { Group } = Radio;

class ContactWarranty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: [],
      demandType: "",
      dateWarrantyDevice: "",
      concessionaireListeUsers: [],
      typePriseEnCharge: "", // If  100000000 -> remboursement facture, if  100000001 -> remplacement piéce
      showHelp: false,
      warrantyPieces: [],
      concessionaireListeUsers: [],
      concessionaireFiltredUsers: [],
      guidClientToSend: "",
      numClientToSend: "",
      mainAddressId: "",
      isFetching: false,
      commandList: [],
      characterCount: 0,
    };
  }

  componentDidMount = async () => {
    // Init dealer's user
    await this.loadDealerUsers();
    this.getCommandClientList();
  };

  // Method used to get the command client
  getCommandClientList = async () => {
    let selectedType = ["CO","RE"]; // 20231212 changement pour ["CO","RE"] //Toujours RE pour une garantie
    const { signIn } = this.props;
    this.setState({ commandList: [] });

    //Get command list by client
    const { commandList, isSuccess, isUnauthorized, errorMessage, errorDetail } =
      await fetchOrderClientAndType(signIn.clientEurekaNumber, selectedType);
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
    }
    this.setState({ commandList: isSuccess ? commandList : [] });
  };
  /**
   * Method used to update Warranty Piece table
   */
  updateWarrantyPieces = async (pieces) => {
    // Update local state
    await this.setState({ warrantyPieces: pieces });
    // Update parent state
    this.props.updateWarrantyState(this.state.warrantyPieces);
  };
  showWarrantyPieces = () => {
    const { commandList, typePriseEnCharge } = this.state;
    const { form, loadingClient } = this.props;
    if (typePriseEnCharge.length > 0) {
      return (
        <WarrantyPieces
          form={form}
          loadingClient={loadingClient}
          commandList={commandList}
          demandType={typePriseEnCharge}
          updateWarrantyPieces={(pieces) => this.updateWarrantyPieces(pieces)}
        />
      );
    }
  };
  // Method used to get dealer's user
  // Return an array of user
  loadDealerUsers = async () => {
    const { signIn, clientID } = this.props;
    const {
      items,
      isSuccess: isSuccessUserConcCli,
      isUnauthorized: isUnauthorizedUserConcCli,
      errorDetail: errorMessageUserConcCli,
    } = await fetchCustomers(signIn);

    if (!isSuccessUserConcCli) {
      notification.error({
        message: isUnauthorizedUserConcCli
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorizedUserConcCli
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorMessageUserConcCli,
      });
      return;
    }
    const guidInitClientConnected = items.filter(
      (concessionaireUser) => concessionaireUser.numeroClientEureka === clientID
    );
    const numClientToSend = guidInitClientConnected[0].numeroClientEureka;
    const guIdClientToSend = guidInitClientConnected[0].guIdClient;
    this.setState({
      concessionaireListeUsers: items,
      concessionaireFiltredUsers: guidInitClientConnected,
      numClientToSend,
      guIdClientToSend,
    });
    //  Init client addresses
    await this.loadAddresses(guIdClientToSend);
  };

  onChangeClient = async (key, value) => {
    const { concessionaireListeUsers } = this.state;
    const guidInitClientSelected = concessionaireListeUsers.filter(
      (concessionaireUser) => concessionaireUser.guIdClient === value.key
    );
    this.setState({
      concessionaireFiltredUsers: guidInitClientSelected,
      guidClientToSend: value.key,
      numClientToSend: key,
      isFetching: false,
    });
    await this.loadAddresses(value.key);
  };
  // Method load client addresses
  loadAddresses = async (clientGuid) => {
    const { guidClientToSend } = this.state;
    const { intl, defineMainAdresseId } = this.props;
    const clientGuidToSend = clientGuid ? clientGuid : guidClientToSend;
    if (clientGuidToSend) {
      const { addresses, isSuccess, isUnauthorized, errorMessage, errorDetail } =
        await getAddressCustomerById(clientGuidToSend);
      if (!isSuccess) {
        notification.error({
          message: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.title" })
            : intl.formatMessage({ id: "common.error" }),
          description: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.message" })
            : errorDetail,
        });
        return;
      }
      const addressesWhitoutNull = addresses.filter(
        (address) => address.line1 != null
      );
      const otherAddress = addressesWhitoutNull.filter(
        (address) => address.addressTypeCode === 4
      );
      const billingAddress = addressesWhitoutNull.filter(
        (address) => address.addressTypeCode === 1
      );
      const deliveryAddress = addressesWhitoutNull.filter(
        (address) => address.addressTypeCode === 2
      );
      const mainAddress = addresses.filter(
        (address) => address.addressTypeCode === 3
      );
      for (let i = 0; i < addresses.length; i++) {
        if (addresses[i].addressTypeCode === 1) {
          addresses[i].addressTypeLabel = intl.formatMessage({
            id: "client.address.billing",
          });
        } else if (addresses[i].addressTypeCode === 2) {
          addresses[i].addressTypeLabel = intl.formatMessage({
            id: "client.address.delevery",
          });
        } else if (addresses[i].addressTypeCode === 3) {
          addresses[i].addressTypeLabel = intl.formatMessage({
            id: "client.address.main",
          });
        } else {
          addresses[i].addressTypeLabel = intl.formatMessage({
            id: "client.address.orderOther",
          });
        }
      }
      const addresseSortWhitoutNull = [
        ...mainAddress,
        ...deliveryAddress,
        ...billingAddress,
        ...otherAddress,
      ];

      const mainAddresstoStore =
        mainAddress.length > 0 ? mainAddress[0].customerAddressId : "";

      await this.setState({
        addresses: addresseSortWhitoutNull,
        mainAddressId: mainAddresstoStore,
      });
      defineMainAdresseId(mainAddresstoStore);
    }
  };
  // Method used to get dealer's user
  // Return an array of user
  onSearch = async (value) => {
    this.setState({
      isFetching: value.length !== 0,
      concessionaireFiltredUsers: [],
    });

    if (value) {
      const {
        items: concessionaireFiltredUsers,
        isSuccess,
        isUnauthorized,
        errorMessage,
        errorDetail
      } = await fetchCustomers({ nom_client: value });

      if (!isSuccess) {
        notification.error({
          message: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.title" })
            : intl.formatMessage({ id: "common.error" }),
          description: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.message" })
            : errorDetail,
        });
      }
      const guidListe = concessionaireFiltredUsers.filter(
        (concessionaireUser) => concessionaireUser.guIdClient != null
      );
      this.setState({
        concessionaireFiltredUsers: guidListe,
        isFetching: false,
      });
      return;
    }
  };

  onChangeDevice = (value) => {
    const { machines, disableForm } = this.props;
    const device = machines.find((machine) => machine.clefUnicite === value);
    const dateWarrantyDevice = device.lotValidationDate
      ? device.lotValidationDate
      : null;
    disableForm(dateWarrantyDevice);
    this.setState({
      deviceGarentee: device.lotValidationDate,
      dateWarrantyDevice,
      selectedDevice: value ? true : false,
    });
  };

  onSelectClientCall = (value) => {
    const { clearFormFields, onChangeClients } = this.props;
    clearFormFields(["clefUniciteMachine"], null);
    onChangeClients(value);
  };
  onChangeDemandeType = (value) => {
    this.setState({ demandType: value });
    const { updateBonN1, updateBonN2, updateWarrantyState } = this.props;
    updateBonN1(null);
    updateBonN2(null);
    updateWarrantyState({});
  };

  characterCountState = (value) => {
    this.setState({ characterCount: value });
  };

  render = () => {
    const {
      addresses,
      characterCount,
      concessionaireFiltredUsers,
      dateWarrantyDevice,
      demandType,
      numClientToSend,
      showHelp,
      typePriseEnCharge,
      isFetching,
      mainAddressId,
    } = this.state;
    const { getFieldDecorator } = this.props.form;
    const {
      bonN1,
      bonN2,
      clients,
      intl,
      loadingClient,
      loadingDevice,
      machines,
      selectedClientConcessionnaire,
      userRole,
      updateBonN1,
      updateBonN2,
      onSearchDevice,
    } = this.props;
    return (
      <Fragment>
        {/* Help Button */}
        <div className="aligne-help-required">
          <Button
            type="primary"
            style={{ marginBottom: "1em" }}
            onClick={() => {
              this.setState({ showHelp: true });
            }}
          >
            <Icon type="info-circle" />
            <FormattedMessage id="contact.form.help" />
          </Button>
          {/* Legend */}
          <RequiredFieldLegend />
        </div>
        {/* Help Modal */}
        {showHelp && (
          <Modal
            title={<FormattedMessage id="contact.form.help" />}
            visible={showHelp}
            onCancel={() => this.setState({ showHelp: false })}
            bodyStyle={{
              overflowY: "hidden",
              height: "50vh",
              position: "relative",
            }}
            footer={null}
            width="80%"
          >
            <HtmlIncluder
              fileName="help"
              folder="contact/warranty"
              isModalContent={true}
            />
          </Modal>
        )}
        <div className="select-warranty-devices">
          <FormItem
            label={<FormattedMessage id="contact.form.demandType.title" />}
          >
            {getFieldDecorator("demandType", {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage id="contact.form.demandType.required" />
                  ),
                },
              ],
            })(
              <Select onChange={(value) => this.onChangeDemandeType(value)}>
                <Option key="demandType.equipment" value="equipment">
                  <FormattedMessage id="common.yes" />
                </Option>
                <Option key="demandType.parts" value="parts">
                  <FormattedMessage id="common.no" />
                </Option>
              </Select>
            )}
          </FormItem>
          {isGroupConcessionnaire({ userRole }) && (
            /* if concessionnaire, select client */
            <FormItem
              label={
                <FormattedMessage id="contact.form.selectedClientConcessionnaire" />
              }
            >
              {getFieldDecorator("numeroClientEureka")(
                <Select
                  notFoundContent={
                    loadingClient ? (
                      <Spin size="small" />
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )
                  }
                  onSelect={(value) => this.onSelectClientCall(value)}
                  showSearch
                  optionFilterProp="children"
                  //   filterOption={(input, option) =>
                  //     option.props.children
                  //       .toString()
                  //       .toLowerCase()
                  //       .indexOf(input.toLowerCase()) >= 0 ||
                  //     option.props.value
                  //       .toString()
                  //       .toLowerCase()
                  //       .indexOf(input.toLowerCase()) >= 0
                  //   }
                  // >
                  //   {clients.map((client) => (
                  //     <Option
                  //       key={client.guIdClient}
                  //       value={client.numeroClientEureka}
                  //     >
                  //       {client.nomClient} ({client.numeroClientEureka})
                  //     </Option>
                  filterOption={(input, option) => {
                    return (
                      option.props.title
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 ||
                      option.props.value
                        .toString()
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                >
                  {clients
                    .filter((c) => c.numeroClientEureka !== null)
                    .map((client) => (
                      <Option
                        key={client.guIdClient}
                        title={client.nomClient}
                        value={client.numeroClientEureka}
                      >
                        {client.nomClient} ({client.numeroClientEureka})
                      </Option>
                    ))}
                </Select>
              )}
            </FormItem>
          )}
          {/* Select device */}
          <FormItem
            label={<FormattedMessage id="contact.form.selectDevices" />}
          >
            {getFieldDecorator("clefUniciteMachine", {
              rules: [
                {
                  required: true,
                  message: (
                    <FormattedMessage id="contact.form.selectDevicesRequired" />
                  ),
                },
              ],
            })(
              <Select
                disabled={
                  isGroupConcessionnaire({ userRole })
                    ? selectedClientConcessionnaire
                      ? false
                      : true
                    : false
                }
                notFoundContent={
                  loadingDevice ? (
                    <Spin size="small" />
                  ) : (
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                  )
                }
                onChange={(value) => this.onChangeDevice(value)}
                onSearch={debounce(onSearchDevice, 600)}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.props.children
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.value
                    .toString()
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {machines &&
                  machines.map((machine) => (
                    <Option
                      key={machine.clefUnicite}
                      value={machine.clefUnicite}
                    >
                      {machine.nameDevice
                        ? machine.nameDevice
                        : machine.libelleArticle}{" "}
                      - {machine.numeroLot}{" "}
                      {machine.serialIot == "NA"
                        ? ""
                        : " - " + machine.serialIot}
                    </Option>
                  ))}
              </Select>
            )}
          </FormItem>
          {dateWarrantyDevice == null ? (
            <span style={{ color: "red", fontSize: "14px" }}>
              <FormattedMessage id="contact.form.noWarrantyDevice" />{" "}
            </span>
          ) : (
            <Fragment>
              {/* /*TODO A appliquer pour les garenties */
              /* {dateWarrantyDevice && (dateWarrantyDevice ?  
                    <span className="selectContactWarranty"> Mise en service le : {deviceGarentee} , si cette date n'est pas bonne, merci de nous contacter" </span>
                    :  <span className="selectContactWarranty"> la mise en service n'as jamais été déclaré </span> )} */}

              {/* Date defaillance */}

              <FormItem
                label={<FormattedMessage id="contact.form.dateDefaillance" />}
              >
                {getFieldDecorator("dateDefaillance", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage
                          id="contact.form.dateDefaillanceRequired" //traduction
                        />
                      ),
                    },
                  ],
                })(
                  <DatePicker
                    disabledDate={(d) => !d || d.isAfter(new Date())}
                    format="DD/MM/YY"
                  />
                )}
              </FormItem>

              {/* Prise en charge */}

              <FormItem
                label={<FormattedMessage id="contact.form.typePriseEnCharge" />}
              >
                {getFieldDecorator("typePriseEnCharge", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="contact.form.requestType.required" />
                      ),
                    },
                  ],
                })(
                  <Select
                    onChange={(value) =>
                      this.setState({ typePriseEnCharge: value })
                    }
                  >
                    <Option key="supportedType.repaymentBill" value="100000000">
                      <FormattedMessage id="contact.form.supportedType.repaymentBill" />
                    </Option>
                    <Option
                      key="supportedType.replacementParts"
                      value="100000001"
                    >
                      <FormattedMessage id="contact.form.supportedType.replacementParts" />
                    </Option>
                  </Select>
                )}
              </FormItem>
              {/* Dans le cas d'un équipement  => Bonn1 */}

              {demandType == "equipment" && (
                <FormItem
                  label={<FormattedMessage id="contact.form.bonN1e" />}
                  className="file-upload-form-item"
                >
                  {getFieldDecorator("new_bonN1", {
                    rules: [
                      {
                        required: true,
                        message: (
                          <FormattedMessage id="contact.form.bonN1e.required" />
                        ),
                      },
                    ],
                  })(
                    <Dropzone
                      className="dropzone-bonn"
                      name="files"
                      onDrop={(value) => updateBonN1(value)}
                    >
                      <Fragment>
                        {bonN1 ? (
                          <div className="file-container">
                            <button
                              type="button"
                              className="file-name-container"
                              onClick={() => updateBonN1(null)}
                            >
                              <span className="file-name">{bonN1[0].name}</span>
                              <span className="file-delete">
                                <FontAwesomeIcon icon="times-circle" />
                              </span>
                            </button>
                          </div>
                        ) : null}
                      </Fragment>
                    </Dropzone>
                  )}
                </FormItem>
              )}

              {/* Dans le cas d'une piéce => bonn1 et bonn2 */}

              {demandType == "parts" && (
                <Fragment>
                  <FormItem
                    label={<FormattedMessage id="contact.form.bonN1ps" />}
                    className="file-upload-form-item"
                  >
                    {getFieldDecorator("new_bonN1", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="contact.form.bonN1ps.required" />
                          ),
                        },
                      ],
                    })(
                      <Dropzone
                        className="dropzone-bonn"
                        name="files"
                        onDrop={(value) => updateBonN1(value)}
                      >
                        <Fragment>
                          {bonN1 ? (
                            <div className="file-container">
                              <button
                                type="button"
                                className="file-name-container"
                                onClick={() => updateBonN1(null)}
                              >
                                <span className="file-name">
                                  {bonN1[0].name}
                                </span>
                                <span className="file-delete">
                                  <FontAwesomeIcon icon="times-circle" />
                                </span>
                              </button>
                            </div>
                          ) : null}
                        </Fragment>
                      </Dropzone>
                    )}
                  </FormItem>
                  <FormItem
                    label={<FormattedMessage id="contact.form.bonN2ps" />}
                    className="file-upload-form-item"
                  >
                    {getFieldDecorator("new_bonN2", {
                      rules: [
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="contact.form.bonN2ps.required" />
                          ),
                        },
                      ],
                    })(
                      <Dropzone
                        className="dropzone-bonn"
                        name="files"
                        onDrop={(value) => updateBonN2(value)}
                      >
                        <Fragment>
                          {bonN2 ? (
                            <div className="file-container">
                              <button
                                type="button"
                                className="file-name-container"
                                onClick={() => updateBonN2(bonN2)}
                              >
                                <span className="file-name">
                                  {bonN2[0].name}
                                </span>
                                <span className="file-delete">
                                  <FontAwesomeIcon icon="times-circle" />
                                </span>
                              </button>
                            </div>
                          ) : null}
                        </Fragment>
                      </Dropzone>
                    )}
                  </FormItem>
                </Fragment>
              )}

              {typePriseEnCharge == "100000001" && (
                <Fragment>
                  <span style={{ color: "red", fontSize: "14px" }}>
                    <FormattedMessage id="contact.form.noWarrantyExp" />{" "}
                  </span>

                  {/* Reference Client */}

                  <FormItem
                    label={
                      <FormattedMessage id="contact.form.referenceClient" />
                    }
                  >
                    {getFieldDecorator("referenceClient")(
                      <Input style={{ width: "42%" }} />
                    )}
                  </FormItem>

                  {/* Commentaires livraison */}

                  <FormItem
                    label={
                      <Fragment>
                        <FormattedMessage id="contact.form.commentaireErp" />(
                        {characterCount} / 70)
                      </Fragment>
                    }
                  >
                    {getFieldDecorator("commentaireERP", {
                      rules: [
                        {
                          max: 70,
                          message: intl.formatMessage(
                            { id: "user.form.commentMaxCarac" },
                            { max: 70 }
                          ),
                        },
                        {
                          pattern: new RegExp(REGEX_ORDER_REF),
                          message: (
                            <FormattedMessage
                              id="form.validator.invalidOrderRef"
                              defaultMessage="Only numbers, letters from a to z as well as the - and space are allowed"
                            />
                          ),
                        },
                      ],
                    })(
                      <Input
                        style={{ width: "42%" }}
                        onChange={(e) =>
                          this.characterCountState(e.target.value.length)
                        }
                        maxLength={70}
                      />
                    )}
                  </FormItem>

                  {/* Date expedition souhaitee */}

                  <FormItem
                    label={
                      <FormattedMessage id="contact.form.dateExpeditionSouhaitee" />
                    }
                  >
                    {getFieldDecorator("dateExpeditionSouhaitee")(
                      <DatePicker
                        disabledDate={(d) => d.isBefore(new Date())}
                        format="DD/MM/YY"
                      />
                    )}
                  </FormItem>
                  {isGroupConcessionnaire({ userRole }) && (
                    <FormItem
                      label={
                        <FormattedMessage id="order.modal.customerSelect" />
                      }
                    >
                      {getFieldDecorator("numeroClientLivraisonEureka", {
                        initialValue: numClientToSend,
                        rules: [
                          {
                            required: true,
                            message: (
                              <FormattedMessage id="user.form.customerSelectRequired" />
                            ),
                          },
                        ],
                      })(
                        <Select
                          style={{ width: "100%" }}
                          showSearch
                          notFoundContent={
                            isFetching ? <Spin size="small" /> : null
                          }
                          onSearch={debounce(this.onSearch, 600)}
                          filterOption={false}
                          onChange={(key, value) =>
                            this.onChangeClient(key, value)
                          }
                        >
                          {concessionaireFiltredUsers.map(
                            (concessionaireUser) => (
                              <Option
                                key={concessionaireUser.guIdClient}
                                value={concessionaireUser.numeroClientEureka}
                              >
                                {concessionaireUser.nomClient} (
                                {concessionaireUser.numeroClientEureka})
                              </Option>
                            )
                          )}
                        </Select>
                      )}
                    </FormItem>
                  )}

                  <FormItem
                    label={
                      <FormattedMessage id="order.modal.deliveryAddresse" />
                    }
                  >
                    {getFieldDecorator("adresseDynamicsGuid", {
                      initialValue: mainAddressId,
                      rules: [
                        {
                          required: true,
                          message: (
                            <FormattedMessage id="user.form.deliveryAddresseRequired" />
                          ),
                        },
                      ],
                    })(
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        optionFilterProp="children"
                        filterOption={(input, option) =>
                          option.props.children
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0 ||
                          option.props.value
                            .toString()
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {addresses.map((deliveryAddresses) => (
                          <Option
                            key={deliveryAddresses.customerAddressId}
                            value={deliveryAddresses.customerAddressId}
                          >
                            {deliveryAddresses.addressTypeLabel}{" "}
                            {deliveryAddresses.name
                              ? " - " + deliveryAddresses.name + " - "
                              : ""}
                            ({deliveryAddresses.line1 + " "}
                            {deliveryAddresses.line2
                              ? "," + deliveryAddresses.line2 + " "
                              : ""}
                            {deliveryAddresses.line3
                              ? "," + deliveryAddresses.line3 + " "
                              : ""}
                            - {deliveryAddresses.city} )
                          </Option>
                        ))}
                      </Select>
                    )}
                  </FormItem>
                </Fragment>
              )}

              {this.showWarrantyPieces()}
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  };
}

ContactWarranty.propTypes = {
  bonN1: PropTypes.array,
  bonN2: PropTypes.array,
  clearFormFields: PropTypes.func.isRequired,
  clients: PropTypes.array.isRequired,
  selectedClientConcessionnaire: PropTypes.string,
  form: PropTypes.shape({
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
  loadingClient: PropTypes.bool.isRequired,
  loadingDevice: PropTypes.bool.isRequired,
  machines: PropTypes.array.isRequired,
  onChangeClients: PropTypes.func,
  intl: intlShape.isRequired,
  updateWarrantyState: PropTypes.func.isRequired,
  updateBonN1: PropTypes.func,
  updateBonN2: PropTypes.func,
  disableForm: PropTypes.func,
  onSearchDevice: PropTypes.func,
  defineMainAdresseId: PropTypes.func,
};

const mapStateToProps = ({
  app: { locale },
  signIn: { userRole, userID, clientID },
  signIn,
}) => ({
  locale,
  signIn,
  userRole,
  userID,
  clientID,
});

export default connect(mapStateToProps)(injectIntl(ContactWarranty));
