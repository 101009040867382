import PropTypes from 'prop-types';
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { notification, Switch, Spin, Modal } from 'antd';
import { updateClient } from '../../utils/apiBucherVaslin';
import { isGroupAdminClient, isSuperAdmin, isInfoCompleted } from '../../utils/helpers';


class CustomerSettings extends Component {

    state = {
        isDelegateConcessionnaire: false,
        isDelegateRR: false,
        isLoadData: false,
        onValueChanged: false,
        pendingSettingChange: {
            settingField: '',
            settingValue: null,
        },
        showSettingModal: false,
    };

    async componentDidMount() {
        const { initialValues: { isDelegateConcessionnaire, isDelegateRR}, intl, userID } = this.props;
        /* check connexion and information */ 
        await isInfoCompleted(intl, userID);       
        this.setState({ isDelegateConcessionnaire, isDelegateRR, isLoadData: true });
    }

    componentDidUpdate() {
        const { onValueChanged } = this.state;

        if (onValueChanged) {
            this.updateSettingsCustomer();
            this.setState({ onValueChanged: false });
        }
    }

    updateSettingsCustomer = () =>  {
        const { initialValues, intl } = this.props;
        const { isDelegateConcessionnaire, isDelegateRR } = this.state;
        updateClient({
            ...initialValues,
            isDelegateConcessionnaire,
            isDelegateRR,
        }).then(({ isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
            if (isSuccess) {
                notification.success({
                    message: intl.formatMessage({ id: 'client.edit.notification.title' }),
                    description: intl.formatMessage({ id: 'client.edit.notification.description' }),
                });
            }
            else {
                notification.error({
                    message: isUnauthorized
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
                });
            }
        });
    }

    // Method triggered whenether a setting switch is changed
    changeSwitchSettings = (settingField, settingValue) => {
        // If switch is turn on, we need to ask for confirmation
        if (settingValue) {
            // Store a pending value for pendingSettingChange
            const pendingSettingChange = {
                settingField,
                settingValue,
            };
            this.showModal(pendingSettingChange);
        } else {
            if (settingField === 'concessionnaire') {
                this.setState({isDelegateConcessionnaire: settingValue, onValueChanged: true});
            } else if (settingField === 'RR') {
                this.setState({isDelegateRR: settingValue, onValueChanged: true});
            }
        }
    };

    // Methode déclanché en cas de modification de l'autorisation de licence
    changeLicenceManagement() {
        const { settingField, settingValue } = this.state.pendingSettingChange;

        if (settingField === 'concessionnaire') {
            this.setState({isDelegateConcessionnaire: settingValue, onValueChanged: true});
        } else if (settingField === 'RR') {
            this.setState({isDelegateRR: settingValue, onValueChanged: true});
        }

        this.hideModal()
    }

    // Show Modal
    showModal(pendingSettingChange) {
        this.setState({showSettingModal: true, pendingSettingChange});
    }

    // Hide Modal
    hideModal() {
        this.setState({
            showSettingModal: false, 
            pendingSettingChange: {
                settingField: '',
                settingValue: null,
            }
        });
    }

    render() {
        const { loggedIn, userRole } = this.props;
        const { isDelegateConcessionnaire, isDelegateRR, isLoadData, showSettingModal, pendingSettingChange } = this.state;

        if (!isLoadData || !loggedIn) {
            return (
                <Spin />
            );
        }

        return ( 
            <Fragment>
                <Fragment>
                    <div className="alert-switches">
                        <Switch 
                            checked={isDelegateConcessionnaire}
                            disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))}
                            onChange={(value) =>  this.changeSwitchSettings('concessionnaire', value)}
                            className="alert-switch"
                        />
                        <FormattedMessage id="user.form.settings.delegateConcessionnaire"
                            defaultMessage="Authorized dealer to manage licenses"
                        />
                    </div>
                </Fragment>
                <Fragment>
                    <div className="alert-switches">
                        <Switch 
                            checked={isDelegateRR}
                            disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))}
                            onChange={(value) =>  this.changeSwitchSettings('RR', value)}
                            className="alert-switch"
                        />
                        <FormattedMessage id="user.form.settings.delegateRR"
                            defaultMessage="Authorized Sales Manager to manage licenses"
                        />
                    </div>
                </Fragment>
            
                {/* Modal for user settings */}
                <Modal
                    title={<FormattedMessage id={pendingSettingChange.settingField==='RR' ? 
                        'user.form.settings.delegateRR' : 
                        'user.form.settings.delegateConcessionnaire'
                    } />}
                    visible={showSettingModal}
                    okText={<FormattedMessage id="common.yes" />}
                    cancelText={<FormattedMessage id="common.no" />}
                    onOk={() => this.changeLicenceManagement()}
                    onCancel={() => this.hideModal()}
                    bodyStyle={{
                        overflowY: 'auto',
                        minHeight: '150px',
                        position: 'relative',
                        maxHeight: 'calc(100vh - 250px)'
                    }}
                    width="70%"
                >
                    <Fragment>
                        <FormattedMessage id="user.form.settings.allowLicenceManagement"/>
                    </Fragment>
                </Modal>
            </Fragment>
        );
    }
}

CustomerSettings.defaultProps = {
    initialValues: {},
}

CustomerSettings.propTypes = {
    initialValues: PropTypes.shape({}),
    intl: intlShape.isRequired,
    loggedIn: PropTypes.bool,
}

const mapStateToProps = ({ signIn: { loggedIn, userRole, userID },
}) => ({ loggedIn, userRole, userID });

export default connect(mapStateToProps)(injectIntl(CustomerSettings));