import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

// Component used to formate amount value
class FormattedCurrency extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formattedValue: this.getParsedValue()
        };
    };

    componentDidUpdate() {
        const {formattedValue} = this.state;
        const parsedValue = this.getParsedValue();

        // Check if the component need to update the value
        if (parsedValue !== formattedValue) {
            this.setState({
                formattedValue: parsedValue
            });
        }
    }

    getParsedValue = () => {
        const { amount, currency } = this.props;
        let { locale } = this.props;

        // If local is undefined, we set it to fr-FR
        if ( !this.props.locale ) {
            locale = 'fr-FR';
        } 

        // return parsed value
        return new Intl.NumberFormat(locale, { style: 'currency', currency: currency }).format(amount);
    }

    render() {
        const { formattedValue } = this.state;

        return (
            <span className="deliveryFee">{formattedValue}</span>
        );
    }
}

FormattedCurrency.propTypes = {
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    locale: PropTypes.string,
}

const mapStateToProps = ({
    app: {
        locale,
    },
}) => ({ locale });

export default connect(mapStateToProps)(injectIntl(FormattedCurrency));