import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl } from 'react-intl';
import { notification, Spin } from 'antd';
import { getHistoricGraphData } from '../../utils/apiBucherVaslin';
import PageLayout from '../Layout/PageLayout';
import LinearGraphContainer from '../Graph/LinearGraph/LinearGraphContainer';
import moment from 'moment';

const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

class CycleGraph extends Component {
    constructor(props){
        super(props);
        this.state = {
            deviceFamily: null,
            graphDataset: [],
            loadGraphData: false,
            numberLine: 50,
            selectedCycle: null,
        };
    }

    async componentDidMount() {
        const {
            location: {
                state: { 
                    cycle,
                    deviceFamily,
                },
            }
        } = this.props;

        // Set family / cycle to dataSet
        await this.setState({ deviceFamily, selectedCycle: cycle });

        // Load graph data
        await this.loadGraphData();
    }


    /**
     * Method used to convert histo data into a proper dataset value for LinearGraphComponent
     */
    convertGraphData = async (histoGraph) => {
        const parsedData = [];

        histoGraph.forEach((histoValue, indexHisto) => {
            // On first index, initiate parsedData arrays
            if (indexHisto === 0) {
                // Initiate Date and Time metrics
                parsedData.push(['Date']);
                parsedData.push(['Heure']);

                // Initiate other metrics
                histoValue.metricWithValueList.forEach((metric) => {
                    parsedData.push([metric.field]);
                });
            }

            // Add values inside day and time array
            const day = moment(histoValue.recordDate, dateFormat).format("DD-MM-YYYY");
            const time = moment(histoValue.recordDate, dateFormat).format("HH:mm:ss");
            parsedData[0].push(day);
            parsedData[1].push(time);

            // Add values inside parsedData array
            const customFieldCount = 2;
            histoValue.metricWithValueList.forEach((metric, metricIndex) => {
                parsedData[metricIndex + customFieldCount].push(metric.value);
            });
        });

        return parsedData;
    }


    /**
     * Method called to get historic graph data
     */
    loadGraphData = async () => {
        const { selectedCycle } = this.state;
        const {
            match: {
                params: { serial },
            },
            intl,
        } = this.props;

        // Set graph loading in progress
        this.setState({graphDataset: [], loadGraphData: false});

        // Get histo graph values
        const { histoGraph, isSuccess, isUnauthorized, errorMessage, errorDetail} = await getHistoricGraphData(serial, selectedCycle);
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }

        // Get a converted value of historic graph values
        const convertedGraphData = await this.convertGraphData(histoGraph);

        // Stop graph loading and update dataset
        this.setState({graphDataset: convertedGraphData, loadGraphData: true});
    }

    render () {
        const { deviceFamily, graphDataset, loadGraphData } = this.state;
        const { history } = this.props;

        return (
            <PageLayout pageKey="cycleGraph" history={history}>
                <div>
                    { (!loadGraphData) ? (
                        <Spin size="default" style={{marginLeft: '1em'}}/>
                    ) : (
                        // Graph Component
                        <div>
                            {(graphDataset && graphDataset.length > 0) && (
                                <LinearGraphContainer 
                                    excludedMetrics={['date', 'heure', 'timestamp']}
                                    jsonMetrics={graphDataset}
                                    source={deviceFamily}
                                    xAxis={'timestamp'}
                                />
                            )}
                        </div>
                    )}
                </div>
            </PageLayout>
        );
    }
}

CycleGraph.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            serial: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            cycle: PropTypes.string.isRequired,
            deviceFamily: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
}

const mapStateToProps = ({}) => ({

});

export default injectIntl(connect(mapStateToProps)(CycleGraph));