import { Button, Divider, notification , message, Form, InputNumber, Modal, Select} from 'antd';
import moment from 'moment';
import React, {Component, Fragment} from 'react';
import {  
    fetchPDRByCodeParent,
    fetchOrdersForClient,
    fetchCurrentCustomerDetails, 
    fetchOrder,
    fetchDeliveryDeadlines,
    fetchOrderType,  
    addLineToOrder , 
    updateLineInOrder} from '../../utils/apiBucherVaslin';
import PropTypes from 'prop-types';
import {
    isGroupEBV
} from '../../utils/helpers';
import { connect } from 'react-redux';
import {  injectIntl, intlShape, FormattedMessage } from 'react-intl';
import CreateOrder from '../Orders/CreateOrder';
import TablePDRAffichage from './TablePDRAffichage';
import './Catalog.less';

const { Option } = Select;

class TablePDRCatalog extends Component {

    constructor(props) {
        super(props);
        this.state = {
            pdrDocument: [],
            selectedRowKeys: [],
            codeParentState: props.codeParent,
            codeDocumentState: props.codeDocument,
            ordersDraft: [],
            orderLine: [],
            visible: false,
            selectedOrder: null,
            clientInfo: null,
            loading: false,
            sawOnlyPrixPublic: true
        };
    }
    componentDidMount() {
        fetchCurrentCustomerDetails().then(({ clientInfo }) => {
            this.setState({
                clientInfo
            });
        });
        this.fetchOrdersClient();  
    }    

    componentWillUpdate() {
        const { codeParentState, codeDocumentState } = this.state;
        const { codeParent, codeDocument } = this.props;
        // Reload PDR list when parent component trigger a change event
        if(codeParent !== codeParentState || codeDocument !== codeDocumentState) {
            this.setState({ codeParentState: codeParent, codeDocumentState: codeDocument ,  pdrDocument: []}); 
        }    
    }

    onCancel() {
        this.setState({ visible: false, sawOnlyPrixPublic: true, selectedOrder: null });
    } 
    onOk() {
        this.setState({ visible: false });
        this.fetchArticleOrder();
    }
    onOrderChanged(value) {
        this.setState({ selectedOrder: value });
        this.fetchPDRByCodeParent(value);
    }

    fetchOrdersClient = async () => {
        const { clientID, intl } = this.props;
        const {items, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchOrdersForClient(clientID)();
        if (isSuccess) {
            var ordersDraft = items.filter(x => { 
                 return x.statutCommand === "Draft";
             })
         }
         else {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
        }
        await this.setState({ ordersDraft });

        await this.initOrderType();  

        await this.initDeliveryDeadlines();  
    }

    initOrderType = async () => {
        const { intl } = this.props;
        const { ordersDraft } = this.state;
        // Charge la liste des délais de livraison avec le libellé dans la langue de l'utilisateur
        const { orderType, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchOrderType();
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
            return;
        }
        for (let i = 0; i < ordersDraft.length; i++) { 
            const orderTypeToGet =  orderType.filter((orderTypes) => orderTypes.id == ordersDraft[i].typeCommandeId); 
            ordersDraft[i].libelleTypeCommande = orderTypeToGet[0].libelleTypeByLangUser;
        } 
        this.setState({ordersDraft});
    }

    initDeliveryDeadlines = async () => {
        const { intl } = this.props;
        const { ordersDraft } = this.state;
        // Charge la liste des délais de livraison avec le libellé dans la langue de l'utilisateur
        const { deliveryDeadlines, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchDeliveryDeadlines();
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
            return;
        }
        for (let i = 0; i < ordersDraft.length; i++) { 
            const orderTypeToGet = deliveryDeadlines.filter((deliveryDeadline) => deliveryDeadline.id == ordersDraft[i].delaiLivraisonId); 
            if(orderTypeToGet[0]) {
                ordersDraft[i].libelleDeadlines = orderTypeToGet[0].libelleByLangUser;
            }             
        } 
        this.setState({ordersDraft});
    }

    getCommandeAndCreateLine = () => {
        const { ordersDraft } = this.state;
        var selectedOrder =  ordersDraft.length > 0? ordersDraft[0].numCommande : '';
        this.setState({sawOnlyPrixPublic: false, visible:true, selectedOrder });
        this.fetchPDRByCodeParent(selectedOrder);
    }
    getCommandePrixPublic = () => {
        this.setState({ visible: true });
        this.fetchPDRByCodeParent(null);
    }

    fetchPDRByCodeParent = async(selectOrder) => {
        const { intl, codeDocument, codeParent, niveau } = this.props;
        var marque,gamme,capacite,modele;
        marque = gamme = capacite = modele = false;
        if(niveau == 1) {
            marque = true;
        } else if(niveau == 2) {
            gamme = true;
        } else if (niveau == 3) {
            capacite = true;
        } else {
            modele = true;
        }
        await this.setState({ loading: true });
        let { isSuccess, ebvPDRs, isUnauthorized, errorMessage, errorDetail } = await fetchPDRByCodeParent(codeDocument, codeParent, marque, gamme, capacite, modele, selectOrder);
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
            await this.setState({ loading: false });
            return;
        }
        await this.setState({ pdrDocument: ebvPDRs, loading: false, pdrDocumentLoaded : false  });      
}

    async fetchArticleOrder() {
        const {intl} = this.props;
        const {selectedOrder} = this.state;
        const { items, isSuccess: isSuccessOrder, isUnauthorized, errorMessage, errorDetail } = await fetchOrder(selectedOrder)();
    
        if (!isSuccessOrder){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
        }
        this.setState({ orderLine: items.lignes});
        this.createCommande();
    }

    async createCommande() {
        const { pdrDocument, orderLine , selectedOrder } = this.state;
        const { intl } = this.props;
        for (let i = 0; i < pdrDocument.length; i++) {
            const quantiteArticle = this.props.form.getFieldValue('quantity_' + pdrDocument[i].codeArticle);
            if(quantiteArticle > 0) {            
                    const acctualCodeArticle = pdrDocument[i].article.codeArticle;
                //     const existingArticle = orderLine.find(article => article.codeArticle === acctualCodeArticle);
                //     if (existingArticle) {
                    //         const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await updateLineInOrder(selectedOrder, existingArticle, quantiteArticle);
                    //         if (!isSuccess){
                        //             notification.error({
                            //                 message: isUnauthorized 
                            //                     ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            //                     : intl.formatMessage({ id: 'common.error' }),
                            //                 description: isUnauthorized 
                            //                     ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            //                     : errorDetail
                            //             });                  
                            //         } else {             
                                //             message.success(
                                    //                 intl.formatMessage(
                                        //                     {
                                            //                         id: 'order.article.update'
                                            //                     },
                                            //                     { selectedOrder, acctualCodeArticle }
                                            //                 ),
                                            //                 4
                                            //             );
                                            //         }
                                            //     } else {
                    if(pdrDocument[i].article) {
                        const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await addLineToOrder(selectedOrder, pdrDocument[i].article, quantiteArticle);
                        if (!isSuccess) {
                            notification.error({
                                message: isUnauthorized 
                                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                                    : intl.formatMessage({ id: 'common.error' }),
                                description: isUnauthorized 
                                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                                    : errorDetail
                            });
                        }
                        else { 
                            message.success(
                                intl.formatMessage(
                                    {
                                        id: 'order.article.add'
                                    },
                                    { selectedOrder, acctualCodeArticle }
                                ),
                                4
                            );
                        }
                    }
            //    }
            }     
        }
    }

    updateQuantity = (rawId) => {       
        const {getFieldDecorator} = this.props.form;  
        return (
            getFieldDecorator('quantity_' + rawId,
            {
                initialValue: 0
            })(
                <InputNumber className="inputPDRQuantity" min={0} />
            ));
    } 

    render = () => {
        const{ pdrDocument, ordersDraft, clientInfo, visible, selectedOrder, loading, sawOnlyPrixPublic } = this.state;
        const{ userRole } = this.props;
        return(
            <Fragment>
                  <Modal
                    title={<FormattedMessage id="catalog.tablePdr.modal.title" />} 
                    visible={visible}
                    style={{ marginLeft: 0, top: 0 }}
                    onCancel={() => this.onCancel()}
                    onOk={() => this.onOk()}
                    footer={[
                        <Button key="back" onClick={() => this.onCancel()}>
                            <FormattedMessage id="common.cancel" />
                        </Button>,
                        <Button key="submit" type="danger" disabled={sawOnlyPrixPublic} onClick={() => this.onOk()}>
                            <FormattedMessage id="catalog.tablePdr.modal.addPiece" />
                        </Button>
                    ]}
                    width="45%"
                    bodyStyle={{height: "88vh"}}
                >             
                    <div>                         
                    {isGroupEBV({userRole}) ? 
                        (sawOnlyPrixPublic ? 
                        <Button onClick={() => this.getCommandeAndCreateLine()}><FormattedMessage id="catalog.tablePdr.modal.order" /></Button>    
                        :        
                        (ordersDraft.length > 0 ?                  
                        <Fragment>
                            <span id="select-category-label"><FormattedMessage id="catalog.tablePdr.modal.order" />:</span>
                            <Select
                                style={{ width: '100%'}}
                                onChange={(value) => this.onOrderChanged(value)}
                                defaultValue={selectedOrder}
                            >
                                {ordersDraft && ordersDraft.map((order) => (
                                    <Option
                                        key={order.idCommande}
                                        value={order.numCommande} 
                                    >
                                        {order.numCommande} ( 
                                            {order.libelleTypeCommande}{" - " + order.libelleDeadlines + " - "} 
                                            {(moment(order.dateCreation).format('DD/MM/YYYY'))+ " - "}
                                            {order.userCreated.nomPrenom}
                                            {order.numCommandCustomer ?  " - " +  order.numCommandCustomer : ''}
                                        )
                                    </Option>
                                ))}
                        </Select>
                        </Fragment> 
                        : 
                        <CreateOrder refresh={this.fetchOrdersClient}  client={clientInfo}/>)       
                        ) : ""}
                        <Divider /> 
                        <TablePDRAffichage 
                            pdrDocument={pdrDocument} 
                            sawOnlyPrixPublic={sawOnlyPrixPublic} 
                            loading={loading} 
                            updateQuantity={(rawId) => this.updateQuantity(rawId)}/>   
                    </div>
                  
                </Modal>
                <span className="actions">
                    <Button htmlType="button" onClick={() => this.getCommandePrixPublic()}><FormattedMessage id="catalog.tablePdr.button.consultOrder" /></Button>
                </span>        
        </Fragment>
        )
    }
}

TablePDRCatalog.propTypes = {
    clientId: PropTypes.string,
    form: PropTypes.shape({
        validateFieldsAndScroll: PropTypes.func.isRequired,
        getFieldDecorator: PropTypes.func.isRequired,
    }),
    intl: intlShape.isRequired,
    codeDocument: PropTypes.string,
    codeParent: PropTypes.string
};

const mapStateToProps = ({ app: { locale }, signIn: { clientID, userRole } }) => ({
    clientID,
    locale,
    userRole
});
export default  connect(mapStateToProps)(injectIntl(Form.create()(TablePDRCatalog)));
