import React, { useState, useEffect, Fragment } from "react";
import { Table } from "antd";
import { getListEchangesReseau } from "../../utils/apiBucherVaslin";
import { FormattedMessage } from "react-intl";
import {
  RemiseEnCircuit,
  InformationDemandeur,
  FilterDropDown,
  OnFilter,
  OnFilterDropdownVisibleChange,
} from "./NetworkRequestsActions";
import { alphabetically } from "../../utils/helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

const getColumns = (columns, refresh) => {
  let columnsWhitAction = [
    ...columns,
    {
      dataIndex: "demandeurNomClient",
      key: "demandeurNomClient",
      filterDropdown: FilterDropDown,
      onFilter: (value, record) =>
        OnFilter(value, record.demandeurNomClient.toString()),
      onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
      sorter: (a, b) =>
        alphabetically(a.demandeurNomClient, b.demandeurNomClient),
      title: <FormattedMessage id={`networkRequests.table.demandeur`} />,
      render: (_, record) => (
        <InformationDemandeur
          idUser={record.userIdCreation}
          nomClient={record.demandeurNomClient}
        />
      ),
    },
    {
      dataIndex: "demandeurPays",
      key: "demandeurPays",
      filterDropdown: FilterDropDown,
      onFilter: (value, record) =>
        OnFilter(value, record.demandeurPays.toString()),
      onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
      sorter: (a, b) => alphabetically(a.demandeurPays, b.demandeurPays),
      title: <FormattedMessage id={`table.customers.country`} />,
    },
    {
      dataIndex: "actions",
      key: "actions",
      title: <FormattedMessage id={`table.users.action`} />,
      render: (_, record) => (
        <RemiseEnCircuit id={record.id} refresh={refresh} />
      ),
    },
  ];
  return columnsWhitAction;
};
const NetworkRequestsAnswered = ({ columns, isRefreshing, refresh }) => {
  const [loadNetworkRequestsAnswered, setLoadNetworkRequestsAnswered] =
    useState(false);
  const [networkRequestsAnsweredData, setNetworkRequestsAnsweredData] =
    useState([]);
  useEffect(() => {
    getListEchangesReseau("DemandesAssignees").then(
      ({ listEchangesReseau }) => {
        setNetworkRequestsAnsweredData(listEchangesReseau);
        setLoadNetworkRequestsAnswered(false);
      }
    );
  }, [isRefreshing]);
  return (
    <Fragment>
      <Table
        className="custom-table"
        dataSource={networkRequestsAnsweredData}
        columns={getColumns(columns, refresh)}
        rowKey={(record) => record.id}
        loading={loadNetworkRequestsAnswered}
      />
      <FontAwesomeIcon
        icon={faInfoCircle}
        style={{ marginRight: "0.7em", color: "#09a3db" }}
      />
      <FormattedMessage id={`networkRequests.table.infoMessage`} />
    </Fragment>
  );
};

export default NetworkRequestsAnswered;
