import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import PageLayout from './Layout/PageLayout';

import './NotFoundPage.less';

const NotFoundPage = ({ children }) => (
    <PageLayout pageKey="NotFound">
        <div className="not-found-page">
            <h1>
                <FormattedMessage
                    id="page.not.found"
                    defaultMessage="Page not found"
                />
            </h1>
            {children}
        </div>
    </PageLayout>
);

NotFoundPage.propTypes = {
    children: PropTypes.node,
};

export default NotFoundPage;
