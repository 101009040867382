import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Divider, message, notification, Popover, Table } from 'antd';
import { Link } from 'react-router-dom';
import { faCopy, faEdit, faSearch} from '@fortawesome/free-solid-svg-icons';
import { isNil } from 'lodash';
import PageLayout from '../Layout/PageLayout';
import {
    alphabetically,
    compareDate,
    compareNumber,
    isGroupEBV,
    isGroupAdminClient,
    isOrderEditable,
    isSuperAdmin,
    isInfoCompleted
} from '../../utils/helpers';
import { duplicateOrder, fetchOrdersLicenseForClient, fetchOrderStatus } from '../../utils/apiBucherVaslin';
import CreateOrderLicense from './CreateOrderLicense';
import DeleteOrderLicense from './DeleteOrderLicense';
import SubmitOrderLicense from './SubmitOrderLicense';
import  FormattedCurrency  from '../utils/FormattedCurrency';
import { CURRENCY_EURO } from '../../utils/constants';

const tableName = 'orders';
const tableNameLicense = 'ordersLicense';

const mongoDateFormat = 'YYYY-MM-DD HH:mm:ss';
const renderDate = (date) => (date ? <span>{moment(date).format('DD/MM/YY')}</span> : '-');

/* eslint-disable */
class OrdersLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            currency: CURRENCY_EURO,
            loading: false,
            statusList: [],
            orders: []
        };
    }

    componentDidMount = async () => {
        /* check connexion and information */ 
        const { userID, intl } = this.props;
        await isInfoCompleted(intl, userID);

        // Init status list
        await this.initStatusList();
        // Load and filter orders
        await this.reloadOrders();

        message.info(
            intl.formatMessage(
                {
                    id: 'orderLicense.index.information'
                }
            ),
            5
        );
    };

    /*
    *   Load the list of displayed orders
    */
    reloadOrders = async () => {
        // Load orders
        await this.fetchDataAsync();

    }

    fetchDataAsync = async () => {
        await this.setState({ loading: true });
        const { clientID, intl, match: { params: { eureka } } } = this.props;
        const { items, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchOrdersLicenseForClient(eureka || clientID)();
        if (isSuccess) {
            items.sort(function(a, b){
                return new Date(b.dateCreation) - new Date(a.dateCreation);
            });
        }
        else {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }

        await this.setState({ orders: items, loading: false });
    };

    handleDuplicate = async (numCommande) => {
        const { intl } = this.props;
        const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await duplicateOrder(numCommande);
        if (isSuccess) {
            notification.success({
                message: intl.formatMessage({
                    id: 'order.duplicate.success',
                }),
            });
            await this.reloadOrders();
        }
        else {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
    };

    initStatusList = async () => {
        const { intl, locale } = this.props;

        // Charge la liste des status
        const { status, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchOrderStatus(locale.toUpperCase());
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            return;
        }
        await this.setState({statusList: status});
    }

    getColumns () {
        const { intl, userRole } = this.props;
        const { currency } = this.state;

        return [
            {
                dataIndex: 'numCommande',
                key: 'contract',
                sorter: (a, b) => alphabetically(a.numCommande, b.numCommande),
            },
            {
                dataIndex: 'montant',
                key: 'amount',
                sorter: (a, b) => compareNumber(a.montant, b.montant),
                render: (value) => <FormattedCurrency amount={value} currency={currency} />
            },
            {
                key: 'status',
                filterDropdown: this.filterStatusDropDown,
                sorter: (a, b) => alphabetically(this.getStatus(a), this.getStatus(b)),
                render: (_, order) => { return this.getStatus(order)},
            },
            {
                dataIndex: 'dateCreation',
                key: 'dateCreation',
                sorter: (a, b) => compareDate(a.dateCreation, b.dateCreation, mongoDateFormat),
                render: renderDate,
            },
            {
                dataIndex: 'dateSoumission',
                key: 'dateSubmitted',
                sorter: (a, b) => compareDate(a.dateSoumission, b.dateSoumission, mongoDateFormat),
                render: renderDate,
            },
            {
                key: 'action',
                render: (_, { numCommande, statutCommand, nbArticles }) => (
                    <span>
                        {isOrderEditable({ statutCommand }) ? (
                            <Popover content={<FormattedMessage id={`table.${tableName}.edit`} />}>
                                <Link to={`/ordersLicense/${numCommande}`}>
                                    <Button>
                                        <FontAwesomeIcon icon={faEdit} />
                                    </Button>
                                </Link>
                            </Popover>) : (
                            <Popover content={<FormattedMessage id={`table.${tableName}.details`} />}>
                                <Link to={`/ordersLicense/${numCommande}`}>
                                    <Button>
                                        <FontAwesomeIcon icon={faSearch} />
                                    </Button>
                                </Link>
                            </Popover>)
                        }
                        <Divider type="vertical" />
                        <Popover content={<FormattedMessage id={`table.${tableName}.duplicate`} />}>
                            <Button>
                                <FontAwesomeIcon icon={faCopy} onClick={() => this.handleDuplicate(numCommande)} />
                            </Button>
                        </Popover>
                        {/* Button submit show when no validate (or date not passed) and user is superAdmin or AdminClient
                            If user haven't these roles we show a button deactivate
                        */}
                        {isOrderEditable({ statutCommand }) && (
                        ((isSuperAdmin({ userRole }) || isGroupEBV({userRole}) || isGroupAdminClient({userRole})) ? (
                            nbArticles ?
                                (
                                    <Fragment>
                                        <Divider type="vertical" />
                                        <SubmitOrderLicense id={numCommande} refresh={this.fetchDataAsync} />
                                    </Fragment>
                                ) :  
                                    <Fragment>
                                    <Divider type="vertical" />
                                        <Popover content={<FormattedMessage id="table.order.submit.noArticle" />}>
                                            <Button disabled>
                                                <FontAwesomeIcon icon="paper-plane" />
                                            </Button>
                                        </Popover>
                                    </Fragment>
                           ) : 
                            <Fragment>
                            <Divider type="vertical" />
                                <Popover content={<FormattedMessage id="table.order.submit.deactivate" />}>
                                    <Button disabled>
                                        <FontAwesomeIcon icon="paper-plane" />
                                    </Button>
                                </Popover>
                            </Fragment>
                            )
                    )}
                        {/** Can't delete submitted order */}
                        {isOrderEditable({ statutCommand }) && (
                            <Fragment>
                                <Divider type="vertical" />
                                <DeleteOrderLicense id={numCommande} refresh={this.reloadOrders} />
                            </Fragment>
                        )}
                    </span>
                ),
            },
        ];
    };

    // Return status id for translation
    getStatus = (order) => {
        const { statusList } = this.state;
        const orderStatut = statusList.find((status) => status.statut === order.statutCommand);
        return (orderStatut) ? orderStatut.statutTraduit : null;
    }

    render() {
        const { match: { params: { eureka } }, history } = this.props;
        const {  loading,  orders } = this.state;

        const columns = this.getColumns();
        const translatedColumns = columns.map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        return (
            <PageLayout pageKey={isNil(eureka) ? 'ordersLicense' : 'customers'} history={history}>
                <h2>
                    <FormattedMessage id={`table.${tableNameLicense}.${eureka ? 'listTitle' : 'title'}`} />
                </h2>

                <Table
                    className="custom-table"
                    dataSource={orders}
                    columns={translatedColumns}
                    rowKey="numCommande"
                    loading={loading}
                />
                <CreateOrderLicense refresh={this.reloadOrders} />
            </PageLayout>
        );
    }
}

OrdersLicense.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            eureka: PropTypes.string,
        }).isRequired,
    }).isRequired,
    clientId: PropTypes.string,
    intl: intlShape.isRequired,
    userRole: PropTypes.array.isRequired,
};

const mapStateToProps = ({ app: { locale }, signIn: { clientID, userRole, userID } }) => ({
    clientID,
    locale,
    userID,
    userRole
});

export default injectIntl(connect(mapStateToProps)(OrdersLicense));
