import { Button, Input, Table,Popover} from 'antd';
import React, {Component, Fragment} from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import  FormattedCurrency  from '../utils/FormattedCurrency';
import './Catalog.less';
import {
    alphabetically,
    compareNumber
} from '../../utils/helpers';


class TablePDRAffichage extends Component {

    constructor(props) {
        super(props);
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                <FormattedMessage id="common.search" />
            </Button>
            <Button onClick={this.handleReset(clearFilters)}><FormattedMessage id="common.cancel" /></Button>
        </div>
    );

    onFilter = (value, obj) => obj && obj.toLowerCase().includes(value.toLowerCase());

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };
    getPdrDocument = () => {
        const {intl, sawOnlyPrixPublic, loading, updateQuantity} = this.props;
        let columns = [];
        const codeArticle =
        {
            title: intl.formatMessage({id:'catalog.tablePdr.table.item'}),
            dataIndex: '',
            fixed: 'left',
            render: (text, row, index) => { 
                if(row.article != null) {
                    if(row.article.isSubstitution) {
                        const article = row.article.codeArticle;
                        const artSubstitue = row.codeArticle;
                        return {
                            children:
                            <Fragment>
                                {row.codeArticle}  {" "}
                                <Popover placement="topLeft"  content={
                                    intl.formatMessage(
                                        {id: 'catalog.tablePdr.table.substitution'}, { article, artSubstitue })} trigger="hover">
                                    <FontAwesomeIcon icon={faInfoCircle} />
                                </Popover>
                            </Fragment>,
                            props: {
                                style: { color: '#d65c10' },
                            },
                        } 
                    }
                }
                return row.codeArticle
            },
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.codeArticle),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            sorter: (a, b) => alphabetically(a.codeArticle, b.codeArticle),
            width: 100
        }
        const designation = 
            {
                title: intl.formatMessage({id:'catalog.tablePdr.table.designation'}), 
                dataIndex: 'designation',
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.designation),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.designation, b.designation),
                width: 400
            }
        const amount =
            {
                title: intl.formatMessage({id:'catalog.tablePdr.table.amount'}),
                dataIndex: '',
                render: (text, row, index) => {
                    if(row.article.prix != 0) {
                       return updateQuantity(row.codeArticle)
                    }
                    return {
                        children: <a> {row.article.messageStatut} </a>,
                        props: {colSpan: 3}
                    }                 
                },
                width: 80
            }
        const prixRemise = 
            {
                title: intl.formatMessage({id:'table.articles.prixRemise'}),
                dataIndex: '',
                render: (text, row, index) => { 
                    if(row.article.prix != 0) {
                        return <FormattedCurrency amount={row.article.prix} currency={row.article.codeDevise} />
                    }
                    return(
                        <Fragment>
                            <Link to={`/partsUseCases/${row.codeArticle}`} target="_blank">
                                <Button>
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </Link>  
                        </Fragment>
                    )
                },
                sorter: (a, b) => compareNumber(a.article? a.article.prix : 0 , b.article? b.article.prix : 0),
                width: 100
            }
        const prixPublic =
            {
                title: intl.formatMessage({id:'table.articles.prixPublic'}),
                dataIndex: '',
                render: (text, row, index) => { 
                    if(row.article.prixPublic != 0) {
                        return <FormattedCurrency amount={row.article.prixPublic} currency={row.article.codeDevise} />
                    } else if (sawOnlyPrixPublic) {
                           return <a><FormattedMessage id="table.devices.guarantee.error.contact" /></a>
                    } 
                },
                sorter: (a, b) => compareNumber(a.article.prixPublic , b.article.prixPublic),
                width: 100
            }
        const action =
            {
                render: (_, row) => {
                if(row.article.prixPublic != 0) {
                    return(
                        <Fragment>
                            <Link to={`/partsUseCases/${row.codeArticle}`} target="_blank">
                                <Button>
                                    <FontAwesomeIcon icon={faSearch} />
                                </Button>
                            </Link>  
                        </Fragment>
                )}
            }} 
            if(!sawOnlyPrixPublic && !loading) {
                columns = [codeArticle, designation, amount, prixRemise, prixPublic, action]
            } else {
                columns = [codeArticle, designation, prixPublic]
            }
              
        return columns;
    }

    render = () => {
        const{ pdrDocument, loading } = this.props;
        const columns = this.getPdrDocument();
        return(
                <Table
                    rowClassName={ (record, index) => { record.article? (record.article.isSubstitution ? "background-substitution"  : "" ) :""}   }
                    className="custom-table"
                    columns={columns}
                    dataSource={pdrDocument}
                    rowKey="codeArticle"
                    loading={loading}
                    locale={{ emptyText: <FormattedMessage id="catalog.tablePdr.table.noData" /> }}
                    pagination={false} 
                    scroll={{ y:"70vh", x:700}}
                />
        )
    }
}

TablePDRAffichage.propTypes = {
    intl: intlShape.isRequired,
    pdrDocument: PropTypes.array,
    sawOnlyPrixPublic: PropTypes.bool,
    loading: PropTypes.bool,
    updateQuantity: PropTypes.func
};

export default injectIntl(TablePDRAffichage);
