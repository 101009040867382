import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  Divider,
  Button,
  Empty,
  Input,
  InputNumber,
  Popover,
  Table,
  Select,
  Spin,
  notification,
} from "antd";
import { injectIntl, intlShape } from "react-intl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  EditableCell,
  EditableContext,
  EditableFormRow,
} from "../EditableCell";
import {
  fetchBillOrder,
  fetchArticleBill,
  searchArticles,
} from "../../utils/apiBucherVaslin";
import SearchArticles from "../Orders/SearchArticles";

const { Option } = Select;

class WarrantyPieces extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pieces: [],
      editingKey: -1,
      articleListe: [],
      billList: [],
      enableEditing: true,
      idArticleActif: "",
      articlePrix: 0,
    };
  }

  componentDidMount() {
    // Insert a row in pieces table and set it to editable
    this.addWarrantyRow();
  }

  // Return true if the component allow edition
  isEditable() {
    return this.state.enableEditing;
  }

  // Return true if line is in editing mode
  isEditing = (recordId) => {
    const element = this.state.editingKey === recordId;
    return element ? true : false;
  };

  // Delete specified line
  delete = async (recordId) => {
    const pieces = this.state.pieces;
    const pieceIndex = pieces.findIndex((piece) => piece.id === recordId);

    // Remove piece from editing list, then delete it
    pieces.splice(pieceIndex, 1);
    await this.setState({
      articleListe: [],
      billList: [],
      editingKey: -1,
      pieces: pieces,
    });

    // Update Contact Component according to new "this.state.pieces" value
    this.props.updateWarrantyPieces(pieces);
  };

  // Method used to remove row edition (after confirmation)
  removeEdition(recordId) {
    this.setState({ editingKey: recordId });
  }

  // Method used to set a row to editable
  edit(keyId) {
    const { pieces } = this.state;
    const idArticleActif =
      pieces[keyId] && pieces.length > 0 ? pieces[keyId].articleId : "";
    const articlePrix =
      pieces[keyId] && pieces.length > 0 ? pieces[keyId].prix : "";
    this.setState({ editingKey: keyId, idArticleActif, articlePrix });
    this.setState({ editingKey: keyId });
  }

  // Method used to update the stored list of pieces
  save(form, key) {
    const { pieces, articleListe, idArticleActif, articlePrix } = this.state;
    const {
      form: { getFieldValue },
      demandType,
    } = this.props;
    // validateFields(async (error, row) => {
    // Add row id according to key object (object contained in this.state.pieces for this row)
    // Then replace piece object value with row value for corresponding row id
    const order =
      demandType === "100000001"
        ? null
        : getFieldValue("commandeOrigine_" + key.id);
    const billNumber =
      demandType === "100000001"
        ? null
        : getFieldValue("factureOrigine_" + key.id);
    const articleNumber =
      idArticleActif.length > 0
        ? idArticleActif
        : getFieldValue("articleId_" + key.id);
    const quantity =
      demandType === "100000001" ? 1 : getFieldValue("quantite_" + key.id);
    const numSeriePiece = getFieldValue("seriePiece_" + key.id);
    const prixArticle = articleListe.findIndex(
      (article) => article.codeArticle === articleNumber
    );

    const copyPieces = pieces;
    const indexPiece = copyPieces.findIndex((piece) => piece.id === key.id);

    // const { articles } = searchArticles(articleNumber, null);
    copyPieces[indexPiece] = key;
    copyPieces[indexPiece].commandeOrigine = order;
    copyPieces[indexPiece].factureOrigine = billNumber;
    copyPieces[indexPiece].articleId = articleNumber;
    copyPieces[indexPiece].quantite = quantity;
    copyPieces[indexPiece].seriePiece = numSeriePiece;
    copyPieces[indexPiece].prix =
      demandType === "100000001" ? articlePrix : articleListe[prixArticle].prix;

    this.setState({
      pieces: copyPieces,
      articleListe: [],
      billList: [],
      editingKey: -1,
      idArticleActif: "",
      articlePrix: 0,
    });
    // Send info to Contact composant
    this.props.updateWarrantyPieces(copyPieces);
    // });
  }

  // Add a row in warranty table
  addWarrantyRow = () => {
    const { pieces } = this.state;
    const { demandType } = this.props;
    let validId = 0;
    let validCount = false;
    let newPiece;

    // Get first available id
    while (!validCount) {
      let valid = true;
      pieces.forEach((piece) => {
        if (piece.id === validId.toString()) {
          valid = false;
          validId++;
        }
      });
      validCount = valid;
    }
    if (demandType === "100000001") {
      newPiece = { id: validId.toString(), articleId: "", seriePiece: "" };
    } else if (demandType === "100000000") {
      newPiece = {
        id: validId.toString(),
        commandeOrigine: "",
        factureOrigine: "",
        articleId: "",
        seriePiece: "",
        quantite: 1,
      };
    }
    this.setState({ pieces: [...pieces, newPiece] });
    // Set row editable
    this.edit(validId.toString());
  };

  // Method used to get bill command
  getBillOrder = async (idRaw) => {
    const {
      intl,
      form: { getFieldValue, setFieldsValue },
      demandType,
    } = this.props;
    const idOrder = getFieldValue("commandeOrigine_" + idRaw);

    setFieldsValue({ [("factureOrigine_" + idRaw).valueOf()]: null });
    setFieldsValue({ [("articleId_" + idRaw).valueOf()]: null });
    if (demandType === "100000000") {
      setFieldsValue({ [("quantite_" + idRaw).valueOf()]: 1 });
    }

    this.setState({ articleListe: [] });

    //Get order list by client
    const { billList, isSuccess, isUnauthorized, errorMessage, errorDetail } =
      await fetchBillOrder(idOrder);
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
    }
    this.setState({ billList: isSuccess ? billList : [] });
  };

  // Method used to get bill articles
  getArticleBill = async (idRaw) => {
    const {
      intl,
      form: { setFieldsValue, getFieldValue },
      demandType,
    } = this.props;
    const billNumber = getFieldValue("factureOrigine_" + idRaw);

    setFieldsValue({ [("articleId_" + idRaw).valueOf()]: null });
    if (demandType === "100000000") {
      setFieldsValue({ [("quantite_" + idRaw).valueOf()]: 1 });
    }
    //Get order list by client
    const { articleListe, isSuccess, isUnauthorized, errorMessage, errorDetail } =
      await fetchArticleBill(billNumber);
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
    }
    this.setState({ articleListe: isSuccess ? articleListe : [] });
  };

  showOrderResult = (idRaw) => {
    const {
      commandList,
      loadingClient,
      form: { getFieldDecorator },
      intl,
    } = this.props;
    const { pieces, editingKey } = this.state;

    let isEditable = editingKey == idRaw;

    if (!isEditable) {
      return <span>{pieces[idRaw] ? pieces[idRaw].commandeOrigine : ""}</span>;
    }
    if (isEditable) {
      return getFieldDecorator("commandeOrigine_" + idRaw, {
        initialValue: pieces[idRaw] ? pieces[idRaw].commandeOrigine : "",
      })(
        <Select
          style={{ width: "200px" }}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().includes(input.toLowerCase())
          }
          notFoundContent={
            loadingClient ? (
              <Spin size="small" />
            ) : (
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            )
          }
          onSelect={() => this.getBillOrder(idRaw)}
          showSearch={true}
          key={"selectOrder_" + idRaw}
        >
          <Option value={null}>
            {intl.formatMessage({ id: "incident.commandeOrigine.toutes" })}
          </Option>
          {commandList.map((commandeOrigine) => (
            <Option key={commandeOrigine.numCommande}>
              {commandeOrigine.numCommande}
            </Option>
          ))}
        </Select>
      );
    }
  };
  //Show billing depanding of order selected
  showBillResult = (idRaw) => {
    const { billList, pieces, editingKey } = this.state;
    const { getFieldDecorator } = this.props.form;

    let isEditable = editingKey == idRaw;

    if (!isEditable) {
      return <span>{pieces[idRaw] ? pieces[idRaw].factureOrigine : ""}</span>;
    }
    if (isEditable) {
      return getFieldDecorator("factureOrigine_" + idRaw, {
        initialValue: pieces[idRaw] ? pieces[idRaw].factureOrigine : "",
      })(
        <Select
          style={{ width: "200px" }}
          onSelect={() => this.getArticleBill(idRaw)}
          showSearch={true}
          key={"selectBillNumber_" + idRaw}
        >
          {billList.map((bill) => (
            <Option key={bill.numBilling}>{bill.numBilling}</Option>
          ))}
        </Select>
      );
    }
  };
  //Show articles depanding of bill selected
  showArticlesResult = (idRaw) => {
    const { articleListe, pieces, editingKey, idArticleActif } = this.state;
    const {
      demandType,
      form: { getFieldDecorator },
    } = this.props;
    let isEditable = editingKey == idRaw;

    if (!isEditable) {
      return <span>{pieces[idRaw] ? pieces[idRaw].articleId : ""}</span>;
    }
    if (isEditable) {
      if (demandType === "100000001") {
        let idArticleActifTemp = pieces[idRaw].articleId
          ? pieces[idRaw].articleId
          : idArticleActif;
        return (
          <SearchArticles
            warrantyPiece={isEditable}
            pieceSelected={idArticleActifTemp}
            onSubmitWarrantyPiece={(codeArticle, articlePrix, libelle) =>
              this.submitCodeArticle(codeArticle, articlePrix)
            }
          />
        );
      } else {
        return getFieldDecorator("articleId_" + idRaw, {
          initialValue: pieces[idRaw] ? pieces[idRaw].articleId : "",
        })(
          <Select
            style={{ width: "200px" }}
            showSearch={true}
            key={"selectArticleNumber_" + idRaw}
          >
            {articleListe.map((article) => (
              <Option key={article.codeArticle}>{article.codeArticle}</Option>
            ))}
          </Select>
        );
      }
    }
  };
  submitCodeArticle = (idArticleActif, articlePrix) => {
    this.setState({ idArticleActif, articlePrix });
  };
  showSeriePiece = (idRaw) => {
    const { pieces, editingKey } = this.state;
    const {
      form: { getFieldDecorator },
    } = this.props;
    let isEditable = editingKey == idRaw;

    if (!isEditable) {
      return <span>{pieces[idRaw] ? pieces[idRaw].seriePiece : ""}</span>;
    }
    if (isEditable) {
      return getFieldDecorator("seriePiece_" + idRaw, {
        initialValue: pieces[idRaw] ? pieces[idRaw].seriePiece : "",
      })(<Input />);
    }
  };

  updateQuantity = (idRaw) => {
    const { pieces, articleListe, editingKey } = this.state;
    const { getFieldDecorator, getFieldValue } = this.props.form;
    const articleNumber = getFieldValue("articleId_" + idRaw);
    let isEditable = editingKey == idRaw;
    let renderNumber = <InputNumber min={1} disabled={!articleNumber} />;

    const maxQuantity = articleListe[
      articleListe.findIndex((x) => x.codeArticle === articleNumber)
    ]
      ? articleListe[
          articleListe.findIndex((x) => x.codeArticle === articleNumber)
        ].qte
      : pieces[idRaw]
      ? pieces[idRaw].quantite
      : 1;
    if (isEditable) {
      renderNumber = (
        <InputNumber min={1} max={maxQuantity} disabled={!articleNumber} />
      );
    }

    return getFieldDecorator("quantite_" + idRaw, {
      initialValue: pieces[idRaw] ? pieces[idRaw].quantity : 1,
    })(renderNumber);
  };
  /**
   * Method used to define Warranty Table columns
   */
  getWarrantyColumns = () => {
    const {
      intl,
      demandType,
      form: { getFieldValue },
    } = this.props;
    const { editingKey, idArticleActif } = this.state;

    let commandeOrigine = {
      title: intl.formatMessage({ id: "table.contact.warranty.order" }),
      dataIndex: "commandeOrigine",
      key: "commandeOrigine",
      enable: false,
      render: (_, record) => this.showOrderResult(record.id),
    };
    let factureOrigine = {
      title: intl.formatMessage({ id: "table.contact.warranty.billNumber" }),
      dataIndex: "factureOrigine",
      key: "factureOrigine",
      render: (_, record) => this.showBillResult(record.id),
    };
    let articleId = {
      title: intl.formatMessage({ id: "table.contact.warranty.articleNumber" }),
      dataIndex: "articleId",
      key: "articleId",
      render: (_, record) => this.showArticlesResult(record.id),
    };
    let seriePiece = {
      title: intl.formatMessage({ id: "table.contact.warranty.seriePiece" }),
      type: "text",
      dataIndex: "seriePiece",
      key: "seriePiece",
      render: (_, record) => this.showSeriePiece(record.id),
    };
    let quantite = {
      title: intl.formatMessage({ id: "table.contact.warranty.quantity" }),
      dataIndex: "quantite",
      key: "quantite",
      render: (_, record) => this.updateQuantity(record.id),
    };
    let columns = [];
    if (demandType === "100000001") {
      columns = [articleId, seriePiece];
    } else if (demandType === "100000000") {
      columns = [
        commandeOrigine,
        factureOrigine,
        articleId,
        seriePiece,
        quantite,
      ];
    }

    if (this.isEditable()) {
      columns.push({
        key: "action",
        title: intl.formatMessage({ id: "table.contact.warranty.action" }),
        render: (_, record) => {
          const { id } = record;
          let billNumberRes = getFieldValue("factureOrigine_" + record.id);
          let articleNumberRes = getFieldValue("articleId_" + record.id);
          let canSave = false;
          if (demandType === "100000000") {
            canSave = billNumberRes && articleNumberRes;
          } else if (demandType === "100000001") {
            canSave = idArticleActif;
          }
          if (this.isEditing(id)) {
            return (
              <span>
                <EditableContext.Consumer>
                  {(form) => (
                    <Button
                      htmlType="button"
                      type="primary"
                      disabled={!canSave}
                      onClick={() => this.save(form, record)}
                    >
                      <FontAwesomeIcon icon="save" />
                    </Button>
                  )}
                </EditableContext.Consumer>
                <Divider type="vertical" />
                <Button
                  htmlType="button"
                  type="primary"
                  onClick={() => this.delete(id)}
                >
                  <FontAwesomeIcon icon="ban" />
                </Button>
              </span>
            );
          }

          return (
            <Fragment>
              {editingKey > -1 ? (
                <Popover
                  placement="topRight"
                  content={intl.formatMessage({
                    id: "table.contact.warranty.saveUpdateValidation",
                  })}
                  trigger="hover"
                >
                  <Button htmlType="button" disabled={editingKey > -1}>
                    <FontAwesomeIcon icon="pen-alt" />
                  </Button>
                </Popover>
              ) : (
                <Button htmlType="button" onClick={() => this.edit(id)}>
                  <FontAwesomeIcon icon="pen-alt" />
                </Button>
              )}
            </Fragment>
          );
        },
      });

      // Configurate editables columns
      columns = columns.map((col) => {
        if (!col.editable) {
          return col;
        }

        const c = {
          ...col,
          onCell: (record) => ({
            record,
            inputType: col.type,
            dataIndex: col.dataIndex,
            title: col.title,
            editing: this.isEditing(record.id),
            errorDetail: col.errorMessage,
          }),
        };

        return c;
      });
    }
    return columns;
  };

  render() {
    const { pieces, editingKey } = this.state;
    const { intl } = this.props;
    const warrantyColumns = [...this.getWarrantyColumns()];

    const components = {
      body: {
        row: EditableFormRow,
        cell: EditableCell,
      },
    };

    const pagination = {
      total: pieces.length,
      pageSize: pieces.length,
      hideOnSinglePage: true,
    };

    return (
      <Fragment>
        <Table
          bordered={true}
          components={components}
          columns={warrantyColumns}
          dataSource={pieces}
          pagination={pagination}
          rowKey="id"
        />
        {editingKey > -1 ? (
          <Popover
            placement="topLeft"
            content={intl.formatMessage({
              id: "table.contact.warranty.saveAddValidation",
            })}
            trigger="hover"
          >
            <Button htmlType="button" disabled>
              {" "}
              {intl.formatMessage({ id: "table.contact.warranty.addPiece" })}
            </Button>
          </Popover>
        ) : (
          <Button htmlType="button" onClick={() => this.addWarrantyRow()}>
            {" "}
            {intl.formatMessage({ id: "table.contact.warranty.addPiece" })}
          </Button>
        )}
      </Fragment>
    );
  }
}

WarrantyPieces.propTypes = {
  form: PropTypes.shape({
    validateFieldsAndScroll: PropTypes.func.isRequired,
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  demandType: PropTypes.string.isRequired,
  commandList: PropTypes.array.isRequired,
};

const mapStateToProps = () => ({});

export default connect(mapStateToProps)(injectIntl(WarrantyPieces));
