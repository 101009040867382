import { Button, notification } from 'antd';
import React from 'react';

const generateKey = (id) => `notification-${id.replace(/\s+/g, '-').toLowerCase()}`;
const getNewsURL = (id) => `/infos/${id}`;

export const create = ({ description, title, id }, push, moreText, handleRedirectFlashInfo) => {
    const key = generateKey(title);
    if (description.length > 150) {
            description = description.substring(0, 150) + " [...]";
    }

    const button = (
        <Button
            type="primary"
            size="small"
            onClick={() => {
                notification.close(key);
                handleRedirectFlashInfo();
            }}
        >
            {moreText}
        </Button>
    );

    notification.open({
        key,
        message: <span style={{fontSize:18}}>{title}</span>,
        description: <span style={{fontSize:16}}>{description}</span>,
        duration: 0,
        placement: 'bottomLeft',
        bottom: 20,
        btn: button
    });
};

export const onInfoClicked = (push, id) => {
    push(getNewsURL(id));
};
