const findNodeByName = (name, rootNode) => {
    const { reduce } = [];

    function runner(result, node) {
        if (result || !node) {
            return result;
        }

        return (node.name === name && node)
            || runner(null, node.children)
            || reduce.call(Object(node), runner, result);
    }

    return runner(null, rootNode);
};

export default findNodeByName;
