import { Button, Col, Divider, Icon, Row, Input, Select, Spin, Tree, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { extensionToType } from '../../utils/helpers';
import { fetchCatalog, fetchDocumentByCodeParent } from '../../utils/apiBucherVaslin';
import findNodeByName from '../../utils/trees';
import Doc from './Document';
import TablePDRCatalog from './TablePDRCatalog';
import PageLayout from '../Layout/PageLayout';
import {
    isInfoCompleted
} from '../../utils/helpers';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './Catalog.less';
import { Fragment } from 'react';

const { DirectoryTree, TreeNode } = Tree;
const BLOB_URL_TECHDOC = process.env.BLOB_URL_TECHDOC;

// List of extension on which we can filter
const extensionList = [
    { label: 'PDF', value: 'pdf' },
    { label: 'Excel', value: 'xls' },
    { label: 'Image', value: 'png' }
]


class Catalog extends Component {
    state = {
        expandedKeys: [],
        searchValue: '',
        extensionFilter: [],
        autoExpandParent: true,
        selectedNode: null,
        tree: null,
        loadedTree: null,
        affichePDR: false,
        niveau: 4,
        codeParentDoc: ''
    };

    async componentDidMount() {
        const { match: { params: { eureka } }, intl, userID } = this.props;
        /* check connexion and information */ 
        await isInfoCompleted(intl, userID);
        this.fetchData();
    }

    // Get all data needed to fetch our tree
    fetchData = async () => {
        // Get Catalog documents
        let { isSuccess, treeDocuments, isUnauthorized, errorMessage, errorDetail } = await fetchCatalog();
        const { intl } = this.props;

        // If api call failed
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
            this.setState({ canAccess: false, loading: true });
            return;
        }

        // If api call succeed
        this.setState({
            tree: Object.assign({}, {treeDocuments}),
            loadedTree: Object.assign({}, {treeDocuments})
        });
    }

    fetchDocument = async (codeParent, position) => {
        const {tree, niveau} = this.state;
        let newNiveau = niveau;
        const niveauActuelTree = codeParent.substring(0, 4);
        var marque,gamme,capacite,modele;
        marque = gamme = capacite = modele = false;
        const codeParentToSend = codeParent.substring(4);
        let newTree = tree;
        if(niveauActuelTree == "MAR_") {
            marque = true;
        } else if(niveauActuelTree == "GAM_") {
            gamme = true;
        } else if (niveauActuelTree == "CAP_" ) {
            capacite = true;
        } else {
            modele = true;
        }
        const { intl } = this.props;
        let { ebvDocumentationTypes, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchDocumentByCodeParent(codeParentToSend, marque, gamme, capacite, modele );
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
            this.setState({ canAccess: false, loading: true });
            return;
        }
        if(ebvDocumentationTypes.length > 0 ) {
            if(niveauActuelTree == "MAR_") {

                let MarValueAdd =  newTree.treeDocuments[position[1]].ebvGammesList;
                MarValueAdd = MarValueAdd.concat(ebvDocumentationTypes);
                const MarWhitoutDuplicate = [...new Map(MarValueAdd.map(item => [JSON.stringify(item), item])).values()];
                newTree.treeDocuments[position[1]].ebvGammesList = MarWhitoutDuplicate;
                newNiveau = 1;

            } else if(niveauActuelTree == "GAM_") {

                let GamValueAdd =  newTree.treeDocuments[position[1]].ebvGammesList[position[2]].ebvCapacitesList;
                GamValueAdd = GamValueAdd.concat(ebvDocumentationTypes);
                const GamWhitoutDuplicate = [...new Map(GamValueAdd.map(item => [JSON.stringify(item), item])).values()];
                newTree.treeDocuments[position[1]].ebvGammesList[position[2]].ebvCapacitesList = GamWhitoutDuplicate;
                newNiveau = 2;

            } else if (niveauActuelTree == "CAP_" ) {

                let CapValueAdd =  newTree.treeDocuments[position[1]].ebvGammesList[position[2]].ebvCapacitesList[position[3]].ebvModelesList;
                CapValueAdd = CapValueAdd.concat(ebvDocumentationTypes);
                const CapWhitoutDuplicate = [...new Map(CapValueAdd.map(item => [JSON.stringify(item), item])).values()];
                newTree.treeDocuments[position[1]].ebvGammesList[position[2]].ebvCapacitesList[position[3]].ebvModelesList = CapWhitoutDuplicate;
                newNiveau = 3;

            } else {
                newTree.treeDocuments[position[1]].ebvGammesList[position[2]].ebvCapacitesList[position[3]].ebvModelesList[position[4]].ebvDocumentationInModelesList = ebvDocumentationTypes;
            }
            this.setState({
                niveau: newNiveau,
                tree: newTree,
                // affichePDR: true, A remettre quand meilleur perf
                codeParentDoc: codeParentToSend
            });
        }
    }
    // Method used when folder is expend
    onExpand = (newExpandedKeys, selectedNode) => {
        const {expandedKeys} = this.state
        const pos = selectedNode.node.props.pos;
        const liste_pos = pos.split('-');
        if(expandedKeys.length < newExpandedKeys.length){
            const difference = newExpandedKeys.filter(x => !expandedKeys.includes(x)); // cas ajout expanded
            this.fetchDocument(difference[0], liste_pos);
        }
        this.setState({
            expandedKeys: newExpandedKeys,
            autoExpandParent: false
        });
    }

    // Method used when folder/file is selected
    onNodeSelected(nodesKey, info) {
        this.setState({
            affichePDR: false
        });
        var niveau = nodesKey[0].substring(0, 4);
        if(niveau == "DOC_"){
            this.setState({
                selectedNode: info.selectedNodes[0].props,
                affichePDR: true
            });
        } else {
            const { tree } = this.state;
            const nodes = nodesKey.map((nodeKey) => findNodeByName(nodeKey, tree));
            this.setState({
                selectedNode: nodes[0]
            });
        }
    }
    // Method used to render nodes
    renderNodes(nodes) {
        const { searchValue } = this.state;
        return nodes.map((node) => {
            if(node) {
            const index = node.libelle.toLowerCase().indexOf(searchValue.toLowerCase());
            const beforeStr = node.libelle.substr(0, index);
            const searchStr = node.libelle.substr(index, searchValue.length);
            const afterStr = node.libelle.substr(index + searchValue.length);
            const title = index > -1 ? (
                <span>
                    {beforeStr}
                    <span style={{ color: '#f50' }}>{searchStr}</span>
                    {afterStr}
                </span>
            ) : <span>{node.libelle}</span>;

            let key = "MOD_" + node.codeModele;  // remettre DOC_ pour afficher les PDR liée au modele
            if (node.ebvGammesList) {
                key = "MAR_" + node.codeMarque;
                return (
                    <TreeNode key={key} title={<span style={{"fontWeight":"bold", "fontSize":"1.2em"}}>{title}</span>}>
                        {this.renderNodes(node.ebvGammesList)}
                    </TreeNode>
                );
            }
            if (node.ebvCapacitesList) {
                key = "GAM_" + node.codeGamme;
                return (
                    <TreeNode key={key} title={title}>
                        {this.renderNodes(node.ebvCapacitesList)}
                    </TreeNode>
                );
            }

            if (node.ebvModelesList) {
                key = "CAP_" + node.codeCapacite;
                return (
                    <TreeNode key={key} title={title}>
                        {this.renderNodes(node.ebvModelesList)}
                    </TreeNode>
                );
            }

            if (node.ebvDocumentationInModelesList) {
                key = "MOD_" + node.codeModele; // remettre DOC_ pour afficher les PDR liée au modele
                return (
                    <TreeNode key={key} title={title}>
                        {this.renderNodes(node.ebvDocumentationInModelesList)}
                    </TreeNode>
                );
            }

            if (node.ebvDocumentationInDocumentationTypesList) {
                return (
                    <TreeNode title={title}>
                        {this.renderNodes(node.ebvDocumentationInDocumentationTypesList)}
                    </TreeNode>
                );
            }
            if (node.ebvDocumentationInDocumentationList) {
                return (
                    <TreeNode title={title}>
                        {this.renderNodes(node.ebvDocumentationInDocumentationList)}
                    </TreeNode>
                );
            }

            if(node.codeDocumentation) {
                const fileType = node.nomFichier.split('.').pop();
                let iconType = 'file-unknown';
                switch (extensionToType(fileType.toLowerCase())) {
                    case 'image':
                        iconType = 'file-jpg';
                        break;
                    case 'excel':
                        iconType = 'file-excel';
                        break;
                    case 'pdf':
                        iconType = 'file-pdf';
                        break;
                    default:
                        iconType = 'file-unknown';
                        break;
                }
                    key = "DOC_" + node.codeDocumentation + (node.version? "_" + node.version : "") +  (node.codeModele? "_" + node.codeModele : "");
                    return (
                    <TreeNode
                        key={key}
                        title={node.nomFichier.split('.')[0]}
                        urlDoc={node.nomFichier}
                        codeDoc={node.codeDocumentation}
                        icon={<Icon type={iconType} />}
                        isLeaf
                    />
                    )
            }
            return (
                <TreeNode key={key} title={title}>

                </TreeNode>
            );
            }
        });
    }

    // Method used to filter node element
    filterNode = (node, filterName, filterExtension) => {
        let visibleChildFolders = [];
        // Manage child folder visibility
        if(node.treeDocuments && node.treeDocuments.length > 0 ) {
            node.treeDocuments.forEach((subNode) => {
                const childFolder = this.filterNode(subNode, filterName, filterExtension);
                if (childFolder) {
                    visibleChildFolders.push(childFolder);
                }
            });
        }

        // TODO => Manage child file when implemented
        // TODO => Manage extension filter for child files

        // Edit node to show his visible treeDocuments
        node.treeDocuments = visibleChildFolders;

        // Manage folder name
        if (node.libelle.toLowerCase().includes(filterName.toLowerCase()) || node.treeDocuments.length > 0) {
            return node;
        } else {
            return null;
        }
    }

    // Method used to filter displayed tree
    // Return the value of the tree after applying filter
    triggerFilter = (tree, name, extension) => {
        // Initiate new tree
        const filteredTree = {
            treeDocuments: []
        };
        const copyTree = JSON.parse(JSON.stringify(tree));

        copyTree.treeDocuments.forEach((node) => {
            const nodeValue = this.filterNode(node, name, extension);

            // If the node passed filter in success with atleast one child or himself
            if (nodeValue) {
                filteredTree.treeDocuments.push(nodeValue);
            }
        });

        return filteredTree;
    }

    // Method triggered when extension select value change
    onChangeExtension = (value) => {
        const { loadedTree, searchValue } = this.state;
        const copyTree = Object.assign({}, loadedTree);

        const filteredTree = this.triggerFilter(copyTree, searchValue, value);
        this.setState({
            extensionFilter: value,
            autoExpandParent: true,
            tree: filteredTree
        });
    }

    // Method triggered whenever filter text field change
    onChangeFilter = (event) => {
        const { value } = event.target;
        const { loadedTree, extensionFilter } = this.state;
        const copyTree = Object.assign({}, loadedTree);
        const filteredTree = this.triggerFilter(copyTree, value, extensionFilter);
        this.setState({
            searchValue: value,
            autoExpandParent: true,
            tree: filteredTree
        });
    };

    showTablePDRCatalog = () => {
        const { selectedNode, codeParentDoc, affichePDR, niveau } = this.state;
            if(selectedNode) {
                const linkDecoder = BLOB_URL_TECHDOC + "/" +  escape(selectedNode.urlDoc);
                return(
                    <Fragment>
                        <Divider type="vertical" />
                        <div>
                        <a href={linkDecoder} target="_blank" download>
                            <Button htmlType="button" style={{ marginRight: 8 }}>
                                <Icon type="download" />
                                <FormattedMessage id="documents.download" />
                            </Button>
                        </a>
                        <TablePDRCatalog
                            codeDocument={selectedNode? selectedNode.codeDoc: ''}
                            codeParent={codeParentDoc}
                            affichePDR={affichePDR}
                            niveau={niveau}
                        />
                        </div>
                    </Fragment> 
                )
            }
    }
    
    render() {
        const { history } = this.props;
        const { expandedKeys, autoExpandParent, niveau, codeParentDoc, selectedNode , tree, affichePDR } = this.state;

        return (
            <PageLayout pageKey="catalog" history={history}>
                {(!tree) ? (<Spin />) : (
                    <Row className="explorator">
                        <Col span={6} className="tree-view">
                            {/* <span> */}
                                {/* Name filter */}
                                {/* <Search
                                    style={{ marginBottom: 8 }}
                                    placeholder={intl.formatMessage({id: 'catalog.filter.placeholder'} )}
                                    onChange={this.onChangeFilter}
                                /> */}
                                {/* Extension filter */}
                                {/* <Select
                                    onSelect={ (value) => { this.onChangeExtension(value) } }
                                    placeholder={intl.formatMessage({id: 'catalog.filter.typePlaceholder'})}
                                    mode="multiple"
                                >
                                    {extensionList && extensionList.map((extension, index) => (
                                        <Option key={index} value={extension.value} >{extension.label}</Option>
                                    ))}
                                </Select>
                            </span> */}
                            <DirectoryTree
                                onExpand={this.onExpand}
                                expandedKeys={expandedKeys}
                                node={selectedNode}
                                autoExpandParent={autoExpandParent}
                                onSelect={(key,info) => this.onNodeSelected(key,info)}
                            >
                            {this.renderNodes(tree.treeDocuments)}
                            </DirectoryTree>
                            <span className="actions">
                                {this.showTablePDRCatalog()}
                             </span>
                        </Col>
                        <Col span={18} className="document-view">
                            <Doc src={selectedNode && selectedNode.urlDoc}
                                 codeDocument={selectedNode? selectedNode.codeDoc: ''} 
                                 codeParent={codeParentDoc} 
                                 niveau={niveau} 
                                 affichePDR= {affichePDR} />
                        </Col>
                    </Row>
                )}
            </PageLayout>
        );
    }
}

Catalog.propTypes = {
    intl: intlShape.isRequired
};
const mapStateToProps = ({ app: { locale }, signIn: {  userRole, userID } }) => ({
    locale,
    userRole,
    userID
});

export default connect(mapStateToProps)(injectIntl(Catalog));
