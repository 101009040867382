import jwtDecode from 'jwt-decode';
import { fetchAllProgressIconOfTemplateList, 
    fetchCurrentCustomerDetails,
    fetchUserDetailsById,
    getUserAccess,
    signIn } from '../utils/apiBucherVaslin';
import { setLocale } from './app';

export const LOGIN_RESET = 'LOGIN_RESET';
export const LOGIN_SET_ALLOW_DOWNLOAD_HISTORY = 'LOGIN_SET_ALLOW_DOWNLOAD_HISTORY';
export const LOGIN_SET_CLIENT_DEALER = 'LOGIN_SET_CLIENT_DEALER';
export const LOGIN_SET_CLIENT_EBV = 'LOGIN_SET_CLIENT_EBV';
export const LOGIN_SET_CLIENT_EMAIL= 'LOGIN_SET_CLIENT_EMAIL';
export const LOGIN_SET_CLIENT_EUREKA_NUMBER = 'LOGIN_SET_CLIENT_EUREKA_NUMBER';
export const LOGIN_SET_CLIENT_ID = 'LOGIN_SET_CLIENT_ID';
export const LOGIN_SET_CLIENT_NAME = 'LOGIN_SET_CLIENT_NAME';
export const LOGIN_SET_CLIENT_PERMISSIONS = 'LOGIN_SET_CLIENT_PERMISSIONS';
export const LOGIN_SET_CLIENT_ROLE = 'LOGIN_SET_CLIENT_ROLE';
export const LOGIN_SET_STATE = 'LOGIN_SET_STATE';
export const LOGIN_SET_TOKEN = 'LOGIN_SET_TOKEN';
export const LOGIN_SET_TOKEN_DATE = 'LOGIN_SET_TOKEN_DATE';
export const LOGIN_SET_USER_ID = 'LOGIN_SET_USER_ID';
export const LOGIN_SET_USER_NAME = 'LOGIN_SET_USER_NAME';
export const LOGIN_SET_USER_ROLE = 'LOGIN_SET_USER_ROLE';
export const LOGIN_SET_IS_PROSPECT = 'LOGIN_SET_IS_PROSPECT';
export const LOGIN_SET_USER_RIGHTS = 'LOGIN_SET_USER_RIGHTS';
export const LOGIN_SET_PROGRESS_ICONS = 'LOGIN_SET_PROGRESS_ICONS';
const PORTAIL_PREPROD_URL = process.env.PORTAIL_PREPROD_URL;

const setLoginState = (loggedIn) => ({
    type: LOGIN_SET_STATE,
    loggedIn,
});

const setLoginToken = (token) => ({
    type: LOGIN_SET_TOKEN,
    token,
});

const setTokenDate = (tokenDate) => ({
    type: LOGIN_SET_TOKEN_DATE,
    tokenDate,
});

const setClientEbv = (clientEbv) => ({
    type: LOGIN_SET_CLIENT_EBV,
    clientEbv: '1' === clientEbv,
});

const setClientID = (clientID) => ({
    type: LOGIN_SET_CLIENT_ID,
    clientID,
});

const setUserID = (userID) => ({
    type: LOGIN_SET_USER_ID,
    userID,
});

const setUserRole = (userRole) => ({
    type: LOGIN_SET_USER_ROLE,
    userRole,
});

const setClientEurekaNumber = (clientEurekaNumber) => ({
    type: LOGIN_SET_CLIENT_EUREKA_NUMBER,
    clientEurekaNumber,
});

const setClientIsDealer = (clientIsDealer) => ({
    type: LOGIN_SET_CLIENT_DEALER,
    clientIsDealer,
});

const setClientEmail = (email) => ({
    type: LOGIN_SET_CLIENT_EMAIL,
    email,
});

const setClientName = (clientName) => ({
    type: LOGIN_SET_CLIENT_NAME,
    clientName,
});

const setAllowDownloadHisto = (allowDownloadHisto) => ({
    type: LOGIN_SET_ALLOW_DOWNLOAD_HISTORY,
    allowDownloadHisto,
});

const setUserName = (userName) => ({
    type: LOGIN_SET_USER_NAME,
    userName,
});

const setIsProspect = (isProspect) => ({
    type: LOGIN_SET_IS_PROSPECT,
    isProspect,
});

const setUserRights = (rights) => ({
    type: LOGIN_SET_USER_RIGHTS,
    rights,
});

const resetLoginState = () => ({
    type: LOGIN_RESET,
});

const setProgressIcons = (progressIconsTemplateList) => ({
    type: LOGIN_SET_PROGRESS_ICONS,
    progressIconsTemplateList,
});

export const signInWithOAuth2 = (formValues) => async (dispatch) => {
    const token = await signIn(formValues);
    const newUserRole = [];
    if (null === token) {
        return Promise.reject();
    }
    const { idClient, sub, role: userRole } = jwtDecode(token);

    // On transforme le userRole en tableau de rôle (qu'il en ait plusieurs, 1 ou 0)
    if (userRole != null){
        if (typeof userRole == 'string'){
            newUserRole.push(userRole);
        }
        else {
            userRole.forEach(role => newUserRole.push(role));
        }
    }

    dispatch(setTokenDate(new Date().getTime()));
    dispatch(setLoginToken(token));
    dispatch(setLoginState(true));

    // Bloque la connexion de l'utilisateur si celui-ci n'est pas autorisé à se connecter en pré-production
    if(window.location.origin === PORTAIL_PREPROD_URL && !newUserRole.includes('Dev')) {
        dispatch(resetLoginState());
        return Promise.reject("unauthorized");
    }

    dispatch(setUserID(sub));
    dispatch(setClientID(idClient));
    dispatch(setUserRole(newUserRole));

    const { clientInfo: { clientEbv, nomClient, numeroClientEureka, isProspect } } = await fetchCurrentCustomerDetails();
    dispatch(setClientEbv(clientEbv));
    dispatch(setClientName(nomClient));
    dispatch(setClientEurekaNumber(numeroClientEureka));
    dispatch(setIsProspect(isProspect));

    const { item: { allowDownloadHisto, clientIsDealer, email, nomPrenom, lang } } = await fetchUserDetailsById(sub);
    dispatch(setAllowDownloadHisto(allowDownloadHisto));
    dispatch(setClientIsDealer(clientIsDealer));
    dispatch(setClientEmail(email));
    dispatch(setUserName(nomPrenom));
    dispatch(setLocale(lang.toLowerCase()));

    //Récupération des droits de l'utilisateur
    const { items: rights } = await getUserAccess();
    dispatch(setUserRights(rights));

    // Récupération des progressIcons pour le template liste
    const { items: progressIconsTemplateList } = await fetchAllProgressIconOfTemplateList();
    dispatch(setProgressIcons(progressIconsTemplateList));

    return Promise.resolve();
};

export const logout = () => async (dispatch) => {
    dispatch(resetLoginState());
};
