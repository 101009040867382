import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserShield } from '@fortawesome/free-solid-svg-icons';
import React, { Fragment} from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Row, Col, Button } from 'antd';

import Table from '../Table';
import PageLayout from '../Layout/PageLayout';
import UsersTableActions from './TableActions';
import { fetchUsers } from '../../utils/apiBucherVaslin';
import { isGroupAdminClient, isGroupRR, isSuperAdmin } from '../../utils/helpers';

const tableName = 'users';
const keyToDbColumn = {
    name: 'Nomprenom',
    email: 'Email',
    clientName: 'nom_client',
};

const columns = [
    {
        dataIndex: '',
        key: 'name',
        sorter: true,
        render: () => (_, { nomPrenom, roles }) => {
            let userRole = roles ? roles.split(';') : '';
            return {
                children:
                    <Fragment>
                        {(isGroupAdminClient({ userRole }) &&  <FontAwesomeIcon icon={faUserShield} style={{ color: 'green'}} /> )} {nomPrenom}
                    </Fragment>
            }
           
        },
    },
    {
        dataIndex: 'email',
        key: 'email',
        sorter: true,
    },
    {
        dataIndex: 'client.nomClient',
        key: 'clientName',
        sorter: true,
    },
];

const actionsColumn = {
    title: 'Actions',
    key: 'action',
    render: (refresh) => (_, { idUser, email, nomPrenom }) => (
        <UsersTableActions refresh={refresh} idUser={idUser} email={email} name={nomPrenom} />
    ),
};

const Users = ({ pageKey, fetchCurrentUsers, clientID, userRole, ...props }) => {
    const allColumns = [...columns];
    const {
        match: {
            params: { eureka },
        },
        history,
    } = props;

    if (isSuperAdmin({ userRole }) || isGroupAdminClient({ userRole }) || isGroupRR({ userRole })) {
        allColumns.push(actionsColumn);
    }

    return (
        <PageLayout pageKey={pageKey} history={history}>
            <Row type="flex" justify="space-between">
                <Col sm={24} md={4} lg={12}>
                    <h2>
                        <FormattedMessage id={`table.${tableName}.title`} />
                    </h2>
                </Col>
                {(isSuperAdmin({ userRole }) || isGroupAdminClient({ userRole })) && (
                    <Col sm={24} md={20} lg={12} className="radio-licenses">
                        <Button>
                            <NavLink to="/newUser">
                                <FormattedMessage id="menu.myAccount.newUser" defaultMessage="Create user" />
                            </NavLink>
                        </Button>
                    </Col>
                )}
            </Row>
            <Table
                fetchItems={!eureka ? fetchUsers : () => fetchUsers({}, eureka)}
                keyToDbColumn={keyToDbColumn}
                columns={allColumns}
                rowKey="idUser"
                tableName={tableName}
                mandatoryFilter={fetchCurrentUsers ? clientID : null}
            />
        </PageLayout>
    );
};

Users.propTypes = {
    pageKey: PropTypes.string.isRequired,
    match: PropTypes.shape({
        path: PropTypes.string.isRequired,
        params: PropTypes.shape({
            eureka: PropTypes.string,
        }),
    }).isRequired,
    fetchCurrentUsers: PropTypes.bool,
    clientID: PropTypes.string.isRequired,
    userRole: PropTypes.array.isRequired,
    intl: intlShape.isRequired,
};

Users.defaultProps = {
    fetchCurrentUsers: false,
};

const mapStateToProps = ({ signIn: { clientID, userRole } }) => ({
    clientID,
    userRole
});

export default injectIntl(connect(mapStateToProps)(Users));
