import { Col, Icon, Row, Input, Spin, Tree } from 'antd';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { extensionToType } from '../../utils/helpers';
import { fetchDocuments } from '../../utils/apiBucherVaslin';
import findNodeByName from '../../utils/trees';
import Document from './Document';
import DocumentActions from './DocumentActions';
import './Documents.less';
import PageLayout from '../Layout/PageLayout';

const { Search } = Input;
const { DirectoryTree, TreeNode } = Tree;

class Documents extends Component {
    state = {
        expandedKeys: [],
        searchValue: '',
        autoExpandParent: true,
        selectedNode: null,
        tree: null,
    };

    async componentDidMount() {
        const tree = await fetchDocuments();
        this.setState({
            tree,
        });
    }

    onExpand = (expandedKeys) => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    }

    onNodeSelected(nodesKey) {
        const { tree } = this.state;

        const nodes = nodesKey.map((nodeKey) => findNodeByName(nodeKey, tree));
        this.setState({
            selectedNode: nodes[0],
        });
    }

    renderNodes(nodes) {
        const { searchValue } = this.state;

        return nodes.map((node) => {
            const index = node.name.indexOf(searchValue);
            const beforeStr = node.name.substr(0, index);
            const afterStr = node.name.substr(index + searchValue.length);

            const title = index > -1 ? (
                <span>
                    {beforeStr}
                    <span style={{ color: '#f50' }}>{searchValue}</span>
                    {afterStr}
                </span>
            ) : <span>{node.name}</span>;

            const key = node.name;
            if (node.children) {
                return (
                    <TreeNode key={key} title={title}>
                        {this.renderNodes(node.children)}
                    </TreeNode>
                );
            }

            const fileType = node.link && node.link.split('.').pop();
            let iconType = 'file-unknown';
            switch (extensionToType(fileType)) {
                case 'image':
                    iconType = 'file-jpg';
                    break;
                case 'excel':
                    iconType = 'file-excel';
                    break;
                case 'pdf':
                    iconType = 'file-pdf';
                    break;
                default:
                    iconType = 'file-unknown';
                    break;
            }

            return (
                <TreeNode
                    key={key}
                    title={title}
                    icon={<Icon type={iconType} />}
                    isLeaf
                />
            );
        });
    }

    render() {
        const { catalog, history } = this.props;
        const { expandedKeys, autoExpandParent, selectedNode, tree } = this.state;

        if (!tree) {
            return <Spin />;
        }

        return (
            <PageLayout pageKey="documents" history={history}>
                <Row className="explorator">
                    <Col span={6} className="tree-view">
                        <Search style={{ marginBottom: 8 }} placeholder="Rechercher un document" />
                        <DirectoryTree
                            onExpand={this.onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                            onSelect={(nodes) => this.onNodeSelected(nodes)}
                        >
                            {this.renderNodes(tree.children)}
                        </DirectoryTree>
                    </Col>
                    <Col span={18} className="document-view">
                        <Document src={selectedNode && selectedNode.link} />
                        {selectedNode && selectedNode.link && (
                            <DocumentActions link={selectedNode && selectedNode.link} enableShop={catalog} item={{ selectedNode }} itemSuggestion={'My PDF file' === selectedNode.name} />
                        )}
                    </Col>
                </Row>
            </PageLayout>
        );
    }
}

Documents.propTypes = {
    catalog: PropTypes.bool,
};

export default Documents;
