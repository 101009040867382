import { isNil, merge } from "lodash";

import { store } from "../store";
import { logout } from "../actions/signIn";
import tree from "./tree";
import { locales } from "../translations";
import { REGEX_GUID } from "../utils/constants";

const responseError = ({ statusText, status }) => ({
  isSuccess: false,
  errorMessage: statusText,
  errorDetail: status,
});

const responseErrorAPI = ({ statusText, httpCode }) => ({
  isSuccess: false,
  errorMessage: statusText,
  items: [],
  item: null,
  total: 0,
  isUnauthorized: httpCode === 401,
});

const responseSuccess = ({ items, total }) => ({
  isSuccess: true,
  items,
  total,
});

const getToken = () => {
  const {
    signIn: { token },
  } = store.getState();

  return token;
};

const getHeaders = () => ({
  Authorization: `Bearer ${getToken()}`,
  "Content-type": "application/json",
});

/* eslint-disable prefer-destructuring */
const CLIENT_ID = process.env.CLIENT_ID;
const CLIENT_SECRET = process.env.CLIENT_SECRET;
const SCOPE = process.env.SCOPE;
const BACKEND_URL = process.env.BACKEND_URL;
/* eslint-enable prefer-destructuring */

export const fetchCustomers = async (filters = {}) => {
  const response = await fetch(`${BACKEND_URL}/api/Client/searchclient`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(filters),
  });
  if (response.ok) {
    // Doit être impérativement items et total, objets reçu en json
    const { items, total, isSuccess, errorDetail } = await response.json();
    // console.log(items);
    return { items, total, isSuccess, errorDetail};
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchUsers = async (filters = {}, clientID = null) => {
  const body = { ...filters };
  if (!isNil(clientID)) {
    body.idClient = clientID;
  }
  const response = await fetch(`${BACKEND_URL}/api/User/searchUser`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(body),
  });
  if (response.ok) {
    // Doit être impérativement items et total, objets reçu en json
    const { items, total, isSuccess, errorDetail } = await response.json();

    return { items, total, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

const addKeyToItem = (item, index) => ({
  key: index,
  ...item,
});

export const fetchArticles =
  (eureka, numCommande) =>
  async (filters = {}) => {
    const response = await fetch(
      `${BACKEND_URL}/api/Articles/getArticlesForClientByEurekaNum`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          ...filters,
          ID: eureka,
          numCommande: numCommande,
        }),
      }
    );
    if (response.ok) {
      // Doit être impérativement items et total, objets nommés reçu en json
      const { items, total, isSuccess, errorDetail } = await response.json();

      return { items: items.map(addKeyToItem), total, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const fetchArticlesFamilies = async (numCommande) => {
  const response = await fetch(`${BACKEND_URL}/api/Articles/fa`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(
      {
        numCommande: numCommande,
      }
    ),
  });
  if (response.ok) {
    // Doit être impérativement items et total, objets nommés reçu en json
    const { items, total, isSuccess, errorDetail } = await response.json();

    return { items: items.map(addKeyToItem), total, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchArticlesForFamily =
  (eureka, familyCode, numCommande) => async () => {
    const response = await fetch(
      `${BACKEND_URL}/api/Articles/getArticlesForFamilyAndEurekaNum`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          ID: eureka,
          codefamille: familyCode,
          numCommande: numCommande,
        }),
      }
    );
    if (response.ok) {
      // Doit être impérativement items et total, objets nommés reçu en json
      const { items, total, isSuccess, errorDetail } = await response.json();

      return { items: items.map(addKeyToItem), total, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const fetchCustomerDetailsById = async (id) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Client/getInfosClientByEurekaNum`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ ID: id }),
    }
  );
  if (response.ok) {
    // Doit être impérativement clientInfo, objet nommé reçu en json
    const { clientInfo: item, isSuccess, errorDetail } = await response.json();

    return { item: item[0], isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchCurrentCustomerDetails = async () => {
  const response = await fetch(`${BACKEND_URL}/api/Client/getInfosClient`, {
    credentials: "include",
    method: "GET",
    headers: getHeaders(),
  });
  if (response.ok) {
    // Doit être impérativement clientInfo, objet nommé reçu en json
    const { clientInfo, isSuccess, errorDetail } = await response.json();

    return { clientInfo, isSuccess, errorDetail };
  }

  return { clientInfo: {}, isSuccess: false };
};

export const fetchConnectedDevices =
  (eureka) =>
  async (filters = {}) => {
    const response = await fetch(
      `${BACKEND_URL}/api/Device/getDevicesByEurekaNum`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          ...filters,
          ID: eureka,
        }),
      }
    );
    if (response.ok) {
      // Doit être impérativement items et total, objets reçu en json
      const {
        items: { devices: items },
        total,
        isSuccess, 
        errorDetail
      } = await response.json();

      return { items, total, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const fetchDevices =
  (eureka, showDevicesConnected = false, toDisplay = null, numeroLot = null) =>
  async (filters = {}) => {
    const response = await fetch(
      `${BACKEND_URL}/api/Device/getlotsbyeurekanum`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify({
          ...filters,
          ID: eureka,
          ShowDevicesConnected: showDevicesConnected,
          toDisplay,
          numeroLot,
        }),
      }
    );

    if (response.ok) {
      // Doit être impérativement items et total, objets reçu en json
      const {
        items: { lots: items },
        total,
        isSuccess, 
        errorDetail
      } = await response.json();

      return { items, total, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

// Super Admin route only
export const fetchAllDevices = async (
  showAllDevices = false,
  onlyDevicesAffectedToClient = false
) => {
  const response = await fetch(`${BACKEND_URL}/api/Device/getAllDevices`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      OnlyDevicesAffectedToClient: onlyDevicesAffectedToClient,
      showAllDevices,
    }),
  });

  if (response.ok) {
    // Doit être impérativement devices, cet objet est reçu en json
    const { devices: items, isSuccess, errorDetail } = await response.json();

    return { items, total: items ? items.length : [], isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Dealer and higher route only
export const searchLot = async (dealerID) => {
  const response = await fetch(`${BACKEND_URL}/api/Device/searchLot`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      codeConcessionnaire: dealerID,
    }),
  });

  if (response.ok) {
    // Doit être impérativement items et total, objets reçu en json
    const {
      items: { lots: items },
      total,
      isSuccess, 
      errorDetail
    } = await response.json();

    return { items, total, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const updateDeviceHistoryState = async (serial, state) => {
  const response = await fetch(`${BACKEND_URL}/api/Device/SwitchHistorySave`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      serial,
      HistorySaveEnabled: state,
    }),
  });
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

function objectToFormData(formData, object, suffix = "") {
  Object.keys(object).forEach((key) => {
    if (object[key]) {
      const elementName = suffix ? `${suffix}.${key}` : key;
      if (Array.isArray(object[key])) {
        object[key].forEach((element) => {
          element.name
            ? formData.append(`${elementName}[]`, element, element.name)
            : ""; // mise à jour dealers qui oblige cette gestion. Cela servait pour les fichiers
        });
      } else {
        formData.append(elementName, object[key]);
      }
    }
  });
}

export const createClientWithUser = async (clientData) => {
  const body = new FormData();
  objectToFormData(body, clientData.client, "client");
  objectToFormData(
    body,
    clientData.user !== undefined ? clientData.user : {},
    "user"
  );
  body.append("CreateNoUser", clientData.CreateNoUser);

  const response = await fetch(
    `${BACKEND_URL}/api/Client/createclientwithuser2`,
    {
      credentials: "include",
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      body,
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
export const getAddressCustomerById = async (clientGuid) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/getCustomerAddressesByClientId?guid=${clientGuid}`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { result: addresses, isSuccess, errorDetail } = await response.json();
    return { addresses, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
export const getAddressesByClientId = async (clientGuid) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/getAddressesByClientId?guid=${clientGuid}`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { result: addresses, isSuccess, errorDetail } = await response.json();
    // console.log(addresses);
    return { addresses, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const getAddressById = async (adressseGuid) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/GetCustomerAddressById?guid=${adressseGuid}`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { result: addresses, isSuccess, errorDetail } = await response.json();
    return { addresses, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
export const createClientAddress = async (
  addressData,
  addressTypeCode,
  clientGuid
) => {
  const body = {
    ...addressData,
    addressTypeCode,
    objectTypeCode: "account",
    parentId: clientGuid,
  };

  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/addCustomerAddress`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body),
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { message: address, isSuccess, errorDetail } = await response.json();
    return { address, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// export const updateClientAddress = async (
//   customerAddressId,
//   addressData,
//   addressTypeCode,
//   clientGuid
// ) => {
//   const body = {
//     ...addressData,
//     customerAddressId,
//     addressTypeCode,
//     objectTypeCode: "account",
//     parentId: clientGuid,
//     // country: new RegExp(REGEX_GUID).test(addressData.guidCountry) ? null : addressData.guidCountry,
//     // city: new RegExp(REGEX_GUID).test(addressData.guidCity) ? null : addressData.guidCity,
//     // postalCode : new RegExp(REGEX_GUID).test(addressData.guidPostalCode) ? null : addressData.guidPostalCode,
//   };

//   const response = await fetch(
//     `${BACKEND_URL}/api/Dynamic/updateCustomerAddress`,
//     {
//       credentials: "include",
//       method: "POST",
//       headers: getHeaders(),
//       body: JSON.stringify(body),
//     }
//   );
//   if (response.ok) {
//     // Doit être impérativement message, objet nommé reçu en json
//     const { message: address, isSuccess, errorDetail } = await response.json();
//     return { address, isSuccess, errorDetail };
//   }

//   return responseErrorAPI({
//     statusText: response.statusText,
//     httpCode: response.status,
//   });
// };
export const updateAddress = async (
  customerAddressId,
  addressData,
  addressTypeCode,
  clientGuid
) => {
  const body = {
    ...addressData,
    customerAddressId,
    addressTypeCode,
    objectTypeCode: "account",
    parentId: clientGuid,
    // country: new RegExp(REGEX_GUID).test(addressData.guidCountry) ? null : addressData.guidCountry,
    // city: new RegExp(REGEX_GUID).test(addressData.guidCity) ? null : addressData.guidCity,
    // postalCode : new RegExp(REGEX_GUID).test(addressData.guidPostalCode) ? null : addressData.guidPostalCode,
  };

  // console.log(body);

  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/updateAddress`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body),
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { message: address, isSuccess, errorDetail } = await response.json();
    return { address, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const deleteAddress = async (customerAddressId) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/deleteCustomerAddress`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ customerAddressId }),
    }
  );

  if (response.ok) {
    // Doit être impérativement items, celui-ci étant un objet du json reçu
    const { isSuccess, errorDetail } = await response.json();
    return { isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const checkEmailExistOnUser = async (email, userId = undefined) => {
  const body = {
    Email: email,
    idUser: userId,
  };

  const response = await fetch(
    `${BACKEND_URL}/api/User/checkEmailExistOnUser`,
    {
      method: "POST",
      credentials: "omit",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(body),
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { emailExist: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
export const CheckEmailExistOnCustomer = async (email) => {
  const body = {
    EMAIL: email,
  };

  const response = await fetch(
    `${BACKEND_URL}/api/Client/checkEmailExistOnCustomer`,
    {
      method: "POST",
      credentials: "omit",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(body),
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { emailExist: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const checkNameCustomerExist = async (clientName) => {
  const body = {
    NOM_CLIENT: clientName,
  };

  const response = await fetch(
    `${BACKEND_URL}/api/Client/checkNameCustomerExistOnCustomer`,
    {
      credentials: "include",
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(body),
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { nameCustomerExist: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const checkSiretExist = async (siret) => {
  const body = {
    SIRET: siret,
  };

  const response = await fetch(
    `${BACKEND_URL}/api/Client/checkSiretExistOnCustomer`,
    {
      credentials: "include",
      method: "POST",
      headers: { "Content-type": "application/json" },
      body: JSON.stringify(body),
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { siretExist: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const noAuthCreateClientWithUser = async (clientData) => {
  const body = new FormData();
  objectToFormData(body, clientData.client, "client");
  objectToFormData(body, clientData.user, "user");

  // Manage dealer name
  if (clientData.dealerType && clientData.dealerType.toUpperCase() === "BV") {
    // If user choose a BV subsidiary company, set subsidiaryCompany value
    body.append("DealerName", clientData.subsidiaryCompany);
  }
  const response = await fetch(`${BACKEND_URL}/api/Client/registerClient2`, {
    method: "PUT",
    body,
  });
  if (response.ok) {
    //todo: à modifier le retour plus tard pour qu'il soit conforme au type de retour des autres routes
    return response.json();
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const signIn = async ({ login, password }) => {
  const body = new FormData();
  body.append("grant_type", "password");
  body.append("username", login);
  body.append("password", password);
  body.append("client_id", CLIENT_ID);
  body.append("client_secret", CLIENT_SECRET);
  body.append("scope", SCOPE);

  const response = await fetch(`${BACKEND_URL}/Auth/connect/token`, {
  //const response = await fetch(`http://localhost:5000/connect/token`, {
    credentials: "omit",
    method: "POST",
    body,
  });
  if (response.ok) {
    const { access_token: token = null } = await response.json();

    // Init token expiration timestamp
    localStorage.setItem("tokenExpiration", new Date().getTime() + 20000000);
    return token;
  }

  return null;
};

export const updateLoggedInStatus = async () => {
  const response = await fetch(`${BACKEND_URL}/api/user/checkToken`, {
    credentials: "include",
    method: "GET",
    headers: getHeaders(),
  });

  if (403 === response.status) {
    await store.dispatch(logout());

    return Promise.reject();
  }

  if (response.ok) {
    const result = await response.json();
    const { isSuccess, isAuthenticated } = result;

    if (isSuccess && !isAuthenticated) {
      const tokenExpiration = JSON.parse(
        localStorage.getItem("tokenExpiration")
      );
      if (!tokenExpiration || tokenExpiration < new Date().getTime()) {
        // Set a specific flag in localStorage for expired flags
        localStorage.setItem("expiredFlag", true);
      }

      // Then logout
      store.dispatch(logout());
    } else if (isSuccess && isAuthenticated) {
      // Update token expiration timestamp
      localStorage.setItem("tokenExpiration", new Date().getTime() + 20000000);
      return true;
    }
  }

  return false;
};

export const getUserAccess = async () => {
  const response = await fetch(`${BACKEND_URL}/api/user/getUserAccess`, {
    credentials: "include",
    method: "GET",
    headers: getHeaders(),
  });

  if (response.ok) {
    // Doit être impérativement data, objet nommé par défaut
    const { data: items, isSuccess, errorDetail } = await response.json();

    return { items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const updateClient = async (clientData) => {
  const body = new FormData();
  objectToFormData(body, clientData, "client");
  // Manage file list
  if (clientData.fichiers && clientData.fichiers.length > 0) {
    clientData.fichiers.forEach((fichier) => {
      body.append("fichiers", fichier);
    });
  }

  const response = await fetch(`${BACKEND_URL}/api/Client/updateClient2`, {
    credentials: "include",
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body,
  });
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchCountries = async () => {
  const response = await fetch(`${BACKEND_URL}/api/Param/getListePays`, {
    credentials: "include",
    method: "POST",
  });
  if (response.ok) {
    const { items } = await response.json();
    // demander à William si on peut recevoir une liste déjà triée
    // TODO: à voir pour trier côté api si ce n'est pas déjà fait
    // TODO2: à modifier le retour plus tard pour qu'il soit conforme au type de retour des autres routes
    items.sort(({ nom: nom1 }, { nom: nom2 }) => {
      if (nom1 === nom2) {
        return 0;
      }
      if (nom1 < nom2) {
        return -1;
      }

      return 1;
    });

    return items;
  }

  return undefined;
};

export const fetchDynamicsCountries = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/Param/getListePaysDynamics`,
    {
      credentials: "include",
      method: "GET",
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { pays, isSuccess, errorDetail } = await response.json();

    pays.sort(({ nom: nom1 }, { nom: nom2 }) => {
      if (nom1 === nom2) {
        return 0;
      }
      if (nom1 < nom2) {
        return -1;
      }

      return 1;
    });

    return { countries: pays, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchDynamicsCitiesFromCountry = async (
  countryId,
  filteredValue,
  maxSize = 300
) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/getCityFromCountry?countryId=${countryId}&filteredValue=${filteredValue}&maxSize=${maxSize}`,
    {
      credentials: "include",
      method: "GET",
    }
  );
  if (response.ok) {
    const { result, isSuccess, errorDetail } = await response.json();

    if (result && result.length > 0) {
      result.sort(({ name: name1 }, { name: name2 }) => {
        if (name1 === name2) {
          return 0;
        }
        if (name1 < name2) {
          return -1;
        }

        return 1;
      });
    }
    return { cities: result, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchDynamicsZipFromCity = async (cityId) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/getPostalCodesByCity?guidCity=${cityId}`,
    {
      credentials: "include",
      method: "GET",
    }
  );
  if (response.ok) {
    const { result, isSuccess, errorDetail } = await response.json();
    return { zipCodes: result, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const createUserForClient = async (userData) => {
  const response = await fetch(`${BACKEND_URL}/api/User/createUserForClient`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(userData),
  });
  if (response.ok) {
    // Peut être n'importe quel nom de variable à la place d'item ici
    // TODO: tester appel route voir si l'on a bien un isSuccess, côté api les données envoyées sont un peu particulières
    const { item } = await response.json();
    // console.log(response);
    return { item, isSuccess: true  };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const editUserForClient = async (userData) => {
  const response = await fetch(`${BACKEND_URL}/api/User/editUserForClient`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(userData),
  });
  if (response.ok) {
    // Doit être impérativement userInfo, objet nommé reçu en json
    const { userInfo: item, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchUserDetailsById = async (id) => {
  const response = await fetch(`${BACKEND_URL}/api/User/getInfosUserById`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ ID: id }),
  });
  if (response.ok) {
    // Doit être impérativement userInfo, objet reçu en json
    const { userInfo: items, isSuccess, errorDetail } = await response.json();

    return { item: items ? items[0] : {}, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const deleteUserById = async (id) => {
  const response = await fetch(`${BACKEND_URL}/api/User/deleteUserForClient`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ ID: id }),
  });
  if (response.ok) {
    // Doit être impérativement message, objet reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const changeUserPassword = async (userData) => {
  const response = await fetch(`${BACKEND_URL}/api/User/changePasswordUser`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(userData),
  });
  if (response.ok) {
    // Peut être item ou un autre nom, objet reçu en json
    // todo: vérifier si on reçoit la propriété isSuccess
    const { item } = await response.json();

    return { item, isSuccess: true };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const generateUserPassword = async (email) => {
  const response = await fetch(`${BACKEND_URL}/api/User/GeneratePassword`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ email }),
  });
  if (response.ok) {
    // Peut être item ou un autre nom, objet reçu en json
    // todo: vérifier si on reçoit la propriété isSuccess
    const { item } = await response.json();

    return { item, isSuccess: true };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const setUserAlerts = async (userData) => {
  const response = await fetch(`${BACKEND_URL}/api/User/SetUserAlertMailSms`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(userData),
  });
  if (response.ok) {
    // Doit être impérativement message, objet reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const getListRolesByUser = async (idUser) => {
  const response = await fetch(`${BACKEND_URL}/api/User/GetListRolesByUser`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ idUser }),
  });
  if (response.ok) {
    const { userInfo, isSuccess, errorDetail } = await response.json();

    return { userInfo, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const setUserRole = async (idUser, isInsert, roleId) => {
  const response = await fetch(`${BACKEND_URL}/api/User/SetRoleClientUser`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ idUser, isInsert, roleId }),
  });
  if (response.ok) {
    // Peut être n'importe quel nom, objet reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const updateDevice = async (userData) => {
  const response = await fetch(`${BACKEND_URL}/api/Device/UpdateDevice`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(userData),
  });
  if (response.ok) {
    // Doit être impérativement message, objet reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const loadContactSubject = async (lang, isConnected) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Contact/getContactSubjects?lang=${lang}&isConnected=${isConnected}`,
    {
      credentials: "include",
      method: "GET",
      headers: isConnected
        ? getHeaders()
        : { "Content-type": "application/json" },
    }
  );

  if (response.ok) {
    // Doit être impérativement message, objet reçu en json
    const { subjects, isSuccess, errorDetail } = await response.json();

    return { subjects, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const contactSupport = async ({
  articles,
  bonNum1,
  bonNum2,
  loggedIn,
  fichiers,
  garantie,
  message,
  sujet,
  email,
  dealerID,
  ...datas
}) => {
  const body = new FormData();
  let response;
  body.append("sujet", sujet);
  body.append("message", message);
  body.append("description", datas.description);
  body.append("typeFormulaire", datas.typeFormulaire);

  if (dealerID) {
    body.append("idConcessionnaire", dealerID);
  }

  // Manage file list
  if (fichiers && fichiers.length > 0) {
    fichiers.forEach((fichier) => {
      body.append("fichiers", fichier);
    });
  }
  body.append("garantie", garantie);
  if (!isNil(email)) {
    body.append("email", email);
  }
  if (loggedIn) {
    body.append(
      "numeroClientEureka",
      datas.numeroClientEureka ? datas.numeroClientEureka : dealerID
    );
    body.append(
      "isProductionDependante",
      datas.isProductionDependante ? datas.isProductionDependante : false
    );
    body.append("isVendange", datas.isVendange ? datas.isVendange : false);
    if (datas.clefUniciteMachine) {
      body.append("clefUniciteMachine", datas.clefUniciteMachine);
    }
    if (datas.criticiteId) {
      body.append("criticiteId", datas.criticiteId);
    }
    if (datas.dateDefaillance) {
      body.append("dateDefaillance", datas.dateDefaillance);
    }
    if (datas.dateExpeditionSouhaitee) {
      body.append("dateExpeditionSouhaitee", datas.dateExpeditionSouhaitee);
    }
    if (datas.typePriseEnCharge) {
      body.append("typePriseEnCharge", datas.typePriseEnCharge);
    }
    if (datas.referenceClient) {
      body.append("referenceClient", datas.referenceClient);
    }
    if (datas.commentaireERP) {
      body.append("commentaireERP", datas.commentaireERP);
    }
    if (datas.numeroClientLivraisonEureka) {
      body.append(
        "numeroClientLivraisonEureka",
        datas.numeroClientLivraisonEureka
      );
    }
    if (datas.adresseDynamicsGuid) {
      body.append("adresseDynamicsGuid", datas.adresseDynamicsGuid);
    }
    if (bonNum1 && bonNum1.length > 0) {
      bonNum1.forEach((bonN1) => {
        body.append("bonN1", bonN1);
      });
    }
    if (bonNum2 && bonNum2.length > 0) {
      bonNum2.forEach((bonN2) => {
        body.append("bonN2", bonN2);
      });
    }
    if (articles) {
      articles.forEach((article, index) => {
        if (article.commandeOrigine) {
          body.append(
            `articles[${index}][numCommandeOrigine]`,
            article.commandeOrigine
          );
        }
        if (article.factureOrigine) {
          body.append(
            `articles[${index}][numFactureOrigine]`,
            article.factureOrigine
          );
        }
        body.append(`articles[${index}][codeArticle]`, article.articleId);
        body.append(`articles[${index}][qte]`, article.quantite);
        body.append(`articles[${index}][seriePiece]`, article.seriePiece);
        body.append(`articles[${index}][prix]`, article.prix);
      });
    }
    response = await fetch(`${BACKEND_URL}/api/Contact/contactSupport`, {
      credentials: "include",
      method: "POST",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
      body: body,
    });
  } else {
    response = await fetch(`${BACKEND_URL}/api/Contact/contactSupport`, {
      credentials: "include",
      method: "POST",
      body: body,
    });
  }

  if (response.ok) {
    // TODO: modifier retour pour qu'il soit conforme aux autres appels de route
    return response.json();
  }

  return responseError(response);
};

export const fetchLicensesForUser = (id) => async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/License/getLicensesForUser`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ IdUser: id }),
    }
  );
  if (response.ok) {
    // Doit être impérativement être licenses, un objet reçu en json
    const { licenses, isSuccess, errorDetail } = await response.json();

    return { items: licenses, total: licenses.length, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchLicensesForClient =
  (id) =>
  async (filters = {}) => {
    const body = { ...filters };
    if (!isNil(id)) {
      body.idClient = id;
    }
    const response = await fetch(
      `${BACKEND_URL}/api/License/getLicensesForClient`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      }
    );
    if (response.ok) {
      // Doit être impérativement licenses, un objet reçu en json
      const { licenses: items, isSuccess, errorDetail } = await response.json();

      return { items, total: items.length, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const fetchLicensesForDevice = (id) => async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/License/getLicensesByDevice`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ IdDevice: id }),
    }
  );

  if (response.ok) {
    // Doit être impérativement être license, un objet reçu en json
    const { license, isSuccess, errorDetail } = await response.json();

    return { items: license, total: license.length, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchAllLicenses = async () => {
  const response = await fetch(`${BACKEND_URL}/api/License/getAllLicenses`, {
    credentials: "include",
    method: "GET",
    headers: getHeaders(),
  });
  if (response.ok) {
    // Doit être impérativement licenses, objet nommé reçu en json
    const { licenses: items, isSuccess, errorDetail } = await response.json();

    return { items, total: items.length, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchAllLicenseFamilies = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/License/getAllLicenseFamilies`,
    {
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    // Doit être impérativement familles, objet reçu en json
    const { familles: items, isSuccess, errorDetail } = await response.json();

    return { items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const forgotPassword = async (data) => {
  const response = await fetch(`${BACKEND_URL}/api/User/ForgotPassword`, {
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      email: data.login,
      captcha: data.captcha,
    }),
  });
  if (response.ok) {
    // todo: changer le retour pour qu'il soit conforme aux autres retour de routes
    return response.json();
  }

  return responseError(response);
};

export const linkLicense = async (data) => {
  const response = await fetch(`${BACKEND_URL}/api/License/affecterLicense`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(data),
  });

  if (response.ok) {
    // Doit être impérativement message, objet reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const generateLicense =
  (
    id,
    duree = 0,
    family = null,
    profil = null,
    parentGuid = null,
    type = null
  ) =>
  async (filters = {}) => {
    const body = { ...filters };
    if (!isNil(id)) {
      body.idClient = id;
    }
    if (!isNil(family)) {
      body.famille = family;
    }
    const response = await fetch(`${BACKEND_URL}/api/License/genererLicense`, {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      // Doit être impérativement data avec message et guid en objets enfants, objet reçu en json
      const {
        data: { message: item, guid },
        isSuccess, 
        errorDetail
      } = await response.json();

      return { item, isSuccess, guid, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const deleteLicense = async (guid) => {
  const response = await fetch(`${BACKEND_URL}/api/License/supprimerLicense`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ guid }),
  });

  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const updateLicense = async (guid, prolongementDuree) => {
  const response = await fetch(`${BACKEND_URL}/api/License/updateLicense`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ guid, prolongementDuree }),
  });
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();

    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Récupère la liste des commandes d'un client
export const fetchOrdersForClient =
  (idClient, annee) =>
  async (filters = {}) => {
    const body = { ...filters };
    if (!isNil(idClient)) {
      body.numero_client_eureka = idClient;
      body.annee = annee;
    }
    const response = await fetch(
      `${BACKEND_URL}/api/Command/getListCommandesByNumClient`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      }
    );
    if (response.ok) {
      // Doit être impérativement items, objet nommé reçu en json
      const { items, isSuccess, errorDetail } = await response.json();
      // console.log(items);
      return { items, total: items.length, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

// Récupère la liste des contrats d'un client
export const fetchOrdersLicenseForClient =
  (idClient) =>
  async (filters = {}) => {
    const body = { ...filters };
    if (!isNil(idClient)) {
      body.numero_client_eureka = idClient;
    }
    const response = await fetch(
      `${BACKEND_URL}/api/Command/getListContratsForClient`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      }
    );
    if (response.ok) {
      // Doit être impérativement items, objet nommé reçu en json
      const { items, isSuccess, errorDetail } = await response.json();
      return { items, total: items.length, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const fetchOrderStatus = async (lang) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/getAllStatutCommand?lang=${lang}`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    // Doit impérativement être commande et lignes, l'api renvoyant ces données nommées en json
    const { items: status, isSuccess, errorDetail } = await response.json();
    return { status, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
export const fetchOrderClientAndType = async (selectedClient, selectedType) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/GetCommandByIdClientAndTypeDoc`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        numCommandeClient: selectedClient,
        listTypeDocumentEureka: selectedType,
      }),
    }
  );
  if (response.ok) {
    // Doit impérativement être commande et lignes, l'api renvoyant ces données nommées en json
    const { result: commandList, isSuccess, errorDetail } = await response.json();
    return { commandList, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchBillOrder = async (idOrder) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/GetFactureByNumCommande`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        numCommande: idOrder,
      }),
    }
  );
  if (response.ok) {
    // Doit impérativement être commande et lignes, l'api renvoyant ces données nommées en json
    const { result: billList, isSuccess, errorDetail } = await response.json();
    return { billList, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchArticleBill = async (billNumber) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/GetArticlesByNumFacture`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        NUMERO_CLIENT_FACTURE_EUREKA: billNumber,
      }),
    }
  );
  if (response.ok) {
    // Doit impérativement être commande et lignes, l'api renvoyant ces données nommées en json
    const { result: articleListe, isSuccess, errorDetail } = await response.json();
    return { articleListe, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchOrder =
  (numCommande) =>
  async (filters = {}) => {
    const body = { ...filters };
    if (!isNil(numCommande)) {
      body.numCommande = numCommande;
    }
    const response = await fetch(`${BACKEND_URL}/api/Command/GetCommande`, {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body),
    });
    if (response.ok) {
      // Doit impérativement être commande et lignes, l'api renvoyant ces données nommées en json
      const { commande, lignes, statut, isSuccess, errorDetail } = await response.json();

      return { items: { commande, lignes, statut }, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const createOrder = async (
  idClient = null,
  values,
  deliveryDate,
  isContrat
) => {
  const response = await fetch(`${BACKEND_URL}/api/Command/CreateCommande`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      numero_client_eureka: idClient ? idClient : undefined,
      ...values,
      dateLivraison: deliveryDate ? deliveryDate : undefined,
      isContrat,
    }),
  });

  if (response.ok) {
    // Doit impérativement être items, l'api renvoi un objet json contenant items
    const { items, isSuccess, errorDetail } = await response.json();
    return { item: items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const deleteArticle = async (id, numCommande) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/RemoveLineFromCommande`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ id, numCommande }),
    }
  );

  if (response.ok) {
    // Doit impérativement être message, objet nommé reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();
    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const activateDevice = async (serial, clientID) => {
  const response = await fetch(`${BACKEND_URL}/api/Device/MiseEnRouteDevice`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ Serial: serial, IdClient: clientID }),
  });

  if (response.ok) {
    // Doit être impérativement message, objet reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();
    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const transferDeviceNoConnected = async (
  codeArticle,
  numeroLot,
  codeClientEureka,
  commissioning
) => {
  let article = { codeArticle };
  const response = await fetch(
    `${BACKEND_URL}/api/Device/transferdevicenoconnected`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        article,
        numeroLot,
        codeClientEureka,
        commissioning,
      }),
    }
  );

  if (response.ok) {
    // Doit être impérativement message, objet reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();
    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Fonction en charge de déclencher la garantie
export const declencherGarantie = async (
  props,
  isConnectedDevice,
  guaranteeIsValidate,
  warrantyStartDate
) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Device/validationDeviceGuarantee`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        deviceGuaranteeId: props.lotGuaranteeId,
        guaranteeIsValidate,
        numeroLot:
          warrantyStartDate && !isConnectedDevice ? props.numeroLot : undefined,
        serial:
          warrantyStartDate && isConnectedDevice ? props.serial : undefined,
        warrantyStartDate,
      }),
    }
  );

  if (response.ok) {
    // Doit être impérativement message, objet reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();
    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const deleteOrder = async (numCommande) => {
  const response = await fetch(`${BACKEND_URL}/api/Command/DeleteCommande`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ numCommande }),
  });

  if (response.ok) {
    // Doit être impérativement items, celui-ci étant un objet du json reçu
    const { items, isSuccess, errorDetail } = await response.json();
    return { item: items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const submitOrder = async (numCommande, telephone) => {

  const response = await fetch(`${BACKEND_URL}/api/Command/SubmitCommande`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ numCommande, telephone }),
  });

  if (response.ok) {
    // Doit impérativement être items, celui-ci étant dans l'objet json reçu
    const { items, isSuccess, errorDetail } = await response.json();
    return { item: items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const addLineToOrder = async (numCommande, Pdr, quantity) => {
  const response = await fetch(`${BACKEND_URL}/api/Command/AddLineToCommande`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      numCommande,
      codeArticle: Pdr.codeArticle,
      quantite: quantity,
    }),
  });

  if (response.ok) {
    // Doit impérativement être items, celui-ci étant un objet nommé du json reçu
    const { items, isSuccess, errorDetail } = await response.json();
    return { item: items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const updateLineInOrder = async (
  numCommande,
  Pdr,
  quantity,
  comment
) => {
  // console.log(Pdr);
  const response = await fetch(
    `${BACKEND_URL}/api/Command/UpdateLineInCommande`,
    // `${BACKEND_URL}/api/Command/AddLineToCommande`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        id: Pdr.id,
        codeArticle: Pdr.codeArticle,
        numCommande,
        quantite: quantity,
        commentaire: comment,
      }),
    }
  );

  if (response.ok) {
    // Doit impérativement être items, celui-ci étant un objet nommé du json reçu
    const { items, isSuccess, errorDetail } = await response.json();
    return { item: items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const updateOrderCustomer = async (
  numCommande,
  values,
  deliveryDate
) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/updateOrderCustomer`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        numCommande,
        ...values,
        dateLivraison: deliveryDate ? deliveryDate : undefined,
      }),
    }
  );
  if (response.ok) {
    // Doit impérativement être items, celui-ci étant un objet nommé du json reçu
    const { items, isSuccess, errorDetail } = await response.json();
    return { item: items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const getCyclesForDevice = async (serial, maxResult) => {
  const response = await fetch(`${BACKEND_URL}/api/Data/getCyclesForDevice`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ serial, maxResult }),
  });

  if (response.ok) {
    // Doit être impérativement infoMachine et cycles, objets nommés reçu en json
    const { infoMachine, cycles, isSuccess, errorDetail } = await response.json();
    return { item: { infoMachine, cycles }, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const deleteCycle = async (cycle, serial) => {
  const response = await fetch(`${BACKEND_URL}/api/Data/DeleteCycleForDevice`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ cycleId: cycle, serial }),
  });

  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { message: item, isSuccess, errorDetail } = await response.json();
    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const getCsvDataForCycle = async (serial, cycleId) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Data/getCsvDataForCycle?serial=${serial}&cycleId=${cycleId}`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-type": "application/text",
      },
    }
  );

  if (response.ok) {
    const data = await response.text();

    return { item: data, isSuccess: true };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchMachineData = async (serial, lang) => {
  const fetchLang = lang ? lang.toUpperCase() : "EN";
  const result = await fetch(
    `${BACKEND_URL}/api/data/getdevicedata?serial=${serial}&parsing=1&lang=${fetchLang}`,
    {
      credentials: "include",
      method: "GET",
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-type": "application/text",
      },
    }
  );

  if (200 === result.status) {
    const jsonRes = await result.json();
    const metricsArray = jsonRes.map(({ date, ...values }) => values);

    return merge(...metricsArray);
  }

  return {};
};
export const CheckCanShowGTAAndReturnIdAgent = async (id) => {
  const response = await fetch(`${BACKEND_URL}/api/Device/CheckCanShowGTAAndReturnIdAgent`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      id
    }),
  });

  if (response.ok) {
    const data = await response.json();
    // console.log(data);
    return { item: data, isSuccess: true };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
export const Gta = async (id) => {
  const response = await fetch(`${BACKEND_URL}/api/LinkManager/Gta`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      id
    }),
  });
 
  if (response.ok) {
    const data = await response.json();

    return { item: data, isSuccess: true };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
export const triggerMachineOrderRestart = async (
  serial,
  value,
  inRequest,
  inVariable,
  metric
) => {
  const response = await fetch(
    `${BACKEND_URL}/api/OrdresMachines/envoiOrdreMachine`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        content: {
          metric: metric,
          serial,
          "in-request": inRequest,
          "in-variable": inVariable,
          values: {
            [inVariable]: value,
          },
          step: false,
        },
      }),
    }
  );

  if (response.ok) {
    const { ordreResult, isSuccess, errorDetail } = await response.json();
    return { ordreResult, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

const getHistoric = async (name, serial, cycleId) => {
  const response = await fetch(`${BACKEND_URL}/api/Historic/getHistoric`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      cycleId,
      name,
      serial,
    }),
  });

  if (response.ok) {
    const data = await response.text();

    return { item: data, isSuccess: true };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const getHistoricGraphData = async (serial, cycleId) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Historic/getHistoDataForGraph`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        cycleId,
        serial,
      }),
    }
  );

  if (response.ok) {
    const { histoGraph, isSuccess, errorDetail } = await response.json();
    return { histoGraph, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const downloadCycleArchive = (serial, cycleId) =>
  getHistoric("usp_GetHistoHistoCycle", serial, cycleId);
export const downloadCycleReport = (serial, cycleId) =>
  getHistoric("usp_GetHistoBilanCycle", serial, cycleId);
export const downloadCycleDefaults = (serial, cycleId) =>
  getHistoric("usp_GetHistoHistoDefaults", serial, cycleId);
export const downloadCycleProcess = (serial, cycleId) =>
  getHistoric("usp_GetHistoHistoProcess", serial, cycleId);

export const getHistoricDefault =
  () =>
  async (filters = {}) => {
    const body = { ...filters };
    const response = await fetch(
      `${BACKEND_URL}/api/Historic/searchHistoDefault`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      }
    );

    if (response.ok) {
      // Doit être impérativement histo et isSuccess, objets nommés reçu en json
      const { histo: items, isSuccess, errorDetail } = await response.json();
      return { items, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const getHistoricDefaultSynthesis =
  () =>
  async (filters = {}) => {
    const body = { ...filters };
    const response = await fetch(
      `${BACKEND_URL}/api/Historic/searchHistoDefaultStats`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      }
    );

    if (response.ok) {
      // Doit être impérativement histo et isSuccess, objets nommés reçu en json
      const { histo: items, isSuccess, errorDetail } = await response.json();
      return { items, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const getHistoricProcess =
  () =>
  async (filters = {}) => {
    const body = { ...filters };
    const response = await fetch(
      `${BACKEND_URL}/api/Historic/searchHistoProcess`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      }
    );

    if (response.ok) {
      // Doit être impérativement histo et isSuccess, objets nommés reçu en json
      const { histo: items, isSuccess, errorDetail } = await response.json();
      return { items, isSuccess, errorDetail };
    }

    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

export const fetchLicensesTypes = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/License/getTypesLicenseWithFamily`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    // Doit être impérativement typesLicense, objet nommé reçu en json
    const { typesLicense: item, isSuccess, errorDetail } = await response.json();
    return { item, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchProfils = async () => {
  const response = await fetch(`${BACKEND_URL}/api/Profils/getProfils`, {
    credentials: "include",
    method: "GET",
    headers: getHeaders(),
  });
  if (response.ok) {
    // Doit être impérativement profils, objet nommé reçu en json
    const { profils: items, isSuccess, errorDetail } = await response.json();

    return { items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchDirectory = async () => {
  const response = await fetch(`${BACKEND_URL}/api/Data/getAllAnnuaire`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({}),
  });
  if (response.ok) {
    // Doit être impérativement annuaire, objet nommé reçu en json
    const { annuaire, isSuccess, errorDetail } = await response.json();

    return { items: annuaire, total: annuaire.length, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const getUsersForConcessionnaireClient = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/User/getUsersForConcessionnaireClient`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    // Doit être impérativement userList, objet reçu en json
    const { userList, isSuccess, errorDetail } = await response.json();

    return { items: userList, total: userList.length, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchDocuments = async () => tree;

export const fetchPDF = async(NomFichier)=>{
  const response = await fetch(
    `${BACKEND_URL}/api/Document/GetFileTechDocByFilename`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({NomFichier})
    }
  )

  if (response.ok) {
    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob]));
    return {url};
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
}


export const duplicateOrder = async (numCommande) => {
  const response = await fetch(`${BACKEND_URL}/api/Command/DuplicateCommande`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ numCommande }),
  });

  if (response.ok) {
    // Doit être impérativement items, objet nommé reçu en json
    const { items, isSuccess, errorDetail } = await response.json();

    return { item: items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Appel de la route pour récupérer la configuration de notification
export const fetchConfigNotification = async () => {
  const response = await fetch(`${BACKEND_URL}/api/Notification/getconfig`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
  });
  if (response.ok) {
    // Doit être impérativement config, objet reçu en json
    const { config: data, isSuccess } = await response.json();

    return { item: data, isSuccess: true };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Appel de la route pour modifier la configuration notification
export const postAlertConfig = async (
  notificationToken,
  start,
  stop,
  isActivatedAlert,
  language,
  isEmailActivated,
  isSmsActivated
) => {
  const response = await fetch(
    `${BACKEND_URL}/api/notification/config` +
      `?start=${encodeURIComponent(start)}` +
      `&stop=${encodeURIComponent(stop)}` +
      `&isActivated=${encodeURIComponent(isActivatedAlert)}` +
      `&token=${encodeURIComponent(notificationToken)}` +
      `&lang=${language}` +
      `&isEmailActivated=${encodeURIComponent(isEmailActivated)}` +
      `&isSmsActivated=${encodeURIComponent(isSmsActivated)}`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
    }
  );

  if (response.ok) {
    // Doit être impérativement statusCode, objet nommé reçu en json
    // todo: vérifier si isSuccess est récupéré
    const { statusCode: item } = await response.json();

    return { item, isSuccess: true };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Appel de la route pour récupérer les ProgressIcon du template-list
export const fetchAllProgressIconOfTemplateList = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/Template/getAllProgressIconWithChildsForTemplateList`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    // Peut être n'importe quel nom, objet reçu en json
    const { progressIcons: data, isSuccess, errorDetail } = await response.json();

    return { items: data, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to get FAQ list
export const fetchFAQ = async (locale) => {
  const localeExist = locales.hasOwnProperty(locale);

  const response = await fetch(`${BACKEND_URL}/api/Faq/getcompletefaq`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({
      lang: localeExist ? locale : "en",
    }),
  });
  if (response.ok) {
    // Peut être n'importe quel nom, objet reçu en json
    const { faq, isSuccess, errorDetail } = await response.json();
    return { faq, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to get flash info list
export const fetchFlashInfos = async (locale) => {
  const response = await fetch(`${BACKEND_URL}/api/Dynamic/getAllFlashInfos`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      lang: locale,
    }),
  });
  if (response.ok) {
    // Peut être n'importe quel nom, objet reçu en json
    const { result, isSuccess, errorDetail } = await response.json();
    return { result, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used to add read flash info
export const readFlashInfos = async (idFlash) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/addFlashInfoReadByGuidContact`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        GuidFlash: idFlash,
      }),
    }
  );
  if (response.ok) {
    // Peut être n'importe quel nom, objet reçu en json
    const { result, isSuccess, errorDetail } = await response.json();
    return { result, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used to delete read flash info
export const unreadFlashInfos = async (idFlash) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/deleteFlashInfoReadByGuidContact`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        GuidFlash: idFlash,
      }),
    }
  );
  if (response.ok) {
    // Peut être n'importe quel nom, objet reçu en json
    const { result, isSuccess, errorDetail } = await response.json();
    return { result, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to get Catalog document list
export const fetchCatalog = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/Document/getTreeStructureDocumentEBV`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({}),
    }
  );
  if (response.ok) {
    // Peut être n'importe quel nom, objet reçu en json
    const { treeDocuments, isSuccess, errorDetail } = await response.json();
    return { treeDocuments, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to get the incidentHistory
export const fetchIncidentHistory = async () => {
  const response = await fetch(`${BACKEND_URL}/api/Dynamic/getAllIncidents`, {
    credentials: "include",
    method: "POST",
    headers: {
      "Content-type": "application/json",
    },
    body: JSON.stringify({}),
  });
  if (response.ok) {
    const { result: incidents, isSuccess, errorDetail } = await response.json();
    return { incidents, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to get the incidentHistory by year
export const fetchIncidentHistoryByYear = async (year) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/GetIncidentsByYear`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        annee: year,
      }),
    }
  );
  if (response.ok) {
    const { result: incidents, isSuccess, errorDetail } = await response.json();
    return { incidents, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used to get the incident by Id
export const fetchIncidentById = async (incidentId) => {
  const response = await fetch(`${BACKEND_URL}/api/Dynamic/GetIncidentById`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      incidentId,
    }),
  });
  if (response.ok) {
    const { result: incidentInfo, isSuccess, errorDetail } = await response.json();
    return { incidentInfo, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to update incident form
export const updateIncident = async (values, incidentId) => {
  const response = await fetch(`${BACKEND_URL}/api/Dynamic/UpdateIncident`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ ...values, incidentId }),
  });
  if (response.ok) {
    const { result, isSuccess, errorDetail } = await response.json();
    return { result, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to update bon incident form
export const updateBonIncident = async (incidentId, numBon, fichier = null) => {
  const body = new FormData();
  body.append("incidentId", incidentId);
  if (numBon == "bonN1File") {
    body.append("bonN1File", fichier);
  }
  if (numBon == "bonN2File") {
    body.append("bonN2File", fichier);
  }
  const response = await fetch(`${BACKEND_URL}/api/Dynamic/UpdateBonIncident`, {
    credentials: "include",
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body,
  });
  if (response.ok) {
    const { result, isSuccess, errorDetail } = await response.json();
    return { result, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const createAnnotation = async (values, fichier, annotationId) => {
  const body = new FormData();
  body.append("objectGuIdIncident", annotationId);
  body.append("noteText", values.noteText ? values.noteText : "");
  body.append("subject", values.subject);
  if (fichier && fichier.length > 0) {
    fichier.forEach((fichier) => {
      body.append("fichier", fichier);
    });
  }

  const response = await fetch(`${BACKEND_URL}/api/Dynamic/CreateAnnotation`, {
    credentials: "include",
    method: "POST",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body,
  });
  if (response.ok) {
    const { result, isSuccess, errorDetail } = await response.json();
    return { result, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const getMaintenancePage = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/Param/getAllPagePortalWebInMaintenance`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    const { items, isSuccess, errorDetail } = await response.json();
    return { items, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to export a command
export const exportCommand = async (commandNumber) => {
  const response = await fetch(`${BACKEND_URL}/api/Command/ExportCommand`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({
      NumCommande: commandNumber,
    }),
  });
  if (response.ok) {
    const result = await response.text();
    return { result };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to import a command
export const importCommandArcticles = async (commandNumber, csvFile) => {
  const body = new FormData();
  body.append("numCommande", commandNumber);
  body.append("fileCSV", csvFile);

  const response = await fetch(`${BACKEND_URL}/api/Command/ImportCommande`, {
    credentials: "include",
    method: "PUT",
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
    body,
  });
  if (response.ok) {
    const { isSuccess, resultatsImportFails: resultatsImportFails, errorDetail } =
      await response.json();
    return { isSuccess, resultatsImportFails, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to get every files from a specific order
export const fetchFilesOrder = async (id) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/GetListFilesCommande`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ NumCommande: id }),
    }
  );
  if (response.ok) {
    // Doit être impérativement anonymousFiles, objet nommé reçu en json
    const { anonymousFiles: files, isSuccess, errorDetail } = await response.json();

    return { files: files, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchDeliveryDeadlines = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/getListDelaiLivraison`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    // Doit impérativement être items, l'api renvoyant ces données nommées en json
    const { items: deliveryDeadlines, isSuccess, errorDetail } = await response.json();
    return { deliveryDeadlines, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchOrderType = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/getAllTypeCommande`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    const { typeCommandes: orderType, isSuccess, errorDetail } = await response.json();
    return { orderType, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const getTrackingLinks = async (numCommande) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Command/GetTrackingLinksByCommande`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ numCommande }),
    }
  );
  if (response.ok) {
    const { trackingLinks, isSuccess, errorDetail } = await response.json();
    return { trackingLinks, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

export const fetchCustomerByAddressId = async (dynamicsAdresseGuid) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Dynamic/GetAccountByAdresseGuid?dynamicsAdresseGuid=${dynamicsAdresseGuid}`,
    {
      credentials: "include",
      method: "GET",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    const { result, isSuccess, errorDetail } = await response.json();
    return { result, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to export a command
export const fetchDocumentByCodeParent = async (
  codeParent,
  isInMarque,
  isInGamme,
  isInCapcacite,
  isInModele
) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Document/GetEbvDocumentationTypesAndDocumentationByCodeParent`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        codeParent,
        isInMarque,
        isInGamme,
        isInCapcacite,
        isInModele,
      }),
    }
  );
  if (response.ok) {
    const { ebvDocumentationTypes, isSuccess, errorDetail } = await response.json();
    return { ebvDocumentationTypes, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to export pdr
export const fetchPDRByCodeParent = async (
  codeDocumentation,
  codeParent,
  isInMarque,
  isInGamme,
  isInCapcacite,
  isInModele,
  numCommande
) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Document/GetEbvPDRByCodeParent`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({
        codeDocumentation,
        codeParent,
        isInMarque,
        isInGamme,
        isInCapcacite,
        isInModele,
        numCommande,
      }),
    }
  );
  if (response.ok) {
    const { ebvPDRs, isSuccess, errorDetail } = await response.json();
    return { ebvPDRs, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to search articles
export const searchArticles = async (codeArticle, numCommande) => {
  const response = await fetch(`${BACKEND_URL}/api/Document/SearchArticles`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify({ codeArticle, numCommande }),
  });
  if (response.ok) {
    const { articles, isSuccess, errorDetail } = await response.json();
    return { articles, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to search code articles by name
export const searchCodeArticlesByName = async (libelle) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Document/SearchCodeArticlesByName`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ libelle }),
    }
  );
  if (response.ok) {
    const { articles, isSuccess, errorDetail } = await response.json();
    return { refArticles: articles, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to get all docs bye code article
export const getAllDocsByCodeArticle = async (codeArticle) => {
  const response = await fetch(
    `${BACKEND_URL}/api/Document/GetAllDocsByCodeArticle`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ codeArticle }),
    }
  );
  if (response.ok) {
    const { docs, isSuccess, errorDetail } = await response.json();
    return { docs, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};

// Method used to get years created orders
export const fetchYearsCreatedOrders =
  (idClient) =>
  async (filters = {}) => {
    const body = { ...filters };
    if (!isNil(idClient)) {
      body.numero_client_eureka = idClient;
    }
    const response = await fetch(
      `${BACKEND_URL}/api/Command/GetAllAnneesCommandesByNumClient`,
      {
        credentials: "include",
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      }
    );
    if (response.ok) {
      // Doit être impérativement items, objet nommé reçu en json
      const { items, isSuccess, errorDetail } = await response.json();
      return { items, isSuccess, errorDetail };
    }
    return responseErrorAPI({
      statusText: response.statusText,
      httpCode: response.status,
    });
  };

// Method used to get all info dropDown user
export const fetchInfoDropDownUser = async (locale) => {
  const response = await fetch(
    `${BACKEND_URL}/api/User/GetInfoDropDownUser?lang=${locale}`,
    {
      credentials: "include",
      method: "GET",
    }
  );
  if (response.ok) {
    const { result: infoDropDown, isSuccess, errorDetail } = await response.json();
    return { infoDropDown, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used to know if user completed information required
export const infoCompleted = async (userID) => {
  const response = await fetch(
    `${BACKEND_URL}/api/User/GetInfoCompleted?userid=${userID}`,
    {
      credentials: "include",
      method: "GET",
    }
  );
  if (response.ok) {
    // Doit être impérativement message, objet nommé reçu en json
    const { result: message, isSuccess, errorDetail } = await response.json();
    return { message, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used to get every criticites
export const fetchAllCriticites = async () => {
  const response = await fetch(`${BACKEND_URL}/api/Dynamic/GetAllCriticites`, {
    credentials: "include",
    method: "POST",
    headers: getHeaders(),
  });
  if (response.ok) {
    // Doit être impérativement anonymousFiles, objet nommé reçu en json
    const { result, isSuccess, errorDetail } = await response.json();

    return { result, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used to get every criticites for a network request
export const getListCriticites = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/EchangeReseau/GetListCriticites`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    const { result: criticites, isSuccess, errorDetail } = await response.json();

    return { criticites, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used to get every occasion type for a networks request
export const getListOccasions = async () => {
  const response = await fetch(
    `${BACKEND_URL}/api/EchangeReseau/GetListOccasions`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
    }
  );
  if (response.ok) {
    const { result: occasions, isSuccess, errorDetail } = await response.json();

    return { occasions, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used create a networks request
export const createDemande = async (values, statut) => {
  const response = await fetch(
    `${BACKEND_URL}/api/EchangeReseau/CreateDemande`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ ...values, statut }),
    }
  );
  if (response.ok) {
    const { items: id, isSuccess, errorDetail } = await response.json();
    return { id, isSuccess, errorDetail };
  }

  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used to get letwork list depanding on parameter ( my network request, network requeste assing, other network request
export const getListEchangesReseau = async (vue, nbShow = null) => {
  const response = await fetch(
    `${BACKEND_URL}/api/EchangeReseau/GetListEchangesReseau`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ vue, nbShow }),
    }
  );
  if (response.ok) {
    const { items: listEchangesReseau, isSuccess, errorDetail } = await response.json();

    return { listEchangesReseau, isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
// Method used to get letwork list depanding on parameter ( my network request, network requeste assing, other network request
export const updateStatutDemandeEchangeReseau = async (id, statut) => {
  const response = await fetch(
    `${BACKEND_URL}/api/EchangeReseau/UpdateStatutDemandeEchangeReseau`,
    {
      credentials: "include",
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify({ id, statut }),
    }
  );
  if (response.ok) {
    const { isSuccess, errorDetail } = await response.json();
    return { isSuccess, errorDetail };
  }
  return responseErrorAPI({
    statusText: response.statusText,
    httpCode: response.status,
  });
};
