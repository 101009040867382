import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Form, Icon, Input, notification, Upload, Select, Spin, Switch } from 'antd';
import { REGEX_TVA } from '../../utils/constants';
import { locales } from '../../translations';
import { formItemLayout } from '../../utils/formItemLayout';
import { REGEX_ONLY_CARACTERE_LATIN, REGEX_CSV_SEMICOLON } from '../../utils/constants';
import { debounce, isNil } from 'lodash';
import PhoneInput from 'react-phone-number-input';
import {
    checkNameCustomerExist,
    checkSiretExist,
    fetchDynamicsCountries,
    fetchDynamicsCitiesFromCountry,
    fetchDynamicsZipFromCity,
    CheckEmailExistOnCustomer
} from '../../utils/apiBucherVaslin';
import './FormItem.less';

const { Item: FormItem } = Form;
const { Option } = Select;

class ClientFormItems extends Component {
    // onEditMode permet de définir si c'est une mise à jour ou une création (on se base sur le nom client)
    // isOldProspect : permet de vérifier si le client est un prospect (avant modification)
    // car on ne permet pas à un client de devenir prospect, seulement un prospect de devenir client
    state = {
        cities: [],
        newCitySelected: null,
        countries: [],
        dupeState: {
            clientName: false,
            clientSiret: false,
            clientMail: false,
        },
        isLoadingCity: false,
        isLoadingCountry: false,
        isLoadingZip: false,
        isOldProspect: false,
        isProspect: false,
        onEditMode: false,
        selectedCountry: null,
        zipList: [],
        zipListInit: [],
        zipList: [],
        newZipCodeSelected: null
    };

    async componentDidMount() {
        const { initialValues: {codePays, isProspect, nomClient}} = this.props;
        await this.loadCountries();
        await this.setState({isProspect: isProspect || false, onEditMode: !isNil(nomClient), isOldProspect: isProspect || false });
        await this.setCountry(codePays, null);
    }

    cityContainsSimicolon = (_, value, callback) => {
        const {intl} = this.props;
        const {newCitySelected} = this.state;
         if (newCitySelected.includes(';')) {
             callback(intl.formatMessage({id: 'form.validator.invalidCaractereSemicolon'}));
         } else {
             callback();
         }
    }
    loadCountries = async () => {
        await this.setState({isLoadingCountry: true});
        const { countries, isSuccess, isUnauthorized, errorMessage, errorDetail }  = await fetchDynamicsCountries();
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
        await this.setState({countries: countries, isLoadingCountry: false});
    }

    loadCities = async (value) => {
        const {clientPrefix, form, intl} = this.props;
        const countryId = form.getFieldValue(`${clientPrefix}guidCodePays`);

        // Load cities only if search field length > 2
        if(value && value.length > 2) {
            // Load cities corresponding to the selected country
            await this.setState({isLoadingCity: true});

            const { cities, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchDynamicsCitiesFromCountry(countryId, value, 300);
            if (!isSuccess){
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail,
                });
            }

            const citiesToAdd = cities.concat({cityId : "NewCity" , countryId, name : value});
            await this.setState({cities: citiesToAdd, isLoadingCity: false, newCitySelected: value});
        }
    }

    loadZip = async (cityId) => {
        const {intl} = this.props;
        await this.setState({isLoadingZip: true});
        
        const { zipCodes, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchDynamicsZipFromCity(cityId);
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
        
        await this.setState({zipListInit: zipCodes, zipList: zipCodes, isLoadingZip: false});
    }
    /**
     * Method called on blur for the fields who needs to be unique
     * @param {string} field Field decorator used to now on which field we want to check dupe
     */
    checkDupe = async (field) => {
        const { form } = this.props;

        // Copy dupeState to update it later
        const dupeState = JSON.parse(JSON.stringify(this.state.dupeState));
        const fieldValue = form.getFieldValue(field);

        // Check if field display an error and if field display a dupe error
        const errors = form.getFieldError(field);
        const dupeErrors = (errors) ? errors.filter((error) => !error.props) : null;
        // Check for dupe only if another error isn't displayed on this fields
        if (!errors || !dupeErrors || errors.length - dupeErrors.length === 0) {
            switch (field) {
                case "client.nomClient":
                    const { item: itemName } = await checkNameCustomerExist(fieldValue);
                    dupeState.clientName = itemName;
                    await this.setState({dupeState});
                    break;
                case "client.siret":
                    const { item: itemSiret } = await checkSiretExist(fieldValue);
                    dupeState.clientSiret = itemSiret;
                    await this.setState({dupeState});
                    break;
                case "client.email":
                    const { item: clientMail } = await CheckEmailExistOnCustomer(fieldValue);
                    dupeState.clientMail = clientMail;
                    await this.setState({dupeState});
                    break;
            }
        }        
    }

    /**
     * Method used to check for client name duplication
     */
    checkClientNameDuplication = async (_) => {
        // Trigger dupe check
        await this.checkDupe(`client.nomClient`);

        // Get dupeState
        const { intl } = this.props;
        const { dupeState } = this.state;
        const message = intl.formatMessage({id:"client.form.clientName.duplicate"});
        
        if (dupeState.clientName) {
            // Reject if client name is a duplicate
            return Promise.reject(message);
        } else {
            // Resolve if client name isn't a duplicate
            return Promise.resolve();
        }
    }
    /**
     * Method used to check for siret duplication
     */
    checkSiretDuplication = async (_) => {
        // Trigger dupe check
        await this.checkDupe(`client.siret`);

        // Get dupeState
        const { intl } = this.props;
        const { dupeState } = this.state;
        const message = intl.formatMessage({id:"client.form.siret.duplicate"});
        
        if (dupeState.clientSiret) {
            // Reject if siret is a duplicate
            return Promise.reject(message);
        } else {
            // Resolve if siret isn't a duplicate
            return Promise.resolve();
        }
    }

    /**
     * Method used to check for Siret format
     */
    checkSiret = (_, value, callback) => {
        console.log('CheckSiret');
        const { clientPrefix, form: { setFields, getFieldValue } } = this.props;
        const selectedCountry = this.state.selectedCountry;
        const fieldValue = getFieldValue(`${clientPrefix}siret`);
        // console.log(selectedCountry);
        // On vérifie que cela correspond à une chaîne de longueur 14
        if (selectedCountry.codeIso3 === "FRA" && selectedCountry.siretIsObligatoire && (typeof fieldValue !== 'string' || fieldValue.length !== 14)){
            // console.log('France');
            return callback(<FormattedMessage
                id="client.form.SIRETValid"
            />);
        }
        return callback();
    }
    /**
     * Method used to check for email duplication
     */
    checkEmailValidation = async (_) => {
        const { intl } = this.props;
        // Trigger dupe check
        await this.checkDupe(`client.email`);

        // Get dupeState
        const { dupeState } = this.state;
        const message = intl.formatMessage({id:"user.form.emailDuplicate"});
        if (dupeState.clientMail == 2) {
            // Reject if user email is a duplicate
            return Promise.reject(message);
        } else {
            return Promise.resolve();
        }
    }

    onChangeCountry = async (value) => {
        // Update country value
        await this.setCountry(null, value);
    }

    onChangeCity = async (value) => {
        const {cities} = this.state;
        const city = cities.filter((city) => city.cityId == "NewCity");
        const cityToAdd = city[0].name;
        this.setState({
            newCitySelected : cityToAdd
        });
        // Update city value
        await this.setCity(null, value);

        if(value != "" && value != "NewCity") {
            // Load Zip List
            await this.loadZip(value);
        }
    }

    loadNewZip = async (value) => {
        const {zipListInit} = this.state; 
        const zipToAdd = zipListInit.concat({postalCodeId : "NewZipCode" , name : value}) ;
        await this.setState({zipList: zipToAdd, newZipCodeSelected: value});
    }

    onChangeZip = async (value) => {
        // Update Zip value
        await this.setZip(value);
    }

    setCountry = async (codePays, guidPays) => {
        const { countries } = this.state;
        const { clientPrefix } = this.props;

        // Get country data
        let country;
        if(guidPays) {
            country = countries.find((c) => c.idPays === guidPays);

            // Update form
            const fieldName = `${clientPrefix}codePays`;
            this.props.form.setFields({
                [fieldName]: {
                    value: country.codeErpPays
                }
            });
        } else {
            country = codePays && countries.find((c) => c.codePays === codePays);
        }

        // Update State
        await this.setState({
            cities: [],
            selectedCountry: country,
            zipList: []
        });
    }

    setCity = async(cityName, guidCity) => {
        const { cities } = this.state;
        const { clientPrefix } = this.props;

        // Get city data
        let city;
        if(guidCity) {
            city = guidCity && cities.find((c) => c.cityId === guidCity);

            // Update form
            const fieldName = `${clientPrefix}ville`;
            this.props.form.setFields({
                [fieldName]: {
                    value: city.name
                }
            });

        } else {
            city = {
                cityId: null,
                name: cityName
            }
        }

        // Update State
        await this.setState({
            zipList: []
        });
    }

    setZip = async(guidZip) => {
        const { zipList } = this.state;
        const { clientPrefix } = this.props;

        // Get zip data
        let zip;
        if(guidZip) {
            zip = guidZip && zipList.find((c) => c.postalCodeId === guidZip);

            // Update form
            const fieldName = `${clientPrefix}codePostal`;
            this.props.form.setFields({
                [fieldName]: {
                    value: zip.name
                }
            });
        };
        // Update State
        await this.setState({
            newZipCodeSelected: zip.name
        });
    }

    render() {
        // Props constant
        const {
            form: { getFieldDecorator, getFieldValue },
            clientPrefix,
            initialValues: {
                nomClient,
                adresseClient1,
                adresseClient2,
                codePostal,
                ville,
                codeLangue,
                codePays,
                pays,
                email,
                email2,
                phoneNumber,
                siret,
                idTva,
                fichiers,
            },
            intl,
            locale,
            loggedIn,
            pageKey
        } = this.props;

        let phoneNumber2 = phoneNumber;
        // State constant
        const { 
            cities, 
            countries, 
            isLoadingCountry,
            isLoadingCity,
            isLoadingZip,
            isProspect, 
            onEditMode, 
            isOldProspect, 
            selectedCountry,
            zipList,
            newCitySelected,
            newZipCodeSelected
        } = this.state;
      //Form variable
        const countryId = getFieldValue(`${clientPrefix}guidCodePays`);
        const cityId = getFieldValue(`${clientPrefix}guidVille`);
        const postalCodeId = getFieldValue(`${clientPrefix}guidCodePostal`);
        // Overrides default value to allow optionnal field depending selected local
        let filesText = intl.formatMessage({ id: 'client.form.fileslist' });
        if ('client.form.fileslist' === filesText) {
            filesText = '';
        }
        
        return (
            <Fragment>
                {/* {(!loggedIn || (onEditMode && isOldProspect)) && (
                    <FormItem>
                        <div className="alert-switches">
                        {getFieldDecorator(`${clientPrefix}isProspect`, {
                            rules: [],
                            initialValue: isProspect,
                        })(
                            <Fragment>
                                <Switch
                                    defaultChecked={isProspect}
                                    onChange={(value) => {this.setState({isProspect: value})}}
                                    className="alert-switch"
                                />
                                <FormattedMessage
                                    id="client.form.prospectMessage"
                                    defaultMessage="Check this option if you don’t own Bucher Vaslin machine and if you wish to try Winect app in demo mode. You’ll have access to all app functionalities with your account.
                                    (You won’t be able to use your own machines, until you complete your account creation.)"
                                />
                            </Fragment>
                        )}
                        </div>
                    </FormItem>
                )} */}

                {/* Company name field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.company"
                            defaultMessage="Company"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}nomClient`, {
                        validate: [
                            {
                                trigger: 'onChange',
                                rules: [
                                    {
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="client.form.companyRequired"
                                                defaultMessage="Please enter your company name"
                                            />
                                        ),
                                    },
                                    {
                                        pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                        message: (<FormattedMessage
                                            id="form.validator.invalidCaracteres"
                                            defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                        />),
                                    },
                                    {
                                        pattern: new RegExp(REGEX_CSV_SEMICOLON),
                                        message: (<FormattedMessage
                                            id="form.validator.invalidCaractereSemicolon"
                                            defaultMessage="Denied character: ';'"
                                        />)
                                    },
                                    {
                                        max: 35,
                                        message: intl.formatMessage({
                                            id: 'validator.field.max'
                                        }, { length: 35}),
                                    },
                                ],
                            }, {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="client.form.companyRequired"
                                                defaultMessage="Please enter your company name"
                                            />
                                        ),
                                    },
                                    {
                                        pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                        message: (<FormattedMessage
                                            id="form.validator.invalidCaracteres"
                                            defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                        />),
                                    },
                                    {
                                        pattern: new RegExp(REGEX_CSV_SEMICOLON),
                                        message: (<FormattedMessage
                                            id="form.validator.invalidCaractereSemicolon"
                                            defaultMessage="Denied character: ';'"
                                        />)
                                    },
                                    {
                                        max: 35,
                                        message: intl.formatMessage({
                                            id: 'validator.field.max'
                                        }, { length: 35}),
                                    },
                                    {
                                        validator: this.checkClientNameDuplication
                                    },
                                ],
                            }
                        ],
                        initialValue: nomClient,
                    })(
                        <Input />,
                    )}
                </FormItem>

                {/* Country field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.country"
                            defaultMessage="Country"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}guidCodePays`, {
                        rules: [{
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="client.form.countryRequired"
                                    defaultMessage="Please enter your company country"
                                />
                            ),
                        }],
                        initialValue: pays,
                    })(
                        <Select
                            loading={isLoadingCountry}
                            showSearch
                            notFoundContent={<Spin size="small" />}
                            optionFilterProp="children"
                            onChange={(value) => this.onChangeCountry(value)}
                        >
                            {countries.map(({ nom, idPays: code }) => (
                                <Option
                                    key={code}
                                    value={code}
                                >
                                    {nom}
                                </Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>

                {/* City field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.city"
                            defaultMessage="City"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}guidVille`, {
                         validate: [
                            {
                                trigger: 'onBlur',
                                rules: [{
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="client.form.cityRequired"
                                            defaultMessage="Please enter your company city"
                                        />
                                    ),
                                },
                                {
                                    validator : this.cityContainsSimicolon
                                }],
                            }],
                        initialValue: ville,
                    })(
                        <Select
                            disabled={!countryId}
                            loading={isLoadingCity}
                            notFoundContent={
                                <span style={{color:"#000000D9"}}>
                                    <Icon type="info-circle" style={{marginRight: "0.5em"}}/>
                                    <FormattedMessage
                                        id="client.create.selectCity.filter"
                                        defaultMessage="Please enter at least 3 characters"
                                    />    
                                </span>  
                            }
                            onSearch={debounce(this.loadCities, 600)}
                            optionFilterProp="children"
                            onChange={(value) => this.onChangeCity(value)}
                            showSearch
                        >
                            {cities.map(({ name, cityId }) => (
                                <Option
                                    key={cityId}
                                    value={cityId}
                                >
                                    {name}
                                </Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>

                {cityId == "NewCity" ? 
                    getFieldDecorator(`city`, {
                        initialValue: newCitySelected
                    })(
                        <Input hidden={true}/>
                    ) : '' }
                {/* ZIP (Postal Code) field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.zipCode"
                            defaultMessage="Zip code"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}guidCodePostal`, {
                        validate: [
                            {
                                trigger: 'onBlur',
                                rules: [{
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="client.form.zipCodeRequired"
                                            defaultMessage="Please enter your company zip code"
                                        />
                                    ),
                                },
                                {
                                    validator: (rule, value, callback) => {
                                        const zip = zipList.filter((zip) => zip.postalCodeId == value);
                                        const zipToAdd = zip[0].name;
                                        const {intl} = this.props;
                                        const {newZipCodeSelected} = this.state;
                                        if (zipToAdd.length >= 12){
                                            return callback( intl.formatMessage({id: 'validator.field.max'}, { length: 12}));
                                        }    
                                        if (newZipCodeSelected.includes(';')) {
                                            return callback(intl.formatMessage({id: 'form.validator.invalidCaractereSemicolon'}));
                                        }       
                                        return callback();
                                    }     
                                },
                                {
                                    validator : this.zipContainsSimicolon
                                }],
                            }],            
                        initialValue: codePostal,
                    })(
                        <Select
                            disabled={!cityId || !countryId}
                            loading={isLoadingZip}
                            notFoundContent={cityId != "NewCity" ? <Spin size="small" /> : ''}
                            optionFilterProp="children"
                            onSearch={debounce(this.loadNewZip, 600)}
                            onChange={(value) => this.onChangeZip(value)}
                            showSearch
                        >
                            {zipList.map(({ name, postalCodeId }) => (
                                <Option
                                    key={postalCodeId}
                                    value={postalCodeId}
                                >
                                    {name}
                                </Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>

                {postalCodeId == "NewZipCode" ? 
                    getFieldDecorator(`postalCode`, {
                        initialValue: newZipCodeSelected
                    })(
                        <Input hidden={true}/>
                    ) : ''}

                {/* Address field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.address"
                            defaultMessage="Address"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}adresseClient1`, {
                        rules: [{
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="client.form.addressRequired"
                                    defaultMessage="Please enter your company address"
                                />
                            ),
                        },
                        {
                            pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaracteres"
                                defaultMessage="Characters are invalid. Only Latin characters are allowed"
                            />),
                        },
                        {
                            pattern: new RegExp(REGEX_CSV_SEMICOLON),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaractereSemicolon"
                                defaultMessage="Denied character: ';'"
                            />)
                        },
                        {
                            max: 150,
                            message: intl.formatMessage({
                                id: 'validator.field.max'
                            }, { length: 150}),
                        }],
                        initialValue: adresseClient1,
                    })(
                        <Input />,
                    )}
                </FormItem>

                {/* Additional Adress field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.additionalAddress"
                            defaultMessage="Additional address"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}adresseClient2`, {
                        rules: [{
                            pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaracteres"
                                defaultMessage="Characters are invalid. Only Latin characters are allowed"
                            />),
                        },
                        {
                            pattern: new RegExp(REGEX_CSV_SEMICOLON),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaractereSemicolon"
                                defaultMessage="Denied character: ';'"
                            />)
                        },
                        {
                            max: 150,
                            message: intl.formatMessage({
                                id: 'validator.field.max'
                            }, { length: 150}),
                        }],
                        initialValue: adresseClient2,
                    })(
                        <Input />,
                    )}
                </FormItem>
                <FormItem
                {...formItemLayout}
                label={(  pageKey == "newCustomer" ?
                <FormattedMessage
                    id="user.form.emailCompany"
                    defaultMessage="Company email"
                /> : 
                <FormattedMessage
                id="user.form.email"
                defaultMessage="Email"
                />
                )}
                >
                {getFieldDecorator(`${clientPrefix}email`, {
                    validate: [{
                        trigger: 'onChange',
                        rules: [
                            {
                                required: true,
                                message: (<FormattedMessage
                                    id="user.form.emailRequired"
                                    defaultMessage="Please enter your e-mail"
                                />),
                            },
                            {
                                type: 'email',
                                message: (<FormattedMessage
                                    id="user.form.emailValid"
                                    defaultMessage="Please enter a valid e-mail"
                                />),
                            },
                            {
                                pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                message: (<FormattedMessage
                                    id="form.validator.invalidCaracteres"
                                    defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                />),
                            }
                        ]},
                        {
                            trigger: 'onBlur',
                            rules: [
                                {
                                    required: true,
                                    message: (<FormattedMessage
                                        id="user.form.emailRequired"
                                        defaultMessage="Please enter your e-mail"
                                    />),
                                },
                                {
                                    type: 'email',
                                    message: (<FormattedMessage
                                        id="user.form.emailValid"
                                        defaultMessage="Please enter a valid e-mail"
                                    />),
                                },
                                {
                                    pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                    message: (<FormattedMessage
                                        id="form.validator.invalidCaracteres"
                                        defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                    />),
                                },
                                {
                                    validator: this.checkEmailValidation
                                }
                            ]
                        }
                    ],
                    initialValue: email,
                })(
                    <Input />,
                )}
                </FormItem>
                    <FormItem
                        {...formItemLayout}
                        label={(  pageKey == "newCustomer" ?
                        <FormattedMessage
                            id="user.form.phoneNumberCompany"
                            defaultMessage="Company phone"
                        /> :
                        <FormattedMessage
                        id="user.form.phoneNumber"
                        defaultMessage="Phone Number"
                        />
                )}
                >
                {getFieldDecorator(`${clientPrefix}phoneNumber`, {
                    rules: [{
                        required: true,
                        message: (<FormattedMessage
                            id="user.form.phoneNumberRequired"
                            defaultMessage="Please enter your phone number"
                        />),
                    }],
                    initialValue: phoneNumber,
                })(
                    <PhoneInput
                        onChange={ phone => {phoneNumber2=phone;} }
                        international={true}
                        //country={'FR'}
                        displayInitialValueAsLocalNumber
                        limitMaxLength={true}
                    />,
                )}
                </FormItem>
                {/* Language field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.language"
                            defaultMessage="Language"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}codeLangue`, {
                        rules: [{
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="client.form.languageRequired"
                                    defaultMessage="Please enter the language"
                                />
                            ),
                        }],
                        initialValue: (codeLangue && codeLangue.toLowerCase()) || locale,
                    })(
                        <Select>
                            {Object.keys(locales).map((key) => (
                                <Option
                                    key={key}
                                    value={locales[key]}
                                >
                                    <FormattedMessage
                                        id={`menu.lang.${key}`}
                                        defaultMessage={key}
                                    />
                                </Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>

                {/* Siret Field (france country only) */}
                    <FormItem
                        {...formItemLayout}
                        label={(
                            <FormattedMessage
                                id="client.form.SIRET"
                                defaultMessage="SIRET"
                            />
                        )}
                    >
                        {getFieldDecorator(`${clientPrefix}siret`, {
                            validate: [
                                {
                                    trigger: 'onChange',
                                    rules: !isProspect ? [
                                        {
                                            validator: this.checkSiret,
                                        },
                                        {
                                            required: selectedCountry ? selectedCountry.siretIsObligatoire : false,
                                            message: (
                                                <FormattedMessage
                                                    id="client.form.SIRETRequired"
                                                    defaultMessage="Please enter a SIRET number"
                                                />
                                            ),
                                        },
                                    ] : [
                                        {
                                            validator: this.checkSiret,
                                        },
                                    ],
                                }, {
                                    trigger: 'onBlur',
                                    rules: !isProspect ? [
                                        {
                                            validator: this.checkSiret,
                                        },
                                        {
                                            validator: this.checkSiretDuplication
                                        },
                                        {
                                            required: selectedCountry ? selectedCountry.siretIsObligatoire : false,
                                            message: (
                                                <FormattedMessage
                                                    id="client.form.SIRETRequired"
                                                    defaultMessage="Please enter a SIRET number"
                                                />
                                            ),
                                        },
                                    ] : [
                                        {
                                            validator: this.checkSiret,
                                        },
                                        {
                                            validator: this.checkSiretDuplication
                                        }
                                    ],
                                }
                            ],
                            initialValue: siret,
                        })(
                            <Input />,
                        )}
                    </FormItem>

                {/* VAT field */}
                {selectedCountry && selectedCountry.tvaIsObligatoire ? (
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.idTVA"
                            defaultMessage="idTVA"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}idTva`, {
                        initialValue: idTva,
                        rules: [!isProspect && {		
                            required: true,		
                            message: (		
                                <FormattedMessage		
                                    id="client.form.tvaRequired"		
                                    defaultMessage="Please enter the VTA"		
                                />		
                            )		
                        },
                        { 
                            pattern: new RegExp(REGEX_TVA), 
                            message: (<FormattedMessage
                                id="form.validator.invalidTva"
                                defaultMessage="Only numbers and letters are allowed"
                            />)
                        },
                        { 
                            max: 14, 
                            message: intl.formatMessage({id: 'user.form.commentMaxCarac'},{ max : 14 })
                        }]
                    })(
                        <Input />,
                    )}
                </FormItem>
                ) :null}

                {pageKey !== "newCustomer" ? null : (       
                    <FormItem
                    {...formItemLayout}
                    label={(  
                    <FormattedMessage
                        id="client.addressModal.emailBilling"
                        defaultMessage="Billing email"
                    /> 
                    )}
                    >
                    {getFieldDecorator(`email2`, {
                        validate: [{
                            trigger: 'onChange',
                            rules: [
                                {
                                    required: true,
                                    message: (<FormattedMessage
                                        id="client.addressModal.emailBillingRequired"
                                        defaultMessage="Please enter your Billing e-mail"
                                    />),
                                },
                                {
                                    type: 'email',
                                    message: (<FormattedMessage
                                        id="user.form.emailValid"
                                        defaultMessage="Please enter a valid billing e-mail"
                                    />),
                                },
                                {
                                    pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                    message: (<FormattedMessage
                                        id="form.validator.invalidCaracteres"
                                        defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                    />),
                                }
                            ]},
                            {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        message: (<FormattedMessage
                                            id="client.addressModal.emailBillingRequired"
                                            defaultMessage="Please enter your billing e-mail"
                                        />),
                                    },
                                    {
                                        type: 'email',
                                        message: (<FormattedMessage
                                            id="user.form.emailValid"
                                            defaultMessage="Please enter a valid billing e-mail"
                                        />),
                                    },
                                    {
                                        pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                        message: (<FormattedMessage
                                            id="form.validator.invalidCaracteres"
                                            defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                        />),
                                    },
                                    // {
                                    //     validator: this.checkEmailValidation
                                    // }
                                ]
                            }
                        ],
                        initialValue: email2,
                    })(
                        <Input />,
                    )}
                    </FormItem>
                )} 

                {/* Files field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <span>
                            <FormattedMessage
                                id="client.form.files"
                                defaultMessage="Files"
                                style={{ marginRight: '5px' }}
                            />
                        </span>
                    )}
                    extra={filesText}
                >
                    {getFieldDecorator(`${clientPrefix}fichiers`, {
                        initialValue: fichiers,
                    })(
                        <Upload
                            multiple
                            beforeUpload={() => false}
                        >
                            <Button>
                                <Icon type="upload" />
                                <FormattedMessage id="client.form.addfiles" />
                            </Button>
                        </Upload>,
                    )}
                </FormItem>
            

                {/* Hidden fields required after dynamics transition */}
                <FormItem
                    style={{display: 'none'}}   
                >
                    {getFieldDecorator(`${clientPrefix}codePays`, {
                        initialValue: codePays,
                    })(
                        <Input />,
                    )}
                </FormItem>

                <FormItem
                    style={{display: 'none'}}   
                >
                    {getFieldDecorator(`${clientPrefix}ville`, {
                        initialValue: ville,
                    })(
                        <Input />,
                    )}
                </FormItem>

                <FormItem
                    style={{display: 'none'}}   
                >
                    {getFieldDecorator(`${clientPrefix}codePostal`, {
                        initialValue: codePostal,
                    })(
                        <Input />,
                    )}
                </FormItem>
            </Fragment>
        );
    }
}

ClientFormItems.defaultProps = {
    clientPrefix: '',
    initialValues: {},
};

ClientFormItems.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    clientPrefix: PropTypes.string,
    initialValues: PropTypes.shape({}),
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    loggedIn: PropTypes.bool,
    pageKey: PropTypes.string
};

const mapStateToProps = ({ app: { locale },signIn: { loggedIn },
 }) => ({ locale, loggedIn });

export default connect(mapStateToProps)(injectIntl(ClientFormItems));
