import moment from 'moment';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from 'antd';
import { infoCompleted } from './apiBucherVaslin';

export function exportToCSV(filename, data, filePrefix = '') {
    const link = document.createElement('a');
    const csvData = new Blob([data], { type: 'text/csv' });
    const csvUrl = URL.createObjectURL(csvData);
    link.href = csvUrl;
    link.target = '_blank';
    link.setAttribute('download', `${filePrefix}-${filename}.csv`);
    // Fix mozilla : without file not downloaded
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

export function isSuperAdmin({ userRole }) {
    return userRole != null && userRole.includes('Admin');
}

export function isGroupAdminClient({ userRole }) {
    return userRole != null && (userRole.includes('AdminClient') || userRole.includes('Admin'));
}

export function isGroupProspect({ userRole }) {
    return userRole != null && (userRole.includes('Prospect') || userRole.includes('Admin'));
}

export function isGroupRR({ userRole }) {
    return userRole != null && (userRole.includes('RR')  || userRole.includes('Admin'));
}

export function isGroupConcessionnaire({ userRole }) {
    return userRole != null && (userRole.includes('Concessionnaire')  || userRole.includes('Admin'));
}

export function isGroupBV({ userRole }) {
    return userRole != null && (userRole.includes('BV') || userRole.includes('Admin'));
}

export function isGroupEBV({ userRole }) {
    return userRole != null && (userRole.includes('eBV') || userRole.includes('Admin'));
}

export function isGroupDev({ userRole }) {
    return userRole != null && userRole.includes('Dev');
}

// Fonction permet de vérifier si l'utilisateur peut accéder à une fonctionnalité
// objectToCompare et objectCompared peuvent être des identifiants user/ client/autre 
// dans le cas de fonctionnalités accessibles par eux-mêmes
export function canAccessRight({ rights, nameFeature, objectToCompare = null, objectCompared = null }) {
    if (rights == null || rights.length <= 0) { return false; } // Tableau des droits non initialisé en amont
    let right = rights.filter((right) => right.featureName == nameFeature && right.canAccessWithGroup);
    if (right.length <= 0 ) { return false; } // droit non trouvé
    right = right[0];
    if (right.canAccessWithGroup) { return true; } // Le groupe permet l'accès à la fonctionnalité

    if (right.featureAccessByHimSelf) {
        return objectToCompare != null && objectCompared != null && objectToCompare == objectCompared;
    }

    return false;
}

export function isOrderEditable({ statutCommand }) {
    return (statutCommand) ? statutCommand.toLowerCase() === "draft" : false;
}

export function alphabetically(a, b) {
    if (null === a) {
        return 1;
    }
    if (null === b) {
        return -1;
    }
    if (a === b) {
        return 0;
    }

    return a.localeCompare(b);
}

export function compareNumber(a, b) {
    return a - b; // Trick to returns "-1", "0", "1", instead of a boolean
}

export function compareDate(a, b, format = 'DD/MM/YYYY') {
    const dateA = moment(a, format);
    const dateB = moment(b, format);
    if (dateA < dateB) {
        return -1;
    }
    if (dateA > dateB) {
        return 1;
    }

    return 0;
}

// Transforme une date UTC en date GMT (à l'heure locale)
export function getDateGmt(date) {
    if (date === undefined || date == null) { return date; }
    // Récupère la différence entre le fuseau horaire local et la date UTC
    var timedifference = new Date().getTimezoneOffset();
    const mongoDateFormat = 'YYYYMMDD HH:mm:ss';

    const dateGmt = moment(date, mongoDateFormat);

    return dateGmt.clone().subtract(timedifference, 'minutes').format(mongoDateFormat);
}

export function extensionToType(extension) {
    if (['jpg', 'png', 'gif', 'bmp', 'webp'].includes(extension)) {
        return 'image';
    }
    if (['xls', 'csv'].includes(extension)) {
        return 'excel';
    }
    if ('txt' === extension) {
        return 'text';
    }

    return extension;
}

export const isInfoCompleted = async (intl, userID) => {
        const { message, isSuccess }  =  await infoCompleted(userID);
        if(isSuccess) {
            if(message.length > 0) {
                Modal.error({title: intl.formatMessage({ id: 'warning'}, {default: 'Warning'}, {fontSize: '24px'}),
                    content:  <div dangerouslySetInnerHTML={{__html: message}}></div>
                });
            }
        }
}

