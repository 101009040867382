import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Menu, Tooltip } from "antd";
import { FormattedMessage, intlShape, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import {
  isSuperAdmin,
  isGroupConcessionnaire,
  isGroupEBV,
  isGroupRR,
  isGroupAdminClient,
} from "../../utils/helpers";
import CookieConsent from "react-cookie-consent";

import "flag-icon-css/css/flag-icon.css";
import "./MainMenu.less";

const { SubMenu, Item: MenuItem, ItemGroup } = Menu;

const Languages = (props) => {
  const { currentLocale, handleChangeLocale, subMenuClass, ...other } = props;

  return (
    <SubMenu
      className={subMenuClass}
      title={
        <span
          className={
            "flag-item flag-icon flag-icon-" +
            (currentLocale == "en" ? "gb" : currentLocale)
          }
        ></span>
      }
      {...other}
    >
      <MenuItem key="frLang" onClick={() => handleChangeLocale("fr")}>
        <span className="flag-item flag-icon flag-icon-fr"></span>
        <FormattedMessage id="menu.lang.fr" defaultMessage="French" />
        <LinkMenu id="menu.lang.fr" to="/myProfile" defaultMessage="French" />
      </MenuItem>
      <MenuItem key="enLang" onClick={() => handleChangeLocale("en")}>
        <span className="flag-item flag-icon flag-icon-gb"></span>
        <FormattedMessage id="menu.lang.en" defaultMessage="English" />
        <LinkMenu id="menu.lang.en" to="/myProfile" defaultMessage="English" />
      </MenuItem>
      <MenuItem key="esLang" onClick={() => handleChangeLocale("es")}>
        <span className="flag-item flag-icon flag-icon-es"></span>
        <FormattedMessage id="menu.lang.es" defaultMessage="Español" />
        <LinkMenu id="menu.lang.es" to="/myProfile" defaultMessage="Español" />
      </MenuItem>
      <MenuItem key="deLang" onClick={() => handleChangeLocale("de")}>
        <span className="flag-item flag-icon flag-icon-de"></span>
        <FormattedMessage id="menu.lang.de" defaultMessage="Deutsch" />
        <LinkMenu id="menu.lang.de" to="/myProfile" defaultMessage="Deutsch" />
      </MenuItem>
      <MenuItem key="itLang" onClick={() => handleChangeLocale("it")}>
        <span className="flag-item flag-icon flag-icon-it"></span>
        <FormattedMessage id="menu.lang.it" defaultMessage="Italian" />
        <LinkMenu id="menu.lang.it" to="/myProfile" defaultMessage="Italian" />
      </MenuItem>
    </SubMenu>
  );
};

Languages.propTypes = {
  currentLocale: PropTypes.string,
  handleChangeLocale: PropTypes.func,
  subMenuClass: PropTypes.string.isRequired,
};

// Construit le lien pour chaque item de menu (on a accès au lien seulement si l'on n'est pas un prospect)
const LinkMenu = ({ isProspect, id, to, defaultMessage }) =>
  isProspect ? (
    <FormattedMessage id={id} defaultMessage={defaultMessage} />
  ) : (
    <NavLink to={to}>
      <FormattedMessage id={id} defaultMessage={defaultMessage} />
    </NavLink>
  );

const LoggedInMenu = ({
  clientIsDealer,
  currentLocale,
  handleChangeLocale,
  handleLogout,
  menuClass,
  menuItemClass,
  mode,
  pageKey,
  subMenuClass,
  userName,
  userRole,
  isProspect,
  intl,
}) => (
  <Menu
    className={menuClass}
    selectedKeys={[pageKey]}
    mode={"desktop" === mode ? "horizontal" : "inline"}
  >
    {/* E-Market Sub Menu */}
    {!isProspect && (
      <SubMenu
        className={subMenuClass}
        title={<FormattedMessage id="menu.eMarket" defaultMessage="e-Market" />}
      >
        {isGroupEBV({ userRole }) && (
          <MenuItem className={menuItemClass} key="orders">
            <LinkMenu
              id="menu.eMarket.orderStatus"
              to="/orders"
              defaultMessage="My orders"
            />
          </MenuItem>
        )}
        <MenuItem className={menuItemClass} key="ordersLicense">
          <LinkMenu
            id="menu.eMarket.orderLicense"
            to="/ordersLicense"
            defaultMessage="Contract and license orders"
          />
        </MenuItem>
        <MenuItem className={menuItemClass} key="servicesCatalogue">
          <LinkMenu
            id="menu.eMarket.services"
            to="/services"
            defaultMessage="Services catalogue"
          />
        </MenuItem>
        {isGroupConcessionnaire({ userRole }) && (
          <MenuItem className={menuItemClass} key="networkRequests">
            <LinkMenu
              id="menu.eMarket.networkRequests"
              to="/networkRequests"
              defaultMessage="Network requests"
            />
          </MenuItem>
        )}
      </SubMenu>
    )}

    {/* Training Sub Menu */}
    <SubMenu
      className={subMenuClass}
      title={<FormattedMessage id="menu.master" defaultMessage="Master" />}
    >
      {/* Classroom Training */}
      <MenuItem
        className={menuItemClass}
        key="classroomTraining"
        title={intl.formatMessage({ id: "menu.master.classroomTraining" })}
      >
        <LinkMenu
          id="menu.master.classroomTraining"
          to="/classroomTraining"
          defaultMessage="Classroom training"
        />
      </MenuItem>

      {/* E-Training */}
      <MenuItem
        className={menuItemClass}
        key="eTraining"
        title={intl.formatMessage({ id: "menu.master.eTraining" })}
      >
        <LinkMenu
          id="menu.master.eTraining"
          to="/eTraining"
          defaultMessage="e-training"
        />
      </MenuItem>

      {/* portail Master */}
      <MenuItem
        className={menuItemClass}
        onClick={(event) =>
          window.open("https://master.buchervaslin.com/", "_blank")
        }
        key="portalMaster"
        title={intl.formatMessage({ id: "menu.master.portalMaster" })}
      >
        {intl.formatMessage({ id: "menu.master.portalMaster" })}
      </MenuItem>
    </SubMenu>

    {/* Winnect Sub Menu */}
    <SubMenu
      className={subMenuClass}
      title={<FormattedMessage id="menu.winect" defaultMessage="Winect" />}
    >
      <MenuItem className={menuItemClass} key="winectGuide" disabled={false}>
        <LinkMenu
          isProspect={false}
          id="menu.winect.winectGuide"
          to="/winectGuide"
          defaultMessage="Winect guide"
        />
      </MenuItem>
      <MenuItem
        className={menuItemClass}
        key="Licenses"
        disabled={isProspect}
        title={
          isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
        }
      >
        <LinkMenu
          isProspect={isProspect}
          id="menu.winect.licensesManagement"
          to="/licenses"
          defaultMessage="Licenses management"
        />
      </MenuItem>
    </SubMenu>

    {/* My Parc Sub Menu */}
    <SubMenu
      className={subMenuClass}
      title={<FormattedMessage id="menu.myParc" defaultMessage="My parc" />}
    >
      <MenuItem
        className={menuItemClass}
        key="myDevices"
        disabled={isProspect}
        title={
          isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : intl.formatMessage({ id: "menu.myParc.myDevices.info" })
        }
      >
        <LinkMenu
          isProspect={isProspect}
          id="menu.myParc.myDevices"
          to="/myDevices"
          defaultMessage="My machines not connected"
        />
      </MenuItem>
      <MenuItem
        className={menuItemClass}
        key="myConnectedDevices"
        disabled={isProspect}
        title={
          isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : intl.formatMessage({ id: "menu.myParc.myDevicesConnected.info" })
        }
      >
        <LinkMenu
          isProspect={isProspect}
          id="menu.myParc.myDevicesConnected"
          to="/myDevicesConnected"
          defaultMessage="My connected devices"
        />
      </MenuItem>
      {(isGroupConcessionnaire({ userRole }) ||
        isGroupRR({ userRole }) ||
        isSuperAdmin({ userRole })) && (
        <MenuItem
          className={menuItemClass}
          key="devicesOfMyCustomers"
          disabled={isProspect}
          title={
            isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
          }
        >
          <LinkMenu
            isProspect={isProspect}
            id="menu.myParc.devicesOfMyCustomer"
            to="/listingParc"
            defaultMessage="My customers' machines"
          />
        </MenuItem>
      )}
    </SubMenu>

    {/* Customers Sub Menu */}
    {(clientIsDealer || isSuperAdmin({ userRole })) && (
      <SubMenu
        className={subMenuClass}
        title={
          <FormattedMessage id="menu.customers" defaultMessage="Customers" />
        }
      >
        <MenuItem
          className={menuItemClass}
          key="customers"
          disabled={isProspect}
          title={
            isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
          }
        >
          <LinkMenu
            isProspect={isProspect}
            id="menu.customers.myCustomers"
            to="/customers"
            defaultMessage="My customers"
          />
        </MenuItem>
        <MenuItem
          className={menuItemClass}
          key="newCustomer"
          disabled={isProspect}
          title={
            isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
          }
        >
          <LinkMenu
            isProspect={isProspect}
            id="menu.customers.newCustomer"
            to="/newCustomer"
            defaultMessage="Create customer"
          />
        </MenuItem>
        <MenuItem
          className={menuItemClass}
          key="usersManagement"
          disabled={isProspect}
          title={
            isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
          }
        >
          <LinkMenu
            isProspect={isProspect}
            id="menu.customers.usersManagement"
            to="/usersManagement"
            defaultMessage="Users management"
          />
        </MenuItem>
      </SubMenu>
    )}

    {/* Support Sub Menu */}
    <SubMenu
      className={subMenuClass}
      title={<FormattedMessage id="menu.support" defaultMessage="Support" />}
    >
      {/* incidents Menu */}
      <MenuItem
        className={menuItemClass}
        key="incidents"
        disabled={isProspect}
        title={
          isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
        }
      >
        <LinkMenu
          isProspect={isProspect}
          id="menu.support.incidents"
          to="/incidents"
          defaultMessage="My Incidents"
        />
      </MenuItem>
      <MenuItem
          className={menuItemClass}
          key="catalog"
          disabled={isProspect}
          title={
            isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : intl.formatMessage({ id: "menu.support.catalog.info" })
          }
        >
          {/* <Tooltip title={
            isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
          } placement="right"> */}
            <LinkMenu
              isProspect={isProspect}
              id="menu.support.catalog"
              to="/catalog"
              defaultMessage="Catalog"
            />
            {/* </Tooltip>  */}
      </MenuItem>
      <MenuItem className={menuItemClass} key="partsUseCases" disabled={false} title ={intl.formatMessage({ id: "menu.support.partsUseCases.info" })}>
        <LinkMenu
          isProspect={false}
          id="menu.support.partsUseCases"
          to="/partsUseCases"
          defaultMessage="Parts use cases"
        />
      </MenuItem>
      <MenuItem className={menuItemClass} key="contact" disabled={false} title={intl.formatMessage({ id: "menu.support.contact.info" })}>
        <LinkMenu
          isProspect={false}
          id="menu.support.contact"
          to="/contact"
          defaultMessage="Contact"
        />
      </MenuItem>
      <MenuItem className={menuItemClass} key="faq" disabled={false}>
        <LinkMenu
          isProspect={false}
          id="menu.footer.faq"
          to="/faq"
          defaultMessage="FAQ"
        />
      </MenuItem>
      {/* <SubMenu
        className={subMenuClass}
        title={
          <FormattedMessage
            id="menu.support.complaints"
            defaultMessage="Complaints"
          />
        }
      >
        <ItemGroup
          title={
            <FormattedMessage
              id="menu.support.complaints.detachedPieces"
              defaultMessage="detached pieces"
            />
          }
        >
          <MenuItem
            className={menuItemClass}
            key="contact1231"
            disabled={false}
          >
            <LinkMenu
              isProspect={false}
              id="menu.support.complaints.detachedPieces.deliveryError"
              to="/contact/12/31"
              defaultMessage="Order delivery error"
            />
          </MenuItem>
          <MenuItem
            className={menuItemClass}
            key="contact1235"
            disabled={false}
          >
            <LinkMenu
              isProspect={false}
              id="menu.support.complaints.detachedPieces.componentDmmgTransport"
              to="/contact/12/35"
              defaultMessage="Component damaged during transport"
            />
          </MenuItem>
          <MenuItem
            className={menuItemClass}
            key="contact1236"
            disabled={false}
          >
            <LinkMenu
              isProspect={false}
              id="menu.support.complaints.detachedPieces.outServicePart"
              to="/contact/12/36"
              defaultMessage="Out of service part"
            />
          </MenuItem>
          <MenuItem className={menuItemClass} key="contact129" disabled={false}>
            <LinkMenu
              isProspect={false}
              id="menu.support.complaints.detachedPieces.otherRequest"
              to="/contact/12/9"
              defaultMessage="Other request"
            />
          </MenuItem>
        </ItemGroup>
        <ItemGroup
          title={
            <FormattedMessage
              id="menu.support.complaints.equipment"
              defaultMessage="Equipment"
            />
          }
        >
          <MenuItem
            className={menuItemClass}
            key="contact1732"
            disabled={false}
          >
            <LinkMenu
              isProspect={false}
              id="menu.support.complaints.equipment.nonFunctionalComponent"
              to="/contact/17/32"
              defaultMessage="Non-functional component to be replaced"
            />
          </MenuItem>
          <MenuItem
            className={menuItemClass}
            key="contact1734"
            disabled={false}
          >
            <LinkMenu
              isProspect={false}
              id="menu.support.complaints.equipment.functionalProblem"
              to="/contact/17/34"
              defaultMessage="Functional problem"
            />
          </MenuItem>
          <MenuItem
            className={menuItemClass}
            key="contact1731"
            disabled={false}
          >
            <LinkMenu
              isProspect={false}
              id="menu.support.complaints.detachedPieces.deliveryError"
              to="/contact/17/31"
              defaultMessage="Order delivery error"
            />
          </MenuItem>
          <MenuItem
            className={menuItemClass}
            key="contact1735"
            disabled={false}
          >
            <LinkMenu
              isProspect={false}
              id="menu.support.complaints.detachedPieces.componentDmmgTransport"
              to="/contact/17/35"
              defaultMessage="Component damaged during transport"
            />
          </MenuItem>
          <MenuItem className={menuItemClass} key="contact179" disabled={false}>
            <LinkMenu
              isProspect={false}
              id="menu.support.complaints.detachedPieces.otherRequest"
              to="/contact/17/9"
              defaultMessage="Other request"
            />
          </MenuItem>
        </ItemGroup>
      </SubMenu> */}
      <SubMenu
        className={subMenuClass}
        title={
          <FormattedMessage id="menu.support.tools" defaultMessage="Tools" />
        }
      >
        <MenuItem
          className={menuItemClass}
          key="metricPreview"
          disabled={isProspect}
          title={
            isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
          }
        >
          <LinkMenu
            isProspect={isProspect}
            id="menu.support.metricPreview"
            to="/metricPreview"
            defaultMessage="Metric Preview"
          />
        </MenuItem>

        {/* {isGroupEBV({ userRole }) && (
                    <MenuItem className={menuItemClass} key="documents" disabled={isProspect} title={(isProspect ? intl.formatMessage({ id: 'menu.prospect.info' }) : "")}>
                        <LinkMenu
                            isProspect={isProspect}
                            id="menu.support.documents"
                            to="/documents"
                            defaultMessage="Documentary base"
                        />
                    </MenuItem>
                )} */}
        <MenuItem
          className={menuItemClass}
          key="directory"
          disabled={isProspect}
          title={
            isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
          }
        >
          <LinkMenu
            isProspect={isProspect}
            id="menu.support.directory"
            to="/directory"
            defaultMessage="OPCUA directory"
          />
        </MenuItem>
      </SubMenu>
      {clientIsDealer && (
        <MenuItem
          className={menuItemClass}
          key="downloads"
          disabled={isProspect}
          title={
            isProspect ? intl.formatMessage({ id: "menu.prospect.info" }) : ""
          }
        >
          <LinkMenu
            isProspect={isProspect}
            id="menu.support.downloads"
            to="/downloads"
            defaultMessage="Downloads"
          />
        </MenuItem>
      )}
    </SubMenu>

    {/* User Sub Menu */}
    <SubMenu
      className={subMenuClass}
      title={
        <FormattedMessage id="menu.myAccount" defaultMessage="My Account" />
      }
    >
      <ItemGroup key="username_group" title={userName}>
        <MenuItem className={menuItemClass} key="myInformation">
          <LinkMenu
            isProspect={false}
            id="menu.myAccount.myInformation"
            to="/myInformation/edition"
            defaultMessage="My customer account"
          />
        </MenuItem>
        <MenuItem className={menuItemClass} key="alert">
          <LinkMenu
            isProspect={false}
            id="menu.notification.myAlerts"
            to="/alert"
            defaultMessage="My alerts"
          />
        </MenuItem>
        {isGroupEBV({ userRole }) && (
          <MenuItem className={menuItemClass} key="flashInfo">
            <LinkMenu
              isProspect={false}
              id="menu.myAccount.myFlashInfo"
              to="/flashInfos"
              defaultMessage="My flash info"
            />
          </MenuItem>
        )}
        <MenuItem className={menuItemClass} key="myUsers" disabled={false}>
          <LinkMenu
            isProspect={false}
            id="menu.myAccount.myUsers"
            to="/myUsers"
            defaultMessage="My users"
          />
        </MenuItem>
        {(isSuperAdmin({ userRole }) || isGroupAdminClient({ userRole })) && (
          <MenuItem className={menuItemClass} key="newUser" disabled={false}>
            <LinkMenu
              isProspect={false}
              id="menu.myAccount.newUser"
              to="/newUser"
              defaultMessage="Create user"
            />
          </MenuItem>
        )}
        <MenuItem className={menuItemClass} key="myProfile" disabled={false}>
          <LinkMenu
            isProspect={false}
            id="menu.myAccount.myProfile"
            to="/myProfile"
            defaultMessage="My user profile"
          />
        </MenuItem>
        <MenuItem className={menuItemClass} key="password" disabled={false}>
          <LinkMenu
            isProspect={false}
            id="menu.myAccount.password"
            to="/resetPassword"
            defaultMessage="Password"
          />
        </MenuItem>
        <MenuItem className={menuItemClass} onClick={handleLogout}>
          <FormattedMessage
            id="menu.myAccount.logOut"
            defaultMessage="Log out"
          />
        </MenuItem>
      </ItemGroup>
    </SubMenu>

    {/* Language Sub Menu */}
    <Languages
      subMenuClass={subMenuClass}
      currentLocale={currentLocale}
      handleChangeLocale={handleChangeLocale}
    />
  </Menu>
);

LoggedInMenu.propTypes = {
  currentLocale: PropTypes.string,
  handleLogout: PropTypes.func.isRequired,
  handleChangeLocale: PropTypes.func,
  pageKey: PropTypes.string.isRequired,
  mode: PropTypes.oneOf(["desktop", "mobile"]).isRequired,
  menuClass: PropTypes.string.isRequired,
  subMenuClass: PropTypes.string.isRequired,
  clientIsDealer: PropTypes.bool,
  userName: PropTypes.string,
  userRole: PropTypes.array,
  isProspect: PropTypes.bool,
  intl: intlShape.isRequired,
};

const NotLoggedInMenu = ({
  pageKey,
  mode,
  menuClass,
  subMenuClass,
  menuItemClass,
  handleChangeLocale,
  currentLocale,
}) => (
  <Menu
    className={menuClass}
    selectedKeys={[pageKey]}
    mode={"desktop" === mode ? "horizontal" : "inline"}
  >
    <MenuItem className={menuItemClass} key="signIn">
      <NavLink to="/signIn">
        <FormattedMessage id="menu.signIn" defaultMessage="Sign in" />
      </NavLink>
    </MenuItem>
    <Languages
      subMenuClass={subMenuClass}
      currentLocale={currentLocale}
      handleChangeLocale={handleChangeLocale}
    />
  </Menu>
);

NotLoggedInMenu.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  pageKey: PropTypes.string.isRequired,
  handleChangeLocale: PropTypes.func,
  mode: PropTypes.oneOf(["desktop", "mobile"]).isRequired,
  menuClass: PropTypes.string.isRequired,
  menuItemClass: PropTypes.string.isRequired,
  subMenuClass: PropTypes.string.isRequired,
};

const MainMenu = ({
  loggedIn,
  mode,
  handleChangeLocale,
  currentLocale,
  isProspect,
  ...props
}) => {
  const menuClass = classNames({
    "desktop-menu": "desktop" === mode,
    "mobile-menu": "mobile" === mode,
  });
  const subMenuClass = classNames({
    "desktop-sub-menu": "desktop" === mode,
    "mobile-sub-menu": "mobile" === mode,
  });
  const menuItemClass = classNames({
    "desktop-menu-item": "desktop" === mode,
    "mobile-menu-item": "mobile" === mode,
  });

  if (loggedIn) {
    const { userRole, intl } = props;

    return (
      <Fragment>
        <LoggedInMenu
          handleChangeLocale={handleChangeLocale}
          mode={mode}
          menuClass={menuClass}
          menuItemClass={menuItemClass}
          subMenuClass={subMenuClass}
          currentLocale={currentLocale}
          userRole={userRole}
          isProspect={isProspect}
          {...props}
          intl={intl}
        />

        {/* Cookie consent */}
        <CookieConsent
          buttonText={<FormattedMessage id="cookie.accept" />}
          location="none"
          style={{
            width: "400px",
            top: "85px",
            left: "20px",
            lineHeight: "20px",
          }}
        >
          <FormattedMessage id="cookie.info" />
        </CookieConsent>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <NotLoggedInMenu
        handleChangeLocale={handleChangeLocale}
        currentLocale={currentLocale}
        mode={mode}
        menuClass={menuClass}
        menuItemClass={menuItemClass}
        subMenuClass={subMenuClass}
        {...props}
      />

      {/* Cookie consent */}
      <CookieConsent
        buttonText={<FormattedMessage id="cookie.accept" />}
        location="none"
        style={{
          width: "400px",
          top: "85px",
          left: "20px",
          lineHeight: "20px",
        }}
      >
        <FormattedMessage id="cookie.info" />
      </CookieConsent>
    </Fragment>
  );
};

MainMenu.propTypes = {
  currentLocale: PropTypes.string.isRequired,
  handleLogout: PropTypes.func.isRequired,
  handleChangeLocale: PropTypes.func,
  pageKey: PropTypes.string.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(["desktop", "mobile"]).isRequired,
  userRole: PropTypes.array,
  isProspect: PropTypes.bool,
};

const mapStateToProps = ({
  signIn: { clientIsDealer, userName, userRole, isProspect },
}) => ({
  clientIsDealer,
  userName,
  userRole,
  isProspect,
});

export default connect(mapStateToProps, null, null, { pure: false })(
  injectIntl(MainMenu)
);
