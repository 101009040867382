import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { getDisplayName } from 'recompose';
import { getMaintenancePage, updateLoggedInStatus } from '../utils/apiBucherVaslin';
import Maintenance from '../components/Maintenance';
import PageLayout from '../components/Layout/PageLayout';

// Duration (in minutes) between two loading of maintenances page list
const maintenanceValidity = 5;

const mapStateToProps = ({
    signIn: {
        loggedIn,
    },
}) => ({ loggedIn });

export default (ComposedComponent, pageKey, requiredLoggedIn) => {
    class Authentication extends Component {
        constructor(props) {
            super(props);
            this.state = {
                loadMaintenance: false,
                maintenancePage: null,
            };
            // Désactivé du à des problèmes de redirection sur la page d'accueil
            //document.addEventListener('click', this.handleClick, false);
            //this.timerClick = Date.now() / 1000;
        }
        static propTypes = {
            loggedIn: PropTypes.bool.isRequired,
            history: PropTypes.shape({
                push: PropTypes.func.isRequired,
            }).isRequired,
        };

        async componentDidMount() {
            // Check if user is logged
            if (requiredLoggedIn) {
                await this.checkLoggedIn();
            }

            // Check if target page is under maintenance
            await this.checkMainteance();
        }

        async componentDidUpdate() {
            // Check if user is logged
            if (requiredLoggedIn) {
                await this.checkLoggedIn();
            }
        }

        async checkLoggedIn() {
            const { loggedIn, history: { push } } = this.props;

            if (!loggedIn) {
                push('/signIn');
            } else {
                await updateLoggedInStatus()
                    .then((tokenValid) => {
                        if (!tokenValid) {
                            push('/signIn', {forcedLogOut: true});
                        }
                    })
                    .catch(() => {
                        push('/signIn', {
                            forcedLogOut: true,
                        });
                    });
            }
        }

        // Check if target page is in maintenance
        async checkMainteance() {
            // Get last maintenances list
            let lastMaintenanceValue = JSON.parse(localStorage.getItem('maintenance'));
            const actualTime = new Date().getTime();
            let maintenancePage = null;

            // reload check on change pages
            await getMaintenancePage().then((result) => {    
                // Set a specific flag in localStorage for maintenance
                const maintenanceObject = {
                    lastLoading: new Date().getTime(),
                    pages: result.items
                }
                
                // Update localstorage value
                localStorage.setItem('maintenance', JSON.stringify(maintenanceObject));

                // Update last maintenance list
                lastMaintenanceValue = maintenanceObject;
            });


            // Check if target page is availabled
            if (lastMaintenanceValue && lastMaintenanceValue.pages && lastMaintenanceValue.pages.length > 0) {
                lastMaintenanceValue.pages.forEach(page => {
                    const pageEndDate = new Date(page.dateEndMaintenance).getTime();

                    // Check if : - one of the pages is our target page
                    //            - maintenance and date is greater than actual time
                    if (page.key === pageKey && pageEndDate > actualTime) {
                        maintenancePage = page;
                    }
                });
            }
            
            // Update maintenance states
            await this.setState({maintenancePage: maintenancePage, loadMaintenance: true});
        }

        // Fonction appelant la vérification de token au moindre click (minimum de 30 secondes d'écart)
        // Est géré automatiquement dans chaque composant recquiérant la connexion de l'utilisateur
        // Désactivé : provoque des redirections sur la page d'accueil (peut-être manque-t-il un redirect si cela vient de
        // la fin de session)
        // handleClick = async () => {
        //     let dateNow = Date.now() / 1000;
        //     if ((this.timerClick + 30) < dateNow){
        //         this.checkLoggedIn();
        //         this.timerClick = dateNow;
        //     }
        // }

        render() {
            const { loggedIn } = this.props;
            const { maintenancePage, loadMaintenance } = this.state;

            // Check if user is logged (for pages who needs to be logged in)
            if (requiredLoggedIn && !loggedIn) {
                return null;
            }

            // Check if maintenance list have been loaded
            if (loadMaintenance) {
                // Check if target page is under maintenance
                if (maintenancePage) {
                    return <Maintenance maintenancePage={maintenancePage} />
                } else {
                    return <ComposedComponent {...this.props} />;
                }
            } else {
                return (
                    <PageLayout flex pageKey="awaitingControl"></PageLayout>
                );
            }
        }
    }

    Authentication.displayName = getDisplayName(ComposedComponent);

    return connect(mapStateToProps)(Authentication);
};
