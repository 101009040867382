import PropTypes from 'prop-types';
import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { FormattedMessage } from 'react-intl';

const sitekey = process.env.CAPTCHA_SITE_KEY;

const RecaptchaItem = ({ form: { getFieldDecorator }, onChange }) => getFieldDecorator('captcha', {
    rules: [{
        required: true,
        message: (
            <FormattedMessage
                id="signIn.captchaMessage"
            />
        ),
    }],
})(
    <ReCAPTCHA
        sitekey={sitekey}
        onChange={onChange}
    />,
);

RecaptchaItem.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    onChange: PropTypes.func,
};

export default RecaptchaItem;
