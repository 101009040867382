import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import { Button, Input, InputNumber, notification, Popover, Spin, Table, DatePicker } from 'antd';
import { getHistoricDefault } from '../../utils/apiBucherVaslin';
import { alphabetically, compareDate } from '../../utils/helpers';
import moment from 'moment';

const tableName = 'histoDefault';

const mongoDateFormat = 'YYYYMMDD HH:mm:ss';
const dateOnlyFormat = 'DD/MM/YY';

const renderDate = (date) => (date ? (
    <Popover content={moment(date, mongoDateFormat).format('DD/MM/YY HH:mm:ss')}>
        <span>{moment(date, mongoDateFormat).format('DD/MM/YY HH:mm:ss')}</span>
    </Popover>
) : (
    '-'
));

class ListDefaultHistorique extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadData: false,
            numberLine: 20,
            histoDefaults: [],
            nameDevice: null,
        };
    }

    async componentDidMount() {
        const { numberLine } = this.state;
        this.fetchDataAsync(numberLine);
    }

    async fetchDataAsync(numberLine) {
        const {
            match: {
                params: { serial },
            },
            location: {
                state: { nameDevice },
            },
            intl,
        } = this.props;
        this.setState({ loadData: false, nameDevice });

        const { items: histoDefaults, isSuccess, isUnauthorized, errorMessage, errorDetail} = await getHistoricDefault()({ serial, numberLine });
        
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }

        this.setState({ loadData: true, histoDefaults });
    }

    onFilter = (value, obj) => {
        if (obj === true || obj === false) {
            return obj == value;
        }
        // Cas des dates : on convertit l'objet avec le même format d'affichage (pour que le match corresponde)
        if (obj && obj.includes('T')){
            obj = moment(obj, mongoDateFormat).format('DD/MM/YY'); 
        }
        return obj && obj.toLowerCase().includes(value.toLowerCase()); 
    };

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    getColumns() {
        return [{
            dataIndex: 'value',
            key: 'value',
            sorter: (a, b) => alphabetically(a.value, b.value),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.value),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'dateAdded',
            key: 'dateAdded',
            sorter: (a, b) => compareDate(a.dateAdded, b.dateAdded, mongoDateFormat),
            render: renderDate,
            filterDropdown: this.filterDateDropDown,
            onFilter: (value, record) => this.onFilter(value, record.dateAdded),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'cycle',
            key: 'cycle',
            sorter: (a, b) => compareDate(a.cycle, b.cycle, mongoDateFormat),
            render: renderDate,
            filterDropdown: this.filterDateDropDown,
            onFilter: (value, record) => this.onFilter(value, record.cycle),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'message',
            key: 'message',
            sorter: (a, b) => alphabetically(a.message, b.message),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.message),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }];
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    filterDateDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <DatePicker
                format={dateOnlyFormat}
                onChange={(date, dateString) => setSelectedKeys(dateString ? [dateString] : [])}
                onOk={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    render () {
        const { loadData, histoDefaults, numberLine } = this.state;

        const translatedColumns = this.getColumns().map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        if (!loadData){
            return (
                <div>
                    <Spin size="small" />
                </div>
            );
        }
        return (
            <div>
                {histoDefaults && histoDefaults.length > 0 && (
                    <Fragment>
                        <FormattedMessage id="cycles.default.numberLine" />
                        <InputNumber
                            defaultValue={numberLine}
                            className="input-number"
                            size="medium"
                            onChange={ value => this.setState({ numberLine: value })}
                        />
                        <Button htmlType="button" onClick={ () => this.fetchDataAsync(numberLine)}>
                            <FormattedMessage id="cycles.default.search.confirm" />
                        </Button>
                    </Fragment>
                )}
                <Table 
                    className="custom-table"
                    dataSource={histoDefaults}
                    columns={translatedColumns}
                    loading={!loadData}
                    // rowKey="dateAdded"
                    rowKey={record => record.dateAdded + '_' + record.value}
                    tableName={tableName}
                />
            </div>
        );
    }
}

ListDefaultHistorique.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            serial: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            nameDevice: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
}

const mapStateToProps = ({}) => ({

});

export default injectIntl(connect(mapStateToProps)(ListDefaultHistorique));