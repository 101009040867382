import { Button, Card } from 'antd';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { injectIntl, intlShape } from 'react-intl';

export const InfosPortal = ({ children, container }) => ReactDOM.createPortal(children, container);

const InfosList = ({ intl, list, onClick }) => (
    <Fragment>
        {list.map(({ description, id, title }, index) => (
            <Card
                key={index.toString()}
                title={title}
                extra={(
                    <Button type="primary" size="small" onClick={() => onClick(id)}>
                        {intl.formatMessage({ id: 'news.more' })}
                    </Button>
                )}
            >
                <p>{description}</p>
            </Card>
        ))}
    </Fragment>
);

InfosList.propTypes = {
    intl: intlShape.isRequired,
    list: PropTypes.arrayOf(PropTypes.object),
    onClick: PropTypes.func.isRequired,
};

InfosList.defaultProps = {
    list: [],
};

export default injectIntl(InfosList);
