import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Input, notification, Popover, Table } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faExclamationTriangle, faHistory, faFileArchive, faSignal, faTasks } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { connect } from 'react-redux';

import DeleteCycle from './DeleteCycle';
import PageLayout from '../Layout/PageLayout';
import {
    getCyclesForDevice,
    getCsvDataForCycle,
    downloadCycleArchive,
    downloadCycleDefaults,
    downloadCycleProcess,
    downloadCycleReport,
} from '../../utils/apiBucherVaslin';
import { alphabetically, compareDate, exportToCSV } from '../../utils/helpers';
import { NavLink } from 'react-router-dom';

const mongoDateFormat = 'YYYYMMDD HH:mm:ss';

const renderDate = (cycle) => (cycle ? (
    <Popover content={moment(cycle.startDateFormatted, mongoDateFormat).format('DD/MM/YY HH:mm:ss')}>
        <span>{moment(cycle.startDateFormatted, mongoDateFormat).format('DD/MM/YY HH:mm:ss')}</span>
    </Popover>
) : (
    '-'
));

class Cycles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cycles: [],
            deviceFamily: null,
            machineName: null,
            loading: true,
        };
    }

    async componentDidMount() {
        const {
            location: {
                state: { famille },
            }
        } = this.props;

        // Set device family in state
        await this.setState({ 
            deviceFamily: (famille) ? famille.toLowerCase() : "preview",
        });

        await this.fetchDataAsync();
    }

    async download(type, cycle) {
        const {
            match: {
                params: { serial },
            },
            intl,
        } = this.props;

        this.setState({ loading: true });
        if ('archive' === type) {
            const { item: dataCycleArchive
                , isSuccess: isSuccessCycleArchive
                , isUnauthorized: isUnauthorizedCycleArchive
                , errorDetail: errorMessageCycleArchive } = await downloadCycleArchive(serial, cycle.startDateFormatted);

            if (isSuccessCycleArchive) {
                exportToCSV(cycle.startDateFormatted, dataCycleArchive, 'history');
            }
            else {
                notification.error({
                    message: isUnauthorizedCycleArchive 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorizedCycleArchive 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorMessageCycleArchive,
                });
            }
        } else if ('total' === type) {
            const { item: data, isSuccess, isUnauthorized, errorMessage, errorDetail } = await getCsvDataForCycle(serial, cycle.startDateFormatted);

            if (isSuccess) {
                exportToCSV(cycle.startDateFormatted, data, 'result');
            }
            else {
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail,
                });
            }
        } else if ('report' === type) {
            const { item: dataCycleReport
                , isSuccess: isSuccessCycleReport
                , isUnauthorized: isUnauthorizedCycleReport
                , errorDetail: errorMessageCycleReport } = await downloadCycleReport(serial, cycle.startDateFormatted);

            if (isSuccessCycleReport) {
                exportToCSV(cycle.startDateFormatted, dataCycleReport, 'report');
            }
            else {
                notification.error({
                    message: isUnauthorizedCycleReport 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorizedCycleReport 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorMessageCycleReport,
                });
            }
        } else if ('default' === type) {
            const { item: dataCycleDefault
                , isSuccess: isSuccessCycleDefault
                , isUnauthorized: isUnauthorizedCycleDefault
                , errorDetail: errorMessageCycleDefault } = await downloadCycleDefaults(serial, cycle.startDateFormatted);

            if (isSuccessCycleDefault) {
                exportToCSV(cycle.startDateFormatted, dataCycleDefault, 'default');
            }
            else {
                notification.error({
                    message: isUnauthorizedCycleDefault 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorizedCycleDefault 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorMessageCycleDefault,
                });
            }
        } else if ('process' === type) {
            const { item: dataCycleProcess
                , isSuccess: isSuccessCycleProcess
                , isUnauthorized: isUnauthorizedCycleProcess
                , errorDetail: errorMessageCycleProcess } = await downloadCycleProcess(serial, cycle.startDateFormatted);

            if (isSuccessCycleProcess) {
                exportToCSV(cycle.startDateFormatted, dataCycleProcess, 'process');
            }
            else {
                notification.error({
                    message: isUnauthorizedCycleProcess
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorizedCycleProcess 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorMessageCycleProcess,
                });
            }
        }
        this.setState({ loading: false });
    }

    async fetchDataAsync() {
        const {
            match: {
                params: { serial },
            },
            intl,
        } = this.props;

        // Max result to be displayed on screen
        const maxResult = 500;

        this.setState({ loading: true });
        const { item, isSuccess, isUnauthorized, errorMessage, errorDetail } = await getCyclesForDevice(serial, maxResult);

        if (isSuccess) {
            this.setState({
                cycles: item && item.cycles && item.cycles.map((cycle, index) => ({ key: index, cycle })),
                machineName: item && item.infoMachine && item.infoMachine.name,
                loading: false,
            });
        } else {
            this.setState({ loading: false });
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
    }

    onFilter = (value, obj) => {
        if (obj === true || obj === false) {
            return obj == value;
        }
        return obj && obj.toLowerCase().includes(value.toLowerCase()); 
    };

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    render() {
        const { cycles, deviceFamily, machineName, loading } = this.state;
        const {
            allowDownloadHisto,
            match: {
                params: { serial },
            },
            history,
        } = this.props;

        const columns = [
            {
                title: <FormattedMessage id="cycles.column.cycleType" />,
                dataIndex: 'cycle.type',
                key: 'type',
                sorter: (a, b) => alphabetically(a.cycle.type, b.cycle.type),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.cycle.type),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            },
            {
                title: <FormattedMessage id="cycles.column.cycles" />,
                dataIndex: 'cycle',
                key: 'cycle',
                sorter: (a, b) => compareDate(a.cycle.startDateFormatted, b.cycle.startDateFormatted, mongoDateFormat),
                render: renderDate,
            },
            {
                title: <FormattedMessage id="cycles.action" />,
                key: 'action',
                render: (_, { cycle }) => (
                    <Fragment>
                        <Popover
                            content={<FormattedMessage id={allowDownloadHisto ? 'table.cycles.downloadReport' : 'common.authMissing'} />}
                        >
                            <Button
                                disabled={!allowDownloadHisto}
                                onClick={() => this.download('report', cycle)}
                            >
                                <FontAwesomeIcon icon={faTasks} />
                            </Button>
                        </Popover>
                        <Divider type="vertical" />
                        <Popover
                            content={<FormattedMessage id={allowDownloadHisto ? 'table.cycles.downloadArchive' : 'common.authMissing'} />}
                        >
                            <Button
                                disabled={!allowDownloadHisto}
                                onClick={() => this.download('archive', cycle)}
                            >
                                <FontAwesomeIcon icon={faHistory} />
                            </Button>
                        </Popover>
                        <Divider type="vertical" />
                        <Popover
                            content={<FormattedMessage id={allowDownloadHisto ? 'table.cycles.downloadCSV' : 'common.authMissing'} />}
                        >
                            <Button
                                disabled={!allowDownloadHisto}
                                onClick={() => this.download('total', cycle)}
                            >
                                <FontAwesomeIcon icon={faFileArchive} />
                            </Button>
                        </Popover>
                        <Divider type="vertical" />
                        <Popover
                            content={<FormattedMessage id={allowDownloadHisto ? 'table.cycles.downloadDefault' : 'common.authMissing'} />}
                        >
                            <Button
                                disabled={!allowDownloadHisto}
                                onClick={() => this.download('default', cycle)}
                            >
                                <FontAwesomeIcon icon={faExclamationTriangle} />
                            </Button>
                        </Popover>
                        <Divider type="vertical" />
                        <Popover
                            content={<FormattedMessage id={allowDownloadHisto ? 'table.cycles.downloadProcess' : 'common.authMissing'} />}
                        >
                            <Button
                                disabled={!allowDownloadHisto}
                                onClick={() => this.download('process', cycle)}
                            >
                                <FontAwesomeIcon icon={faSignal} />
                            </Button>
                        </Popover>

                        {/* Cycle graph button */}
                        {/* <Divider type="vertical" />
                        <NavLink to={{ 
                            pathname:`/${serial}/cycles/graph`, 
                            state: { 
                                cycle: cycle.startDateFormatted, 
                                deviceFamily 
                            } 
                        } } >
                            <Popover content={<FormattedMessage id="table.cycles.graph" />}>
                                <Button disabled={!allowDownloadHisto}>
                                    <FontAwesomeIcon icon={faChartLine} />
                                </Button>
                            </Popover>
                        </NavLink> */}

                        {/* Delete cycle button */}
                        <Divider type="vertical" />
                        <DeleteCycle
                            disabled={!allowDownloadHisto}
                            id={cycle.startDateFormatted}
                            serial={serial}
                            refresh={() => this.fetchDataAsync()}
                        />
                    </Fragment>
                ),
            },
        ];

        return (
            <PageLayout pageKey="cycles" history={history}>
                <h2>{<FormattedMessage id="cycles.title" values={{ machineName }} />}</h2>
                <Table
                    dataSource={cycles}
                    columns={columns}
                    loading={loading}
                    rowKey="key"
                />
            </PageLayout>
        );
    }
}

Cycles.propTypes = {
    allowDownloadHisto: PropTypes.bool.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            serial: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            famille: PropTypes.string,
        }).isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ signIn: { allowDownloadHisto } }) => ({
    allowDownloadHisto,
});

export default injectIntl(connect(mapStateToProps, null)(Cycles));
