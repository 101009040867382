import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Divider, Form, Button } from 'antd';
import ClientFormItemsEdit from './FormItemsEdit';
import RequiredFieldLegend from '../RequiredFieldLegend';
import { tailFormItemLayout } from '../../utils/formItemLayout';
import { isGroupAdminClient, isSuperAdmin } from '../../utils/helpers';

const { Item: FormItem } = Form;

class EditForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        const { form: { validateFieldsAndScroll }, save } = this.props;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                save(values);
            }
        });
    }

    render() {
        const { initialValues, form, spinUpdateClient, userRole } = this.props;

        return (
            <Form onSubmit={this.handleSubmit}>
                <Divider>
                    <FormattedMessage
                        id="customerDetails.line.informationTitle"
                        defaultMessage="My information"
                    />
                </Divider>
                <ClientFormItemsEdit
                    form={form}
                    clientPrefix="client."
                    initialValues={initialValues}
                />
                <FormItem {...tailFormItemLayout}>
                    <RequiredFieldLegend />
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                    <Button disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))}
                        type="primary"
                        htmlType="submit"
                        loading={spinUpdateClient}
                    >
                        <FormattedMessage
                            id="client.edit.button"
                            defaultMessage="Update client"
                        />
                    </Button>
                </FormItem>
            </Form>
        );
    }
}

EditForm.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    initialValues: PropTypes.shape({}),
    spinUpdateClient: PropTypes.bool
};

const mapStateToProps = ({signIn: { userRole },
}) => ({ userRole });

export default connect(mapStateToProps)(Form.create()(EditForm));
