import { notification } from 'antd'
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PageLayout from '../Layout/PageLayout';
import CreateForm from './CreateForm';
import {
    noAuthCreateClientWithUser,
    createClientWithUser,
} from '../../utils/apiBucherVaslin';
import history from '../../history';

const save = (locale, loggedIn) => (values) => {
const formatedValues = { ...values };
    if (formatedValues.client.fichiers) {
        formatedValues.client.fichiers = values.client.fichiers.fileList.map((f) => f.originFileObj);
        if (1 === formatedValues.client.fichiers.length) {
            formatedValues.client.fichiers = formatedValues.client.fichiers.shift();
        }
    }
    formatedValues.client.codeLangue = locale;

    const promise = (loggedIn
        ? createClientWithUser
        : noAuthCreateClientWithUser
    )(formatedValues).then(({
        isSuccess,
        isUnauthorized,
        errorMessage,
        errorDetail,
    }) => {
        if (isSuccess) {
            return history.goBack();
        }
        const { intl } = this.props;

        notification.error({
            message: isUnauthorized 
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({ id: 'common.error' }),
            description: isUnauthorized 
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : errorDetail,
        });
    });

    return promise;
};

const ClientCreate = ({ locale, loggedIn }) => (
    <PageLayout pageKey="newCustomer">
        <h2>
            <FormattedMessage
                id="client.create.title"
                defaultMessage="Create a new client"
            />
        </h2>
        <CreateForm save={save(locale, loggedIn)} loggedIn={loggedIn} pageKey="newCustomer"/>
    </PageLayout>
);

ClientCreate.propTypes = {
    loggedIn: PropTypes.bool.isRequired,
    locale: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({
    signIn: {
        loggedIn,
    },
    app: {
        locale,
    },
}) => ({ locale, loggedIn });

export default injectIntl(connect(mapStateToProps, {})(ClientCreate));
