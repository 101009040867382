import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, compose, createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import reducer from './reducer';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['app', 'signIn'],
};

const persistedReducer = persistReducer(persistConfig, reducer);

// eslint-disable-next-line no-underscore-dangle
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const middlewares = [thunkMiddleware];

if ('development' === process.env.NODE_ENV) {
    // eslint-disable-next-line global-require
    const { logger } = require('redux-logger');

    middlewares.push(logger);
}

export const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(
            ...middlewares,
        ),
    ),
);

export const persistor = persistStore(store);
