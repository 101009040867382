import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';

import history from './history';
import notRequireAuth from './hoc/notRequireAuth';
import requireAuth from './hoc/requireAuth';

import Home from './components/Home';
import PasswordReset from './components/PasswordReset';
import ClientDetails from './components/Client/Details';
import ClientEdit from './components/Client/Edit';
import Devices from './components/Devices/Devices';
import Directory from './components/Directory';
import Documents from './components/Documents/Documents';
import ClassroomTraining from './components/Master/ClassroomTraining';
import ETraining from './components/Master/ETraining';
import Catalog from './components/Catalog/Catalog';
import Downloads from './components/Downloads';
import ConnectedDevices from './components/Devices/ConnectedDevices';
import Articles from './components/Articles';
import UserList from './components/User/List';
import ClientList from './components/Client/List';
import UserEdit from './components/User/Edit';
import ClientCreate from './components/Client/Create';
import UserCreate from './components/User/Create';
import Contact from './components/Contact/Contact';
import Maintenance from './components/Maintenance';
import NetworkRequestsMenu from './components/NetworkRequests/NetworkRequestsMenu'
import Licenses from './components/Licenses/Licenses';
import UserLicenses from './components/Licenses/UserLicenses';
import Orders from './components/Orders';
import Order from './components/Orders/Order';
import OrdersLicense from './components/OrdersLicense/indexLicense';
import OrderLicense from './components/OrdersLicense/OrderLicense';
import WinectGuide from './components/WinectGuide';
import CGU from './components/CGU';
import FAQ from './components/FAQ/FAQ';
import FAQContent from './components/FAQ/FAQContent';
import FlashInfo from './components/FlashInfo/FlashInfo';
import SignIn from './components/SignIn';
import Cycles from './components/Cycles/Cycles';
import CycleGraph from './components/Cycles/CycleGraph';
import Info from './components/Infos/Info';
import ServicesCatalogue from './components/ServicesCatalogue';
import NotFoundPage from './components/NotFoundPage';
import Alert from './components/Notification/Alert';
import HistoDefault from './components/Historique/ListDefault';
import HistoProcess from './components/Historique/ListProcess';
import ListForMaintainer from './components/Devices/ListForMaintainer';
import Infos from './components/Devices/Infos';
import MetricPreview from './components/MetricPreview/MetricPreview';
import Incidents from './components/Incidents/Incidents';
import PartsUseCases from './components/PartsUseCases/PartsUseCases';

const MyProfile = (props) => <UserEdit {...props} pageKey="myProfile" />;
const MyUsers = (props) => <UserList {...props} fetchCurrentUsers pageKey="myUsers" />;
const UsersManagement = (props) => <UserList {...props} pageKey="usersManagement" />;
const ClientUsers = (props) => <UserList {...props} pageKey="customersUsers" />;
const ClientOrders = (props) => <Orders {...props} pageKey="customersOrders" />;
const ClientLicenseOrders = (props) => <OrdersLicense {...props} pageKey="customersLicenseOrders" />;

const CustomRouter = () => (
    <Router history={history}>
        <Switch>
            <Route exact path="/" component={requireAuth(Home, "home", false)} pageKey="home"/>
            <Route exact path="/alert" component={requireAuth(Alert,"alert", true)} />
            <Route exact path="/catalog" component={requireAuth(Catalog, "catalog", true)} />
            <Route exact path="/cgu" component={requireAuth(CGU, "cgu", false)} pageKey="cgu"/>
            <Route exact path="/classroomTraining" component={requireAuth(ClassroomTraining, "classroomTraining", true)} />
            <Route exact path="/contact/:id?/:type?" component={requireAuth(Contact, "contact", false)} pageKey="contact"/> 
            <Route exact path="/customers" component={requireAuth(ClientList, "customers", true)} />
            <Route exact path="/customers/:eureka/articles" component={requireAuth(Articles,"customersArticles", true)} />
            <Route exact path="/customers/:eureka/connectedDevices" component={requireAuth(ConnectedDevices, "customersConnectedDevices", true)} />
            <Route exact path="/customers/:eureka/details" component={requireAuth(ClientDetails, "customersDetails", true)} />
            <Route exact path="/customers/:eureka/devices" component={requireAuth(Devices, "customersDevices", true)}/>
            <Route exact path="/customers/:eureka/edition" component={requireAuth(ClientEdit, "customersEdition", true)} />
            <Route exact path="/customers/:eureka/orders" component={requireAuth(ClientOrders, "customersOrders", true)} />
            <Route exact path="/customers/:eureka/ordersLicense" component={requireAuth(ClientLicenseOrders, "customersOrders", true)} />
            <Route exact path="/customers/:eureka/users" component={requireAuth(ClientUsers, "customersUsers", true)} />
            <Route exact path="/directory" component={requireAuth(Directory, "directory", true)} />
            <Route exact path="/documents" component={requireAuth(Documents, "documents", true)} />
            <Route exact path="/downloads" component={requireAuth(Downloads, "downloads", true)} />
            <Route exact path="/eTraining" component={requireAuth(ETraining, "eTraining", true)} />
            <Route exact path="/faq" component={requireAuth(FAQ, "faq", false)} pageKey="faq" />
            <Route exact path="/faqContent" component={requireAuth(FAQContent, "faqContent", false)} pageKey="faqContent"/>
            <Route exact path="/flashInfos" component={requireAuth(FlashInfo, "flashInfo", true)} pageKey="flashInfo"/>
            <Route exact path="/infos/:name" component={requireAuth(Info, "infos", false)} pageKey="infos"/>
            <Route exact path="/incidents" component={requireAuth(Incidents, "incidents", true)} />
            <Route exact path="/licenses" component={requireAuth(Licenses, "licenses", true)} />
            <Route exact path="/licenses/:userId" component={requireAuth(UserLicenses, "userLicenses", true)} />
            <Route exact path="/listingParc" component={requireAuth(ListForMaintainer, "listingParc", true)} />
            <Route exact path="/metricPreview" component={requireAuth(MetricPreview, 'metricPreview', false)} pageKey="metricPreview"/>
            <Route exact path="/myDevices" component={requireAuth(Devices, "myDevices", true)} />
            <Route exact path="/myDevicesConnected" component={requireAuth(ConnectedDevices, "myConnectedDevices", true)} />
            <Route exact path="/myInformation" component={requireAuth(ClientDetails, "myInfos", true)} />
            <Route exact path="/myInformation/edition" component={requireAuth(ClientEdit, "myInfosEdit", true)} />
            <Route exact path="/myProfile" component={requireAuth(MyProfile, "myProfile", true)} />
            <Route exact path="/myUsers" component={requireAuth(MyUsers, "myUsers", true)} />
            <Route exact path="/networkRequests" component={requireAuth(NetworkRequestsMenu, "networkRequests", true)} />
            <Route exact path="/newCustomer" component={requireAuth(ClientCreate, 'newCustomer', false)} pageKey="newCustomer"/>
            <Route exact path="/newUser" component={requireAuth(UserCreate, "newUser", true)} />
            <Route exact path="/newUser/:clientId/:clientName" component={requireAuth(UserCreate, "newClientUser", true)} />
            <Route exact path="/orders" component={requireAuth(Orders, "orders", true)} />
            <Route exact path="/orders/:id" component={requireAuth(Order, "orderDetail", true)} />
            <Route exact path="/ordersLicense" component={requireAuth(OrdersLicense, "ordersLicense", true)} />
            <Route exact path="/ordersLicense/:id" component={requireAuth(OrderLicense, "orderLicenseDetail", true)} />
            <Route exact path="/partsUseCases/:id?" component={requireAuth(PartsUseCases, 'partsUseCases', false)} pageKey="partsUseCases"/>
            <Route exact path="/resetPassword" component={requireAuth(PasswordReset, "resetPassword", true)} />
            <Route exact path="/services" component={requireAuth(ServicesCatalogue, "services", false)} />
            <Route exact path="/:serial/cycles" component={requireAuth(Cycles, "deviceCycles", true)} />
            <Route exact path="/:serial/cycles/graph" component={requireAuth(CycleGraph, "cycleGraph", true)} />
            <Route exact path="/:serial/histoDefault" component={requireAuth(HistoDefault, "deviceDefaults", true)} />
            <Route exact path="/:serial/histoProcess" component={requireAuth(HistoProcess, "deviceProcess", true)} />
            <Route exact path="/:serial/infos" component={requireAuth(Infos, "deviceInfos", true)} />
            <Route exact path="/users/:id/edition" component={requireAuth(UserEdit, "userEdit", true)} />
            <Route exact path="/usersManagement" component={requireAuth(UsersManagement, "userManagement", true)} />
            <Route exact path="/winectGuide" component={requireAuth(WinectGuide, "winectGuide", true)} />
            
            <Route path="/signIn" component={notRequireAuth(SignIn)} />
            <Route component={NotFoundPage} />
        </Switch>
    </Router>
);

export default CustomRouter;
