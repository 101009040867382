import { LOCALE_SET } from '../actions/app';
import { formatLocaleToNavigator } from '../utils/locale';

const defaultState = {
    locale: window.navigator.language.slice(0, 2),
};

const applicationReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case LOCALE_SET:
            return { locale: formatLocaleToNavigator(payload.locale) };
        default:
            return state;
    }
};

export default applicationReducer;
