import React from 'react';
import { FormattedMessage } from 'react-intl';

import './RequiredFieldLegend.less';

const RequiredFieldLegend = () => (
    <div className="required-field-legend">
        *&nbsp;:&nbsp;
        <FormattedMessage
            id="form.required.field"
            defaultMessage="Required field"
        />
    </div>
);

export default RequiredFieldLegend;
