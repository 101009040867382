import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Modal, Popover, message } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { deleteArticle } from '../../utils/apiBucherVaslin';

const { confirm } = Modal;

const showDeleteConfirm = (intl, id, libelle, numCommande, refresh) => {
    confirm({
        title: intl.formatMessage(
            {
                id: 'table.articles.deleteModal.title',
            },
            { id, libelle },
        ),
        okText: intl.formatMessage({
            id: 'common.yes',
        }),
        cancelText: intl.formatMessage({
            id: 'common.no',
        }),
        okType: 'danger',
        async onOk() {
            const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await deleteArticle(id, numCommande);

            if (isSuccess) {
                message.success(
                    intl.formatMessage(
                        {
                            id: 'table.articles.success',
                        },
                        { id },
                    ),
                    5,
                );

                refresh();

                return;
            }
            
            Modal.error({
                title: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({
                    id: 'table.articles.fail',
                }),
                content: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : errorDetail,
            });
        },
    });
};

const DeleteArticle = ({ intl, id, libelle, numCommande, refresh }) => (
    <Popover content={<FormattedMessage id="common.delete" />}>
        <Button
            htmlType="button"
            type="danger"
            onClick={() => showDeleteConfirm(intl, id, libelle, numCommande, refresh)}
        >
            <FontAwesomeIcon icon="trash-alt" />
        </Button>
    </Popover>
);

DeleteArticle.propTypes = {
    intl: intlShape.isRequired,
    id: PropTypes.number.isRequired,
    libelle: PropTypes.string.isRequired,
    numCommande: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired,
};

export default injectIntl(DeleteArticle);
