export const CLIENT_ROLE_ADMIN = 'admin';
export const CLIENT_ROLE_CLIENT = 'client';
export const NOTIFICATIONS_COUNT = 3;
export const REGEX_ONLY_CARACTERE_LATIN = "^[\x00-\xFF]+$";
export const REGEX_ONLY_NUMBER = "^[0-9]+$";
export const REGEX_NAME = "^([ \u00c0-\u01ffa-zA-Z'\-])+$";
export const REGEX_ORDER_REF = "^([a-zA-Z0-9' \-])+$";
export const REGEX_GUID = "^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}";
export const REGEX_TVA = "^([a-zA-Z0-9])+$";
export const REGEX_CSV_SEMICOLON = "^[^;]+$";
export const BV_CLIENT_ID = "10091199"; // Client ID For BV

// Name of features
export const FEATURE_LICENSE_GENERATE = 'LicenseGenerate';
export const FEATURE_LICENSE_ASSIGN = 'LicenseAssign';

// Name of currencies
export const CURRENCY_EURO = "EUR";
export const CURRENCY_DOLLAR = "USD";
export const CURRENCY_POUND = "GBP";

// List of BVSubsidiaries
export const BVSubsidiaries = [
    { code: "9ea0cdfc-11be-eb11-bacc-000d3a441212", label: "Bucher Vaslin - France"},
    { code: "e0a16f71-1907-eb11-a813-000d3a25bb0d", label: "Bucher Vaslin - Italia"},
    { code: "67a26f71-1907-eb11-a813-000d3a25bb0d", label: "Bucher Vaslin - North Amercia"},
    { code: "53a26f71-1907-eb11-a813-000d3a25bb0d", label: "Bucher Vaslin - South America"},
    { code: "aa4f0473-1907-eb11-a813-000d3abe0461", label: "Bucher Vaslin - Argentina"},
];