import React, { Fragment, useState } from "react";
import { Button, Input, Modal, notification, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormattedMessage } from "react-intl";
import {
  updateStatutDemandeEchangeReseau,
  fetchUserDetailsById,
} from "../../utils/apiBucherVaslin";
import {
  faSave,
  faTrash,
  faArrowAltCircleLeft,
  faArrowAltCircleRight,
  faThumbsUp,
  faThumbsDown,
} from "@fortawesome/free-solid-svg-icons";

export const InformationDemandeur = ({ idUser, nomClient }) => {
  const [visible, setVisible] = useState(false);
  const [infoUser, setInfoUser] = useState(false);

  const getInfoUser = (idUser) => {
    fetchUserDetailsById(idUser).then(({ item }) => {
      setInfoUser(item);
      setVisible((v) => !v);
    });
  };
  return (
    <Fragment>
      <Button onClick={() => getInfoUser(idUser)}>{nomClient}</Button>
      <Modal
        title={
          <FormattedMessage id="networkRequests.table.action.infoTitleModal" />
        }
        width="30%"
        visible={visible}
        onCancel={() => setVisible(!visible)}
        footer={
          <Button onClick={() => setVisible(!visible)}>
            <FormattedMessage id="common.modal.close" />
          </Button>
        }
      >
        <div>
          <div style={{ marginBottom: "auto" }}>
            <label style={{ fontWeight: "bold" }}>
              <FormattedMessage id="user.form.client" />
            </label>
            <span>: {infoUser.client && infoUser.client.nomClient}</span>
          </div>
          <div style={{ marginBottom: "auto" }}>
            <label style={{ fontWeight: "bold" }}>
              <FormattedMessage id="networkRequests.table.demandeur" />
            </label>
            <span>: {infoUser.nomPrenom}</span>
          </div>
          <div style={{ marginBottom: "auto" }}>
            <label style={{ fontWeight: "bold" }}>
              <FormattedMessage id="user.form.email" />
            </label>
            <span>: {infoUser.email}</span>
          </div>
          <div style={{ marginBottom: "auto" }}>
            <label style={{ fontWeight: "bold" }}>
              <FormattedMessage id="user.form.mobilePhone" />
            </label>
            <span>: {infoUser.mobileNumber}</span>
          </div>
          <div style={{ marginBottom: "auto" }}>
            <label style={{ fontWeight: "bold" }}>
              <FormattedMessage id="user.form.phoneNumber" />
            </label>
            <span>: {infoUser.phoneNumber}</span>
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export const RenderCommentaire = ({ commentaire }) => {
  const [visibleModal, setVisibleModal] = useState(false);
  return (
    <Fragment>
      <span
        style={{ cursor: "pointer" }}
        onClick={() => setVisibleModal(!visibleModal)}
      >
        {commentaire.substring(0, 27) + "[...]"}
      </span>
      <Modal
        visible={visibleModal}
        onCancel={() => setVisibleModal(!visibleModal)}
        footer={
          <Button onClick={() => setVisibleModal(!visibleModal)}>
            <FormattedMessage id="common.modal.close" />
          </Button>
        }
      >
        {commentaire}
      </Modal>
    </Fragment>
  );
};
const handleSearch = (selectedKeys, confirm) => () => {
  confirm();
};

const handleReset = (clearFilters) => () => {
  clearFilters();
};

export const FilterDropDown = ({
  setSelectedKeys,
  searchInput,
  selectedKeys,
  confirm,
  clearFilters,
}) => (
  <div className="custom-filter-dropdown">
    <Input
      /* eslint-disable-next-line */
      ref={(ele) => (searchInput = ele)}
      value={selectedKeys[0]}
      onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
      onPressEnter={handleSearch(selectedKeys, confirm)}
    />
    <Button type="primary" onClick={handleSearch(selectedKeys, confirm)}>
      <FormattedMessage id="common.search" />
    </Button>
    <Button onClick={handleReset(clearFilters)}>
      <FormattedMessage id="common.cancel" />
    </Button>
  </div>
);

export const OnFilter = (value, obj) =>
  obj && obj.toLowerCase().includes(value.toLowerCase());

export const OnFilterDropdownVisibleChange = ({ visible, searchInput }) => {
  if (visible) {
    setTimeout(() => {
      searchInput.focus();
    });
  }
};

const buttonIcon = (id, statut, icon, refresh) => {
  return (
    <Button
      onClick={() => updateDemandeEchangeReseau(id, statut, refresh)}
      style={{ marginRight: "2px" }}
    >
      <FontAwesomeIcon icon={icon} />
    </Button>
  );
};

const updateDemandeEchangeReseau = (id, statut, refresh) => {
  updateStatutDemandeEchangeReseau(id, statut).then(({ isSuccess }) => {
    if (isSuccess) {
      notification.success({
        message: "Success",
      });
      refresh();
      return;
    }
    notification.error({
      message: "Error",
    });
  });
};

export const PrendreEnCharge = ({ id, refresh }) => {
  return (
    <Fragment>
      <Popover
        content={
          <FormattedMessage
            id={`networkRequests.table.action.prendreEnCharge`}
          />
        }
      >
        {buttonIcon(id, "Assigned", faArrowAltCircleRight, refresh)}
      </Popover>
    </Fragment>
  );
};

export const RemiseEnCircuit = ({ id, refresh }) => {
  return (
    <Fragment>
      <Popover
        content={
          <FormattedMessage
            id={`networkRequests.table.action.remiseEnCircuit`}
          />
        }
      >
        {buttonIcon(id, "Published", faArrowAltCircleLeft, refresh)}
      </Popover>
    </Fragment>
  );
};

export const SoumettreLaDemande = ({ id, refresh }) => {
  return (
    <Fragment>
      <Popover
        content={<FormattedMessage id={`networkRequests.modal.submit`} />}
      >
        {buttonIcon(id, "Published", faSave, refresh)}
      </Popover>
    </Fragment>
  );
};

export const CloturerCommePerdu = ({ id, refresh }) => {
  return (
    <Fragment>
      <Popover
        content={
          <FormattedMessage
            id={`networkRequests.table.action.cloturerPerdue`}
          />
        }
      >
        {buttonIcon(id, "Fail", faThumbsDown, refresh)}
      </Popover>
    </Fragment>
  );
};

export const CloturerCommeObtenu = ({ id, refresh }) => {
  return (
    <Fragment>
      <Popover
        content={
          <FormattedMessage
            id={`networkRequests.table.action.cloturerObtenue`}
          />
        }
      >
        {buttonIcon(id, "Success", faThumbsUp, refresh)}
      </Popover>
    </Fragment>
  );
};

export const AnnulerLaDemande = ({ id, refresh }) => {
  return (
    <Fragment>
      <Popover
        content={
          <FormattedMessage id={`networkRequests.table.action.annuler`} />
        }
      >
        {buttonIcon(id, "Cancelled", faTrash, refresh)}
      </Popover>
    </Fragment>
  );
};
