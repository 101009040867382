import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import { extensionToType } from '../../utils/helpers';
import './Document.less';

const Document = ({ src }) => {
    if (!src) {
        return <FormattedMessage id="documents.selectfile" />;
    }

    const fileType = src.split('.').pop();
    switch (extensionToType(fileType.toLowerCase())) {
        case 'image':
            return (
                <span className="image-wrapper">
                    <img src={src} alt="" />
                </span>
            );
        case 'pdf':          
        return (
            <iframe src={"https://docs.google.com/viewerng/viewer?url=" + src + "&embedded=true"} width="100%" height="100%"></iframe>
        )
        default:
            break;
    }

    return <FormattedMessage id="documents.invalidfile" />;
};

Document.propTypes = {
    src: PropTypes.string,
};

export default Document;
