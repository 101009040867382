import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form, notification } from 'antd';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileImport } from '@fortawesome/free-solid-svg-icons';
import { updateBonIncident } from '../../utils/apiBucherVaslin';
import './IncidentBon.less';

const { Item: FormItem } = Form;

class IncidentBon extends Component {
    constructor(props) {
        super(props);
    }   
    
    updateBon = async(value, bonN) => {
        const { incidentId, form : {intl}, updateStateBon } = this.props;
        const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await updateBonIncident(incidentId, bonN, value[0])
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
        }
        updateStateBon(value, bonN)
    }

    render() {   
        const { form : {getFieldDecorator}, isEditable, typePriseEnCharge, downloadFileBase64, bonN1File, bonN2File, bonN1FileName, bonN2FileName, updateStateBon} = this.props;
        return(
            <Fragment>
                <FormItem label={typePriseEnCharge == "100000000"? (<b><FormattedMessage id="contact.form.bonN1ps"/></b>) 
                :
                <b><FormattedMessage id="contact.form.bonN1e"/></b>} className="file-upload-form-item"> 
                {getFieldDecorator('bonN1', {
                    rules: [{
                        required: true,
                        message: (
                            <FormattedMessage
                                id="contact.form.bonN1e.required"
                            />
                        ),
                    }],
                })(
                isEditable ?
                <Dropzone
                    className="dropzone-bonn"
                    name="files"
                    onDrop={(value) => this.updateBon(value, "bonN1File")}
                >
                    <Fragment>
                        { (bonN1File) ? (
                            <div className="file-container">
                                <button
                                    type="button"
                                    className="file-name-container"
                                    onClick={() => updateStateBon(null, "bonN1File")}
                                >
                                    <span className="file-name">{bonN1FileName}</span>
                                    <span className="file-delete">
                                        <FontAwesomeIcon icon="times-circle" />
                                    </span>
                                </button>
                            </div>
                        ) : null}
                    </Fragment>
                </Dropzone>
                    :   
                    <Fragment>
                        <FontAwesomeIcon icon={faFileImport} style={{marginRight: "0.5em", cursor: "pointer"}}/>
                        <span onClick={() =>downloadFileBase64(bonN1File)} style= {{cursor: "pointer"}}>
                            {bonN1FileName}
                        </span> 
                    </Fragment>
                )}   
                </FormItem> 
                {typePriseEnCharge == "100000000" &&
                <FormItem label={(<b><FormattedMessage id="contact.form.bonN2ps" /></b>)} className="file-upload-form-item">
                    {isEditable ?
                    getFieldDecorator('bonN2', {
                    rules: [{
                        message: (
                            <FormattedMessage
                                id="contact.form.bonN1e.required"
                            />
                            ),
                        }],
                    })(
                        <Dropzone
                            className="dropzone-bonn"
                            name="files"
                            onDrop={(value) => this.updateBon(value, "bonN2File")}
                        >
                            <Fragment>
                                { (bonN2File) ? (
                                    <div className="file-container">
                                        <button
                                            type="button"
                                            className="file-name-container"
                                            onClick={() => updateStateBon(null, "bonN2File")}
                                        >
                                            <span className="file-name">{bonN2FileName}</span>
                                            <span className="file-delete">
                                                <FontAwesomeIcon icon="times-circle" />
                                            </span>
                                        </button>
                                    </div>
                                    ) : null}
                            </Fragment>
                        </Dropzone>
                        )
                        :  
                        <Fragment>
                            <FontAwesomeIcon icon={faFileImport} style={{marginRight: "0.5em", cursor: "pointer"}}/>
                            <span onClick={() => downloadFileBase64(bonN2File)} style= {{cursor: "pointer"}}>
                                {bonN2FileName}
                            </span> 
                        </Fragment>
                        }          
                </FormItem>
                }
            </Fragment>
    )}
}

IncidentBon.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func,
    }),
    incidentId: PropTypes.string.isRequired,
    typePriseEnCharge : PropTypes.string.isRequired,
    isEditable: PropTypes.bool.isRequired,
    bonn1File: PropTypes.array,
    bonn2File: PropTypes.array,
    bonN1FileName: PropTypes.string,
    bonN2FileName: PropTypes.string,
    downloadFileBase64: PropTypes.func.isRequired,
    updateStateBon: PropTypes.func.isRequired
};

export default injectIntl(Form.create()(IncidentBon));
