import PropTypes from "prop-types";
import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { fetchFlashInfos } from "../utils/apiBucherVaslin";
import { NOTIFICATIONS_COUNT } from "../utils/constants";
import { create, onInfoClicked } from "../utils/flashInfos";
import InfoRequestNetwork from "../components/NetworkRequests/InfoRequestNetwork";
import HtmlIncluder from "./HtmlIncluder";
import InfosList, { InfosPortal } from "./Infos/InfosList";
import PageLayout from "./Layout/PageLayout";
import {
  isGroupConcessionnaire,
  isGroupDev,
  isGroupEBV,
  isInfoCompleted,
} from "../utils/helpers";

class Home extends Component {
  state = {
    iframe: null,
    infos: [],
  };

  async componentDidMount() {
    const { intl, userID } = this.props;
    /* check connexion and information */
    await isInfoCompleted(intl, userID);
    this.loadInfos();
  }

  componentDidUpdate(prevProps) {
    const { userRole } = this.props;

    if (userRole !== prevProps.userRole) {
      this.loadInfos();
    }
  }

  async loadInfos() {
    const {
      history: { push },
      intl,
      locale,
      userRole,
      userID,
    } = this.props;

    // Get session storage flag for flashInfo
    const isAlreadyDisplayFlash = sessionStorage.getItem(
      "isAlreadyDisplayFlash"
    );

    if (isAlreadyDisplayFlash || !isGroupEBV({ userRole })) {
      return;
    }
    // Show last notifications only
    if (userID) {
      const infos = await fetchFlashInfos(locale);
      const moreText = intl.formatMessage({ id: "news.more" });
      let displayedInfos = [];

      // Manage flash infos display
      if (infos && infos.result) {
        if (infos.result.length > 0) {
          for (
            let i = infos.result.length - 1;
            i >= 0 && i > displayedInfos.length - NOTIFICATIONS_COUNT;
            i--
          ) {
            // TODO => Add read condition after flash info reading implementation
            if (infos.result[i].isRead == false) {
              create(
                infos.result[i],
                push,
                moreText,
                this.handleRedirectFlashInfo
              );
              displayedInfos.push(infos.result[i]);
            }
          }
        }
      }

      // Update session storage flag for flashInfo
      sessionStorage.setItem("isAlreadyDisplayFlash", true);

      // Update state
      this.setState({
        infos: displayedInfos,
      });
    }
  }

  handleRedirectFlashInfo = () => {
    // Manage navigation
    const { history } = this.props;
    history.push("/flashInfos");
  };

  render() {
    const {
      userRole,
      history: { push },
    } = this.props;
    const { iframe, infos } = this.state;

    return (
      <PageLayout pageKey="home" flex>
        <HtmlIncluder
          fileName="home"
          onLoad={(instance) => {
            if (instance) {
              this.setState({
                iframe: instance.contentWindow.document.getElementById("news"),
              });
            }
          }}
        />
        {isGroupConcessionnaire({ userRole }) && <InfoRequestNetwork />}
        {isGroupDev({ userRole }) && iframe && infos.length && (
          <InfosPortal container={iframe}>
            <InfosList
              list={infos.slice(-NOTIFICATIONS_COUNT)}
              onClick={(id) => onInfoClicked(push, id)}
            />
          </InfosPortal>
        )}
      </PageLayout>
    );
  }
}
Home.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  locale: PropTypes.string,
  userRole: PropTypes.array,
};

const mapStateToProps = ({
  app: { locale },
  signIn: { userRole, userID },
}) => ({ locale, userRole, userID });

export default connect(mapStateToProps)(injectIntl(Home));
