import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Modal, notification, Select } from 'antd';
import { fetchArticlesFamilies } from '../../utils/apiBucherVaslin';
import Articles from '../Articles';
import OrderStepper from './OrderStepper';
import './AddArticles.less';

const { Option } = Select;

class AddArticles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedArticles: [],
            family: null,
            families: [],
            visible: false,
        };
    }

    async componentDidMount() {

        await this.fetchFamilies(this.props.numCommande);

        const { familyCode } = this.props;
        if (familyCode) {
            this.setState({ family: this.findFamily(familyCode) });
        }
    }

    onArticlesSelected(selectedArticles) {
        this.setState({ selectedArticles });
    }

    onCancel() {
        this.setState({ visible: false });
    }

    onFamilyChanged(value) {
        this.setState({ family: this.findFamily(value) });
    }

    onOk() {
        const { onSubmit } = this.props;
        const { selectedArticles } = this.state;

        onSubmit(selectedArticles);
        this.setState({ visible: false });
    }

    findFamily(value) {
        const { families } = this.state;

        return families.find((f) => f.codE_FAMILLE === value || f.libellE_FAMILLE === value);
    }

    async fetchFamilies(value) {
        const { intl } = this.props;
        const { items, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchArticlesFamilies(value);
        
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
        this.setState({ families: items });
    }

    render() {
        const { family, families, visible } = this.state;
        const { allowFamilySwitch, client, numCommande } = this.props;
        const familyCode = family ? family.codE_FAMILLE : null;

        return (
            <Fragment>
                <Modal
                    title={<FormattedMessage id="order.modal.title" />}
                    visible={visible}
                    onCancel={() => this.onCancel()}
                    onOk={() => this.onOk()}
                    okText={<FormattedMessage id="order.modal.button" />}
                    cancelText={<FormattedMessage id="common.cancel" />}
                    width="80%"
                >
                    
                    <div className="category-filter">
                        {/* Include order stepper */}
                        <OrderStepper stepIndex={2} />

                        <span id="select-category-label"><FormattedMessage id="order.modal.categories" /></span>
                        
                        <Select
                            autoFocus
                            allowClear
                            name="families"
                            placeholder={<FormattedMessage id="order.modal.category" />}
                            style={{ width: 250 }}
                            onChange={(value) => this.onFamilyChanged(value)}
                            defaultValue={(family) ? family.codE_FAMILLE : undefined}
                            // disabled={!allowFamilySwitch}
                        >
                            {families && families.map((familyOption) => (
                                <Option
                                    key={familyOption.codE_FAMILLE}
                                    value={familyOption.codE_FAMILLE}
                                >
                                    {familyOption.libellE_FAMILLE}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <Articles
                        familyCode={familyCode}
                        client={client}
                        allowSelection
                        showFamily={allowFamilySwitch}
                        numCommande={numCommande}
                        onArticlesSelected={(selectedArticles) => this.onArticlesSelected(selectedArticles)}
                    />
                </Modal>
                <Button onClick={() => this.setState({ visible: true })}>
                    <FormattedMessage id="order.add" />
                </Button>
            </Fragment>
        );
    }
}

AddArticles.propTypes = {
    allowFamilySwitch: PropTypes.bool,
    client: PropTypes.string.isRequired,
    familyCode: PropTypes.string,
    onSubmit: PropTypes.func,
    numCommande: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
};

export default injectIntl(AddArticles);
