import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { hot } from 'react-hot-loader';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { IntlProvider } from 'react-intl';
import ReactBreakpoints from 'react-breakpoints';

import translations from './translations';
import { store, persistor } from './store';

import Router from './Router';

import './utils/importFAIcons';
import './App.less';

const defaultLocale = 'en';

const IntlApp = ({ locale }) => (
    <IntlProvider
        locale={(translations[locale] ? locale : defaultLocale)}
        messages={translations[locale] || translations[defaultLocale]}
    >
        <Router />
    </IntlProvider>
);

IntlApp.propTypes = {
    locale: PropTypes.string,
};

const mapStateToProps = ({ app: { locale } }) => ({ locale });

const AppInner = connect(mapStateToProps)(IntlApp);

const breakpoints = {
    mobile: 320,
    mobileLandscape: 480,
    tablet: 768,
    tabletLandscape: 1024,
    desktop: 1200,
    desktopLarge: 1500,
    desktopWide: 1920,
};

const initGoogleAnalytics = () => {
    const googleAnalyticsKey = process.env.GOOGLE_ANALYTICS_KEY;
    ReactGA.initialize(googleAnalyticsKey);
    ReactGA.pageview(window.location.pathname + window.location.search);
};

class App extends Component {
    constructor(props) {
        super(props);
        initGoogleAnalytics();
    }

    render() {
        return (
            <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                    <ReactBreakpoints breakpoints={breakpoints}>
                        <AppInner />
                    </ReactBreakpoints>
                </PersistGate>
            </Provider>
        );
    }
}

export default hot(module)(App);
