import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Form, Icon, Input, Button, Card, Checkbox, message, notification, Spin } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';

import PageLayout from './Layout/PageLayout';
import RecaptchaItem from './Captcha';

import { signInWithOAuth2 } from '../actions/signIn';
import { forgotPassword } from '../utils/apiBucherVaslin';

import './SignIn.less';
import PasswordField from './PasswordField';

const { Item: FormItem } = Form;

class SignIn extends Component {
    state = {
        forgottenPassword: false,
        loading: false,
    };

    componentDidMount() {
        // Remove tokenExpiration value from localStorage
        localStorage.removeItem('tokenExpiration');

        // Remove isAlreadyDisplayFlash value from sessionStorage
        sessionStorage.removeItem('isAlreadyDisplayFlash');
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const {
            form: { validateFields },
            intl,
            signInWithOAuth2: signIn,
        } = this.props;
        const { forgottenPassword } = this.state;
        validateFields(async (err, values) => {
            if (err) {
                return;
            }

            if (forgottenPassword) {
                this.setState({ loading: true });
                const response = await forgotPassword(values);
                this.setState({ loading: false });
                if (false === response.isSuccess) {
                    message.error(intl.formatMessage({
                        id: 'signIn.password.recovery.error',
                    }));
                } else {
                    message.success(intl.formatMessage({
                        id: 'signIn.password.recovery.success',
                    }));
                }

                return;
            }

            this.setState({ loading: true });
            signIn(values).catch((reason) => {
                let text;

                switch(reason) {
                    case "unauthorized":
                        text = intl.formatMessage({
                            id: 'error.unauthorized.message',
                        });
                        break;

                    default:
                        text = intl.formatMessage({
                            id: 'signIn.invalid',
                        });
                        break;
                }
                message.error(text);
                this.setState({ loading: false });
            });
        });
    };

    checkRedirection() {
        const { intl, history: { location, replace } } = this.props;
        const { state } = location;

        if (state && state.forcedLogOut) {
            const inactivityFlag = JSON.parse(localStorage.getItem('expiredFlag'));

            // Check if disconexion is due to innactivity
            if (inactivityFlag) {
                notification.error({
                    message: intl.formatMessage({ id: 'error.inactivity.message' }),
                    description: intl.formatMessage({ id: 'error.inactivity.title' })
                });
                localStorage.removeItem('expiredFlag');
            } else {
                // Display error due to multi device connexion
                notification.error({
                    message: intl.formatMessage({ id: 'signIn.disconnected.title' }),
                    description: intl.formatMessage({ id: 'signIn.disconnected.description' }),
                });
            }
            // We mush erase, manually, the state to avoid notifications on refresh
            replace({ ...location, state: {} });
        }
    }

    render() {
        const { form: { getFieldDecorator }, form } = this.props;
        const { forgottenPassword, loading } = this.state;
        this.checkRedirection();

        return (
            <PageLayout pageKey="signIn">
                <div className="sign-in-container">
                    <Card
                        className="sign-in-card"
                        title={(
                            <FormattedMessage
                                id={forgottenPassword
                                    ? 'signIn.lostPasswordTitle'
                                    : 'signIn.signInTitle'
                                }
                            />
                        )}
                        actions={[(
                            <NavLink to="/newCustomer">
                                <FormattedMessage
                                    id="signIn.createAccount"
                                />
                            </NavLink>
                        )]}
                    >
                        <Form
                            onSubmit={this.handleSubmit}
                            className="login-form"
                        >
                            <FormItem>
                                {getFieldDecorator('login', {
                                    normalize: (value) => value && value.trim(),
                                    rules: [{
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="signIn.loginMessage"
                                            />
                                        ),
                                    }],
                                })(
                                    <Input
                                        prefix={(
                                            <Icon
                                                type="user"
                                                style={{
                                                    color: 'rgba(0,0,0,.25)',
                                                }}
                                            />
                                        )}
                                        placeholder="Username"
                                    />,
                                )}
                            </FormItem>
                            {forgottenPassword ? (
                                <FormItem>
                                    <RecaptchaItem form={form} />
                                </FormItem>
                            ) : (
                                <PasswordField
                                    form={form}
                                    identifier="password"
                                    rules={[{
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="signIn.passwordMessage"
                                            />
                                        ),
                                    }]}
                                    prefix={(
                                        <Icon
                                            type="lock"
                                            style={{
                                                color: 'rgba(0,0,0,.25)',
                                            }}
                                        />
                                    )}
                                    placeholder="Password"
                                />
                            )}
                            <FormItem>
                                <Checkbox
                                    onChange={(e) => {
                                        this.setState({
                                            forgottenPassword: e.target.checked,
                                        });
                                    }}
                                >
                                    <FormattedMessage
                                        id="signIn.forgottenPasswordCheckBox"
                                    />
                                </Checkbox>
                            </FormItem>
                            {loading ? (
                                <Button htmlType="button">
                                    <Spin size="small" />
                                </Button>
                            ) : (
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                >
                                    <FormattedMessage
                                        id={forgottenPassword
                                            ? 'signIn.lostPasswordButton'
                                            : 'signIn.loginButton'
                                        }
                                    />
                                </Button>
                            )}
                        </Form>
                    </Card>
                </div>
            </PageLayout>
        );
    }
}

SignIn.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFields: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    history: PropTypes.shape({
        location: PropTypes.shape({
            state: PropTypes.shape({
                forcedLogOut: PropTypes.bool,
            }),
        }),
    }),
    signInWithOAuth2: PropTypes.func.isRequired,
};

const FormSignIn = Form.create()(SignIn);

export default injectIntl(connect(null, { signInWithOAuth2 })(FormSignIn));
