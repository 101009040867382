import React, { Component, Fragment } from "react";
import PropTypes from 'prop-types';
import { injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Spin } from "antd";
import ProgressIcon from './ProgressIcon';

class ProgressIconList extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadData: false,
            dataExist: false,
            files: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData() {
        const { deviceType, metrics, progressIcons, grandAccess } = this.props;
        this.setState({ loadData: false });
        let resultMetrics = [];
        let metricsToShowIcon = [];

        if (Object.keys(metrics).length <= 0 || grandAccess === false){
            this.setState({ dataExist: false });
        }
        else
        {
            let progressIconsFilter = progressIcons && progressIcons.filter((p) => p.componentMachineDeviceType === deviceType);
            
            if (progressIconsFilter.length > 0){
                // On récupère tous les ProgressIcon groupés par nom de metric
                progressIconsFilter.map((progressIcon) => {
                    if (metricsToShowIcon.length <= 0 || 
                        !metricsToShowIcon.some(m => m.name == progressIcon.componentProgressIcon.codeVariable.code)){
                        metricsToShowIcon.push({name: progressIcon.componentProgressIcon.codeVariable.code, metricExist: false});
                    }
                });
            }
            // On vérifie pour chaque metric des ProgressIcon s'il y a une metric associée
            // Permettra de savoir si l'on récupère la valeur automatiquement
            if (metricsToShowIcon.length > 0){
                metricsToShowIcon = metricsToShowIcon.map((m) => {
                    if (Object.keys(metrics).some(metric => metric == m.name)){
                        m.metricExist = true;
                    }
                    return {
                        ...m
                    }
                });
            }
            // Pour chaque metric existant on va récupérer sa valeur et intégré l'icône associée qui lui correspond
            resultMetrics = metricsToShowIcon && metricsToShowIcon.map((m) => {
                let value;
                if (m.metricExist) {
                    value = metrics[m.name];
                }
                else {
                    // S'il n'y a pas de valeur de remontée pour la metric on utilise la valeur par défaut du ProgressIcon
                    var tempProg = progressIconsFilter && progressIconsFilter.find(prog => prog.componentProgressIcon.codeVariable.code == m.name);
                    value = tempProg !== undefined ? tempProg.componentProgressIcon.defaultValue : null;
                }
                
                var resultProgIcon = progressIconsFilter && progressIconsFilter.find(prog => prog.componentProgressIcon.codeVariable.code == m.name &&
                     ((prog.componentIconValue.exactValue != null && prog.componentIconValue.exactValue == value)
                        || (prog.componentIconValue.minValue != null && prog.componentIconValue.minValue != null
                            && prog.componentIconValue.minValue <= value && value <= prog.componentIconValue.maxValue) ));
                
                if (resultProgIcon !== undefined){
                    return { name: m.name, value: value, nameFile: resultProgIcon.componentIcon.nameFile };
                }
                
            });
            // On ne récupère que les metrics ayant un fichier correspondant à la valeur (des lignes undefined apparaissent par défaut)
            resultMetrics = resultMetrics && resultMetrics.filter(res => res !== undefined);

            if (resultMetrics.length > 0){
                this.setState({ dataExist: true, files: resultMetrics });
            }
            else {
                this.setState({ dataExist: false });
            }
        }

        this.setState({ loadData: true });
    }

    render() {
        const { loadData, dataExist, files } = this.state;

        if (!loadData){
            return <Spin size="small" />;
        }

        if (!dataExist){
            return null;
        }

        return (
            <Fragment>
                {files && files.map((file, index) => {
                    return <ProgressIcon key={index} fileName={file.nameFile} />;
                })}
            </Fragment>
        );
    }
}

ProgressIconList.propTypes = {
    clientID: PropTypes.string.isRequired,
    userRole: PropTypes.array,
    intl: intlShape.isRequired,
    progressIcons: PropTypes.arrayOf(PropTypes.object).isRequired,
    metrics: PropTypes.object.isRequired,
    deviceType: PropTypes.string.isRequired,
}

const mapStateToProps = ({ signIn: { clientID, userRole } }) => ({
    clientID,
    userRole,
});

export default injectIntl(connect(mapStateToProps)(ProgressIconList));