import React from 'react';

import PageLayout from './Layout/PageLayout';
import HtmlIncluder from './HtmlIncluder';

const Downloads = () => (
    <PageLayout pageKey="downloads" flex>
        <HtmlIncluder fileName="downloads" />
    </PageLayout>
);

export default Downloads;
