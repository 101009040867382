import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
} from "antd";
import { FormattedMessage, injectIntl } from "react-intl";
import RequiredFieldLegend from "../RequiredFieldLegend";
import {
  getListCriticites,
  getListOccasions,
  createDemande,
} from "../../utils/apiBucherVaslin";
import SearchArticles from "../Orders/SearchArticles";
import "./NetworkRequests.less";

const { Item: FormItem } = Form;
const { Option } = Select;
const { TextArea } = Input;

const CreateNetworkRequests = ({ intl, form, refresh }) => {
  const [criticites, setCriticites] = useState([]);
  const [occasions, setOccasions] = useState([]);
  const [visible, setVisible] = useState(false);
  const [codeArticle, setCodeArticle] = useState(null);
  const [characterCountLibelle, setCharacterCountLibelle] = useState(0);
  const [characterCountCommentaire, setCharacterCountCommentaire] = useState(0);
  const { getFieldDecorator, setFieldsValue, validateFields } = form;

  function submitNetworkRequests(statut) {
    validateFields(async (err, values) => {
      if (err) {
        return;
      }
      const { isSuccess } = await createDemande(values, statut);
      if (isSuccess) {
        notification.success({
          message: intl.formatMessage({
            id: "networkRequests.modal.success",
          }),
        });
        setVisible(!visible);
        refresh();
        return;
      }
      notification.error({
        message: intl.formatMessage({
          id: "order.new.errorMessage",
        }),
      });
    });
  }

  useEffect(() => {
    getListCriticites().then(({ criticites }) => {
      setCriticites(criticites);
    });
    getListOccasions().then(({ occasions }) => {
      setOccasions(occasions);
    });
  }, []);
  return (
    <Fragment>
      <Button
        type="primary"
        onClick={() => setVisible(!visible)}
        style={{ margin: "2px" }}
      >
        <FormattedMessage id="networkRequests.modal.title" />
      </Button>
      <Modal
        title={<FormattedMessage id="networkRequests.modal.title" />}
        width="30%"
        visible={visible}
        onCancel={() => setVisible(!visible)}
        footer={[
          <Button type="danger" onClick={() => setVisible(!visible)}>
            <FormattedMessage id="common.cancel" />
          </Button>,
          <Button type="default" onClick={() => submitNetworkRequests("Draft")}>
            <FormattedMessage id="networkRequests.modal.submitDraft" />
          </Button>,
          <Button
            type="primary"
            onClick={() => submitNetworkRequests("Published")}
          >
            <FormattedMessage id="networkRequests.modal.submit" />
          </Button>,
        ]}
      >
        <Fragment>
          <FormItem
            label={
              <Fragment>
                <FormattedMessage id="table.articles.code" />
              </Fragment>
            }
          >
            {getFieldDecorator("codeArticle")(
              <Input style={{ width: "40%", marginRight: "20px" }} disabled />
            )}
            <SearchArticles
              warrantyPiece={true}
              onSubmitWarrantyPiece={(
                codeArticleSelected,
                prix,
                libelleSelected
              ) => (
                setCodeArticle(codeArticleSelected),
                setCharacterCountLibelle(libelleSelected.length),
                setFieldsValue({ codeArticle: codeArticleSelected }),
                setFieldsValue({ libelle: libelleSelected })
              )}
            />
          </FormItem>
          <FormItem
            label={
              <Fragment>
                <FormattedMessage id="table.order.label" /> (
                {characterCountLibelle} / 80)
              </Fragment>
            }
          >
            {getFieldDecorator("libelle", {
              rules: [
                {
                  max: 80,
                  message: intl.formatMessage(
                    { id: "user.form.commentMaxCarac" },
                    { max: 80 }
                  ),
                },
                {
                  required: true,
                  message: <FormattedMessage id="table.order.labelRequired" />,
                },
              ],
            })(
              <Input
                maxLength={80}
                onChange={(e) =>
                  setCharacterCountLibelle(e.target.value.length)
                }
                disabled={codeArticle != null}
              />
            )}
          </FormItem>
          <FormItem
            label={
              <Fragment>
                <FormattedMessage id="table.order.comment" /> (
                {characterCountCommentaire} / 500)
              </Fragment>
            }
          >
            {getFieldDecorator("commentaire", {
              rules: [
                {
                  max: 500,
                  message: intl.formatMessage(
                    { id: "user.form.commentMaxCarac" },
                    { max: 500 }
                  ),
                },
              ],
            })(
              <TextArea
                autoSize={{ minRows: 3 }}
                maxLength={500}
                onChange={(e) =>
                  setCharacterCountCommentaire(e.target.value.length)
                }
              />
            )}
          </FormItem>
          <div className="newtworkRequestsElem-inline">
            <FormItem
              label={
                <Fragment>
                  <FormattedMessage id="table.order.quantite" />
                </Fragment>
              }
            >
              {getFieldDecorator("qty", {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="table.order.quantiteRequired" />
                    ),
                  },
                ],
              })(<InputNumber precision={2} min={0} />)}
            </FormItem>
            <FormItem
              label={<FormattedMessage id="networkRequests.modal.dateBesoin" />}
            >
              {getFieldDecorator("dateBesoin", {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="networkRequests.modal.dateBesoinRequired" />
                    ),
                  },
                ],
              })(
                <DatePicker
                  disabledDate={(d) => !d || d.isBefore(new Date())}
                  format="DD/MM/YY"
                />
              )}
            </FormItem>
          </div>
          <div className="newtworkRequestsElem-inline">
            <FormItem
              label={<FormattedMessage id="contact.form.criticiteid" />}
            >
              {getFieldDecorator("criticiteDynamicsFieldsId", {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="contact.form.criticiteRequired" />
                    ),
                  },
                ],
              })(
                <Select style={{ width: "100%" }}>
                  {criticites.map((criticite) => (
                    <Option key={criticite.valueField}>
                      {criticite.libelleField}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <FormItem
              label={<FormattedMessage id="networkRequests.modal.occasion" />}
            >
              {getFieldDecorator("occasionDynamicsFieldsId", {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="networkRequests.modal.occasionRequired" />
                    ),
                  },
                ],
              })(
                <Select style={{ width: "100%" }}>
                  {occasions.map((occasion) => (
                    <Option key={occasion.valueField}>
                      {occasion.libelleField}
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
          </div>
          <RequiredFieldLegend />
        </Fragment>
      </Modal>
    </Fragment>
  );
};
export default Form.create()(injectIntl(CreateNetworkRequests));
