import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Empty, Icon, Input, notification, Table } from 'antd';
import { fetchArticles, fetchArticlesForFamily } from '../utils/apiBucherVaslin';
import { alphabetically, compareNumber } from '../utils/helpers';
import ArticleComponents from './ArticleComponents';
import  FormattedCurrency  from './utils/FormattedCurrency';
import { isGroupEBV, isSuperAdmin } from '../utils/helpers';

const tableName = 'articles';

class Articles extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            articles: [],
            familyCode: props.familyCode,
            selectedArticles: []
        };
    }

    async componentDidMount() {
        this.fetchDataAsync();
    }

    componentDidUpdate(prevProps, prevState) {
        const { familyCode } = this.state;
        if (prevState.familyCode !== familyCode) {
            this.fetchDataAsync();
        }
    }

    getColumns() {
        const { showFamily, userRole } = this.props;

        const columns = [{
            dataIndex: 'libelle',
            key: 'label',
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.libelle),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            sorter: (a, b) => alphabetically(a.libelle, b.libelle),
        }, {
            dataIndex: 'codeArticle',
            key: 'code',
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.codeArticle),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            sorter: (a, b) => alphabetically(a.codeArticle, b.codeArticle),
            render: (_, { codeArticle }) => (<span>{parseInt(codeArticle, 10)}</span>),
        }];

        if (showFamily) {
            columns.push({
                dataIndex: 'libelleFamille',
                key: 'libelleFamille',
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.libelleFamille),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.libelleFamille, b.libelleFamille),
            });
        }
        if(isGroupEBV({ userRole }) || isSuperAdmin({ userRole })){
            columns.push({
                dataIndex: '',
                key: 'prixRemise',
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => parseInt(value, 10) === parseInt(record.prix, 10),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => compareNumber(a.prix, b.prix),
                render: (value) => value,
                render: (text, row, index) => { 
                    return <FormattedCurrency amount={row.prix} currency={row.codeDevise} />
                },
  
            });
        };
        return [...columns, {
            dataIndex: '',
            key: 'prixPublic',
            render: (text, row, index) => { 
            if(row.prix != 0 ) {
                return <FormattedCurrency amount={row.prixPublic} currency={row.codeDevise} />
                }
            },
            align: 'right',
        }];
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.familyCode !== prevState.familyCode) {
            return { familyCode: nextProps.familyCode };
        }

        return null;
    }

    onSelectedRowKeysChange = (selectedArticlesKeys, selectedArticles) => {
        const { onArticlesSelected } = this.props;
        onArticlesSelected(selectedArticles);
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    onFilter = (value, obj) => obj && obj.toLowerCase().includes(value.toLowerCase());

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    async fetchDataAsync() {
        const { client, familyCode, intl, numCommande } = this.props;
        if (!client || !familyCode) {
            this.setState({ loading: false, articles: [] });

            return;
        }

        this.setState({ loading: true });
        const { items, isSuccess, isUnauthorized, errorMessage, errorDetail } = familyCode && client
        ? await fetchArticlesForFamily(client, familyCode, numCommande)()
        : await fetchArticles(client, numCommande)();

        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
        this.setState({ articles: items, loading: false });
    }

    getEmptyTextForTable() {
        const {loading} = this.state;
        const {familyCode} = this.props;

        // Let default message behavior when table is loading
        if (loading) {
            return "";
        } else {
            // Show no data message if familly code is set
            if (familyCode) {
                return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            } 
            else {
                // Show Specific message to ask user to select a product family
                return (
                    <span style={{color:"rgba(0, 0, 0, 0.5)", fontSize:"1.3em"}}>
                        <Icon type="exclamation-circle" style={{marginRight:"0.5em"}} />
                        <FormattedMessage id='table.article.emptyFamily' />
                    </span>
                );
            }
        }
    }

    render() {
        const { allowSelection } = this.props;
        const { articles, loading, selectedArticles } = this.state;

        let rowSelection = null;
        if (allowSelection) {
            rowSelection = {
                selectedArticles,
                onChange: this.onSelectedRowKeysChange,
            };
        }

        const translatedColumns = this.getColumns().map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        return (
            <Table
                className="custom-table"
                columns={translatedColumns}
                dataSource={articles}
                expandedRowRender={ArticleComponents}
                loading={loading}
                locale={{ emptyText: this.getEmptyTextForTable() }}
                rowClassName={(article) => (article.composants && article.composants.length ? null : 'no-expand')}
                rowKey="codeArticle" // TODO: avec composants enfants warning sur clé non unique dans la table 
                rowSelection={rowSelection}
                //scroll={{ x: true }} // non compatible avec expandedRowRender
            />
        );
    }
}

Articles.propTypes = {
    familyCode: PropTypes.string,
    client: PropTypes.string.isRequired,
    allowSelection: PropTypes.bool,
    onArticlesSelected: PropTypes.func,
    showFamily: PropTypes.bool,
    intl: intlShape.isRequired,
    numCommande: PropTypes.string,
    userRole: PropTypes.array.isRequired
};

const mapStateToProps = ({signIn: { userRole } }) => ({
    userRole
});

export default connect(mapStateToProps)(injectIntl(Articles));
