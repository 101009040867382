import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { intlShape, injectIntl, FormattedMessage } from 'react-intl';
import PageLayout from '../Layout/PageLayout';
import { Tabs } from 'antd';
import ListDefaultHistorique from './ListDefaultHistorique';
import ListDefaultSynthese from './ListDefaultSynthese';

const { TabPane } = Tabs;

class ListDefault extends Component {
    constructor(props){
        super(props);
        this.state = {
            nameDevice: null,
            deviceFamily: null
        };
    }

    componentDidMount() {
        const {
            location: {
                state: { famille, nameDevice },
            }
        } = this.props;
        this.setState({ 
            deviceFamily: (famille) ? famille.toLowerCase() : "preview", 
            nameDevice 
        });
    }

    callbackTab(key) {
    
    }

    render () {
        const { deviceFamily, nameDevice } = this.state;
        const { history, location, match } = this.props;

        return (
            <PageLayout pageKey="histoDefault" history={history}>
                <h2>{<FormattedMessage id="cycles.default.title" values={{name: nameDevice}} />}</h2>

                <Tabs onChange={this.callbackTab} type="card" defaultActiveKey="1">
                    {/* Historic Tab */}
                    <TabPane tab={<FormattedMessage id="cycles.historic.title" />} key="1">
                        <ListDefaultHistorique location={location} match={match} />
                    </TabPane>

                    {/* Synthesis Tab */}
                    <TabPane tab={<FormattedMessage id="cycles.synthesis.title" />} key="2">
                        <ListDefaultSynthese location={location} match={match} />
                    </TabPane>
                </Tabs>
            </PageLayout>
        );
    }
}

ListDefault.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            serial: PropTypes.string,
        }).isRequired,
    }).isRequired,
    location: PropTypes.shape({
        state: PropTypes.shape({
            nameDevice: PropTypes.string.isRequired,
        }).isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
}

const mapStateToProps = ({}) => ({

});

export default injectIntl(connect(mapStateToProps)(ListDefault));