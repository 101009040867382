import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Divider, Modal, message, notification, Popover } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileContract, faPen, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { deleteUserById } from '../../utils/apiBucherVaslin';
import PasswordGenerate from '../PasswordGenerate';

const { confirm } = Modal;

const showDeleteConfirm = (intl, idUser, refresh, name) => {
    confirm({
        title: intl.formatMessage({
            id: 'table.users.deleteModal.title',
        }, { name }),
        okText: intl.formatMessage({
            id: 'common.yes',
        }),
        cancelText: intl.formatMessage({
            id: 'common.no',
        }),
        okType: 'danger',
        async onOk() {

            const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await deleteUserById(idUser);

            if (isSuccess) {
                message.success(
                    intl.formatMessage(
                        {
                            id: 'table.users.delete.success',
                        },
                    ),
                    5,
                );

                refresh();

                return;
            }
            
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        },
    });
};

const UsersTableActions = ({
    intl,
    idUser,
    refresh,
    name,
    email,
}) => (
    <span>
        <Popover content={<FormattedMessage id="table.users.edit" />}>
            <Link to={`/users/${idUser}/edition`}>
                <Button htmlType="button">
                    <FontAwesomeIcon icon={faPen} />
                </Button>
            </Link>
        </Popover>
        <Divider type="vertical" />
        <Popover content={<FormattedMessage id="table.users.licences" />}>
            <Link to={`/licenses/${idUser}`}>
                <Button htmlType="button">
                    <FontAwesomeIcon icon={faFileContract} />
                </Button>
            </Link>
        </Popover>
        <Divider type="vertical" />
        <PasswordGenerate intl={intl} name={name} email={email} />
        <Divider type="vertical" />
        <Popover content={<FormattedMessage id="table.users.delete" />}>
            <Button
                htmlType="button"
                type="danger"
                onClick={() => showDeleteConfirm(intl, idUser, refresh, name)}
            >
                <FontAwesomeIcon icon={faTrashAlt} />
            </Button>
        </Popover>
    </span>
);

UsersTableActions.propTypes = {
    intl: intlShape.isRequired,
    idUser: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired,
    name: PropTypes.string,
    email: PropTypes.string,
};

export default injectIntl(UsersTableActions);
