import React, { useState, useEffect } from "react";
import { Table } from "antd";
import { FormattedMessage } from "react-intl";
import { getListEchangesReseau } from "../../utils/apiBucherVaslin";
import {
  PrendreEnCharge,
  FilterDropDown,
  OnFilter,
  OnFilterDropdownVisibleChange,
} from "./NetworkRequestsActions";
import { alphabetically } from "../../utils/helpers";

const getColumns = (columns, refresh) => {
  let columnsWhitAction = [
    ...columns,
    {
      dataIndex: "demandeurNomClient",
      key: "demandeurNomClient",
      filterDropdown: FilterDropDown,
      onFilter: (value, record) =>
        OnFilter(value, record.demandeurNomClient.toString()),
      onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
      sorter: (a, b) =>
        alphabetically(a.demandeurNomClient, b.demandeurNomClient),
      title: <FormattedMessage id={`networkRequests.table.demandeur`} />,
    },
    {
      dataIndex: "demandeurPays",
      key: "demandeurPays",
      filterDropdown: FilterDropDown,
      onFilter: (value, record) =>
        OnFilter(value, record.demandeurPays.toString()),
      onFilterDropdownVisibleChange: OnFilterDropdownVisibleChange,
      sorter: (a, b) => alphabetically(a.demandeurPays, b.demandeurPays),
      title: <FormattedMessage id={`table.customers.country`} />,
    },
    {
      dataIndex: "actions",
      key: "actions",
      title: <FormattedMessage id={`table.users.action`} />,
      render: (_, record) => (
        <PrendreEnCharge id={record.id} refresh={refresh} />
      ),
    },
  ];
  return columnsWhitAction;
};
const NetworkRequests = ({ columns, isRefreshing, refresh }) => {
  const [loadNetworkRequests, setLoadNetworkRequests] = useState(true);
  const [networkRequestsData, setNetworkRequestsData] = useState([]);
  useEffect(() => {
    getListEchangesReseau("DemandesDuReseau").then(({ listEchangesReseau }) => {
      setNetworkRequestsData(listEchangesReseau);
      setLoadNetworkRequests(false);
    });
  }, [isRefreshing]);
  return (
    <Table
      className="custom-table"
      dataSource={networkRequestsData}
      columns={getColumns(columns, refresh)}
      rowKey={(record) => record.id}
      loading={loadNetworkRequests}
    />
  );
};

export default NetworkRequests;
