import { combineReducers } from 'redux';

import app from './app';
import signIn from './signIn';
import params from './params';
import tables from './tables';

const reducer = combineReducers({
    app,
    signIn,
    params,
    tables,
});

export default reducer;
