import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Divider, Form, Button, Icon, notification, Spin, Switch, Input, Radio, Select, Tooltip } from 'antd';
import { FormattedMessage, injectIntl, intlShape, FormattedHTMLMessage } from 'react-intl';
import ClientFormItems from './FormItems';
import RequiredFieldLegend from '../RequiredFieldLegend';
import UserFormItems from '../User/FormItems';
import { tailFormItemLayout, formItemLayout } from '../../utils/formItemLayout';
import RecaptchaItem from '../Captcha';
import { BVSubsidiaries } from '../../utils/constants';

const { Item: FormItem } = Form;
const { Group } = Radio;
const { Option } = Select;
const  local  = (process.env.BACKEND_URL == 'http://localhost:16111' ? true : false);
class CreateForm extends Component {
    state = {
        saving: false,
        createNoUser: false,
        subsidiaries: BVSubsidiaries,
        nomFililaleSelected: null
    };

    handleSubmit = (e) => {
        e.preventDefault();
        const { form: { validateFieldsAndScroll }, intl, save } = this.props;
        validateFieldsAndScroll((err, values) => {
            this.setState({ saving: true });
            if (!err) {
                save(values)
                    .catch((error) => {
                        notification.error({
                            message: intl.formatMessage({ id: 'common.error' }),
                            description: 'Failed : DuplicateUserName' === error.message
                                ? intl.formatMessage({ id: 'user.create.error.duplicate' })
                                : error.message,
                        });
                    })
                    .finally(() => {
                        this.setState({ saving: false });
                    });
            } else {
                this.setState({ saving: false });
            }
        });
    }

    handleChange(code) {
        const {subsidiaries} = this.state;
        const filiale = subsidiaries.find((subsidiarie) => subsidiarie.code === code);
        const nomFililaleSelected = filiale.label;
        this.setState({nomFililaleSelected})
    }

    render() {
        const { form, loggedIn, intl, pageKey } = this.props;
        const { saving, createNoUser, subsidiaries, nomFililaleSelected } = this.state;
        const dealerType = form.getFieldValue('dealerType');

        return (
            <Form onSubmit={this.handleSubmit}>
                {loggedIn && (
                    <div className="alert-switches">
                        {form.getFieldDecorator('CreateNoUser', {
                            rules: [],
                            initialValue: createNoUser,
                        })(
                            <Fragment>
                                <Switch
                                    defaultChecked={createNoUser}
                                    onChange={value => { this.setState({createNoUser: value}); }}
                                    className="alert-switch"
                                />
                                <FormattedMessage
                                    id="client.create.noUser.title"
                                    defaultMessage="Create this account without client user"
                                />
                            </Fragment>
                        )}
                    </div>
                )}
                {createNoUser ? (
                    <Fragment><b>
                        <FormattedMessage
                            id="client.create.noUser.active"
                        /></b>
                    </Fragment>
                ) : (
                    <Fragment>
                        <Divider>
                            <FormattedMessage
                                id="client.create.mainUser"
                                defaultMessage="Customer account administrator"
                            />
                            <Tooltip title={<FormattedHTMLMessage id="client.create.info" />}>
                                <Icon type="info-circle" style={{marginLeft: "4px"}} />
                            </Tooltip>
                        </Divider>
                        <UserFormItems
                            form={form}
                            intl={intl}
                            userPrefix="user."
                        />
                    </Fragment>
                )}
                
                <Divider>
                    <FormattedMessage
                        id="client.create.client"
                        defaultMessage="Client"
                    />
                </Divider>
                <ClientFormItems
                    form={form}
                    clientPrefix="client."
                    pageKey= "newCustomer"
                />
                <Divider />
                {loggedIn ? null : (
                    <Fragment>
                        {/* Choose dealer type (BV or Other) */}
                        <FormItem
                            {...formItemLayout}
                            label={(
                                <FormattedMessage
                                    id="client.create.dealerType"
                                />
                            )}
                        >
                            {form.getFieldDecorator('dealerType', {
                                rules: [{
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="client.create.dealerType.required"
                                        />
                                    ),
                                }],
                            })(
                                <Group>
                                    <Radio value="bv">
                                        <FormattedMessage id="client.create.dealerType.bv" />
                                    </Radio>
                                    <Radio value="other">
                                        <FormattedMessage id="client.create.dealerType.other" />
                                    </Radio>
                                </Group>
                            )}
                        </FormItem>
                    
                        {/* If selected dealer type is "other" */}
                        { dealerType === "other" && (
                            <Fragment>
                                {/* Dealer name field */}
                                <FormItem
                                    {...formItemLayout}
                                    label={(
                                        <FormattedMessage
                                            id="customerDetails.line.dealer"
                                            defaultMessage="Concessionnaire"
                                        />
                                    )}
                                >
                                    {form.getFieldDecorator('client.dynamicsDealerAnnotation', {
                                        rules: [{
                                            required: true,
                                            message: (
                                                <FormattedMessage
                                                    id="customerDetails.line.dealerRequired"
                                                    defaultMessage="Please enter a dealer name"
                                                />
                                            ),
                                        }],
                                    })(
                                        <Input />,
                                    )}
                                </FormItem>
                            </Fragment>
                        )}

                        {/* If selected dealer type is "bv" */}
                        { dealerType === "bv" && (
                            <Fragment>
                                {/* Select BV entity */}
                                <FormItem
                                    {...formItemLayout}
                                    label={(
                                        <FormattedMessage
                                            id="client.create.subsidiaryCompany"
                                        />
                                    )}
                                >
                                    {form.getFieldDecorator('client.dynamicsDealerId', {
                                        rules: [{
                                            required: true,
                                            message: (
                                                <FormattedMessage
                                                    id="client.create.subsidiaryCompany.required"
                                                />
                                            ),
                                        }],
                                    })(
                                        <Select  onChange={(value) => this.handleChange(value)}>
                                            { subsidiaries && subsidiaries.length > 0 && subsidiaries.map((company) => (
                                                <Option key={company.code} value={company.code} >
                                                    {company.label}
                                                </Option>
                                            ))}
                                        </Select>,
                                    )}
                                </FormItem>
                                {form.getFieldDecorator(`subsidiaryCompany`,{initialValue:nomFililaleSelected})(
                                        <Input hidden={true}/>
                                    )}
                            </Fragment>
                        )}
                        
                    </Fragment>
                )}
                {loggedIn || local == true ? null : (
                    // Affichage du recaptcha
                    <FormItem {...tailFormItemLayout}>
                        <RecaptchaItem form={form} />
                    </FormItem>
                )}
                <FormItem {...tailFormItemLayout}>
                    <RequiredFieldLegend />
                </FormItem>
                <FormItem {...tailFormItemLayout}>
                    {saving ? (
                        <Spin />
                    ) : (
                        <Button
                            type="primary"
                            htmlType="submit"
                        >
                            <FormattedMessage
                                id="client.create.button"
                                defaultMessage="Create account"
                            />
                        </Button>
                    )}
                </FormItem>
            </Form>
        );
    }
}

CreateForm.propTypes = {
    form: PropTypes.shape({
        validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    save: PropTypes.func.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    pageKey:PropTypes.string
};

export default Form.create()(injectIntl(CreateForm));
