import { Button, Divider, Icon, Popover } from 'antd';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

// const MissingPieceModal = (
//     <div>
//         <div>
//             <FormattedMessage id="documents.missing.description" />
//             <br />
//             <strong>A80C28</strong>
//         </div>
//         <br />
//         <Button type="primary" htmlType="button">
//             <Icon type="shopping-cart" />
//             <FormattedMessage id="documents.buy" />
//         </Button>
//         <Divider type="vertical" />
//         <Button htmlType="button">
//             <Icon type="eye" />
//             <FormattedMessage id="documents.show" />
//         </Button>
//     </div>
// );

const DocumentActions = ({ link }) => ( //enableShop, item, itemSuggestion,
    <span className="actions">
        {/* {enableShop && itemSuggestion && (
            <Popover content={MissingPieceModal} title={<FormattedMessage id="documents.missing" />}>
                <Button htmlType="button" disabled>
                    <Icon type="shopping-cart" />
                    <FormattedMessage id="documents.buy" />
                </Button>
            </Popover>
        )} */}
        {/* {enableShop && !itemSuggestion && (
            <Button htmlType="button">
                <Icon type="shopping-cart" />
                <FormattedMessage id="documents.buy" />
            </Button>
        )} */}
        <Divider type="vertical" />
        <a href={link} download>
            <Button htmlType="button">
                <Icon type="download" />
                <FormattedMessage id="documents.download" />
            </Button>
        </a>
        {/* <Divider type="vertical" />
        <a href={link} target="_blank" rel="noopener noreferrer">
            <Button htmlType="button">
                <Icon type="link" />
                <FormattedMessage id="documents.opentab" />
            </Button>
        </a> */}
    </span>
);

DocumentActions.propTypes = {
    enableShop: PropTypes.bool,
    item: PropTypes.shape({}),
    itemSuggestion: PropTypes.bool,
    link: PropTypes.string.isRequired,
};

export default DocumentActions;
