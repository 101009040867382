import {
  faExclamationTriangle,
  faExclamationCircle,
  faInfoCircle,
  faSyncAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Button,
  Divider,
  Icon,
  Input,
  notification,
  Popover,
  Spin,
  Switch,
  Table,
} from "antd";
import { isNil } from "lodash";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { connect } from "react-redux";
import { NavLink } from "react-router-dom";
import {
  fetchConnectedDevices,
  fetchMachineData,
  updateDeviceHistoryState,
  updateDevice,
} from "../../utils/apiBucherVaslin";
import {
  alphabetically,
  canAccessRight,
  isGroupAdminClient,
  isGroupBV,
  isGroupConcessionnaire,
  isSuperAdmin,
  isInfoCompleted,
} from "../../utils/helpers";
import ChangeName from "../ChangeName";
import GiveMachine from "../GiveMachine";
import PageLayout from "../Layout/PageLayout";
import ActivateDevice from "./ActivateDevice";
import ModalGenerateForDealer from "../Licenses/ModalGenerateForDealer";
import {
  FEATURE_LICENSE_GENERATE,
  FEATURE_LICENSE_ASSIGN,
} from "../../utils/constants";
import ProgressIconList from "../Templates/ProgressIconList";
import { multiPropsFilter, sorterArrayObject } from "../../utils/helperArray";
import ActivateGuarantee from "./ActivateGuarantee";
import MachineHandling from "../MachineHandling";

const tableName = "devicesLots";

class ConnectedDevices extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      reloadData: true,
      devices: [],
      canAccess: false,
      filters: {},
      sorter: {},
      pageSize: 10,
      pageCurrent: 1,
    };
  }

  async componentDidMount() {
    /* check connexion and information */
    const { userID, intl } = this.props;
    await isInfoCompleted(intl, userID);

    this.fetchDataAsync();
  }

  // Récupération des données
  async fetchDataAsync() {
    this.setState({ loading: false, canAccess: false });
    const {
      intl,
      match: {
        params: { eureka },
      },
    } = this.props;
    const { pageSize, pageCurrent } = this.state;
    let deviceIds = [];
    let displayedDevices = [];

    // On récupère les devices liés à l'utilisateur
    let {
      isSuccess,
      items: devices,
      isUnauthorized,
      errorMessage,
      errorDetail
    } = await fetchConnectedDevices(eureka)();

    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
      this.setState({ canAccess: false, loading: true });
      return;
    }

    // On ajoute l'objet data qui servira à contenir les data de la machine
    if (devices.length > 0) {
      devices = devices.map((device, index) => {
        if (index < pageSize) {
          // device.hasLicenceConnexion ? console.log(device) : null;
          deviceIds.push({ id: device.id });
          displayedDevices.push(device);
        }
        return Object.assign({}, device, { metrics: {}, metricsLoaded: false });
      });
    }

    this.setState(
      { devices, canAccess: true, loading: true, displayedDevices },
      async () => {
        await this.updateMetricsOfDevices(pageSize, pageCurrent, deviceIds);
      }
    );
  }

  // Met à jour les metrics des devices affichés
  updateMetricsOfDevices = async (pageSize, currentPage, deviceIds = []) => {
    const { locale: lang } = this.props;
    const { devices } = this.state;

    Promise.all(
      devices &&
        devices.map(async (device, index) => {
          // On ne récupère les metrics que des devices étant dans la liste des id reçu
          if (
            !device.metricsLoaded &&
            deviceIds.some((dev) => dev.id == device.id)
          ) {
            const result = await fetchMachineData(device.serial, lang);
            device.metrics = result;
            device.metricsLoaded = true;
          }
          return {
            ...device,
          };
        })
    ).then((devicesResponse) => {
      this.setState({
        devices: devicesResponse,
        pageSize,
        pageCurrent: currentPage,
      });
    });
  };

  // Raffraichis les données de la liste des machines
  refreshDevices = async () => {
    const {
      intl,
      match: {
        params: { eureka },
      },
    } = this.props;
    const { pageSize, pageCurrent, filters, sorter } = this.state;
    const pagination = { current: pageCurrent, pageSize };

    // Passe le chargement à en cours
    this.setState({ reloadData: false });

    // On récupère les devices liés à l'utilisateur
    let {
      isSuccess,
      items: devices,
      isUnauthorized,
      errorMessage,
      errorDetail
    } = await fetchConnectedDevices(eureka)();

    // Gestion d'une erreur de récupération des données
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
      this.setState({ canAccess: false, reloadData: true });
      return;
    }

    // En cas de succès, met à jour le state
    this.setState({ devices });
    await this.onPageChange(pagination, filters, sorter);
    this.setState({ canAccess: true, reloadData: true });
  };

  // Appelé au changement de page de la table des devices
  onPageChange = async (pagination, filters, sorter) => {
    const { devices, pageSize } = this.state;
    let devicesWork = Object.assign([], devices);
    let deviceIds = [];

    if (filters && sorter) {
      this.setState({ filters, sorter });
    }

    let filterObject = this.getFilterObject(filters);
    let sorterObject = this.getSorterObject(sorter);

    if (filterObject.isFilter) {
      devicesWork = multiPropsFilter(devices, filters);
    }
    if (sorterObject.isSorter) {
      devicesWork = Object.assign(
        [],
        sorterArrayObject(devicesWork, sorter.field, sorter.order === "ascend")
      );
    }

    // Récupère la liste des devices visible
    let visibleDevices = [];
    devicesWork.forEach((device, index) => {
      if (
        index >= (pagination.current - 1) * pageSize &&
        index < pagination.current * pageSize
      ) {
        visibleDevices.push(device);
      }
    });

    devicesWork &&
      devicesWork.map((device, index) => {
        if (
          !device.metricsLoaded &&
          index + 1 - pagination.pageSize * pagination.current < 1 &&
          index + 1 > pagination.pageSize * (pagination.current - 1)
        ) {
          deviceIds.push({ id: device.id });
        }
      });

    await this.updateMetricsOfDevices(
      pagination.pageSize,
      pagination.current,
      deviceIds
    );
  };

  getFilterObject = (filters) => {
    let isFilter = false;
    let searchValues = [];
    if (Object.keys(filters).length <= 0) {
      return { isFilter: isFilter, searchValues: searchValues };
    } else {
      Object.keys(filters).map((key) => {
        if (filters[key].length > 0) {
          isFilter = true;
          searchValues.push({
            columnName: key,
            columnSearchValue: filters[key][0],
          });
        }
      });
    }
    return { isFilter: isFilter, searchValues: searchValues };
  };

  getSorterObject = (sorter) => {
    let isSorter = false;
    let order = null;
    let columnName = null;
    if (Object.keys(sorter).length <= 0) {
      return { isSorter: isSorter, order: order, columnName: columnName };
    } else {
      if (sorter["column"] !== undefined) {
        isSorter = true;
        order = sorter["order"];
        columnName = sorter["field"];
      }
    }
    return { isSorter: isSorter, order: order, columnName: columnName };
  };

  getColumns() {
    return [
      {
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => alphabetically(a.name, b.name),
        filterDropdown: this.filterDropDown,
        onFilter: (value, record) => this.onFilter(value, record.name),
        onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
      },
      {
        dataIndex: "serialMachine",
        key: "serialMachine",
        sorter: (a, b) => alphabetically(a.serialMachine, b.serialMachine),
        filterDropdown: this.filterDropDown,
        onFilter: (value, record) => this.onFilter(value, record.serialMachine),
        onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
      },
      {
        dataIndex: "serial",
        key: "serial",
        sorter: (a, b) => alphabetically(a.serial, b.serial),
        filterDropdown: this.filterDropDown,
        onFilter: (value, record) => this.onFilter(value, record.serial),
        onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
      },
      {
        dataIndex: "type",
        key: "type",
        sorter: (a, b) => alphabetically(a.type, b.type),
        filterDropdown: this.filterDropDown,
        onFilter: (value, record) => this.onFilter(value, record.type),
        onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
      },
    ];
  }

  // Affiche le nombre de licences et le nombre de licences expirées
  getLicences = () => [
    {
      title: <FormattedMessage id={`table.${tableName}.licences`} />,
      key: "licences",
      render: (_, { numberLicenses, numberLicensesExpired }) => (
        <div style={{ textAlign: "center" }}>
          {numberLicenses}

          <Popover
            content={
              <span>
                <FormattedMessage
                  id="table.devicesLots.validLicence"
                  values={{ numberLicenses }}
                />

                {numberLicensesExpired && numberLicensesExpired > 0 ? (
                  <span>
                    <br />
                    <FormattedMessage
                      id="table.devicesLots.expiredLicence"
                      values={{ numberLicensesExpired }}
                    />
                  </span>
                ) : (
                  ""
                )}
              </span>
            }
          >
            <FontAwesomeIcon
              icon={
                numberLicensesExpired === 0 ? faInfoCircle : faExclamationCircle
              }
              style={{
                marginLeft: "0.5em",
                fontSize: "12px",
                color: numberLicensesExpired > 0 ? "#DC0023" : "yellowgreen",
              }}
            />
          </Popover>
        </div>
      ),
    },
  ];

  // Affiche la colonne Etat de la table pour chacune des lignes affichées
  getEtats = (progressIcons) => [
    {
      key: "etats",
      render: (_, { serial, metrics, metricsLoaded, type, hasLicenceConnexion, hasLicenceConnexionValide, ...props }) => {
        if (!metricsLoaded) {
          return <Spin size="small" />;
        }

        return (
          <span>
            <ProgressIconList
              progressIcons={progressIcons}
              metrics={metrics}
              deviceType={type}
              grandAccess = {hasLicenceConnexion && hasLicenceConnexionValide ? true : false}
            />
          </span>
        );
      },
    },
  ];

  getHistorics = (userRole) => [
    {
      title: <FormattedMessage id={`table.${tableName}.history`} />,
      key: "history",
      render: (
        _,
        {
          historySaveEnabled,
          name: machineName,
          serial,
          famille,
          hasLicenceConnexion,
          authorizeTransfert,
        }
      ) => (
        
        <span>
          <Switch
            defaultChecked={historySaveEnabled}
            disabled ={!hasLicenceConnexion}
            onChange={(value) => {
              updateDeviceHistoryState(serial, value).then(
                ({ isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
                  if (!isSuccess) {
                    const { intl } = this.props;
                    notification.error({
                      message: isUnauthorized
                        ? intl.formatMessage({ id: "error.unauthorized.title" })
                        : intl.formatMessage({ id: "common.error" }),
                      description: isUnauthorized
                        ? intl.formatMessage({
                            id: "error.unauthorized.message",
                          })
                        : errorDetail,
                    });
                  }
                }
              );
            }}
          />
          <Divider type="vertical" />
          <NavLink
            to={!hasLicenceConnexion ? false : {
              pathname: `/${serial}/histoDefault`,
              state: { nameDevice: machineName },
            }}
          >
            <Popover
              content={!hasLicenceConnexion ?
              <FormattedMessage id="table.devices.no.login.license" />:
                <FormattedMessage
                  id="cycles.defaults"
                  values={{ machineName }}
                />
              }
            >
              <Button disabled ={!hasLicenceConnexion}>
                <FontAwesomeIcon icon={faExclamationTriangle} />
              </Button>
            </Popover>
          </NavLink>
          <Divider type="vertical" />
          {/* Device cycle button */}
          {(hasLicenceConnexion || isSuperAdmin({ userRole })) && (
            <NavLink
              to={{
                pathname: `/${serial}/cycles`,
                state: { famille, nameDevice: machineName },
              }}
            >
              <Popover
                content={
                  !authorizeTransfert ? (
                    <FormattedMessage id="table.devices.impossible" />
                  ) : (
                    <FormattedMessage
                      id="cycles.title"
                      values={{ machineName }}
                    />
                  )
                }
              >
                <Button disabled={!authorizeTransfert}>
                  <Icon type="bars" />
                </Button>
              </Popover>
              <Divider type="vertical" />
            </NavLink>
          )}
        </span>
      ),
    },
  ];

  getActions = (clientID, eureka, rights, userRole) => [
    {
      key: "action",
      render: (
        _,
        {
          active,
          activationOnWaiting,
          client: { concessionaire, numeroClientEureka },
          deviceGuaranteeValidate,
          dateLastDataFeedBack,
          deviceRequestActivation,
          famille,
          hasLicenceConnexion,
          hasLicenceConnexionValide,
          historySaveEnabled,
          id,
          lastDeviceGuaranteeOnTreatment,
          name: machineName,
          preTransfer,
          serial,
          serialMachine,
          type,
          usedClientNumeroEureka,
          authorizeTransfert,
          ...props
        }
      ) => (
        <span>
          {/* Show device information button */}
          <NavLink
            to={{
              pathname: `/${serial}/infos`,
              state: {
                nameDevice: machineName,
                serialMachine,
                type,
                active,
                historySaveEnabled,
                id,
                dateLastDataFeedBack,
                deviceGuaranteeValidate,
              },
            }}
          >
            <Popover content={<FormattedMessage id="device.info" />}>
              <Button>
                <Icon type="info-circle" theme="filled" />
              </Button>
            </Popover>
          </NavLink>
          <Divider type="vertical" />

          {/* Change device name button */}
          <ChangeName
            {...props}
            serial={serial}
            refresh={() => this.refreshDevices()}
          />
          <Divider type="vertical" />

          {/* Machine handling button */}
          <Fragment>
            <MachineHandling grantAccess = {hasLicenceConnexion && hasLicenceConnexionValide ? true :false} idDevice={id} />
          </Fragment>
          <Divider type="vertical"/>
          
          {/* Change owner button */}
          {(isSuperAdmin({ userRole }) ||
            isGroupAdminClient({ userRole }) ||
            (isGroupConcessionnaire({ userRole }) &&
              clientID == concessionaire)) && (
            <Fragment>
              <GiveMachine
                {...props}
                authorizeTransfert={authorizeTransfert}
                serial={serial}
                refresh={() => this.refreshDevices()}
              />
              <Divider type="vertical" />
            </Fragment>
          )}

          {/* Disabled change owner button */}
          {!preTransfer &&
            !isSuperAdmin({ userRole }) &&
            !isGroupAdminClient({ userRole }) &&
            !(
              isGroupConcessionnaire({ userRole }) && clientID == concessionaire
            ) && (
              <Fragment>
                <Popover
                  content={
                    <FormattedMessage id="table.devices.customerSwitch.deactivate" />
                  }
                >
                  <Button disabled>
                    <Icon type="retweet" />
                  </Button>
                </Popover>
                <Divider type="vertical" />
              </Fragment>
            )}

          {/* Pre-transfer button */}
          {preTransfer &&
            (isSuperAdmin({ userRole }) ||
              isGroupBV({ userRole }) ||
              (isGroupConcessionnaire({ userRole }) &&
                clientID == concessionaire)) && (
              <Fragment>
                <Popover
                  content={
                    <FormattedMessage id="table.devices.preTransfer.confirm" />
                  }
                >
                  <Button
                    onClick={() =>
                      this.handleConfirmPreTransfer({ ...props }, serial)
                    }
                  >
                    <Icon type="swap" />
                  </Button>
                </Popover>
                <Divider type="vertical" />
              </Fragment>
            )}

          {deviceGuaranteeValidate.validateGuarantee
            ? /* Bouton permettant de modifer la la garantie */
              (isSuperAdmin({ userRole }) ||
                isGroupAdminClient({ userRole }) ||
                (isGroupConcessionnaire({ userRole }) &&
                  clientID == concessionaire)) && (
                <Fragment>
                  <ActivateGuarantee
                    {...props}
                    askActivate={isGroupConcessionnaire({ userRole })}
                    isUpdate={true}
                    isConnectedDevice={true}
                    lotGuaranteeId={deviceGuaranteeValidate.deviceGuaranteeId}
                    refresh={() => this.refreshDevices()}
                    serial={serial}
                    warranty={
                      !deviceGuaranteeValidate.guaranteeOnWaiting &&
                      !deviceGuaranteeValidate.validateGuarantee
                        ? true
                        : false
                    }
                    guaranteeIsValidate={true}
                    authorizeTransfert={authorizeTransfert}
                  />
                  <Divider type="vertical" />
                </Fragment>
              )
            : /* Bouton permettant de valider la garantie */
              (isSuperAdmin({ userRole }) ||
                isGroupAdminClient({ userRole }) ||
                (isGroupConcessionnaire({ userRole }) &&
                  clientID == concessionaire)) && (
                <Fragment>
                  <ActivateGuarantee
                    {...props}
                    askActivate={isGroupConcessionnaire({ userRole })}
                    isConnectedDevice={true}
                    lotGuaranteeId={deviceGuaranteeValidate.deviceGuaranteeId}
                    refresh={() => this.refreshDevices()}
                    serial={serial}
                    disable={lastDeviceGuaranteeOnTreatment}
                    warranty={
                      !deviceGuaranteeValidate.guaranteeOnWaiting &&
                      !deviceGuaranteeValidate.validateGuarantee
                        ? true
                        : false
                    }
                    guaranteeIsValidate={
                      isGroupConcessionnaire({ userRole }) &&
                      !isSuperAdmin({ userRole })
                        ? false
                        : true
                    }
                    authorizeTransfert={authorizeTransfert}
                  />
                  <Divider type="vertical" />
                </Fragment>
              )}

          {/* Activate device button */}
          {/* Visible if device isn't active and if there aren't any pending activation request */}
          {!active &&
            !deviceRequestActivation.requestOnWaiting &&
            !activationOnWaiting && (
              <Fragment>
                <ActivateDevice
                  askActivate={isGroupConcessionnaire({ userRole })}
                  clientID={clientID}
                  isNumeroEureka={usedClientNumeroEureka ? true : false}
                  name={machineName}
                  ownerId={numeroClientEureka}
                  refresh={() => this.refreshDevices()}
                  serial={serial}
                  warranty={
                    !deviceGuaranteeValidate.guaranteeOnWaiting &&
                    !deviceGuaranteeValidate.validateGuarantee
                      ? true
                      : false
                  }
                  authorizeTransfert={authorizeTransfert}
                />
                <Divider type="vertical" />
              </Fragment>
            )}

          {/* Button used to generate licence on device ... */}
          {active && !isGroupConcessionnaire({ userRole }) && (
            <Fragment>
              <ModalGenerateForDealer
                serial={serial}
                eureka={eureka}
                family={famille}
                canGenerate={
                  canAccessRight({
                    rights,
                    nameFeature: FEATURE_LICENSE_GENERATE,
                    objectToCompare: numeroClientEureka,
                    objectCompared: eureka | clientID,
                  }) &&
                  canAccessRight({
                    rights,
                    nameFeature: FEATURE_LICENSE_ASSIGN,
                  })
                }
              />
              <Divider type="vertical" />
            </Fragment>
          )}
        </span>
      ),
    },
  ];

  onFilter = (value, obj) => {
    if (obj === true || obj === false) {
      return obj == value;
    }
    return obj && obj.toLowerCase().includes(value.toLowerCase());
  };

  onFilterDropdownVisibleChange = (visible) => {
    if (visible) {
      setTimeout(() => {
        this.searchInput.focus();
      });
    }
  };

  handleSearch = (selectedKeys, confirm) => () => {
    confirm();
  };

  handleReset = (clearFilters) => () => {
    clearFilters();
  };

  filterDropDown = ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div className="custom-filter-dropdown">
      <Input
        /* eslint-disable-next-line */
        ref={(ele) => (this.searchInput = ele)}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={this.handleSearch(selectedKeys, confirm)}
      />
      <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
        Search
      </Button>
      <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
    </div>
  );

  // Manage action when preTransfer button is pressed
  handleConfirmPreTransfer = (props, serial) => {
    const { intl } = this.props;

    updateDevice({
      ...props,
      serial,
      preTransfer: false,
    })
      .then(({ isSuccess, isUnauthorized, errorDetail }) => {
        if (isSuccess) {
          notification.success({
            message: intl.formatMessage({
              id: "table.devices.preTransfer.success",
            }),
          });
          this.refreshDevices();
        } else {
          notification.error({
            message: isUnauthorized
              ? intl.formatMessage({ id: "error.unauthorized.title" })
              : intl.formatMessage({ id: "common.error" }),
            description: isUnauthorized
              ? intl.formatMessage({ id: "error.unauthorized.message" })
              : errorDetail,
          });
        }
      })
      .catch(() => {
        notification.error({
          message: intl.formatMessage({
            id: "table.devices.preTransfer.error",
          }),
        });
      });
  };

  render() {
    const {
      match: {
        params: { eureka },
      },
      clientID,
      userRole,
      history,
      rights,
      progressIconsTemplateList,
    } = this.props;
    const { canAccess, devices, loading, reloadData, pageSize } = this.state;

    // Display only header when
    // - data are loading
    // - device length is null (no to be applied when user click on refresh button)
    if (!loading && reloadData) {
      return (
        <PageLayout
          pageKey={isNil(eureka) ? "myConnectedDevices" : "customers"}
          history={history}
        >
          <h2>
            {eureka ? (
              <FormattedMessage id={`table.${tableName}.listTitle`} />
            ) : (
              <FormattedMessage id={`table.${tableName}.title`} />
            )}
          </h2>
          <Spin size="small" />
        </PageLayout>
      );
    }

    if (!canAccess) {
      return (
        <PageLayout
          pageKey={isNil(eureka) ? "myConnectedDevices" : "customers"}
          history={history}
        >
          <h2>
            {eureka ? (
              <FormattedMessage id={`table.${tableName}.listTitle`} />
            ) : (
              <FormattedMessage id={`table.${tableName}.title`} />
            )}
          </h2>
          <h3 style={{ color: "red" }}>
            {<FormattedMessage id="error.unauthorized.title" />}
          </h3>
        </PageLayout>
      );
    }

    const licenses = this.getLicences();
    const etats = this.getEtats(progressIconsTemplateList);
    const historics = this.getHistorics(userRole);
    const actions = this.getActions(clientID, eureka, rights, userRole);
    const columns = [
      ...this.getColumns(),
      ...licenses,
      ...etats,
      ...historics,
      ...actions,
    ];

    const translatedColumns = columns.map(({ key, ...others }) => ({
      ...others,
      key,
      title: <FormattedMessage id={`table.${tableName}.${key}`} />,
    }));

    return (
      <PageLayout
        pageKey={isNil(eureka) ? "myConnectedDevices" : "customers"}
        history={history}
      >
        <h2>
          {eureka ? (
            <FormattedMessage id={`table.${tableName}.listTitle`} />
          ) : (
            <FormattedMessage id={`table.${tableName}.title`} />
          )}
        </h2>

        <button
          className="ant-btn ant-btn-primary"
          style={{ marginBottom: "0.5em" }}
          onClick={() => this.refreshDevices()}
        >
          <FontAwesomeIcon icon={faSyncAlt} />
        </button>

        <Table
          className="custom-table"
          dataSource={devices}
          columns={translatedColumns}
          loading={!loading || !reloadData}
          rowKey="id"
          tableName={tableName}
          onChange={this.onPageChange}
          pagination={{ pageSize: pageSize }}
        />
      </PageLayout>
    );
  }
}

ConnectedDevices.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      eureka: PropTypes.string,
    }).isRequired,
  }).isRequired,
  clientID: PropTypes.string.isRequired,
  userRole: PropTypes.array,
  intl: intlShape.isRequired,
  rights: PropTypes.array,
};

const mapStateToProps = ({
  signIn: { clientID, userID, rights, userRole, progressIconsTemplateList },
  app: { locale },
}) => ({
  clientID,
  userID,
  rights,
  userRole,
  progressIconsTemplateList,
  locale,
});

export default injectIntl(connect(mapStateToProps)(ConnectedDevices));
