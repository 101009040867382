import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { Input, InputNumber, Form } from 'antd';
import { REGEX_ORDER_REF } from '../utils/constants';

const FormItem = Form.Item;
export const EditableContext = React.createContext();

const EditableRow = ({ form, index, ...props }) => (
    <EditableContext.Provider value={form}>
        <tr {...props} />
    </EditableContext.Provider>
);

export const EditableFormRow = Form.create()(EditableRow);

export class EditableCell extends Component {
    getInput() {
        const { inputType } = this.props;
        if ('number' === inputType) {
            return <InputNumber />;
        }

        return <Input />;
    }

    render() {
        const {
            editing,
            dataIndex,
            title,
            inputType,
            record,
            index,
            errorMessage,
            errorDetail,
            ...restProps
        } = this.props;

        return (
            <EditableContext.Consumer>
                {(form) => {
                    const { getFieldDecorator } = form;

                    return (
                        <td {...restProps}>
                            {editing ? (
                                <FormItem style={{ margin: 0 }}>
                                    {getFieldDecorator(dataIndex, inputType != 'string' ? { 
                                        rules: [{
                                            required: true,
                                            message: (errorDetail),
                                        }],
                                        initialValue: record[dataIndex],
                                    } : { 
                                        rules: [
                                            { max: 70 },
                                            {
                                            pattern: new RegExp(REGEX_ORDER_REF), 
                                            message: (<FormattedMessage
                                                id="form.validator.invalidOrderRef"
                                                defaultMessage="Only numbers, letters from a to z as well as the - and space are allowed"
                                            />)}
                                        ],
                                        initialValue: record[dataIndex],
                                    }
                                    )(this.getInput())}
                                </FormItem>
                            ) : restProps.children}
                        </td>
                    );
                }}
            </EditableContext.Consumer>
        );
    }
}