import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PageLayout from '../Layout/PageLayout';
import { Button, Divider, Icon, Input, notification, Popover, Spin, Table, Switch } from 'antd';
import { alphabetically, compareNumber, isSuperAdmin, isGroupBV, isGroupConcessionnaire, isInfoCompleted } from '../../utils/helpers';
import { faExclamationTriangle, faExclamationCircle, faInfoCircle, faUndo, faPowerOff, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fetchAllDevices, fetchMachineData, updateDevice } from '../../utils/apiBucherVaslin';
import ProgressIconList from '../Templates/ProgressIconList';
import _ from 'lodash';
import { multiPropsFilter, sorterArrayObject } from '../../utils/helperArray';
import GiveMachine from '../GiveMachine';
import RestartMachine from '../RestartMachine';
import MachineHandling from '../MachineHandling';

const tableName = 'listingParc';

class ListForMaintainer extends Component {
    constructor(props){
        super(props);
        this.state = {
            loadData: false,
            reloadData: true,
            devices: [],
            canAccess: false,
            filters: {},
            sorter: {},
            showAllDevices: false,
            showPretransfer: false,
            pageSize: 10,
            pageCurrent: 1,
        };
    }
    
    async componentDidMount() {
        /* check connexion and information */ 
        const { userID, intl } = this.props;
        await isInfoCompleted(intl, userID);
        this.fetchDataAsync();
    }

    // Récupération des données
    async fetchDataAsync() {
        this.setState({ loadData: false, canAccess: false });
        const { intl } = this.props;
        const { showAllDevices, pageSize, pageCurrent } = this.state;
        let deviceIds = [];
        let displayedDevices = [];

        // On récupère tous les devices des clients du concessionnaire ou du RR (voir les deux si l'utilisateur 
        // a les deux profils)
        let { isSuccess, items: devices, isUnauthorized, errorMessage, errorDetail } = await fetchAllDevices(showAllDevices);
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            this.setState({ canAccess: false, loadData: true });
            return;
        }
        // On filtre sur les devices ayant uniquement un client
        devices = devices && devices.filter(device => device.client.nomClient != null);
        // On ajoute l'objet data qui servira à contenir les data de la machine
        if (devices.length > 0){
            devices = devices.map((device, index) => {   
                // device.hasLicenceConnexion ? console.log(device) : null;          
                if (index < pageSize){
                    deviceIds.push({ id: device.id });
                    displayedDevices.push(device);
                }
                return Object.assign({}, device, {metrics: {}, metricsLoaded: false});
            });
        }
        this.setState({ devices, canAccess: true, loadData: true }, async () => {
            await this.updateMetricsOfDevices(pageSize, pageCurrent, deviceIds);
        });

    };

    onFilter = (value, obj) => {
        if (obj === true || obj === false) {
            return obj == value;
        }
        return obj && obj.toLowerCase().includes(value.toLowerCase()); 
    };

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    getColumns() {
        return [{
            dataIndex: 'client.nomClient',
            key: 'client.nomClient',
            sorter: (a, b) => alphabetically(a.client.nomClient, b.client.nomClient),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.client.nomClient),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => alphabetically(a.name, b.name),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.name),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'serialMachine',
            key: 'serialMachine',
            sorter: (a, b) => alphabetically(a.serialMachine, b.serialMachine),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.serialMachine),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'serial',
            key: 'serial',
            sorter: (a, b) => alphabetically(a.serial, b.serial),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.serial),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => alphabetically(a.type, b.type),
            filterDropdown: this.filterDropDown,
            onFilter: (value, record) => this.onFilter(value, record.type),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },{
            dataIndex: 'active',
            key: 'active',
            render: (_, {active}) => {
                return active ? (<FontAwesomeIcon icon={faPowerOff} style={{ color: 'yellowgreen'}} />) : 
                    (<FontAwesomeIcon icon={faPowerOff} style={{ color: 'gray'}} />);
            },
            sorter: (a, b) => compareNumber(a.active, b.active),
            filterDropdown: this.filterSwitchDropDown,
            onFilter: (value, record) => this.onFilter(value, record.active),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        }];
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterSwitchDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <div className="alert-switches">
                <Switch
                    /* eslint-disable-next-line */
                    ref={(ele) => (this.searchInput = ele)}
                    defaultChecked={selectedKeys[0]}
                    onChange={(value) => setSelectedKeys([value])}
                    className="alert-switch"
                />
            </div>
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    // Appelé au changement de page de la table des devices
    onPageChange = async (pagination, filters, sorter) => {
        const { devices, pageSize, showPretransfer } = this.state;
        let devicesWork = Object.assign([], devices);
        let deviceIds = [];

        if (filters && sorter) {
            this.setState({filters, sorter});
        }

        let filterObject = this.getFilterObject(filters);
        let sorterObject = this.getSorterObject(sorter);
        
        if (filterObject.isFilter){
            devicesWork = multiPropsFilter(devices, filters);
        }
        if (sorterObject.isSorter){
            devicesWork = Object.assign([], sorterArrayObject(devicesWork, sorter.field, sorter.order === 'ascend'));
        }
        if (devicesWork && showPretransfer) {
            // On filtre sur les machines ayant un pré transfert en cours
            devicesWork = devicesWork.filter(device => device.preTransfer);
        }

        // Récupère la liste des devices visible
        let visibleDevices = [];
        devicesWork.forEach((device, index) => {
            if (index >= (pagination.current - 1) * pageSize && index < pagination.current * pageSize) {
                visibleDevices.push(device);
            }
        });
        
        devicesWork && devicesWork.map((device, index) => {
            if (!device.metricsLoaded && ((index + 1) - (pagination.pageSize * pagination.current)) < 1 && ((index + 1) > (pagination.pageSize * (pagination.current - 1)))){
                deviceIds.push({ id: device.id });
            }
        });

        await this.updateMetricsOfDevices(pagination.pageSize, pagination.current, deviceIds);
    }
    
    getFilterObject = (filters) => {
        let isFilter = false;
        let searchValues = [];
        if (Object.keys(filters).length <= 0){
            return { isFilter: isFilter, searchValues: searchValues };
        }
        else {
            Object.keys(filters).map((key) => {
                if (filters[key].length > 0){
                    isFilter = true;
                    searchValues.push({ columnName: key, columnSearchValue: filters[key][0] });
                }
            });
        }
        return { isFilter: isFilter, searchValues: searchValues };
    }

    getSorterObject = (sorter) => {
        let isSorter = false;
        let order = null;
        let columnName = null;
        if (Object.keys(sorter).length <= 0){
            return { isSorter: isSorter, order: order, columnName: columnName };
        }
        else {
            if (sorter['column'] !== undefined){
                isSorter = true;
                order = sorter['order'];
                columnName = sorter['field'];
            }
        }
        return { isSorter: isSorter, order: order, columnName: columnName };
    }

    // Met à jour les metrics des devices affichés
    updateMetricsOfDevices = async (pageSize, currentPage, deviceIds = []) => {
        const { locale: lang } = this.props;
        const { devices } = this.state;

        Promise.all(devices && devices.map(async (device, index) => {
            // On ne récupère les metrics que des devices étant dans la liste des id reçu
            if (!device.metricsLoaded && deviceIds.some((dev) => dev.id == device.id)){
                const result = await fetchMachineData(device.serial, lang);
                device.metrics = result;
                device.metricsLoaded = true;
            }
            return {
                ...device
            }
        })).then(devicesResponse => {
            // Ignore devicesResponse if devices array has changed
            if(devices.length !== this.state.devices.length) {
                this.setState({ pageSize, pageCurrent: currentPage });
            } else {
                this.setState({ devices: devicesResponse, pageSize, pageCurrent: currentPage });
            }
        });
    }

    // Raffraichis les données de la liste des machines
    refreshDevices = async () => {       
        const { intl } = this.props;
        const { pageSize, pageCurrent, filters, sorter, showAllDevices, showPretransfer } = this.state;
        const pagination = { current: pageCurrent, pageSize}

        // Passe le chargement à en cours
        this.setState({ reloadData: false});

        // On récupère les devices liés à l'utilisateur
        let { isSuccess, items: devices, isUnauthorized, errorMessage, errorDetail } = await fetchAllDevices(showAllDevices);
        
        // On filtre sur les devices ayant uniquement un client
        devices = devices && devices.filter(device => device.client.nomClient != null);

        // On filtre sur les machines ayant un pré transfert en cours
        if (devices && showPretransfer) {
            devices = devices.filter(device => device.preTransfer);
        }

        // Gestion d'une erreur de récupération des données
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            this.setState({ canAccess: false, reloadData: true });
            return;
        }

        // En cas de succès, met à jour le state
        this.setState({ devices });
        await this.onPageChange(pagination, filters, sorter);
        this.setState({ canAccess: true, reloadData: true});
    }

    // Affiche le nombre de licences et le nombre de licences expirées
    getLicences = () => [
        {
            title: <FormattedMessage id={`table.${tableName}.licences`} />,
            key: 'licences',
            render: (_, { numberLicenses, numberLicensesExpired }) => (
                <div style={{textAlign: 'center'}}>
                    { numberLicenses }
                    
                    <Popover content={
                        <span>
                            <FormattedMessage id="table.devicesLots.validLicence" values={{ numberLicenses }} />
                            
                            { (numberLicensesExpired && numberLicensesExpired > 0) ? 
                                <span>
                                    <br />
                                    <FormattedMessage id="table.devicesLots.expiredLicence" values={{ numberLicensesExpired }} />
                                </span>
                            : ''}
                        </span>
                    }>
                        <FontAwesomeIcon icon={ (numberLicensesExpired === 0) ? faInfoCircle : faExclamationCircle} style={{
                            marginLeft: '0.5em', 
                            fontSize: '12px',
                            color: (numberLicensesExpired > 0) ? '#DC0023' : 'yellowgreen'
                        }} />
                    </Popover>
                </div>
            ),
        }
    ];

    // Affiche la colonne Etat de la table pour chacune des lignes affichées
    getEtats = (progressIcons) => [
        {
            key: 'etats',
            render: (_, { serial, metrics, metricsLoaded, type, hasLicenceConnexionValide, ...props }) => {

                if (!metricsLoaded){
                    return <Spin size="small" />;
                }
                
                return (
                    <span>
                        <ProgressIconList progressIcons={progressIcons} metrics={metrics} deviceType={type} grantAccess = {hasLicenceConnexionValide} />
                    </span>
                );
            }
        }
    ];

    getHistorics = () => [
        {
            key: 'historics',
            render: (_, { serial, name, preTransfer, hasLicenceConnexionValide, ...props }) => (
                (!preTransfer) && (
                    <span>
                        <NavLink to={!hasLicenceConnexionValide ? false : { pathname:`/${serial}/histoDefault`, state: { nameDevice: name } } } >
                            <Popover content={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:<FormattedMessage id="cycles.defaults" values={{ machineName: name }} />}>
                                <Button disabled = {!hasLicenceConnexionValide}>
                                    <FontAwesomeIcon icon={faExclamationTriangle} />
                                </Button>
                            </Popover>
                        </NavLink>
                        <Divider type="vertical" />
                        <NavLink to={!hasLicenceConnexionValide ? false : { pathname:`/${serial}/histoProcess`, state: { nameDevice: name } } } >
                            <Popover content={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:<FormattedMessage id="cycles.process" values={{ machineName: name }} />}>
                                <Button disabled = {!hasLicenceConnexionValide}>
                                    <Icon type="history" theme="outlined" />
                                </Button>
                            </Popover>
                        </NavLink>
                    </span>
                )
            ),
        }
    ];

    getActions = (clientID, userRole) => [
        {
            key: 'actions',
            render: (_, { active, client, dateLastDataFeedBack, historySaveEnabled, id, name, preTransfer, serial, serialMachine, type, hasLicenceConnexionValide, ...props }) => (
                <span>
                    { (preTransfer) ? (
                            // Action available when preTransfer === true                                 
                            (isSuperAdmin({ userRole }) || isGroupBV({ userRole }) 
                            || (isGroupConcessionnaire({ userRole }) && clientID == client.concessionaire)) && (
                                <Fragment>
                                    {/* Change owner button */}
                                    <Fragment>
                                        <GiveMachine {...props} serial={serial} hasLicenceConnexionValide={hasLicenceConnexionValide} refresh={() => this.refreshDevices()} />
                                        <Divider type="vertical" />
                                    </Fragment>
                                    
                                    {/* Pre-transfer button */}
                                    <Fragment>
                                        <Popover content={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:<FormattedMessage id="table.devices.preTransfer.confirm" />}>
                                            <Button disabled = {!hasLicenceConnexionValide} onClick={() => this.handleConfirmPreTransfer({...props}, serial)}>
                                                <Icon type="swap" />
                                            </Button>
                                        </Popover>
                                        <Divider type="vertical" />
                                    </Fragment>
                                </Fragment>
                            )
                        ) : (
                            <Fragment>
                                {/* Action available when preTransfer === false */}
                                {/* Device info button */}
                                <NavLink to={!hasLicenceConnexionValide ? false : { pathname:`/${serial}/infos`, state: { nameDevice: name, serialMachine, type, active, historySaveEnabled, id, dateLastDataFeedBack } } }>
                                    <Popover content={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:<FormattedMessage id="device.info" />}>
                                        <Button disabled = {!hasLicenceConnexionValide}>
                                            <Icon type="info-circle" theme="filled" />
                                        </Button>
                                    </Popover>
                                </NavLink>
                                <Divider type="vertical" />

                                {/* Restart machine button */}
                                <Fragment>
                                    <RestartMachine active={active} serial={serial} hasLicenceConnexionValide={hasLicenceConnexionValide} refresh={() => this.refreshDevices()} />
                                    <Divider type="vertical" />
                                </Fragment>

                                {/* Machine handling button */}
                                <Fragment>
                                    {/* TODO : vérifier comportement hasLicenceConnexion vs hasLicenceConnexionValide */}
                                    {/* <MachineHandling hasLicenceConnexion={hasLicenceConnexion} hasLicenceConnexionValide ={hasLicenceConnexionValide} idDevice={id} /> */}
                                    <MachineHandling  grantAccess ={hasLicenceConnexionValide} idDevice={id} />
                                </Fragment>
                                
                                <Divider type="vertical" />
                                {/* Bouton non gérré */}
                                <Popover content={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:<FormattedMessage id="appli.upcoming" />}>
                                    <Button disabled>
                                        <Icon type="cloud-download" theme="outlined" />
                                    </Button>
                                </Popover>
                                <Divider type="vertical" />

                                {/* Bouton non gérré */}
                                <Popover content={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:<FormattedMessage id="appli.upcoming" />}>
                                    <Button disabled>
                                        <Icon type="sync" theme="outlined" />
                                    </Button>
                                </Popover>
                                <Divider type="vertical" />

                                {/* Bouton non gérré */}
                                <Popover content={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:<FormattedMessage id="appli.upcoming" />}>
                                    <Button disabled>
                                        <Icon type="save" theme="filled" />
                                    </Button>
                                </Popover>
                                <Divider type="vertical" />

                                {/* Bouton non gérré */}
                                <Popover content={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:<FormattedMessage id="appli.upcoming" />}>
                                    <Button disabled>
                                        <Icon type="cloud-sync" theme="outlined" />
                                    </Button>
                                </Popover>
                                <Divider type="vertical" />

                                {/* Bouton non gérré */}
                                <Popover content={!hasLicenceConnexionValide ?<FormattedMessage id="table.devices.no.login.license" />:<FormattedMessage id="appli.upcoming" />}>
                                    <Button disabled>
                                        <Icon type="folder-open" theme="filled" />
                                    </Button>
                                </Popover>
                            </Fragment>
                        ) }
                </span>
            )
        }
    ];

    // Manage action when preTransfer button is pressed
    handleConfirmPreTransfer = (props, serial) => {
        const { intl } = this.props;

        updateDevice({
            ...props,
            serial,
            preTransfer: false
        }).then(({ isSuccess, isUnauthorized, errorDetail }) => {
            if (isSuccess) {
                notification.success({
                    message: intl.formatMessage({id: 'table.devices.preTransfer.success'})
                })
                this.refreshDevices();
            } else {
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail
                    });
            }
        }).catch(() => {
            notification.error({
                message: intl.formatMessage({id: 'table.devices.preTransfer.error'})
            })
        });
    }

    render () {
        const { canAccess, devices, loadData, reloadData, pageSize, showAllDevices, showPretransfer } = this.state;
        const { history, progressIconsTemplateList, userRole, clientID } = this.props;

        // Display only header when 
        // - data are loadData
        // - device length is null (no to be applied when user click on refresh button)
        if (!loadData && reloadData) {
            return (
                <PageLayout pageKey="devicesOfMyCustomers" history={history}>
                    <h2>{<FormattedMessage id="device.listingParc.title" />}</h2>
                    <Spin size="small" />
                </PageLayout>
            );
        }

        if (!canAccess){
            return (
                <PageLayout pageKey="devicesOfMyCustomers" history={history}>
                    <h2>{<FormattedMessage id="device.listingParc.title" />}</h2>
                    <h3 style={{color: 'red'}}>{<FormattedMessage id="error.unauthorized.title" />}</h3>
                </PageLayout>
            );
        }

        const licenses = this.getLicences();
        const etats = this.getEtats(progressIconsTemplateList);
        const historics = this.getHistorics();
        const actions = this.getActions(clientID, userRole);
        const columns = [...this.getColumns(), ...licenses, ...etats, ...historics, ...actions];

        const translatedColumns = columns.map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.${tableName}.${key}`} />,
        }));

        return (
            <PageLayout pageKey="devicesOfMyCustomers" history={history}>
                <h2>{<FormattedMessage id="device.listingParc.title" />}</h2>

                <div className="action-header">
                    <button className="ant-btn ant-btn-primary"
                            style={{marginBottom: '0.5em'}}
                            onClick={() => this.refreshDevices()}
                    >
                        <FontAwesomeIcon icon={faSyncAlt} />
                    </button>

                    {(isSuperAdmin({userRole}) || isGroupBV({userRole})) && (
                        <Fragment>
                            <Switch
                                style={{margin: "0 8px 0 2em"}}
                                defaultChecked={showAllDevices}
                                onChange= {(e) => {this.setState({ showAllDevices: e}, () => { this.refreshDevices(); });}}
                                disabled={!loadData || !reloadData}
                            />
                            <FormattedMessage
                                id="licenses.switchDevices.label"
                                defaultMessage="Show all devices"
                            />
                        </Fragment>
                    )}

                    {(isSuperAdmin({ userRole }) || isGroupBV({ userRole }) 
                    || (isGroupConcessionnaire({ userRole }))) && (
                        <Fragment>
                            <Switch
                                style={{margin: "0 8px 0 2em"}}
                                checked={showPretransfer}
                                onChange= {(e) => {this.setState({ showPretransfer: e}, () => { this.refreshDevices(); });}}
                                disabled={!loadData || !reloadData}
                            />
                            <FormattedMessage
                                id="table.listingParc.preTransfer.switchLabel"
                                defaultMessage="Show all devices"
                            />
                        </Fragment>
                    )}
                </div>

                <Table
                    className="custom-table"
                    dataSource={devices}
                    columns={translatedColumns}
                    loading={!loadData || !reloadData}
                    rowKey="id"
                    tableName={tableName}
                    onChange={this.onPageChange}
                    pagination={{pageSize: pageSize}}
                />
            </PageLayout>
        );
    }
}

ListForMaintainer.propTypes = {
    clientID: PropTypes.string.isRequired,
    userRole: PropTypes.array,
    intl: intlShape.isRequired,
}

const mapStateToProps = ({ signIn: { clientID, userID, userRole, progressIconsTemplateList }, app: { locale } }) => ({
    clientID,
    userID,
    userRole,
    progressIconsTemplateList,
    locale,
});

export default injectIntl(connect(mapStateToProps)(ListForMaintainer));