import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './OrderStepper.less'

const { Step } = Steps;

class OrderStatusStepper extends Component {
    constructor(props) {
        super(props);
    } 

    getStatusIndex() {
        const { order, statusList } = this.props;
        const index = statusList.findIndex((status) => status.statut === order.statutCommand);
        return index;
    }

    render() {
        const { statusList } = this.props;
        const statusIndex = this.getStatusIndex();

        return (
            <Steps progressDot current={statusIndex} className="order-status-stepper">
                {statusList.map((status, index) => (
                    <Step key={"step_" + index} title={status.statutTraduit} />
                ))}
            </Steps>
        );
    }
}

OrderStatusStepper.propTypes = {
    order: PropTypes.object.isRequired,
    statusList: PropTypes.array.isRequired,
    intl: intlShape.isRequired,
};

export default injectIntl(OrderStatusStepper);