import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Form, Icon, Input, notification, Select, Spin, Alert } from 'antd';
import PhoneInput, { formatPhoneNumberIntl } from 'react-phone-number-input';
import { debounce } from 'lodash';
import { 
    fetchDynamicsCountries,
    fetchDynamicsCitiesFromCountry,
    fetchDynamicsZipFromCity
} from '../../utils/apiBucherVaslin';
import { formItemLayout, tailFormItemLayout } from '../../utils/formItemLayout';
import { REGEX_ONLY_CARACTERE_LATIN, REGEX_CSV_SEMICOLON } from '../../utils/constants';
import RequiredFieldLegend from '../RequiredFieldLegend';
import flags from 'react-phone-number-input/flags';

const { Item: FormItem } = Form;
const { Option } = Select;

class ModalClientAddress extends Component {
    state = {
        countryUpdate: null,
        cities: [],
        newCitySelected: null,
        cityUpdate: null,
        countries: [],
        isLoadingCity: false,
        isLoadingCountry: false,
        isLoadingZip: false,
        zipListInit: [],
        zipList: [],
        newZipCodeSelected: null,
        zipCodeUpdate: null,
        phone: "",
        phone2: ""
    };

    

    async componentDidMount() {
        await this.loadCountries();
    }

    loadCountries = async () => {
        const {intl} = this.props;
        await this.setState({isLoadingCountry: true});
        const { countries, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchDynamicsCountries();
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
        }
        await this.setState({countries, isLoadingCountry: false});
        // remplace le nom du pays par le guId
        //if(updatedAddress) {    
            // this.setState({cityUpdate: updatedAddress.city, countryUpdate: updatedAddress.country});
            // console.log(this.state);
            // const selectedCountry = countries.find((country) => country.nom === updatedAddress.country);
            // form.setFieldsValue({"guidCountry" : selectedCountry ? selectedCountry.idPays : null});
        //}
    }
    cityContainsSimicolon = (_, value, callback) => {
       const {intl} = this.props;
       const {newCitySelected} = this.state;
        if (newCitySelected != null && newCitySelected.includes(';')) {
            callback(intl.formatMessage({id: 'form.validator.invalidCaractereSemicolon'}));
        } else {
            callback();
        }
    }
    loadCities = async (value) => {
        const {form, intl} = this.props;
        const countryId = form.getFieldValue("guidCountry");
        // if (updatedAddress) {
        //     const country = countries.find((country) => country.nom === updatedAddress.country);
        //     countryId = country.idPays;
        // }
        // Load cities only if search field length > 2
        if(value && value.length > 2) {
            // Load cities corresponding to the selected country
            await this.setState({isLoadingCity: true});

            const { cities, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchDynamicsCitiesFromCountry(countryId, value, 300);
            if (!isSuccess){
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail
                });
            }
            const citiesToAdd = cities.concat({cityId : "NewCity" , countryId, name : value});
            await this.setState({cities: citiesToAdd, isLoadingCity: false, newCitySelected: value, cityUpdate: value});
        }
    }

    loadZip = async (cityId) => {
        const {intl} = this.props;
        await this.setState({isLoadingZip: true});
        
        const { zipCodes, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchDynamicsZipFromCity(cityId);
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
        }
        await this.setState({zipListInit: zipCodes, zipList: zipCodes, isLoadingZip: false});
    }

    loadNewZip = async (value) => {
        const {zipListInit} = this.state; 
        const zipToAdd = zipListInit.concat({postalCodeId : "NewZipCode" , name : value}) ;
        await this.setState({zipList: zipToAdd, newZipCodeSelected: value});
    }

    onChangeCountry = async (value) => {
        // Update country value
        await this.setCountry(value);
    }

    onChangeCity = async (value) => {
        const {cities} = this.state;
        const city = cities.filter((city) => city.cityId == value);
        const cityToAdd = city[0].name;
        this.setState({
            newCitySelected : cityToAdd,
            cityUpdate: cityToAdd
        });
        // Update city value
        await this.setCity(value);

        if(value != "" && value != "NewCity") {
        // Load Zip List
        await this.loadZip(value);
        }
    }

    setCountry = async (value) => {
        const { form } = this.props;
        const {countries} = this.state; 
        form.setFieldsValue({"guidCountry" : value});
        form.setFieldsValue({"guidCity": null});
        form.setFieldsValue({"guidPostalCode": null});
        const selectedCountry = countries.find((country) => country.idPays == value);
        const countryToAdd = selectedCountry.nom;
   
        this.setState({
            cities: [],
            zipList: [],
            countryUpdate: countryToAdd
        })
    }     
    setCity = async() => {
        const { form } = this.props;
        form.setFieldsValue({"guidPostalCode": null});
        this.setState({
            zipList: []
        })
    }

    onSelectCity = async(value) => {
        const {cities} = this.state; 
        const city = cities.filter((city) => city.cityId == value);
        const cityToAdd = city[0].name;
        this.setState({
            cityUpdate: cityToAdd
        })
    }

    onChangeZipCode = async(value) => {
        const {zipList} = this.state; 
        const zip = zipList.filter((zip) => zip.postalCodeId == value);
        const zipToAdd = zip[0].name;
        this.setState({
            zipCodeUpdate: zipToAdd,
            newZipCodeSelected : zipToAdd
        })
    }
    render() {
        // Props variables
        const {
            addressTypeCode,
            form: { getFieldDecorator, getFieldValue },
            intl,
            updatedAddress,
            billingAddress,
            showCreationSpinner
        } = this.props;

        // State variables
        const { 
            cities, 
            countryUpdate,
            countries, 
            isLoadingCountry,
            isLoadingCity,
            isLoadingZip,
            zipList,
            newCitySelected,
            newZipCodeSelected,
            phone,
            phone2
        } = this.state;
        //Form variable
        const cityId = getFieldValue("guidCity");
        const postalCodeId = getFieldValue("guidPostalCode");
        return (
            
            <Form>

                {/* Alert Principal adresse changes termes */}
                {(addressTypeCode === 3) && <Alert
                    banner
                    message={<FormattedMessage
                                id="client.addressModal.alert.principal.address.changes.termes"
                                defaultMessage="Contact Bucher Vaslin for address modifications"
                                />
                            }
                />}

                {/* Address name field */}
                {(addressTypeCode === 4) && (
                    <FormItem
                        {...formItemLayout}
                        label={(
                            <FormattedMessage
                                id="client.addressModal.addressName"
                                defaultMessage="Address name"
                            />
                        )}
                    >
                        {getFieldDecorator(`addressName`, {
                            rules: [{
                                required: true,
                                message: (
                                    <FormattedMessage
                                        id="client.addressModal.addressNameRequired"
                                        defaultMessage="Please enter an address name"
                                    />
                                )
                            },
                            {
                                pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                message: (<FormattedMessage
                                    id="form.validator.invalidCaracteres"
                                    defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                />)
                            },
                            {
                                pattern: new RegExp(REGEX_CSV_SEMICOLON),
                                message: (<FormattedMessage
                                    id="form.validator.invalidCaractereSemicolon"
                                    defaultMessage="Denied character: ';'"
                                />)
                            },
                            {
                                max: 150,
                                message: intl.formatMessage({
                                    id: 'validator.field.max'
                                }, { length: 150})
                            }],
                            initialValue: (updatedAddress) ? updatedAddress.name : null
                        })(
                            <Input />
                        )}
                    </FormItem>
                )}

                {/* Country field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.addressModal.country"
                            defaultMessage="Country"
                        />
                    )}
                >
                    {getFieldDecorator(`guidCountry`, {
                        rules: [{
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="client.addressModal.countryRequired"
                                    defaultMessage="Please enter your company country"
                                />
                            )
                        },
                        {
                            pattern: new RegExp(REGEX_CSV_SEMICOLON),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaractereSemicolon"
                                defaultMessage="Denied character: ';'"
                            />)
                        }],
                          initialValue: (updatedAddress) ? updatedAddress.countryGuId : null
                    })(
                        <Select
                            loading={isLoadingCountry}
                            disabled ={(addressTypeCode === 3) ? true : false}
                            showSearch
                            notFoundContent={<Spin size="small" />}
                            optionFilterProp="children"
                            onChange={(value) => this.onChangeCountry(value)}
                        >
                            {countries.map(({ nom, idPays: code }) => (
                                <Option
                                    key={code}
                                    value={code}
                                >
                                    {nom}
                                </Option>
                            ))}
                        </Select>
                    )}
                </FormItem>
                {updatedAddress ?
                    getFieldDecorator(`country`, {
                        initialValue: null
                    })(
                        <Input hidden={true}/>
                    ) : '' }

                {/* City field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.addressModal.city"
                            defaultMessage="City"
                        />
                    )}
                >
                    {getFieldDecorator(`guidCity`, {   
                        validate: [
                            {
                                trigger: 'onBlur',
                                rules: [{
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="client.addressModal.cityRequired"
                                                defaultMessage="Please enter your company city"
                                            />
                                        )
                                    },{
                                        validator: this.cityContainsSimicolon
                                    }],
                            }],
                        initialValue: (updatedAddress) ? updatedAddress.city : null
                    })(
                        <Select
                            disabled={ (addressTypeCode === 3) ? true : ((updatedAddress) ? false : countryUpdate == null)}
                            loading={isLoadingCity}
                            notFoundContent={
                                <span style={{color:"#000000D9"}}>
                                    <Icon type="info-circle" style={{marginRight: "0.5em"}}/>
                                    <FormattedMessage
                                        id="client.addressModal.selectCity.filter"
                                        defaultMessage="Please enter at least 3 characters"
                                    />    
                                </span>  
                            }
                            onSearch={debounce(this.loadCities, 600)}
                            optionFilterProp="children"
                            onChange={(value) => this.onChangeCity(value)}
                            showSearch
                        >
                            {cities.map(({ name, cityId }) => (
                                <Option
                                    key={cityId}
                                    value={cityId}
                                >
                                    {name}
                                </Option>
                            ))}
                        </Select>
                    )}
                </FormItem>
                {cityId == "NewCity" || updatedAddress ?
                    getFieldDecorator(`city`, {
                        initialValue: (!updatedAddress || (newCitySelected != null && updatedAddress.city != newCitySelected)) ? newCitySelected : updatedAddress.city
                    })(
                        <Input hidden={true}/>
                    ) : '' }

                {/* ZIP (Postal Code) field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.addressModal.zipCode"
                            defaultMessage="Zip code"
                        />
                    )}
                >
                    {getFieldDecorator(`guidPostalCode`, {
                            validate: [
                                {
                                    trigger: 'onBlur',
                                    rules: [
                                        {
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="client.addressModal.zipCodeRequired"
                                                defaultMessage="Please enter your company zip code"
                                            />
                                            )
                                        },
                                        {
                                        validator: (rule, value, callback) => {                      
                                            var zipToAdd = "";
                                            const {intl} = this.props;
                                            const {newZipCodeSelected} = this.state;  
                                            if(updatedAddress) {
                                                if(updatedAddress.postalCode != value){
                                                    const zipUpdate = zipList.filter((zip) => zip.postalCodeId == value);
                                                    zipToAdd = zipUpdate[0].name;
                                                }
                                            } else {
                                                const zipAdd = zipList.filter((zip) => zip.postalCodeId == value);
                                                zipToAdd = zipAdd[0].name;
                                            }
                                            //console.log(zipToAdd.length);
                                            if (zipToAdd.length >= 12){
                                                return callback(intl.formatMessage({id: 'validator.field.max'}, { length: 12}));
                                            }  
                                            if (newZipCodeSelected.includes(';')) {
                                                callback(intl.formatMessage({id: 'form.validator.invalidCaractereSemicolon'}));
                                            }           
                                            return callback();                
                                        },
                                        validator : this.zipContainsSimicolon
                                    }]
                                }],
                        initialValue: (updatedAddress) ? updatedAddress.postalCode : null
                    })(
                        <Select
                            disabled={(addressTypeCode === 3) ? true : (!cityId || countries.length < 1)}
                            loading={isLoadingZip}
                            notFoundContent={cityId != "NewCity" ? <Spin size="small" /> : ''}
                            optionFilterProp="children"
                            onSearch={debounce(this.loadNewZip, 600)}
                            onChange={(value) => this.onChangeZipCode(value)}
                            onSelect={(value) => this.onChangeZipCode(value)}
                            showSearch
                        >
                            {zipList.map(({ name, postalCodeId }) => (
                                <Option
                                    key={postalCodeId}
                                    value={postalCodeId}
                                >
                                    {name}
                                </Option>
                            ))}
                        </Select>,
                    )}
                </FormItem>
                {postalCodeId == "NewZipCode"  || updatedAddress ?
                    getFieldDecorator(`postalCode`, { 
                        initialValue: (!updatedAddress || (newZipCodeSelected != null && updatedAddress.postalCode != newZipCodeSelected)) ? newZipCodeSelected : updatedAddress.postalCode 
                    })(
                        <Input hidden={true}/>
                    ) : ''}

                {/* Address field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.addressModal.address"
                            defaultMessage="Address"
                        />
                    )}
                >
                    {getFieldDecorator(`line1`, {
                        rules: [{
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="client.addressModal.addressRequired"
                                    defaultMessage="Please enter your company address"
                                />
                            )
                        },
                        {
                            pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaracteres"
                                defaultMessage="Characters are invalid. Only Latin characters are allowed"
                            />)
                        },
                        {
                            pattern: new RegExp(REGEX_CSV_SEMICOLON),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaractereSemicolon"
                                defaultMessage="Denied character: ';'"
                            />)
                        },
                        {
                            max: 32,
                            message: intl.formatMessage({
                                id: 'validator.field.max'
                            }, { length: 32})
                        }],
                        initialValue: (updatedAddress) ? updatedAddress.line1 : null,
                    })(
                        <Input disabled = {(addressTypeCode === 3) ? true : false}/>
                    )}
                </FormItem>

                {/* Additional Adress field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.addressModal.additionalAddress"
                            defaultMessage="Additional address"
                        />
                    )}
                >
                    {getFieldDecorator(`line2`, {
                        rules: [{
                            pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaracteres"
                                defaultMessage="Characters are invalid. Only Latin characters are allowed"
                            />)
                        },
                        {
                            pattern: new RegExp(REGEX_CSV_SEMICOLON),
                            message: (<FormattedMessage
                                id="form.validator.invalidCaractereSemicolon"
                                defaultMessage="Denied character: ';'"
                            />)
                        },
                        {
                            max: 32,
                            message: intl.formatMessage({
                                id: 'validator.field.max'
                            }, { length: 32})
                        }],
                        initialValue: (updatedAddress) ?  updatedAddress.line2 : null,
                    })(
                        <Input disabled = {(addressTypeCode === 3) ? true : false}/>
                    )}
                </FormItem>

                {/* Phone number 1 field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.addressModal.telephone1"
                            defaultMessage="Phone number 1"
                        />
                    )}
                >
                    {getFieldDecorator(`telephone1`, {
                        rules: [{
                            required: true,
                            message: (<FormattedMessage
                                id="client.addressModal.telephoneRequired"
                                defaultMessage="Please enter your phone number"
                            />)
                        }],
                        initialValue: (updatedAddress) ? updatedAddress.telephone1 : null
                    })(
                        <PhoneInput
                            international={true}
                            value={this.state.phone}
                            onChange={(phone) => {this.setState({phone: phone})}}
                            flags={flags}
                            limitMaxLength={true}
                        />
                    )}
                </FormItem>

                {/* Phone number 2 field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.addressModal.telephone2"
                            defaultMessage="Phone number 2"
                        />
                    )}
                >
                    {getFieldDecorator(`telephone2`, {
                        rules: [{
                            required: false,
                            message: (<FormattedMessage
                                id="client.addressModal.telephoneRequired"
                                defaultMessage="Please enter your phone number"
                            />)
                        }],
                        initialValue: (updatedAddress) ? updatedAddress.telephone2 : null
                    })(
                        <PhoneInput
                            value={this.state.phone2}
                            onChange={(phone2) => {this.setState({phone2: phone2})}}
                            flags={flags}
                            international={true}
                            limitMaxLength={true}
                        />
                    )}
                </FormItem>
                {/* Fax */}
                {(addressTypeCode === 1 || addressTypeCode === 3) && (
                    <Fragment>
                             <FormItem
                             {...formItemLayout}
                             label={(
                                 <FormattedMessage
                                     id="client.addressModal.fax"
                                     defaultMessage="Fax"
                                 />
                             )}
                         >
                             {getFieldDecorator(`fax`, { 
                                 initialValue: (updatedAddress) ? updatedAddress.fax : null
                             })(
                                <PhoneInput
                                    flags={flags}
                                    international={true}
                                    limitMaxLength={true}
                                />
                             )}
                        </FormItem>
                    {/* Email */}     
                        <FormItem
                            {...formItemLayout}
                            label={(
                                <FormattedMessage
                                    id="client.addressModal.email"
                                    defaultMessage="Email"
                                />
                            )}
                        >
                            {getFieldDecorator(`email`, {
                                   rules: [{
                                    required:  addressTypeCode === 3 ? true : false,
                                    message: (
                                        <FormattedMessage
                                            id="client.addressModal.emailRequired"
                                            defaultMessage="Please enter your email"
                                        />
                                    )
                                }],
                                initialValue: (updatedAddress) ? updatedAddress.email : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                        {/* Billing Email */}     
                        <FormItem
                            {...formItemLayout}
                            label={(
                                <FormattedMessage
                                    id="client.addressModal.emailBilling"
                                    defaultMessage="Billing Email"
                                />
                            )}
                        >
                            {getFieldDecorator(`email2`, {
                                   rules: [{
                                    required: true,
                                    message: (
                                        <FormattedMessage
                                            id="client.addressModal.emailBillingRequired"
                                            defaultMessage="Please enter your billing email"
                                        />
                                    )
                                }],
                                initialValue: (updatedAddress) ? updatedAddress.email2 : null
                            })(
                                <Input />
                            )}
                        </FormItem>
                    </Fragment>
                )}


                {/* Legend */}
                <FormItem {...tailFormItemLayout}>
                    <RequiredFieldLegend />
                </FormItem>

                {/* Creation Spinner */}
                {(showCreationSpinner) && (
                    <Spin size="small" />
                )}
            </Form>
        
        );
    }
}

ModalClientAddress.propTypes = {
    addressTypeCode: PropTypes.number,
    client: PropTypes.object.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    updatedAddress: PropTypes.object,
    showCreationSpinner: PropTypes.bool
};

export default injectIntl((ModalClientAddress));
