import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Form, Icon, Modal, notification, Card, Col, Row, Divider, Popover, Input } from 'antd';
import { createClientAddress, getAddressesByClientId, updateAddress} from '../../utils/apiBucherVaslin';
import ModalClientAddress from './ModalClientAddress';
import { connect } from 'react-redux';
import { isGroupAdminClient, isGroupEBV, isSuperAdmin, isInfoCompleted } from '../../utils/helpers';
import  DeleteAddress from './DeleteAddress';

const { Search } = Input;

class ClientAddress extends Component {
    state = {
        billingAddress: [],
        deleveryAddress: [],
        displayedAddress: null,
        displayedAddressType: null,
        isModalVisible: false,
        mainAddress: [],
        otherAddresses: [],
        otherAddressesFiltred: [],
        showCreationSpinner: false,
        visibleConfirmationModal: false,
        adresseToDelete: null
    };

    async componentDidMount() {
        /* check connexion and information */ 
        const { userID, intl } = this.props;
        await isInfoCompleted(intl, userID);
                
        await this.loadAddresses();
    }

    reloadAddress = async () => {
        // Load address
        await this.loadAddresses();
    }
    
    // Method load client addresses
    loadAddresses = async () => {
        const {client, intl} = this.props;
        // TODO => Remove comment after real client guid implementation : "d303338b-bc3f-e411-940c-005056a6393b"
        const { addresses, isSuccess, isUnauthorized, errorMessage, errorDetail } =  await getAddressesByClientId(client.dynamicsAccountId);
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            return
        }
        const billingAddress = addresses.filter((address) => address.addressTypeCode === 1);       
        const deleveryAddress = addresses.filter((address) => address.addressTypeCode === 2);       
        const mainAddress = addresses.filter((address) => address.addressTypeCode === 3);       
        const otherAddresses = addresses.filter((address) => address.addressTypeCode === 4);    
        // console.log(billingAddress);
        await this.setState({
            billingAddress,
            deleveryAddress,
            mainAddress,
            otherAddresses,
            otherAddressesFiltred : otherAddresses
        });
    }

    // Method used to open the address creation/modification modal
    openModal = async (addressTypeCode, updatedAddress) => {
        await this.setState({ 
            displayedAddress: updatedAddress, 
            displayedAddressType: addressTypeCode, 
            isModalVisible: true 
        });
    }
    onChange = (e) => {
        const {otherAddresses} = this.state;
        const addressesFiltred = otherAddresses.filter((address) => (
                        address.name.toString().toLowerCase().includes(e.target.value.toString().toLowerCase())));   
        this.setState({otherAddressesFiltred : addressesFiltred })
      }

    // Method used to close the address creation/modification modal
    closeModal =  async () => {
        const {form} = this.props;
        form.resetFields();
        await this.setState({ 
            displayedAddress: null, 
            displayedAddressType: null, 
            isModalVisible: false 
        });
    }

    showSubmitModal(index) {
        this.setState({ visibleConfirmationModal: true, adresseToDelete: index });
    }

    // Method called to save addresss creation/update
    saveAddress = async (e) => {
        e.preventDefault();
        const { client, form: { validateFieldsAndScroll }, intl} = this.props;
        const { displayedAddress, displayedAddressType } = this.state;  

        // Check form validity
        validateFieldsAndScroll( async(err, values) => {
            if (!err) {
                await this.setState({showCreationSpinner: true});
                if (displayedAddress) {      
                    // Bouchon à utiliser en guid pour test tant que les guid ne remontent pas en synchro => "d303338b-bc3f-e411-940c-005056a6393b" sinon client.dynamicsAccountId
                    const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await updateAddress(displayedAddress.customerAddressId, values, displayedAddressType, client.dynamicsAccountId);
                    
                    // Remove spinner
                    await this.setState({showCreationSpinner: false});
                    
                    if (!isSuccess){
                        notification.error({
                            message: isUnauthorized 
                                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                                : intl.formatMessage({ id: 'common.error' }),
                            description: isUnauthorized 
                                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                                : errorDetail,
                        });
                        return;
                    }
                } else {
                    // Trigger api call
                    // Bouchon à utiliser en guid pour test tant que les guid ne remontent pas en synchro => "d303338b-bc3f-e411-940c-005056a6393b" sinon client.dynamicsAccountId
                    const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await createClientAddress(values, displayedAddressType, client.dynamicsAccountId);
                    const { intl } = this.props;
                    
                    // Remove spinner
                    await this.setState({showCreationSpinner: false});
                    
                    if (!isSuccess){
                        notification.error({
                            message: isUnauthorized 
                                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                                : intl.formatMessage({ id: 'common.error' }),
                            description: isUnauthorized 
                                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                                : errorDetail,
                        });
                        return;
                    }
                }

                // Reset modal after 
                this.closeModal();

                // Reload adresses
                await this.componentDidMount();
            }
        });
    }
    
    renderAddress = (addresse) => {
        return (
        <div>
            <div>
                <label><FormattedMessage
                    id="client.addressModal.country"
                /> : </label>
                <span>{addresse.country}</span>
            </div> 
            <div>
                <label><FormattedMessage
                    id="client.addressModal.city"
                /> : </label>
                <span>{addresse.city}</span>
            </div>  
            <div>
                <label><FormattedMessage
                    id="client.addressModal.zipCode"
                /> : </label>
                <span>{addresse.postalCode}</span>
            </div>
            <div>
                <label><FormattedMessage
                    id="client.addressModal.address"
                /> : </label>
                <span>{addresse.line1}</span>
            </div> 
            <div>
                <label><FormattedMessage
                    id="client.addressModal.additionalAddress"
                /> : </label>
                <span>{addresse.line2}</span>
            </div> 
            <div>
                <label><FormattedMessage
                    id="client.addressModal.telephone1"
                /> : </label>
                <span>{addresse.telephone1}</span>
            </div>    
            <div>
                <label><FormattedMessage
                    id="client.addressModal.telephone2"
                /> : </label>
                <span>{addresse.telephone2}</span>
            </div> 
            {(addresse.addressTypeCode === 3) && (
                <div>
                    <div>
                         <label><FormattedMessage
                             id="client.addressModal.fax"
                         /> : </label>
                         <span>{addresse.fax}</span>
                    </div>    
                    <div>
                         <label><FormattedMessage
                             id="client.addressModal.email"
                         /> : </label>
                         <span>{addresse.email}</span>
                     </div> 
                     <div>
                            <label><FormattedMessage
                                id="client.addressModal.emailBilling"
                            /> : </label>
                            <span>{addresse.email2}</span>
                        </div> 
                </div>
            )}
        </div>
        )}
    render() {
        // Props variables
        const {
            client,
            form,
            intl,
            userRole
        } = this.props;

        // State variables
        const { 
            billingAddress,
            deleveryAddress,
            displayedAddress,
            displayedAddressType,
            isModalVisible,
            mainAddress,
            otherAddressesFiltred,
            showCreationSpinner
        } = this.state;
        
        return (
            <Fragment>        
                <Modal
                    title={displayedAddress ? <FormattedMessage id="client.addressModal.edit.title"/> :<FormattedMessage id="client.addressModal.create.title"/> } 
                    visible={isModalVisible}
                    onOk={this.saveAddress}
                    onCancel={this.closeModal}
                >
                    <ModalClientAddress  
                        addressTypeCode={displayedAddressType} 
                        client={client}
                        form={form}
                        updatedAddress={displayedAddress} 
                        billingAddress={billingAddress[0]}
                        showCreationSpinner={showCreationSpinner}
                    />
                </Modal>
                <span></span>

                <div className="site-card-wrapper">
                    <Row gutter={16}>
                        <Col span={8}>
                            <Card title={<span><FormattedMessage id="client.address.main" />{mainAddress.length === 0 ? 
                            <Popover content={<FormattedMessage id="client.addressModal.create.title" />}>
                                <Button  style={{float:'right'}} onClick={() => this.openModal(3)}>+
                                </Button> 
                            </Popover> :
                            <Popover content={<FormattedMessage id="client.addressModal.edit.title" />}>
                                <Button  disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))} style={{float:'right'}} onClick={() => this.openModal(3, mainAddress[0])}><Icon type="edit" />
                                </Button> 
                            </Popover> } 
                            </span>}>                         
                                {mainAddress.length > 0 ? this.renderAddress(mainAddress[0]) : <FormattedMessage id="client.address.noMain" />}
                            </Card>
                        </Col>
                        {/* <Col span={8}>
                            <Card title={<span><FormattedMessage id="client.address.billing" />{billingAddress.length === 0 ? 
                            <Popover content={<FormattedMessage id="client.addressModal.create.title" />}>
                                <Button style={{float:'right'}} onClick={() => this.openModal(1)}>+
                                </Button> 
                            </Popover> :
                            <Popover content={<FormattedMessage id="client.addressModal.edit.title" />}>
                                 <Button  disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))} style={{float:'right'}} onClick={() => this.openModal(1, billingAddress[0])}><Icon type="edit" />
                                 </Button> 
                             </Popover> }  </span>}>
                                {billingAddress.length > 0 ? this.renderAddress(billingAddress[0]) : <FormattedMessage id="client.address.noBilling" />}
                            </Card>
                        </Col> */}
                        <Col span={8}>
                            <Card title={<span><FormattedMessage id="client.address.delevery" />{deleveryAddress.length === 0 ? 
                            <Popover content={<FormattedMessage id="client.addressModal.create.title"/>}>
                                <Button style={{float:'right'}} onClick={() => this.openModal(2)}>+
                                </Button>
                            </Popover>  :                  
                            <Popover content={<FormattedMessage id="client.addressModal.edit.title" />}>
                                 <Button  disabled={!(isGroupAdminClient({userRole}) || isGroupEBV({userRole}) || isSuperAdmin({ userRole }))}  style={{float:'right'}} onClick={() => this.openModal(2, deleveryAddress[0])}><Icon type="edit" />
                                 </Button> 
                            </Popover> } 
                            </span>}>
                                {deleveryAddress.length > 0 ? this.renderAddress(deleveryAddress[0]) : <FormattedMessage id="client.address.noDelevery" />}
                            </Card>
                        </Col>
                    </Row>
                </div>
                <Divider style={{margin:"1em 0"}}><FormattedMessage id="client.address.other" /></Divider>
                <div>
                <Button onClick={() => this.openModal(4)}><FormattedMessage id="client.addressModal.create.title" /></Button>
                <Search style={{ width: '25%', marginLeft: '1em' }} placeholder={intl.formatMessage({id: 'client.address.filtredOtherAddresses'} )}onChange={this.onChange} />
                </div>
                <div className="site-card-wrapper">
                    <Row style={{alignItems:"stretch"}} gutter={16}>
                    {otherAddressesFiltred.map((addresse, index) => (
                        <Col span={8} key={index}>
                            <Card title={<span>{addresse.name? addresse.name :<FormattedMessage id="client.address.undefined" />}
                            <Popover content={<FormattedMessage id="client.addressModal.edit.title" />}>
                                 <Button disabled={!(isGroupAdminClient({userRole}) || isGroupEBV({userRole}) || isSuperAdmin({ userRole }))} style={{float:'right'}} onClick={() => this.openModal(4, otherAddressesFiltred[index])}><Icon type="edit" />
                                 </Button> 
                            </Popover>
                            {/* <DeleteAddress 
                                id={otherAddressesFiltred[index].customerAddressId}
                                nom={otherAddressesFiltred[index].name}
                                userRole={userRole}
                                refresh={this.reloadAddress}
                            /> */}
                     
                            </span>}>
                                {this.renderAddress(addresse)}
                            </Card>
                        </Col>
                    ))}       
                    </Row>
                </div>
            </Fragment>
        );
    }
}

ClientAddress.propTypes = {
    client: PropTypes.object.isRequired,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    intl: intlShape.isRequired,
    userRole: PropTypes.array.isRequired
};

const mapStateToProps = ({signIn: {userRole, userID }}) => ({
    userRole,
    userID
});

export default injectIntl(connect(mapStateToProps)(Form.create()(ClientAddress)));
