import React from 'react';
import PropTypes from 'prop-types';
import { NavLink, Link } from 'react-router-dom';
import { Layout, Icon } from 'antd';

import MainMenu from './MainMenu';
import FooterMenu from './FooterMenu';

import './Layout.less';
import { FormattedMessage } from 'react-intl';

const { Header, Content, Footer } = Layout;

const DesktopLayout = ({ pageKey, loggedIn, children, handleLogout, handleChangeLocale, currentLocale, flex, withPadding, history, includeMargin }) => (
    <Layout className={`main-container ${flex && 'fixed-size'}`}>
        <Header className="header">
            <NavLink to="/" className="app-name-container">
                <div className="logo-container">
                    <img
                        className="logo"
                        src="/img/logo.png"
                        alt="logo"
                    />
                </div>
            </NavLink>
            <MainMenu
                handleChangeLocale={handleChangeLocale}
                handleLogout={handleLogout}
                pageKey={pageKey}
                loggedIn={loggedIn}
                mode="desktop"
                currentLocale={currentLocale}
            />
        </Header>
        <Content className={`content ${flex ? 'content-flex' : ''} ${withPadding ? 'content-padding' : ''} ${includeMargin ? 'margin-content' : ''}`}>
            {history !== undefined && (
                <Link to="/" onClick={history.goBack}>
                    <Icon type="left" theme="outlined" />
                    <FormattedMessage id="menu.goBack" />
                </Link>
            )}
            {children}
        </Content>
        <Footer>
            <FooterMenu pageKey={pageKey} />
        </Footer>
    </Layout>
);

DesktopLayout.propTypes = {
    pageKey: PropTypes.string.isRequired,
    loggedIn: PropTypes.bool.isRequired,
    children: PropTypes.node,
    handleLogout: PropTypes.func,
    handleChangeLocale: PropTypes.func,
    currentLocale: PropTypes.string,
    flex: PropTypes.bool,
    withPadding: PropTypes.bool.isRequired,
    includeMargin: PropTypes.bool,
    history: PropTypes.shape({}),
};

export default DesktopLayout;
