import * as Sentry from '@sentry/browser';
import '@babel/polyfill';
import 'whatwg-fetch';

import { createElement } from 'react';
import { render } from 'react-dom';
import App from './App';

if ('development' !== process.env.NODE_ENV) {
    Sentry.init({
        dsn: process.env.SENTRY_URL,
        environment: process.env.SENTRY_ENVIRONMENT,
    });
}

render(createElement(App), document.getElementById('root'));
