import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Form, Button, Checkbox, Modal, Spin } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PageLayout from './Layout/PageLayout';
import { changeUserPassword } from '../utils/apiBucherVaslin';
import PasswordField from './PasswordField';

const { Item: FormItem } = Form;

// au moins 6 caractères, une minuscule, une majuscule et un caractère spécial
const passwordRe = /^(?=.{6,})(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[*@!#;%&()^~{}[\]/\\]).*$/;

class ClientForm extends Component {
    state = {
        confirmDirty: false,
        loading: false,
    }

    handleSubmit = (e) => {
        this.setState({ loading: true });

        e.preventDefault();
        const { form: { validateFieldsAndScroll }, history } = this.props;
        validateFieldsAndScroll((err, values) => {
            if (!err) {
                if (values.isRandom) {
                    changeUserPassword({ resetPassword: true })
                        .then(({ isSuccess, isUnauthorized, errorMessage }) => {
                            if (isSuccess) {
                                Modal.success({
                                    title: <FormattedMessage
                                        id="user.password.randomSuccess"
                                        defaultMessage="Password successfully generated. Check your emails."
                                    />,
                                });

                                return;
                            }

                            Modal.error({
                                title: isUnauthorized
                                ? <FormattedMessage
                                    id="error.unauthorized.title"
                                    defaultMessage=""
                                />
                                : <FormattedMessage
                                    id="user.password.randomFail"
                                    defaultMessage="Can't generate password"
                                />,
                                content: isUnauthorized
                                ? <FormattedMessage
                                    id="error.unauthorized.message"
                                    defaultMessage=""
                                />
                                : <FormattedMessage
                                    id="user.password.serverError"
                                    defaultMessage="Server Error. Try to relog you in."
                                />,
                            });

                            this.setState({ loading: false });
                        })
                        .finally(() => {
                            this.setState({ loading: false });
                        });

                    return;
                }
                const { oldPassword, password } = values;

                changeUserPassword({ oldPassword, newPassword: password })
                    .then(({ isSuccess, isUnauthorized }) => {
                        this.setState({ loading: false });

                        if (isSuccess) {
                            Modal.success({
                                title: <FormattedMessage
                                    id="user.password.updateSuccess"
                                    defaultMessage="Password successfully updated"
                                />,
                            });

                            // Redirect to the homepage
                            history.push('/');

                            return;
                        }
                        Modal.error({
                            title: isUnauthorized
                            ? <FormattedMessage
                                id="error.unauthorized.title"
                                defaultMessage=""
                            />
                            : <FormattedMessage
                                id="user.password.updateFail"
                                defaultMessage="Can't update password"
                            />,
                            content: isUnauthorized
                                ? (
                                    <FormattedMessage
                                        id="error.unauthorized.message"
                                        defaultMessage=""
                                    />
                                )
                                : (
                                    <FormattedMessage
                                        id="user.password.serverError"
                                        defaultMessage="Server Error. Try to relog you in."
                                    />
                                ),
                        });
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            }
            else {
                this.setState({ loading: false });
            }
        });
    }

    handleConfirmBlur = (e) => {
        const { target: { value } } = e;
        this.setState(({ confirmDirty }) => ({
            confirmDirty: confirmDirty || !!value,
        }));
    }

    compareToFirstPassword = (_, value, callback) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('Le mot de passe et sa confirmation doivent être identiques');
        } else {
            callback();
        }
    }

    validateToNextPassword = (_, value, callback) => {
        const { form } = this.props;
        const { confirmDirty } = this.state;
        if (value && confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    }

    render() {
        const {
            form,
            form: { getFieldDecorator, getFieldsValue },
            intl,
            history,
        } = this.props;
        const { loading } = this.state;

        getFieldDecorator('isRandom', {
            initialValue: false,
        });
        const { isRandom, password } = getFieldsValue();
        const formItemLayout = {
            labelCol: {
                xs: { span: 24 },
                sm: { span: 8 },
            },
            wrapperCol: {
                xs: { span: 24 },
                sm: { span: 16 },
            },
        };
        const tailFormItemLayout = {
            wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            },
        };

        return (
            <PageLayout pageKey="password" history={history}>
                <h2><FormattedMessage id="user.resetpassword.title" /></h2>
                <Form onSubmit={this.handleSubmit}>
                    <FormItem
                        {...tailFormItemLayout}
                    >
                        {getFieldDecorator('isRandom')(
                            <Checkbox><FormattedMessage id="user.resetpassword.email" /></Checkbox>,
                        )}
                    </FormItem>
                    {isRandom ? null : (
                        <Fragment>
                            <PasswordField
                                form={form}
                                identifier="oldPassword"
                                label={intl.formatMessage({ id: 'user.resetpassword.old' })}
                                layout={formItemLayout}
                                rules={[{
                                    required: true,
                                    message: 'Veuillez saisir votre ancien mot de passe',
                                }]}
                            />
                            <PasswordField
                                form={form}
                                identifier="password"
                                label={intl.formatMessage({ id: 'user.resetpassword.new' })}
                                layout={formItemLayout}
                                rules={[{
                                    required: true,
                                    message: (<FormattedMessage id="user.resetpassword.form.new" />),
                                }, {
                                    pattern: passwordRe,
                                    message: (<FormattedMessage id="user.resetpassword.form.password" />),
                                }, {
                                    validator: this.validateToNextPassword,
                                }]}
                                extra={password ? null : intl.formatMessage({ id: 'user.resetpassword.form.password' })}
                            />
                            <PasswordField
                                form={form}
                                identifier="confirm"
                                label={intl.formatMessage({ id: 'user.resetpassword.confirm' })}
                                layout={formItemLayout}
                                rules={[{
                                    required: true,
                                    message: (<FormattedMessage id="user.resetpassword.form.confirm" />),
                                }, {
                                    validator: this.compareToFirstPassword,
                                }]}
                                onBlur={this.handleConfirmBlur}
                            />
                        </Fragment>
                    )}
                    <FormItem {...tailFormItemLayout}>
                        {loading ? (
                            <Button htmlType="button">
                                <Spin size="small" />
                            </Button>
                        ) : (
                            <Button
                                type="primary"
                                htmlType="submit"
                            >
                                <FormattedMessage id="user.resetpassword.submit" />
                            </Button>
                        )}
                    </FormItem>
                </Form>
            </PageLayout>
        );
    }
}

ClientForm.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
        validateFieldsAndScroll: PropTypes.func.isRequired,
    }).isRequired,
    history: PropTypes.shape({}),
    intl: intlShape.isRequired,
};

export default Form.create()(injectIntl(ClientForm));
