import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { notification, Radio, Row, Col } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import PageLayout from '../Layout/PageLayout';
import { fetchAllLicenses, fetchLicensesForClient, fetchLicensesForUser } from '../../utils/apiBucherVaslin';
import { isGroupAdminClient, isSuperAdmin, isInfoCompleted } from '../../utils/helpers';
import LicensesList from './LicensesList';
import ModalGenerate from './ModalGenerate';

import './Licenses.less';

class Licenses extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            licenses: [],
        };

        this.selectedLicenses = 'myLicense';
    }

    componentDidMount = async () => {
        /* check connexion and information */ 
        const { intl, userID  } = this.props;
        await isInfoCompleted(intl, userID);

        this.fetchDataAsync();
    }

    /* eslint-disable-next-line */
    fetchFor = async (whichLicenses) => {
        const { clientID, userID, intl } = this.props;
        /* eslint-disable-next-line */
        switch (whichLicenses) {
            case 'myLicense':
                const { items: licensesForUser, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchLicensesForUser(userID)();
                if (!isSuccess){
                    notification.error({
                        message: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorDetail,
                    });
                }
                return licensesForUser;
            case 'ClientLicenses':
                const { items: licensesForClient
                    , isSuccess: isSuccessLicForCli
                    , isUnauthorized: isUnauthorizedLicForCli
                    , errorDetail: errorMessageLicForCli } = await fetchLicensesForClient(clientID)();
                if (!isSuccessLicForCli){
                    notification.error({
                        message: isUnauthorizedLicForCli 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorizedLicForCli 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorMessageLicForCli,
                    });
                }
                return licensesForClient;
            case 'AllLicenses':
                const { items: licenses
                    , isSuccess: isSuccessAllLic
                    , isUnauthorized: isUnauthorizedAllLic
                    , errorDetail: errorMessageAllLic } = await fetchAllLicenses();
                if (!isSuccessAllLic){
                    notification.error({
                        message: isUnauthorizedAllLic 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorizedAllLic 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorMessageAllLic,
                    });
                }
                return licenses;
        }
    };

    fetchDataAsync = async () => {
        this.setState({ loading: true });
        const licenses = await this.fetchFor(this.selectedLicenses);
        this.setState({ licenses: licenses, loading: false });
    };

    render() {
        const { history, userRole } = this.props;
        const { loading, licenses } = this.state;

        return (
            <PageLayout pageKey="Licenses" history={history}>
                <Row type="flex" justify="space-between">
                    <Col sm={24} md={4} lg={12}>
                        <h2>
                            <FormattedMessage id="table.licenses.title" defaultMessage="Licenses" />
                        </h2>
                    </Col>

                    {(isGroupAdminClient({ userRole }) || isSuperAdmin({ userRole })) && (
                        <Col sm={24} md={20} lg={12} className="radio-licenses">
                            <Radio.Group
                                defaultValue={this.selectedLicenses}
                                buttonStyle="solid"
                                onChange={({ target: { value } }) => {
                                    this.selectedLicenses = value;
                                    this.fetchDataAsync();
                                }}
                            >
                                <Radio.Button value="myLicense">
                                    <FormattedMessage id="licenses.switchDisplayedLicenses.myLicenses" />
                                </Radio.Button>
                                <Radio.Button value="ClientLicenses">
                                    <FormattedMessage id="licenses.switchDisplayedLicenses.clientLicenses" />
                                </Radio.Button>
                                {(isSuperAdmin ({ userRole })) &&
                                (
                                    <Radio.Button value="AllLicenses">
                                        <FormattedMessage id="licenses.switchDisplayedLicenses.allLicenses" />
                                    </Radio.Button>
                                )
                                }
                                
                            </Radio.Group>
                        </Col>
                    )}
                </Row>
                <LicensesList
                    licenses={licenses}
                    loading={loading}
                    refresh={this.fetchDataAsync}
                    showUserColumn
                />
                {isSuperAdmin({ userRole }) && <ModalGenerate refresh={this.fetchDataAsync} />}
            </PageLayout>
        );
    }
}

Licenses.propTypes = {
    clientID: PropTypes.string.isRequired,
    userID: PropTypes.string.isRequired,
    userRole: PropTypes.array,
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ signIn: { clientID, userID, userRole } }) => ({
    clientID,
    userID,
    userRole,
});

export default connect(mapStateToProps)(injectIntl(Licenses));
