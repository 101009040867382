import React, { Component, Fragment} from 'react';
import PropTypes from 'prop-types';
import { Button, Popover} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Page, Document, pdf, View, Text, StyleSheet, Image } from '@react-pdf/renderer';
import { fetchIncidentById, getAddressCustomerById } from '../../utils/apiBucherVaslin';

const acctualDate = moment(new Date()).format('DD/MM/YYYY');

// Create styles
const styles = StyleSheet.create({
	page: {
		flexDirection: 'row'
	},
    image: {
        margin: 20,
        width: '35%',
        height: '10%'
    },
    textGarantie: {
        position: 'absolute',
        left: '40%',
        top: '70px',
        fontSize: '20px'
    },
	sectionTop: {
        position: 'absolute',
        left: '30px',
        top: '180px',
        fontSize: '16px'
	},
    marginBottom: {
        marginBottom: 4
    },
    marginTopBottom: {
        marginBottom: 4,
        marginTop: 10
    },
    tableLibelle: {
        position: 'absolute',
        left: '30px',
        top: '45%',
        fontSize: '14px'
	},
    tableInfo: {
        position: 'absolute',
        left: '48%',
        top: '45%',
        fontSize: '14px'
	}
});

class BonImpression extends Component {
  constructor(props) {
      super(props);
      this.state = {
        isLoding: false
    };
}   

/**
 * Method used to get more incident information
 */
getIncidentById = async () =>
{    
    const { incidentId } = this.props;   
    this.setState({isLoading: true});
    const {incidentInfo, isSuccess, isUnauthorized, errorMessage, errorDetail} = await fetchIncidentById(incidentId);
    if (!isSuccess) {
        notification.error({
            message: isUnauthorized 
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({ id: 'common.error' }),
            description: isUnauthorized 
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : errorDetail
        });
    return;
    }
    
    this.loadAdresse(incidentInfo)   
}
/**
 * Method used to load adresse customer connected
 */
loadAdresse = async(incidentInfo) => {
    const { addresses, isSuccess, isUnauthorized, errorMessage, errorDetail } =  await getAddressCustomerById(incidentInfo.customerId);
    if (!isSuccess){
        notification.error({
            message: isUnauthorized 
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({ id: 'common.error' }),
            description: isUnauthorized 
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : errorDetail
        });
        return;
    } 
    const mainAddress = addresses.filter((address) => address.addressTypeCode === 3); 
    this.generatePDFDocument(incidentInfo, mainAddress)  
}   
// Create Document Component
generatePDFDocument = async (incidentInfo, addresses) => {
    const {techContact, etatPriseEnChargeLabel} = this.props;  
    const blob = await pdf(
    <Document>
    <Page size="A4">
        <Image src="/img/logo-.jpg" style={styles.image}/>
        <Text style={styles.textGarantie}>GARANTIE - WARRANTY</Text>
    <View style={styles.sectionTop}>
        <Text style={styles.marginBottom}>N° de demande / Request # : {incidentInfo.ticketNumber}</Text>
        <Text style={styles.marginBottom}>Demandeur  / Origin: {techContact}</Text>
        <Text style={styles.marginTopBottom}>{addresses ? addresses[0].line1 : " "}</Text>
        {addresses && addresses[0].line2 &&
            <Text style={styles.marginBottom}>{addresses[0].line2}</Text>
        }
        {addresses && addresses[0].line3 &&
            <Text style={styles.marginBottom}>{addresses[0].line3}</Text>
        }		
        <Text style={styles.marginBottom}>{addresses ? addresses[0].postalCode : " "}</Text>
        <Text style={styles.marginBottom}>{addresses ? addresses[0].city : " "}</Text>
        <Text style={styles.marginBottom}>{addresses ? addresses[0].country : " "}</Text>
    </View>
  
    <View style={styles.tableLibelle}>
        <Text>Date Création / Creation Date :</Text>
        <Text>Article / Part :</Text>
        <Text>Quantité / Quantity :</Text>
        <Text>Date défaillance / Failure date : </Text>
        <Text>Commande d’origine / Original order :</Text>
        <Text>Facture d’origine / Original bill :</Text>
        <Text>N° de série / Part serial # :</Text>
        <Text>Prix unitaire / Unit price :</Text>
        <Text>Prise en charge / Support status :</Text>
    </View>
    <View style={styles.tableInfo}>
        <Text>{acctualDate}</Text>
        <Text>{incidentInfo.article ? incidentInfo.article.productnumber : " "}</Text>
        <Text>{incidentInfo.quantite ? incidentInfo.quantite : " "}</Text>
        <Text>{moment(incidentInfo.dateDefaillance).format("DD/MM/YYYY")}</Text>
        <Text>{incidentInfo.commandeOrigine ? incidentInfo.commandeOrigine : " "}</Text>
        <Text>{incidentInfo.factureOrigine ? incidentInfo.factureOrigine : " "}</Text>
        <Text>{incidentInfo.seriePiece ? incidentInfo.seriePiece : " "}</Text>
        <Text>{incidentInfo.prixUnitaire ? incidentInfo.prixUnitaire : " "}</Text>
        <Text>{etatPriseEnChargeLabel}</Text>
    </View>
    </Page>
    </Document>
    ).toBlob();
    saveAs(blob, incidentInfo.ticketNumber);
    this.setState({isLoading: false});
};

  render() {
      const {isLoading} = this.state;
    return(
    <Popover content={<FormattedMessage id="incident.modal.formInfo.bonImpression" />}>
      <Button loading={isLoading} onClick={()=>this.getIncidentById()}>
        {!isLoading &&
            <FontAwesomeIcon icon={faDownload}/>    
        } 
      </Button>
    </Popover>
    )
  }
}

BonImpression.propTypes = {
    incidentId: PropTypes.string.isRequired,
    techContact: PropTypes.string,
    etatPriseEnChargeLabel: PropTypes.string

};
export default BonImpression;
