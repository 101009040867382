import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spin, notification, Collapse, Carousel } from 'antd';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import { fetchFAQ } from '../../utils/apiBucherVaslin';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './FAQ.less';
import { isInfoCompleted } from '../../utils/helpers';

const { Panel } = Collapse;
const BLOB_URL_FAQ = process.env.BLOB_URL_FAQ;

class FAQContent extends Component {
    constructor(props){
        super(props);
        this.state = {
            faq: [],
            loading: false,
        };
    }

    async componentDidMount() {
        const { intl, userID} = this.props;
        /* check connexion and information */ 
        await isInfoCompleted(intl, userID);
        this.loadFAQ();
    }

    // Method used to load faq list
    loadFAQ = async() => {
        const { intl, locale } = this.props;
        
        this.setState({ loading:true })
        const {faq, isSuccess, isUnauthorized, errorMessage, errorDetail} = await fetchFAQ(locale);
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            this.setState({ loading: false });
            return;
        }

        this.setState({ faq: faq, loading: false });
    }

    // Set next page on carousel
    next(carouselRef) {
        this.refs[carouselRef].next();
    }

    // Set previous page on carousel
    previous(carouselRef) {
        this.refs[carouselRef].prev();
    }

    render() {
        const { faq, loading } = this.state;
        const { intl, fromPortal } = this.props;

        // Arrow left for Carousel
        const ArrowLeft = ({ carouselRef}) => (
            <button onClick={() => { this.previous(carouselRef)}}>
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
        );
        
        // Arrow Right for Carousel
        const ArrowRight = ({ carouselRef }) => (
            <button onClick={() => { this.next(carouselRef)}}>
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
        );

        return(
            <Fragment>
                {(loading) ? (
                    <Spin size="large" />
                ) : (
                    <div className={(fromPortal) ? "faq-screen" : "faq-screen faq-mobile-screen"}>
                        {(fromPortal) && (
                            <h1>
                                {intl.formatMessage({ id: 'faq.title' })}
                            </h1>
                        )}                      

                        <p>
                            <FormattedMessage id="faq.description" />
                            <NavLink to="/contact">
                                <FormattedMessage
                                    id="faq.contactLink"
                                />
                            </NavLink>
                        </p>
                        {/* Show FAQ container */}
                        <div className="faq-container">
                            {/* Loop throught FAQ's chapters */}
                            <Collapse
                                expandIconPosition="left" 
                                bordered={false} 
                                destroyInactivePanel={true}
                            >
                            { faq.map((chapter, indexChapter) => (
                                <Panel header={chapter.chapterTitle}>
                                <div className="chapter-container" key={indexChapter}>
                                    <Collapse 
                                        expandIconPosition="left" 
                                        bordered={false} 
                                        destroyInactivePanel={true}
                                    >
                                        {chapter.faqTitleList.map((title) => (
                                            <Panel header={title.titleContent} key={title.titleId}>
                                                {title.faqContentList.length === 1 ? (
                                                    // Static FAQ content
                                                    <Fragment>
                                                    <div className="carousel-item">
                                                        <img src={BLOB_URL_FAQ + "/" + title.faqContentList[0].contentImgSrc} alt={title.faqContentList[0].contentImgAlt} />
                                                        {/* Display carousel description for the displayed item */}
                                                        <div className="static-faq-content">
                                                            <h3>{title.faqContentList[0].contentTitle}</h3>
                                                            <div dangerouslySetInnerHTML={{__html: title.faqContentList[0].contentDescription}} />
                                                        </div>
                                                    </div>                                               
                                                    </Fragment>
                                                ) : (
                                                    // Multi-page FAQ content
                                                    <Carousel 
                                                        autoplay={true}
                                                        autoplaySpeed={8000}
                                                        arrows
                                                        draggable={true}
                                                        nextArrow={ <ArrowRight carouselRef={"carousel_" + title.titleId} />}
                                                        prevArrow={ <ArrowLeft carouselRef={"carousel_" + title.titleId} />}
                                                        ref={"carousel_" + title.titleId}
                                                    >
                                                        { title.faqContentList.map((faqContent) => (
                                                            // Carousel item
                                                            <div key={faqContent.contentId} className="carousel-item">
                                                                <img src={BLOB_URL_FAQ + "/" + faqContent.contentImgSrc} alt={faqContent.contentImgAlt} />
                                                                {/* Display carousel description for the displayed item */}
                                                                <div className="carousel-item-description">
                                                                    <h3>{faqContent.contentTitle}</h3>
                                                                    <div dangerouslySetInnerHTML={{__html: faqContent.contentDescription}} />
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </Carousel>
                                                )}
                                            </Panel>
                                        ))}
                                    </Collapse>
                            </div>
                            </Panel>
                            ))}
                            </Collapse>
                        </div>
                    </div>
                )}
            </Fragment>
        )
    } 
}

FAQContent.propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    fromPortal: PropTypes.bool,
}

const mapStateToProps = ({
    app: {
        locale,
    },
    signIn : {
        userID
    }
}) => ({ locale, userID });
export default connect(mapStateToProps)(injectIntl(FAQContent));
