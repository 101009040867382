import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Icon, notification, Popover, Switch } from 'antd';
import { FormattedMessage, FormattedHTMLMessage, injectIntl, intlShape } from 'react-intl';
import { fetchCustomers, updateDevice } from '../utils/apiBucherVaslin';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Table from './Table';

const tableName = 'customers-light';

const SwapButton = ({ numeroClientEureka, machineProps, preTransfer, handleOk }) => {
    let isSuccessfullyTransferred;
    const handleClick = () => {
        updateDevice({
            ...machineProps,
            idClient: numeroClientEureka,
            preTransfer
        })
            .then(({ isSuccess, isUnauthorized }) => {
                isSuccess ? (
                    isSuccessfullyTransferred = true
                ) : (
                    isSuccessfullyTransferred = false
                );
                handleOk(isSuccessfullyTransferred, isUnauthorized);
            });
    };

    return (
        <span>
            <Popover content={<FormattedMessage id="table.devices.customerSwitch" />}>
                <Button htmlType="button" onClick={handleClick}>
                    <Icon type="swap" />
                </Button>
            </Popover>
        </span>
    );
};

SwapButton.propTypes = {
    numeroClientEureka: PropTypes.string,
    machineProps: PropTypes.shape({}),
    handleOk: PropTypes.func,
};

const columns = (machineProps, preTransfer, handleOk) => (

    [
        {
            dataIndex: 'nomClient',
            key: 'name',
            sorter: true,
            render:() => (_, props) =>{
                return <Fragment><span>{props.nomClient} ({props.ville})</span><br/><span style={{fontSize:"90%", color:'#d65c10'}}><i>{props.numeroClientEureka}</i></span></Fragment>
            },
        },
        {
            key: 'action',
            render: () => (_, props) => (
                //  (machineProps.client.concessionaire == props.numeroClientEureka
                //     || machineProps.client.numeroClientEureka == props.numeroClientEureka) ?
                //     <SwapButton {...props} machineProps={machineProps} handleOk={handleOk} /> : ''
                    <SwapButton {...props} machineProps={machineProps} preTransfer={preTransfer} handleOk={handleOk} />
                ),
        },
    ]
);

const keyToDbColumn = {
    name: 'NOM_CLIENT',
};

class GiveMachine extends Component {
    state = {
        visible: false,
        preTransfer: false,
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = (isSuccessfullyTransferred, isUnauthorized) => {
        const { refresh, intl } = this.props;
        this.setState({
            visible: false,
        });
        refresh();
        isSuccessfullyTransferred ? (
            Modal.success({
                title: intl.formatMessage({
                    id: 'table.devices.deviceTransferSuccess',
                }),
            })
        ) : (
            Modal.error({
                title: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                : intl.formatMessage({
                    id: 'table.devices.deviceTransferFail',
                }),
                content: isUnauthorized
                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                : null,
            })
        );
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { visible, preTransfer } = this.state;
        const { authorizeTransfert } = this.props;

        return (
            <span>
                <Popover content={!authorizeTransfert ? <FormattedMessage id="table.devices.impossible" /> : <FormattedMessage id="table.devices.customerSwitch" />}>
                    <Button htmlType="button" onClick={this.showModal}  disabled={!authorizeTransfert}>
                        <Icon type="retweet" />
                    </Button>
                </Popover>
                <Modal
                    width={700}
                    title={(
                        <FormattedMessage
                            id="table.devices.customerSelect"
                            defaultMessage="Select a user"
                        />
                    )}
                    visible={visible}
                    onCancel={this.handleCancel}
                    footer={[
                        <Button key="cancel" type="default" onClick={this.handleCancel}>
                            <FormattedMessage id="common.modal.cancel" />
                        </Button>
                    ]}
                >
                    <div>
                        <span>
                            <Switch
                                defaultChecked={preTransfer}
                                onChange={value => { this.setState({preTransfer: value}); }}
                                className="alert-switch"
                            />
                            <FormattedMessage
                                id="table.devices.preTransfer.switch"
                                defaultMessage="Perform a pre-transfer"
                            />
                            <Popover 
                                content={<FormattedHTMLMessage  id="table.devices.preTransfer.info" />}
                            >
                                <FontAwesomeIcon icon={ faInfoCircle } style={{marginLeft: "0.5em"}} />
                            </Popover>
                        </span>
                    </div>

                    <Table
                        fetchItems={fetchCustomers}
                        keyToDbColumn={keyToDbColumn}
                        columns={columns(this.props, this.state.preTransfer, this.handleOk)}
                        rowKey="numeroClientEureka"
                        tableName={tableName}
                    />
                </Modal>
            </span>
        );
    }
}

GiveMachine.propTypes = {
    refresh: PropTypes.func,
    intl: intlShape.isRequired,
    authorizeTransfert: PropTypes.bool.isRequired,
};

export default injectIntl(GiveMachine);
