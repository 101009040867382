import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Card, Icon, notification } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { NavLink } from 'react-router-dom';
import { isNil } from 'lodash';

import { fetchCustomerDetailsById, fetchCurrentCustomerDetails } from '../../utils/apiBucherVaslin';

import PageLayout from '../Layout/PageLayout';

import './Details.less';
import { isGroupAdminClient, isInfoCompleted } from '../../utils/helpers';

const DetailLine = ({ titleID, defaultTitle, content, additionalTitle }) => (
    <div className="detail-line">
        <div className="detail-title">
            <FormattedMessage id={titleID} defaultMessage={defaultTitle} />
            {additionalTitle}
        </div>
        <div className="detail-content">{content}</div>
    </div>
);

DetailLine.propTypes = {
    titleID: PropTypes.string.isRequired,
    defaultTitle: PropTypes.string.isRequired,
    content: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    additionalTitle: PropTypes.string,
};

DetailLine.defaultProps = {
    additionalTitle: '',
};

class ClientDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clientInfo: {},
        };
    }

    async componentDidMount() {
        const {
            match: {
                params: { eureka },
            },
            intl, userID
        } = this.props;
        /* check connexion and information */ 
        await isInfoCompleted(intl, userID);

        if (!isNil(eureka)) {
            fetchCustomerDetailsById(eureka).then(({ item: clientInfo, isSuccess, isUnauthorized, errorMessage, errorDetail }) => {
                if (!isSuccess){
                    notification.error({
                        message: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorDetail,
                    });
                }

                this.setState({
                    clientInfo,
                });
            });
        } else {
            fetchCurrentCustomerDetails().then(({ clientInfo }) => {
                this.setState({
                    clientInfo,
                });
            });
        }
    }

    renderContent() {
        const { clientInfo } = this.state;

        return (
            <Fragment>
                <DetailLine
                    titleID="customerDetails.line.clientName"
                    defaultTitle="Name"
                    content={clientInfo.nomClient}
                />
                <DetailLine
                    titleID="customerDetails.line.address"
                    defaultTitle="Address"
                    content={clientInfo.adresseClient1}
                    additionalTitle=" 1"
                />
                <DetailLine
                    titleID="customerDetails.line.address"
                    defaultTitle="Address"
                    content={clientInfo.adresseClient2}
                    additionalTitle=" 2"
                />
                <DetailLine
                    titleID="customerDetails.line.postalCode"
                    defaultTitle="Postal code"
                    content={clientInfo.codePostal}
                />
                <DetailLine titleID="customerDetails.line.city" defaultTitle="City" content={clientInfo.ville} />
                <DetailLine titleID="customerDetails.line.country" defaultTitle="Country" content={clientInfo.pays} />
                <DetailLine
                    titleID="customerDetails.line.language"
                    defaultTitle="Language"
                    content={clientInfo.langue}
                />
                <DetailLine titleID="customerDetails.line.siret" defaultTitle="SIRET" content={clientInfo.siret} />
                <DetailLine titleID="customerDetails.line.idTVA" defaultTitle="ID TVA" content={clientInfo.idTva} />
                <DetailLine
                    titleID="customerDetails.line.currency"
                    defaultTitle="Currency"
                    content={clientInfo.devise}
                />
                <DetailLine
                    titleID="customerDetails.line.eurekaClientID"
                    defaultTitle="ID Eureka"
                    content={clientInfo.numeroClientEureka}
                />
                <DetailLine
                    titleID="customerDetails.line.dealer"
                    defaultTitle="Dealer"
                    content={clientInfo.dealers && clientInfo.dealers.map((deal) => <Fragment>{deal.nomClient}< br/></Fragment>)}
                />
                <DetailLine titleID="customerDetails.line.rr" defaultTitle="Sales Manager" content={clientInfo.rr} />
                <DetailLine
                    titleID="customerDetails.line.smsConsumption"
                    defaultTitle="SMS consumption for current month"
                    content={clientInfo.consoSmsMoisEnCours}
                />
            </Fragment>
        );
    }

    render() {
        const { clientInfo } = this.state;
        const {
            userRole,
            match: {
                params: { eureka },
            },
            history,
        } = this.props;

        const editLink = isNil(eureka) ? '/myInformation/edition' : `/customers/${eureka}/edition`;

        return (
            <PageLayout pageKey={isNil(eureka) ? 'myInformation' : 'customers'} history={history}>
                <div className="customerDetails">
                    <Card
                        title={(
                            <Fragment>
                                {isNil(eureka) ? (
                                    <FormattedMessage id="customerDetails.myTitle" defaultMessage="My customer account" />
                                ) : (
                                    <FormattedMessage id="customerDetails.title" defaultMessage="Client" />
                                )}
                                {isGroupAdminClient({ userRole }) && (
                                    <NavLink to={editLink} className="edit-link">
                                        <Icon type="form" />
                                    </NavLink>
                                )}
                            </Fragment>
                        )}
                        loading={isNil(clientInfo.numeroClientEureka)}
                    >
                        {!isNil(clientInfo.numeroClientEureka) ? this.renderContent() : null}
                    </Card>
                </div>
            </PageLayout>
        );
    }
}

ClientDetails.propTypes = {
    userRole: PropTypes.array,
    match: PropTypes.shape({
        params: PropTypes.shape({
            eureka: PropTypes.string,
        }).isRequired,
    }).isRequired,
    intl: intlShape.isRequired
};

const mapStateToProps = ({ signIn: { userRole, userID } }) => ({
    userRole,
    userID
});

export default injectIntl(connect(mapStateToProps)(ClientDetails));
