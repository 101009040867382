import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl, intlShape } from "react-intl";
import { Form, Switch, TimePicker, List, Divider, notification } from "antd";
import { setUserAlerts } from "../../utils/apiBucherVaslin";
import { isInfoCompleted } from "../../utils/helpers";

import "./EditForm.less";
import moment from "moment";
import { formItemLayout } from "../../utils/formItemLayout";

const format = "HH:mm";
const { Item: FormItem } = Form;

class EditForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActivated: false,
      isEmailActivated: false,
      isSmsActivated: false,
      start: "00:00",
      stop: "00:00",
    };
  }

  async componentDidMount() {
    const {
      configNotif: {
        isActivated,
        isEmailActivated,
        isSmsActivated,
        start,
        stop,
      },
      connectedUserID,
      intl,
    } = this.props;
    /* check connexion and information */
    await isInfoCompleted(intl, connectedUserID);
    this.setState({
      isActivated,
      isEmailActivated,
      isSmsActivated,
      start: start != null ? start : "00:00",
      stop: stop != null ? stop : "00:00",
    });
  }

  // Actions de modifications des valeurs
  setNotifPushActivated(checked) {
    const { isEmailActivated, isSmsActivated, start, stop } = this.state;
    this.setState({ isActivated: checked.isActivated }, () => {
      this.handleSubmit(
        start,
        stop,
        checked.isActivated,
        isEmailActivated,
        isSmsActivated
      );
    });
  }

  setNotifEmailActivated(checked) {
    const { isActivated, isSmsActivated, start, stop } = this.state;
    this.setState({ isEmailActivated: checked.isEmailActivated }, () => {
      this.handleSubmit(
        start,
        stop,
        isActivated,
        checked.isEmailActivated,
        isSmsActivated
      );
    });
  }

  setNotifSmsActivated(checked) {
    const { isEmailActivated, isActivated, start, stop } = this.state;
    this.setState({ isSmsActivated: checked.isSmsActivated }, () => {
      this.handleSubmit(
        start,
        stop,
        isActivated,
        isEmailActivated,
        checked.isSmsActivated
      );
    });
  }

  onChangeTimeStart = (time, timeString) => {
    const { isActivated, isEmailActivated, isSmsActivated, stop } = this.state;
    this.setState({ start: timeString }, () => {
      this.handleSubmit(
        timeString,
        stop,
        isActivated,
        isEmailActivated,
        isSmsActivated
      );
    });
  };

  onChangeTimeStop = (time, timeString) => {
    const { isActivated, isEmailActivated, isSmsActivated, start } = this.state;
    this.setState({ stop: timeString }, () => {
      this.handleSubmit(
        start,
        timeString,
        isActivated,
        isEmailActivated,
        isSmsActivated
      );
    });
  };
  // Fin action de modifications des valeurs

  // Appel de la fonction de sauvegarde de la configuration notification
  handleSubmit(start, stop, isActivated, isEmailActivated, isSmsActivated) {
    const { save, configNotif } = this.props;
    let token = configNotif.token;
    let lang = configNotif.lang;
    save({
      token,
      start,
      stop,
      isActivated,
      lang,
      isEmailActivated,
      isSmsActivated,
    });
  }

  render() {
    const {
      configNotif,
      user,
      form: { getFieldDecorator },
      connectedUserID,
      intl,
    } = this.props;
    const { start, stop } = this.state;

    const data = [
      {
        title: "Alerts",
        key: "alerts",
      },
      {
        title: "Activation",
        key: "activation",
      },
      {
        title: "Do not disturb",
        key: "notDisturb",
      },
    ];

    return (
      <Form>
        <List
          grid={{ gutter: 16, column: 9 }}
          dataSource={data}
          renderItem={(item) => (
            <List.Item>
              <List.Item.Meta
                title={
                  <FormattedMessage
                    id={`notification.edit.${item.key}`}
                    defaultMessage={item.title}
                  />
                }
              />
            </List.Item>
          )}
        />
        <List grid={{ gutter: 16, column: 9 }}>
          <List.Item>
            <FormattedMessage
              id="notification.edit.push"
              defaultMessage="Push"
            />
          </List.Item>
          <List.Item>
            <Switch
              defaultChecked={user.push}
              onChange={(checked) =>
                setUserAlerts({
                  push: checked,
                  idUser: connectedUserID === user.idUser ? user.idUser : null,
                }).then(({ isSuccess, isUnauthorized }) => {
                  if (isSuccess) {
                    notification.success({
                      message: intl.formatMessage({
                        id: "notification.edit.title",
                      }),
                      description: intl.formatMessage({
                        id: "notification.edit.success.description",
                      }),
                    });

                    return true;
                  } else {
                    notification.error({
                      message: isUnauthorized
                        ? intl.formatMessage({ id: "error.unauthorized.title" })
                        : intl.formatMessage({ id: "common.error" }),
                    });
                  }
                })
              }
              className="alert-switch"
            />
          </List.Item>
          <List.Item>
            <Switch
              defaultChecked={configNotif.isActivated}
              onChange={(checked) =>
                this.setNotifPushActivated({ isActivated: checked })
              }
              className="alert-switch"
            />
          </List.Item>
        </List>
        <Divider style={{ height: "100%" }} />
        <List grid={{ gutter: 16, column: 9 }}>
          <List.Item>
            <FormattedMessage
              id={`notification.edit.email`}
              defaultMessage="Email"
            />
          </List.Item>
          <List.Item>
            <Switch
              defaultChecked={user.isEmailAlertActivated}
              onChange={(checked) =>
                setUserAlerts({
                  isEmailAlertActivated: checked,
                  idUser: connectedUserID === user.idUser ? user.idUser : null,
                }).then(({ isSuccess, isUnauthorized }) => {
                  if (isSuccess) {
                    notification.success({
                      message: intl.formatMessage({
                        id: "notification.edit.title",
                      }),
                      description: intl.formatMessage({
                        id: "notification.edit.success.description",
                      }),
                    });

                    return true;
                  } else {
                    notification.error({
                      message: isUnauthorized
                        ? intl.formatMessage({ id: "error.unauthorized.title" })
                        : intl.formatMessage({ id: "common.error" }),
                    });
                  }
                })
              }
              className="alert-switch"
            />
          </List.Item>
          <List.Item>
            <Switch
              defaultChecked={configNotif.isEmailActivated}
              onChange={(checked) =>
                this.setNotifEmailActivated({ isEmailActivated: checked })
              }
              className="alert-switch"
            />
          </List.Item>
        </List>
        <Divider />
        <List grid={{ gutter: 16, column: 9 }}>
          <List.Item>
            <FormattedMessage id="notification.edit.sms" defaultMessage="SMS" />
          </List.Item>
          <List.Item>
            <Switch
              disabled={!user.mobileNumber || user.mobileNumber.length == 0}
              defaultChecked={user.isSmsActivated}
              onChange={(checked) =>
                setUserAlerts({
                  isSmsActivated: checked,
                  idUser: connectedUserID === user.idUser ? user.idUser : null,
                }).then(({ isSuccess, isUnauthorized }) => {
                  if (isSuccess) {
                    notification.success({
                      message: intl.formatMessage({
                        id: "notification.edit.title",
                      }),
                      description: intl.formatMessage({
                        id: "notification.edit.success.description",
                      }),
                    });

                    return true;
                  } else {
                    notification.error({
                      message: isUnauthorized
                        ? intl.formatMessage({ id: "error.unauthorized.title" })
                        : intl.formatMessage({ id: "common.error" }),
                    });
                  }
                })
              }
              className="alert-switch"
            />
          </List.Item>
          <List.Item>
            <Switch
              defaultChecked={configNotif.isSmsActivated}
              onChange={(checked) =>
                this.setNotifSmsActivated({ isSmsActivated: checked })
              }
              className="alert-switch"
            />
          </List.Item>
        </List>
        <Divider />
        <Fragment>
          <FormItem
            wrapperCol={{
              xs: {
                span: 24,
                offset: 0,
              },
              sm: {
                span: 24,
                offset: 0,
              },
            }}
          >
            <FormattedMessage
              id="notification.edit.period"
              defaultMessage='The "do not disturb" period is common for each type of alert. Set here schedules'
            />{" "}
            :
          </FormItem>

          <FormItem
            {...formItemLayout}
            label={
              <FormattedMessage
                id="notification.edit.period.fromTime"
                defaultMessage="From"
              />
            }
          >
            {getFieldDecorator(`start`, {
              rules: [],
              initialValue: moment(start, format),
            })(
              <TimePicker
                format={format}
                onChange={this.onChangeTimeStart}
                className="time-picker"
              />
            )}
          </FormItem>
          <FormItem
            {...formItemLayout}
            label={
              <FormattedMessage
                id="notification.edit.period.toTime"
                defaultMessage="To"
              />
            }
          >
            {getFieldDecorator(`stop`, {
              rules: [],
              initialValue: moment(stop, format),
            })(
              <TimePicker
                format={format}
                onChange={this.onChangeTimeStop}
                className="time-picker"
              />
            )}
          </FormItem>
        </Fragment>
      </Form>
    );
  }
}

EditForm.propTypes = {
  configNotif: PropTypes.shape({}),
  user: PropTypes.shape({}),
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
  save: PropTypes.func.isRequired,
};

const mapStateToProps = ({ signIn: { userID } }) => ({
  connectedUserID: userID,
});

export default injectIntl(Form.create()(connect(mapStateToProps)(EditForm)));
