import { notification } from 'antd';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import LicensesList from './LicensesList';
import NotFoundPage from '../NotFoundPage';
import PageLayout from '../Layout/PageLayout';
import { fetchLicensesForUser, fetchUserDetailsById } from '../../utils/apiBucherVaslin';

class UserLicenses extends Component {
    state = {
        licenses: [],
        loading: true,
        nomPrenom: null,
    };

    componentDidMount() {
        this.fetchDataAsync();
    }

    fetchDataAsync = async () => {
        const { match: { params: { userId } }, intl } = this.props;
        if (userId) {
            this.setState({ loading: true });
            const { items: licenses, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchLicensesForUser(userId)();
            if (!isSuccess){
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail,
                });
            }

            const { item
                , isSuccess: isSuccessUserGet
                , isUnauthorized: isUnauthorizedUserGet
                , errorDetail: errorMessageUserGet } = await fetchUserDetailsById(userId);
            const nomPrenom = item ? item.nomPrenom : null;

            if (!isSuccessUserGet){
                notification.error({
                    message: isUnauthorizedUserGet 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorizedUserGet 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorMessageUserGet,
                });
            }
            this.setState({
                licenses: isSuccess ? licenses : [],
                loading: false,
                nomPrenom,
            });
        }
    };

    render() {
        const { licenses, loading, nomPrenom } = this.state;
        const { history } = this.props;

        if (!loading && !nomPrenom) {
            return (
                <NotFoundPage>
                    <p>
                        <FormattedMessage
                            id="user.not.found"
                            defaultMessage="User not found"
                        />
                    </p>
                </NotFoundPage>
            );
        }

        return (
            <PageLayout pageKey="UserLicenses" history={history}>
                <h2>
                    <FormattedMessage
                        id="table.users.licenses.title"
                        values={{ name: nomPrenom }}
                        defaultMessage="Licenses"
                    />
                </h2>
                <LicensesList
                    licenses={licenses}
                    loading={loading}
                    refresh={this.fetchDataAsync}
                />
            </PageLayout>
        );
    }
}

UserLicenses.propTypes = {
    match: PropTypes.shape({
        params: PropTypes.shape({
            userId: PropTypes.string,
        }).isRequired,
    }),
    intl: intlShape.isRequired,
};

export default injectIntl(UserLicenses);
