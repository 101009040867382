import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { Page, Document, pdf, View, Text, StyleSheet, Image } from '@react-pdf/renderer';


const acctualDate = moment(new Date()).format('DD/MM/YYYY');
// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row'
  },
  sectionFrom: {
    position: 'absolute',
    left: '30px',
    top: '140px',
    fontSize: '16px',
    color: '#C0C0C0',
    width: '27%'
  },
  sectionTo: {
    position: 'absolute',
    left: '30%',
    top: '140px',
    fontSize: '18px',
  },
  image: {
    margin: 20,
    width: '20%',
    height: '10%'
  },
  textGarantie: {
    position: 'absolute',
    left: '20%',
    top: '60px',
    fontSize: '20px',
  },
  sectionInfo: {
    position: 'absolute',
    left: '30%',
    top: '300px',
    fontSize: '18px'
  },
  txtInfo: {
    marginTop: 6,
  },
  txtWarningF: {
    left: '35%',
  },
  txtWarningH: {
    left: '15%',
  },
  sectionWarning: {
    position: 'absolute',
    bottom: '32px',
    fontSize: '40px'
  }
});


class BonDeRetour extends Component {
  constructor(props) {
      super(props);

  }   
  
  // Create Document Component
generatePDFDocument = async () => {
  const { incident, clientName, customerAddresse } = this.props;    
  const blob = await pdf(
  <Document>
    <Page size="A4" orientation='landscape'>
      <Image src="/img/logo-.jpg" style={styles.image}/>
      <Text style={styles.textGarantie}>GARANTIE - WARRANTY</Text>
    <View style={styles.sectionFrom}>
				<Text>From :</Text>
				<Text>{clientName}</Text>
        <Text>{customerAddresse ? customerAddresse[0].line1 : " "}</Text>
        {customerAddresse && customerAddresse[0].line2 && (
          <Text>{customerAddresse[0].line2}</Text> 
        )}  
        {customerAddresse && customerAddresse[0].line3 && (
          <Text>{customerAddresse[0].line3}</Text> 
        )}        		
				<Text>{customerAddresse ? customerAddresse[0].postalCode : " "}</Text>
				<Text>{customerAddresse ? customerAddresse[0].city : " "}</Text>
				<Text>{customerAddresse ? customerAddresse[0].country : " "}</Text>

		</View>
    <View style={styles.sectionTo}>
        <Text>To :</Text>
				<Text>Bucher Vaslin - Magasin</Text>
				<Text>Rue Gaston Bernier</Text>
				<Text>49290</Text>
				<Text>CHALONNES SUR LOIRE</Text>
				<Text>FRANCE</Text>
		</View>
    <View style={styles.sectionInfo}>
      <Text style={styles.txtInfo}>Ref : {incident.ticketNumber} </Text>
			<Text style={styles.txtInfo}>Art : {incident.article.productnumber} </Text>
			<Text style={styles.txtInfo}>Qte : {incident.quantite} </Text>
      <Text style={styles.txtInfo}>Crea : {moment(incident.dateDefaillance).format("DD/MM/YYYY")} </Text>
			<Text style={styles.txtInfo}>Exp : {acctualDate} </Text>
		</View>
    <View style={styles.sectionWarning}>
      <Text style={styles.txtWarningF}>FRAGILE</Text>
      <Text style={styles.txtWarningH}>Handle with care </Text>
    </View>
    </Page>
  </Document>
  ).toBlob();
  saveAs(blob, incident.ticketNumber);
};

  render() {
    return(
      <Button  onClick={()=>this.generatePDFDocument()}>
        <FontAwesomeIcon icon={faDownload} style={{marginRight: "0.5em"}}/>
        <FormattedMessage id="incident.modal.formInfo.bonDeRetour" />
      </Button>
    )
  }
}

BonDeRetour.propTypes = {
  incidentId: PropTypes.array.isRequired,
  clientName: PropTypes.string.isRequired,
  customerAddresse: PropTypes.array
};
export default BonDeRetour;
