import React, { Component, Fragment } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { REGEX_ORDER_REF } from "../../utils/constants";
import {
  Alert,
  DatePicker,
  Divider,
  Button,
  Input,
  Modal,
  message,
  notification,
  Form,
  Select,
  Spin,
  Switch,
} from "antd";
import { connect } from "react-redux";
import {
  FormattedHTMLMessage,
  FormattedMessage,
  injectIntl,
  intlShape,
} from "react-intl";
import {
  createOrder,
  fetchDeliveryDeadlines,
  getAddressCustomerById,
  fetchCustomers,
  fetchOrderType,
} from "../../utils/apiBucherVaslin";
import { isSuperAdmin } from "../../utils/helpers";
import RequiredFieldLegend from "../RequiredFieldLegend";
import SelectCustomer from "../SelectCustomer";
import OrderStepper from "./OrderStepper";
import { debounce } from "lodash";
import "./CreateOrder.less";

const { Item: FormItem } = Form;
const { Option } = Select;
const datePlus6Month = moment(new Date()).add(6, "M").format("YYYY/MM/DD");

class CreateOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      confirmLoading: false,
      deliveryDate: "",
      deliveryDeadlinesList: [],
      deliveryDeadlinesListFiltred: [],
      mainAddressId: "",
      addresses: [],
      orderTypeList: [],
      concessionaireListeUsers: [],
      concessionaireFiltredUsers: [],
      guidClientToSend: "",
      isFetching: false,
      dateMinOrder: null,
      dateMaxOrder: null,
      disabledDatePicker: true,
      idDeadLinesSelected: null,
      characterCount: 0,
      characterCountRef: 0,
    };
  }

  componentDidMount = async () => {
    // Init deliveryDate list
    await this.initDeliveryDeadlines();
    // Init order type
    await this.initOrderType();
    // Init dealer's user
    await this.loadDealerUsers();
  };

  generateModal = () => {
    this.setState({
      visible: true,
    });
  };

  // Method used to get dealer's user
  // Return an array of user
  onSearch = async (value) => {
    this.setState({
      isFetching: value.length !== 0,
      concessionaireFiltredUsers: [],
    });

    if (value) {
      const {
        items: concessionaireFiltredUsers,
        isSuccess,
        isUnauthorized,
        errorMessage,
        errorDetail
      } = await fetchCustomers({ nom_client: value });

      if (!isSuccess) {
        notification.error({
          message: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.title" })
            : intl.formatMessage({ id: "common.error" }),
          description: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.message" })
            : errorDetail,
        });
      }
      const guidListe = concessionaireFiltredUsers.filter(
        (concessionaireUser) => concessionaireUser.guIdClient != null
      );
      this.setState({
        concessionaireFiltredUsers: guidListe,
        isFetching: false,
      });
      return;
    }
  };
  // Method used to get dealer's user
  // Return an array of user
  loadDealerUsers = async () => {
    const { intl, signIn, clientID } = this.props;
    const {
      items,
      isSuccess: isSuccessUserConcCli,
      isUnauthorized: isUnauthorizedUserConcCli,
      errorDetail: errorMessageUserConcCli,
    } = await fetchCustomers(signIn);

    if (!isSuccessUserConcCli) {
      notification.error({
        message: isUnauthorizedUserConcCli
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorizedUserConcCli
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorMessageUserConcCli,
      });
      return;
    }
    const guidInitClientConnected = items.filter(
      (concessionaireUser) => concessionaireUser.numeroClientEureka === clientID
    );
    const guidClientConnectedToStore = guidInitClientConnected[0].guIdClient;
    this.setState({
      concessionaireListeUsers: items,
      concessionaireFiltredUsers: guidInitClientConnected,
      guidClientToSend: guidClientConnectedToStore,
    });
    //  Init client addresses
    await this.loadAddresses();
  };

  onChangeClient = async (key, value) => {
    const { concessionaireListeUsers } = this.state;
    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
    const guidInitClientSelected = concessionaireListeUsers.filter(
      (concessionaireUser) => concessionaireUser.guIdClient === key
    );
    this.setState({
      concessionaireFiltredUsers: guidInitClientSelected,
      guidClientToSend: key,
      isFetching: false,
    });
    await this.loadAddresses(key);
  };

  // Method load client addresses
  loadAddresses = async (clientGuid) => {
    const { guidClientToSend } = this.state;
    const clientGuidToSend = clientGuid ? clientGuid : guidClientToSend;
    const { intl, form } = this.props;
    if (clientGuidToSend) {
      const { addresses, isSuccess, isUnauthorized, errorMessage, errorDetail } =
        await getAddressCustomerById(clientGuidToSend);
      if (!isSuccess) {
        notification.error({
          message: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.title" })
            : intl.formatMessage({ id: "common.error" }),
          description: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.message" })
            : errorDetail,
        });
        return;
      }
      const addressesWhitoutNull = addresses.filter(
        (address) => address.line1 != null
      );
      const otherAddress = addressesWhitoutNull.filter(
        (address) => address.addressTypeCode === 4
      );
      const billingAddress = addressesWhitoutNull.filter(
        (address) => address.addressTypeCode === 1
      );
      const deliveryAddress = addressesWhitoutNull.filter(
        (address) => address.addressTypeCode === 2
      );
      const mainAddress = addresses.filter(
        (address) => address.addressTypeCode === 3
      );
      for (let i = 0; i < addresses.length; i++) {
        if (addresses[i].addressTypeCode === 1) {
          addresses[i].addressTypeLabel = intl.formatMessage({
            id: "client.address.billing",
          });
        } else if (addresses[i].addressTypeCode === 2) {
          addresses[i].addressTypeLabel = intl.formatMessage({
            id: "client.address.delevery",
          });
        } else if (addresses[i].addressTypeCode === 3) {
          addresses[i].addressTypeLabel = intl.formatMessage({
            id: "client.address.main",
          });
        } else {
          addresses[i].addressTypeLabel = intl.formatMessage({
            id: "client.address.orderOther",
          });
        }
      }
      const addresseSortWhitoutNull = [
        ...mainAddress,
        ...deliveryAddress,
        ...billingAddress,
        ...otherAddress,
      ];

      const deliveryAddresstoStore =
        deliveryAddress.length > 0 ? deliveryAddress[0].customerAddressId : "";
      const mainAddresstoStore =
        mainAddress.length > 0 ? mainAddress[0].customerAddressId : "";

      form.setFieldsValue({
        dynamicsAdresseGuid: mainAddresstoStore
          ? mainAddresstoStore
          : deliveryAddresstoStore,
      });
      await this.setState({
        addresses: addresseSortWhitoutNull,
        mainAddressId: mainAddresstoStore,
      });
    }
  };

  initOrderType = async () => {
    const { intl } = this.props;
    // Charge la liste des délais de livraison avec le libellé dans la langue de l'utilisateur
    const { orderType, isSuccess, isUnauthorized, errorMessage, errorDetail } =
      await fetchOrderType();
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
      return;
    }
    await this.setState({ orderTypeList: orderType });
  };

  initDeliveryDeadlines = async () => {
    const { intl } = this.props;
    // Charge la liste des délais de livraison avec le libellé dans la langue de l'utilisateur
    const { deliveryDeadlines, isSuccess, isUnauthorized, errorMessage, errorDetail } =
      await fetchDeliveryDeadlines();
    if (!isSuccess) {
      notification.error({
        message: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({ id: "common.error" }),
        description: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : errorDetail,
      });
      return;
    }
    await this.setState({ deliveryDeadlinesList: deliveryDeadlines });
  };

  updateDateMinMax = (value, event) => {
    const { deliveryDeadlinesList, idDeadLinesSelected } = this.state;
    const { form } = this.props;
    let dateMinToAdd = null;
    let dateMaxOrder = null;
    const deleverySelected = deliveryDeadlinesList.find(
      (delevery) => delevery.id == value
    );
    let dateMinOrderToChange = deleverySelected.nbJourMiniAvantLivraison;
    let dateMaxOrderToChange = deleverySelected.nbJourMaxAvantLivraison;
    if (idDeadLinesSelected != value) {
      form.setFieldsValue({ dateLivraison: null });
    }
    if (dateMinOrderToChange) {
      let dateMin = new Date();
      while (dateMinOrderToChange > 0) {
        dateMin.setDate(dateMin.getDate() + 1);
        if (moment(dateMin).day() !== 0 && moment(dateMin).day() !== 6) {
          dateMinOrderToChange--;
        }
      }
      dateMinToAdd = moment(dateMin).format("YYYY/MM/DD");
    }
    if (dateMaxOrderToChange) {
      let dateMax = new Date();
      while (dateMaxOrderToChange > 0) {
        dateMax.setDate(dateMax.getDate() + 1);
        if (moment(dateMax).day() !== 0 && moment(dateMax).day() !== 6) {
          dateMaxOrderToChange--;
        }
      }
      dateMaxOrder = moment(dateMax).format("YYYY/MM/DD");
    }
    this.setState({
      dateMinOrder: dateMinToAdd,
      dateMaxOrder,
      disabledDatePicker: false,
      idDeadLinesSelected: value,
    });
  };

  loadDeliveryDeadLines = (value, event) => {
    const { deliveryDeadlinesList } = this.state;
    const deadLines = deliveryDeadlinesList.filter(
      (deliveryDeadlines) => deliveryDeadlines.typeCommande == value
    );
    this.setState({ deliveryDeadlinesListFiltred: deadLines });
  };

  characterCountState = (value) => {
    this.setState({ characterCount: value });
  };

  characterCountRefState = (value) => {
    this.setState({ characterCountRef: value });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  handleOk = () => {
    const { form, intl, refresh, userRole } = this.props;
    const isContrat = 0;

    this.setState({
      confirmLoading: true,
    });

    form.validateFields(async (err, values) => {
      if (err) {
        this.setState({
          confirmLoading: false,
        });
        return;
      }
      const { isSuccess, item, isUnauthorized } = await createOrder(
        values.idClient,
        values,
        this.state.deliveryDate,
        isContrat
      );
      const id = isSuccess ? item.numCommande : null;
      if (isSuccess) {
        refresh();
        this.setState({
          visible: false,
          confirmLoading: false,
        });
        message.success(
          intl.formatMessage(
            {
              id: "order.new.success",
            },
            { id }
          ),
          5
        );
        return;
      }
      Modal.error({
        title: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.title" })
          : intl.formatMessage({
              id: "order.new.error",
            }),
        content: isUnauthorized
          ? intl.formatMessage({ id: "error.unauthorized.message" })
          : intl.formatMessage({
              id: "order.new.errorMessage",
            }),
      });
      this.setState({
        visible: false,
        confirmLoading: false,
      });
    });
  };

  render() {
    const {
      visible,
      confirmLoading,
      concessionaireFiltredUsers,
      deliveryDeadlinesListFiltred,
      mainAddressId,
      addresses,
      orderTypeList,
      guidClientToSend,
      isFetching,
      dateMinOrder,
      dateMaxOrder,
      disabledDatePicker,
      characterCount,
      characterCountRef,
    } = this.state;
    const { form, userRole, intl } = this.props;
    const { getFieldDecorator } = form;

    return (
      <div>
        <Divider style={{width:"auto", minWidth:"auto"}}/>
        <Button className="generate-button" onClick={this.generateModal}>
          <FormattedMessage id="orders.new" />
        </Button>
        <Modal
          title={<FormattedMessage id="order.new.modal" />}
          onOk={this.handleOk}
          visible={visible}
          onCancel={this.handleCancel}
          confirmLoading={confirmLoading}
          width="80%"
        >
          <Fragment>
            {/* Order's life cycle */}
            <OrderStepper stepIndex={0} />

            {/* Customer selection form item -> only superAdmin */}
            {isSuperAdmin({ userRole }) && (
              <SelectCustomer getFieldDecorator={getFieldDecorator} />
            )}

            {/* Client number form item */}
            <FormItem
              label={
                <Fragment>
                  <FormattedMessage id="order.modal.clientRef" />(
                  <span className="infoClient">
                    <FormattedMessage id="order.modal.infoClientRef" />
                  </span>
                  ) ({characterCountRef} / 20)
                </Fragment>
              }
            >
              {getFieldDecorator("numCommandeClient", {
                rules: [
                  {
                    max: 20,
                    message: intl.formatMessage(
                      { id: "user.form.commentMaxCarac" },
                      { max: 20 }
                    ),
                  },
                  {
                    pattern: new RegExp(REGEX_ORDER_REF),
                    message: (
                      <FormattedMessage
                        id="form.validator.invalidOrderRef"
                        defaultMessage="Only numbers, letters from a to z as well as the - and space are allowed"
                      />
                    ),
                  },
                ],
              })(
                <Input
                  style={{ width: "40%" }}
                  onChange={(e) =>
                    this.characterCountRefState(e.target.value.length)
                  }
                  maxLength={20}
                />
              )}
            </FormItem>

            <div className="deliveryDate-inline">
              <FormItem label={<FormattedMessage id="order.modal.orderType" />}>
                {getFieldDecorator("typeCommandeId", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="user.form.orderTypeRequired" />
                      ),
                    },
                  ],
                })(
                  <Select
                    style={{ width: "100%" }}
                    onSelect={(value, event) =>
                      this.loadDeliveryDeadLines(value, event)
                    }
                  >
                    {orderTypeList.map((orderType) => (
                      <Option key={orderType.id}>
                        {orderType.libelleTypeByLangUser}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>
              <FormItem
                label={<FormattedMessage id="order.modal.deliveryDeadlines" />}
              >
                {getFieldDecorator("delaiLivraisonId", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="user.form.deliveryDeadlinesRequired" />
                      ),
                    },
                  ],
                })(
                  <Select
                    disabled={!deliveryDeadlinesListFiltred.length > 0}
                    style={{ width: "200px" }}
                    onSelect={(value, event) =>
                      this.updateDateMinMax(value, event)
                    }
                  >
                    {deliveryDeadlinesListFiltred.map((deliveryDeadlines) => (
                      <Option key={deliveryDeadlines.id}>
                        {deliveryDeadlines.libelleByLangUser}
                      </Option>
                    ))}
                  </Select>
                )}
              </FormItem>

              <FormItem
                label={<FormattedMessage id="order.modal.deliveryDate" />}
              >
                {getFieldDecorator("dateLivraison", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="user.form.deliveryDateRequired" />
                      ),
                    },
                  ],
                })(
                  <DatePicker
                    disabled={disabledDatePicker}
                    disabledDate={(d) =>
                      !d ||
                      d.isAfter(datePlus6Month) ||
                      d.isAfter(dateMaxOrder) ||
                      d.isSameOrBefore(dateMinOrder) ||
                      moment(d).day() === 0 ||
                      moment(d).day() === 6
                    }
                    format="DD/MM/YY"
                    onChange={(date, dateString) => {
                      this.setState({
                        deliveryDate: moment(date).format("YYYY-MM-DD"),
                      });
                    }}
                  />
                )}
              </FormItem>
            </div>

            <div className="information-inline">
              <FormItem
                label={
                  <Fragment>
                    <FormattedMessage id="order.modal.comment" />(
                    {characterCount} / 70)
                  </Fragment>
                }
              >
                {getFieldDecorator("commentaire", {
                  rules: [
                    {
                      max: 70,
                      message: intl.formatMessage(
                        { id: "user.form.commentMaxCarac" },
                        { max: 70 }
                      ),
                    },
                    {
                      pattern: new RegExp(REGEX_ORDER_REF),
                      message: (
                        <FormattedMessage
                          id="form.validator.invalidOrderRef"
                          defaultMessage="Only numbers, letters from a to z as well as the - and space are allowed"
                        />
                      ),
                    },
                  ],
                })(
                  <Input
                    onChange={(e) =>
                      this.characterCountState(e.target.value.length)
                    }
                    maxLength={70}
                  />
                )}
              </FormItem>
              <FormItem
                label={<FormattedMessage id="order.modal.reliquat" />}
                style={{ margin: 0 }}
              >
                {getFieldDecorator("reliquat", {
                  valuePropName: "checked",
                  initialValue: false,
                })(<Switch />)}
              </FormItem>
            </div>

            <Divider></Divider>

            <Alert
              message={
                <FormattedHTMLMessage id="user.form.deliveryAddresseInfo" />
              }
              type="info"
            />

            <FormItem
              label={<FormattedMessage id="order.modal.customerSelect" />}
            >
              {getFieldDecorator("selectUser", {
                initialValue: guidClientToSend,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="user.form.customerSelectRequired" />
                    ),
                  },
                ],
              })(
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  notFoundContent={isFetching ? <Spin size="small" /> : null}
                  onSearch={debounce(this.onSearch, 600)}
                  filterOption={false}
                  onChange={(key, value) => this.onChangeClient(key, value)}
                >
                  {concessionaireFiltredUsers.map((concessionaireUser) => (
                    <Option
                      key={concessionaireUser.guIdClient}
                      value={concessionaireUser.guIdClient}
                    >
                      {concessionaireUser.nomClient} (
                      {concessionaireUser.numeroClientEureka})
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>

            <FormItem
              label={<FormattedMessage id="order.modal.deliveryAddresse" />}
            >
              {getFieldDecorator("dynamicsAdresseGuid", {
                initialValue: mainAddressId,
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="user.form.deliveryAddresseRequired" />
                    ),
                  },
                ],
              })(
                <Select
                  style={{ width: "100%" }}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) =>
                    option.props.children
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.props.value
                      .toString()
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {addresses.map((deliveryAddresses) => (
                    <Option
                      key={deliveryAddresses.customerAddressId}
                      value={deliveryAddresses.customerAddressId}
                    >
                      {deliveryAddresses.addressTypeLabel}{" "}
                      {deliveryAddresses.name
                        ? " - " + deliveryAddresses.name + " - "
                        : ""}
                      ({deliveryAddresses.line1 + " "}
                      {deliveryAddresses.line2
                        ? "," + deliveryAddresses.line2 + " "
                        : ""}
                      {deliveryAddresses.line3
                        ? "," + deliveryAddresses.line3 + " "
                        : ""}
                      - {deliveryAddresses.city} )
                    </Option>
                  ))}
                </Select>
              )}
            </FormItem>
            <RequiredFieldLegend />
          </Fragment>
        </Modal>
      </div>
    );
  }
}

CreateOrder.propTypes = {
  userRole: PropTypes.array.isRequired,
  refresh: PropTypes.func.isRequired,
  intl: intlShape.isRequired,
  onChange: PropTypes.func,
  form: PropTypes.shape({
    getFieldsValue: PropTypes.func,
    getFieldDecorator: PropTypes.func,
  }),
};

const mapStateToProps = ({ signIn: { userRole, signIn, clientID } }) => ({
  userRole,
  signIn,
  clientID,
});

export default connect(mapStateToProps)(injectIntl(Form.create()(CreateOrder)));
