import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faAddressBook } from "@fortawesome/free-solid-svg-icons";
import {
  FormattedMessage,
  FormattedHTMLMessage,
  injectIntl,
  intlShape,
} from "react-intl";
import {
  Button,
  Divider,
  Form,
  Modal,
  message,
  notification,
  Input,
  InputNumber,
  Table,
} from "antd";
import {
  searchArticles,
  searchCodeArticlesByName,
  updateLineInOrder,
  addLineToOrder,
} from "../../utils/apiBucherVaslin";
import FormattedCurrency from "../utils/FormattedCurrency";
import { isGroupEBV, isSuperAdmin } from "../../utils/helpers";
import "./SearchArticles.less";

const { Search } = Input;
const { Item: FormItem } = Form;

class SearchArticles extends Component {
  constructor(props) {
    super(props);

    this.state = {
      articles: [],
      refArticles: [],
      searchArticle: "",
      visible: false,
      visibleSearchName: false,
      loading: false,
      loadingRef: false,
      addArticleSpin: false,
    };
  }

  onCancel() {
    this.setState({ visible: false });
  }
  onCancelSearchName() {
    this.setState({ visibleSearchName: false });
  }

  addRefArticles(value) {
    this.setState({ visibleSearchName: false });
    this.props.form.setFieldsValue({ searchArticle: value });
    this.searchArticle(value);
  }

  searchRefArticle = async (value) => {
    const { intl } = this.props;
    // Load articles only if search field length > 3
    if (value && value.length > 2) {
      // Load cities corresponding to the selected country
      await this.setState({ loadingRef: true });

      const { refArticles, isSuccess, isUnauthorized, errorMessage, errorDetail } =
        await searchCodeArticlesByName(value);
      if (!isSuccess) {
        notification.error({
          message: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.title" })
            : intl.formatMessage({ id: "common.error" }),
          description: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.message" })
            : errorDetail,
        });
      }
      await this.setState({ refArticles: refArticles, loadingRef: false });
    }
  };

  searchArticle = async (value) => {
    const { intl, numCommande } = this.props;
    // Load articles only if search field length > 3
    if (value && value.length > 2) {
      // Load cities corresponding to the selected country
      await this.setState({ loading: true });

      const { articles, isSuccess, isUnauthorized, errorMessage, errorDetail } =
        await searchArticles(value, numCommande);
      if (!isSuccess) {
        notification.error({
          message: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.title" })
            : intl.formatMessage({ id: "common.error" }),
          description: isUnauthorized
            ? intl.formatMessage({ id: "error.unauthorized.message" })
            : errorDetail,
        });
      }
      await this.setState({
        articles: articles,
        loading: false,
        searchArticle: value,
      });
    }
  };

  addArticles = async () => {
    const { articles } = this.state;
    const {
      intl,
      numCommande,
      form: { getFieldValue },
      lines,
      onSubmit,
    } = this.props;
    const selectedOrder = numCommande;
    this.setState({ addArticleSpin: true });
    for (let i = 0; i < articles.length; i++) {
      const quantiteArticle = getFieldValue(
        "quantity_" + articles[i].codeArticle
      );
      if (quantiteArticle > 0) {
        const acctualCodeArticle = articles[i].codeArticle;
        // const existingArticle = lines.find(
        //   (article) => article.codeArticle === acctualCodeArticle
        // );
        // if (existingArticle) {
        //   const { isSuccess, isUnauthorized, errorMessage, errorDetail } =
        //     await updateLineInOrder(
        //       selectedOrder,
        //       existingArticle,
        //       quantiteArticle
        //     );
        //   if (!isSuccess) {
        //     notification.error({
        //       message: isUnauthorized
        //         ? intl.formatMessage({ id: "error.unauthorized.title" })
        //         : intl.formatMessage({ id: "common.error" }),
        //       description: isUnauthorized
        //         ? intl.formatMessage({ id: "error.unauthorized.message" })
        //         : errorDetail,
        //     });
        //   } else {
        //     message.success(
        //       intl.formatMessage(
        //         {
        //           id: "order.article.update",
        //         },
        //         { selectedOrder, acctualCodeArticle }
        //       ),
        //       4
        //     );
        //   }
        // } else {
          const { isSuccess, isUnauthorized, errorMessage, errorDetail } =
            await addLineToOrder(selectedOrder, articles[i], quantiteArticle);
          if (!isSuccess) {
            notification.error({
              message: isUnauthorized
                ? intl.formatMessage({ id: "error.unauthorized.title" })
                : intl.formatMessage({ id: "common.error" }),
              description: isUnauthorized
                ? intl.formatMessage({ id: "error.unauthorized.message" })
                : errorDetail,
            });
          } else {
            message.success(
              intl.formatMessage(
                {
                  id: "order.article.add",
                },
                { selectedOrder, acctualCodeArticle }
              ),
              4
            );
          }
        // }
      }
    }
    this.setState({ visible: false, addArticleSpin: false });
    onSubmit();
  };

  updateQuantity = (rawId) => {
    const {
      form: { getFieldDecorator },
    } = this.props;
    return getFieldDecorator("quantity_" + rawId, {
      initialValue: 0,
    })(<InputNumber style={{ width: 50 }} min={0} />);
  };
  selectArticlePiece = (idArticle, articlePrix, libelle) => {
    const { onSubmitWarrantyPiece } = this.props;
    this.setState({ visible: false });
    onSubmitWarrantyPiece(idArticle, articlePrix, libelle);
  };
  getSearchedRefArticles = () => {
    const { intl } = this.props;

    let codeArticle = {
      title: intl.formatMessage({ id: "catalog.tablePdr.table.item" }),
      dataIndex: "codeArticle",
      fixed: "left",
      width: 90,
    };
    let libelle = {
      title: intl.formatMessage({ id: "catalog.tablePdr.table.designation" }),
      dataIndex: "libelle",
      width: 500,
    };
    let columns = [codeArticle, libelle];
    return columns;
  };
  getSearchedArticles = () => {
    const { intl, userRole, warrantyPiece, updateIncident } = this.props;

    let codeArticle = {
      title: intl.formatMessage({ id: "catalog.tablePdr.table.item" }),
      dataIndex: "codeArticle",
      fixed: "left",
      width: 90,
    };
    let libelle = {
      title: intl.formatMessage({ id: "catalog.tablePdr.table.designation" }),
      dataIndex: "libelle",
      width: 500,
    };
    let quantite = {
      title: intl.formatMessage({ id: "catalog.tablePdr.table.amount" }),
      dataIndex: "",
      render: (text, row, index) => {
        if (row.prix != 0) {
          return this.updateQuantity(row.codeArticle);
        }
        return {
          children: (
            <a>
              {" "}
              <FormattedHTMLMessage id="catalog.tablePdr.table.contactBV" />
            </a>
          ),
        };
      },
      width: 75,
    };
    let prixRemise = {
      title: intl.formatMessage({ id: "table.articles.prixRemise" }),
      dataIndex: "",
      render: (text, row, index) => {
        if (row.prix != 0) {
          return (
            <FormattedCurrency amount={row.prix} currency={row.codeDevise} />
          );
        }
      },
      width: 75,
    };
    let prixPublic = {
      title: intl.formatMessage({ id: "table.articles.prixPublic" }),
      dataIndex: "",
      render: (text, row, index) => {
        if (row.prix != 0) {
          return (
            <FormattedCurrency
              amount={row.prixPublic}
              currency={row.codeDevise}
            />
          );
        }
      },
      width: 75,
    };
    let action = {
      key: "action",
      title: "Action",
      render: (_, row) => {
        return (
          <Fragment>
            <Link to={`/partsUseCases/${row.codeArticle}`} target="_blank">
              <Button>
                <FontAwesomeIcon icon={faSearch} />
              </Button>
            </Link>
          </Fragment>
        );
      },
      width: 30,
    };

    let columns = [];
    if (warrantyPiece || updateIncident) {
      columns = [codeArticle, libelle];
    } else {
      isGroupEBV({ userRole }) || isSuperAdmin({ userRole })
        ? (columns = [
            codeArticle,
            libelle,
            quantite,
            prixRemise,
            prixPublic,
            action,
          ])
        : (columns = [codeArticle, libelle, quantite, prixPublic, action]);
    }
    return columns;
  };
  render() {
    const {
      articles,
      refArticles,
      visible,
      visibleSearchName,
      loading,
      loadingRef,
      addArticleSpin,
      searchArticle,
    } = this.state;
    const {
      intl,
      pieceSelected,
      form: { getFieldDecorator, getFieldValue },
      updateIncident,
      warrantyPiece,
    } = this.props;
    const valueToSearch = getFieldValue("searchArticle");
    const columns = this.getSearchedArticles();
    const columnsRef = this.getSearchedRefArticles();
    return (
      <Fragment>
        <Modal
          title={<FormattedMessage id="order.search" />}
          visible={visible}
          onCancel={() => this.onCancel()}
          onOk={
            valueToSearch === searchArticle
              ? () => this.addArticles()
              : () => this.searchArticle(valueToSearch)
          }
          okButtonProps={{
            disabled: valueToSearch ? valueToSearch.length < 3 : true,
            loading: addArticleSpin,
            style:
              warrantyPiece || updateIncident
                ? { display: "none" }
                : { display: "inline" },
          }}
          okText={
            valueToSearch === searchArticle ? (
              <FormattedMessage id="order.modal.title" />
            ) : (
              <FormattedMessage id="order.search" />
            )
          }
          cancelText={<FormattedMessage id="common.cancel" />}
          width="80%"
        >
          <Form>
            <div className="inlineSearch">
              <FormItem>
                {getFieldDecorator("searchArticle", {
                  rules: [
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="order.search.searchRequired" />
                      ),
                    },
                    {
                      min: 3,
                      message: intl.formatMessage(
                        { id: "order.search.miniCharRequired" },
                        { min: 3 }
                      ),
                    },
                  ],
                })(
                  <Search
                    loading={loading}
                    placeholder={intl.formatMessage({
                      id: "order.search.placeholder",
                    })}
                    onSearch={this.searchArticle}
                  />
                )}
              </FormItem>
            </div>
            <div className="inline">
              <Button
                onClick={() => this.setState({ visibleSearchName: true })}
                disabled={loading || loadingRef}
              >
                <FontAwesomeIcon
                  icon={faAddressBook}
                  style={{ marginRight: "0.5em" }}
                />
                <FormattedMessage id="order.searchName" />
              </Button>
            </div>
          </Form>
          <Divider />
          <Table
            columns={columns}
            dataSource={articles}
            loading={loading}
            className="custom-table"
            rowKey="codeArticle"
            pagination={false}
            scroll={{ y: "50vh", x: "80vh" }}
            onRow={
              warrantyPiece || updateIncident
                ? (record, rowIndex) => {
                    return {
                      onClick: (event) => {
                        this.selectArticlePiece(
                          record.codeArticle,
                          record.prix,
                          record.libelle
                        );
                      }, // click row (only for warranty piece)
                    };
                  }
                : ""
            }
          />
        </Modal>
        {/* MODAL DE RECHERCHE PAR NOM  */}
        <Modal
          title={<FormattedMessage id="order.searchName" />}
          visible={visibleSearchName}
          onCancel={() => this.onCancelSearchName()}
          okButtonProps={{ style: { display: "none" } }}
          cancelText={<FormattedMessage id="common.cancel" />}
          width="60%"
        >
          <Form>
            <FormItem>
              {getFieldDecorator("searchRefArticle", {
                rules: [
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="order.search.searchRequired" />
                    ),
                  },
                  {
                    min: 3,
                    message: intl.formatMessage(
                      { id: "order.search.miniCharRequired" },
                      { min: 3 }
                    ),
                  },
                ],
              })(
                <Search
                  loading={loadingRef}
                  onSearch={this.searchRefArticle}
                  placeholder={intl.formatMessage({
                    id: "order.search.placeholder",
                  })}
                  style={{ width: "60%" }}
                />
              )}
            </FormItem>
          </Form>
          <Divider />
          <Table
            columns={columnsRef}
            dataSource={refArticles}
            loading={loadingRef}
            className="custom-table"
            rowKey="codeArticle"
            pagination={false}
            scroll={{ y: "50vh", x: "80vh" }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  this.addRefArticles(record.codeArticle);
                }, // click row
              };
            }}
          />
        </Modal>
        {pieceSelected && pieceSelected.length > 0 ? (
          updateIncident ? (
            <Input
              type="text"
              value={pieceSelected}
              onClick={() => this.setState({ visible: true })}
            />
          ) : (
            <span onClick={() => this.setState({ visible: true })}>
              {pieceSelected}
            </span>
          )
        ) : (
          <Button onClick={() => this.setState({ visible: true })}>
            <FontAwesomeIcon icon={faSearch} style={{ marginRight: "0.5em" }} />
            <FormattedMessage id="order.search" />
          </Button>
        )}
      </Fragment>
    );
  }
}

SearchArticles.propTypes = {
  onSubmit: PropTypes.func,
  onSubmitWarrantyPiece: PropTypes.func,
  pieceSelected: PropTypes.string,
  form: PropTypes.shape({
    getFieldDecorator: PropTypes.func.isRequired,
  }).isRequired,
  intl: intlShape.isRequired,
  numCommande: PropTypes.string,
  lines: PropTypes.array,
  userRole: PropTypes.array.isRequired,
  warrantyPiece: PropTypes.bool,
  updateIncident: PropTypes.bool,
};

const mapStateToProps = ({ signIn: { userRole } }) => ({
  userRole,
});

export default connect(mapStateToProps)(
  injectIntl(Form.create()(SearchArticles))
);
