import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PageLayout from '../Layout/PageLayout';
import { fetchIncidentHistoryByYear } from '../../utils/apiBucherVaslin';
import { isInfoCompleted, compareNumber } from '../../utils/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAward, faQuestionCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { Button, Input, notification, Select, Switch, Table,  } from 'antd';
import { injectIntl, intlShape, FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
    alphabetically,
    compareDate,
} from '../../utils/helpers';
import IncidentInfo from './IncidentInfo';
import BonImpression from './BonImpression';

const mongoDateFormat = 'YYYY-MM-DD';
const renderedDate = 'DD/MM/YY';
const acctualYears = new Date().getFullYear();
const years = Array.from(new Array(10),( val, index) =>acctualYears - index );
const { Option } = Select;

const renderDate = (date) => (date ? <span>{moment(date).format(renderedDate)}</span> : '-');
class Incidents extends Component {
    constructor(props){
        super(props);
        this.state = {
            incidents: [],
            loadIncident: false,
            yearSelected: acctualYears    
        };
    }
     
    async componentDidMount() {
        const { intl, userID } = this.props;
        /* check connexion and information */ 
        await isInfoCompleted(intl, userID);    
        this.fetchIncidentsByYear()
    }
    /**
     * Method used to get the list of incidents by selected year ( current by default)
     */
    fetchIncidentsByYear = async () => {
        const { intl } = this.props;
        const { yearSelected } = this.state;
        this.setState({loadIncident: true});

        const {incidents, isSuccess, isUnauthorized, errorMessage, errorDetail} = await fetchIncidentHistoryByYear(yearSelected);
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
            this.setState({ loadIncident: false });
            return;
        }
        console.log(incidents);
        this.setState({incidents, loadIncident: false});
        // const test = incidents.filter(a => a.demandeGarantie == true);
        // console.log(test);
        //CAS-05448-00000 / CAS-01415-00000 / CAS-22472-V6Q2V1
        //CAS-22472-V6Q2V1
    }

    changeYearsIncidents =  async (year) => {
        await this.setState({ yearSelected: year});

        await this.fetchIncidentsByYear();
    }
    getColumns () { 
        let columns = [
            {
                key: 'demandeGarantie',
                sorter: (a, b) => compareNumber(a.demandeGarantie, b.demandeGarantie),
                render: (_, record) => (<Fragment>
                {record.demandeGarantie === true && <FontAwesomeIcon icon={faAward} style={{marginRight: "0.5em", color: 'green'}}/>}
                {record.demandeGarantie === false ?
                record.codeMotif === 100000001 ? <FontAwesomeIcon icon={faExclamationTriangle} style={{marginRight: "0.5em", color: '#fab907'}}/> :
                record.codeMotif === 100000016 ? <FontAwesomeIcon icon={faQuestionCircle} style={{marginRight: "0.5em", color: '#09a3db'}}/> :''
                : ""}
                </Fragment>),
                width: 1
            },
            {
                dataIndex: 'ticketNumber',
                key: 'ticketNumber',
                title: <FormattedMessage id={`table.incidents.ticketNumber`} />,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.ticketNumber),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.ticketNumber, b.ticketNumber)
            },
            {
                dataIndex: 'statusCodeLabel',
                key: 'status',
                title: <FormattedMessage id={`table.incidents.status`} />,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.statusCodeLabel),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.statusCodeLabel, b.statusCodeLabel),
                render: (_, record) => (record.demandeGarantie === true ? record.etatPriseEnChargeLabel : record.statusCodeLabel)
            },
            {
                dataIndex: 'createdOn',
                key: 'createdOn',
                title: <FormattedMessage id={`table.incidents.createdOn`} />,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, moment(record.createdOn, mongoDateFormat).format(renderedDate)),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => compareDate(a.createdOn, b.createdOn, mongoDateFormat),
                render: renderDate
            },
            {
                dataIndex: 'priorityCodeLabel',
                key: 'priority',
                title: <FormattedMessage id={`table.incidents.priority`} />,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.priorityCodeLabel),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.priorityCodeLabel, b.priorityCodeLabel)
            },
            {
                dataIndex: 'clientConcerne',
                key: 'client',
                title: <FormattedMessage id={`table.incidents.client`} />,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.clientConcerne),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.clientConcerne, b.clientConcerne)
            },
            {
                dataIndex: 'numeroAppareil',
                key: 'appareil',
                title: <FormattedMessage id={`table.incidents.appareil`} />,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.numeroAppareil),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.numeroAppareil, b.numeroAppareil)
            },
            {
                dataIndex: 'techContact',
                key: 'contact',
                title: <FormattedMessage id={`table.incidents.contact`} />,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.techContact),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.techContact, b.techContact)
            },
            {
                dataIndex: 'categorie.new_name',
                key: 'category',
                title: <FormattedMessage id={`table.incidents.category`} />,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.categorie ? record.categorie.new_name : ""),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                sorter: (a, b) => alphabetically(a.categorie ? a.categorie.new_name : "" , b.categorie? b.categorie.new_name : "")
            },
            {
                dataIndex: 'description',
                key: 'actions',
                title: <FormattedMessage id={`table.incidents.actions`} />,
                render: (_, record) => (
                    <Fragment> 
                        <IncidentInfo incident={record}/> {record.demandeGarantie === true ? <BonImpression techContact={record.techContact} incidentId={record.incidentId} etatPriseEnChargeLabel= {record.etatPriseEnChargeLabel}/> : ""}
                    </Fragment>
               )
            }
        ];
        return columns;
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    onFilter = (value, obj) => {
        if (obj === true || obj === false) {
            return obj == value;
        }
        return obj && obj.toLowerCase().includes(value.toLowerCase()); 
    };

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    render() {
        const { incidents, loadIncident, yearSelected } = this.state;

        return(
            <PageLayout pageKey="incidents">
                <div>                 
                    <div>
                        <h2>
                            <FormattedMessage id={`table.incidents.title`} />
                        </h2>
                        <div style={{marginBottom:'1em'}}>
                        <label><b><FormattedMessage id={`table.incidents.title`} /> : </b></label>
                            <Select
                                    defaultValue={yearSelected}
                                    style={{width :'100px', marginLeft:'1em'}}
                                    onChange={(value) => { this.changeYearsIncidents(value)}}
                                    disabled={loadIncident}
                                >
                                {years.map((years) => (
                                    <Option key={years}>
                                        {years} 
                                    </Option>
                                ))}
                            </Select>
                        </div>            
                        <Table
                            className="custom-table"
                            dataSource={incidents}
                            columns={this.getColumns()}
                            rowKey="incidentId"
                            loading={loadIncident}
                        />
                        <FontAwesomeIcon icon={faAward} style={{marginRight: "0.7em", color: 'green'}}/> <FormattedMessage id={`table.incidents.legendWarranty`} />< br />
                        <FontAwesomeIcon icon={faExclamationTriangle} style={{marginRight: "0.5em", color: '#fab907'}}/> <FormattedMessage id={`table.incidents.legendIncident`} />< br />
                        <FontAwesomeIcon icon={faQuestionCircle} style={{marginRight: "0.5em", color: '#09a3db'}}/> <FormattedMessage id={`table.incidents.legendRequest`} />
                    </div>
                </div>
            </PageLayout>
        )
    } 
}

Incidents.propTypes = {
    intl: intlShape.isRequired,
    locale: PropTypes.string
}

const mapStateToProps = ({
    app: {
        locale,
        userID
    }
}) => ({ locale, userID });

export default connect(mapStateToProps)(injectIntl(Incidents));
