import _, { filter } from 'lodash';

const getRootKey = (__, key) => (isNaN(key) ? key : `[${key}]`);

const getChildKey = (prop, key) => (isNaN(key) ? `${prop}.${key}` : `${prop}[${key}]`);

// Permet de retransformer une valeur envoyée en équivalent string
export function flatten(data, prop) {
    const getKey = prop ? getChildKey : getRootKey;

    // If entry data is a boolean we change to string data (1 or 0) for coherence and fix bug 
    // (device don't support value true or false except if in uppercase)
    if (!_.isObject(data) && data === true){
        data = "1";
    }
    else if (!_.isObject(data) && data === false){
        data = "0";
    }

    return !_.isObject(data)
        ? { [prop]: data }
        : _.reduce(
            data, (result, v, k) => Object.assign({}, result, flatten(data[k], getKey(prop, k))), {}
        );
}