import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Alert, Button, Checkbox, Modal, Popover, message, notification, Form} from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PhoneInput from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { submitOrder, fetchOrder, fetchOrderType, fetchDeliveryDeadlines, getAddressById } from '../../utils/apiBucherVaslin';
import moment from 'moment';
import { Link } from 'react-router-dom';
import UpdateOrder from './UpdateOrder';
import './SubmitOrder.less';
class SubmitOrder extends Component {
    constructor(props) {
        super(props);

        this.state = {
            confirmationCheckbox: false,
            visible: false,
            step: 1,
            orderId: '',
            order: null,
            addressShow: '',
            typeShow: '',
            deliveryDeadlineShow: '',
            telephone:''
        };
    }

    fetchDataAsync = async(id) => {
        const { intl } = this.props;
        const { orderId } = this.state;
        if(id == null) {
            id = orderId;
        }
        const { items, isSuccess: isSuccessOrder, isUnauthorized, errorMessage, errorDetail } = await fetchOrder(id)();  
        if (isSuccessOrder){
            this.setState({ order: items.commande });
        }
        if (!isSuccessOrder){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }
            if (items.commande.dynamicsAdresseGuid) {
                this.fetchAddress(items.commande.dynamicsAdresseGuid);
            }
            if (items.commande.typeCommandeId) {
                this.initOrderType(items.commande.typeCommandeId);
            }
            if (items.commande.delaiLivraisonId) {
                this.initDeliveryDeadlines(items.commande.delaiLivraisonId);
        }      
    }
    fetchAddress = async(id) => {
        const { intl } = this.props; 
        const {addresses, isSuccess, isUnauthorized, errorMessage, errorDetail } = await getAddressById(id);
        if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
        }
        this.setState({ addressShow: addresses[0] }); 
        this.setState({ telephone: addresses[0].telephone1 }); 
    }

    initOrderType = async (id) => {
        const { intl } = this.props;
        const { orderType, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchOrderType();
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
            return;
        }
        const type = orderType.filter((type) => type.id === id); 
        await this.setState({typeShow: type[0].libelleTypeByLangUser});
    }
    initDeliveryDeadlines = async (id) => {
        const { intl } = this.props;
        const { deliveryDeadlines, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchDeliveryDeadlines();
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail
            });
            return;
        }
        const deliveryDeadline = deliveryDeadlines.filter((delivery) => delivery.id === id); 
        await this.setState({deliveryDeadlineShow: deliveryDeadline[0].libelleByLangUser});
    }

    // Method triggered on ok
    async handleOk() {
        const { intl, refresh } = this.props;
        const { orderId, telephone } = this.state;
        const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await submitOrder(orderId, telephone);

        if (isSuccess) {
            message.success(
                intl.formatMessage(
                    {
                        id: 'table.order.submit.success'
                    },
                    { id: orderId }
                ),
                5
            );

            refresh();
            this.setState({ visible: false, step: 1 });

            return;
        }
        if (!isSuccess) {
            {message.error(errorDetail, 5)}
            refresh();
            this.setState({ visible: false, step: 1 });

            return;
        }
        Modal.error({
            title: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.title' })
            : intl.formatMessage({
                id: 'table.order.submit.fail',
            }),
            content: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.message' })
            : errorDetail
        });

        this.setState({ visible: false, step: 1 });
    }
    
    nextStep() {
        this.setState({ step: 2});
    }

    // Method triggered on cancel
    handleCancel() {
        this.setState({ visible: false, step: 1 });
    }

    // Display transfert confirmation modal
    showSubmitModal() {
        const { id } = this.props;
        this.setState({ visible: true, orderId: id });
        this.fetchDataAsync(id);
    }

    // Update validation checkbox value
    async changeValidationCheck() {
        const { confirmationCheckbox } = this.state;
        await this.setState({confirmationCheckbox: !confirmationCheckbox})
    }

    // Method used to get informations about General Terms of Sales
    getGTS() {
        const { intl, locale } = this.props;
        const gts = { 
            label: intl.formatMessage({ id: 'gts.title' }),
            link: ""
        };

        switch(locale) {
            case "fr":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_french.pdf"
                break;
            case "en":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_english.pdf"
                break;
            case "de":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_german.pdf"
                break;
            case "es":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_spanish.pdf"
                break;
            case "it":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_italian.pdf"
                break;
            default :
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_english.pdf"
                break;
        }

        return gts;
    }

    render() {
        const { confirmationCheckbox, visible, step, order, orderId, addressShow, typeShow, deliveryDeadlineShow, telephone} = this.state;
        const { intl, isMontantMini} = this.props;

        const gts = (visible) ? this.getGTS() : null;

        return (
            <Fragment>
                <Popover content={<FormattedMessage id="table.order.submit" />}>
                    <Button htmlType="button" onClick={() => this.showSubmitModal()}>
                        <FontAwesomeIcon icon="paper-plane" />
                    </Button>
                </Popover>

                <Modal
                    visible={visible}
                    title={(
                        step == 1 ? 
                        intl.formatMessage(
                            { id: 'table.order.submit.information'} 
                    ): 
                        intl.formatMessage(
                            { id: 'table.order.submit.title'},
                            { id: orderId }
                    ))}
                    onOk={() => this.handleOk()}
                    onCancel={() => this.handleCancel()}
                    footer={[
                        <Button key="back" onClick={() => this.handleCancel()}>
                            <FormattedMessage id="common.no" />
                        </Button>,
                        step == 1 && (
                            <Button  key="nextStep" type="info" onClick={() => this.nextStep()}>
                                <FormattedMessage id="common.yes" />
                            </Button>                         
                        ), 
                        step == 2 && (
                        <Button key="submit" type="danger" disabled={!confirmationCheckbox} onClick={() => this.handleOk()}>
                          <FormattedMessage id="common.yes" />
                        </Button> )   
                    ]}
                >
                {step == 1 && (
                     <Fragment>
                        {isMontantMini && (
                            <Fragment>
                                <Alert message={<FormattedMessage id="order.detail.currentTotalAmount.warning" />} type="warning" showIcon/>
                                < br />
                            </Fragment>
                        )}
                        {order && order.reliquat == false && (
                            <Fragment>
                                <Alert message={<FormattedMessage id="order.detail.reliquat.info" />} type="info" showIcon/>
                                < br />
                            </Fragment>
                        )}
                        {order && (  
                            <Fragment> 
                                <div className='showInforOrder'>  
                                    <div>
                                        <label><FormattedMessage
                                            id="order.modal.deliveryAddresse"
                                        /> : </label>
                                        <span>
                                            <Fragment>
                                                    {addressShow.line1} 
                                                    {addressShow.line2 ? ", " + addressShow.line2 + " " : ' '}
                                                    {addressShow.line3 ? ", " + addressShow.line3 + " " : '' } 
                                                    ({addressShow.postalCode} {addressShow.city})
                                            </Fragment>
                                        </span>
                                    </div> 
                                    <div style={{display: "flex", flexDirection:"row", alignItems:"center"}}>
                                        <label><FormattedMessage
                                            id="order.modal.deliveryTelephone"
                                        /> : </label>
                                        <span>
                                                <PhoneInput
                                                    style={{margin:'4px'}}
                                                    title={intl.formatMessage({ id: "order.modal.deliveryTelephone.info" })} 
                                                    international={true}
                                                    value={telephone}
                                                    onChange={(phone) => {this.setState({telephone: phone})}}
                                                    flags={flags}
                                                    limitMaxLength={true}
                                                />
                                        </span>
                                    </div>  
                                    <div>
                                        <label><FormattedMessage
                                            id="order.modal.orderType"
                                        /> : </label>
                                        <span>{typeShow}</span>
                                    </div>  
                                    <div>
                                        <label><FormattedMessage
                                            id="order.modal.deliveryDeadlines"
                                        /> : </label>
                                        <span>{deliveryDeadlineShow}</span>
                                    </div>
                                    <div>
                                        <label><FormattedMessage
                                            id="order.modal.deliveryDate"
                                        /> : </label>
                                        <span>{order.dateLivraison ? moment(order.dateLivraison).format('DD/MM/YY') : ''}</span>
                                    </div> 
                                    <div>
                                        <label><FormattedMessage
                                            id="order.modal.reliquat"
                                        /> : </label>
                                        <span>{order.reliquat == true ?  <FormattedMessage id="common.yes" /> :  <FormattedMessage id="common.no" /> }</span>
                                    </div>
                                    <div>
                                        <label><FormattedMessage
                                            id="order.modal.clientRef"
                                        /> : </label>
                                        <span>{order.numCommandCustomer}</span>
                                    </div> 
                                    <div>
                                        <label><FormattedMessage
                                            id="order.modal.comment"
                                        /> : </label>
                                        <span>{order.commentaire}</span>
                                    </div> 
                                    
                                </div>   
                            </Fragment>
                        )}
                    {order &&
                        <div className='buttonUpdateOrder'>
                            <br />
                            <UpdateOrder refresh={() => this.fetchDataAsync()} order={order}> </UpdateOrder>  
                        </div>   
                    }
                    </Fragment>
                )}
                {step == 2 && (
                    <Fragment>
                        {isMontantMini && (
                            <Fragment>
                                <Alert message={<FormattedMessage id="order.detail.currentTotalAmount.warning" />} type="warning" showIcon/>
                                < br />
                            </Fragment>
                        )}
                        {order && order.reliquat == false && (
                            <Fragment>
                                <Alert message={<FormattedMessage id="order.detail.reliquat.info" />} type="info" showIcon/>
                                < br />
                            </Fragment>
                        )}
                        <span>
                            <Checkbox 
                                checked={confirmationCheckbox} 
                                onChange={ () => this.changeValidationCheck() }
                                style={{marginRight: "1em"}}
                            />
                            <FormattedMessage id="order.modal.transfert.confirm" />
                        </span>

                        {/* List of informations link */}
                        <ul style={{marginTop: "1em"}}>
                            <li>
                                {/* Link to CGU */}
                                <Link to="/cgu" target="_blank">
                                    <FormattedMessage id="cgu.title" />
                                </Link>
                            </li>

                            {/* Link to General Terms of Sales */}
                            {gts && (
                                <li>
                                    <a target="_blank" href={gts.link}>{gts.label}</a>
                                </li>
                            )}
                        </ul>
                    </Fragment>
                )}  
                </Modal>
            </Fragment>
        )
    }
}

SubmitOrder.propTypes = {
    intl: intlShape.isRequired,
    id: PropTypes.string.isRequired,
    locale: PropTypes.string.isRequired,
    refresh: PropTypes.func.isRequired,
    isMontantMini: PropTypes.bool.isRequired
};

const mapStateToProps = ({
    app: {
        locale,
    },
}) => ({ locale });

export default injectIntl(connect(mapStateToProps, {})(SubmitOrder));