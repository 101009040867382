import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Badge, Button, Divider, Input, notification, Table, Tooltip } from 'antd';
import moment from 'moment';
import { alphabetically, compareDate, isSuperAdmin, isGroupAdminClient } from '../../utils/helpers';
import { fetchAllDevices, fetchConnectedDevices, fetchUsers, getUsersForConcessionnaireClient } from '../../utils/apiBucherVaslin';
import DeleteLicense from './DeleteLicense';
import ModalGenerate from './ModalGenerate';
import SwapLicenseOwnership from './SwapLicenseOwnership';
import UpdateLicense from './UpdateLicense';

class LicensesList extends Component {
    state = {
        allMachines: null,
        allUsers: null,
        concessionaireUsers: null,
        dataSource: [],
        myMachines: null,
        myUsers: null,
    };

    componentDidMount = async () => {
        this.setDataSource();

        const { userRole } = this.props;
        if (isGroupAdminClient({ userRole })) {
            this.initSwapLicenseOwnershipModal();
        }
    }

    componentDidUpdate(prevProps) {
        const { licenses } = this.props;
        if (licenses !== prevProps.licenses) {
            this.setDataSource();
        }
    }

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    getActions = (refresh, userRole, allMachines, allUsers, concessionaireUsers, myMachines, myUsers) => [
        {
            key: 'actions',
            render: (_, { guid, isLicenseChild, isMultiUsers, duree, famille, idProfil, profil, ...props }) => (
                <span>
                    {(isGroupAdminClient({ userRole }) || isSuperAdmin({ userRole })) && (
                        <SwapLicenseOwnership
                            {...props}
                            guid={guid}
                            myMachines={myMachines}
                            allMachines={allMachines}
                            myUsers={myUsers}
                            allUsers={allUsers}
                            concessionaireUsers={concessionaireUsers}
                            refresh={refresh}
                            isLicenseChild={isLicenseChild}
                            isMultiUsers={isMultiUsers}
                            profil={profil}
                        />
                    )}
                    {!isLicenseChild && isMultiUsers && (
                        <Fragment>
                            <Divider type="vertical" />
                            <ModalGenerate refresh={refresh} parent={{ guid, duree, famille, idProfil }} />
                        </Fragment>
                    )}
                    {!isLicenseChild && isSuperAdmin({ userRole }) && (
                        <Fragment>
                            <Divider type="vertical" />
                            <UpdateLicense guid={guid} refresh={refresh} />
                        </Fragment>
                    )}
                    {/* A superAdmin can delete all licenses
                        A clientAdmin can delete only licenseChild
                        La confirmation est pour toutes les licences mères
                    */}
                    {isSuperAdmin({ userRole }) && (
                        <Fragment>
                            <Divider type="vertical" />
                            <DeleteLicense refresh={refresh} guid={guid} isLicenseChild={isLicenseChild} />
                        </Fragment>
                    )}
                    {(isGroupAdminClient({ userRole }) && !isSuperAdmin({ userRole }) && isLicenseChild) && (
                        <Fragment>
                        <Divider type="vertical" />
                        <DeleteLicense refresh={refresh} guid={guid} isLicenseChild={isLicenseChild} />
                    </Fragment>
                    )}
                </span>
            ),
        },
    ];

    getColumns = () => {
        const columns = [
            {
                dataIndex: 'guid',
                key: 'guid',
                sorter: (a, b) => alphabetically(a.guid, b.guid),
                render: (text) => (
                    <Tooltip title={text}>
                        <span>{`${text.substring(0, 10)}...`}</span>
                    </Tooltip>
                ),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.guid),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            },
            {
                dataIndex: 'profil',
                key: 'profil',
                sorter: (a, b) => alphabetically(a.profil, b.profil),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.profil),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            },
            {
                dataIndex: 'type',
                key: 'type',
                sorter: (a, b) => alphabetically(a.type, b.type),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.type),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            },
            {
                dataIndex: 'expirationDate',
                key: 'expiration',
                sorter: (a, b) => compareDate(a.expirationDate, b.expirationDate),
                filterDropdown: this.filterDropDown,
                onFilter: (value, { expirationDate }) => this.onFilter(value, expirationDate),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                render: (expirationDate) => {
                    const momentDate = moment(expirationDate, 'DD/MM/YYYY');
                    const inside = <span>{momentDate.format('DD/MM/YY')}</span>;

                    return moment().diff(momentDate, 'days') >= 0 ? <Badge dot>{inside}</Badge> : inside;
                },
            },
            {
                dataIndex: 'client.nomClient',
                key: 'clientName',
                sorter: (a, b) => alphabetically(a.client.nomClient, b.client.nomClient),
                filterDropdown: this.filterDropDown,
                onFilter: (value, { client: { nomClient } }) => this.onFilter(value, nomClient),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            },
        ];

        const { showUserColumn } = this.props;
        if (showUserColumn) {
            columns.push({
                dataIndex: 'user.nomPrenom',
                key: 'userName',
                sorter: (a, b) => alphabetically(a.user.nomPrenom, b.user.nomPrenom),
                filterDropdown: this.filterDropDown,
                onFilter: (value, { user: { nomPrenom } }) => this.onFilter(value, nomPrenom),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            });
        }

        columns.push({
            dataIndex: 'device.client.nomClient',
            key: 'machineClient',
            sorter: (a, b) => alphabetically(a.device.client.nomClient, b.device.client.nomClient),
            filterDropdown: this.filterDropDown,
            onFilter: (
                value,
                {
                    device: {
                        client: { nomClient },
                    },
                },
            ) => this.onFilter(value, nomClient),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },
        {
            dataIndex: 'device.name',
            key: 'machine',
            sorter: (a, b) => alphabetically(a.device.name, b.device.name),
            filterDropdown: this.filterDropDown,
            onFilter: (value, { device: { name } }) => this.onFilter(value, name),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },
        {
            dataIndex: 'device.serial',
            key: 'machineSerial',
            sorter: (a, b) => alphabetically(a.device.serial, b.device.serial),
            filterDropdown: this.filterDropDown,
            onFilter: (value, { device: { serial } }) => this.onFilter(value, serial),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        },
        {
            dataIndex: 'device.type',
            key: 'machineType',
            sorter: (a, b) => alphabetically(a.device.type, b.device.type),
            filterDropdown: this.filterDropDown,
            onFilter: (value, { device: { type } }) => this.onFilter(value, type),
            onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
        });

        return columns;
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    handleSearch = (confirm) => () => {
        confirm();
    };

    initSwapLicenseOwnershipModal = async () => {
        const { clientID, intl, userRole } = this.props;

        const { items: myMachines, isSuccess: isSuccessConnectDevice
            , isUnauthorized: isUnauthorizedConnectDevice
            , errorDetail: errorMessageConnectDevice } = await fetchConnectedDevices()();

            if (!isSuccessConnectDevice){
                notification.error({
                    message: isUnauthorizedConnectDevice 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorizedConnectDevice 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorMessageConnectDevice,
                });
            }

        const showAllDevices = isSuperAdmin({ userRole }) ? true : false;
        const { items: allMachines, isSuccess: isSuccessAllDevice
            , isUnauthorized: isUnauthorizedAllDevice
            , errorDetail: errorMessageAllDevice } = await fetchAllDevices(showAllDevices, true);

            if (!isSuccessAllDevice){
                notification.error({
                    message: isUnauthorizedAllDevice 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorizedAllDevice 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorMessageAllDevice,
                });
            }

        const orderAllMachines = isSuccessAllDevice && allMachines ? allMachines.sort(
            ({ client: { nomClient: nomClientA } }, { client: { nomClient: nomClientB } }) => {
                if (nomClientA < nomClientB) {
                    return -1;
                }

                if (nomClientA > nomClientB) {
                    return 1;
                }

                return 0;
            },
        ) : [];

        const {items: myUsers, isSuccess: isSuccessMyUsers
            , isUnauthorized: isUnauthorizedMyUsers
            , errorDetail: errorMessageMyUsers } = await fetchUsers({}, clientID);

        if (!isSuccessMyUsers){
            notification.error({
                message: isUnauthorizedMyUsers 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedMyUsers 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageMyUsers,
            });
        }

        const {items: allUsers, isSuccess: isSuccessAllUser
            , isUnauthorized: isUnauthorizedAllUser
            , errorDetail: errorMessageAllUser } = await fetchUsers();

        if (!isSuccessAllUser){
            notification.error({
                message: isUnauthorizedAllUser 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedAllUser 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageAllUser,
            });
        }

        const { items: concessionaireUsers, isSuccess: isSuccessUserConcCli
            , isUnauthorized: isUnauthorizedUserConcCli, errorDetail: errorMessageUserConcCli }
        = await getUsersForConcessionnaireClient();

        if (!isSuccessUserConcCli){
            notification.error({
                message: isUnauthorizedUserConcCli 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedUserConcCli 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageUserConcCli,
            });
        }

        const orderAllUsers = isSuccessAllUser && allUsers ? allUsers.sort(
            ({ client: { nomClient: nomClientA } }, { client: { nomClient: nomClientB } }) => {
                if (nomClientA < nomClientB) {
                    return -1;
                }
                if (nomClientA > nomClientB) {
                    return 1;
                }

                return 0;
            },
        ) : [];

        this.setState({
            allMachines: orderAllMachines,
            allUsers: orderAllUsers,
            concessionaireUsers: isSuccessUserConcCli ? concessionaireUsers : null,
            myMachines: myMachines,
            myUsers: myUsers,
        });
    };

    onFilter = (value, obj) => obj && obj.toLowerCase().includes(value.toLowerCase());

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    setDataSource = () => {
        const { licenses } = this.props;
        const dataSource = licenses && licenses.map((license) => {
            const date = moment(license.dateCreation);

            const delta = parseInt(license.duree, 10);

            if (1 === Math.sign(delta)) {
                date.add(Math.abs(delta), 'month');
            } else {
                date.subtract(Math.abs(delta), 'month');
            }

            return { ...license, expirationDate: date.format('DD/MM/YYYY') };
        });

        this.setState({ dataSource });
    };

    render() {
        const { loading, refresh, userRole } = this.props;
        const { allMachines, allUsers, concessionaireUsers, dataSource, myMachines, myUsers } = this.state;

        const actions = this.getActions(refresh, userRole, allMachines, allUsers, concessionaireUsers, myMachines, myUsers);
        const columns = isGroupAdminClient({ userRole })
            ? [...this.getColumns(), ...actions]
            : this.getColumns();

        const translatedColumns = columns.map(({ key, ...others }) => ({
            ...others,
            key,
            title: <FormattedMessage id={`table.licenses.${key}`} />,
        }));

        return (
            <Table
                className="custom-table"
                dataSource={dataSource}
                columns={translatedColumns}
                rowKey="guid"
                loading={loading}
                scroll={{ x: true }}
            />
        );
    }
}

LicensesList.propTypes = {
    clientID: PropTypes.string.isRequired,
    licenses: PropTypes.arrayOf(PropTypes.shape({})),//.isRequired,
    loading: PropTypes.bool.isRequired,
    refresh: PropTypes.func.isRequired,
    showUserColumn: PropTypes.bool,
    userRole: PropTypes.array,
    intl: intlShape.isRequired,
};

LicensesList.defaultProps = {
    showUserColumn: false,
};

const mapStateToProps = ({ signIn: { clientID, userRole } }) => ({
    clientID,
    userRole,
});

export default injectIntl(connect(mapStateToProps)(LicensesList));
