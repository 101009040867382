import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Modal, Popover, Icon, InputNumber, message } from 'antd';
import { updateLicense } from '../../utils/apiBucherVaslin';

class UpdateLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
            expiration: null,
        };
    }

    handleOk = async () => {
        const { guid, refresh, intl } = this.props;
        const { expiration } = this.state;

        this.setState({
            confirmLoading: true,
        });
        const { isSuccess, isUnauthorized } = await updateLicense(guid, expiration);
        if (isSuccess) {
            refresh();
            this.setState({
                visible: false,
                confirmLoading: false,
            });
            message.success(
                intl.formatMessage({
                    id: 'licenses.update.success',
                }),
            );

            return;
        }
        
        Modal.error({
            title: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.title' })
            : intl.formatMessage({
                id: 'licenses.update.errorTitle',
            }),
            content: isUnauthorized
            ? intl.formatMessage({ id: 'error.unauthorized.message' })
            : intl.formatMessage({
                id: 'licenses.update.error',
            }),
        });
        this.setState({
            visible: false,
            confirmLoading: false,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            confirmLoading: false,
        });
    };

    generateModal = () => {
        this.setState({
            visible: true,
        });
    };

    changeExpiration = (value) => {
        this.setState({
            expiration: value,
        });
    };

    render() {
        const { intl } = this.props;
        const { visible, confirmLoading } = this.state;

        return (
            <Fragment>
                <Popover content={<FormattedMessage id="licenses.update.button" />}>
                    <Button htmlType="button" onClick={this.generateModal}>
                        <Icon type="form" />
                    </Button>
                </Popover>
                <Modal
                    title={<FormattedMessage id="licenses.update.title" />}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    visible={visible}
                    confirmLoading={confirmLoading}
                >
                    <FormattedMessage id="licenses.update.expiration" />
                    <InputNumber
                        required
                        className="input-number"
                        placeholder={intl.formatMessage({
                            id: 'licenses.update.placeholder',
                        })}
                        size="large"
                        onChange={this.changeExpiration}
                    />
                </Modal>
            </Fragment>
        );
    }
}

UpdateLicense.propTypes = {
    guid: PropTypes.string.isRequired,
    intl: intlShape.isRequired,
    refresh: PropTypes.func.isRequired,
};

export default injectIntl(UpdateLicense);
