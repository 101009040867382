import React, { Component, Fragment } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, notification, Modal, Popover, Switch, DatePicker, Checkbox } from 'antd';
import { declencherGarantie } from '../../utils/apiBucherVaslin';
import { faEdit, faFileContract} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HtmlIncluder from '../HtmlIncluder';
import { withRouter } from "react-router-dom";

// Total step number
const maxStep = 2;

class ActivateGuarantee extends Component {
    state = {
        visible: false,
        warrantyStartDate: moment(new Date()),
        dealerCheckCGU: false,
        dealerCheckWarranty: false,
        step: 1,
        confirmationCheckbox : false,
    };

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            dealerCheckCGU: false,
            dealerCheckWarranty: false,
            step: 1
        });
    };

    declencher = async () => {
        const { intl, refresh, isConnectedDevice, guaranteeIsValidate } = this.props;
        const { warrantyStartDate } = this.state;
        this.setState({ visible: false,  dealerCheckCGU: false, dealerCheckWarranty: false, step: 1});
        // Call api
        let { isSuccess, isUnauthorized } = await declencherGarantie(this.props, isConnectedDevice, guaranteeIsValidate, warrantyStartDate);
        isSuccess ? (
            // Refresh data
            refresh(),
            // Display success Modal
            notification.success({
                message: intl.formatMessage({ id: 'table.devices.guarantee.success' })
            })
        ) : (
            notification.error({
                message: (isUnauthorized) ?  
                    intl.formatMessage({ id: 'error.unauthorized.title' }) :
                    intl.formatMessage({ id: 'table.devices.guarantee.error' }),
                description: (isUnauthorized) ? intl.formatMessage({ id: 'error.unauthorized.message' }) : (
                    <a onClick={() => this.handleRedirect()}>
                        {intl.formatMessage({ id: 'table.devices.guarantee.error.contact' })}
                    </a>
                ),
            })
        )
    };

    /**
     * Method used when user confirm modal
     */
    confirm() {
        this.declencher();
    }

    /**
     * Method used to return corresponding label for "Ok" button
     */
    getOkButtonLabel() {
        const { askActivate, warranty } = this.props;
        const { step } = this.state;

        if (!warranty || step === maxStep) {
            // If all displayed step have been completed
            return (askActivate) ? "cgu.accept.guarantee" : "cgu.accept";
        } else {
            // Else, display next step
            return "activateDevice.nextStep";
        }
    }

    handleRedirect = () => {
        const { history } = this.props;
        history.push("/contact");
    }

    // Update validation checkbox value
    async changeValidationCheck() {
        const { confirmationCheckbox } = this.state;
        await this.setState({confirmationCheckbox: !confirmationCheckbox})
    }

    // Method used to get informations about General Terms of Sales
    getGTS() {
        const { intl, locale } = this.props;
        const gts = { 
            label: intl.formatMessage({ id: 'gts.title' }),
            link: ""
        };

        switch(locale) {
            case "fr":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_french.pdf"
                break;
            case "en":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_english.pdf"
                break;
            case "de":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_german.pdf"
                break;
            case "es":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_spanish.pdf"
                break;
            case "it":
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_italian.pdf"
                break;
            default :
                gts.link = "/templates/pdf/general_terms_of_sales/general_terms_of_sales_english.pdf"
                break;
        }

        return gts;
    }

    render() {
        const { visible, warrantyStartDate, confirmationCheckbox,} = this.state;
        const { disable, isUpdate, authorizeTransfert } = this.props;
        const gts = (visible) ? this.getGTS() : null;

        return (        
            <span> 
                {isUpdate ? 
                    <Popover content={!authorizeTransfert ? <FormattedMessage id="table.devices.impossible" /> : <FormattedMessage id="table.devices.guaranteeUpdate" />} >
                        <Button htmlType="button" onClick={this.showModal} disabled={disable || !authorizeTransfert}>
                            <FontAwesomeIcon icon={faEdit} />
                        </Button>
                    </Popover>
                    :
                    <Popover content={!authorizeTransfert ? <FormattedMessage id="table.devices.impossible" /> : <FormattedMessage id="table.devices.guarantee" />} >
                        <Button htmlType="button" onClick={this.showModal} disabled={disable || !authorizeTransfert}>
                            <FontAwesomeIcon icon={faFileContract} />
                        </Button>
                    </Popover>
                }
                <Modal
                    title={( isUpdate ? <FormattedMessage id="table.devices.guaranteeUpdate" /> : <FormattedMessage id="table.devices.guarantee"/> )}
                    visible={visible}
                    okText={<FormattedMessage id="common.yes"/>}
                    onOk={() => this.confirm()}
                    okButtonProps={{ 
                        disabled: confirmationCheckbox ? false : true
                    }}
                    cancelText={<FormattedMessage id="common.cancel" />}
                    onCancel={this.handleCancel}
                    bodyStyle={{
                        overflowY: 'hidden',
                        minHeight: '50px',
                        position: 'relative',
                        display: 'block'
                    }}
                    width= {"30%"}
                >
                    <Fragment>
                        <span>{<FormattedMessage id="table.devices.guaranteeDate" />}</span>
                        <DatePicker 
                            format='DD/MM/YY'
                            defaultValue={warrantyStartDate}
                            disabledDate = { d => d.isAfter(new Date())}
                            onChange={
                                (date, dateString) => {
                                    this.setState({warrantyStartDate: date, dealerCheckWarranty: true})
                                }
                        } />
                        <br />
                        <span>
                            <Checkbox 
                                checked={confirmationCheckbox} 
                                onChange={ () => this.changeValidationCheck() }
                                style={{marginRight: "1em", marginTop: "1em"}}
                            />
                            <FormattedMessage id="device.modal.confirm.terms.of.sales" />
                            
                        </span>

                        {/* List of informations link */}
                        <ul style={{marginTop: "1em"}}>
                            {/* Link to General Terms of Sales */}
                            {gts && (
                                <li>
                                    <a target="_blank" href={gts.link}>{gts.label}</a>
                                </li>
                            )}
                        </ul>
                    </Fragment>
                </Modal>
            </span>
        );
    }
}

ActivateGuarantee.propTypes = {
    askActivate: PropTypes.bool.isRequired, // Si l'utilisateur est le concessionnaire de la machine alors la valeur est à true
    refresh: PropTypes.func,
    intl: intlShape.isRequired,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired,
    }).isRequired,
    disable: PropTypes.bool,
    isUpdate: PropTypes.bool,
    warranty: PropTypes.bool.isRequired,
    guaranteeIsValidate: PropTypes.bool.isRequired,
    authorizeTransfert: PropTypes.bool,
};

export default injectIntl(withRouter(ActivateGuarantee));