import { QUERY_SET } from '../actions/tables';

const tablesReducer = (state = {}, { type, table, data }) => {
    switch (type) {
        case QUERY_SET:
            return {
                ...state,
                [table]: data,
            };
        default:
            return state;
    }
};

export default tablesReducer;
