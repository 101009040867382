import React, { Component } from 'react';
import { Select, Form, notification, Spin } from 'antd';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { fetchCustomers } from '../utils/apiBucherVaslin';

const { Item: FormItem } = Form;
const { Option } = Select;

class SelectCustomer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isFetching: false,
            customers: [],
        };
    }

    componentDidMount() {
        const { client } = this.props;

        if (client && client.clientID && client.clientName) {
            this.setState({
                customers: [{ numeroClientEureka: client.clientID, nomClient: client.clientName }],
            });
        }
    }

    onSearch = async (value) => {
        this.setState({
            isFetching: value.length !== 0,
            customers: [],
        });
        if (value) {
            const { items: customers, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchCustomers({ nom_client: value });
            
            if (!isSuccess){
                notification.error({
                    message: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.title' })
                        : intl.formatMessage({ id: 'common.error' }),
                    description: isUnauthorized 
                        ? intl.formatMessage({ id: 'error.unauthorized.message' })
                        : errorDetail,
                });
            }
            
            this.setState({
                customers,
                isFetching: false,
            });

            return;
        }
    };

    handleClientChange = (key) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(key);
        }

        this.setState({
            customers: [],
            isFetching: false,
        });
    };

    renderSelect(initialValue) {
        const { isFetching, customers } = this.state;

        return (
            <Select
                initialValue={initialValue && initialValue.key}
                style={{ width: '100%' }}
                notFoundContent={isFetching ? <Spin size="small" /> : null}
                showSearch
                onSearch={debounce(this.onSearch, 600)}
                onChange={this.handleClientChange}
                filterOption={false}
            >
                {customers && customers.map((customer) => (
                    <Option key={customer.numeroClientEureka}>{customer.nomClient} ({customer.numeroClientEureka})</Option>
                ))}
            </Select>
        );
    }

    render() {
        const { getFieldDecorator, client } = this.props;
        const initialValue = client && client.clientID && client.clientName ? {
            key: client.clientID,
            label: client.clientName,
        } : undefined;

        const decorator = getFieldDecorator && getFieldDecorator('idClient', {
            initialValue,
            rules: [
                {
                    required: true,
                    message: <FormattedMessage id="user.form.clientRequired" />,
                },
            ],
        });
        const select = this.renderSelect(initialValue);

        return (
            <FormItem label={<FormattedMessage id="user.form.client" />} style={{ marginBottom: 10, padding: 0 }}>
                {decorator ? decorator(select) : select}
            </FormItem>
        );
    }
}

SelectCustomer.propTypes = {
    client: PropTypes.shape({
        clientID: PropTypes.string,
        clientName: PropTypes.string,
    }),
    getFieldDecorator: PropTypes.func,
    onChange: PropTypes.func,
    intl: intlShape.isRequired,
};

export default injectIntl(SelectCustomer);
