import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Steps } from 'antd';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import './OrderStepper.less'

const { Step } = Steps;

class OrderStepper extends Component {
    constructor(props) {
        super(props);
    } 

    render() {
        const { stepIndex } = this.props;

        return (
            <Steps type="navigation" className="order-stepper" current={stepIndex}>
                <Step key="step_0" 
                    title={(<FormattedMessage
                        id="order.modal.step.firstStep"
                    />)}
                />
                <Step key="step_1" 
                    title={(<FormattedMessage
                        id="order.modal.step.secondStep"
                    />)}
                />
                <Step key="step_2" 
                    title={(<FormattedMessage
                        id="order.modal.step.thirdStep"
                    />)}
                />
                <Step key="step_3" 
                    title={(<FormattedMessage
                        id="order.modal.step.fourthStep"
                    />)}
                />
            </Steps>
        );
    }
}

OrderStepper.propTypes = {
    stepIndex: PropTypes.number.isRequired,
    intl: intlShape.isRequired,
};

export default injectIntl(OrderStepper);