import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import { Button, Form, Icon, Input, Upload, Select, Switch, Popover, Alert } from 'antd';
import { REGEX_TVA } from '../../utils/constants';
import { locales } from '../../translations';
import { formItemLayout } from '../../utils/formItemLayout';
import { REGEX_ONLY_CARACTERE_LATIN } from '../../utils/constants';
import {
    checkNameCustomerExist,
    checkSiretExist
} from '../../utils/apiBucherVaslin';
import { isGroupAdminClient, isSuperAdmin } from '../../utils/helpers';

import './FormItem.less';

const { Item: FormItem } = Form;
const { Option } = Select;

class ClientFormItems extends Component {
    // onEditMode permet de définir si c'est une mise à jour ou une création (on se base sur le nom client)
    // isOldProspect : permet de vérifier si le client est un prospect (avant modification)
    // car on ne permet pas à un client de devenir prospect, seulement un prospect de devenir client
    state = {
        dupeState: {
            clientName: false,
            clientSiret: false
        },
        isInsideCEE: false,
        isOldProspect: false,
        isProspect: false,
        onEditMode: false
    };
    
    /**
     * Method called on blur for the fields who needs to be unique
     * @param {string} field Field decorator used to now on which field we want to check dupe
     */
    checkDupe = async (field) => {
        const { form : {getFieldValue, getFieldError} } = this.props;

        // Copy dupeState to update it later
        const dupeState = JSON.parse(JSON.stringify(this.state.dupeState));
        const fieldValue = getFieldValue(field);

        // Check if field display an error and if field display a dupe error
        const errors = getFieldError(field);
        const dupeErrors = (errors) ? errors.filter((error) => !error.props) : null;

        // Check for dupe only if another error isn't displayed on this fields
        if (!errors || !dupeErrors || errors.length - dupeErrors.length === 0) {
            switch (field) {
                case "client.nomClient":
                    const { item: itemName } = await checkNameCustomerExist(fieldValue);
                    dupeState.clientName = itemName;
                    await this.setState({dupeState});
                    break;
                case "client.siret":
                    const { item: itemSiret } = await checkSiretExist(fieldValue);
                    dupeState.clientSiret = itemSiret;
                    await this.setState({dupeState});
                    break;
            }
        }        
    }
    /**
     * Method used to check for client name duplication
     */
    checkClientNameDuplication = async (_) => {

        const { initialValues: {nomClient }, form : {getFieldValue}} = this.props;
        const fieldValue = getFieldValue(`client.nomClient`);
        // Trigger dupe check
        if (nomClient != fieldValue) {
            await this.checkDupe(`client.nomClient`);

            // Get dupeState
            const { intl } = this.props;
            const { dupeState } = this.state;
            const message = intl.formatMessage({id:"client.form.clientName.duplicate"});
            
            if (dupeState.clientName) {
                // Reject if client name is a duplicate
                return Promise.reject(message);
            } else {
                // Resolve if client name isn't a duplicate
                return Promise.resolve();
            }
        }
        
    }

    /**
     * Method used to check for siret duplication
     */
    checkSiretDuplication = async (_) => {
        const { initialValues: { siret }, form : {getFieldValue}} = this.props;
        const fieldValue = getFieldValue(`client.siret`);
      
        if(siret != fieldValue) {
            // Trigger dupe check
            await this.checkDupe(`client.siret`);

            // Get dupeState
            const { intl } = this.props;
            const { dupeState } = this.state;
            const message = intl.formatMessage({id:"client.form.siret.duplicate"});
            
            if (dupeState.clientSiret) {
                // Reject if siret is a duplicate
                return Promise.reject(message);
            } else {
                // Resolve if siret isn't a duplicate
                return Promise.resolve();
            }
        }
    }

    checkSiret = (_, value, callback) => {
        const { clientPrefix, form: { setFields, getFieldValue} } = this.props;
        const fieldValue = getFieldValue(`${clientPrefix}idTva`);
        if (!value) {
            setFields({
                [`${clientPrefix}idTva`]: {
                    value: undefined
                }
            });

            return callback();
        }
        // On vérifie que cela correspond à une chaîne de longueur 14
        if (typeof value !== 'string' || value.length != 14){
            return callback(<FormattedMessage
                id="client.form.SIRETValid"
            />);
        }

        // Si c'est un numéro siret on indique en champ idTVA le numéro adéquat
        if (typeof value === 'string' && value.length == 14 && fieldValue.length == 0){
            setFields({
                [`${clientPrefix}idTva`]: {
                    value: value.substring(0,9)
                }
            });

            return callback();
        }

        return callback();
    }
    render() {
        // Props constant
        const {
            form: { getFieldDecorator },
            clientPrefix,
            initialValues: {
                nomClient,
                webSite,
                codeLangue,
                siret,
                idTva,
                fichiers,
                pays
            },
            intl,
            locale,
            loggedIn,
            userRole
        } = this.props;

        // State constant
        const { 
            isInsideCEE,
            isProspect, 
            onEditMode, 
            isOldProspect
        } = this.state;

        // Overrides default value to allow optionnal field depending selected local
        let filesText = intl.formatMessage({ id: 'client.form.fileslist' });
        if ('client.form.fileslist' === filesText) {
            filesText = '';
        }
        
        return (
            <Fragment>

                {/* Alert Principal adresse changes termes */}
                <Alert
                    banner
                    message={<FormattedMessage
                                id="client.addressModal.alert.principal.address.changes.termes"
                                defaultMessage="Contact Bucher Vaslin for address modifications"
                                />
                            }
                />

                {(!loggedIn || (onEditMode && isOldProspect)) && (
                    <FormItem>
                        <div className="alert-switches">
                        {getFieldDecorator(`${clientPrefix}isProspect`, {
                            rules: [],
                            initialValue: isProspect
                        })(
                            <Fragment>
                                <Switch
                                    defaultChecked={isProspect}
                                    onChange={(value) => {this.setState({isProspect: value})}}
                                    disabled= {(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))}
                                    className="alert-switch"
                                />
                                <FormattedMessage
                                    id="client.form.prospectMessage"
                                    defaultMessage="Check this option if you don’t own Bucher Vaslin machine and if you wish to try Winect app in demo mode. You’ll have access to all app functionalities with your account.
                                    (You won’t be able to use your own machines, until you complete your account creation.)"
                                />
                            </Fragment>
                        )}
                        </div>
                    </FormItem>
                )}

                {/* Company name field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.company"
                            defaultMessage="Company"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}nomClient`, {
                        validate: [
                            {
                                trigger: 'onChange',
                                rules: [
                                    {
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="client.form.companyRequired"
                                                defaultMessage="Please enter your company name"
                                            />
                                        )
                                    },
                                    {
                                        pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                        message: (<FormattedMessage
                                            id="form.validator.invalidCaracteres"
                                            defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                        />)
                                    },
                                    {
                                        max: 35,
                                        message: intl.formatMessage({
                                            id: 'validator.field.max'
                                        }, 
                                        { length: 35})
                                    }
                                ]
                            }, {
                                trigger: 'onBlur',
                                rules: [
                                    {
                                        required: true,
                                        message: (
                                            <FormattedMessage
                                                id="client.form.companyRequired"
                                                defaultMessage="Please enter your company name"
                                            />
                                        )
                                    },
                                    {
                                        pattern: new RegExp(REGEX_ONLY_CARACTERE_LATIN),
                                        message: (<FormattedMessage
                                            id="form.validator.invalidCaracteres"
                                            defaultMessage="Characters are invalid. Only Latin characters are allowed"
                                        />)
                                    },
                                    {
                                        max: 35,
                                        message: intl.formatMessage({
                                            id: 'validator.field.max'
                                        }, 
                                        { length: 35})
                                    },
                                    {
                                        validator: this.checkClientNameDuplication
                                    }
                                ]
                            }
                        ],
                        initialValue: nomClient,
                    })(
                        <Popover content={<FormattedMessage id="client.form.changeCustomer" />}>
                            <Input disabled value={nomClient}/>  
                        </Popover> 
                    )}
                </FormItem>

                {/* Siret Field (france country only) */}
                {pays === 'FRANCE' ? (
                    <FormItem
                        {...formItemLayout}
                        label={(
                            <FormattedMessage
                                id="client.form.SIRET"
                                defaultMessage="SIRET"
                            />
                        )}
                    >
                        {getFieldDecorator(`${clientPrefix}siret`, {
                            validate: [
                                {
                                    trigger: 'onChange',
                                    rules: !isProspect ? [
                                        {
                                            validator: this.checkSiret,
                                        },
                                        {
                                            required: true,
                                            message: (
                                                <FormattedMessage
                                                    id="client.form.SIRETRequired"
                                                    defaultMessage="Please enter a SIRET number"
                                                />
                                            )
                                        }
                                    ] : [
                                        {
                                            validator: this.checkSiret,
                                        }
                                    ]
                                }, {
                                    trigger: 'onBlur',
                                    rules: !isProspect ? [
                                        {
                                            validator: this.checkSiret,
                                        },
                                        {
                                            validator: this.checkSiretDuplication
                                        },
                                        {
                                            required: true,
                                            message: (
                                                <FormattedMessage
                                                    id="client.form.SIRETRequired"
                                                    defaultMessage="Please enter a SIRET number"
                                                />
                                            )
                                        }
                                    ] : [
                                        {
                                            validator: this.checkSiret,
                                        },
                                        {
                                            validator: this.checkSiretDuplication
                                        }
                                    ]
                                }
                            ],
                            initialValue: siret,
                        })(
                            // <Input disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))}/>
                            <Input disabled={true} value={siret}/>
                        )}
                    </FormItem>
                ) : null}

                {/* VAT field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.idTVA"
                            defaultMessage="idTVA"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}idTva`, {
                        initialValue: idTva,
                        rules: [isInsideCEE && !isProspect && {		
                            required: true,		
                            message: (		
                                <FormattedMessage		
                                    id="client.form.tvaRequired"		
                                    defaultMessage="Please enter the VTA"		
                                />		
                            )		
                        },
                        { 
                            pattern: new RegExp(REGEX_TVA), 
                            message: (<FormattedMessage
                                id="form.validator.invalidTva"
                                defaultMessage="Only numbers and letters are allowed"
                            />)
                        },
                        { 
                            max: 14, 
                            message: intl.formatMessage({id: 'user.form.commentMaxCarac'},{ max : 14 })
                        }]
                    })(
                        // <Input disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))}/>
                        <Input disabled={true} value={idTva}/>
                    )}
                </FormItem>

                 {/* WebSite URL */}
                 <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.webSite" 
                            defaultMessage="Web Site"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}webSite`, {
                        rules: [{
                            pattern: new RegExp("^.*[\.]([a-zA-Z][a-zA-Z]+)$"),
                            message: (<FormattedMessage
                                id="form.validator.invalidWebSite"
                                defaultMessage="The website is invalid, it must end with a '.' followed by at least 2 characters"
                            />)
                        }],
                        initialValue: webSite,
                    })(
                        <Input disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))}/>
                    )}
                </FormItem>

                {/* Language field */}
                <FormItem
                    {...formItemLayout}
                    label={(
                        <FormattedMessage
                            id="client.form.language"
                            defaultMessage="Language"
                        />
                    )}
                >
                    {getFieldDecorator(`${clientPrefix}codeLangue`, {
                        rules: [{
                            required: true,
                            message: (
                                <FormattedMessage
                                    id="client.form.languageRequired"
                                    defaultMessage="Please enter the language"
                                />
                            )
                        }],
                        initialValue: (codeLangue && codeLangue.toLowerCase()) || locale,
                    })(
                        <Select disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))}>
                            {Object.keys(locales).map((key) => (
                                <Option
                                    key={key}
                                    value={locales[key]}
                                >
                                    <FormattedMessage
                                        id={`menu.lang.${key}`}
                                        defaultMessage={key}
                                    />
                                </Option>
                            ))}
                        </Select>
                    )}
                </FormItem>

                

                {/* Files field */}
                {/* <FormItem
                    {...formItemLayout}
                    label={(
                        <span>
                            <FormattedMessage
                                id="client.form.files"
                                defaultMessage="Files"
                                style={{ marginRight: '5px' }}
                            />
                        </span>
                    )}
                    extra={filesText}
                >
                    {getFieldDecorator(`${clientPrefix}fichiers`, {
                        initialValue: fichiers
                    })(
                        <Upload
                            multiple
                            beforeUpload={() => false}
                        >
                            <Button disabled={(!isGroupAdminClient({userRole}) && !isSuperAdmin({ userRole }))}>
                                <Icon type="upload" />
                                <FormattedMessage id="client.form.addfiles" />
                            </Button>
                        </Upload>
                    )}
                </FormItem>        */}
            </Fragment>
        );
    }
}

ClientFormItems.defaultProps = {
    clientPrefix: '',
    initialValues: {}
};

ClientFormItems.propTypes = {
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    clientPrefix: PropTypes.string,
    initialValues: PropTypes.shape({}),
    intl: intlShape.isRequired,
    locale: PropTypes.string,
    loggedIn: PropTypes.bool
};

const mapStateToProps = ({ app: { locale },signIn: { loggedIn, userRole },
 }) => ({ locale, loggedIn, userRole });

export default connect(mapStateToProps)(injectIntl(ClientFormItems));
