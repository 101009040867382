import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Divider, Button, Input, Modal, message, Form } from 'antd';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { createOrder } from '../../utils/apiBucherVaslin';
import RequiredFieldLegend from '../RequiredFieldLegend';
import SelectCustomer from '../SelectCustomer';
import OrderStepper from '../Orders/OrderStepper';
import { isGroupConcessionnaire } from '../../utils/helpers';

const { Item: FormItem } = Form;

class CreateOrderLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            confirmLoading: false,
        };
    }

    generateModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleOk = () => {
        const { form, intl, refresh, clientEurekaNumber } = this.props;
        const isContrat = 1;
        this.setState({
            confirmLoading: true,
        });

        form.validateFields(async (err, values) => {
            if (err) {
                this.setState({
                    confirmLoading: false,
                });

                return;
            }
            const { isSuccess, item, isUnauthorized } = 
                        await createOrder(
                                values.idClient ? values.idClient : clientEurekaNumber,
                                values,
                                undefined,
                                isContrat);
            const id = isSuccess ? item.numCommande : null;
            if (isSuccess) {
                refresh();
                this.setState({
                    visible: false,
                    confirmLoading: false,
                });
                message.success(
                    intl.formatMessage(
                        {
                            id: 'orderLicense.new.success',
                        },
                        { id },
                    ),
                    5,
                );

                return;
            }
            Modal.error({
                title: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({
                        id: 'order.new.error',
                    }),
                content: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' }) 
                    : intl.formatMessage({
                        id: 'order.new.errorMessage',
                    }),
            });
            this.setState({
                visible: false,
                confirmLoading: false,
            });
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    render() {
        const { visible, confirmLoading } = this.state;
        const { form, userRole } = this.props;
        const { getFieldDecorator } = form;

        return (
            <div>
                <Divider style={{width:"auto", minWidth:"auto"}} />
                <Button className="generate-button" onClick={this.generateModal}>
                    <FormattedMessage id="ordersLicense.new" />
                </Button>
                <Modal
                    title={<FormattedMessage id="orderLicense.new.modal" />}
                    onOk={this.handleOk}
                    visible={visible}
                    onCancel={this.handleCancel}
                    confirmLoading={confirmLoading}
                    width="80%"
                >
                    {
                        <Fragment>
                            {/* Order's life cycle */}
                            <OrderStepper stepIndex={0} />

                            {/* Customer selection form item */}
                            {isGroupConcessionnaire({ userRole }) && (
                                <SelectCustomer getFieldDecorator={getFieldDecorator} />
                            )}

                            {/* Client number form item */}
                            <FormItem
                                label={(
                                    <FormattedMessage
                                        id="orderLicense.modal.clientRef"
                                    />
                                )}
                            >
                                {getFieldDecorator('numCommandeClient')(
                                    <Input />,
                                )}
                            </FormItem>

                            <RequiredFieldLegend />
                        </Fragment>
    }
                </Modal>
            </div>
        );
    }
}

CreateOrderLicense.propTypes = {
    userRole: PropTypes.array.isRequired,
    refresh: PropTypes.func.isRequired,
    intl: intlShape.isRequired,
    form: PropTypes.shape({
        getFieldsValue: PropTypes.func,
        getFieldDecorator: PropTypes.func,
    }),
};

const mapStateToProps = ({ signIn: { userRole, clientEurekaNumber } }) => ({ userRole, clientEurekaNumber });

export default connect(mapStateToProps)(injectIntl(Form.create()(CreateOrderLicense)));
