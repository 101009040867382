import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { Button, Card, Form, Input, notification } from 'antd';
import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight, faShareSquare } from '@fortawesome/free-solid-svg-icons';
import { createAnnotation } from '../../utils/apiBucherVaslin';
import './AddAnnotation.less';

const { Item: FormItem } = Form;
const { TextArea } = Input;

class AddAnnotation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            fichier: null,
            showAdd: false
        };
    }   
     saveNewAnnotation = async() => {
        const { form: { validateFieldsAndScroll, setFieldsValue }, intl, incidentId, reloadAnnotation } = this.props;   
        const { fichier } = this.state;     
       validateFieldsAndScroll( async(err, values) => {
            if (!err) {
                const {isSuccess, isUnauthorized, errorMessage, errorDetail} = await createAnnotation(values, fichier, incidentId);
                if (!isSuccess) {
                    notification.error({
                        message: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.title' })
                            : intl.formatMessage({ id: 'common.error' }),
                        description: isUnauthorized 
                            ? intl.formatMessage({ id: 'error.unauthorized.message' })
                            : errorDetail
                    });
                    return;
                }
            setFieldsValue({[('subject').valueOf()]: null});
            setFieldsValue({[('noteText').valueOf()]: null});
            this.setState({fichier: null});
            reloadAnnotation(incidentId);
            }     
    });
    }

    render() {   
        const { form : {getFieldDecorator, getFieldValue}, intl, userName, disabledButton } = this.props;
        const { fichier, showAdd } = this.state;
        let noteTextValue =  getFieldValue('noteText');
        return(
            showAdd == true ?
            <Fragment>
                <Button onClick={() => this.setState({showAdd: false})} style= {{cursor: "pointer", marginBottom: "0.5em"}} > 
                    <FormattedMessage id="incident.modal.formInfo.ajoutAnnotation" />
                    <FontAwesomeIcon icon={faAngleDown} style= {{marginLeft:"0.5em"}}  />
                </Button>    
                <Card 
                    size="small" 
                    style={{marginBottom : "2px"}}
                    >
                    <FormItem>
                        {getFieldDecorator('subject', {
                                initialValue: userName
                        })(
                            <Input style={{display: "none"}}></Input>
                        )}
                    </FormItem>
                    <FormItem>
                            {getFieldDecorator('noteText')(
                                <TextArea  autoSize={{ minRows: 5 }}  placeholder={intl.formatMessage({ id: 'contact.form.message' })}></TextArea>
                            )}
                    </FormItem>
                    <div className= "inlineAddAnontation">
                        <FormItem>
                            {getFieldDecorator('fichier')(
                                <Dropzone
                                    className="dropzone-annotation"
                                    name="files"
                                    onDrop={(value) => this.setState({fichier: value})}
                                >
                                    <Fragment>
                                        { (fichier) ? (
                                            <div className="file-container">
                                                    <button
                                                        type="button"
                                                        className="file-name-container"
                                                        onClick={() => this.setState({fichier: null})}
                                                    >
                                                        <span className="file-name">{fichier[0].name}</span>
                                                        <span className="file-delete">
                                                            <FontAwesomeIcon icon="times-circle" />
                                                        </span>
                                                    </button>
                                            </div>
                                        ) : null}
                                        <span style={{fontSize:"10px"}}><FormattedMessage id="contact.form.fileUpload" /></span>
                                    </Fragment>
                                </Dropzone>
                            )}          
                        </FormItem>
                        <Button disabled={!(noteTextValue || fichier)} style= {{ float:"right", cursor: "pointer"}}   onClick={() => this.saveNewAnnotation()} > <FontAwesomeIcon title="save" style= {{color:"green", marginRight:"0.5em"}} icon={faShareSquare} /> 
                            <FormattedMessage id="common.send"/>
                        </Button>    
                    </div>   
                </Card>
            </Fragment>
            :
            <Button  disabled={disabledButton} onClick={() => this.setState({showAdd: true})} style= {{cursor: "pointer", marginBottom: "0.5em"}} > 
                <FormattedMessage id="incident.modal.formInfo.ajoutAnnotation" />
                <FontAwesomeIcon icon={faAngleRight} style= {{marginLeft:"0.5em"}}  />
            </Button>    
           
    )}
}

AddAnnotation.propTypes = {
    form: PropTypes.shape({
        getFieldsValue: PropTypes.func,
        getFieldDecorator: PropTypes.func
    }),
    intl: intlShape.isRequired,
    reloadAnnotation: PropTypes.func.isRequired,
    disabledButton: PropTypes.bool.isRequired,
    incidentId: PropTypes.string
};
const mapStateToProps = ({ signIn: {userName} }) => ({ userName });

export default connect(mapStateToProps)(injectIntl(Form.create()(AddAnnotation)));
