import {
    LOGIN_RESET,
    LOGIN_SET_ALLOW_DOWNLOAD_HISTORY,
    LOGIN_SET_CLIENT_DEALER,
    LOGIN_SET_CLIENT_EBV,
    LOGIN_SET_CLIENT_EMAIL,
    LOGIN_SET_CLIENT_EUREKA_NUMBER,
    LOGIN_SET_CLIENT_ID,
    LOGIN_SET_CLIENT_NAME,
    LOGIN_SET_CLIENT_ROLE,
    LOGIN_SET_STATE,
    LOGIN_SET_TOKEN,
    LOGIN_SET_TOKEN_DATE,
    LOGIN_SET_USER_ID,
    LOGIN_SET_USER_NAME,
    LOGIN_SET_USER_ROLE,
    LOGIN_SET_IS_PROSPECT,
    LOGIN_SET_USER_RIGHTS,
    LOGIN_SET_PROGRESS_ICONS,
} from '../actions/signIn';

const defaultState = {
    allowDownloadHisto: false,
    clientEbv: null,
    clientEurekaNumber: null,
    clientID: null,
    clientIsDealer: false,
    clientName: null,
    loggedIn: false,
    notificationRegistered: null,
    notificationToken: null,
    token: null,
    tokenDate: null,
    userID: null,
    userName: null,
    userRole: [],
    isProspect: false,
    rights: [],
    progressIconsTemplateList: []
};

const signInReducer = (state = defaultState, { type, ...payload }) => {
    switch (type) {
        case LOGIN_RESET:
            return defaultState;
        case LOGIN_SET_ALLOW_DOWNLOAD_HISTORY:
            return { ...state, allowDownloadHisto: payload.allowDownloadHisto };
        case LOGIN_SET_CLIENT_DEALER:
            return { ...state, clientIsDealer: payload.clientIsDealer };
        case LOGIN_SET_CLIENT_EBV:
            return { ...state, clientEbv: payload.clientEbv };
        case LOGIN_SET_CLIENT_EMAIL:
            return { ...state, email: payload.email };
        case LOGIN_SET_CLIENT_EUREKA_NUMBER:
            return { ...state, clientEurekaNumber: payload.clientEurekaNumber };
        case LOGIN_SET_CLIENT_ID:
            return { ...state, clientID: payload.clientID };
        case LOGIN_SET_CLIENT_NAME:
            return { ...state, clientName: payload.clientName };
        case LOGIN_SET_STATE:
            return { ...state, loggedIn: payload.loggedIn };
        case LOGIN_SET_TOKEN:
            return { ...state, token: payload.token };
        case LOGIN_SET_TOKEN_DATE:
            return { ...state, tokenDate: payload.tokenDate };
        case LOGIN_SET_USER_ID:
            return { ...state, userID: payload.userID };
        case LOGIN_SET_USER_NAME:
            return { ...state, userName: payload.userName };
        case LOGIN_SET_USER_ROLE:
            return { ...state, userRole: payload.userRole };
        case LOGIN_SET_IS_PROSPECT:
            return { ...state, isProspect: payload.isProspect };
        case LOGIN_SET_USER_RIGHTS:
            return { ...state, rights: payload.rights };
        case LOGIN_SET_PROGRESS_ICONS:
            return { ...state, progressIconsTemplateList: payload.progressIconsTemplateList };
        default:
            return state;
    }
};

export default signInReducer;
