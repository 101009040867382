import React, { Component, Fragment } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, Col, Divider, Input, notification, Popover, Row, Table } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { alphabetically, isOrderEditable, isInfoCompleted } from '../../utils/helpers';
import PageLayout from '../Layout/PageLayout';
import { addLineToOrder, fetchOrder, fetchOrderStatus, fetchUsers, updateLineInOrder } from '../../utils/apiBucherVaslin';
import AddArticlesLicense from './AddArticlesLicense';
import ArticleComponents from '../ArticleComponents';
import DeleteArticle from './DeleteArticle';
import { EditableCell, EditableContext, EditableFormRow } from '../EditableCell';
import  FormattedCurrency  from '../utils/FormattedCurrency';
import { CURRENCY_EURO } from '../../utils/constants';
import OrderStepper from '../Orders/OrderStepper';
import '../Orders/Orders.less'

const { TextArea } = Input;

const mongoDateFormat = 'YYYY-MM-DD HH:mm:ss';
const renderDate = (date) => (date ? (
    <Popover content={moment(date).format('DD/MM/YY HH:mm:ss')}>
        <span>{moment(date).format('DD/MM/YY')}</span>
    </Popover>
) : (
    '-'
));

/* eslint-disable */
class OrderLicense extends Component {
    constructor(props) {
        super(props);
        this.state = {
            clientEurekaNumber: null,
            loading: false,
            order: null,
            orderStatus: null,
            lines: [],
            editingKey: '',
            currency: CURRENCY_EURO,
            statusList: []
        };
    }

    async componentDidMount() {
        /* check connexion and information */ 
        const { userID, intl } = this.props;
        await isInfoCompleted(intl, userID);
        
        await this.initStatusList();
        await this.fetchDataAsync();
    }

    async AddArticlesLicense(articles) {
        const { order, lines } = this.state;
        const { intl } = this.props;
        const QUANTITY = 1;

        const addedLines = [];
        for (let i = 0; i < articles.length; i++) {
            // const existingArticle = lines.find(article => article.codeArticle === articles[i].codeArticle);
            // if (existingArticle) {
            //     const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await updateLineInOrder(order.numCommande, existingArticle, existingArticle.quantite);
            //     if (!isSuccess){
            //         notification.error({
            //             message: isUnauthorized 
            //                 ? intl.formatMessage({ id: 'error.unauthorized.title' })
            //                 : intl.formatMessage({ id: 'common.error' }),
            //             description: isUnauthorized 
            //                 ? intl.formatMessage({ id: 'error.unauthorized.message' })
            //                 : errorDetail,
            //         });
            //     } else {
            //         // Gestion de la quantité et du prix total de la ligne
            //         existingArticle.quantite += QUANTITY;
            //         existingArticle.prixTotal += parseInt(existingArticle.prix);
            //     }
            // } else {
                if(articles[i].codeArticle){
                    const { item, isSuccess, isUnauthorized, errorMessage, errorDetail } = await addLineToOrder(order.numCommande, articles[i], QUANTITY);
                    if (isSuccess) {
                        // Gestion du prix total de la ligne
                        item.prixTotal = parseInt(item.prix);
                        addedLines.push(item);
                    }
                    else {
                        notification.error({
                            message: isUnauthorized 
                                ? intl.formatMessage({ id: 'error.unauthorized.title' })
                                : intl.formatMessage({ id: 'common.error' }),
                            description: isUnauthorized 
                                ? intl.formatMessage({ id: 'error.unauthorized.message' })
                                : errorDetail,
                        });
                    }
                }
            // }
        }
        this.fetchDataAsync();
    }

    initStatusList = async () => {
        const { intl, locale } = this.props;

        // Charge la liste des status
        const { status, isSuccess, isUnauthorized, errorMessage, errorDetail } = await fetchOrderStatus(locale.toUpperCase());
        if (!isSuccess) {
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
            return;
        }
        // Mise à jour du state
        await this.setState({statusList: status});
    }

    handleSearch = (selectedKeys, confirm) => () => {
        confirm();
    };

    handleReset = (clearFilters) => () => {
        clearFilters();
    };

    isEditing = record => record.id === this.state.editingKey;

    cancel() {
      this.setState({ editingKey: '' });
    }

    edit(key) {
      this.setState({ editingKey: key });
    }

    filterDropDown = ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <div className="custom-filter-dropdown">
            <Input
                /* eslint-disable-next-line */
                ref={(ele) => (this.searchInput = ele)}
                value={selectedKeys[0]}
                onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                onPressEnter={this.handleSearch(selectedKeys, confirm)}
            />
            <Button type="primary" onClick={this.handleSearch(selectedKeys, confirm)}>
                Search
            </Button>
            <Button onClick={this.handleReset(clearFilters)}>Reset</Button>
        </div>
    );

    onFilter = (value, obj) => obj && obj.toLowerCase().includes(value.toLowerCase());

    onFilterDropdownVisibleChange = (visible) => {
        if (visible) {
            setTimeout(() => {
                this.searchInput.focus();
            });
        }
    };

    async fetchDataAsync() {
        this.setState({ loading: true });
        const {
            match: {
                params: { id },
            },
            clientID,
            intl,
        } = this.props;

        const { items, isSuccess: isSuccessOrder, isUnauthorized, errorMessage, errorDetail } = await fetchOrder(id)();
        
        const {items: users, isSuccess: isSuccessUsers
            , isUnauthorized: isUnauthorizedUsers
            , errorDetail: errorMessageUsers } = await fetchUsers({}, clientID);

        if (!isSuccessUsers){
            notification.error({
                message: isUnauthorizedUsers 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorizedUsers 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorMessageUsers,
            });
        }

        // Ajout du prix total de la ligne
        items && items.lignes && items.lignes.forEach((ligne) => {
            ligne.prixTotal = ligne.quantite * ligne.prix;
        })

        // TODO => A retirer quand le statut de la commande remontera correctement pour les statuts
        // "Brouillon" et "En préparation"
        if(!items.commande.statutCommand) {
            items.commande.statutCommand = (!items.commande.dateSoumission) ? "Draft" : "In preparation";
        }

        if (isSuccessOrder){
            this.setState({ order: items.commande, orderStatus: items.statut });
        }
        if (!isSuccessOrder){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
        }

        if (!isSuccessUsers || !users || 0 === users.length) {
            this.setState({ lines: [], loading: false })
            return;
        }

        const clientEurekaNumber = users[0].client.numeroClientEureka;
        this.setState({ lines: items.lignes, loading: false, clientEurekaNumber });
    }

    getColumns () {
        const { currency } = this.state;
        const { intl } = this.props;
        
        let columns = [
            {
                dataIndex: 'libelle',
                key: 'libelle',
                sorter: false,
                title: <FormattedMessage id={`table.order.label`} />,
                sorter: (a, b) => alphabetically(a.libelle, b.libelle),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.libelle),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            },
            {
                dataIndex: 'codeArticle',
                key: 'codeArticle',
                sorter: false,
                title: <FormattedMessage id={`table.order.codeArticle`} />,
                sorter: (a, b) => alphabetically(a.codeArticle, b.codeArticle),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.codeArticle),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                render: (_, { codeArticle }) => (<span>{parseInt(codeArticle, 10)}</span>),
            },
            {
                dataIndex: 'famille',
                key: 'famille',
                sorter: false,
                title: <FormattedMessage id={`table.order.famille`} />,
                sorter: (a, b) => alphabetically(a.famille, b.famille),
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => this.onFilter(value, record.famille),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
            },
            {
                dataIndex: 'prix',
                key: 'prix',
                sorter: false,
                title: <FormattedMessage id={`table.order.prixUnitaire`} />,
                sorter: (a, b) => a.prix > b.prix,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => parseInt(value) === parseInt(record.prix),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                render: (value) => value
                // render: (value) => <FormattedCurrency amount={value} currency={currency} />, // a remettre quand la deviuse sera la bonne 
            },
            {
                dataIndex: 'quantite',
                key: 'quantite',
                sorter: false,
                title: <FormattedMessage id={`table.order.quantite`} />,
                sorter: (a, b) => a.quantite > b.quantite,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => parseInt(value) === parseInt(record.quantite),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                editable: true,
            },
            {
                dataIndex: 'prixTotal',
                key: 'prixTotal',
                sorter: false,
                title: <FormattedMessage id={`table.order.prixTotal`} />,
                sorter: (a, b) => a.prixTotal > b.prixTotal,
                filterDropdown: this.filterDropDown,
                onFilter: (value, record) => parseInt(value) === parseInt(record.prixTotal),
                onFilterDropdownVisibleChange: this.onFilterDropdownVisibleChange,
                render: (value) => value
                // render: (value) => <FormattedCurrency amount={value} currency={currency} />, // a remettre quand la deviuse sera la bonne 
            },
        ];

        if (this.isEditable()) {
            columns.push(
            {
                key: 'action',
                title: 'Action',
                render: (_, record) => {
                    const { id, libelle, numCommande } = record;

                    if (this.isEditing(record)) {
                        return (
                            <span>
                                <EditableContext.Consumer>
                                    {form => (
                                        <Button
                                            htmlType="button"
                                            type="primary"
                                            onClick={() => this.save(form, record.id)}
                                        >
                                            <FontAwesomeIcon icon="save" />
                                        </Button>
                                    )}
                                </EditableContext.Consumer>
                                <Divider type="vertical" />
                                <Button
                                    htmlType="button"
                                    type="primary"
                                    onClick={() => this.cancel(record.id)}
                                >
                                    <FontAwesomeIcon icon="ban" />
                                </Button>
                            </span>
                        );
                    }

                    return (
                        <Fragment>
                            <Button
                                htmlType="button"
                                type="danger"
                                onClick={() => this.edit(record.id)}
                            >
                                <FontAwesomeIcon icon="pen-alt" />
                            </Button>
                            <Divider type="vertical" />
                            <DeleteArticle id={id} libelle={libelle} numCommande={numCommande} refresh={() => this.fetchDataAsync()} />
                        </Fragment>
                    );
                },
            });

            // Configurate editables columns
            columns = columns.map((col) => {
                if (!col.editable) {
                    return col;
                }

                const c = {
                    ...col,
                    onCell: record => ({
                      record,
                      inputType: 'number',
                      dataIndex: col.dataIndex,
                      title: col.title,
                      editing: this.isEditing(record),
                    }),
                };

                return c;
            });
        }

        return columns;
    }

    save(form, key) {
        form.validateFields(async (error, row) => {
          if (error) {
            return;
          }

          const { order } = this.state;
          const { intl } = this.props;
          const newLines = [...this.state.lines];
          const index = newLines.findIndex(item => key === item.id);
          if (index === -1) {
            return;
          }

          if (row.quantite <= 0) {
            row.quantite = 1;
          }

          const item = newLines[index];
          const newItem = { ...item, ...row };
          // Update total price
          newItem.prixTotal = parseInt(newItem.quantite) * parseInt(newItem.prix);
          newLines.splice(index, 1, newItem);

          this.setState({ lines: newLines, editingKey: '' });
          const { isSuccess, isUnauthorized, errorMessage, errorDetail } = await updateLineInOrder(order.numCommande, newItem, newItem.quantite);
          if (!isSuccess){
            notification.error({
                message: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.title' })
                    : intl.formatMessage({ id: 'common.error' }),
                description: isUnauthorized 
                    ? intl.formatMessage({ id: 'error.unauthorized.message' })
                    : errorDetail,
            });
          }
        })
    }

    isEditable() {
        return this.state.order && isOrderEditable(this.state.order);
    }

    // Return status id for translation 
    getStatus = (line) => {
        const { statusList } = this.state;
        const lineStatut = statusList.find((status) => status.statut === line.statut);
        return (lineStatut) ? lineStatut.statutTraduit : null;
    }

    render() {
        const {
            match: {
                params: { id },
            },
            history,
        } = this.props;
        const { clientEurekaNumber, currency, lines, loading, order, statusList } = this.state;

        // // Allow orders with articles of the same family
        // let familyCode = null;
        // if (lines.length > 0) {
        //     familyCode = lines[0].famille;
        // }

        const components = {
            body: {
                row: EditableFormRow,
                cell: EditableCell,
            },
        };

        return (
            <PageLayout pageKey="order" history={history}>
                <Row type="flex" justify="space-between">
                    <Col sm={24} md={4} lg={12}>
                        <h2>
                            <FormattedMessage
                                id="orderLicense.title"
                                values={{
                                    id,
                                }}
                            />
                        </h2>
                    </Col>
                </Row>

                { order && (
                    <Fragment>
                        {/* Include creation order stepper when order is edditable*/}
                        { this.isEditable() ? (
                            <OrderStepper stepIndex={(lines && lines.length > 0) ? 3 : 1} />
                        ) : ''}
                    </Fragment>
                )}

                {/* Order detail bloc */}
                {order && (
                    <div className="order-details">
                        {/* Left side bloc */}
                        <div className="order-details-bloc">
                            {/* Client Ref */}
                            <div>
                                <FormattedMessage id="orderLicense.detail.clientRef"></FormattedMessage>
                                {order.numCommandCustomer}
                            </div>

                            {/* Created By */}
                            <div>
                                <FormattedMessage id="order.detail.createdBy"></FormattedMessage>
                                {(order.userCreated.nomPrenom) ? (
                                    <Fragment>{order.userCreated.nomPrenom}</Fragment>
                                ) : (
                                    <i>Bucher Vaslin</i>
                                )}                    
                            </div>
                        </div>
                    </div>
                )}                

                {/* Array of order parts */}
                <Table
                    className="custom-table"
                    components={components}
                    dataSource={lines}
                    columns={this.getColumns()}
                    expandedRowRender={ArticleComponents}
                    rowClassName={(article) => (article.composants && article.composants.length ? 'editable-row' : 'editable-row no-expand')}
                    rowKey="id"
                    loading={loading}
                    //scroll={{ x: true }} // non compatible avec expandedRowRender
                />
                {
                    clientEurekaNumber && this.isEditable() ? (
                        <Fragment>
                            {/* Add article button */}
                            <AddArticlesLicense
                                client={clientEurekaNumber}
                                onSubmit={(articles) => this.AddArticlesLicense(articles)}
                            />
                            {/* Add article from csv file button */}     
                        </Fragment>
                    ) : ''
                }
            </PageLayout>
        );
    }
}

OrderLicense.propTypes = {
    intl: intlShape.isRequired,
};

const mapStateToProps = ({ app: { locale }, signIn: { userID, clientID } }) => ({
    userID,
    clientID,
    locale
});

export default injectIntl(connect(mapStateToProps)(OrderLicense));
