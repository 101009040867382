import React, { Component } from 'react';
import { FormattedMessage, injectIntl, intlShape } from 'react-intl';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLayout from './Layout/PageLayout';
import moment from 'moment';
import './Maintenance.less'

const renderDate = (date) => (date ? (
    <span>{moment(date).format('DD/MM/YYYY HH:mm')}</span>
) : (
    '-'
));

class Maintenance extends Component {

    render() {
        const { history, intl, maintenancePage } = this.props;

        return (
            <PageLayout pageKey="contact" history={history}>
                <div className="maintenance-container">
                    <div className="maintenance-title">
                        <FormattedMessage id="maintenance.title" />
                    </div>

                    <div className="maintenance-icon">
                        <FontAwesomeIcon icon={faTools} />
                    </div>

                    <div className="maintenance-title">
                        <FormattedMessage id="maintenance.description" />
                    </div>

                    <div className="maintenance-title">
                        <FormattedMessage id="maintenance.endDate" />
                        {maintenancePage ? renderDate(maintenancePage.dateEndMaintenanceFormatted) : null}
                    </div>
                </div>
            </PageLayout>
        );
    }
}

Maintenance.propTypes = {
    intl: intlShape.isRequired,
};

export default injectIntl(Maintenance);
