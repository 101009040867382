export function getDatasetColor(index) {
    // Prevent get color crash when more than 22 color is needed
    const totalColor = 22;
    index = index % totalColor;

    switch(index) {
        case 0:
            return {r: 180, g:180, b:180, a:0.2};
        case 1:
            return {r: 80, g:180, b:15, a:0.2};
        case 2:
            return {r: 180, g:15, b:180, a:0.2};
        case 3:
            return {r: 15, g:180, b:180, a:0.2};
        case 4:
            return {r: 180, g:15, b:15, a:0.2};
        case 5:
            return {r: 15, g:180, b:15, a:0.2};
        case 6:
            return {r: 15, g:15, b:180, a:0.2};
        case 7:
            return {r: 15, g:15, b:15, a:0.2};
        case 8:
            return {r: 180, g:30, b:120, a:0.2};
        case 9:
            return {r: 30, g:120, b:180, a:0.2};
        case 10:
            return {r: 30, g:180, b:125, a:0.2};
        case 11:
            return {r: 125, g:30, b:180, a:0.2};
        case 12:
            return {r: 180, g:180, b:25, a:0.2};
        case 13:
            return {r: 180, g:125, b:25, a:0.2};
        case 14:
            return {r: 180, g:100, b:25, a:0.2};
        case 15:
            return {r: 180, g:50, b:25, a:0.2};
        case 16:
            return {r: 210, g:125, b:125, a:0.2};
        case 17:
            return {r: 210, g:125, b:180, a:0.2};
        case 18:
            return {r: 180, g:125, b:210, a:0.2};
        case 19:
            return {r: 125, g:125, b:210, a:0.2};
        case 20:
            return {r: 125, g:210, b:150, a:0.2};
        case 21:
            return {r: 210, g:180, b:125, a:0.2};
    }
}