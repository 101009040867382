import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Form, Icon, Input } from 'antd';

import './PasswordField.less';

const { Item: FormItem } = Form;

class PasswordField extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
        };
    }

    onSuffixClicked() {
        const { show } = this.state;
        this.setState({ show: !show });
    }

    renderSuffix() {
        const { show } = this.state;

        return (
            <Icon
                type={show ? 'eye-invisible' : 'eye'}
                style={{ color: 'rgba(0, 0, 0, 0.5)', cursor: 'pointer' }}
                onClick={() => this.onSuffixClicked()}
            />
        );
    }

    render() {
        const {
            extra,
            form: { getFieldDecorator },
            identifier,
            label,
            layout,
            onBlur,
            placeholder,
            prefix,
            rules,
        } = this.props;
        const { show } = this.state;

        return (
            <FormItem
                {...layout}
                className="password-field"
                label={label}
                extra={extra}
            >
                {getFieldDecorator(identifier, {
                    normalize: (value) => value && value.replace(/\s/g, ''),
                    rules,
                })(
                    <Input
                        type={show ? 'text' : 'password'}
                        onBlur={onBlur}
                        prefix={prefix}
                        placeholder={placeholder}
                        suffix={this.renderSuffix()}
                    />,
                )}
            </FormItem>
        );
    }
}

PasswordField.propTypes = {
    onBlur: PropTypes.func,
    placeholder: PropTypes.string,
    prefix: PropTypes.node,
    form: PropTypes.shape({
        getFieldDecorator: PropTypes.func.isRequired,
    }).isRequired,
    rules: PropTypes.arrayOf(PropTypes.shape),
    identifier: PropTypes.string.isRequired,
    label: PropTypes.string,
    layout: PropTypes.shape({}),
    extra: PropTypes.string,
};

export default PasswordField;
